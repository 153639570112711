import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	OnDestroy,
	ChangeDetectorRef,
	Injector,
	OnInit,
} from "@angular/core";
import { PurchaseOrderDetailQuery } from "app/shared/eagers";
import { IInventory } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import {
	CalculatedCostInput,
	CostPerUnitInput,
	CurrencySelect,
	DiscountsInput,
	OtherFeesInput,
	TotalTaxInput,
} from "app/modules/dashboard/modules/es-forms/schemas/fields/costing";
import { getSelectedFacilitySettings } from "app/modules/dashboard/selectors/facility-settings.selector";

@Component({
	selector: "purchase-order-update-cost",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PurchaseOrderUpdateCostComponent
	extends GenericUpdateComponent<IInventory>
	implements OnDestroy, OnInit
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			purchase_order_id: {
				type: "number",
				title: "Purchase Order",
				hidden: true,
			},
			inventory_id: {
				type: "number",
				title: "Inventory",
				hidden: true,
			},
			cost_per_unit: { ...CostPerUnitInput, visibleIf: undefined },
			subtotal: { ...CalculatedCostInput, title: "Subtotal" },
			other_fees: { ...OtherFeesInput },
			discount: { ...DiscountsInput },
			currency: { ...CurrencySelect, hidden: true, widget: undefined },
			tax: { ...TotalTaxInput },
			total: { ...CalculatedCostInput, title: "Net cost" },
		},
		required: ["purchase_order_id", "inventory_id"],
	};

	facilitySettings$ = this._store.select(getSelectedFacilitySettings);

	private purchase_order_id: number;
	private inventory: IInventory;
	private is_outgoing: boolean;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.form_title = "Update Line Item";
		this.form_title_translation_key = marker("form_title_update_line_item");
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("form_button_update");
	}

	ngOnInit() {
		this.purchase_order_id = this._injector.get("purchase_order_id", undefined);
		this.inventory = this._injector.get("inventory", undefined);
		this.is_outgoing = this._injector.get("is_outgoing", undefined);
		this.model.purchase_order_id = this.purchase_order_id;
		this.model.inventory_id = this.inventory.id;
		this.model.cost_per_unit = this.inventory.cost_per_unit?.amount;
		this.model.other_fees = this.inventory.other_fees?.amount;
		this.model.discount = this.inventory.discount?.amount;
		this.model.tax = this.inventory.tax?.amount;
		const anyCost =
			this.inventory.cost_per_unit ??
			this.inventory.other_fees ??
			this.inventory.discount ??
			this.inventory.tax;
		this.model.currency = anyCost?.currency;
		if (!this.model.currency) {
			this.facilitySettings$.subscribe((facilitySettings) => {
				this.model.currency =
					facilitySettings.data?.options?.facility_currency ?? "CAD";
			});
		}
		if (this.is_outgoing) {
			this.schema.properties.cost_per_unit.title = "Price per unit";
		}
	}

	onChanges(model) {
		model.subtotal = this.inventory.orderedAmount! * model.cost_per_unit;
		model.total =
			(model.subtotal ?? 0) +
			(model.tax ?? 0) +
			(model.other_fees ?? 0) -
			(model.discount ?? 0);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(update) {
		this.loading$.next(true);
		this._itemService
			.update(
				`purchase_order/${this.purchase_order_id}/cost`,
				this.inventory.id,
				{
					...update,
				},
				{
					...PurchaseOrderDetailQuery,
					is_outgoing: this.is_outgoing ? "true" : "false",
				},
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "purchase_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
