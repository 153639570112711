import { IWorkOrder } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.WorkOrders;

export type State = SelectionState<IWorkOrder>;

export const reducer = createSelectionReducer<IWorkOrder>(reducerKey);
