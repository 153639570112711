import { Store } from "@ngrx/store";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, take, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { IInventory } from "@elevatedsignals/amygoodman";
import { InventoryDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { Globals } from "app/shared/modules/globals/globals.service";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "inventory-move",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class InventoryMoveComponent
	extends GenericUpdateComponent<IInventory>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			location_id: {
				type: "number",
				title: "Location",
				title_translation_key: marker("word_location"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "locations",
					},
				],
			},
			timestamp: {
				type: "string",
				title: "Date lot moved",
				title_translation_key: marker("form_field_label_date_lot_moved"),
				widget: "date",
			},
		},
		required: ["location_id"],
	};

	inventory$ = this._store.select(fromDashboard.getSelectedInventory);
	inventory: IInventory;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
	) {
		super(_store, _cd);
		this.form_title = "Move Inventory Location";
		this.form_title_translation_key = marker(
			"form_title_move_inventory_location",
		);
		this.submit_button = "Move";
		this.submit_button_translation_key = marker("word_move");
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete (this.schema.properties as any).timestamp;
		}

		this.inventory$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((inventory) => {
				this.inventory = inventory;
				this.model.id = this.inventory.id;
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(type: Partial<IInventory>) {
		this.loading$.next(true);
		this._itemService
			.update(
				`inventory/move`,
				Number(type.id),
				{
					...type,
					...(this.model.timestamp && { timestamp: this.model.timestamp }),
				},
				InventoryDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "inventories",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
