import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	Injector,
	OnInit,
	OnDestroy,
} from "@angular/core";
import { WorkOrderService } from "app/modules/dashboard/services/work-order.service";
import { Globals } from "app/shared/modules/globals/globals.service";
import { IWorkOrderSource, MeasureUnit } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

// @deprecated
@Component({
	selector: "work-order-drying-source-ez-gi",
	templateUrl: "../../form-view.component.html",
	styleUrls: ["../../sidenav.scss"],
})
export class WorkOrderDryingSourceEzGiComponent
	extends GenericUpdateComponent<{
		id: number;
		batch_id: number;
		work_order_id: boolean;
		weight: number;
	}>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			batch_id: {
				type: "number",
				hidden: true,
			},
			remaining_inventory: {
				title: "Use Remaining Inventory",
				title_translation_key: marker("form_field_label_use_remaining_inventory"),
				type: "boolean",
				widget: "checkbox",
				default: false,
			},
			value: {
				type: "number",
				title: `Weight (${this._globals.getFacilityUnit(MeasureUnit.Mass)})`,
				title_translation_key: marker("form_field_label_value"),
				title_translation_params: {
					units: this._globals.getFacilityUnit(MeasureUnit.Mass),
				},
				visibleIf: {
					remaining_inventory: [false],
				},
			},
			timestamp: {
				type: "string",
				title: "Date",
				title_translation_key: marker("word_date"),
				widget: "date",
			},

			/** These are just used for the required to make sure we have all the correct items selected before allowing the user to submit the form */
			remaining_inventory_selected: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: false,
				visibleIf: {
					remaining_inventory: [true],
				},
			},
		},
		anyOf: [
			{
				required: ["value"],
			},
			{
				required: ["remaining_inventory_selected"],
			},
		],
	};

	weight_unit = this._globals.weight_unit;
	// Injected from other component
	private batch_id: number;
	private work_order_id: number;

	constructor(
		protected readonly _store: Store<fromDashboard.State>,
		protected readonly _cd: ChangeDetectorRef,
		private readonly _workOrderService: WorkOrderService,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.form_title = "Add Fresh Cannabis";
		this.form_title_translation_key = marker("form_title_add_fresh_cannabis");
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.batch_id = this._injector.get("batch_id", null);
		this.model.batch_id = this.batch_id;
		this.work_order_id = this._injector.get("work_order_id", null);

		const duplicate: IWorkOrderSource | null = this._injector.get(
			"duplicate",
			null,
		);
		if (duplicate) {
			this.model = {
				...this.model,
				inventory_product_id: duplicate.inventory.inventory_product_id,
				inventory_unit_id: duplicate.inventory.inventory_unit_id,
				batch_id: duplicate.batch_id,
				location_id: duplicate.location_id,
				new_lot: "Use Existing Lot",
				lot_id: duplicate.inventory.lot_id,
			};
			if (
				duplicate.inventory.measurements &&
				duplicate.inventory.measurements[0]
			) {
				this.model = {
					...this.model,
					value: duplicate.inventory.measurements[0].value,
					timestamp: duplicate.inventory.measurements[0].timestamp,
				};
			}
			this.batch_id = duplicate.batch_id!;
			this.work_order_id = duplicate.work_order_id;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {}

	updateItem(work_order_output: { value: number; timestamp?: Date }) {
		const newWorkOrderOutput = {
			...work_order_output,
			timestamp: work_order_output.timestamp ?? new Date(),
		};

		this.loading$.next(true);
		const input: any = {
			...newWorkOrderOutput,
			work_order_id: this.work_order_id,
			batch_id: this.batch_id,
			type: "Inventory",
		};

		this._workOrderService
			.addSources(input)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
