import { tap, take, takeUntil, timeout, catchError } from "rxjs/operators";
import {
	Component,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
	Injector,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ReplaySubject, EMPTY, Observable } from "rxjs";
import { Batch, UserProfile } from "@elevatedsignals/amygoodman";
import { BatchDetailQuery } from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { PasswordInputComponent } from "app/modules/auth/components/password-input";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GeneralBatchActionSchemaProperties } from "../work-order/schemas/batch-action";

@Component({
	selector: "batch-change-growth-stage",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class BatchChangeGrowthStageComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	batch$ = this._store.select(fromDashboard.getSelectedBatch);
	batch: Batch;
	batch_ids: number[];

	user$: Observable<UserProfile | null> = this._store.select(
		fromDashboard.getProfile,
	);

	user: UserProfile;

	form_title = "Advance growth stage from a batch";
	form_title_translation_key: string = marker(
		"form_title_advance_growth_stage_from_a_batch",
	);

	submit_button = "Advance Growth Stage";
	submit_button_translation_key: string = marker(
		"form_button_advance_growth_stage",
	);

	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};
	onUpdated: any;

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			batch_ids: {
				type: "array",
				title: "Selected Batches",
				title_translation_key: marker("form_field_label_selected_batches"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "batches",
						},
					],
				},
				warning:
					"Warning: Bulk Actions can only be reversed at an individual batch level, please ensure you have selected the correct batches before continuing with the action.",
				warning_translation_key: marker(
					"form_field_warning_bulk_actions_can_only_be_reversed_at_an_individual_batch_level",
				),
			},
			growth_stage_id: {
				type: "number",
				title: "Select Growth Stage",
				title_translation_key: marker("form_field_label_select_growth_stage"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "growth_stages",
						queryString: {
							excludeTypes: ["Harvested"],
							growth_stage_status: ["ACTIVE"],
						},
					},
				],
			},
			timestamp: {
				type: "string",
				title: "Date growth stage changed",
				title_translation_key: marker("form_field_label_date_growth_stage_changed"),
				widget: "date",
			},
			work_order_description: {
				type: "string",
				widget: "textarea",
				title: "Work Order Description",
				title_translation_key: marker("form_field_label_work_order_description"),
			},
			...GeneralBatchActionSchemaProperties,
		},
		required: ["growth_stage_id"],
	};

	// eslint-disable-next-line @typescript-eslint/naming-convention
	validators = { "/batch_ids": () => ({ batch_ids: {} }) };

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
	) {}

	valid(v) {
		this.valid$.next(v);
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete (this.schema.properties as any).timestamp;
		}

		this.batch_ids = this._injector.get("ids", null);
		this.onUpdated = this._injector.get("onUpdated", null);

		this.user$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined())
			.subscribe((user) => {
				this.user = user;
			});

		if (this.batch_ids) {
			delete (this.schema.properties as any).id;
			delete this.model.id;

			this.model = {
				...this.model,
				batch_ids: this.batch_ids,
			};
		} else {
			delete (this.schema.properties as any).batch_ids;

			this.batch$
				.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
				.subscribe((batch) => {
					this.batch = batch;
					this.model = {
						...this.model,
						id: this.batch.id,
					};
					this._cd.detectChanges();
				});
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const growth_change: any = {
			...this.model,
		};

		if (this._globals.gmp_enabled) {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: PasswordInputComponent,
					inputs: {
						onSubmit: (password) => {
							this.growthStageChangeRequest(growth_change, true, password);
						},
					},
				}),
			);
		} else {
			this.growthStageChangeRequest(growth_change, true);
		}
	}

	// tslint:disable-next-line:no-empty
	onChanges(form_data): void {}

	growthStageChangeRequest(
		growth_change_model: any,
		signing = false,
		password?: string,
	) {
		const authObject = password
			? { username: this.user.email, password }
			: undefined;
		this.loading$.next(true);

		let moveEndpoint;
		let moveParams;

		if (this.batch) {
			moveEndpoint = "batch";
			moveParams = `${this.batch.id}/change_growth_stage`;
		} else if (this.batch_ids) {
			moveEndpoint = "batches";
			moveParams = "change_growth_stage";
		}

		this._itemService
			.update(
				moveEndpoint,
				moveParams,
				growth_change_model,
				{ ...BatchDetailQuery, signing: signing.toString() },
				authObject,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					if (this.batch_ids) {
						this.onUpdated();
					} else {
						this._store.dispatch(
							ItemActions.updateSuccess({
								updatedItem,
								result_type: "batches",
							}),
						);
					}
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
