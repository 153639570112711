import { marker } from "@jsverse/transloco-keys-manager/marker";

import { NewInventoryCreationSchema } from "../../inventory/schemas/index";
import { NewLotCreationSchema } from "../../lot/schemas/index";

export interface GeneralInventoryBOMOutputVhDynamic {
	id: number;
	inventory_product_id: number;
	vendor_id: number;
	batch_id?: number;
	location_id?: number;

	quantity?: number;
	inventory_unit_id?: number;
	timestamp?: string;
}

export const GeneralInventoryBOMOutputVhDynamicSchema = {
	title: "",
	description: "",
	info: "",
	properties: {
		id: {
			type: "number",
			hidden: true,
		},
		inventory_product_id: {
			title: "Product",
			title_translation_key: marker("word_product"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			selectFirstAndOnly: true,
			oneOf: [
				{
					result_type: "inventory_products",
					queryString: {
						showArchived: "false",
						non_inventory_destruction: "false",
					},
				},
			],
			readOnly: true,
		},
		vendor_id: {
			type: "number",
			title: "Vendor",
			title_translation_key: marker("word_vendor"),
			widget: "data-select",
			oneOf: [
				{
					result_type: "vendors",
				},
			],
			related_properties: ["inventory_product_id"],
			quick_create: false,
			selectFirstAndOnly: true,
		},
		batch_id: {
			type: "number",
			title: "Destination Batch",
			title_translation_key: marker("form_field_label_destination_batch"),
			widget: "data-select",
			hidden: true,
			selectFirstAndOnly: true,
			oneOf: [
				{
					result_type: "batches",
					queryString: {
						input: "true",
						dont_show_expired: "true",
					},
				},
			],
			visibleIf: {
				allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
			},
			readOnly: false,
		},
		location_id: {
			type: "number",
			title: "Destination Location",
			title_translation_key: marker("form_field_label_destination_location"),
			widget: "data-select",
			quick_create: false,
			selectFirstAndOnly: true,
			oneOf: [
				{
					result_type: "locations",
					queryString: {
						input: "true",
					},
				},
			],
			visibleIf: {
				allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
			},
			readOnly: false,
		},
		quantity: {
			type: "number",
			title: `Quantity`,
			title_translation_key: marker("word_quantity"),
			width: "50%",
			visibleIf: {
				inventory_product_id: ["$EXP$ target.value > 0"],
			},
		},
		inventory_unit_id: {
			type: "number",
			title: "Inventory Unit",
			title_translation_key: marker("word_inventory_unit"),
			widget: "data-select",
			quick_create: false,
			related_properties: ["inventory_product_id"],
			width: "50%",
			shorter_placeholder: true,
			oneOf: [
				{
					result_type: "inventory_units",
				},
			],
			visibleIf: {
				inventory_product_id: ["$EXP$ target.value > 0"],
			},
		},
		timestamp: {
			type: "string",
			title: "Timestamp",
			title_translation_key: marker("word_timestamp"),
			hidden: false,
			widget: "date",
		},
	},
	anyOf: [
		{
			required: [
				"id",
				"location_id",
				"inventory_product_id",
				"inventory_unit_id",
				"quantity",
				"vendor_id",
			],
		},
	],
};
