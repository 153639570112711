import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { TranslocoService } from "@jsverse/transloco";
import { timeout, catchError, tap, take, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	Injector,
	OnInit,
	OnDestroy,
} from "@angular/core";
import { WorkOrderService } from "app/modules/dashboard/services/work-order.service";
import {
	IWorkOrder,
	IWorkOrderInput,
	SubstanceTypes,
} from "@elevatedsignals/amygoodman";
import { Globals } from "app/shared/modules/globals/globals.service";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { handleObservableError } from "app/shared/utils";
import { WorkOrderOutputQuery } from "app/shared/eagers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "work-order-add-plants-harvest-by-id",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderAddPlantHarvestByIdSourceComponent
	extends GenericUpdateComponent<{
		id: number;
		batch_id: number;
		work_order_id: boolean;
		weight: number;
	}>
	implements OnInit, OnDestroy
{
	work_order$ = this._store.select(fromDashboard.getSelectedWorkOrder);
	work_order: IWorkOrder;

	wet_weight_inventory_product_names: string[] = [];

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/growth_stage_id": (value, property, form) => {
			if (value !== null) {
				if (this.gs_ids.length > 0) {
					if (this.gs_ids.includes(value)) {
						return null;
					} else {
						return { growth_stage_id: {} };
					}
				}
			}
			return null;
		},
	};

	// Injected from other component
	private batch_id: number;
	private batch_name: number;
	private work_order_id: number;
	private gs_ids: number[] = [];

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _workOrderService: WorkOrderService,
		private readonly _injector: Injector,
		private readonly _globals: Globals,
		private readonly _itemService: ItemService,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store, _cd);
		this.form_title = "Special Harvest by Plant Id";
		this.form_title_translation_key = marker(
			"form_title_add_plants_ids_to_harvest",
		);
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";

		this.wet_weight_inventory_product_names = this._injector.get(
			"wet_weight_inventory_product_names",
			[] as string[],
		);

		this.schema = {
			title: "",
			description: "",
			info: "",
			properties: {
				batch_id: {
					type: "number",
					readOnly: true,
					hidden: true,
				},
				input_label: {
					type: "string",
					title: "Inputs",
					widget: "section",
				},
				plant_id: {
					type: "number",
					title: "Harvested Plant",
					title_translation_key: marker("word_plant"),
					widget: "data-select",
					related_properties: ["batch_id"],
					oneOf: [
						{
							result_type: "plants",
							queryString: {
								plant_status: "FLOWERING",
							},
						},
					],
				},
				growth_stage_type: {
					type: "string",
					title: "Transition Growth Stage To...",
					title_translation_key: marker(
						"form_field_label_transition_growth_stage_to",
					),
					warning:
						"Warning: Selected growth stage is not a harvest growth stage, this will leave the plants unharvested.",
					warning_translation_key: marker(
						"form_field_warning_selected_growth_stage_is_not_a_harvest_growth_stage_this_will_leave_the_plants_unharvested",
					),
					widget: "select",
					oneOf: [
						{
							name: "Harvested",
							name_translation_key: marker("word_harvested"),
							value: "Harvested",
							enum: ["Harvested"],
						},
						{
							name: "Other",
							name_translation_key: marker("word_other"),
							value: "OTHER",
							enum: ["OTHER"],
						},
					],
					default: "Harvested",
				},
				growth_stage_id: {
					type: "number",
					title: "Growth Stage",
					title_translation_key: marker("word_growth_stage"),
					widget: "data-select",
					oneOf: [
						{
							result_type: "growth_stages",
							queryString: { excludeTypes: ["Harvested"] },
						},
					],
					visibleIf: {
						growth_stage_type: ["OTHER"],
					},
				},
				output_label: {
					type: "string",
					title: "Outputs",
					widget: "section",
				},
				inventory_product_id: {
					type: "number",
					title: "Product",
					title_translation_key: marker("word_product"),
					widget: "data-select",
					quick_create: false,
					oneOf: [
						{
							result_type: "inventory_products",
							queryString: {
								search:
									this.wet_weight_inventory_product_names[0] ??
									SubstanceTypes.FreshCannabis,
								internal_vendor: "true",
							},
						},
					],
				},
				inventory_unit_id: {
					title: "Inventory Unit",
					title_translation_key: marker("word_inventory_unit"),
					type: "number",
					widget: "data-select",
					quick_create: false,
					related_properties: ["inventory_product_id"],
					oneOf: [
						{
							result_type: "inventory_units",
						},
					],
					visibleIf: {
						inventory_product_id: ["$ANY$"],
					},
				},
				quantity: {
					type: "number",
					title: `Quantity`,
					title_translation_key: marker("word_quantity"),
					visibleIf: {
						inventory_unit_id: ["$ANY$"],
					},
				},
				timestamp: {
					type: "string",
					title: "Harvest Date",
					title_translation_key: marker("form_field_label_harvest_date"),
					widget: "date",
				},
			},
			anyOf: [
				{
					required: [
						"plant_id",
						"inventory_product_id",
						"inventory_unit_id",
						"quantity",
					],
				},
			],
		};
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.batch_id = this._injector.get("batch_id", null);
		this.batch_name = this._injector.get("batch_name", null);

		if (this.batch_id) {
			this.model = {
				...this.model,
				batch_id: this.batch_id,
			};
		}

		const growth_stage_id = this._injector.get("growth_stage_id", null);
		if (growth_stage_id) {
			this.model = {
				...this.model,
				growth_stage_id,
			};
		}

		this.work_order_id = this._injector.get("work_order_id", null);
		this.work_order$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((work_order: IWorkOrder) => {
				this.work_order = work_order;
				if (this.work_order.location_id) {
					this.schema.properties.move_batch_to_work_order_location = {
						type: "boolean",
						widget: "checkbox",
						title: "Move batch to work order location",
						title_translation_key: marker(
							"form_field_label_move_batch_to_work_order_location",
						),
						default: this.work_order.work_order_type?.move_batch_to_location ?? false,
					};
					this.model.move_batch_to_work_order_location =
						this.work_order.work_order_type?.move_batch_to_location ?? false;
				}
				this._cd.detectChanges();
			});

		this._itemService
			.fetchItem(`growth_stages/type`, "Harvested")
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For harvest growth stage validation.
				this.gs_ids = (value ?? []).map((gs) => gs.id);
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(_model) {}

	updateItem(work_order_source: {
		batch_id: number;
		plant_type: string;
		plant_id: number | undefined;
		plant_count: number | undefined;
		growth_stage_type: string;
		growth_stage_id: number;
		timestamp: Date;
		inventory_product_id: number;
		inventory_unit_id: number;
		quantity: number;
	}) {
		this.loading$.next(true);

		let growth_stage_id = work_order_source.growth_stage_id;
		if (work_order_source.growth_stage_type === "Harvested") {
			if (this.gs_ids[0]) {
				growth_stage_id = this.gs_ids[0];
			} else {
				this.error$.next(
					this._translocoService.translate("error_harvested_growth_stage_not_found"),
				);
				this.loading$.next(false);
				return;
			}
		}

		const input = {
			plant_ids: [work_order_source.plant_id!],
			growth_stage_id,
			timestamp: work_order_source.timestamp,
			substance_type: "Plant",
			work_order_id: this.work_order_id,
			batch_id: this.batch_id,
			plant_selection: "EXISTING",
		};

		const inventory_name: string = [
			this.batch_name,
			work_order_source.plant_id,
		].join("-");

		const output = {
			work_order_id: this.work_order_id,
			batch_id: this.batch_id,
			timestamp: work_order_source.timestamp,
			type: "Inventory",
			inventory_product_id: work_order_source.inventory_product_id,
			inventory_unit_id: work_order_source.inventory_unit_id,
			create_new_inventory: true,
			inventory_name,
			create_new_lot: true,
			value: work_order_source.quantity,
		};

		this._workOrderService
			.addSources(input)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(tap((item) => {}))
			.subscribe();

		this._itemService
			.add(
				`work_order/${this.work_order_id}/outputs`,
				output,
				WorkOrderOutputQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
