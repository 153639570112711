import {
	createSelector,
	createFeatureSelector,
	combineReducers,
	Action,
} from "@ngrx/store";
import {
	State as ModuleState,
	DashboardState as DBState,
} from "app/modules/dashboard/state/dashboard.state";
import { facilityLanguage } from "app/modules/dashboard/selectors/facility-settings.selector";
import { Permissions } from "app/shared/permissions";

import * as fromProfile from "./profile.reducer";
import * as fromUsers from "./users.reducer";
import * as fromLayout from "./layout.reducer";
import * as fromPaging from "./paging.reducer";
import * as fromRoles from "./roles.reducer";
import * as fromNotifications from "./notifications.reducer";
import * as fromFacilitySettings from "./facilitySettings.reducer";
import * as fromPrinter from "./printer.reducer";
import * as fromCustomFields from "./customField.reducer";
import * as fromDownload from "./download.reducer";

/**
 * @deprecated Directly import from selector folder.
 * This is meant to keep things working as is till things can be moved around.
 */
export * from "../selectors/paging.selector";

/**
 * @deprecated Directly import from state folder.
 * This is meant to keep things working as is till things can be moved around.
 */
export type State = ModuleState;

/**
 * @deprecated Directly import from state folder.
 * This is meant to keep things working as is till things can be moved around.
 */
export type DashboardState = DBState;

// TODO: consolidate these from selectors
export const dashboardFeatureKey = "dashboardV2";

export const reducers = (state: DBState, action: Action) =>
	combineReducers({
		profile: fromProfile.reducer,
		users: fromUsers.reducer,
		layout: fromLayout.reducer,
		paging: fromPaging.reducer,
		roles: fromRoles.reducer,
		notifications: fromNotifications.reducer,
		facilitySettings: fromFacilitySettings.reducer,
		printer: fromPrinter.reducer,
		customFields: fromCustomFields.reducer,
		download: fromDownload.reducer,
	})(state, action);

/**
 * A selector function is a map function factory. We pass it parameters and it
 * returns a function that maps from the larger state tree into a smaller
 * piece of state. This selector simply selects the `books` state.
 *
 * Selectors are used with the `select` operator.
 *
 * ```ts
 * class MyComponent {
 * 	constructor(state$: Observable<State>) {
 * 	  this.booksState$ = state$.select(getBooksState);
 * 	}
 * }
 * ```
 */

/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const getDashboardState =
	createFeatureSelector<DBState>(dashboardFeatureKey);

/**
 * Every reducer module exports selector functions, however child reducers
 * have no knowledge of the overall state tree. To make them useable, we
 * need to make new selectors that wrap them.
 *
 * The createSelector function creates very efficient selectors that are memoized and
 * only recompute when arguments change. The created selectors can also be composed
 * together to select different pieces of state.
 */
export const getProfileState = createSelector(
	getDashboardState,
	(state: DashboardState) => state.profile,
);
export const getProfile = createSelector(
	getProfileState,
	fromProfile.getProfile,
);
export const getProfileId = createSelector(getProfileState, fromProfile.getId);

export const getFacilityId = createSelector(
	getProfileState,
	fromProfile.getFacilityId,
);

export const getProfileFetchInProgress = createSelector(
	getProfileState,
	fromProfile.getFetchInProgress,
);
export const getProfileUpdateInProgress = createSelector(
	getProfileState,
	fromProfile.getUpdateInProgress,
);
/** Export Errors **/
export const getProfileFetchError = createSelector(
	getProfileState,
	fromProfile.getFetchError,
);
export const getProfileUpdateError = createSelector(
	getProfileState,
	fromProfile.getUpdateError,
);

/** *********************/
/** ROLES/PERMISSIONS **/
/** *********************/

export const getRoleState = createSelector(
	getDashboardState,
	(state: DashboardState) => state.roles,
);

export const getPermissions = createSelector(
	getRoleState,
	fromRoles.getPermissions,
);

/** *********************/
/** ****** USERS ********/
/** *********************/

export const getUsersState = createSelector(
	getDashboardState,
	(state: DashboardState) => state.users,
);

export const getUsers = createSelector(getUsersState, fromUsers.getUsers);

export const getUsersInviteInProgress = createSelector(
	getUsersState,
	fromUsers.getInviteInProgress,
);
export const getUsersFetchRolesInProgress = createSelector(
	getUsersState,
	fromUsers.getFetchRolesInProgress,
);
export const getUsersUpdateInProgress = createSelector(
	getUsersState,
	fromUsers.getUpdateInProgress,
);
/** Export Errors **/
export const getUsersFetchError = createSelector(
	getUsersState,
	fromUsers.getFetchError,
);
export const getUsersInviteError = createSelector(
	getUsersState,
	fromUsers.getInviteError,
);
export const getUsersFetchRolesError = createSelector(
	getUsersState,
	fromUsers.getFetchRolesError,
);
export const getUsersUpdateError = createSelector(
	getUsersState,
	fromUsers.getUpdateError,
);

/** *********************/
/** ***** LAYOUT ********/
/** *********************/
export const getLayoutState = createSelector(
	getDashboardState,
	(state: DashboardState) => state.layout,
);

export const getShowSidenav = createSelector(
	getLayoutState,
	fromLayout.getShowSidenav,
);

export const getCurrentSidenavData = createSelector(
	getLayoutState,
	fromLayout.getCurrentSidenavData,
);

export const getShowDropdown = createSelector(
	getLayoutState,
	fromLayout.getShowDropdown,
);

export const getSidenavStackData = createSelector(
	getLayoutState,
	fromLayout.getSidenavStackData,
);

export const getDropdownData = createSelector(
	getLayoutState,
	fromLayout.getDropdownData,
);

export const getSidenavMaximized = createSelector(
	getLayoutState,
	fromLayout.getSidenavMaximized,
);

export const getDesiredLanguage = createSelector(
	facilityLanguage,
	getProfile,
	(language, userProfile) => {
		if (userProfile?.settings?.PREFERRED_LANGUAGE) {
			return userProfile.settings.PREFERRED_LANGUAGE;
		}

		return language;
	},
);

export const userHasPermission = (permission: string) =>
	createSelector(getProfile, (user) =>
		Permissions.hasPermission(user!, permission),
	);

/*## Download State */
export const getDownloadState = createSelector(
	getDashboardState,
	(state: DashboardState) => state.download,
);

export const pdfDownloadInProgress = createSelector(
	getDownloadState,
	fromDownload.selectpdfDownloadInProgress,
);

export const pdfDownloadError = createSelector(
	getDownloadState,
	fromDownload.selectPDFDownloadError,
);

export const pdfFileName = createSelector(
	getDownloadState,
	fromDownload.selectPdfFileName,
);
