import { ILot, ISku } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.Lots;

export type State = SelectionState<ILot>;

export const reducer = createSelectionReducer<ILot>(reducerKey);
