<div class="ui grid dirty-mackenzie">
	<div class="sixteen wide column">
		<div class="left floated left aligned eight wide column">
			<button class="ui labeled icon button" (click)="closeSidenav()">
				<i class="left arrow icon"></i>
				Cancel
			</button>
		</div>
	</div>
	<div class="sixteen wide column">
		<h2>{{ form_title }}</h2>
	</div>

	<div class="sixteen wide column">
		<sf-form
			[schema]="schema"
			[model]="model"
			(onChange)="onChanges($event.value); model = $event.value"
			[validators]="validators"
			(isValid)="valid($event)"
			[bindings]="fieldBindings"
		></sf-form>
	</div>
	<br />

	<div class="sixteen wide column" *ngIf="error$ | async">
		<div class="ui error message">
			<span>{{ error$ | async }}</span>
		</div>
	</div>

	<div
		class="sixteen wide column"
		*ngIf="copyProduct && model.inventory_product_id"
	>
		<button class="ui button" (click)="openCustomizedUnits()">
			{{ customize_units_button }}
		</button>
	</div>
	<div class="sixteen wide column" *ngIf="submit_button !== undefined">
		<submit-button
			[loading]="loading$ | async"
			[valid]="valid$ | async"
			submit_icon="plus"
			[submit_button]="submit_button"
			[submit_button_translation_key]="submit_button_translation_key"
			(onSubmit)="onSubmit()"
		/>
	</div>
</div>
