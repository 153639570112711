import { createSelector } from "reselect";
import { User, Page } from "@elevatedsignals/amygoodman";
import { UserActions } from "app/modules/dashboard/actions/user.actions";
import { AuthActions } from "app/modules/auth/actions/auth.actions";

export interface State {
	user: {
		ids: number[];
		entities: Record<number, User>;
	};
	// role: {
	// 	ids: number[];
	// 	entities: { [id: number]: Role };
	// };
	display: {
		selected_user_id: number | null;
		selected_user?: User;
		page: Page<User>;
	};
	api: {
		fetch: {
			error?: string | null;
			in_progress: boolean;
		};
		invite: {
			error?: string | null;
			in_progress: boolean;
		};
		fetch_roles: {
			error?: string | null;
			in_progress: boolean;
		};
		update: {
			error?: string | null;
			in_progress: boolean;
		};
	};
}

const initialState: State = {
	user: {
		ids: [],
		entities: {},
	},
	// role: {
	// 	ids: [],
	// 	entities: {},
	// },
	display: {
		selected_user_id: null,
		selected_user: undefined,
		page: {
			title: "User",
			page: 1,
			page_size: 50,
			total: 0,
			results: [],
			result_type: "users",
		},
	},
	api: {
		fetch: {
			in_progress: false,
		},
		invite: {
			in_progress: false,
		},
		update: {
			in_progress: false,
		},
		fetch_roles: {
			in_progress: false,
		},
	},
};

export const reducer = (state = initialState, action): State => {
	switch (action.type) {
		case UserActions.fetch: {
			return {
				...state,
				api: {
					...state.api,
					fetch: {
						in_progress: true,
					},
				},
			};
		}
		case UserActions.fetchSuccess: {
			const users = action.payload as User[];
			const newUsers = users.filter((user) => !state.user.entities[user.id]);

			const newUserIds = newUsers.map((user: User) => user.id);
			const newUserEntities = newUsers.reduce(
				(entities: Record<number, User>, user: User) => {
					let display_name = user.email;
					if (user.first_name) {
						display_name = user.first_name;
						if (user.last_name) {
							display_name = `${display_name} ${user.last_name}`;
						}
					} else if (user.last_name) {
						display_name = user.last_name;
					}
					return Object.assign(entities, {
						[user.id]: { ...user, display_name },
					});
				},
				{},
			);

			return {
				...state,
				user: {
					ids: [...state.user.ids, ...newUserIds],
					entities: { ...state.user.entities, ...newUserEntities },
				},
				api: {
					...state.api,
					fetch: {
						in_progress: false,
					},
				},
			};
		}
		case UserActions.fetchFailed: {
			const error = action.payload as string;
			return {
				...state,
				api: {
					...state.api,
					fetch: {
						in_progress: false,
						error,
					},
				},
			};
		}

		// case user.ActionTypes.INVITE:
		case UserActions.reinvite: {
			return {
				...state,
				api: {
					...state.api,
					invite: {
						in_progress: true,
					},
				},
			};
		}
		case UserActions.reinviteSuccess: {
			return {
				...state,

				api: {
					...state.api,
					invite: {
						in_progress: false,
					},
				},
			};
		}
		// case user.ActionTypes.INVITE_FAIL:
		case UserActions.reinviteFail: {
			const error = action.payload as string;
			return {
				...state,
				api: {
					...state.api,
					invite: {
						in_progress: false,
						error,
					},
				},
			};
		}

		case UserActions.update: {
			return {
				...state,
				api: {
					...state.api,
					update: {
						in_progress: true,
					},
				},
			};
		}
		case UserActions.updateSuccess: {
			const user = action.payload as User;

			if (!state.user.ids.includes(user.id)) {
				// Error, id not found....
				return state;
			}

			return {
				...state,
				user: {
					ids: state.user.ids,
					entities: { ...state.user.entities, [user.id]: user },
				},
				api: {
					...state.api,
					update: {
						in_progress: false,
					},
				},
			};
		}
		case UserActions.updateFail: {
			const error = action.payload as string;
			return {
				...state,
				api: {
					...state.api,
					update: {
						in_progress: false,
						error,
					},
				},
			};
		}

		case AuthActions.logout: {
			return initialState;
		}

		default: {
			return state;
		}
	}
};

/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */

export const getEntities = (state: State) => state.user.entities;
export const getIds = (state: State) => state.user.ids;
export const getSelectedId = (state: State) => state.display.selected_user_id;

export const getSelected = (state: State) => state.display.selected_user;
export const getPage = (state: State) => state.display.page;

export const getUsers = createSelector(getEntities, getIds, (entities, ids) => {
	return ids.map((id) => entities[id]);
});

// export const getRoleEntities = (state: State) => state.role.entities;
// export const getRoleIds = (state: State) => state.role.ids;

// export const getRoles = createSelector(
// 	getRoleEntities,
// 	getRoleIds,
// 	(entities, ids) => {
// 		return ids.map(id => entities[id]);
// 	}
// );

export const getFetchInProgress = (state: State) => state.api.fetch.in_progress;
export const getInviteInProgress = (state: State) =>
	state.api.invite.in_progress;
export const getFetchRolesInProgress = (state: State) =>
	state.api.fetch_roles.in_progress;
export const getUpdateInProgress = (state: State) =>
	state.api.update.in_progress;

export const getFetchError = (state: State) => state.api.fetch.error;
export const getInviteError = (state: State) => state.api.invite.error;
export const getFetchRolesError = (state: State) => state.api.fetch_roles.error;
export const getUpdateError = (state: State) => state.api.update.error;
