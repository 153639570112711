import { Component, OnInit, Injector } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";

import { SidenavConfirmConfig } from "./confirm.component";

@Component({
	selector: "sidenav-confirm-inject",
	template: `
		<sidenav-confirm
			[config]="config"
			(callback)="onCallback($event)"
		></sidenav-confirm>
	`,
})
export class SidenavConfirmInjectComponent implements OnInit {
	config: Partial<SidenavConfirmConfig> | undefined;
	callback: any;
	defaultConfig: SidenavConfirmConfig;
	constructor(
		private readonly _injector: Injector,
		private readonly _translocoService: TranslocoService,
	) {
		this.defaultConfig = {
			icon: "trash",
			title: "",
			description: this._translocoService.translate(
				"form_title_are_you_sure_you_want_to_delete_this_item",
			),
			iUnderstandText: undefined,
			type: "delete",
			buttons: [
				{
					title: this._translocoService.translate("word_cancel"),
					class: "",
					callback: "cancel",
					requiresUnderstand: false,
				},
				{
					title: this._translocoService.translate("word_delete"),
					class: "red",
					callback: "delete",
					requiresUnderstand: false,
				},
			],
		};
	}

	ngOnInit() {
		this.config = {
			...this.defaultConfig,
			...this._injector.get("config", undefined),
		};
		this.callback = this._injector.get("callback", undefined);
	}

	onCallback(event: any) {
		if (this.callback) {
			this.callback(event);
		}
	}
}
