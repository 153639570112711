import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import {
	timeout,
	catchError,
	take,
	takeUntil,
	filter,
	tap,
} from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { IVendor } from "@elevatedsignals/amygoodman";
import { VendorDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "vendor-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class VendorUpdateComponent
	extends GenericUpdateComponent<IVendor>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: Vendor name already in use.",
				warning_translation_key: marker(
					"form_field_warning_vendor_name_already_in_use",
				),
			},
			vendor_category_ids: {
				type: "array",
				title: "Vendors Categories",
				title_translation_key: marker("word_vendor_categories"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "vendor_categories",
							// text_key: ["name"],
						},
					],
				},
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
			},
			address: {
				type: "string",
				title: "Address",
				title_translation_key: marker("word_address"),
			},
			primary_phone: {
				type: "string",
				title: "Phone 1",
				title_translation_key: marker("word_phone_1"),
			},
			secondary_phone: {
				type: "string",
				title: "Phone 2",
				title_translation_key: marker("word_phone_2"),
			},
			email: {
				type: "string",
				title: "Email",
				title_translation_key: marker("word_email"),
			},
			customer: {
				type: "boolean",
				widget: "checkbox",
				title: "Customer",
				title_translation_key: marker("form_field_label_customer"),
			},
			facility_vendor: {
				type: "boolean",
				widget: "checkbox",
				title: "Facility Vendor",
				title_translation_key: marker("form_field_label_facility_vendor"),
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
		},
		required: ["name"],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	vendor$ = this._store.select(fromDashboard.getSelectedVendor);
	vendor: IVendor;

	private names;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
	) {
		super(_store, _cd);
		this.form_title = "Update Vendor";
		this.form_title_translation_key = marker("form_title_update_vendor");
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.vendor$
			.pipe(
				takeUntil(this.destroyed$),
				filter((vendor): vendor is IVendor => vendor !== undefined),
				take(1),
			)
			.subscribe((vendor) => {
				this.vendor = vendor;
				this.model.id = vendor.id;
				this.model.name = vendor.name;
				this.model.description = vendor.description;
				this.model.address = vendor.address;
				this.model.primary_phone = vendor.primary_phone;
				this.model.secondary_phone = vendor.secondary_phone;
				this.model.email = vendor.email;
				this.model.customer = vendor.customer;
				this.model.facility_vendor = vendor.facility_vendor;
				this.model.archived = vendor.archived;
				this.model.status_id = vendor.status_id;

				if (vendor.vendor_categories && vendor.vendor_categories.length > 0) {
					this.model.vendor_category_ids = vendor.vendor_categories.map(
						(vendor_category) => vendor_category.id,
					);
				}

				this._cd.detectChanges();
			});

		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`vendors/fetch/names`, "", {
				col: "name",
				except: this.vendor.name,
			})
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(type: Partial<IVendor>) {
		this.loading$.next(true);
		this._itemService
			.update(`vendor`, type.id!, type)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "vendors",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
