import {
	tap,
	take,
	takeUntil,
	switchMap,
	timeout,
	catchError,
} from "rxjs/operators";
import { IPurchaseOrder } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { EMPTY, zip } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnDestroy,
	OnInit,
	inject,
} from "@angular/core";
import { Globals } from "app/shared/modules/globals/globals.service";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "app/modules/auth/services/api.service";
import * as fromAuth from "app/modules/auth/reducers";
import { handleObservableError } from "app/shared/utils";
import { TranslateErrorService } from "app/shared/errors/handleError";
import { PurchaseOrderDetailQuery } from "app/shared/eagers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

@Component({
	selector: "purchase-order-unlock",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PurchaseOrderUnlockComponent
	extends GenericUpdateComponent<IPurchaseOrder>
	implements OnInit, OnDestroy
{
	purchase_order$ = this._store.select(fromDashboard.getSelectedPurchaseOrder);
	purchase_order: IPurchaseOrder;
	private readonly token$ = this._authStore.select(fromAuth.getToken);

	private translateErrorService = inject(TranslateErrorService);

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _authStore: Store<fromAuth.State>,
		private readonly _http: HttpClient,
		private readonly _api: ApiService,
		private readonly _globals: Globals,
	) {
		super(_store, _cd);
		this.form_title = "Unlock Purchase Order";
		this.form_title_translation_key = marker("form_title_unlock_purchase_order");
		this.submit_button = "Unlock";
		this.submit_button_translation_key = marker("word_unlock");

		this.schema = {
			title: "",
			description: ``,
			info: "",
			properties: {
				id: {
					type: "number",
					hidden: true,
				},
				reason: {
					type: "string",
					title: "Unlock Reason",
					title_translation_key: marker("form_field_label_unlock_reason"),
					widget: "textarea",
				},
			},
		};
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			this.schema.required = ["reason"];
		}

		this.purchase_order$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((purchase_order: IPurchaseOrder) => {
				this.purchase_order = purchase_order;
				this.model.id = this.purchase_order.id;
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(formData) {
		this.loading$.next(true);
		return zip(
			this.purchase_order$,
			this.token$,
			(purchase_order, token: string) => ({
				purchase_order,
				token,
			}),
		)
			.pipe(
				takeUntil(this.destroyed$),
				switchMap(({ purchase_order, token }) => {
					return this._http
						.patch(
							this._api.po.unlock(purchase_order!.id),
							{ reason: formData.reason },
							{
								headers: {
									"Content-Type": "application/json",
									Authorization: token,
								},
								params: PurchaseOrderDetailQuery,
							},
						)
						.pipe(
							timeout(10000),
							this.translateErrorService.catchAndTranslateError(
								"error_failed_to_unlock_purchase_order",
							),
							catchError((err) => {
								this.error$.next(handleObservableError(err, true));
								this.loading$.next(false);
								return EMPTY;
							}),
							tap((updatedItem) => {
								this._store.dispatch(
									ItemActions.updateSuccess({
										updatedItem,
										result_type: "purchase_orders",
									}),
								);
								this.loading$.next(false);
								this.closeSidenav();
							}),
						);
				}),
			)
			.subscribe();
	}
}
