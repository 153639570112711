import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, take, takeUntil, tap } from "rxjs/operators";
import { EMPTY, pipe } from "rxjs";
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { ILocationType } from "@elevatedsignals/amygoodman";
import { LocationTypeDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "location-type-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class LocationTypeUpdateComponent
	extends GenericUpdateComponent<ILocationType>
	implements OnInit, OnDestroy
{
	location_type$ = this._store.select(fromDashboard.getSelectedLocationType);
	location_type: ILocationType;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
	) {
		super(_store, _cd);
		this.form_title = "Update a Location Type";
		this.form_title_translation_key = marker("form_title_update_a_location_type");
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.location_type$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((location_type: ILocationType) => {
				this.location_type = location_type;
				this.model.id = this.location_type.id;
				this.model.name = this.location_type.name;
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(location: Partial<ILocationType>) {
		this.loading$.next(true);
		this._itemService
			.update(
				`location_type`,
				Number(location.id),
				location,
				LocationTypeDetailQuery,
			)
			.pipe(
				takeUntil(this.destroyed$),
				pipe(
					timeout(10000),
					catchError((error) => {
						this.error$.next(handleObservableError(error, true));
						this.loading$.next(false);
						return EMPTY;
					}),
				),
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "location_types",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
