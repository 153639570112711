/* eslint-disable @typescript-eslint/no-namespace */
import { marker } from "@jsverse/transloco-keys-manager/marker";

export namespace NewLotCreationSchema {
	export const new_lot = {
		type: "string",
		title: "Lot Tracking",
		title_translation_key: marker("form_field_label_lot_tracking"),
		widget: "select",
		oneOf: [
			{
				value: "Create New Lot",
				enum: ["Create New Lot"],
				name: "Create New Lot",
				name_translation_key: marker("form_field_value_create_new_lot"),
			},
			{
				value: "Use Existing Lot",
				enum: ["Use Existing Lot"],
				name: "Use Existing Lot",
				name_translation_key: marker("form_field_value_use_existing_lot"),
			},
		],
		default: "Create New Lot",
	};

	export const create_new_lot = {
		type: "boolean",
		title: "Creating New Lot",
		title_translation_key: marker("form_field_label_creating_new_lot"),
		widget: "checkbox",
		hidden: true,
		default: true,
		visibleIf: {
			new_lot: ["Create New Lot"],
		},
	};

	export const lot_name = {
		type: "string",
		title: "New Lot Name",
		title_translation_key: marker("form_field_label_new_lot_name"),
		description: "Generated if left blank",
		description_translation_key: marker(
			"form_field_description_generated_if_left_blank",
		),
		widget: "string",
		visibleIf: {
			create_new_lot: [true],
		},
	};

	export const lot_id = (queryString: {
		input?: "true" | "false";
		dont_show_expired?: "true" | "false";
		non_zero_inventory?: "true" | "false";
	}) => ({
		type: "number",
		title: "Destination Lot",
		title_translation_key: marker("form_field_label_destination_lot"),
		widget: "data-select",
		quick_create: false,
		related_properties: [],
		oneOf: [
			{
				result_type: "lots",
				queryString,
			},
		],
		visibleIf: {
			new_lot: ["Use Existing Lot"],
		},
	});
}
