import { tap, take, takeUntil, timeout, catchError } from "rxjs/operators";
import {
	Component,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
	Injector,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ReplaySubject, EMPTY } from "rxjs";
import { Plant } from "@elevatedsignals/amygoodman";
import { PlantRelativesQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

import { PlantPropagationWorkOrderSchema } from "./schemas/plant-propagation";

@Component({
	selector: "plant-cuttings",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PlantCuttingsComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	plant$ = this._store.select(fromDashboard.getSelectedPlant);
	plant: Plant;

	form_title = "Take cuttings from a mother";
	form_title_translation_key: string = marker(
		"form_title_take_cuttings_from_a_mother",
	);

	submit_button = "Take Cuttings";
	submit_button_translation_key: string = marker("form_button_take_cuttings");
	submit_icon = "plus";
	loading = false;
	schema: any;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {
		location: "BATCH",
	};

	validators = {};

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _injector: Injector,
	) {
		this.schema = PlantPropagationWorkOrderSchema;
	}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		this.plant$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((plant) => {
				this.plant = plant;
				this.model = {
					id: this.plant.id,
				};
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const cuttings: any = {
			...this.model,
		};
		this.cuttingsRequest(cuttings);
	}

	onChanges(_form_data): void {}

	cuttingsRequest(cuttings_model: any) {
		this.loading$.next(true);
		this._itemService
			.update(
				`plant`,
				`${this.plant.id}/cuttings`,
				cuttings_model,
				PlantRelativesQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "plants",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
