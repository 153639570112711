import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { timeout, catchError, takeUntil, switchMap } from "rxjs/operators";
import { EMPTY, ReplaySubject } from "rxjs";
import {
	Component,
	OnInit,
	ChangeDetectorRef,
	OnDestroy,
	inject,
} from "@angular/core";
import FileSaver from "file-saver";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "app/modules/auth/services/api.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { PagerService } from "app/modules/dashboard/services/pager.service";
import { FetchPageActions } from "app/modules/dashboard/actions/paging.actions";
import { DateFormatsForDateFns } from "app/shared/time-format";
import { DateFormatService } from "app/modules/dashboard/services/date-format/dateFormat.service";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "inventory-product-csv-upload",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class InventoryProductCSVUploadComponent implements OnDestroy, OnInit {
	valid$: ReplaySubject<boolean> = new ReplaySubject();
	error$: ReplaySubject<string> = new ReplaySubject();

	destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

	form_title = "Inventory Product CSV Upload";
	form_title_translation_key = marker("form_title_inventory_product_csv_upload");
	submit_button = "Upload";
	submit_button_translation_key = marker("word_upload");

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	public model: any = {};

	schema: any = {
		title: "",
		info: "",
		properties: {
			file: {
				type: "array",
				widget: "file-upload-deferred",
				title: "Upload CSV",
				description: `Your CSV should be formatted with the column header 'name'. Any format or upload issues will abort the entire upload. The result of this upload will be sent to you back as a file.`,
				description_translation_key: marker(
					`form_description_inventory_product_csv_upload`,
				),
				items: {
					type: "object",
					properties: {
						name: {
							type: "string",
						},
						type: {
							type: "string",
						},
						data: {
							type: "string",
						},
						link: {
							type: "string",
						},
					},
				},
			},
		},
		anyOf: [
			{
				required: ["file"],
			},
		],
	};

	private readonly _pagerService = inject(PagerService);

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _http: HttpClient,
		private readonly _api: ApiService,
		private readonly _dateFormatService: DateFormatService,
	) {}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {}

	onChanges(_model): void {}

	onError(_error): void {}

	ngOnDestroy() {
		this.valid$.complete();
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		this.loading$.next(true);

		this._http
			.post(this._api.items.upload_csv("inventory_products"), this.model, {
				responseType: "blob",
			})
			.pipe(
				takeUntil(this.destroyed$),
				timeout(60000),
				catchError((_error) => {
					// const blob = new Blob([error.error], { type: "application/json" });

					// blob.text().then((value) =>
					// 	this.error$.next(
					// 		handleObservableError(JSON.parse(value).human_message, true)
					// 	)
					// )
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((res) => {
				const blob = new Blob([res as BlobPart], { type: "text/csv" });
				const momentAt = this._dateFormatService.formatWithDateFns(
					new Date(),
					DateFormatsForDateFns.monthDayYearTime24HourWithDashes,
				);
				FileSaver.saveAs(blob, `inventory_product_upload_results-${momentAt}.csv`);

				this._store
					.select(fromDashboard.getInventoryProductPage)
					.pipe(
						takeUntil(this.destroyed$),
						isNotNullOrUndefined(),
						switchMap((page) => {
							return this._pagerService.fetchPage(page);
						}),
					)
					.subscribe((updatedPage) => {
						this._store.dispatch(
							FetchPageActions.fetchPageSuccess({
								payload: updatedPage,
							}),
						);
					});
				this.loading$.next(false);
				this.closeSidenav();
			});
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
