import {
	tap,
	take,
	takeUntil,
	timeout,
	catchError,
	skipWhile,
} from "rxjs/operators";
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { BatchModels, Batch, UserProfile } from "@elevatedsignals/amygoodman";
import { ReplaySubject, EMPTY, Observable } from "rxjs";
import { BatchDetailQuery } from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { PasswordInputComponent } from "app/modules/auth/components";
import { ESValidator } from "app/shared/es-validator";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { blockDuplicateBatchNames } from "app/modules/dashboard/selectors/facility-settings.selector";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

@Component({
	selector: "batch-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class BatchUpdateComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	batch$ = this._store.select(fromDashboard.getSelectedBatch);
	blockDuplicateBatchNames$ = this._store.select(blockDuplicateBatchNames);
	batch: Batch;
	user$: Observable<UserProfile | null> = this._store.select(
		fromDashboard.getProfile,
	);

	user: UserProfile;

	form_title = "Update a Batch";
	form_title_translation_key: string = marker("form_title_update_a_batch");
	submit_button = "Update Batch";
	submit_button_translation_key: string = marker("form_button_update_batch");
	submit_icon = "edit";

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "",
				error: "",
				warning_translation_key: marker(
					"form_field_warning_batch_name_already_in_use",
				),
			},
			cultivar_id: {
				type: "number",
				title: "Cultivar",
				title_translation_key: marker("word_cultivar"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "cultivars",
					},
				],
				// readOnly: true, // Allow updates of batch cultivar
			},
			production_stage_id: {
				type: "number",
				title: "Production Stage",
				title_translation_key: marker("word_production_stage"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "production_stages",
					},
				],
			},
			batch_type_id: {
				type: "number",
				title: "Batch Type",
				title_translation_key: marker("word_batch_type"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "batch_types",
					},
				],
			},
			expiry_date: {
				type: "string",
				title: "Expiry Date",
				title_translation_key: marker("form_field_label_expiry_date"),
				widget: "date",
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
			effective_date: {
				type: "string",
				title: "Date/Time batch created.",
				title_translation_key: marker("form_field_label_date_time_batch_created"),
				description: "- optional",
				description_translation_key: marker("form_field_description_optional"),
				widget: "date",
			},
			timestamp: {
				type: "string",
				title: "Date/time batch updated.",
				title_translation_key: marker("form_field_label_date_time_batch_updated"),
				widget: "date",
			},
			reason: {
				type: "string",
				title: "Update Reason",
				title_translation_key: marker("form_field_label_update_reason"),
				widget: "textarea",
			},
		},
		required: ["name", "cultivar_id"],
	};

	validators: Record<string, ESValidator> = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return [{ name: {} }];
				}
			}
			return null;
		},
	};

	private names;

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _globals: Globals,
	) {}

	ngOnInit() {
		this.blockDuplicateBatchNames$.subscribe((block) => {
			if (block) {
				this.schema.properties.name.error = "Warning: Batch name already in use.";
			} else {
				this.schema.properties.name.warning = "Warning: Batch name already in use.";
			}
		});

		if (this._globals.gmp_enabled) {
			delete (this.schema.properties as any).timestamp;
			delete (this.schema.properties as any).effective_date;
		}

		this.user$
			.pipe(
				takeUntil(this.destroyed$),
				skipWhile((user) => user == null),
			)
			.subscribe((user) => {
				this.user = user!;
			});

		this.batch$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((batch: Batch) => {
				this.batch = batch;
				// let growth_stage_ids;
				// if (this.batch.growth_stages && this.batch.growth_stages.length > 0) {
				// 	growth_stage_ids = this.batch.growth_stages.map(stage => stage.id);
				// }
				this.model = {
					...this.model,
					id: this.batch.id,
					name: this.batch.name,
					description: this.batch.description,
					cultivar_id: this.batch.cultivar_id,
					// growth_stage_id: growth_stage_ids ? growth_stage_ids[0] : null,
					production_stage_id: this.batch.production_stage_id,
					batch_type_id: this.batch.batch_type_id,
					// location_id: this.batch.location_id,
					effective_date: this.batch.effective_date ?? this.batch.created_at,
					expiry_date: this.batch.expiry_date,
				};
				// if (this.batch.plant_count > 0) {
				// 	delete this.schema.properties.growth_stage_id.visibleIf;
				// }
				this._cd.detectChanges();
			});

		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`batches/fetch/names`, "", {
				col: "name",
				except: this.batch.name,
			}) // for editing a batch, we will also pass in current batch's name so that validator doesn't fail
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// This is so we can check in validators
				this.names = value;
			});

		this.loading$.next(false);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const batch: BatchModels.BatchUpdate = {
			description: "",
			...this.model,
		};

		if (!batch.production_stage_id) {
			batch.production_stage_id = null;
		}

		if (!batch.batch_type_id) {
			batch.batch_type_id = null;
		}

		if (!batch.expiry_date) {
			batch.expiry_date = null;
		}

		if (this._globals.gmp_enabled) {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: PasswordInputComponent,
					inputs: {
						onSubmit: (password) => {
							this.updateBatch(batch, true, password);
						},
					},
				}),
			);
		} else {
			this.updateBatch(batch);
		}
	}

	onChanges(_error): void {}

	valid(val) {
		this.valid$.next(val);
	}

	updateBatch(
		update_batch: BatchModels.BatchUpdate,
		signing = false,
		password?: string,
	) {
		const authObject = password
			? { username: this.user.email, password }
			: undefined;
		this.loading$.next(true);
		this._itemService
			.update(
				`batch`,
				Number(this.batch.id),
				update_batch,
				{ ...BatchDetailQuery, signing: signing.toString() },
				authObject,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "batches",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
