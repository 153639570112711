import { Component, Input, OnInit, Injector } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { Store } from "@ngrx/store";
import { TranslocoService } from "@jsverse/transloco";
import { DashboardState } from "app/modules/dashboard/state/dashboard.state";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { getFacilitySettings } from "app/modules/auth/selectors/dashboard.selector";
import { map } from "rxjs";

@Component({
	selector: "password-input",
	template: `
		<ng-container *transloco="let t; read: 'password_input_component'">
			<div class="sixteen wide column">
				<div class="left floated left aligned eight wide column">
					<button class="ui labeled icon button" (click)="closeSidenav()">
						<i class="left arrow icon"></i>
						{{ "word_cancel" | transloco }}
					</button>
				</div>
			</div>
			<div *ngIf="destruction_witness" class="title">
				<h4>{{ witnessStatement$ | async }}</h4>
			</div>
			<div class="title">
				<h3>{{ t("please_enter_your_password_to_confirm_this_action") }}</h3>
			</div>

			<form [formGroup]="passwordForm" (ngSubmit)="onSubmit()" class="ui form">
				<div class="field login">
					<input
						required
						formControlName="password"
						type="password"
						placeholder="{{ 'word_password' | transloco }}"
						name="password"
					/>
				</div>

				<div *ngIf="errorMessage" class="ui negative message">
					<p>{{ errorMessage }}</p>
				</div>

				<button
					class="ui"
					[ngClass]="pending ? 'loading  disabled blue login' : 'button blue login'"
					type="submit"
					[disabled]="!passwordForm.valid"
				>
					{{ t("sign_document") }}
				</button>
			</form>
		</ng-container>
	`,

	styleUrls: ["./index.scss"],
})
export class PasswordInputComponent implements OnInit {
	passwordForm: UntypedFormGroup;
	parentSubmit;

	@Input() errorMessage: string;
	@Input() pending: boolean;

	public destruction_witness: boolean;

	witnessStatement$ = this._store.select(getFacilitySettings).pipe(
		map((settings) => {
			if (settings.data?.options?.witness_statement) {
				return settings.data.options.witness_statement;
			}
			return this.defaultWitnessStatement;
		}),
	);

	private readonly defaultWitnessStatement = this._translocoService.translate(
		"password_input_component.please_update_your_destruction_witness_statement_in_facility_settings",
	);

	constructor(
		private readonly _store: Store<DashboardState>,
		private readonly _fb: UntypedFormBuilder,
		private readonly _injector: Injector,
		private readonly _translocoService: TranslocoService,
	) {}

	ngOnInit() {
		this.destruction_witness = this._injector.get("destruction_witness", null);
		this.passwordForm = this._fb.group({
			password: [null],
		});
		this.parentSubmit = this._injector.get("onSubmit", null);
	}

	onSubmit() {
		if (this.passwordForm.valid) {
			this.parentSubmit(this.passwordForm.value.password);
			this.closeSidenav();
		}
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
