import {
	Component,
	Injector,
	ChangeDetectorRef,
	OnDestroy,
	OnInit,
} from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import {
	FuiActiveModal,
	FuiModalService,
} from "@elevatedsignals/ngx-fomantic-ui";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { EMPTY } from "rxjs";
import { IPrinterJob } from "@elevatedsignals/amygoodman";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { DevCycleService } from "app/devcycle/devcycle.service";
import { DevCycleKey } from "app/devcycle/devcycleKeys";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";
import { LabelPreviewModal, PreviewPrintJobConfig } from "../../modals";

@Component({
	selector: "custom-printer-job-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class CustomPrinterJobCreateComponent
	extends GenericCreateComponent<IPrinterJob>
	implements OnDestroy, OnInit
{
	// Base list of options, applicable to both substance types and GI
	entityOptions: { name: string; value: string; t_key: string }[] = [
		{ name: "Batch", value: "batch", t_key: marker("word_batch") },
		{ name: "Plant", value: "plant", t_key: marker("word_plant") },
		{ name: "Location", value: "location", t_key: marker("word_location") },
		{ name: "Work Order", value: "work_order", t_key: marker("word_work_order") },
		{
			name: "Destruction Lot",
			value: "destruction_lot",
			t_key: marker("word_destruction_lot"),
		},
	];

	// Extra options that we can support for GI facilities
	additionalGiOptions: { name: string; value: string; t_key: string }[] = [
		{ name: "Inventory Lot", value: "lot", t_key: marker("word_inventory_lot") },
		{ name: "Inventory", value: "inventory", t_key: marker("word_inventory") },
	];

	barcodeTypeOptions = [
		{ name: "Batch", value: "batch", t_key: marker("word_batch") },
		{ name: "Plant", value: "plant", t_key: marker("word_plant") },
	];

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			entity_type: {
				type: "string",
				title: "Print Label for",
				title_translation_key: marker("form_field_label_print_label_for"),
				widget: "select",
				oneOf: this.entityOptions.map((item) => {
					return {
						name: item.name,
						name_translation_key: item.t_key,
						value: item.value,
						enum: [item.value],
					};
				}),
			},
			batch_label_options: {
				type: "string",
				title: "Batch Print Options",
				title_translation_key: marker("form_field_label_batch_print_options"),
				widget: "radio",
				oneOf: [
					{
						name: "Print Label for Batch",
						name_translation_key: marker("form_field_value_print_label_for_batch"),
						value: "BATCH_LABEL",
						enum: ["BATCH_LABEL"],
					},
					{
						name: "Print Labels for all Plants in Batch",
						name_translation_key: marker(
							"form_field_value_print_labels_for_all_plants_in_batch",
						),
						value: "ALL_PLANTS_IN_BATCH",
						enum: ["ALL_PLANTS_IN_BATCH"],
					},
					{
						name: "Print Labels for some Plants in Batch",
						name_translation_key: marker(
							"form_field_value_print_labels_for_some_plants_in_batch",
						),
						value: "SOME_PLANTS_IN_BATCH",
						enum: ["SOME_PLANTS_IN_BATCH"],
					},
					{
						name: "Print Labels for all Lots in Batch",
						name_translation_key: marker(
							"form_field_value_print_labels_for_all_lots_in_batch",
						),
						value: "ALL_LOTS_IN_BATCH",
						enum: ["ALL_LOTS_IN_BATCH"],
					},
					{
						name: "Print Labels for some Lots in Batch",
						name_translation_key: marker(
							"form_field_value_print_labels_for_some_lots_in_batch",
						),
						value: "SOME_LOTS_IN_BATCH",
						enum: ["SOME_LOTS_IN_BATCH"],
					},
				],
				visibleIf: { entity_type: ["batch"] },
			},
			lot_label_options: {
				type: "string",
				title: "Inventory Lot Print Options",
				title_translation_key: marker(
					"form_field_label_inventory_lot_print_options",
				),
				widget: "radio",
				oneOf: [
					{
						name: "Print Label for Lot",
						name_translation_key: marker("form_field_value_print_label_for_lot"),
						value: "LOT_LABEL",
						enum: ["LOT_LABEL"],
					},
					{
						name: "Print Labels for all Inventory in Lot",
						name_translation_key: marker(
							"form_field_value_print_labels_for_all_inventory_in_lot",
						),
						value: "ALL_INVENTORY_IN_LOT",
						enum: ["ALL_INVENTORY_IN_LOT"],
					},
					{
						name: "Print Labels for some Inventory in Lot",
						name_translation_key: marker(
							"form_field_value_print_labels_for_some_inventory_in_lot",
						),
						value: "SOME_INVENTORY_IN_LOT",
						enum: ["SOME_INVENTORY_IN_LOT"],
					},
				],
				visibleIf: { entity_type: ["lot"] },
			},
			destruction_lot_label_options: {
				type: "string",
				title: "Destruction Lot Print Options",
				title_translation_key: marker(
					"form_field_label_destruction_lot_print_options",
				),
				widget: "radio",
				oneOf: [
					{
						name: "Print Label for Destruction Lot",
						name_translation_key: marker(
							"form_field_value_print_label_for_destruction_lot",
						),
						value: "DESTRUCTION_LOT_LABEL",
						enum: ["DESTRUCTION_LOT_LABEL"],
					},
					{
						name: "Print Label for one Destruction Event",
						name_translation_key: marker(
							"form_field_value_print_label_for_one_destruction_event",
						),
						value: "ONE_DESTRUCTION_EVENT",
						enum: ["ONE_DESTRUCTION_EVENT"],
					},
				],
				visibleIf: {
					entity_type: ["destruction_lot"],
				},
			},
			work_order_label_options: {
				type: "string",
				title: "Work Order Print Options",
				title_translation_key: marker("form_field_label_work_order_print_options"),
				widget: "radio",
				oneOf: [
					{
						name: "Print Label for Work Order",
						name_translation_key: marker(
							"form_field_value_print_label_for_work_order",
						),
						value: "WORK_ORDER_LABEL",
						enum: ["WORK_ORDER_LABEL"],
					},
					{
						name: "Print Label for one Work Order Output",
						name_translation_key: marker(
							"form_field_value_print_label_for_one_work_order_output",
						),
						value: "ONE_WORK_ORDER_OUTPUT",
						enum: ["ONE_WORK_ORDER_OUTPUT"],
					},
					{
						name: "Print Labels for all Work Order Outputs",
						name_translation_key: marker(
							"form_field_value_print_labels_for_all_work_order_outputs",
						),
						value: "ALL_WORK_ORDER_OUTPUTS",
						enum: ["ALL_WORK_ORDER_OUTPUTS"],
					},
				],
				visibleIf: {
					entity_type: ["work_order"],
				},
			},
			batch_id: {
				type: "number",
				title: "Batch",
				title_translation_key: marker("word_batch"),
				widget: "data-select",
				quick_create: false,
				oneOf: [
					{
						result_type: "batches",
					},
				],
				visibleIf: {
					entity_type: ["batch"],
				},
			},
			batch_plant_ids: {
				type: "array",
				title: "Batch Plant IDs",
				title_translation_key: marker("form_field_label_batch_plant_ids"),
				widget: "data-select",
				quick_create: false,
				related_properties: ["batch_id"],
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "plants",
							queryString: {
								plant_status: "ACTIVE",
							},
						},
					],
				},
				visibleIf: {
					batch_label_options: ["SOME_PLANTS_IN_BATCH"],
				},
			},
			batch_lot_ids: {
				type: "array",
				title: "Batch Lot IDs",
				title_translation_key: marker("form_field_label_batch_lot_ids"),
				widget: "data-select",
				quick_create: false,
				related_properties: ["batch_id"],
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "lots",
						},
					],
				},
				visibleIf: {
					batch_label_options: ["SOME_LOTS_IN_BATCH"],
				},
			},
			plant_id: {
				type: "number",
				title: "Plant",
				title_translation_key: marker("word_plant"),
				widget: "data-select",
				quick_create: false,
				oneOf: [
					{
						result_type: "plants",
					},
				],
				visibleIf: { entity_type: ["plant"] },
			},
			work_order_id: {
				type: "number",
				title: "Work Order",
				title_translation_key: marker("word_work_order"),
				widget: "data-select",
				quick_create: false,
				oneOf: [
					{
						result_type: "work_orders",
					},
				],
				visibleIf: { entity_type: ["work_order"] },
			},
			work_order_output_id: {
				type: "number",
				title: "Work Order Output",
				title_translation_key: marker("word_work_order_output"),
				widget: "data-select",
				quick_create: false,
				related_properties: ["work_order_id"],
				oneOf: [
					{
						result_type: "work_order_outputs",
					},
				],
				// For some reason this doesn't work if using both entity_type: ["work_order"]
				// and the work_order_options: ["ONE_WORK_ORDER_OUTPUT"]
				// Since work_order_label_options already needs entity_type: ["work_order"] to be visible
				// we can get around this weird behavior by only using work_order_label_options
				visibleIf: {
					work_order_label_options: ["ONE_WORK_ORDER_OUTPUT"],
				},
			},
			destruction_lot_id: {
				type: "number",
				title: "Destruction Lot",
				title_translation_key: marker("word_destruction_lot"),
				widget: "data-select",
				quick_create: false,
				oneOf: [
					{
						result_type: "destruction_lots",
					},
				],
				visibleIf: { entity_type: ["destruction_lot"] },
			},
			destruction_event_id: {
				type: "number",
				title: "Destruction Event",
				title_translation_key: marker("word_destruction_event"),
				widget: "data-select",
				quick_create: false,
				related_properties: ["destruction_lot_id"],
				oneOf: [
					{
						result_type: "destruction_events",
					},
				],
				visibleIf: {
					destruction_lot_label_options: ["ONE_DESTRUCTION_EVENT"],
				},
			},
			lot_id: {
				type: "number",
				title: "Lot",
				title_translation_key: marker("word_lot"),
				widget: "data-select",
				quick_create: false,
				oneOf: [
					{
						result_type: "lots",
					},
				],
				visibleIf: { entity_type: ["lot"] },
			},
			lot_inventory_ids: {
				type: "array",
				title: "Lot Inventory IDs",
				title_translation_key: marker("form_field_label_lot_inventory_ids"),
				widget: "data-select",
				quick_create: false,
				related_properties: ["lot_id"],
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventories",
						},
					],
				},
				visibleIf: {
					lot_label_options: ["SOME_INVENTORY_IN_LOT"],
				},
			},
			inventory_id: {
				type: "number",
				title: "Inventory",
				title_translation_key: marker("word_inventory"),
				widget: "data-select",
				quick_create: false,
				oneOf: [
					{
						result_type: "inventories",
					},
				],
				visibleIf: { entity_type: ["inventory"] },
			},
			location_id: {
				type: "number",
				title: "Location",
				title_translation_key: marker("word_location"),
				widget: "data-select",
				quick_create: false,
				oneOf: [
					{
						result_type: "locations",
					},
				],
				visibleIf: { entity_type: ["location"] },
			},
			// TODO: This should be removed in favour of users creating their own
			// QR code ZPL commands
			barcode_type: {
				type: "string",
				title: "Barcode Type",
				title_translation_key: marker("form_field_label_barcode_type"),
				widget: "select",
				oneOf: this.barcodeTypeOptions.map((item) => {
					return {
						name: item.name,
						name_translation_key: item.t_key,
						value: item.value,
						enum: [item.value],
					};
				}),
				visibleIf: {
					batch_template: [true],
					plant_template: [true],
				},
			},
			printer_id: {
				type: "number",
				title: "Printer",
				title_translation_key: marker("word_printer"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "printers",
					},
				],
				visibleIf: {
					has_valid_label_options_for_template: [true],
				},
			},
			printer_job_template_id: {
				type: "number",
				title: "Printer Job Template",
				title_translation_key: marker("word_printer_job_template"),
				widget: "data-select",
				related_properties: [
					"printer_id",
					"batch_template",
					"plant_template",
					"work_order_template",
					"work_order_output_template",
					"destruction_lot_template",
					"destruction_event_template",
					"inventory_lot_template",
					"inventory_template",
					"location_template",
				],
				oneOf: [
					{
						result_type: "printer_job_templates",
						queryString: {
							include_default_templates: true,
						},
					},
				],
				visibleIf: {
					allOf: [
						{ printer_id: [true] },
						{ has_valid_label_options_for_template: [true] },
					],
				},
			},
			label_quantity: {
				type: "number",
				widget: "number",
				title: "Label Quantity",
				title_translation_key: marker("form_field_label_label_quantity"),
				visibleIf: {
					oneOf: [
						// ESS-7201: Allow the user to print multiple 'copies' of a label
						// if they are printing for a single entity
						{ batch_label_options: ["BATCH_LABEL"] },
						{ entity_type: ["plant"] },
						{ entity_type: ["location"] },
						{ lot_label_options: ["LOT_LABEL"] },
						{ entity_type: ["inventory"] },
						{ work_order_label_options: ["WORK_ORDER_LABEL"] },
						{ destruction_lot_label_options: ["DESTRUCTION_LOT_LABEL"] },
					],
				},
			},
			// Hidden hacky fields to help with visibility
			batch_template: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: true,
				visibleIf: {
					batch_label_options: ["BATCH_LABEL"],
				},
			},
			plant_template: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: true,
				visibleIf: {
					entity_type: ["plant"],
					batch_label_options: ["SOME_PLANTS_IN_BATCH", "ALL_PLANTS_IN_BATCH"],
				},
			},
			work_order_template: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: true,
				visibleIf: {
					work_order_label_options: ["WORK_ORDER_LABEL"],
				},
			},
			work_order_output_template: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: true,
				visibleIf: {
					work_order_label_options: [
						"ONE_WORK_ORDER_OUTPUT",
						"ALL_WORK_ORDER_OUTPUTS",
					],
				},
			},
			destruction_lot_template: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: true,
				visibleIf: {
					destruction_lot_label_options: ["DESTRUCTION_LOT_LABEL"],
				},
			},
			destruction_event_template: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: true,
				visibleIf: {
					destruction_lot_label_options: [
						"ONE_DESTRUCTION_EVENT",
						"ALL_DESTRUCTION_EVENTS",
					],
				},
			},
			inventory_lot_template: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: true,
				visibleIf: {
					lot_label_options: ["LOT_LABEL"],
					batch_label_options: ["SOME_LOTS_IN_BATCH", "ALL_LOTS_IN_BATCH"],
				},
			},
			inventory_template: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: true,
				visibleIf: {
					entity_type: ["inventory"],
					lot_label_options: ["ALL_INVENTORY_IN_LOT", "SOME_INVENTORY_IN_LOT"],
				},
			},
			location_template: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: true,
				visibleIf: {
					entity_type: ["location"],
				},
			},
			has_valid_label_options_for_template: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: true,
				visibleIf: {
					batch_template: [true],
					plant_template: [true],
					work_order_template: [true],
					work_order_output_template: [true],
					destruction_lot_template: [true],
					destruction_event_template: [true],
					inventory_lot_template: [true],
					inventory_template: [true],
					location_template: [true],
				},
			},
			start_range: {
				type: "number",
				widget: "number",
				title: "Start #",
				hidden: true,
				title_translation_key: marker("form_field_label_label_start_range"),
			},
			end_range: {
				type: "number",
				widget: "number",
				title: "End #",
				hidden: true,
				title_translation_key: marker("form_field_label_label_end_range"),
			},
		},
		required: ["printer_id", "printer_job_template_id", "entity_type"],
	};

	validators = {};

	model: any = {};

	showOutputLabelRange = false;

	previewModalOpen = false;
	refreshModal: FuiActiveModal<string, unknown, void>;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
		private readonly _cd: ChangeDetectorRef,
		private readonly _globals: Globals,
		private readonly _devCycleService: DevCycleService,
		private readonly _modalService: FuiModalService,
	) {
		super(_store);
		this.form_title = "Create a Printer Job";
		this.form_title_translation_key = marker("form_title_create_a_printer_job");
		this.submit_button = "Preview Job";
		this.submit_button_translation_key = marker("form_button_preview_job");

		this._globals.general_inventory_enabled_replay.subscribe((gi_enabled) => {
			if (gi_enabled) {
				this.schema.properties.entity_type.oneOf = [
					...this.entityOptions,
					...this.additionalGiOptions,
				].map((item) => {
					return {
						name: item.name,
						name_translation_key: item.t_key,
						value: item.value,
						enum: [item.value],
					};
				});
			}
		});
	}

	ngOnInit() {
		const entity_type: string | null = this._injector.get("entity_type", null);
		const printer_id: number | null = this._injector.get("printer_id", null);
		const batch_id: number | null = this._injector.get("batch_id", null);
		const plant_id: number | null = this._injector.get("plant_id", null);
		const lot_id: number | null = this._injector.get("lot_id", null);
		const batch_label_options: string | null = this._injector.get(
			"batch_label_options",
			null,
		);
		const inventory_id: number | null = this._injector.get("inventory_id", null);
		const lot_label_options: string | null = this._injector.get(
			"lot_label_options",
			null,
		);
		const work_order_output_id: number | null = this._injector.get(
			"work_order_output_id",
			null,
		);
		const work_order_id: number | null = this._injector.get(
			"work_order_id",
			null,
		);
		const work_order_label_options: string | null = this._injector.get(
			"work_order_label_options",
			null,
		);
		const printer_job_template_id: number | null = this._injector.get(
			"printer_job_template_id",
			null,
		);
		const destruction_lot_id: number | null = this._injector.get(
			"destruction_lot_id",
			null,
		);
		const destruction_lot_label_options: string | null = this._injector.get(
			"destruction_lot_label_options",
			null,
		);
		const destruction_event_id: number | null = this._injector.get(
			"destruction_event_id",
			null,
		);
		const location_id: number | null = this._injector.get("location_id", null);

		// If we should set the entity and entity type fields to be readOnly
		const lockEntityAndEntityType: boolean = this._injector.get(
			"lock_entity_and_entity_type",
			false,
		);

		if (lockEntityAndEntityType) {
			// Set Entity type selector to read only
			this.schema.properties.entity_type.readOnly = true;

			// Set entity type options selector to read only
			this.schema.properties.batch_label_options.readOnly = true;
			this.schema.properties.lot_label_options.readOnly = true;
			this.schema.properties.work_order_label_options.readOnly = true;
			this.schema.properties.destruction_lot_label_options.readOnly = true;

			// Set individual entity data selects to read only
			this.schema.properties.batch_id.readOnly = true;
			this.schema.properties.plant_id.readOnly = true;
			this.schema.properties.lot_id.readOnly = true;
			this.schema.properties.inventory_id.readOnly = true;
			this.schema.properties.work_order_id.readOnly = true;
			this.schema.properties.work_order_output_id.readOnly = true;
			this.schema.properties.destruction_lot_id.readOnly = true;
			this.schema.properties.destruction_event_id.readOnly = true;
			this.schema.properties.location_id.readOnly = true;
		}

		this.model = {
			...this.model,
			...(printer_id && { printer_id }),
			...(printer_job_template_id && { printer_job_template_id }),
			...(entity_type && { entity_type }),
			...(batch_id && { batch_id }),
			...(batch_label_options && { batch_label_options }),
			...(plant_id && { plant_id }),
			...(lot_id && { lot_id }),
			...(lot_label_options && { lot_label_options }),
			...(inventory_id && { inventory_id }),
			...(work_order_id && { work_order_id }),
			...(work_order_label_options && { work_order_label_options }),
			...(work_order_output_id && { work_order_output_id }),
			...(destruction_lot_id && { destruction_lot_id }),
			...(destruction_lot_label_options && { destruction_lot_label_options }),
			...(destruction_event_id && { destruction_event_id }),
			...(location_id && { location_id }),
			label_quantity: 1,
		};

		this._devCycleService
			.getVariable(DevCycleKey.WorkOrderOutputLabelRange, false)
			.subscribe((variable: any) => {
				this.showOutputLabelRange = variable.value;
				if (this.showOutputLabelRange) {
					this.model = {
						...this.model,
						start_range: 1,
						end_range: 1,
					};
					this.schema.properties.start_range.hidden = false;
					this.schema.properties.end_range.hidden = false;
				}
			});

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(theChanges) {
		// Clear out the printer_job_template_id field when the print option changes
		const batchLabelOptionsChanged =
			theChanges.batch_label_options &&
			this.model.batch_label_options &&
			theChanges.batch_label_options !== this.model.batch_label_options;
		const lotLabelOptionsChanged =
			theChanges.lot_label_options &&
			this.model.lot_label_options &&
			theChanges.lot_label_options !== this.model.lot_label_options;
		const destructionLotOptionsChanged =
			theChanges.destruction_lot_label_options &&
			this.model.destruction_lot_label_options &&
			theChanges.destruction_lot_label_options !==
				this.model.destruction_lot_label_options;
		const workOrderLabelOptionsChanged =
			theChanges.work_order_label_options &&
			this.model.work_order_label_options &&
			theChanges.work_order_label_options !== this.model.work_order_label_options;

		if (
			batchLabelOptionsChanged ||
			lotLabelOptionsChanged ||
			destructionLotOptionsChanged ||
			workOrderLabelOptionsChanged
		) {
			this.model = theChanges;
			this.model.printer_job_template_id = null;
			this._cd.detectChanges();
		}
	}

	onSubmit() {
		const printer_job: Partial<IPrinterJob> = {
			...this.model,
		};
		this.previewAndCreateItem(printer_job, true);
	}

	openPreviewModal(printPreviewJobConfig: PreviewPrintJobConfig) {
		this._modalService
			.open(new LabelPreviewModal(printPreviewJobConfig))
			.onApprove(() => {
				const printer_job: Partial<IPrinterJob> = {
					...this.model,
				};
				this.previewAndCreateItem(printer_job, false);
			});
	}

	previewAndCreateItem(
		printer_job: Partial<IPrinterJob>,
		preview_only: boolean,
	) {
		this.loading$.next(true);

		// Get the printer label template first, need its properties for the previews
		this._itemService
			.fetchItem("printer_job_template", `${this.model.printer_job_template_id}`)
			.pipe(
				takeUntil(this.destroyed$),
				timeout(30000),
				catchError((error) => {
					/* eslint no-console: off */
					console.error(error);
					return EMPTY;
				}),
			)
			.subscribe((printerJobTemplate) => {
				this._itemService
					.add(`printers/${printer_job.printer_id}/custom_job`, {
						...printer_job,
						entity_type: this.model.entity_type,
						preview_only,
					})
					.pipe(takeUntil(this.destroyed$))
					.pipe(
						timeout(30000),
						catchError((err) => {
							this.error$.next(handleObservableError(err, true));
							this.loading$.next(false);
							return EMPTY;
						}),
					)
					.pipe(
						tap((addedItem) => {
							this.loading$.next(false);

							// addedItem is a string if previewing
							if (preview_only) {
								this._itemService
									.fetchItem("printer", `${this.model.printer_id}`)
									.pipe(
										takeUntil(this.destroyed$),
										timeout(30000),
										catchError((error) => {
											/* eslint no-console: off */
											console.error(error);
											return EMPTY;
										}),
									)
									.subscribe((printerForTemplate) => {
										let labelWidth = printerJobTemplate.label_width;
										let labelHeight = printerJobTemplate.label_height;

										// ESS-7444: Rotated labels need special handling for previews to work correctly.
										// The api will have already performed the rotation and arranging based on how
										// many labels per row and the size of the label we just need to adjust the preview window
										if (printerJobTemplate.rotated) {
											// Label height is only determined by the custom template's width
											labelHeight = printerJobTemplate.label_width;

											// Label width is determined by the custom template's height,
											// multiplied by how many labels per row the printer is printing

											const printerLabelsPerRow = printerForTemplate.labels_per_row ?? 4;
											labelWidth = printerJobTemplate.label_height * printerLabelsPerRow;
										}

										const printPreviewJobConfig: PreviewPrintJobConfig = {
											processedTemplate: addedItem,
											labelHeight,
											labelWidth,
											printerDpi: printerJobTemplate.label_dpi,
										};

										this.openPreviewModal(printPreviewJobConfig);
									});
							} else {
								this._store.dispatch(
									ItemActions.addSuccess({
										addedItem,
										result_type: "printer_jobs",
									}),
								);
								// only close when print jobs are actually created
								this.closeSidenav();
							}
						}),
					)
					.subscribe();
			});
	}

	// Not used but kept around so that this component can continue
	// extending the GenericCreateComponent class
	createItem(_printer_job: Partial<IPrinterJob>) {}
}
