import { ITag } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.Tags;

export type State = SelectionState<ITag>;

export const reducer = createSelectionReducer<ITag>(reducerKey);
