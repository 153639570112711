import { NgModuleRef, enableProdMode } from "@angular/core";
import { disableDebugTools } from "@angular/platform-browser";

import { Environment } from "./model";

enableProdMode();

export const environment = {
	// ...devEnvironment.environment, // Extend dev env
	production: true,
	host: window.location.hostname,
	cookieDomain: "elevatedsignals.com",
	es_api_server: "https://api.elevatedsignals.com",
	es_client_api: "/v1",
	showDevModule: false,

	/** Angular debug tools in the dev console
	 * https://github.com/angular/angular/blob/86405345b781a9dc2438c0fbe3e9409245647019/TOOLS_JS.md
	 * @param modRef
	 * @return {any}
	 */
	decorateModuleRef(modRef: NgModuleRef<any>) {
		console.log("Module Ref", modRef);
		//   disableDebugTools();
		return modRef;
	},
	version: require("./version").version,
	ENV_PROVIDERS: [],
};
