import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ApiService } from "app/modules/auth/services/api.service";
import { Page } from "@elevatedsignals/amygoodman";
import { TranslateErrorService } from "app/shared/errors/handleError";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class PagerService {
	private translateErrorService = inject(TranslateErrorService);
	private _api = inject(ApiService);
	private http = inject(HttpClient);

	fetchPage<T>(page: Page<T>): Observable<Page<T>> {
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
		});
		const url = this._api.page.url(page);

		// REMOVE NULL VALUES
		const query = page.query
			? Object.entries(page.query).reduce(
					(a, [k, v]) => (v ? ((a[k] = v), a) : a),
					{},
				)
			: page.query;

		return this.http.get(url, { headers, params: query }).pipe(
			map((res: any) => {
				// If everything went fine, return the response
				const response: Page<T> = res || {};
				response.page = page.page;
				response.page_size = page.page_size;
				response.result_type = page.result_type;
				return response;
			}),
			this.translateErrorService.catchAndTranslateError(
				"error_failed_to_send_request_to_fetch_record_list",
			),
		);
	}
}
