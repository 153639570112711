import { ILocationType, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { LocationTypePageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "location_types";

export type State = Page<ILocationType>;

export const reducer = createPagingReducer<ILocationType>(reducerKey, {
	result_type: "location_types",
	title: "Location Type",
	title_translation_key: marker("page_title_location_types"),
	title_plural_translation_key: marker("page_title_plural_location_types"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	query: LocationTypePageQuery,
});
