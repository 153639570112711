import { HeaderQueryType, IRole, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "roles";

export type State = Page<IRole>;

export const reducer = createPagingReducer<IRole>(reducerKey, {
	result_type: "roles",
	title_translation_key: marker("page_title_roles"),
	title_plural_translation_key: marker("page_title_plural_roles"),
	title: "Role",
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Permission Filter",
			title_translation_key: marker("permission_filter"),
			key: "filterForPermissionIds",
			type: HeaderQueryType.dataSelect,
			result_type: "permissions",
			value_key: "id",
			text_key: "label",
			multiple: true,
			search: true,
			value: [],
		},
		{
			title: "Status Filter",
			key: "role_filter",
			type: HeaderQueryType.select,
			options: [
				{
					label: "All",
					translationKey: marker("word_all"),
					value: "ALL",
				},
				{
					label: "Active",
					translationKey: marker("word_active"),
					value: "ACTIVE",
				},
				{
					label: "Archived",
					translationKey: marker("word_archived"),
					value: "ARCHIVED",
				},
			],
			value: "ACTIVE",
		},
	],
});
