import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY, Observable } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	Injector,
	OnInit,
	OnDestroy,
} from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { WorkOrderService } from "app/modules/dashboard/services/work-order.service";
import { Globals } from "app/shared/modules/globals/globals.service";
import { SubstanceTypes, MeasureUnit } from "@elevatedsignals/amygoodman";
import { UnitToBaseMeasureUnitPipe } from "app/modules/dashboard/modules/es-pipes/units/unit_to_base_measure_unit.pipe";
import { TitleCasePipe } from "@angular/common";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "work-order-add-processing-loss",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderAddProcessingLossComponent
	extends GenericUpdateComponent<{
		id: number;
		work_order_id: boolean;
		weight: number;
	}>
	implements OnInit, OnDestroy
{
	weight_unit = this._globals.weight_unit;
	// Injected from other component
	private work_order_id: number;

	private prevMeasureUnit: MeasureUnit;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _workOrderService: WorkOrderService,
		private readonly _unitToBaseMeasureUnitPipe: UnitToBaseMeasureUnitPipe,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
		private readonly _titleCasePipe: TitleCasePipe,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store, _cd);
		this.form_title = "Record Processing Loss";
		this.form_title_translation_key = marker("form_title_record_processing_loss");
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";
		const ezGiMigrated = this._injector.get("ez_gi_migrated", false);
		let measureUnitItems: any[] = [
			{
				name: this._titleCasePipe.transform(MeasureUnit.Mass),
				name_translation_key: marker("word_mass"),
				value: MeasureUnit.Mass,
				enum: [MeasureUnit.Mass],
			},
			{
				name: this._titleCasePipe.transform(MeasureUnit.Quantity),
				name_translation_key: marker("word_quantity"),
				value: MeasureUnit.Quantity,
				enum: [MeasureUnit.Quantity],
			},
		];
		if (!ezGiMigrated) {
			measureUnitItems = [
				...measureUnitItems,
				{
					name: this._titleCasePipe.transform(MeasureUnit.Volume),
					name_translation_key: marker("word_volume"),
					value: MeasureUnit.Volume,
					enum: [MeasureUnit.Volume],
				},
			];
		}

		this.schema = {
			title: "",
			description: "",
			info: "",
			properties: {
				measure_unit: {
					type: "string",
					title: "Measure Type",
					title_translation_key: marker("form_field_label_measure_type"),
					widget: "select",
					oneOf: measureUnitItems,
					default: MeasureUnit.Mass,
				},
				value: {
					type: "number",
					title: `Value`,
					title_translation_key: marker("form_field_label_value"),
				},
				timestamp: {
					type: "string",
					title: "Date",
					title_translation_key: marker("word_date"),
					widget: "date",
				},
			},
			anyOf: [
				{
					required: ["use_remaining"],
				},
				{
					required: ["value"],
				},
			],
		};
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}
		this.work_order_id = this._injector.get("work_order_id", null);
	}

	onChanges(model) {
		if (model.measure_unit && model.measure_unit != this.prevMeasureUnit) {
			const units = this._globals.getFacilityUnit(model.measure_unit);
			this.schema.properties.value.title = `Value (${units})`;
			this.schema.properties.value.title_translation_params = { units };
			this.prevMeasureUnit = model.measure_unit;
			this._cd.detectChanges();
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(work_order_processing_loss: {
		value: number;
		substance_type_id?: number;
		measure_unit: MeasureUnit;
		timestamp: Date;
	}) {
		// eslint-disable-next-line no-param-reassign
		work_order_processing_loss = {
			...work_order_processing_loss,
			timestamp: work_order_processing_loss.timestamp,
		};

		// Verify form has remaining_weight or weight
		if (
			!work_order_processing_loss.value ||
			work_order_processing_loss.value <= 0
		) {
			this.error$.next(
				this._translocoService.translate(
					"error_work_order_input_must_include_remaining_weight_or_postive_weight",
				),
			);
			return;
		}
		this.loading$.next(true);
		const processing_loss: any = {
			...work_order_processing_loss,
			value: work_order_processing_loss.value
				? this._unitToBaseMeasureUnitPipe.transform(
						work_order_processing_loss.value,
						this._globals.getFacilityUnit(work_order_processing_loss.measure_unit) ??
							"",
						work_order_processing_loss.measure_unit,
					)
				: undefined,
			substance_type: SubstanceTypes.ProcessingLoss,
			work_order_id: this.work_order_id,
		};
		let request: Observable<any>;
		request = this._workOrderService.addProcessingLosses(processing_loss);

		request
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
