export const privateLabelaryUrl = "https://labelary.elevatedsignals.com";

export const convertToDotsPerMM = (dpiNumber: number): number => {
	if (dpiNumber === 152) {
		return 6;
	} else if (dpiNumber === 203) {
		return 8;
	} else if (dpiNumber === 300) {
		return 12;
	} else if (dpiNumber === 600) {
		return 24;
	}

	// fall back to 203 dpi
	return 8;
};
