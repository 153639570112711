import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	OnDestroy,
	ChangeDetectorRef,
	Injector,
	OnInit,
	SimpleChanges,
} from "@angular/core";
import {
	IInventory,
	IPurchaseOrder,
	PurchaseOrderInventoryOutgoing,
} from "@elevatedsignals/amygoodman";
import { PurchaseOrderDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ESValidator } from "app/shared/es-validator";
import { dateIsBefore } from "app/shared/time-format";
import { Globals } from "app/shared/modules/globals/globals.service";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import {
	CostPerUnitInput,
	CurrencySelect,
	TotalTaxInput,
} from "app/modules/dashboard/modules/es-forms/schemas/fields/costing";

import { GenericCreateComponent } from "../generic/generic-create.component";
import {
	getDynamicFormChanges,
	onVendorChange,
	fetchNewInventoryTotals,
	hasInventoryIdsChanged,
	hasPropertyChanged,
} from "../shared";
import { ShippingOrderAllocateInventoryComponent } from "./so-allocate-inventory.component";

@Component({
	selector: "so-create-inventory",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class ShippingOrderCreateInventoryOrderComponent
	extends GenericCreateComponent<IInventory>
	implements OnDestroy, OnInit
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			purchase_order_id: {
				type: "number",
				title: "Purchase Order",
				hidden: true,
			},
			inventory_product_id: {
				title: "Product",
				title_translation_key: marker("word_product"),
				type: "number",
				widget: "data-select",
				quick_create: false,
				related_properties: ["vendor_id"],
				oneOf: [
					{
						result_type: "inventory_products",
						queryString: {
							non_zero_inventory: "false",
							showArchived: "false",
						},
					},
				],
			},
			vendor_id: {
				type: "number",
				title: "Vendor",
				title_translation_key: marker("word_vendor"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "vendors",
					},
				],
				related_properties: ["inventory_product_id"],
				quick_create: false,
				selectFirstAndOnly: true,
				visibleIf: {
					allOf: [
						{
							oneOf: [{ purchase_order_vendor_is_customer: true }],
						},
						{ inventory_product_id: ["$EXP$ target.value > 0"] },
					],
				},
			},
			amount: {
				type: "number",
				title: `Quantity`,
				title_translation_key: marker("word_quantity"),
				visibleIf: {
					allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
				},
				width: "50%",
			},
			inventory_unit_id: {
				type: "number",
				title: "Inventory Unit",
				title_translation_key: marker("word_inventory_unit"),
				widget: "data-select",
				quick_create: false,
				shorter_placeholder: true,
				related_properties: ["inventory_product_id", "inventory_ids"],
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
				visibleIf: {
					allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
				},
				width: "50%",
			},
			cost_per_unit: { ...CostPerUnitInput },
			currency: { ...CurrencySelect },
			total_tax: { ...TotalTaxInput },
			timestamp: {
				type: "string",
				title: "Date",
				widget: "date",
				title_translation_key: marker("word_date"),
				warning: "The date must be after Shipping Order Date.",
				warning_translation_key: marker(
					"form_field_warning_the_date_must_be_after_shipping_order_date",
				),
			},
			/** These are just used for the required to make sure we have all the correct items selected before allowing the user to submit the form */
			purchase_order_vendor_is_customer: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: false,
			},
			inventory_already_allocated: {
				type: "boolean",
				widget: "checkbox",
				default: false,
				title: "Inventory Already Allocated",
			},
		},
		anyOf: [
			{
				required: [
					"purchase_order_id",
					"inventory_product_id",
					"amount",
					"inventory_unit_id",
					"lot_id",
					"inventory_ids",
				],
			},
			{
				required: [
					"purchase_order_id",
					"inventory_product_id",
					"amount",
					"inventory_unit_id",
				],
			},
		],
	};

	validators: Record<string, ESValidator> = {
		"/timestamp": (value, property, form) => {
			this.dateValidatorFailed = false;

			const isValueBefore = dateIsBefore(new Date(value), new Date(this.minDate));
			if (isValueBefore) {
				this.dateValidatorFailed = true;

				const error = {
					code: "INVALID_DATE",
					path: `#${property.path}`,
					message: "The date must be in the past",
					params: ["timestamp"],
				};
				return [error];
			}

			return null;
		},
	};

	private showFinancialInfo: boolean;
	private purchaseOrder: IPurchaseOrder;
	private copiedInventory: PurchaseOrderInventoryOutgoing | null;
	private minDate: Date;
	private prevInventoryProductId: number | undefined;
	private dateValidatorFailed: boolean;
	private purchaseOrderVendorIsCustomer: boolean;
	private readonly whatChanged: SimpleChanges = {};

	constructor(
		protected readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _injector: Injector,
		private readonly _globals: Globals,
	) {
		super(_store);

		this.form_title = `Create Order for Shipment`;
		this.form_title_translation_key = marker(
			"form_title_add_inventory_to_shipping_order",
		);

		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
	}

	valid(valid) {
		this.valid$.next(valid);

		if (this.dateValidatorFailed) {
			this.valid$.next(false);
		}
	}

	ngOnInit() {
		this.showFinancialInfo = this._injector.get("show_financial_info", false);
		this.purchaseOrder = this._injector.get("purchase_order", null);
		this.copiedInventory = this._injector.get("copied_inventory", null);
		this.minDate = new Date(
			this.purchaseOrder.po_date
				? this.purchaseOrder.po_date
				: this.purchaseOrder.created_at,
		);

		this.purchaseOrderVendorIsCustomer =
			this.purchaseOrder.vendor?.customer || false;
		const initialOrder = this.copiedInventory?.pending_inventory;
		this.model = {
			purchase_order_id: this.purchaseOrder.id,
			inventory_product_id: initialOrder?.inventory_product_id,
			vendor_id: this.purchaseOrder.vendor_id,
			purchase_order_vendor_is_customer: this.purchaseOrderVendorIsCustomer,
			amount: initialOrder?.orderedAmount,
			...((initialOrder?.cost_per_unit || initialOrder?.tax) && {
				cost_per_unit: initialOrder.cost_per_unit?.amount,
				currency:
					initialOrder.cost_per_unit?.currency ?? initialOrder.tax?.currency,
				total_tax: initialOrder.tax?.amount,
			}),
		};

		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.schema.properties.purchase_order_id = {
			...this.schema.properties.purchase_order_id,
			default: this.purchaseOrder.id,
			readOnly: true,
		};

		if (!this.showFinancialInfo) {
			delete this.schema.properties.cost_per_unit;
			delete this.schema.properties.currency;
			delete this.schema.properties.total_tax;
		}

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		if (!model.inventory_product_id && this.prevInventoryProductId) {
			this.prevInventoryProductId = undefined;
			model.inventory_unit_id = undefined;
		} else if (
			model.inventory_product_id &&
			this.prevInventoryProductId !== model.inventory_product_id
		) {
			this.prevInventoryProductId = model.inventory_product_id;
			model.inventory_unit_id = undefined;
			const firstProductSet = !this.model.inventory_product_id;

			this._itemService
				.fetchItem(`inventory_product`, `${model.inventory_product_id}`, {
					eager: "[vendors]",
				})
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((inventory_product) => {
					if (!firstProductSet || !this.model.inventory_unit_id) {
						this.model.inventory_unit_id = inventory_product.display_unit_id;
					}

					[this.model, this.schema] = onVendorChange(
						this.model,
						this.schema,
						inventory_product,
					);

					if (this.schema.properties.batch_id) {
						this.schema.properties.batch_id.hidden = !inventory_product.require_batch;
					}

					this._cd.detectChanges();
				});
		}

		getDynamicFormChanges(this.whatChanged, model);
		this.checkAndUpdateInventoryTotals();
	}

	checkAndUpdateInventoryTotals = () => {
		if (
			hasPropertyChanged(this.whatChanged.inventory_product_id) ||
			hasPropertyChanged(this.whatChanged.lot_id) ||
			hasPropertyChanged(this.whatChanged.batch_id) ||
			hasInventoryIdsChanged(this.whatChanged.inventory_ids) ||
			hasPropertyChanged(this.whatChanged.timestamp) ||
			hasPropertyChanged(this.whatChanged.vendor_id)
		) {
			fetchNewInventoryTotals(this.whatChanged, this._itemService).subscribe(
				(availableInventoryAmount) => {
					this.model.amount_available = availableInventoryAmount.content
						.map((availableInventory) => {
							return `${availableInventory.sum.toFixed(2)} ${availableInventory.name}`;
						})
						.join("\n");

					this._cd.detectChanges();
				},
			);
		}
	};

	createItem(inventory) {
		const inventory_create = {
			...inventory,
			vendor_id: this.model.vendor_id ?? this.purchaseOrder.vendor_id,
		};

		this.loading$.next(true);
		this._itemService
			.add(
				`purchase_order/${inventory_create.purchase_order_id}/inventory`,
				inventory_create,
				PurchaseOrderDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((updatedItem: IPurchaseOrder) => {
				this._store.dispatch(
					ItemActions.updateSuccess({
						updatedItem,
						result_type: "purchase_orders",
					}),
				);

				this.loading$.next(false);
				this.closeSidenav();

				if (this.model.inventory_already_allocated) {
					if (updatedItem.inventories && updatedItem.inventories.length) {
						const pendingPoInventory = updatedItem.inventories.pop();
						this._store.dispatch(
							layoutActions.openSidenav({
								component: ShippingOrderAllocateInventoryComponent,
								inputs: {
									purchase_order: this.purchaseOrder,
									pending_inventory: pendingPoInventory,
									copied_inventory:
										this.copiedInventory?.allocated_inventory ??
										this.copiedInventory?.pending_inventory,
								},
							}),
						);
					}
				}
			});
	}
}
