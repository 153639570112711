import { tap, take, takeUntil, timeout, catchError } from "rxjs/operators";
import {
	Component,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
	Injector,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ReplaySubject, Observable, EMPTY } from "rxjs";
import { Batch, UserProfile } from "@elevatedsignals/amygoodman";
import { BatchDetailQuery } from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { PasswordInputComponent } from "app/modules/auth/components/password-input";
import { Permissions, PERMISSIONS } from "app/shared/permissions";
import { handleObservableError } from "app/shared/utils";
import { maxPlantNumber } from "app/shared/work-order-functions";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { TranslocoService } from "@jsverse/transloco";

import { GeneralBatchActionSchemaProperties } from "../work-order/schemas/batch-action";

@Component({
	selector: "batch-cuttings",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class BatchCuttingsComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();
	batch_names: string[] = [];
	batch$ = this._store.select(fromDashboard.getSelectedBatch);
	batch: Batch;

	user$: Observable<UserProfile | null> = this._store.select(
		fromDashboard.getProfile,
	);

	user: UserProfile;

	form_title = "Propagate plants from a mother batch";
	form_title_translation_key: string = marker(
		"form_title_propagate_plants_from_a_mother_batch",
	);

	submit_button = "Propagate Plants";
	submit_button_title: string = marker("form_button_propagate_plants");

	submit_icon = "plus";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {
		location: "BATCH",
	};

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			work_order_type_id: {
				type: "number",
				title: "Work Order Type",
				title_translation_key: marker("word_work_order_type"),
				widget: "data-select",
				quick_create: false,
				oneOf: [
					{
						result_type: "work_order_types",
						queryString: {
							settings_filter: "propagation",
						},
					},
				],
				readonly: false,
			},
			use_plant_ids: {
				title: "Use Specific Mother Plant Ids",
				title_translation_key: marker(
					"form_field_label_use_specific_mother_plant_ids",
				),
				type: "boolean",
				widget: "checkbox",
				default: false,
			},
			mother_plant_ids: {
				type: "array",
				title: "Plant Ids",
				title_translation_key: marker("form_field_label_plant_ids"),
				widget: "data-select",
				quick_create: false,
				items: {
					type: "number",
					oneOf: [
						{
							queryString: {},
							result_type: "plants",
						},
					],
				},
				visibleIf: {
					allOf: [{ use_plant_ids: [true] }],
				},
			},
			mother_plant_count: {
				type: "number",
				title: "Number of Mother Plants",
				title_translation_key: marker("form_field_label_number_of_mother_plants"),
				minimum: 1,
				visibleIf: {
					allOf: [{ use_plant_ids: [false] }],
				},
			},
			existing_batch_or_new: {
				type: "string",
				title: "Propagate into:",
				title_translation_key: marker("form_field_label_propagate_into"),
				widget: "radio",
				default: "Batch",
				oneOf: [
					{
						name: "Existing Batch",
						name_translation_key: marker("form_field_value_existing_batch"),
						value: "Existing Batch",
						enum: ["Existing Batch"],
					},
					{
						name: "New Batch",
						name_translation_key: marker("form_field_value_new_batch"),
						value: "New Batch",
						enum: ["New Batch"],
					},
				],
			},
			existing_batch_id: {
				type: "number",
				title: "Destination Batch",
				title_translation_key: marker("form_field_label_destination_batch"),
				widget: "data-select",
				oneOf: [{ result_type: "batches" }],
				visibleIf: {
					allOf: [{ existing_batch_or_new: ["Existing Batch"] }],
				},
			},
			new_batch_name: {
				type: "string",
				title: "Destination Batch Name",
				title_translation_key: marker("form_field_label_destination_batch_name"),
				visibleIf: {
					allOf: [{ existing_batch_or_new: ["New Batch"] }],
				},
				warning: "Warning: Batch name already in use.",
				warning_translation_key: marker(
					"form_field_warning_batch_name_already_in_use",
				),
				info_text: this._translocoService.translate(
					"batch_name_will_be_set_to_batch_id_if_name_is_left_blank",
				),
			},
			new_batch_description: {
				type: "string",
				title: "Destination Batch Description",
				title_translation_key: marker(
					"form_field_label_destination_batch_description",
				),
				visibleIf: {
					allOf: [{ existing_batch_or_new: ["New Batch"] }],
				},
			},
			location_id: {
				type: "number",
				title: "Destination Batch Location",
				title_translation_key: marker(
					"form_field_label_destination_batch_location",
				),
				widget: "data-select",
				oneOf: [{ result_type: "locations" }],
				visibleIf: {
					allOf: [{ existing_batch_or_new: ["New Batch"] }],
				},
			},
			cultivar_id: {
				type: "number",
				title: "New Batch Cultivar",
				title_translation_key: marker("form_field_label_new_batch_cultivar"),
				widget: "data-select",
				oneOf: [{ result_type: "cultivars" }],
				visibleIf: {
					allOf: [{ existing_batch_or_new: ["New Batch"] }],
				},
			},
			batch_type_id: {
				type: "number",
				title: "Destination Batch Type",
				title_translation_key: marker("form_field_label_destination_batch_type"),
				widget: "data-select",
				oneOf: [{ result_type: "batch_types" }],
				visibleIf: {
					allOf: [{ existing_batch_or_new: ["New Batch"] }],
				},
			},
			number_of_cuttings: {
				type: "number",
				title: "Number of Cuttings",
				title_translation_key: marker("form_field_label_number_of_cuttings"),
				max: maxPlantNumber,
				warning:
					"Number of plants in output exceeds the recommended maximum - Please lower your plant count.",
				warning_translation_key: marker(
					"form_field_warning_number_of_plants_exceeds_recommended",
				),
			},
			growth_stage_id: {
				type: "number",
				title: "Select Growth Stage",
				title_translation_key: marker("form_field_label_select_growth_stage"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "growth_stages",
						queryString: {
							growth_stage_status: ["ACTIVE"],
						},
					},
				],
			},
			timestamp: {
				type: "string",
				title: "Date/time propagation occurred",
				title_translation_key: marker(
					"form_field_label_date_time_propagation_occurred",
				),
				widget: "date",
			},
			work_order_description: {
				type: "string",
				widget: "textarea",
				title: "Work Order Description",
				title_translation_key: marker("form_field_label_work_order_description"),
			},
			...GeneralBatchActionSchemaProperties,
		},
		required: ["number_of_cuttings", "growth_stage_id"],
	};

	validators = {
		"/new_batch_name": (value, _property, _form) => {
			if (value !== "") {
				if (
					this.batch_names.length > 0 &&
					this.batch_names.includes(value.trim())
				) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private prevWorkOrderTypeId: number;

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
		private readonly _translocoService: TranslocoService,
	) {}

	valid(v) {
		this.valid$.next(v);
	}

	ngOnInit() {
		const max_plant_count = this._injector.get("max_plant_count", null);
		this.schema.properties.mother_plant_count.maximum = max_plant_count;

		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}
		if (this._globals.propagationWorkOrderTypes.length === 0) {
			delete this.schema.properties.work_order_type_id;
		}

		if (this._globals.propagationWorkOrderTypes.length === 1) {
			this.model = {
				...this.model,

				work_order_type_id: this._globals.propagationWorkOrderTypes[0],
			};
		}

		this.user$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined())
			.subscribe((user) => {
				this.user = user;

				// Remove New Batch if user doesn't have Batch -> Add permission
				if (!Permissions.hasPermission(user, PERMISSIONS.BATCHES_ADD)) {
					this.schema.properties.existing_batch_or_new.oneOf = [
						{
							name: "Existing Batch",
							name_translation_key: marker("form_field_value_existing_batch"),
							value: "Existing Batch",
							enum: ["Existing Batch"],
						},
					];
				}
				this.model.existing_batch_or_new = "Existing Batch";
			});

		this.batch$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((batch) => {
				this.batch = batch;
				this.model = {
					...this.model,
					id: this.batch.id,
				};
				this.schema.properties.cultivar_id.default = this.batch.cultivar_id;
				this.schema.properties.mother_plant_ids.items.oneOf[0].queryString = {
					batch_id: this.batch.id,
					plant_status: "ACTIVE",
				};
				this._cd.detectChanges();
			});

		this._itemService
			.fetchItem(`batches/fetch/names`, "", { col: "name" })
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err));
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.batch_names = value;
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const cuttings: any = {
			...this.model,
		};
		if (this._globals.gmp_enabled) {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: PasswordInputComponent,
					inputs: {
						onSubmit: (password) => {
							this.cuttingsRequest(cuttings, true, password);
						},
					},
				}),
			);
		} else {
			this.cuttingsRequest(cuttings, true);
		}
	}

	// tslint:disable-next-line:no-empty
	onChanges(form_data): void {
		const required = this.schema.required.filter(
			(x) =>
				!["existing_batch_id", "mother_plant_count", "mother_plant_ids"].includes(
					x,
				),
		);
		if (form_data.existing_batch_or_new === "Existing Batch") {
			required.push("existing_batch_id");
		}
		if (form_data.use_plant_ids) {
			required.push("mother_plant_ids");
		}
		if (!form_data.use_plant_ids) {
			required.push("mother_plant_count");
		}

		if (
			form_data.work_order_type_id &&
			this.prevWorkOrderTypeId !== form_data.work_order_type_id
		) {
			this.loading$.next(true);
			this._itemService
				.fetchItem(`work_order_type`, form_data.work_order_type_id)
				.pipe(takeUntil(this.destroyed$))
				.pipe(
					timeout(10000),
					catchError((error) => {
						this.error$.next(handleObservableError(error));
						this.loading$.next(false);
						return EMPTY;
					}),
				)
				.subscribe((workOrderType) => {
					this.loading$.next(false);
					this.model = {
						...this.model,
						location_id: workOrderType.default_location_id,
					};
				});
			this.prevWorkOrderTypeId = form_data.work_order_type_id;
		}
		this.schema.required = required;
	}

	cuttingsRequest(cuttings_model: any, signing = false, password?: string) {
		const authObject = password
			? { username: this.user.email, password }
			: undefined;

		this.loading$.next(true);
		this._itemService
			.update(
				`batch`,
				`${this.batch.id}/propagation`,
				cuttings_model,
				{ signing: signing.toString() },
				authObject,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap(() => {
					this._store.dispatch(
						SelectionActions.select({
							result_type: "batches",
							id: Number(this.batch.id),
							query: BatchDetailQuery,
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
