import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap, take } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	OnDestroy,
	ChangeDetectorRef,
	Injector,
	OnInit,
} from "@angular/core";
import { IInventory, IPurchaseOrder } from "@elevatedsignals/amygoodman";
import { PurchaseOrderDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ESValidator } from "app/shared/es-validator";
import { dateIsBefore } from "app/shared/time-format";
import { Globals } from "app/shared/modules/globals/globals.service";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import _ from "lodash";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "so-inventory-create-by-id",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class ShippingOrderInventoryByIdComponent
	extends GenericCreateComponent<IInventory>
	implements OnDestroy, OnInit
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			purchase_order_id: {
				type: "number",
				title: "Purchase Order",
				hidden: true,
			},
			is_outgoing: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: false,
			},
			inventory_ids: {
				type: "array",
				title: "Inventory ID",
				title_translation_key: marker("form_field_label_inventory_id"),
				widget: "data-select",
				quick_create: false,
				multi: true,
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventories",
							text_key: ["name", "id"],
							text_format: "?1 (#?2)",
							value_key: "id",
							queryString: {
								non_zero_inventory: "true",
								dont_show_expired: "true",
							},
						},
					],
				},
			},
			use_remaining: {
				title: "Use Remaining Inventory",
				title_translation_key: marker("form_field_label_use_remaining_inventory"),
				type: "boolean",
				widget: "checkbox",
				default: true,
			},
			value: {
				type: "number",
				title: `Quantity`,
				title_translation_key: marker("word_quantity"),
				visibleIf: {
					use_remaining: false,
				},
				width: "50%",
			},
			inventory_unit_id: {
				type: "number",
				title: "Inventory Unit",
				title_translation_key: marker("word_inventory_unit"),
				widget: "data-select",
				quick_create: false,
				shorter_placeholder: true,
				related_properties: ["inventory_ids"],
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
				visibleIf: {
					use_remaining: false,
				},
				width: "50%",
			},
			timestamp: {
				type: "string",
				title: "Date",
				widget: "date",
				title_translation_key: marker("word_date"),
				warning: "The date must be after Shipping Order Date.",
				warning_translation_key: marker(
					"form_field_warning_the_date_must_be_after_shipping_order_date",
				),
			},
		},
		anyOf: [
			{
				required: [
					"purchase_order_id",
					"inventory_ids",
					"inventory_unit_id",
					"value",
				],
			},
			{
				required: ["purchase_order_id", "inventory_ids", "use_remaining"],
			},
		],
	};

	validators: Record<string, ESValidator> = {
		"/timestamp": (value, property, form) => {
			this.dateValidatorFailed = false;

			const isValueBefore = dateIsBefore(new Date(value), new Date(this.minDate));
			if (isValueBefore) {
				this.dateValidatorFailed = true;

				const error = {
					code: "INVALID_DATE",
					path: `#${property.path}`,
					message: "The date must be in the past",
					params: ["timestamp"],
				};
				return [error];
			}

			return null;
		},
	};

	private readonly purchase_order_id: number;
	private readonly minDate: Date;
	private readonly inventory_id: number;
	private dateValidatorFailed: boolean;

	constructor(
		protected readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _injector: Injector,
		private readonly _globals: Globals,
	) {
		super(_store);
		this.minDate = this._injector.get("minDate", null);
		this.inventory_id = this._injector.get("inventory_id", null);
		this.purchase_order_id = this._injector.get("purchase_order_id", null);
		this.form_title = `Create Order for Shipment #${this.purchase_order_id}`;
		this.form_title_translation_key = marker(
			"form_title_add_inventory_to_shipping_order",
		);
		this.form_title_translation_params = {
			shipping_order_id: this.purchase_order_id,
		};
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
	}

	valid(valid) {
		this.valid$.next(valid);

		if (this.dateValidatorFailed) {
			this.valid$.next(false);
		}
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		if (this.purchase_order_id) {
			this.schema.properties.purchase_order_id = {
				...this.schema.properties.purchase_order_id,
				default: this.purchase_order_id,
				readOnly: true,
			};
			this.model.purchase_order_id = this.purchase_order_id;
		}

		if (this.inventory_id) {
			this.model.inventory_ids = [this.inventory_id];
		}

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges() {}

	createItem(inventory) {
		this.loading$.next(true);
		this._itemService
			.add(
				`purchase_order/${this.purchase_order_id}/inventory`,
				inventory,
				PurchaseOrderDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem: IPurchaseOrder) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "purchase_orders",
						}),
					);

					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
