import { IInventoryCategory } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.InventoryCategories;

export type State = SelectionState<IInventoryCategory>;

export const reducer = createSelectionReducer<IInventoryCategory>(reducerKey);
