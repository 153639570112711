import { createSelector, createFeatureSelector } from "@ngrx/store";
import * as fromRoot from "app/reducers";

import * as fromAuth from "./auth.reducer";
import * as fromLogout from "./logout.reducer";
import * as fromSigninPage from "./signin-page.reducer";
import * as fromRegisterPage from "./register-page.reducer";
import * as fromForgotPasswordPage from "./forgot-password.reducer";
import * as fromPasswordResetPage from "./password-reset.reducer";

export interface AuthState {
	status: fromAuth.State;
	logout: fromLogout.State;
	signinPage: fromSigninPage.State;
	registerPage: fromRegisterPage.State;
	forgotPasswordPage: fromForgotPasswordPage.State;
	passwordResetPage: fromPasswordResetPage.State;
}

export interface State extends fromRoot.State {
	auth: AuthState;
}

export const reducers = {
	status: fromAuth.reducer,
	logout: fromLogout.reducer,
	signinPage: fromSigninPage.reducer,
	registerPage: fromRegisterPage.reducer,
	forgotPasswordPage: fromForgotPasswordPage.reducer,
	passwordResetPage: fromPasswordResetPage.reducer,
};

export const selectAuthState = createFeatureSelector<AuthState>("auth");

export const selectAuthStatusState = createSelector(
	selectAuthState,
	(state: AuthState) => state.status,
);
export const getToken = createSelector(
	selectAuthStatusState,
	(state) => state.auth.token,
);
export const getTokenExpires = createSelector(
	selectAuthStatusState,
	fromAuth.getTokenExpires,
);

/** Signin Page **/
export const selectSigninPageState = createSelector(
	selectAuthState,
	(state: AuthState) => state.signinPage,
);
export const getSigninPageError = createSelector(
	selectSigninPageState,
	fromSigninPage.getError,
);
export const getSigninPagePending = createSelector(
	selectSigninPageState,
	fromSigninPage.getPending,
);

/** Register Page **/
export const selectRegisterPageState = createSelector(
	selectAuthState,
	(state: AuthState) => state.registerPage,
);
export const getRegisterPageError = createSelector(
	selectRegisterPageState,
	fromRegisterPage.getError,
);
export const getRegisterPagePending = createSelector(
	selectRegisterPageState,
	fromRegisterPage.getPending,
);
export const getRegisterPageVerifyError = createSelector(
	selectRegisterPageState,
	fromRegisterPage.getVerifyError,
);
export const getRegisterPageVerifyPending = createSelector(
	selectRegisterPageState,
	fromRegisterPage.getVerifyPending,
);
export const getRegisterPageLimitedUser = createSelector(
	selectRegisterPageState,
	fromRegisterPage.getLimitedUser,
);

/** Forgot Password Page **/
export const selectForgotPasswordPageState = createSelector(
	selectAuthState,
	(state: AuthState) => state.forgotPasswordPage,
);
export const getForgotPasswordPageError = createSelector(
	selectForgotPasswordPageState,
	fromForgotPasswordPage.getError,
);
export const getForgotPasswordPagePending = createSelector(
	selectForgotPasswordPageState,
	fromForgotPasswordPage.getPending,
);
export const getForgotPasswordPageSuccess = createSelector(
	selectForgotPasswordPageState,
	fromForgotPasswordPage.getSuccess,
);

/** Reset Password Page **/
export const selectPasswordResetPageState = createSelector(
	selectAuthState,
	(state: AuthState) => state.passwordResetPage,
);
export const getPasswordResetPageError = createSelector(
	selectPasswordResetPageState,
	fromPasswordResetPage.getError,
);
export const getPasswordResetPagePending = createSelector(
	selectPasswordResetPageState,
	fromPasswordResetPage.getPending,
);
export const getPasswordResetVerifyError = createSelector(
	selectPasswordResetPageState,
	fromPasswordResetPage.getVerifyError,
);
export const getPasswordResetVerifyPending = createSelector(
	selectPasswordResetPageState,
	fromPasswordResetPage.getVerifyPending,
);
export const getPasswordResetSuccess = createSelector(
	selectPasswordResetPageState,
	fromPasswordResetPage.getResetSuccess,
);
export const getResetTokenVerifySuccess = createSelector(
	selectPasswordResetPageState,
	fromPasswordResetPage.getVerifySuccess,
);
