import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY, Observable } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	Injector,
	OnInit,
	OnDestroy,
} from "@angular/core";
import { WorkOrderService } from "app/modules/dashboard/services/work-order.service";
import { Globals } from "app/shared/modules/globals/globals.service";
import { MeasureUnit } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "work-order-add-bom",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderAddBOMComponent
	extends GenericUpdateComponent<{
		id: number;
		work_order_id: boolean;
		weight: number;
	}>
	implements OnInit, OnDestroy
{
	private readonly work_order_id: number;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _workOrderService: WorkOrderService,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.form_title = "Add Bill of Material";
		this.form_title_translation_key = marker("form_title_add_bill_of_material");
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";

		this.work_order_id = this._injector.get("work_order_id", null);
		const work_order_type_id = this._injector.get("work_order_type_id", null);
		this.schema = {
			title: "",
			description: "",
			info: "",
			properties: {
				bill_of_material_id: {
					type: "number",
					title: "Bill of Material",
					title_translation_key: marker("word_bill_of_material"),
					widget: "data-select",
					oneOf: [
						{
							result_type: "bill_of_materials",
							queryString: {
								work_order_type_id,
							},
						},
					],
				},
				bill_of_material_multiplier: {
					type: "number",
					title: "Bill Of Material Multiplier",
					title_translation_key: marker(
						"form_field_label_bill_of_material_multiplier",
					),
					widget: "number",
					default: 1,
					step: 0.01,
					min: 0.01,
					visibleIf: {
						bill_of_material_id: ["$ANY$"],
					},
				},
			},
			anyOf: [
				{
					required: ["bill_of_material_id"],
				},
			],
		};
	}

	ngOnInit() {}

	onChanges(_model) {}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(work_order_bom) {
		this.loading$.next(true);

		const request: Observable<any> = this._workOrderService.addBOM(
			this.work_order_id,
			work_order_bom,
		);

		request
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
