import { Component } from "@angular/core";
import {
	ComponentModalConfig,
	FuiBaseModal,
} from "@elevatedsignals/ngx-fomantic-ui";

export interface IPBRModalDefaults {
	show_audit_log_on_records?: boolean;
}

@Component({
	selector: "pbr-report-modal",
	template: `
		<div class="header">PBR Report Setting</div>
		<div class="content">
			<div class="sixteen wide column">
				<sf-form
					[schema]="schema"
					[model]="model"
					(onChange)="model = $event.value"
				></sf-form>
			</div>
		</div>
		<div class="actions">
			<div class="ui button" (click)="modal.deny(undefined)">Cancel</div>
			<div class="ui primary button" (click)="onApprove()" autofocus>Download</div>
		</div>
	`,
})
export class PBRReportModalComponent {
	defaults: IPBRModalDefaults = {
		show_audit_log_on_records: true,
	};

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			show_audit_log_on_records: {
				type: "boolean",
				widget: "checkbox",
				title: "Show Audit Logs on Records",
				default: this.defaults.show_audit_log_on_records,
			},
		},
	};

	model = {};
	constructor(public modal: FuiBaseModal<IPBRModalDefaults, any, void>) {
		this.model = {
			...this.defaults,
			...modal.context,
		};
	}

	onApprove() {
		this.modal.approve(this.model);
	}
}

export class PBRReportModal extends ComponentModalConfig<
	IPBRModalDefaults,
	any,
	void
> {
	constructor(defaults: IPBRModalDefaults) {
		super(PBRReportModalComponent, defaults);
	}
}
