import { Component, OnInit, ChangeDetectorRef, Injector } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { handleObservableError } from "app/shared/utils";
import {
	timeout,
	catchError,
	take,
	takeUntil,
	tap,
	filter,
} from "rxjs/operators";
import { ReplaySubject, EMPTY } from "rxjs";
import { IGrowthStage } from "@elevatedsignals/amygoodman";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

@Component({
	selector: "growth-stage-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class GrowthStageUpdateComponent implements OnInit {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	growth_stage$ = this._store.select(fromDashboard.getSelectedGrowthStage);
	growth_stage: IGrowthStage;
	id: number;

	form_title = "Update a Growth Stage";
	form_title_translation_key: string = marker(
		"form_title_update_a_growth_stage",
	);

	submit_button = "Update Growth Stage";
	submit_button_translation_key: string = marker(
		"form_button_update_growth_stage",
	);

	submit_icon = "edit";

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				readOnly: false,
				warning: "Warning: Growth Stage name already in use.",
				warning_translation_key: marker(
					"form_field_warning_growth_stage_name_already_in_use",
				),
			},
			growth_stage_type_id: {
				type: "number",
				minItems: 1,
				title: "Growth Stage Type",
				title_translation_key: marker("word_growth_stage_type"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "growth_stage_types",
					},
				],
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
		},
		required: ["name", "growth_stage_type_id"],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private names;

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _injector: Injector,
	) {
		this.id = this._injector.get("id", null);
	}

	valid(v) {
		this.valid$.next(v);
	}

	ngOnInit() {
		this.growth_stage$
			.pipe(
				takeUntil(this.destroyed$),
				isNotNullOrUndefined(),
				filter(
					(growth_stage): growth_stage is IGrowthStage =>
						growth_stage.id === this.id,
				),
				take(1),
			)
			.subscribe((growth_stage: IGrowthStage) => {
				this.growth_stage = growth_stage;
				this.model.id = this.growth_stage.id;
				this.model.name = this.growth_stage.name;
				this.model.growth_stage_type_id = this.growth_stage.growth_stage_type_id;
				this.model.archived = this.growth_stage.archived;
				this.schema.properties.name.readOnly = !this.growth_stage.facility_id;
				this._cd.detectChanges();
				this.getNames();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const growth_stage: Partial<IGrowthStage> = {
			...this.model,
		};
		this.updateGrowthStage(growth_stage);
	}

	onChanges(_error): void {}

	// Used for schema validation - duplicate Growth Stage name check
	getNames() {
		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`growth_stages/fetch/names`, "", {
				col: "name",
				except: this.model.name,
			})
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	updateGrowthStage(add_growth_stage: Partial<IGrowthStage>) {
		this.loading$.next(true);
		this._itemService
			.update(`growth_stage`, this.growth_stage.id, add_growth_stage)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "growth_stages",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
