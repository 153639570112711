import { Component, OnInit, OnDestroy, Injector } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { handleObservableError } from "app/shared/utils";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { ReplaySubject, EMPTY } from "rxjs";
import { ISop } from "@elevatedsignals/amygoodman";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "sop-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class SopCreateComponent
	extends GenericCreateComponent<ISop>
	implements OnDestroy
{
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Create a SOP";
	form_title_translation_key: string = marker("form_title_create_a_sop");
	submit_button = "Create SOP";
	submit_button_translation_key: string = marker("form_button_create_sop");
	submit_icon = "add";
	loading = false;
	specific_field_id: string | null;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: SOP name already in use.",
				warning_translation_key: marker(
					"form_field_warning_sop_name_already_in_use",
				),
			},
			version_major: {
				type: "number",
				title: "Major Version",
				title_translation_key: marker("form_field_label_major_version"),
			},
			version_minor: {
				type: "number",
				title: "Minor Version",
				title_translation_key: marker("form_field_label_minor_version"),
			},
			sop_category_ids: {
				type: "array",
				title: "SOP Categories",
				title_translation_key: marker("form_field_label_sop_categories"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [{ result_type: "sop_categories" }],
				},
			},
		},
		required: ["name"],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private names;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store);
		this.specific_field_id = this._injector.get("specific_field_id", null);
	}

	valid(v) {
		this.valid$.next(v);
	}

	ngOnInit() {
		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`sops/fetch/names`, "", { col: "name" })
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				this.names = value;
			});
		this.loading$.next(false);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		this.createItem(this.model);
	}

	onChanges(_model): void {}

	createItem(new_sop: ISop) {
		this.loading$.next(true);
		this._itemService
			.add("sops", new_sop)
			.pipe(
				takeUntil(this.destroyed$),
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							addedItem,
							result_type: "sops",
							...(this.specific_field_id && {
								specific_field_id: this.specific_field_id,
							}),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
