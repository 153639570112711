import { createReducer, on, Action } from "@ngrx/store";

import { AuthActions } from "../actions/auth.actions";

export interface State {
	auth: {
		token_expires: number;
		token: string;
	};
	error: null | string;
	pending: boolean;
}

const initialState: State = {
	auth: {
		token_expires: 0,
		token: "",
	},
	error: null,
	pending: false,
};

const authReducer = createReducer(
	initialState,
	on(AuthActions.navigationSuccess, (state) => {
		return {
			...state,
			pending: false,
		};
	}),
	on(AuthActions.authenticate, (state) => {
		return {
			...state,
			error: null,
			pending: true,
		};
	}),
	on(AuthActions.authenticateSuccess, (state, { auth }) => {
		return {
			...state,
			auth: {
				token_expires: auth.token_expires,
				token: auth.token,
			},
			error: null,
			pending: false,
		};
	}),
	on(AuthActions.signinSuccess, (state, { auth }) => {
		return {
			...state,
			auth: {
				token_expires: auth.token_expires,
				token: auth.token,
			},
			error: null,
			pending: false,
		};
	}),
	on(AuthActions.authenticateFail, (state, { error }) => {
		return {
			...state,
			error,
			pending: false,
		};
	}),
	on(AuthActions.logout, (_state) => {
		return initialState;
	}),
);

export const reducer = (state: State | undefined, action: Action) =>
	authReducer(state, action);

/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */

export const getAuth = (state: State) => state;

export const getToken = (state: State) => state.auth.token;
export const getTokenExpires = (state: State) => state.auth.token_expires;

export const getPending = (state: State) => state.pending;
export const getError = (state: State) => state.error;
