import {
	Component,
	Injector,
	ChangeDetectorRef,
	OnDestroy,
	OnInit,
} from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { IDocumentSet } from "@elevatedsignals/amygoodman";
import { EMPTY } from "rxjs";
import { timeout, catchError, tap, take, takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { DocumentSetDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "add-record",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class AddRecordToDocumentSetComponent
	extends GenericCreateComponent<IDocumentSet>
	implements OnDestroy, OnInit
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			document_set_id: {
				type: "number",
				title: "Record Set",
				title_translation_key: marker("word_record_set"),
				widget: "data-select",
				readOnly: true,
				oneOf: [
					{
						result_type: "document_sets",
					},
				],
			},
			record_ids: {
				type: "array",
				title: "Record",
				title_translation_key: marker("word_record"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "records",
							text_key: ["id", "form_schema_name"],
							text_format: "#?1 ?2",
							queryString: {
								input: "true",
							},
						},
					],
				},
			},
		},
		required: ["document_set_id", "record_ids"],
	};

	model: any = {};

	document_set$ = this._store.select(fromDashboard.getSelectedDocumentSet);
	document_set: IDocumentSet;

	private document_set_id: number;
	private record_id: number;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
		private readonly _cd: ChangeDetectorRef,
	) {
		super(_store);
		this.form_title = "Add a Record to the Record Set";
		this.form_title_translation_key = marker(
			"form_title_add_a_record_to_the_record_set",
		);
		this.submit_button = "Add Record(s)";
		this.submit_button_translation_key = marker("form_button_add_record_s");
	}

	ngOnInit() {
		this.document_set_id = this._injector.get("document_set_id", null);
		this.record_id = this._injector.get("record_id", null);

		if (this.document_set_id) {
			this.model.document_set_id = this.document_set_id;
		}

		if (this.record_id) {
			this.model = {
				...this.model,
				record_ids: [this.record_id],
			};
		}

		this.document_set$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((document_set) => {
				this.document_set = document_set;
				this.model.document_set_id = this.document_set.id;
				this.model.record_ids = this.document_set.records.map(
					(record) => record.id,
				);
				this._cd.detectChanges();
			});

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const document_set: Partial<IDocumentSet> = {
			id: this.model.document_set_id,
			records: this.model.record_ids,
		};
		this.createItem(document_set);
	}

	createItem(document_set: Partial<IDocumentSet>) {
		this.loading$.next(true);
		this._itemService
			.update(
				`document_set`,
				document_set.id!,
				document_set,
				DocumentSetDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "document_sets",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
