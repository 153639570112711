import { Component, EventEmitter, Output } from "@angular/core";

@Component({
	selector: "es-auth-sso-section",
	template: ` <div class="sso-section">
		<button (click)="signInWithOauth()"></button>
	</div>`,
	styles: [
		`
			:host {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
			}

			.sso-section {
				padding: 1em;
			}

			button {
				width: 215px;
				height: 41px;
				background-image: url("/assets/ms_signin_light.svg");
				background-size: cover;
				background-repeat: no-repeat;
				border: 1px #8c8c8c;
			}
		`,
	],
})
export class SsoSectionComponent {
	@Output() initSignIn = new EventEmitter();

	signInWithOauth() {
		this.initSignIn.emit();
	}
}
