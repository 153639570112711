import {
	Component,
	Input,
	Output,
	EventEmitter,
	OnInit,
	OnChanges,
	SimpleChanges,
} from "@angular/core";
import {
	UntypedFormGroup,
	Validators,
	FormBuilder,
	FormControl,
} from "@angular/forms";
import { FadeInAnimation } from "app/shared/animations/fadein.animation";

import { UserRegister } from "../../models/user-register.model";
import { LimitedUser } from "../../models/limited-user.model";

@Component({
	selector: "register-input",
	template: `
		<div
			*ngIf="
				(!verify_pending && !limited_user) ||
				(limited_user && limited_user.registered && !limited_user.email_confirmed)
			"
			class="ui message"
		>
			<div class="header">Invalid Registration link</div>
			<p>
				Hey! It looks like you're trying to register but the link you've used is
				invalid.
			</p>
			<ul class="list">
				<li>
					Try getting your administrator to send you another registration link.
				</li>
				<li>
					If you already have an account sign in on the
					<a routerLink="/signin">Signin Page.</a>
				</li>
			</ul>
		</div>

		<div *ngIf="limited_user && !limited_user.registered" class="input-form">
			<div class="logo"></div>

			<form
				[formGroup]="registerForm"
				(ngSubmit)="onSubmit()"
				class="ui inverted form"
			>
				<div class="sixteen wide field">
					<label>Name</label>
					<div class="two fields">
						<div class="field">
							<input
								type="text"
								formControlName="first_name"
								placeholder="First Name"
							/>
						</div>

						<div class="field">
							<input type="text" formControlName="last_name" placeholder="Last Name" />
						</div>
					</div>
				</div>

				<div class="sixteen wide field">
					<label>Email</label>
					<input
						required
						formControlName="email"
						type="email"
						readonly=""
						placeholder="Email"
					/>
				</div>

				<div class="sixteen wide field">
					<label>Password</label>
					<div class="two fields">
						<div class="field">
							<input
								required
								formControlName="password"
								type="password"
								placeholder="Password"
							/>
						</div>

						<div class="field">
							<input
								required
								formControlName="confirm_password"
								type="password"
								placeholder="Confirm Password"
							/>
						</div>
					</div>
				</div>

				<div *ngIf="passwordMatchError" class="ui negative message">
					<p>Passwords do not match</p>
				</div>

				<div *ngIf="verify_error || error" class="ui negative message">
					<p>{{ verify_error || error }}</p>
				</div>

				<button
					class="ui"
					[ngClass]="
						pending ? 'loading button disabled blue login' : 'button blue login'
					"
					type="submit"
					[disabled]="!registerForm.valid"
				>
					Sign Up
				</button>

				<p class="signin">
					<span class="blend">Already have an account? </span>
					<a routerLink="/signin">Sign In</a>
				</p>
			</form>
		</div>
		<div *ngIf="limited_user && limited_user.registered" class="ui message">
			<div *ngIf="limited_user.email_confirmed">
				<div class="header">Email Confirmed</div>
				<p>We successfully confirmed your email.</p>
				<p>
					Please use your account email to sign in on the
					<a routerLink="/signin">Signin Page.</a>
				</p>
			</div>
		</div>
	`,
	styleUrls: ["./index.scss"],
})
export class RegisterInputComponent implements OnInit, OnChanges {
	registerForm: UntypedFormGroup;

	@Input() error: string;
	@Input() pending: boolean;
	@Input() verify_error: string;
	@Input() verify_pending: boolean;
	@Input() registrationKey: string;
	@Input() limited_user: LimitedUser;

	@Output() register = new EventEmitter<UserRegister>();

	passwordMatchError = false;

	constructor(private readonly _fb: FormBuilder) {}

	ngOnInit() {
		this.registerForm = this._fb.group({
			first_name: [this.first_name, Validators.required],
			last_name: [this.last_name],
			email: [this.email, Validators.required],
			password: [null, Validators.required],
			confirm_password: [null, Validators.required],
			registration_key: [this.registrationKey, Validators.required],
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!this.registerForm) {
			return;
		}

		if (changes.limited_user) {
			this.registerForm.patchValue({
				email: this.limited_user.email,
			});
			if (this.limited_user.first_name) {
				this.registerForm.patchValue({
					first_name: this.limited_user.first_name,
				});
			}
			if (this.limited_user.last_name) {
				this.registerForm.patchValue({
					last_name: this.limited_user.last_name,
				});
			}
			if (this.limited_user.registered) {
				this.registerForm.patchValue({
					registered: this.limited_user.registered,
				});
			}
			if (this.limited_user.email_confirmed) {
				this.registerForm.patchValue({
					email_confirmed: this.limited_user.email_confirmed,
				});
			}
		}
		if (changes.registrationKey) {
			this.registerForm.patchValue({
				registration_key: this.registrationKey,
			});
		}
	}

	onSubmit() {
		if (this.registerForm.valid) {
			// console.log(this.registerForm.value);
			const formValue = this.registerForm.value;

			if (formValue.password === formValue.confirm_password) {
				this.passwordMatchError = false;
			} else {
				this.passwordMatchError = true;
				delete formValue.password_compare;
				delete formValue.password;
				return;
			}

			delete formValue.password_compare;
			const value: UserRegister = formValue;
			// console.log("Registration Form", value);
			this.register.emit(value);
		}
	}

	get email(): string {
		return this.limited_user && this.limited_user.email
			? this.limited_user.email
			: "";
	}

	get first_name(): string {
		return this.limited_user && this.limited_user.first_name
			? this.limited_user.first_name
			: "";
	}

	get last_name(): string {
		return this.limited_user && this.limited_user.last_name
			? this.limited_user.last_name
			: "";
	}

	// ngOnInit() {
	//   this.location$.subscribe(location=> this.model.location_id = location.id);
	// }
}
