import { Action, combineReducers } from "@ngrx/store";

import { pagingReducers, PagingState } from "./paging";
import { selectionReducers, SelectionState } from "./selection";

export interface State {
	entities: PagingState;
	selections: SelectionState;
}

// export const getInitialState = () => initialState.entities;

// export const getInitialStateByEntity = (entity: string) =>
// 	initialState.entities[entity];

export const reducer = (state: State, action: Action) => {
	return combineReducers({
		entities: pagingReducers,
		selections: selectionReducers,
	})(state, action);
};

export const getEntities = (state: State) => state.entities;

export const getSelections = (state: State) => state.selections;
