import { ILocation } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.Locations;

export type State = SelectionState<ILocation>;

export const reducer = createSelectionReducer<ILocation>(reducerKey);
