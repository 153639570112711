import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { ReplaySubject, EMPTY } from "rxjs";
import { tap, take, takeUntil, timeout, catchError } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { handleObservableError } from "app/shared/utils";
import { Plant } from "@elevatedsignals/amygoodman";
import { PlantDetailQuery } from "app/shared/eagers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";

@Component({
	selector: "plant-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PlantUpdateComponent
	extends GenericUpdateComponent<Plant>
	implements OnInit, OnDestroy
{
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();
	plant$ = this._store.select(fromDashboard.getSelectedPlant);
	plant: Plant;

	form_title = "Update a Plant";
	form_title_translation_key: string = marker("form_title_update_a_plant");
	submit_button = "Update Plant";
	submit_button_translation_key: string = marker("form_button_update_plant");
	submit_icon = "edit";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name (optional)",
				title_translation_key: marker("form_field_label_name_optional"),
			},
			is_mother: {
				type: "boolean",
				title: "Is Mother",
				title_translation_key: marker("form_field_label_is_mother"),
				widget: "checkbox",
				default: false,
			},
			is_male: {
				type: "boolean",
				title: "Is Male",
				title_translation_key: marker("form_field_label_is_male"),
				widget: "checkbox",
				default: false,
			},
		},
	};

	validators = {};

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		protected _cd: ChangeDetectorRef,
	) {
		super(_store, _cd);
	}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		this.plant$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((plant: Plant) => {
				this.plant = plant;
				this.model = {
					...this.model,
					id: this.plant.id,
					name: this.plant.name,
					is_mother: this.plant.is_mother,
					is_male: this.plant.is_male,
				};
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const plant: Partial<Plant> = {
			...this.model,
			location: "BATCH",
		};
		this.updateItem(plant);
	}

	onChanges(_error): void {}

	updateItem(add_plant: Partial<Plant>) {
		this.loading$.next(true);
		this._itemService
			.update(`plant`, this.plant.id, add_plant, PlantDetailQuery)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "plants",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
