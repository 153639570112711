import { tap, take, takeUntil, timeout, catchError } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { EMPTY } from "rxjs";
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { IStatus } from "@elevatedsignals/amygoodman";
import { StatusDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "status-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class StatusUpdateComponent
	extends GenericUpdateComponent<IStatus>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			// vendor_ids: {
			// 	type: "array",
			// 	title: "Vendors",
			// 	widget: "data-select",
			// 	items: {
			// 		type: "number",
			// 		oneOf: [
			// 			{
			// 				result_type: "vendors",
			// 				text_key: ["id", "name"],
			// 			},
			// 		],
			// 	},
			// },
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
			is_blocking: {
				type: "boolean",
				widget: "checkbox",
				title: "Is Blocking",
				title_translation_key: marker("form_field_label_is_blocking"),
			},
		},
		required: ["name"],
	};

	status$ = this._store.select(fromDashboard.getSelectedStatus);
	status: IStatus;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
	) {
		super(_store, _cd);
		this.form_title = "Update Status";
		this.form_title_translation_key = marker("form_title_update_status");
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.status$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((status: IStatus) => {
				this.status = status;
				this.model.id = this.status.id;
				this.model.name = this.status.name;
				this.model.archived = this.status.archived;
				this.model.is_blocking = this.status.is_blocking;
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(type: Partial<IStatus>) {
		const updated_category: Partial<IStatus> = {
			name: type.name || undefined,
			archived: type.archived ?? false,
			is_blocking: type.is_blocking ?? false,
		};

		this.loading$.next(true);
		this._itemService
			.update(`status`, type.id!, updated_category, StatusDetailQuery)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "statuses",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
