import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, Injector, OnDestroy } from "@angular/core";
import { ILot } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { LotDetailQuery } from "app/shared/eagers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "lot-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class LotCreateComponent
	extends GenericCreateComponent<ILot>
	implements OnDestroy
{
	specific_field_id: string;

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
			},
			lot_expires: {
				type: "boolean",
				widget: "checkbox",
				title: "Lot Expires?",
				title_translation_key: marker("form_field_label_lot_expires"),
				default: false,
			},
			expires: {
				type: "string",
				title: "Expiry Date",
				title_translation_key: marker("form_field_label_expiry_date"),
				widget: "date",
				visibleIf: {
					lot_expires: [true],
				},
			},
		},
		required: ["name"],
	};

	constructor(
		private readonly _injector: Injector,
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
	) {
		super(_store);
		this.form_title = "Add an Inventory Tracking Lot";
		this.form_title_translation_key = marker(
			"form_title_add_an_inventory_tracking_lot",
		);
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");

		this.specific_field_id = this._injector.get("specific_field_id", null);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	createItem(lot: Partial<ILot>) {
		this.loading$.next(true);
		this._itemService
			.add(`lots`, lot, LotDetailQuery)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							result_type: "lots",
							addedItem,
							...(this.specific_field_id && {
								specific_field_id: this.specific_field_id,
							}),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
