import { take, timeout, catchError, EMPTY } from "rxjs";

export const fetchNewInventoryTotals = (whatChanged, _itemService) => {
	const inventoryProductId = whatChanged.inventory_product_id?.currentValue;

	const lotId = whatChanged.lot_id?.currentValue;
	const batchId = whatChanged.batch_id?.currentValue;
	const inventoryIds = whatChanged.inventory_ids?.currentValue;
	const timestamp = whatChanged.timestamp?.currentValue;
	const vendor_id = whatChanged.vendor_id?.currentValue;
	const sku_id = whatChanged.sku_id?.currentValue;

	if (inventoryProductId) {
		return _itemService
			.fetchItem(
				`inventory_product`,
				`${inventoryProductId}/available_inventory`,
				{
					...(inventoryIds?.length &&
						inventoryIds.length > 0 && {
							inventory_ids: inventoryIds.map((ea) => ea.toString()),
						}),
					...(lotId && { lot_id: `${lotId}` }),
					...(batchId && { batch_id: `${batchId}` }),
					...(timestamp && { timestamp: `${timestamp}` }),
					...(vendor_id && { vendor_id: `${vendor_id}` }),
					...(sku_id && { sku_id: `${sku_id}` }),
				},
			)
			.pipe(
				take(1),
				timeout(50000),
				catchError((error) => {
					/* eslint no-console: off */
					console.error(error);
					return EMPTY;
				}),
			);
	}

	// Return an observable even if no request is made
	return EMPTY;
};
