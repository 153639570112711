import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ApiService } from "app/modules/auth/services/api.service";
import { TranslateErrorService } from "app/shared/errors/handleError";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class ItemService {
	private translateErrorService = inject(TranslateErrorService);
	private http = inject(HttpClient);
	private _api = inject(ApiService);

	add(
		item_type: string,
		item: any,
		query: Record<string, string | string[]> | undefined = undefined,
		credentials: { username: string; password: string } | undefined = undefined,
	): Observable<any> {
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: credentials
				? `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`
				: "",
		});
		const url = this._api.item.add(item_type);
		return this.http
			.post(url, JSON.stringify(item), { headers, params: query })
			.pipe(
				map((res: any) => {
					return res || {};
				}),
				this.translateErrorService.catchAndTranslateError(
					"error_failed_to_add_item",
				),
			);

		// } else {
		// 	// Send multipart/form-data when files are uploaded
		// 	// console.log("Form files", report.files);
		// 	const files: any[] = item.file? [item.file] : item.files;
		// 	delete item.form_model.file;
		// 	delete item.form_model.files;
		// 	console.log("Files!: ", files);
		// 	//Create FormData object https://developer.mozilla.org/en-US/docs/Web/API/FormData
		// 	let formData: FormData = new FormData();
		// 	formData.append("form_type", item.form_type);
		// 	formData.append("form_schema_id", item.form_schema_id.toString());

		// 	// formData.append("files", blob);
		// 	for (const file of files) {
		// 		console.log("Appending ", file);
		// 		console.log("File type: " + typeof(file));
		// 		const blob = new Blob([file.data], {type: file.type});
		// 		formData.append("files[]", blob, file.name);
		// 	}

		// 	formData.append("form_model", JSON.stringify(item.form_model));

		// 	// console.log("Form Data Object ", formData);

		// 	// Append proper headers for request
		// 	headers.set("Authorization", this.token);
		// 	// headers.set('Content-Type', 'multipart/form-data');
		// 	headers.set("Cache-Control", "no-cache");

		// 	// Build request.
		// 	const url = this._api.item.add(item_type);
		// 	return this.http
		// 		.post(url, formData, { headers })
		// 		.map((res:any) => {
		// 			// If request fails, throw an Error that will be caught
		// 			if (res.status < 200 || res.status >= 300) {
		// 				throw handleError(res, "Failed to add record. ");
		// 			} else {
		// 				// If everything went fine, return the response
		// 				return res || {};
		// 			}
		// 		})
		// 		.catch(error => handleError(error, "Failed to add record."));
		// }
	}

	fetchItem(
		item_type: string,
		item_id: number | string,
		query: Record<string, string | string[]> | undefined = undefined,
	): Observable<any> {
		// console.log("Fetching item");
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
		});

		const url = this._api.item.get(item_type, item_id);
		return this.http
			.get(url, { headers, params: query, observe: "response" })
			.pipe(
				map((response: HttpResponse<any>) => {
					return response.body || {};
				}),
				this.translateErrorService.catchAndTranslateError(
					"error_failed_to_fetch_item",
				),
			);
	}

	fetchItems(
		item_type: string,
		item_ids: (number | string)[],
		query: Record<string, string | string[]> | undefined = undefined,
	): Observable<any[]> {
		// console.log("Fetching items: " + item_type);
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
		});
		const url = this._api.items.get(item_type, item_ids);
		return this.http.get(url, { headers, params: query }).pipe(
			map((res: any) => {
				return res || [];
			}),
			this.translateErrorService.catchAndTranslateError(
				"error_failed_to_fetch_items",
			),
		);
	}

	update(
		item_type: string,
		item_id: number | string,
		item: any,
		search: Record<string, string | string[]> | undefined = undefined,
		credentials: { username: string; password: string } | undefined = undefined,
	): Observable<any> {
		// console.log("Updating item:", item);
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: credentials
				? `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`
				: "",
		});
		const url = this._api.item.update(item_type, item_id);
		return this.http
			.patch(url, JSON.stringify(item), { headers, params: search })
			.pipe(
				map((res: any) => {
					return res || {};
				}),
				this.translateErrorService.catchAndTranslateError(
					"error_failed_to_update_item",
				),
			);
	}

	delete(
		item_type: string,
		item_id: number | string,
		query: Record<string, string | string[]> | undefined = undefined,
		credentials: { username: string; password: string } | undefined = undefined,
	): Observable<any> {
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
			Authorization: credentials
				? `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`
				: "",
		});
		const url = this._api.item.delete(item_type, item_id);
		return this.http.delete(url, { headers, params: query }).pipe(
			map((res: any) => {
				return res || {};
			}),
			this.translateErrorService.catchAndTranslateError(
				"error_failed_to_delete_item",
			),
		);
	}
}
