/* jscpd:ignore-start */
import { ReplaySubject, EMPTY } from "rxjs";
import { tap, take, takeUntil, timeout, catchError } from "rxjs/operators";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { handleObservableError } from "app/shared/utils";
import { IDestructionLot } from "@elevatedsignals/amygoodman";
import { Globals } from "app/shared/modules/globals/globals.service";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
/* jscpd:ignore-end */

@Component({
	selector: "destruction-lot-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class DestructionLotUpdateComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	destruction_lot$ = this._store.select(fromDashboard.getSelectedDestructionLot);
	destruction_lot: IDestructionLot | null;

	form_title = "Update a Destruction Lot";
	form_title_translation_key: string = marker(
		"form_title_update_a_destruction_lot",
	);

	submit_button = "Update Destruction Lot";
	submit_button_translation_key: string = marker(
		"form_button_update_destruction_lot",
	);

	submit_icon = "edit";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name (optional)",
				title_translation_key: marker("form_field_label_name_optional"),
				warning: "Warning: Destruction Lot name already in use.",
				warning_translation_key: marker(
					"form_field_warning_destruction_lot_name_already_in_use",
				),
			},
			destruction_method: {
				type: "string",
				title: "Destruction Method",
				title_translation_key: marker("form_field_label_destruction_method"),
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
		},
		required: [],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private names;

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
	) {}

	valid(v) {
		this.valid$.next(!v);
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			// To get around destruction_method not being optional
			delete (this.schema.properties as any).destruction_method;
		}

		this.destruction_lot$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((destruction_lot: IDestructionLot) => {
				this.destruction_lot = destruction_lot;
				this.model = destruction_lot;
			});

		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`destruction_lots/fetch/names`, "", {
				col: "name",
				except: this.destruction_lot?.name ?? "",
			})
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		this.updateDestructionLot(this.model);
	}

	onChanges(_error): void {}

	updateDestructionLot(add_destruction_lot: IDestructionLot) {
		this.loading$.next(true);
		this._itemService
			.update("destruction_lot", this.destruction_lot!.id, add_destruction_lot)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((_item) => {
					this._store.dispatch(
						SelectionActions.select({
							result_type: "destruction_lots",
							id: Number(this.destruction_lot?.id),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}

	get id(): number {
		return this.destruction_lot ? this.destruction_lot.id : -1;
	}
}
