import { Pipe, PipeTransform } from "@angular/core";
import { User } from "@elevatedsignals/amygoodman";

@Pipe({
	name: "formatName",
})
export class FormatNamePipe implements PipeTransform {
	constructor() {}

	transform(user: User): string {
		let user_string = "";
		if (user.first_name) {
			user_string += user.first_name;
		}
		if (user.last_name) {
			user_string += ` ${user.last_name}`;
		}

		if (user_string === "") {
			return user.email;
		}

		return user_string;
	}
}
