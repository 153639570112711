import { IWorkOrderType } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.WorkOrderTypes;

export type State = SelectionState<IWorkOrderType>;

export const reducer = createSelectionReducer<IWorkOrderType>(reducerKey);
