import {Input, Component, inject} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ReplaySubject } from "rxjs";
import { tap, take } from "rxjs/operators";
import { ApiService } from "app/modules/auth/services/api.service";
import {TranslateErrorService} from "app/shared/errors/handleError";
import {
	IInventory,
	measureUnitDictToString,
	User,
} from "@elevatedsignals/amygoodman";
import { DateFormatService } from "app/modules/dashboard/services/date-format/dateFormat.service";
import { getUserInitials } from "app/shared/get-initials";

interface IAuditLog {
	id: number;
	action: string;
	created_at: string;
	user: User;
	data: any;
	note: any;
}

@Component({
	selector: "measure-event-history",
	templateUrl: "measure-event-history.template.html",
})
export class MeasureEventHistoryComponent {
	@Input() inventory: IInventory | undefined;

	@Input() weightUnit?: string;
	@Input() isSeed = false;

	public auditLog$ = new ReplaySubject<IAuditLog[]>();

  private translateErrorService = inject(TranslateErrorService);

	private _measureEventId: number;

	@Input() set measureEventId(measureEventId: number) {
		this._measureEventId = measureEventId;
		this.getHistory(this._measureEventId);
	}

	get measureEventId() {
		return this._measureEventId;
	}

	constructor(
		private readonly _api: ApiService,
		private readonly _dateFormatService: DateFormatService,
		private readonly _http: HttpClient,
	) {
	}

	getInitials(first?: string, last?: string) {
		return getUserInitials(first, last);
	}

	getHistory(entityId: number) {
		if (!entityId) {
			this.auditLog$.next([]);
			return false;
		}

		const headers = new HttpHeaders({
			"Content-Type": "application/json",
		});
		return this._http
			.get(this._api.audit.history("measure_event", entityId), { headers })
			.pipe(
				tap((res: IAuditLog[]) => this.auditLog$.next(res)),
        this.translateErrorService.catchAndTranslateError("Failed to retrieve history."),
				take(1),
			)
			.subscribe();
	}

	parseRelativeDate(date: string) {
		return this._dateFormatService.getFromNow(new Date(date));
	}

	getHistorySummary(log: IAuditLog): string {
		let summary = "";

		if (log.action === "INSERT") {
			summary = "created";
		}

		if (log.action === "UPDATE") {
			summary = "updated";
		}

		return summary;
	}

	formatMeasureData(log: IAuditLog) {
		if (this.inventory !== undefined) {
			return `${log.data.value} ${this.inventory.inventory_unit!.name}`;
		}

		const stringText = measureUnitDictToString(
			{
				MASS: 0,
				QUANTITY: 0,
				VOLUME: 0,
				[log.data.measure_unit]: log.data.value,
			},
			this.weightUnit,
			this.isSeed ? "seeds" : "quantity",
		);

		// Don't show a 0 in the weight ledger for substance entries like batch move
		if (stringText === "0") {
			return "";
		}
		return stringText;
	}
}
