import { HeaderQueryType, Page, Sensor } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "sensors";

export type State = Page<Sensor>;

export const reducer = createPagingReducer<Sensor>(reducerKey, {
	result_type: "sensors",
	title: "Sensor",
	title_translation_key: marker("page_title_sensors"),
	title_plural_translation_key: marker("page_title_plural_sensors"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Archived Filter",
			key: "archived_filter",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Show Active",
					translationKey: marker("sensor_filter_active"),
					value: "ACTIVE",
				},
				{
					label: "Show All",
					translationKey: marker("sensor_filter_all"),
					value: "ALL",
				},
				{
					label: "Show Archived",
					translationKey: marker("sensor_filter_archived"),
					value: "ARCHIVED",
				},
			],
			value: "ACTIVE",
		},
	],
});
