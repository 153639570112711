<ng-container *transloco="let t; read: 'customize_units_component'">
	<div class="ui grid dirty-mackenzie">
		<div class="sixteen wide column">
			<div class="left floated left aligned eight wide column">
				<button class="ui labeled icon button" (click)="closeSidenav()">
					<i class="left arrow icon"></i>
					{{ "word_cancel" | transloco }}
				</button>
			</div>
		</div>
		<div class="sixteen wide column">
			<h2>
				{{ "form_title_customize_inventory_units" | transloco }}
			</h2>
		</div>

		<div class="sixteen wide column">
			<form [formGroup]="unitsForm" class="ui form">
				<ng-container *ngFor="let unitsOfType of unitTypeBlocks | keyvalue">
					<ng-container *ngIf="unitsOfType.key == InventoryUnitType.Custom">
						<div class="eight wide field">
							<label>{{ t("display_unit") }}</label>
							<select formControlName="displayUnit">
								<ng-container *ngFor="let unit of availableUnits">
									<option value="{{ unit.getRawValue().unit_name }}">
										{{ unit.getRawValue().unit_name }}
									</option>
								</ng-container>
							</select>
						</div>
					</ng-container>
					<h4>{{ unitsOfType.key }} Units</h4>
					<table>
						<thead>
							<tr>
								<th colspan="1"></th>
								<th colspan="2"></th>
								<th colspan="2"></th>
								<th colspan="1"></th>
							</tr>
						</thead>
						<tbody [formArrayName]="unitsOfType.key.toLowerCase() + '_units'">
							<tr
								*ngFor="let unitControl of unitsOfType.value.controls; let i = index"
								[formGroupName]="i"
							>
								<td>
									<button
										[ngClass]="getReverseBtnClass(i, unitsOfType.key)"
										(click)="reverseConversion(i, unitsOfType.key)"
									>
										<i class="exchange alternate icon"></i>
									</button>
								</td>
								<ng-container
									*ngIf="
										!getUnitControlValue(i, unitsOfType.key).reverse_conversion;
										else reverseBlock
									"
								>
									<td>
										<div
											[ngClass]="unitControl.disabled ? 'ui disabled input' : 'ui input'"
										>
											<input type="text" formControlName="unit_name" />
										</div>
									</td>
									<td class="center aligned">=</td>
									<td>
										<div
											[ngClass]="unitControl.disabled ? 'ui disabled input' : 'ui input'"
										>
											<input type="number" formControlName="conversion" />
										</div>
									</td>
									<td>
										<ng-container *ngIf="unitControl.disabled; else selectBlock">
											{{ getUnitControlValue(i, unitsOfType.key).output_name }}
										</ng-container>
										<ng-template #selectBlock>
											<div
												[ngClass]="unitControl.disabled ? 'ui disabled input' : 'ui input'"
											>
												<select formControlName="output_name">
													<ng-container
														*ngFor="let unitSelect of getUnitOptions(i, unitsOfType.key)"
													>
														<option value="{{ unitSelect.getRawValue().unit_name }}">
															{{ unitSelect.getRawValue().unit_name }}
														</option>
													</ng-container>
												</select>
											</div>
										</ng-template>
									</td>
								</ng-container>
								<ng-template #reverseBlock>
									<td>
										<ng-container *ngIf="unitControl.disabled; else selectBlock">
											{{ getUnitControlValue(i, unitsOfType.key).output_name }}
										</ng-container>
										<ng-template #selectBlock>
											<div
												[ngClass]="unitControl.disabled ? 'ui disabled input' : 'ui input'"
											>
												<select formControlName="output_name">
													<ng-container
														*ngFor="let unitSelect of getUnitOptions(i, unitsOfType.key)"
													>
														<option value="{{ unitSelect.getRawValue().unit_name }}">
															{{ unitSelect.getRawValue().unit_name }}
														</option>
													</ng-container>
												</select>
											</div>
										</ng-template>
									</td>
									<td>=</td>
									<td>
										<div
											[ngClass]="unitControl.disabled ? 'ui disabled input' : 'ui input'"
										>
											<input type="number" formControlName="conversion" />
										</div>
									</td>
									<td>
										<div
											[ngClass]="unitControl.disabled ? 'ui disabled input' : 'ui input'"
										>
											<input type="text" formControlName="unit_name" />
										</div>
									</td>
								</ng-template>
								<td>
									<div class="text" *ngIf="canRemove(i, unitsOfType.key)">
										<button
											class="ui icon button"
											(click)="removeUnit(i, unitsOfType.key)"
										>
											<i class="close icon"></i>
										</button>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<ng-container
						*ngIf="unitsOfType.key == InventoryUnitType.Custom; else siAddUnit"
					>
						<button type="button" (click)="addUnit()" class="ui button">
							+ {{ "word_new" | transloco }}
						</button>
					</ng-container>
					<ng-template #siAddUnit>
						<div class="sixteen wide column">
							<ng-container
								*ngFor="let unitSI of getAvailableSIUnits(unitsOfType.key)"
							>
								<button type="button" (click)="addSIUnit(unitSI)" class="ui button">
									+ {{ unitSI }}
								</button>
							</ng-container>
						</div>
					</ng-template>
					<br />
					<br />
				</ng-container>
			</form>
		</div>

		<div class="sixteen wide column">
			<p>
				{{ "word_note" | transloco }}: <br />
				{{ t("if_a_unit_is_entered_more_than_once") }}
			</p>
		</div>

		<div class="sixteen wide column" *ngIf="error$ | async">
			<div class="ui error message">
				<span>{{ error$ | async }}</span>
			</div>
		</div>

		<div class="sixteen wide column" *ngIf="submit_button !== undefined">
			<submit-button
				[loading]="false"
				[valid]="true"
				[submit_button]="submit_button"
				[submit_button_translation_key]="submit_button_translation_key"
				(onSubmit)="onSubmit()"
			/>
		</div>
	</div>
</ng-container>
