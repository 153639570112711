import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, take, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { EquivalentUnit, IInventoryProduct } from "@elevatedsignals/amygoodman";
import {
	EquivalentUnitDetailQuery,
	InventoryProductDetailQuery,
} from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

@Component({
	selector: "equivalent-unit-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class EquivalentUnitUpdateComponent
	extends GenericUpdateComponent<EquivalentUnit>
	implements OnInit, OnDestroy
{
	inventory_product$ = this._store.select(
		fromDashboard.getSelectedInventoryProduct,
	);

	inventory_product: IInventoryProduct | undefined;

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			// ESS-6346: Refactor units to have multiple inventory_products
			inventory_product_id: {
				readOnly: true,
				type: "number",
				title: "Product",
				title_translation_key: marker("word_product"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "inventory_products",
					},
				],
			},
			input_inventory_unit_id: {
				readOnly: true,
				type: "number",
				title: "Unit 1",
				title_translation_key: marker("form_field_label_unit_1"),
				widget: "data-select",
				related_properties: ["inventory_product_id"],
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
			},
			conversion_constant: {
				type: "number",
				title: "Makes",
				minimum: 0.0000001,
				title_translation_key: marker("word_makes"),
			},
			output_inventory_unit_id: {
				readOnly: true,
				type: "number",
				title: "Unit 2",
				title_translation_key: marker("form_field_label_unit_2"),
				widget: "data-select",
				related_properties: ["inventory_product_id"],
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
			},
			reverse_conversion: {
				type: "boolean",
				title: "Reverse Conversion",
				title_translation_key: marker("form_field_label_reserve_conversion"),
				widget: "checkbox",
				default: false,
			},
			conversion_preview: {
				type: "string",
				widget: "section",
				title: " ",
			},
		},
	};

	equivalent_unit$ = this._store.select(fromDashboard.getSelectedEquivalentUnit);
	equivalent_unit: EquivalentUnit;
	result_type = "equivalent_unit";

	private reverse_conversion = false;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _injector: Injector,
		private readonly _itemService: ItemService,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store, _cd);

		this.form_title = "Update Equivalent Unit";
		this.form_title_translation_key = marker("form_title_update_equivalent_unit");
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
		this.result_type = this._injector.get("result_type", "equivalent_unit");
	}

	ngOnInit() {
		this.equivalent_unit$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((equivalent_unit) => {
				this.equivalent_unit = equivalent_unit;
				this.model.id = this.equivalent_unit.id;

				this.model.inventory_product_id =
					this.equivalent_unit.input_inventory_unit.inventory_product_id;
				this.model.input_inventory_unit_id =
					this.equivalent_unit.input_inventory_unit_id;
				this.model.conversion_constant = this.equivalent_unit.conversion_constant;
				this.model.output_inventory_unit_id =
					this.equivalent_unit.output_inventory_unit_id;

				this._cd.detectChanges();
			});
		this.inventory_product$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((inventory_product: IInventoryProduct) => {
				this.inventory_product = inventory_product;
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(model: Partial<EquivalentUnit> & { inventory_product_id: number }) {
		this.loading$.next(true);
		this._itemService
			.update(
				`equivalent_unit`,
				Number(model.id),
				model,
				EquivalentUnitDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "equivalent_units",
						}),
					);

					if (this.result_type === "inventory_product") {
						this._store.dispatch(
							SelectionActions.select({
								result_type: "inventory_products",
								id: model.inventory_product_id,
								query: InventoryProductDetailQuery,
							}),
						);
					}

					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	onChanges(_model) {
		if (this.model.reverse_conversion !== this.reverse_conversion) {
			_model.input_inventory_unit_id = this.model.output_inventory_unit_id;
			_model.output_inventory_unit_id = this.model.input_inventory_unit_id;
			this.reverse_conversion = _model.reverse_conversion;
		}
		if (
			this.model.input_inventory_unit_id &&
			this.model.output_inventory_unit_id &&
			this.model.conversion_constant &&
			this.inventory_product
		) {
			this.schema.properties.conversion_preview.title = `${this._translocoService.translate(
				"word_unit_conversion",
			)}: ${this.model.conversion_constant}${
				this.findInventoryUnitById(
					this.model.output_inventory_unit_id,
					this.inventory_product,
				)?.name
			} = ${
				this.findInventoryUnitById(
					this.model.input_inventory_unit_id,
					this.inventory_product,
				)?.name
			}`;
		}
	}

	findInventoryUnitById(
		inventory_unit_id: number,
		inventoryProduct: IInventoryProduct,
	) {
		for (const unit of inventoryProduct.inventory_units ?? []) {
			if (inventory_unit_id === unit.id) {
				return unit;
			}
		}
		for (const vp of inventoryProduct.virtual_products ?? []) {
			for (const unit of vp.inventory_units ?? []) {
				if (inventory_unit_id === unit.id) {
					return unit;
				}
			}
		}
		return undefined;
	}
}
