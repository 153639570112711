import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, take, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import { IVendorCategory } from "@elevatedsignals/amygoodman";
import { VendorCategoryDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";

@Component({
	selector: "vendor-category-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class VendorCategoryUpdateComponent
	extends GenericUpdateComponent<IVendorCategory>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			type: {
				type: "string",
				title: "Type",
				title_translation_key: marker("word_type"),
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
		},
		required: ["name"],
	};

	vendor_category$ = this._store.select(fromDashboard.getSelectedVendorCategory);

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
	) {
		super(_store, _cd);
		this.form_title = "Update Vendor Category";
		this.form_title_translation_key = marker("form_title_update_vendor_category");
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.vendor_category$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((vendor_category) => {
				this.model.id = vendor_category.id;
				this.model.name = vendor_category.name;
				this.model.archived = vendor_category.archived;
				this.model.type = vendor_category.type;
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(type: Partial<IVendorCategory>) {
		const updated_category: Partial<IVendorCategory> = {
			name: type.name,
			archived: type.archived,
			type: type.type,
			id: type.id,
		};

		this.loading$.next(true);
		this._itemService
			.update(
				`vendor_category`,
				type.id!,
				updated_category,
				VendorCategoryDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "vendor_categories",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
