import {
	IBillOfMaterial,
	HeaderQueryType,
	Page,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { BillOfMaterialPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "bill_of_materials";

export type State = Page<IBillOfMaterial>;

export const reducer = createPagingReducer<IBillOfMaterial>(reducerKey, {
	result_type: "bill_of_materials",
	title: "Bill of Material",
	title_translation_key: marker("page_title_bill_of_materials"),
	title_plural_translation_key: marker("page_title_plural_bill_of_materials"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "BOM Filter",
			key: "bom_filter",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Released",
					translationKey: marker("word_released"),
					value: "RELEASED",
				},
				{
					label: "Unreleased",
					translationKey: marker("word_unreleased"),
					value: "UNRELEASED",
				},
			],
			value: ["RELEASED", "UNRELEASED"],
			multiple: true,
		},
		{
			title: "Show Archived",
			key: "showArchived",
			title_translation_key: marker("checkbox_filter_show_archived"),
			type: HeaderQueryType.checkbox,
			value: false,
		},
	],
	query: BillOfMaterialPageQuery,
});
