import { marker } from "@jsverse/transloco-keys-manager/marker";

export const NewInventoryCreationSchema = {
	new_inventory: {
		type: "string",
		title: "Inventory Tracking",
		title_translation_key: marker("form_field_label_inventory_tracking"),
		widget: "select",
		oneOf: [
			{
				value: "Create New Inventory",
				enum: ["Create New Inventory"],
				name: "Create New Inventory",
				name_translation_key: marker("form_field_value_create_new_inventory"),
			},
			{
				value: "Use Existing Inventory",
				enum: ["Use Existing Inventory"],
				name: "Use Existing Inventory",
				name_translation_key: marker("form_field_value_use_existing_inventory"),
			},
		],
		default: "Create New Inventory",
	},

	create_new_inventory: {
		type: "boolean",
		title: "Creating New Inventory",
		title_translation_key: marker("form_field_label_creating_new_inventory"),
		widget: "checkbox",
		hidden: true,
		default: true,
		visibleIf: {
			new_inventory: ["Create New Inventory"],
		},
	},

	inventory_name: {
		type: "string",
		title: "New Inventory Name",
		title_translation_key: marker("form_field_label_new_inventory_name"),
		description: "Generated if left blank",
		description_translation_key: marker(
			"form_field_description_generated_if_left_blank",
		),
		widget: "string",
		visibleIf: {
			create_new_inventory: [true],
		},
	},

	inventory_id: (queryString: {
		inventory_product_id?: number;
		non_zero_inventory?: "true" | "false";
	}) => ({
		type: "number",
		title: "Destination Inventory",
		title_translation_key: marker("form_field_label_destination_inventory"),
		widget: "data-select",
		quick_create: false,
		related_properties: ["inventory_product_id"],
		oneOf: [{ result_type: "inventories", queryString }],
		visibleIf: { new_inventory: ["Use Existing Inventory"] },
	}),
};
