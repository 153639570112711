import { AuthActions } from "app/modules/auth/actions/auth.actions";
import { createReducer, on } from "@ngrx/store";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

export interface State {
	showSidenav: boolean;
	sidenavStack: any[];
	showDropdown: boolean;
	dropDownData: any;
	sidenavMaximized: boolean;
}

const initialState: State = {
	showSidenav: false,
	sidenavStack: [null],
	showDropdown: false,
	dropDownData: null,
	sidenavMaximized: false,
};

export const reducer = createReducer(
	initialState,
	on(layoutActions.maximizeSidenavRequested, (state, action) => ({
		...state,
		sidenavMaximized: action.expand,
	})),
	on(layoutActions.closeSidenav, (state) => {
		if (state.sidenavStack.length <= 1) {
			return {
				...state,
				showSidenav: false,
				sidenavStack: [null],
			};
		} else {
			return {
				...state,
				showSidenav: true,
				sidenavStack: state.sidenavStack.slice(0, state.sidenavStack.length - 1),
			};
		}
	}),
	on(layoutActions.closeAllSidenavs, (state) => {
		return {
			...state,
			showSidenav: false,
			sidenavStack: [null],
		};
	}),
	on(layoutActions.openSidenav, (state, action) => {
		const data = action;
		if (state.sidenavStack[0] === null) {
			return {
				...state,
				showSidenav: true,
				sidenavStack: [data],
			};
		}
		return {
			...state,
			showSidenav: true,
			sidenavStack: [...state.sidenavStack, data],
		};
	}),
	on(layoutActions.closeDropdown, (state) => ({
		...state,
		showSidenav: false,
		showDropdown: false,
		dropDownData: null,
	})),
	on(layoutActions.openDropdown, (state, action) => ({
		...state,
		showSidenav: false,
		showDropdown: true,
		dropDownData: action.inputs,
	})),
	on(AuthActions.logoutSuccess, () => initialState),
);

export const getShowSidenav = (state: State) => state.showSidenav;
export const getCurrentSidenavData = (state: State) =>
	state.sidenavStack.length > 0
		? state.sidenavStack[state.sidenavStack.length - 1]
		: null;
export const getSidenavStackData = (state: State) => state.sidenavStack;
export const getShowDropdown = (state: State) => state.showDropdown;
export const getDropdownComponent = (state: State) => state.showDropdown;
export const getDropdownData = (state: State) => state.dropDownData;
export const getSidenavMaximized = (state: State) => state.sidenavMaximized;
