import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, tap, takeUntil, take } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	ChangeDetectorRef,
	Component,
	Injector,
	OnDestroy,
} from "@angular/core";
import {
	InventoryProductDetailQuery,
	InventoryUnitDetailQuery,
} from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import {
	IInventoryProduct,
	MeasureUnit,
	MEASURE_UNIT_MAP,
} from "@elevatedsignals/amygoodman";
import { TitleCasePipe } from "@angular/common";
import { TranslocoService } from "@jsverse/transloco";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IInventoryUnitCreate {
	name: string;
	inventory_product_id: number;
	mapsTo: string;
	measure_unit?: string;
	value?: number;
	equivalent_inventory_unit_id?: number;
	conversion_constant?: number;
	si_units?: boolean;
	inventory_volume_unit?: string;
	inventory_weight_unit?: string;
	reverse_conversion?: boolean;
}
@Component({
	selector: "inventory-unit-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class InventoryUnitCreateComponent
	extends GenericCreateComponent<IInventoryUnitCreate>
	implements OnDestroy
{
	inventory_product$ = this._store.select(
		fromDashboard.getSelectedInventoryProduct,
	);

	inventory_product: IInventoryProduct;

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			si_units: {
				type: "boolean",
				widget: "checkbox",
				title: "Add SI unit?",
				title_translation_key: marker("form_field_label_add_si_unit"),
			},
			measure_type: {
				type: "string",
				title: "Measure Type",
				title_translation_key: marker("form_field_label_measure_type"),
				widget: "select",
				oneOf: [
					{
						name: this._titleCasePipe.transform(MeasureUnit.Mass),
						name_translation_key: marker("word_mass"),
						value: MeasureUnit.Mass,
						enum: [MeasureUnit.Mass],
					},
					{
						name: this._titleCasePipe.transform(MeasureUnit.Volume),
						name_translation_key: marker("word_volume"),
						value: MeasureUnit.Volume,
						enum: [MeasureUnit.Volume],
					},
				],
				default: MeasureUnit.Mass,
				visibleIf: {
					si_units: [true],
				},
			},
			inventory_volume_unit: {
				type: "string",
				title: "Select Unit",
				title_translation_key: marker("form_field_label_select_unit"),
				widget: "select",
				oneOf: ["l", "ml", "gallons"].map((unit) => {
					return {
						name: unit,
						value: unit,
						enum: [unit],
					};
				}),
				visibleIf: {
					allOf: [
						{
							si_units: [true],
						},
						{
							measure_type: [MeasureUnit.Volume],
						},
					],
				},
			},
			inventory_weight_unit: {
				type: "string",
				title: "Select Unit",
				title_translation_key: marker("form_field_label_select_unit"),
				widget: "select",
				oneOf: ["g", "kg", "mg", "lbs"].map((unit) => {
					return {
						name: unit,
						value: unit,
						enum: [unit],
					};
				}),
				visibleIf: {
					allOf: [
						{
							si_units: [true],
						},
						{
							measure_type: [MeasureUnit.Mass],
						},
					],
				},
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				visibleIf: {
					si_units: [false],
				},
			},
			// ESS-6346: Refactor units to have multiple inventory_products
			inventory_product_id: {
				type: "number",
				title: "Product",
				title_translation_key: marker("word_product"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "inventory_products",
					},
				],
			},
			conversion_constant: {
				type: "number",
				title: "Makes",
				minimum: 0.0000001,
				title_translation_key: marker("word_makes"),
				visibleIf: {
					disconnected_unit: false,
				},
				readOnly: false,
			},
			equivalent_inventory_unit_id: {
				type: "number",
				title: "Converts to",
				title_translation_key: marker("form_field_label_converts_to"),
				widget: "data-select",
				related_properties: ["inventory_product_id"],
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
				visibleIf: {
					disconnected_unit: false,
				},
			},
			reverse_conversion: {
				type: "boolean",
				title: "Reverse Conversion",
				title_translation_key: marker("form_field_label_reserve_conversion"),
				widget: "checkbox",
				default: false,
			},
			disconnected_unit: {
				type: "boolean",
				title: "Create disconnected unit",
				title_translation_key: marker("form_field_label_create_disconnected_unit"),
				widget: "checkbox",
				description:
					"This unit will not be equated to an existing display unit and will be tracked separately.",
				description_translation_key: marker(
					"form_field_description_this_unit_will_not_be_equated_to_an_existing_display_unit",
				),
				default: false,
			},
			conversion_preview: {
				type: "string",
				widget: "section",
				title: " ",
			},
		},
		anyOf: [
			{
				required: [
					"name",
					"inventory_product_id",
					"equivalent_inventory_unit_id",
					"conversion_constant",
				],
			},
			{
				required: ["name", "inventory_product_id", "disconnected_unit"],
			},
			{
				required: [
					"si_units",
					"inventory_product_id",
					"inventory_volume_unit",
					"equivalent_inventory_unit_id",
					"conversion_constant",
				],
			},
			{
				required: [
					"si_units",
					"inventory_product_id",
					"inventory_volume_unit",
					"disconnected_unit",
				],
			},
			{
				required: [
					"si_units",
					"inventory_product_id",
					"inventory_weight_unit",
					"equivalent_inventory_unit_id",
					"conversion_constant",
				],
			},
			{
				required: [
					"si_units",
					"inventory_product_id",
					"inventory_weight_unit",
					"disconnected_unit",
				],
			},
		],
	};

	specific_field_id: string;
	result_type: "inventory_units" | "inventory_product" = "inventory_units";

	private prevUnit: string;
	private prevEquivalentUnitId: string;
	private readonly allowedSIUnits: string[] = [
		"mg",
		"g",
		"kg",
		"lbs",
		"ml",
		"l",
		"gallons",
	];

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		protected _cd: ChangeDetectorRef,
		private readonly _injector: Injector,
		private readonly _titleCasePipe: TitleCasePipe,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store);
		this.form_title = "Create Inventory Units";
		this.form_title_translation_key = marker("form_title_create_inventory_units");
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
		this.specific_field_id = this._injector.get("specific_field_id", null);
	}

	ngOnInit() {
		const inventory_product_id = this._injector.get("inventory_product_id", null);
		this.result_type = this._injector.get("result_type", "inventory_units");
		if (inventory_product_id) {
			this.model = {
				...this.model,
				inventory_product_id,
			};
			this.schema.properties = {
				...this.schema.properties,
				inventory_product_id: {
					...this.schema.properties.inventory_product_id,
					readOnly: true,
				},
			};
			this._cd.detectChanges();
			this.inventory_product$
				.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
				.subscribe((inventory_product) => {
					this.inventory_product = inventory_product;
					this.model.equivalent_inventory_unit_id =
						this.inventory_product.display_unit_id;
					this._cd.detectChanges();
				});
		}
	}

	onChanges(_model) {
		const unitName =
			this.model.name ??
			this.model.inventory_weight_unit ??
			this.model.inventory_volume_unit;
		const changed =
			this.prevEquivalentUnitId !== this.model.equivalent_inventory_unit_id ||
			this.prevUnit !== unitName;
		if (changed) {
			this.schema.properties.conversion_constant.readOnly = false;
			if (unitName && this.model.equivalent_inventory_unit_id) {
				for (const measure_type of Object.values(MEASURE_UNIT_MAP)) {
					// To not confuse Grams with Gallons
					if ("gallons" in measure_type) {
						delete measure_type.g;
					}
					if (unitName in measure_type && this.allowedSIUnits.includes(unitName)) {
						this._itemService
							.fetchItem(
								`inventory_unit`,
								`${this.model.equivalent_inventory_unit_id}`,
							)
							.pipe(
								takeUntil(this.destroyed$),
								timeout(50000),
								catchError((error) => {
									/* eslint no-console: off */
									console.error(error);
									return EMPTY;
								}),
							)
							.subscribe((equivalent_unit) => {
								if (
									equivalent_unit.name in measure_type &&
									this.allowedSIUnits.includes(equivalent_unit.name)
								) {
									this.model.conversion_constant =
										measure_type[equivalent_unit.name]!.conversion /
										measure_type[unitName]!.conversion;
									this.schema.properties.conversion_constant.readOnly = true;
								} else {
									this.schema.properties.conversion_constant.readOnly = false;
								}
								this._cd.detectChanges();
							});
						break;
					}
				}
			}
			this.prevUnit = unitName;
			this.prevEquivalentUnitId = this.model.equivalent_inventory_unit_id;
			this._cd.detectChanges();
		}
		if (
			unitName &&
			this.model.equivalent_inventory_unit_id &&
			this.model.conversion_constant
		) {
			const equivalentUnitName = this.findInventoryUnitById(
				this.model.equivalent_inventory_unit_id,
				this.inventory_product,
			)?.name;
			const unit1 = this.model.reverse_conversion ? unitName : equivalentUnitName;
			const unit2 = this.model.reverse_conversion ? equivalentUnitName : unitName;
			this.schema.properties.conversion_preview.title = `${this._translocoService.translate(
				"word_unit_conversion",
			)}: ${this.model.conversion_constant}${unit1} = ${unit2}`;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	createItem(inventory_unit: IInventoryUnitCreate) {
		this.loading$.next(true);
		let query: any = null;
		switch (this.result_type) {
			case "inventory_product":
				query = InventoryProductDetailQuery;
				break;
			default:
				query = InventoryUnitDetailQuery;
		}
		this._itemService
			.add(
				`inventory_units`,
				{
					...inventory_unit,
					...(inventory_unit.si_units && {
						name:
							inventory_unit.inventory_weight_unit ??
							inventory_unit.inventory_volume_unit,
					}),
					result_type: this.result_type,
				},
				query,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					switch (this.result_type) {
						case "inventory_product":
							this._store.dispatch(
								ItemActions.updateSuccess({
									updatedItem,
									result_type: "inventory_products",
								}),
							);
							break;
						default:
							this._store.dispatch(
								ItemActions.addSuccess({
									result_type: this.result_type,
									addedItem: updatedItem,
									...(this.specific_field_id && {
										specific_field_id: this.specific_field_id,
									}),
								}),
							);
					}
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	findInventoryUnitById(
		inventory_unit_id: number,
		inventoryProduct: IInventoryProduct,
	) {
		for (const unit of inventoryProduct.inventory_units ?? []) {
			if (inventory_unit_id === unit.id) {
				return unit;
			}
		}
		for (const vp of inventoryProduct.virtual_products ?? []) {
			for (const unit of vp.inventory_units ?? []) {
				if (inventory_unit_id === unit.id) {
					return unit;
				}
			}
		}
		return undefined;
	}
}
