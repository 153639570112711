import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, OnDestroy, Injector } from "@angular/core";
import { IApplicableProduct } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "applicable-product-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class ApplicableProductCreateComponent
	extends GenericCreateComponent<IApplicableProduct>
	implements OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: Applicable Product name already in use.",
				warning_translation_key: marker(
					"form_field_warning_applicable_product_name_already_in_use",
				),
			},
			product_type: {
				type: "string",
				title: "Applicable Product Type",
				title_translation_key: marker("word_applicable_product_type"),
				widget: "select",
				oneOf: [
					{
						value: "BIO_CONTROL",
						enum: ["BIO_CONTROL"],
						name_translation_key: marker("word_bio_control"),
						name: "Bio Control",
					},
					{
						value: "PEST_CONTROL",
						enum: ["PEST_CONTROL"],
						name_translation_key: marker("word_pest_control"),
						name: "Pest Control",
					},
					{
						value: "SANITATION_PRODUCT",
						enum: ["SANITATION_PRODUCT"],
						name_translation_key: marker("word_sanitation_product"),
						name: "Sanitation Product",
					},
					{
						value: "NUTRIENTS",
						enum: ["NUTRIENTS"],
						name_translation_key: marker("word_nutrients"),
						name: "Nutrients",
					},
				],
			},
			application_method_ids: {
				type: "array",
				title: "Application Method",
				title_translation_key: marker("word_application_method"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "application_methods",
							text_key: ["name", "id"],
							text_format: "?1 (#?2)",
						},
					],
				},
			},
		},
		required: ["name", "product_type"],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	specific_field_id: string | null;

	private names;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store);
		this.form_title = "Create an Applicable Product";
		this.form_title_translation_key = marker(
			"form_title_create_an_applicable_product",
		);
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
		this.specific_field_id = this._injector.get("specific_field_id", null);
	}

	ngOnInit() {
		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`applicable_products/fetch/names`, "", { col: "name" })
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	createItem(applicable_product: Partial<IApplicableProduct>) {
		this.loading$.next(true);
		this._itemService
			.add(`applicable_products`, applicable_product)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							result_type: "applicable_products",
							addedItem,
							...(this.specific_field_id && {
								specific_field_id: this.specific_field_id,
							}),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
