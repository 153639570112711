import { takeUntil, tap, timeout, catchError } from "rxjs/operators";
import { ChangeDetectorRef, Component, Injector } from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { Store } from "@ngrx/store";
import { findIndex } from "app/modules/dashboard/vendor/lodash";
import { handleObservableError } from "app/shared/utils";
import { ReplaySubject, EMPTY } from "rxjs";
import { Batch } from "@elevatedsignals/amygoodman";
import { BatchDetailQuery } from "app/shared/eagers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

@Component({
	selector: "batch-adjustment-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class BatchAdjustmentUpdateComponent extends GenericUpdateComponent<Batch> {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	batch_id: number;
	item_index: number;
	reason: string;
	description: string;
	timestamp: string;

	form_title = "Edit Weight Adjustment";
	form_title_translation_key: string = marker(
		"form_title_edit_weight_adjustment",
	);

	submit_button = "Update";
	submit_button_translation_key: string = marker("word_update");
	submit_icon = "edit";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};
	adjustment_reasons: { name: string; name_translation_key?: string }[] = [
		{ name: "Drying Loss", name_translation_key: marker("word_drying_loss") },
		{
			name: "Lost/Theft",
			name_translation_key: marker("form_field_value_lost_theft"),
		},
		{
			name: "Lab Test",
			name_translation_key: marker("form_field_value_lab_test"),
		},
		{
			name: "Processing Loss",
			name_translation_key: marker("word_processing_loss"),
		},
		{ name: "Other", name_translation_key: marker("word_other") },
	];

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			batch_id: {
				type: "number",
				hidden: true,
			},
			reason: {
				type: "string",
				title: "Reason",
				title_translation_key: marker("word_reason"),
				widget: "select",
				oneOf: this.adjustment_reasons.map((item) => {
					return {
						name: item.name,
						name_translation_key: item.name_translation_key,
						value: item.name,
						enum: [item.name],
					};
				}),
			},
			reason_other: {
				type: "string",
				title: "Other Reason",
				title_translation_key: marker("form_field_label_other_reason"),
				visibleIf: {
					reason: ["Other"],
				},
				default: "",
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
			timestamp: {
				type: "string",
				title: "Adjustment Date",
				title_translation_key: marker("form_field_label_adjustment_date"),
				widget: "date",
			},
		},
		required: ["batch_id", "reason"],
	};

	validators = {};

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		protected _cd: ChangeDetectorRef,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
	}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		this.item_index = this._injector.get("item_index", null);
		this.batch_id = this._injector.get("batch_id", null);
		this.reason = this._injector.get("reason", null);
		this.description = this._injector.get("description", null);
		this.timestamp = this._injector.get("timestamp", null);

		this.model = {
			...this.model,
			batch_id: this.batch_id,
			reason:
				findIndex(this.adjustment_reasons, (item) => item.name === this.reason) >= 0
					? this.reason
					: "Other",
			reason_other:
				findIndex(this.adjustment_reasons, (item) => item.name === this.reason) >= 0
					? null
					: this.reason,
			description: this.description,
			timestamp: this.timestamp,
		};
		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const adjustment = {
			batch_id: this.model.batch_id,
			reason:
				this.model.reason === "Other"
					? this.model.reason_other || this.model.reason
					: this.model.reason,
			description: this.model.description,
			timestamp: this.model.timestamp,
			index: this.item_index,
		};
		this.updateItem(adjustment);
	}

	updateItem(update_batch: any) {
		this.loading$.next(true);
		this._itemService
			.update(
				`batch`,
				`${this.batch_id}/adjustment/edit`,
				update_batch,
				BatchDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "batches",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
