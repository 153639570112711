/* eslint-disable @typescript-eslint/naming-convention */
import { createActionGroup, emptyProps, props } from "@ngrx/store";

import { Auth } from "../models/auth.model";
import { PasswordReset } from "../models/password-reset.model";
import { LimitedUser } from "../models/limited-user.model";
import { ForgotPassword } from "../models/forgot-password.model";
import { UserSignin } from "../models/user-signin.model";
import { UserRegister } from "../models/user-register.model";

export const AuthActions = createActionGroup({
	source: "Auth",
	events: {
		"Navigation Success": props<{ success: boolean }>(),
		Register: props<{ payload: UserRegister }>(),
		"Register Success": props<{ payload: Auth }>(),
		"Register Fail": props<{ error: string }>(),
		"Verify Register Token": props<{ token: string }>(),
		"Verify Register Token Success": props<{ user: LimitedUser }>(),
		"Verify Register Token Fail": props<{ error: string }>(),
		Signin: props<{ signin: UserSignin }>(),
		"Signin Success": props<{ auth: Auth }>(),
		"Signin Fail": props<{ error: string }>(),
		"Forgot Password": props<{ payload: ForgotPassword }>(),
		"Forgot Password Success": emptyProps(),
		"Forgot Password Fail": props<{ error: string }>(),
		"Reset Password": props<{ payload: PasswordReset }>(),
		"Reset Password Success": emptyProps(),
		"Reset Password Fail": props<{ error: string }>(),
		"Verify Reset Token": props<{ token: string }>(),
		"Verify Reset Token Success": emptyProps(),
		"Verify Reset Token Fail": props<{ error: string }>(),
		Logout: emptyProps(),
		"Logout Success": emptyProps(),
		"Logout Redirect": emptyProps(),
		"Logout Fail": props<{ error: string }>(),
		// Clear Commands
		"Register Error Clear": emptyProps(),
		"Signin Error Clear": emptyProps(),
		Authenticate: emptyProps(),
		"Authenticate Success": props<{ auth: any }>(),
		"Authenticate Fail": props<{ error: string }>(),
	},
});
