import { ReplaySubject, EMPTY } from "rxjs";
import { tap, take, takeUntil, catchError, timeout } from "rxjs/operators";
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { Batch } from "@elevatedsignals/amygoodman";
import { Globals } from "app/shared/modules/globals/globals.service";
import { BatchDetailQuery } from "app/shared/eagers";
import { dateIsBefore } from "app/shared/time-format";
import { handleObservableError } from "app/shared/utils";
import { ESValidator } from "app/shared/es-validator";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

import { GramsToUnitPipe } from "../../../modules/es-pipes/grams-to-unit.pipe";
import { UnitToGramsPipe } from "../../../modules/es-pipes/unit-to-grams.pipe";

@Component({
	selector: "batch-split-weight",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})

// @deprecated
export class BatchSplitWeightComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	batch$ = this._store.select(fromDashboard.getSelectedBatch);
	batch: Batch;

	form_title = "Split a harvested batch";
	form_title_translation_key: string = marker(
		"form_title_split_a_harvested_batch",
	);

	submit_button = "Split Batch";
	submit_button_translation_key: string = marker("form_button_split_batch");
	submit_icon = "code branch";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	weight_unit: string = this._globals.weight_unit;

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			substance_type_id: {
				type: "number",
				title: "Substance Type",
				title_translation_key: marker("word_substance_type"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "substance_types",
					},
				],
			},
			split_type: {
				type: "string",
				title: "Split type",
				title_translation_key: marker("form_field_label_split_type"),
				widget: "select",
				oneOf: [
					{
						name: "Two New Batches",
						name_translation_key: marker("form_field_value_two_new_batches"),
						value: "NEW",
						enum: ["NEW"],
					},
					{
						name: "Offshoot Batch",
						name_translation_key: marker("form_field_value_offshoot_batch"),
						value: "OFFSHOOT",
						enum: ["OFFSHOOT"],
					},
				],
			},
			section1: {
				type: "string",
				widget: "section",
				title: "New Batch #1",
				title_translation_key: marker("form_field_label_new_batch_one"),
				visibleIf: {
					split_type: ["NEW"],
				},
			},
			section2: {
				type: "string",
				widget: "section",
				title: "New Batch",
				title_translation_key: marker("form_field_label_new_batch"),
				visibleIf: {
					split_type: ["OFFSHOOT"],
				},
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				visibleIf: {
					oneOf: [
						{
							split_type: ["OFFSHOOT"],
						},
						{
							split_type: ["NEW"],
						},
					],
				},
				default: "",
			},
			weight: {
				widget: "number",
				type: "number",
				step: "0.00001",
				title: `Weight(${this.weight_unit})`,
				title_translation_key: marker("form_field_label_weight"),
				title_translation_params: { weight_unit: this.weight_unit },
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
				visibleIf: {
					oneOf: [
						{
							split_type: ["NEW", "OFFSHOOT"],
						},
					],
				},
			},
			section3: {
				type: "string",
				widget: "section",
				title: "New Batch #2",
				title_translation_key: marker("form_field_label_new_batch_two"),
				visibleIf: {
					split_type: ["NEW"],
				},
			},
			name2: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				visibleIf: {
					split_type: ["NEW"],
				},
				default: "",
			},
			weight2: {
				readOnly: true,
				type: "number",
				widget: "number",
				step: "0.00001",
				title: `Weight(${this.weight_unit})`,
				title_translation_key: marker("form_field_label_weight"),
				title_translation_params: { weight_unit: this.weight_unit },
				visibleIf: {
					split_type: ["NEW"],
				},
			},
			description2: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
				visibleIf: {
					split_type: ["NEW"],
				},
			},
			timestamp: {
				type: "string",
				title: "Move Date",
				title_translation_key: marker("form_field_label_move_date"),
				widget: "date",
				minDate: new Date(),
			},
		},
		required: ["split_type", "name", "weight", "substance_type_id"],
	};

	validators: Record<string, ESValidator> = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/timestamp": (value, property, form) => {
			this.dateValidatorFailed = false;

			const isValueBefore = dateIsBefore(
				new Date(value),
				new Date(this.batch.created_at),
			);
			if (isValueBefore) {
				this.dateValidatorFailed = true;

				const error = {
					code: "INVALID_DATE",
					path: `#${property.path}`,
					message: "The date must be in the past",
					params: ["timestamp"],
				};
				return [error];
			}

			return null;
		},
	};

	private dateValidatorFailed: boolean;

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _unitToGrams: UnitToGramsPipe,
		private readonly _gramsToUnit: GramsToUnitPipe,
		private readonly _globals: Globals,
	) {}

	onError(_error) {}

	valid(val) {
		this.valid$.next(val);

		if (this.dateValidatorFailed) {
			this.valid$.next(false);
		}
	}

	ngOnInit() {
		this.batch$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((batch: Batch) => {
				this.batch = batch;
				// const split_weight = this.batch.dry_weight
				// 	? "Dry Weight"
				// 	: this.batch.wet_weight
				// 		? "Wet Weight"
				// 		: undefined;
				this.model = {
					id: this.batch.id,
					split_type: "OFFSHOOT",
					// split_weight,
				};
				this.schema.properties = {
					...this.schema.properties,
					name: {
						...this.schema.properties.name,
						default: `${this.batch.name}-${batch.child_batches!.length + 1}`,
					},
					// location_id: {
					// 	...this.schema.properties.location_id,
					// 	default: this.batch.location_id,
					// },
					name2: {
						...this.schema.properties.name2,
						default: `${this.batch.name}-${batch.child_batches!.length + 2}`,
					},
					timestamp: {
						...this.schema.properties.timestamp,
						minDate: new Date(this.batch.created_at),
					},
				};
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const split: any = {
			...this.model,
			weight: this._unitToGrams.transform(this.model.weight, this.weight_unit),
			weight2: this.model.weight2
				? this._unitToGrams.transform(this.model.weight2, this.weight_unit)
				: undefined,
		};
		this.updateBatch(split);
	}

	onChanges(form_data): void {
		const updated_schema_properties = {
			...this.schema.properties,
		};
		if (form_data.split_type === "NEW" && form_data.weight) {
			const form_weight = this._unitToGrams.transform(
				form_data.weight,
				this.weight_unit,
			);

			if (this.batch.adjusted_weight) {
				this.model = {
					...this.model,
					weight2: parseFloat(
						this._gramsToUnit.transform(
							this.batch.adjusted_weight - form_weight,
							this.weight_unit,
						),
					),
				};
				form_data.weight2 = this.model.weight2;
			} else if (this.batch.dry_weight) {
				this.model = {
					...this.model,
					weight2: parseFloat(
						this._gramsToUnit.transform(
							this.batch.dry_weight - form_weight,
							this.weight_unit,
						),
					),
				};
				form_data.weight2 = this.model.weight2;
			} else if (this.batch.wet_weight) {
				this.model = {
					...this.model,
					weight2: parseFloat(
						this._gramsToUnit.transform(
							this.batch.wet_weight - form_weight,
							this.weight_unit,
						),
					),
				};
				form_data.weight2 = this.model.weight2;
			} else if (this.model.substance_type_id) {
				const totalWeight =
					this.batch.measurements
						?.filter(
							(measureEvent) =>
								measureEvent.substance_type_id === this.model.substance_type_id,
						)
						.reduce((result, event) => result + event.value, 0) ?? 0;

				const weight2Calculated = parseFloat(
					this._gramsToUnit.transform(
						Number(totalWeight - form_weight),
						this.weight_unit,
					),
				);

				this.model = {
					...this.model,
					weight2: weight2Calculated < 0 ? 0 : weight2Calculated,
				};
				form_data.weight2 = this.model.weight2;
			}
		}
		this.schema.properties = updated_schema_properties;
	}

	updateBatch(update_batch: any) {
		this.loading$.next(true);
		this._itemService
			.update(
				`batch`,
				`${this.batch.id}/split_weight`,
				update_batch,
				BatchDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "batches",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
