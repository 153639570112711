import { HeaderQueryType, IVendor, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { VendorPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "vendors";

export type State = Page<IVendor>;

export const reducer = createPagingReducer<IVendor>(reducerKey, {
	result_type: "vendors",
	title: "Vendor",
	title_plural: "Vendors",
	title_translation_key: marker("page_title_vendors"),
	title_plural_translation_key: marker("page_title_plural_vendors"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Vendors Status",
			key: "vendor_status",
			type: HeaderQueryType.select,
			options: [
				{
					label: "ACTIVE Vendors",
					translationKey: marker("word_all"),
					value: "ACTIVE",
				},
				{
					label: "Internal Vendors",
					translationKey: marker("vendor_filter_archived"),
					value: "INTERNAL",
				},
				{
					label: "Customers",
					translationKey: marker("vendor_filter_archived"),
					value: "CUSTOMER",
				},
				{
					label: "Show Archived Vendors",
					translationKey: marker("vendor_filter_archived"),
					value: "ARCHIVED",
				},
			],
			value: "ACTIVE",
		},
		{
			title: "Show Archived",
			key: "showArchived",
			title_translation_key: marker("checkbox_filter_show_archived"),
			type: HeaderQueryType.checkbox,
			value: false,
		},
		{
			title: "Show Blocked Only",
			key: "showBlockingStatus",
			title_translation_key: marker("checkbox_filter_show_blocked_only"),
			type: HeaderQueryType.checkbox,
			value: false,
		},
	],
	query: VendorPageQuery,
});
