import { tap, takeUntil, skipWhile, timeout, catchError } from "rxjs/operators";
import { IPurchaseOrder, UserProfile } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { EMPTY, Observable } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnDestroy,
	OnInit,
	Injector,
} from "@angular/core";
import { PurchaseOrderDetailQuery } from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { PasswordInputComponent } from "app/modules/auth/components/password-input";
import { handleObservableError } from "app/shared/utils";
import { dateIsBefore } from "app/shared/time-format";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { DevCycleService } from "app/devcycle/devcycle.service";
import { DevCycleKey } from "app/devcycle/devcycleKeys";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

import { BatchCloseComponent } from "../batch/close.component";

@Component({
	selector: "purchase-order-end",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PurchaseOrderCloseComponent
	extends GenericUpdateComponent<IPurchaseOrder>
	implements OnInit, OnDestroy
{
	user$: Observable<UserProfile | null> = this._store.select(
		fromDashboard.getProfile,
	);

	user: UserProfile;

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/user_confirm": (value, property, form) => {
			if (this.model.remaining !== false && (value === false || value === null)) {
				return { user_confirm: {} };
			}
			return null;
		},
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/timestamp": (value, property, form) => {
			this.dateValidatorFailed = false;

			const isValueBefore = dateIsBefore(new Date(value), new Date(this.minDate));
			if (isValueBefore) {
				this.dateValidatorFailed = true;

				const error = {
					code: "INVALID_DATE",
					path: `#${property.path}`,
					message: "The date must be in the past",
					params: ["timestamp"],
				};
				return [error];
			}

			return null;
		},
	};

	private readonly purchase_order: IPurchaseOrder;
	private readonly minDate: Date;

	private dateValidatorFailed: boolean;
	private archiveOnClose = false;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
		private readonly _devCycleService: DevCycleService,
	) {
		super(_store, _cd);
		this.purchase_order = this._injector.get("purchase_order", null);
		this.minDate = this._injector.get("minDate", null);
		const order_type = this.purchase_order.is_outgoing ? "shipping" : "purchase";
		this.form_title = `Close purchase order #${this.purchase_order.id} order`;
		this.form_title_translation_key = marker(
			`form_title_close_${order_type}_order`,
		);
		this.form_title_translation_params = {
			purchase_order_id: `#${this.purchase_order.id}`,
		};
		this.submit_button = "Close";
		this.submit_button_translation_key = marker("word_close");

		this.schema = {
			title: "",
			info: "",
			properties: {
				id: {
					type: "number",
					hidden: true,
				},
				timestamp: {
					type: "string",
					title: "Closing Date",
					title_translation_key: marker("form_field_label_closing_date"),
					widget: "date",
					warning: `The date must be after all ${order_type} order events.`,
					warning_translation_key: marker(
						`form_field_warning_the_date_must_be_after_all_${order_type}_order_events`,
					),
				},
				remaining: {
					title: "Remaining Items",
					title_translation_key: marker("form_field_label_remaining_items"),
					type: "boolean",
					widget: "checkbox",
					readOnly: true,
					hidden: true,
					default: false,
				},
				user_confirm: {
					title: "I Understand",
					title_translation_key: marker("form_field_label_i_understand"),
					description: `There are pending inventory items in this ${order_type} order that have not been received. Are you sure you want to close this ${order_type} order?`,
					description_translation_key: marker(
						`form_field_warning_there_are_pending_inventory_items_in_this_${order_type}_order_that_have_not_been_received`,
					),
					type: "boolean",
					widget: "checkbox",
					visibleIf: {
						remaining: [true],
					},
				},
			},
		};

		this._devCycleService
			.getVariable(DevCycleKey.ArchiveBatchesOnCLose, false)
			.subscribe((variable: any) => {
				this.archiveOnClose = variable.value;
			});
	}

	valid(v) {
		this.valid$.next(v);

		if (this.dateValidatorFailed) {
			this.valid$.next(false);
		}
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}
		this.user$
			.pipe(
				takeUntil(this.destroyed$),
				skipWhile((user: UserProfile | null) => user == null),
			)
			.subscribe((user) => {
				if (user) {
					this.user = user;
				}
			});
		const hideRemainingFields = this.isReceived(this.purchase_order);
		if (!hideRemainingFields) {
			this.schema.required = ["user_confirm"];
			this.model.remaining = true;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(purchase_order: Partial<IPurchaseOrder>): void {
		if (this._globals.gmp_enabled) {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: PasswordInputComponent,
					inputs: {
						onSubmit: (password) => {
							this.updatePurchaseOrder(purchase_order, true, password);
						},
					},
				}),
			);
		} else {
			this.updatePurchaseOrder(purchase_order);
		}
	}

	updatePurchaseOrder(
		purchase_order: Partial<IPurchaseOrder>,
		signing = false,
		password?: string,
	) {
		const purchase_order_data = {
			...purchase_order,
			is_outgoing: this.purchase_order.is_outgoing,
			timestamp: (purchase_order as any).timestamp ?? new Date().toISOString(),
		};

		const authObject = password
			? { username: this.user.email, password }
			: undefined;
		this.loading$.next(true);
		this._itemService
			.update(
				`purchase_order`,
				`${this.purchase_order.id}/close`,
				purchase_order_data,
				signing
					? {
							...PurchaseOrderDetailQuery,
							signing: "true",
							is_outgoing: purchase_order.is_outgoing ? "true" : "false",
						}
					: {
							...PurchaseOrderDetailQuery,
							is_outgoing: purchase_order.is_outgoing ? "true" : "false",
						},
				authObject,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "purchase_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();

					if (updatedItem.is_outgoing) {
						const batch_ids: number[] = [];

						// get inventory batch_id
						for (const inventory of updatedItem.inventories ?? []) {
							for (const measurement of inventory.measurements ?? []) {
								if (measurement.credit_event?.inventory?.batch_id) {
									batch_ids.push(measurement.credit_event?.inventory?.batch_id);
									continue;
								}
							}
						}

						// get plant batch_id
						for (const plantOrder of updatedItem.plantOrders ?? []) {
							for (const plant of plantOrder.plants ?? []) {
								if (plant.batch_id && !batch_ids.includes(plant.batch_id)) {
									batch_ids.push(plant.batch_id);
								}
							}
						}

						if (batch_ids.length && this.archiveOnClose) {
							this.getEmptyBatch(batch_ids)
								.pipe(
									catchError(() => {
										return EMPTY;
									}),
								)
								.subscribe((batchesToClose) => {
									if (batchesToClose.length) {
										this._store.dispatch(
											layoutActions.openSidenav({
												component: BatchCloseComponent,
												inputs: {
													ids: batchesToClose,
													only_show_valid_batches: true,
													onUpdated: () => {
														this.closeSidenav();
													},
												},
											}),
										);
									} else {
										this.closeSidenav();
									}
								});
						}
					}
				}),
			)
			.subscribe();
	}

	isReceived(po: IPurchaseOrder) {
		return this.isInventoryReceived(po) && this.isPlantsReceived(po);
	}

	isInventoryReceived(po: IPurchaseOrder) {
		for (const inventory of po.inventories ?? []) {
			// The leftovers of the inventory should dictate if
			// the inventory is fully received
			if (inventory.totalAmount !== undefined && inventory.totalAmount > 0) {
				return false;
			}
		}
		return true;
	}

	isPlantsReceived(po: IPurchaseOrder) {
		for (const plantOrder of po.plantOrders ?? []) {
			if (
				plantOrder.plants &&
				plantOrder.plants.length < plantOrder.number_of_plants
			)
				return false;
		}
		return true;
	}

	getEmptyBatch(batch_ids): any {
		return this._itemService.fetchItem("batches/empty", `${batch_ids.join(";")}`);
	}
}
