import { ISeedLot } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.SeedLots;

export type State = SelectionState<ISeedLot>;

export const reducer = createSelectionReducer<ISeedLot>(reducerKey);
