import { IDestructionReason } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.DestructionReasons;

export type State = SelectionState<IDestructionReason>;

export const reducer = createSelectionReducer<IDestructionReason>(reducerKey);
