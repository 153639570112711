import { Component, OnInit, ChangeDetectorRef, Injector } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { handleObservableError } from "app/shared/utils";
import {
	timeout,
	catchError,
	take,
	takeUntil,
	tap,
	filter,
} from "rxjs/operators";
import { ReplaySubject, EMPTY } from "rxjs";
import { IProductionStage } from "@elevatedsignals/amygoodman";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

@Component({
	selector: "production-stage-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class ProductionStageUpdateComponent implements OnInit {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	production_stage$ = this._store.select(
		fromDashboard.getSelectedProductionStage,
	);

	production_stage: IProductionStage;
	id: number;

	form_title = "Update a Production Stage";
	form_title_translation_key: string = marker(
		"form_title_update_a_production_stage",
	);

	submit_button = "Update Production Stage";
	submit_button_translation_key: string = marker(
		"form_button_update_production_stage",
	);

	submit_icon = "edit";

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};
	form_disabled = false;

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				readOnly: false,
				warning: "Warning: Production Stage name already in use.",
				warning_translation_key: marker(
					"form_field_warning_production_stage_name_already_in_use",
				),
			},
			production_stage_type_id: {
				type: "number",
				minItems: 1,
				title: "Production Stage Type",
				title_translation_key: marker("word_production_stage_type"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "production_stage_types",
					},
				],
				readOnly: true,
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
		},
		required: ["name", "production_stage_type_id"],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private names;

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _injector: Injector,
	) {
		this.id = this._injector.get("id", null);
	}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		this.production_stage$
			.pipe(
				takeUntil(this.destroyed$),
				isNotNullOrUndefined(),
				filter(
					(production_stage): production_stage is IProductionStage =>
						production_stage.id === this.id,
				),
				take(1),
			)
			.subscribe((production_stage) => {
				this.production_stage = production_stage;
				this.model.id = this.production_stage.id;
				this.model.name = this.production_stage.name;
				this.model.production_stage_type_id =
					this.production_stage.production_stage_type_id;
				this.schema.properties.name.readOnly = Boolean(
					this.production_stage.facility_id,
				);
				this.model.archived = this.production_stage.archived;
				this._cd.detectChanges();
				this.getNames();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const production_stage: Partial<IProductionStage> = {
			...this.model,
		};
		this.updateProductionStage(production_stage);
	}

	onChanges(_error): void {}

	// Used for schema validation - duplicate Production Stage name check
	getNames() {
		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`production_stages/fetch/names`, "", {
				col: "name",
				except: this.model.name,
			})
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	updateProductionStage(add_production_stage: Partial<IProductionStage>) {
		this.loading$.next(true);
		this._itemService
			.update(`production_stage`, this.production_stage.id, add_production_stage)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "production_stages",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
