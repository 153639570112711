import { createReducer, on } from "@ngrx/store";

import { PrinterActions } from "../actions/printer.actions";

export interface State {
	facilityJobCount: number;
	api: {
		fetch: {
			error?: string | null;
			in_progress: boolean;
		};
	};
}

const initialState: State = {
	facilityJobCount: 0,
	api: {
		fetch: {
			in_progress: false,
		},
	},
};

export const reducer = createReducer(
	initialState,
	on(PrinterActions.fetchCount, (state) => {
		return {
			...state,
			api: {
				...state.api,
				fetch: {
					in_progress: true,
				},
			},
		};
	}),
	on(PrinterActions.fetchCountSuccess, (state, update) => ({
		...state,
		facilityJobCount: update.count,
		api: {
			...state.api,
			fetch: {
				in_progress: true,
			},
		},
	})),
);
