import { HeaderQueryType, Page, Record } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "../createPagingReducer";

export const reducerKey = "batch_related_records";

export type State = Page<Record>;

export const reducer = createPagingReducer<Record>(reducerKey, {
	result_type: "records",
	title: "Record",
	title_translation_key: marker("page_title_records"),
	title_plural_translation_key: marker("page_title_plural_records"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Show my records",
			title_translation_key: marker("checkbox_filter_show_my_records"),
			key: "myRecords",
			type: HeaderQueryType.checkbox,
			value: false,
		},
		{
			title: "Verifications",
			key: "verification_status",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Verified",
					translationKey: marker("word_verified"),
					value: "VERIFIED",
				},
				{
					label: "Unverified",
					translationKey: marker("word_unverified"),
					value: "UNVERIFIED",
				},
			],
			multiple: true,
			disabled: false,
			value: ["VERIFIED", "UNVERIFIED"],
		},
		{
			title: "Locations",
			title_translation_key: marker("page_title_plural_locations"),
			key: "location_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "locations",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		},
		{
			title: "Schema Type",
			title_translation_key: marker("page_title_schema_types"),
			key: "schema_types",
			type: HeaderQueryType.dataSelect,
			result_type: "schemas",
			value_key: "type",
			text_key: "name",
			hide_description: true,
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		},
		{
			title: "Start",
			title_translation_key: marker("word_start"),
			key: "start_date",
			type: HeaderQueryType.date,
			value: "",
		},
		{
			title: "End",
			title_translation_key: marker("word_end"),
			key: "end_date",
			type: HeaderQueryType.date,
			value: "",
		},
	],
});
