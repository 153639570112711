import { SubstanceTypes, MeasureUnit, baseMeasureUnitToUnit, getDefaultDisplayUnit } from "../measurement";
export const substanceDictToString = (substanceDict, weightUnit, oppositeSign = false) => {
  const result = [];
  for (const substance of Object.keys(substanceDict)) {
    const substanceValue = substanceDict[substance];
    if (substance === SubstanceTypes.Plants && (substanceValue === null || substanceValue === void 0 ? void 0 : substanceValue[MeasureUnit.Quantity]) && substanceValue[MeasureUnit.Quantity] > 0) {
      result.push(`${substanceValue[MeasureUnit.Quantity]} Plants`);
    } else if (substanceValue) {
      let line = measureUnitDictToString(substanceValue, weightUnit, undefined, oppositeSign);
      line += ` of ${substance}`;
      result.push(line);
    }
  }
  return result;
};
export const measureUnitDictToString = (measureDict, weightUnit, quantityUnit, oppositeSign = false) => {
  var _a;
  if (!measureDict) {
    return "N/A";
  }
  const result = [];
  const inventoryUnits = Object.keys(measureDict).filter(unit => measureDict[unit] !== 0 && !Object.values(MeasureUnit).includes(unit));
  if (inventoryUnits.length > 0) {
    return (_a = inventoryUnitDictToString(measureDict, oppositeSign)) !== null && _a !== void 0 ? _a : "0";
  }
  const units = Object.keys(measureDict).filter(unit => measureDict[unit] !== 0);
  if (units.length === 0) {
    return "0";
  }
  for (const [i, unit] of units.entries()) {
    const stringBuild = [baseMeasureUnitToUnit(measureDict[unit], unit, getDefaultDisplayUnit(unit, weightUnit, quantityUnit)), getDefaultDisplayUnit(unit, weightUnit, quantityUnit)].join(" ");
    result.push(stringBuild);
    if (i < units.length - 1) {
      result.push(` and `);
    }
  }
  return result.join("");
};
export const inventoryUnitDictToString = (measureDict, oppositeSign = false) => {
  if (!measureDict) {
    return "N/A";
  }
  const result = [];
  const units = Object.keys(measureDict).filter(unit => measureDict[unit] !== 0 && !Object.values(MeasureUnit).includes(unit));
  if (units.length === 0) {
    return null;
  }
  for (const [i, unit] of units.entries()) {
    result.push(`${measureDict[unit] * (oppositeSign ? -1 : 1)} ${unit}`);
    if (i < units.length - 1) {
      result.push(` and `);
    }
  }
  return result.join("");
};
