import { SimpleChange } from "@angular/core";
import _ from "lodash";

export const hasPropertyChanged = (property) => {
	return property?.previousValue !== property?.currentValue;
};

export const hasInventoryIdsChanged = (inventoryIds) => {
	return (
		Array.isArray(inventoryIds?.previousValue) &&
		Array.isArray(inventoryIds?.currentValue) &&
		!_.isEqual(inventoryIds?.previousValue, inventoryIds?.currentValue)
	);
};

/**
 * Checks if there has been a change of values in the SimpleChange object
 * @param change The SimpleChange key's value. current/previous values must be of type number, or a number array.
 * @returns true if there is change; false if no change
 */
export const didItemChange = (change: SimpleChange | undefined) => {
	// If an array is detected, convert both current and previous values to arrays for comparison.
	if (
		change &&
		(Array.isArray(change.currentValue) || Array.isArray(change.previousValue))
	) {
		let currentValue: number[];
		let previousValue: number[];
		if (Array.isArray(change.currentValue)) {
			currentValue = change.currentValue;
		} else {
			currentValue = change.currentValue ? [change.currentValue] : [];
		}

		if (Array.isArray(change.previousValue)) {
			previousValue = change.previousValue;
		} else {
			previousValue = change.previousValue ? [change.previousValue] : [];
		}

		return _.isEqual(currentValue, previousValue) === false;
	} // Otherwise treat as a single number, and regular comparison will work.
	else if (change?.currentValue !== change?.previousValue) {
		return true;
	}
	return false;
};
