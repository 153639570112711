import { tap, takeUntil, timeout, catchError } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { EMPTY, Observable } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	Injector,
	OnInit,
	OnDestroy,
} from "@angular/core";
import { WorkOrderService } from "app/modules/dashboard/services/work-order.service";
import { Globals } from "app/shared/modules/globals/globals.service";
import { MeasureUnit } from "@elevatedsignals/amygoodman";
import { UnitToBaseMeasureUnitPipe } from "app/modules/dashboard/modules/es-pipes/units/unit_to_base_measure_unit.pipe";
import { TitleCasePipe } from "@angular/common";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

@Component({
	selector: "work-order-add-measure-event",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
// @deprecated
export class WorkOrderAddMeasureEventComponent
	extends GenericUpdateComponent<{
		id: number;
		batch_id: number;
		work_order_id: boolean;
		weight: number;
	}>
	implements OnInit, OnDestroy
{
	weight_unit = this._globals.weight_unit;
	// Injected from other component
	private batch_id: number;
	private work_order_id: number;
	private measure_unit: MeasureUnit | null;
	private measure_setting: string;
	private measure_setting_fallback: string;
	private drying: boolean;
	private propagation: boolean;
	private source: boolean;
	private substance_type_id: number;

	private prevMeasureUnit: MeasureUnit;
	private prevConsumingFrom: string;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _workOrderService: WorkOrderService,
		private readonly _unitToBaseMeasureUnitPipe: UnitToBaseMeasureUnitPipe,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
		private readonly _titleCasePipe: TitleCasePipe,
	) {
		super(_store, _cd);
		this.form_title = "Add Input(s)";
		this.form_title_translation_key = marker("form_title_add_input_s");
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";

		this.schema = {
			title: "",
			description: "",
			info: "",
			properties: {
				batch_id: {
					type: "number",
					title: "Batch",
					title_translation_key: marker("word_batch"),
					widget: "data-select",
					oneOf: [
						{
							result_type: "batches",
							queryString: {
								input: "true",
							},
						},
					],
				},
				substance_type_id: {
					type: "number",
					title: "Substance Type",
					title_translation_key: marker("word_substance_type"),
					widget: "data-select",
					oneOf: [
						{
							result_type: "substance_types",
						},
					],
				},
				measure_unit: {
					type: "string",
					title: "Measure Type",
					title_translation_key: marker("form_field_label_measure_type"),
					widget: "select",
					oneOf: [
						{
							name: this._titleCasePipe.transform(MeasureUnit.Mass),
							name_translation_key: marker("word_mass"),
							value: MeasureUnit.Mass,
							enum: [MeasureUnit.Mass],
						},
						{
							name: this._titleCasePipe.transform(MeasureUnit.Volume),
							name_translation_key: marker("word_volume"),
							value: MeasureUnit.Volume,
							enum: [MeasureUnit.Volume],
						},
						{
							name: this._titleCasePipe.transform(MeasureUnit.Quantity),
							name_translation_key: marker("word_quantity"),
							value: MeasureUnit.Quantity,
							enum: [MeasureUnit.Quantity],
						},
					],
					default: MeasureUnit.Mass,
				},
				value: {
					type: "number",
					title: `Value`,
					title_translation_key: marker("form_field_label_value"),
				},
				timestamp: {
					type: "string",
					title: "Date",
					title_translation_key: marker("word_date"),
					widget: "date",
				},
			},
			anyOf: [
				{
					required: [
						"consuming_from",
						"batch_id",
						"substance_type_id",
						"measure_unit",
						"value",
					],
				},
				{
					required: [
						"consuming_from",
						"seed_lot_id",
						"substance_type_id",
						"measure_unit",
						"value",
					],
				},
			],
		};
	}

	ngOnInit() {
		this.batch_id = this._injector.get("batch_id", null);
		this.work_order_id = this._injector.get("work_order_id", null);
		this.substance_type_id = this._injector.get("substance_type_id", null);
		this.drying = this._injector.get("drying", false);
		this.propagation = this._injector.get("propagation", false);
		this.source = this._injector.get("source", false);
		this.measure_unit = this._injector.get("measure_unit", null);
		this.measure_setting = this._injector.get("measure_setting", null);
		if (this.source) {
			this.form_title = "Add Work Order Input(s)";
			this.form_title_translation_key = marker(
				"form_title_add_work_order_input_s",
			);
		} else {
			this.form_title = "Add Work Order Output(s)";
			this.form_title_translation_key = marker(
				"form_title_add_work_order_output_s",
			);
		}
		if (this.batch_id) {
			this.model.batch_id = this.batch_id;
			this.schema.properties.batch_id.readOnly = true;
		}
		if (this.measure_unit) {
			this.model.measure_unit = this.measure_unit;
			this.schema.properties.measure_unit.readOnly = true;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		if (model.measure_unit && model.measure_unit != this.prevMeasureUnit) {
			this.measure_setting_fallback = this.measure_setting || "batch";
			const units = this._globals.getFacilityUnit(
				model.measure_unit,
				this.measure_setting_fallback,
			);
			this.schema.properties.value.title = `Value (${units})`;
			this.schema.properties.value.title_translation_params = { units };
			this.prevMeasureUnit = model.measure_unit;
			this.prevConsumingFrom = model.consuming_from;
			this._cd.detectChanges();
		}
	}

	updateItem(work_order_output: {
		consuming_from: string;
		seed_lot_id?: number;
		batch_id?: number;
		substance_type_id: number;
		measure_unit: MeasureUnit;
		value: number;
		timestamp?: string;
	}) {
		this.loading$.next(true);
		const input: any = {
			...work_order_output,
			value: work_order_output.value
				? this._unitToBaseMeasureUnitPipe.transform(
						work_order_output.value,
						this._globals.getFacilityUnit(
							work_order_output.measure_unit,
							this.measure_setting || this.measure_setting_fallback,
						) ?? "",
						work_order_output.measure_unit,
					)
				: undefined,
			work_order_id: this.work_order_id,
		};

		let request: Observable<any>;

		if (this.source) {
			request = this._workOrderService.addSources(input);
		} else {
			request = this._workOrderService.addOutputs(input);
		}

		request
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
