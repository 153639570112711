import {
	IInventoryProduct,
	IWorkOrder,
	IWorkOrderPendingInventory,
} from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
	SimpleChanges,
	SimpleChange,
} from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { Globals } from "app/shared/modules/globals/globals.service";
import { WorkOrderInputQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";
import {
	hasPropertyChanged,
	hasInventoryIdsChanged,
	fetchNewInventoryTotals,
	getDynamicFormChanges,
	onVendorChange,
} from "../shared";

import {
	GeneralInventoryBOMSourceDynamicSchema,
	GeneralInventoryBOMSourceDynamic,
} from "./schemas";

interface AddBomSourceConfig {
	work_order_id: number;
	work_order_type_id: number;
	use_remaining_input: boolean;
	add_by_sku_id: boolean;
}
@Component({
	selector: "work-order-add-bom-source-dynamic-gi",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderAddBOMSourceDynamicGIComponent
	extends GenericCreateComponent<GeneralInventoryBOMSourceDynamic>
	implements OnInit, OnDestroy
{
	pending_inventory: IWorkOrderPendingInventory;

	work_order$ = this._store.select(fromDashboard.getSelectedWorkOrder);
	work_order: IWorkOrder;
	amount_valid: boolean;
	private readonly bomSourceConfig: AddBomSourceConfig;
	private readonly pending_inventory_id: number;
	private readonly whatChanged: SimpleChanges = {};

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store);

		const work_order_id = this._injector.get("work_order_id", null);

		this.bomSourceConfig = {
			work_order_id: this._injector.get("work_order_id", null),
			work_order_type_id: this._injector.get("work_order_type_id", null),
			use_remaining_input: this._injector.get("use_remaining_input", false),
			add_by_sku_id: this._injector.get("add_by_sku_id", false),
		};
		this.pending_inventory_id = this._injector.get("pending_inventory_id", null);
		this.form_title = `Add Inputs To Work Order #${work_order_id}`;
		this.form_title_translation_key = marker(
			"form_title_add_inputs_to_work_order_number",
		);
		this.form_title_translation_params = {
			work_order_id: `#${work_order_id}`,
		};
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";

		this.schema = GeneralInventoryBOMSourceDynamicSchema;
		this.schema.properties.inventory_product_id.oneOf[0].queryString = {
			// Retain the queryString already in this schema
			...this.schema.properties.inventory_product_id.oneOf[0].queryString,
			source_inventory_products_for_work_order_type_id:
				this.bomSourceConfig.work_order_type_id,
		};

		// ESS-4901 prevent outputs created in a work order from appearing when trying to add inputs within the same work order
		this.schema.properties.inventory_ids.items.oneOf[0].queryString = {
			...this.schema.properties.inventory_ids.items.oneOf[0].queryString,
			exclude_outputs_for_work_order_id: this.bomSourceConfig.work_order_id,
		};

		if (this.bomSourceConfig.add_by_sku_id) {
			this.schema.properties.sku_id.hidden = false;
			this.schema.properties.batch_id.visibleIf = {
				allOf: [{ vendor_id: ["$EXP$ target.value > 0"] }],
			};
		} else {
			this.schema.properties.sku_id.hidden = true;
			this.schema.properties.batch_id.visibleIf = {
				allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
			};
		}
	}

	valid(val) {
		this.valid$.next(val && this.amount_valid);
	}

	ngOnInit() {
		if (this.pending_inventory_id) {
			this._itemService
				.fetchItem(`pending_inventory/${this.pending_inventory_id}`, "", {
					eager: "[inventory_product.[vendors]]",
				})
				.pipe(takeUntil(this.destroyed$))
				.pipe(
					timeout(10000),
					catchError((error) => {
						this.error$.next(handleObservableError(error));
						this.loading$.next(false);
						return EMPTY;
					}),
				)
				.subscribe((pending_inventory) => {
					this.pending_inventory = pending_inventory;
					this.whatChanged.inventory_product_id = new SimpleChange(
						this.whatChanged.inventory_product_id?.currentValue,
						this.pending_inventory.inventory_product_id,
						false,
					);
					this.model = {
						...this.model,
						inventory_product_id: this.pending_inventory.inventory_product_id,
						inventory_unit_id: this.pending_inventory.inventory_unit_id,
						quantity: this.pending_inventory.quantity,
						pending_inventory_id: this.pending_inventory.id,
					};

					[this.model, this.schema] = onVendorChange(
						this.model,
						this.schema,
						pending_inventory.inventory_product,
					);

					this.updateSchemaForRequireBatch(pending_inventory.inventory_product);

					// account for require batch
					this._cd.detectChanges();
				});
		}
		if (this._globals.gmp_enabled && this.schema.properties.timestamp) {
			this.schema.properties.timestamp.hidden = true;
		}

		this.model.id = this.bomSourceConfig.work_order_id;
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model: GeneralInventoryBOMSourceDynamic) {
		getDynamicFormChanges(this.whatChanged, model);
		this.autoSelectForm();
		this.checkAndUpdateInventoryTotals();
	}

	autoSelectForm = (): void => {
		let requiresDetectChanges = false;
		if (this.itemChanged(this.whatChanged.batch_id)) {
			requiresDetectChanges = this.batchChanged();
		} else if (this.itemChanged(this.whatChanged.lot_id)) {
			requiresDetectChanges = this.lotChanged();
		}

		if (requiresDetectChanges) {
			this._cd.detectChanges();
		}
	};

	itemChanged = (change: SimpleChange | undefined): boolean => {
		if (change?.currentValue !== change?.previousValue) {
			return true;
		}
		return false;
	};

	batchChanged = () => {
		if (this.whatChanged.batch_id!.currentValue) {
			// Here we should be setting inventory_ids to [], lot_id to null. But, infinite loops.
		}

		return false;
	};

	updateSchemaForRequireBatch = (inventoryProduct?: IInventoryProduct) => {
		if (inventoryProduct?.require_batch) {
			this.schema.properties.batch_id.hidden = false;
			this.schema.properties.choose_source_lot.hidden = false;
			this.schema.properties.lot_id.visibleIf = {
				allOf: [
					{ choose_source_lot: [true], batch_id: ["$EXP$ target.value > 0"] },
				],
			};
			this.schema.properties.inventory_ids.visibleIf = {
				allOf: [
					{ choose_source_inventory: [true] },
					{ batch_id: ["$EXP$ target.value > 0"] },
				],
			};
		} else {
			this.schema.properties.batch_id.hidden = true;
			this.schema.properties.choose_source_lot.hidden = true;
			if (this.bomSourceConfig.add_by_sku_id) {
				this.schema.properties.lot_id.visibleIf = {
					allOf: [{ sku_id: ["$EXP$ target.value > 0"] }],
				};
				this.schema.properties.inventory_ids.visibleIf = {
					allOf: [
						{ choose_source_inventory: [true] },
						{ sku_id: ["$EXP$ target.value > 0"] },
					],
				};
			} else {
				this.schema.properties.lot_id.visibleIf = {
					allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
				};
				this.schema.properties.inventory_ids.visibleIf = {
					allOf: [
						{ choose_source_inventory: [true] },
						{ inventory_product_id: ["$EXP$ target.value > 0"] },
					],
				};
			}
		}
	};

	lotChanged = () => {
		if (this.whatChanged.lot_id!.currentValue) {
			// Here we should be setting inventory_ids to []. But, infinite loops.
			return false;
		} else {
			this.model.inventory_ids = [];
			return true;
		}
	};

	checkAndUpdateInventoryTotals = () => {
		if (
			hasPropertyChanged(this.whatChanged.inventory_product_id) ||
			hasPropertyChanged(this.whatChanged.lot_id) ||
			hasPropertyChanged(this.whatChanged.batch_id) ||
			hasInventoryIdsChanged(this.whatChanged.inventory_ids) ||
			hasPropertyChanged(this.whatChanged.timestamp) ||
			hasPropertyChanged(this.whatChanged.vendor_id) ||
			hasPropertyChanged(this.whatChanged.sku_id)
		) {
			fetchNewInventoryTotals(this.whatChanged, this._itemService).subscribe(
				(availableInventoryAmount) => {
					this.model.amount_available = availableInventoryAmount.content
						.map((availableInventory) => {
							return `${availableInventory.sum.toFixed(2)} ${availableInventory.name}`;
						})
						.join("\n");

					this.amount_valid = availableInventoryAmount.content.length > 0;

					this._cd.detectChanges();
				},
			);
		}
	};

	createItem(work_order_bom_source: GeneralInventoryBOMSourceDynamic) {
		// Verify form has remaining_inventory or weight
		if (
			work_order_bom_source.inventory_product_id &&
			!work_order_bom_source.remaining_inventory &&
			(!work_order_bom_source.quantity || work_order_bom_source.quantity <= 0)
		) {
			this.error$.next(
				this._translocoService.translate(
					"error_work_order_input_must_include_remaining_inventory_or_positive_quantity",
				),
			);
			return;
		}
		this.loading$.next(true);

		const update = {
			type: "Inventory",
			inventory_product_id: work_order_bom_source.inventory_product_id,
			inventory_unit_id: work_order_bom_source.inventory_unit_id,
			remaining_inventory: work_order_bom_source.remaining_inventory,
			source: work_order_bom_source.source,
			inventory_ids: work_order_bom_source.inventory_ids,
			lot_id: work_order_bom_source.lot_id,
			batch_id: work_order_bom_source.batch_id,
			timestamp: work_order_bom_source.timestamp,
			value: work_order_bom_source.quantity,
			pending_inventory_id: this.pending_inventory_id,
			vendor_id: work_order_bom_source.vendor_id,
			sku_id: work_order_bom_source.sku_id,
		};
		this._itemService
			.add(
				`work_order/${work_order_bom_source.id}/sources`,
				update,
				WorkOrderInputQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
