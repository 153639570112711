import { Component, OnDestroy } from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { IPrinter } from "@elevatedsignals/amygoodman";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { EMPTY } from "rxjs";
import { handleObservableError } from "app/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

// @deprecated in favour of 'create-printer' component

@Component({
	selector: "printer-ip-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class IPPrinterCreateComponent
	extends GenericCreateComponent<IPrinter>
	implements OnDestroy
{
	schema: any = {
		title: "Add a Networked Printer",
		description: "For IP Printers on the Facility Network",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			ip: {
				type: "string",
				title: "IP",
				title_translation_key: marker("acronym_ip"),
			},
		},
		required: ["name", "ip"],
	};

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store);
		this.form_title = "Create a Printer";
		this.form_title_translation_key = marker("form_title_create_a_printer");
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");

		// Have to manually override the schema.title and schema.description with the
		// translations in order for them to correctly translate
		this.schema.title = this._translocoService.translate(
			"form_title_add_a_networked_printer",
		);
		this.schema.description = this._translocoService.translate(
			"form_description_for_ip_printers_on_the_facility_network",
		);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	createItem(printer: Partial<IPrinter>) {
		this.loading$.next(true);
		this._itemService
			.add(`printers`, printer)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							addedItem,
							result_type: "printers",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
