import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	OnDestroy,
	ChangeDetectorRef,
	Injector,
	OnInit,
} from "@angular/core";
import { PurchaseOrderDetailQuery } from "app/shared/eagers";
import { IPurchaseOrderPlants } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { Globals } from "app/shared/modules/globals/globals.service";
import { dateIsBefore } from "app/shared/time-format";
import { TranslocoService } from "@jsverse/transloco";
import { ESValidator } from "app/shared/es-validator";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "purchase-order-plants-received",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PurchaseOrderPlantsReceivedComponent
	extends GenericUpdateComponent<IPurchaseOrderPlants>
	implements OnDestroy, OnInit
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			purchase_order_id: {
				type: "number",
				title: "Purchase Order",
				hidden: true,
			},
			purchase_order_plants_id: {
				type: "number",
				title: "Plant Order",
				hidden: true,
			},
			quantity: {
				widget: "number",
				type: "number",
				step: "1",
				title: `Quantity Received`,
				title_translation_key: marker("form_field_label_quantity_received"),
			},
			timestamp: {
				type: "string",
				title: "Date",
				widget: "date",
				warning: "The date must be after item was added to this order.",
				warning_translation_key: marker(
					"form_field_warning_the_date_must_be_after_item_was_added_to_this_order",
				),
			},
		},
		required: ["purchase_order_id", "purchase_order_plants_id", "quantity"],
	};

	validators: Record<string, ESValidator> = {
		"/timestamp": (value, property, form) => {
			this.dateValidatorFailed = false;

			const isValueBefore = dateIsBefore(new Date(value), new Date(this.minDate));
			if (isValueBefore) {
				this.dateValidatorFailed = true;

				const error = {
					code: "INVALID_DATE",
					path: `#${property.path}`,
					message: "The date must be in the past",
					params: ["timestamp"],
				};
				return [error];
			}

			return null;
		},
	};

	private purchase_order_id: number;
	private purchase_order_plants_id: number;
	private minDate: Date;
	private is_outgoing: boolean;

	private dateValidatorFailed: boolean;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
		private readonly _globals: Globals,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store, _cd);
		this.form_title = "Plants Received";
		this.form_title_translation_key = marker("form_title_plants_received");
		this.submit_button = "Mark Received";
		this.submit_button = marker("form_button_mark_received");
	}

	valid(v) {
		this.valid$.next(v);

		if (this.dateValidatorFailed) {
			this.valid$.next(false);
		}
	}

	ngOnInit() {
		// if (this._globals.gmp_enabled) {
		// 	delete this.schema.properties.timestamp;
		// }
		this.minDate = this._injector.get("minDate", null);
		this.purchase_order_id = this._injector.get("purchase_order_id", undefined);
		this.purchase_order_plants_id = this._injector.get(
			"purchase_order_plants_id",
			undefined,
		);
		this.is_outgoing = this._injector.get("is_outgoing", undefined);
		if (this.is_outgoing) {
			this.form_title = "Plants Shipped";
			this.form_title_translation_key = marker("form_title_plants_shipped");
			this.submit_button = "Mark Shipped";
			this.submit_button_translation_key = marker("form_button_mark_shipped");
			this.schema.properties.quantity = {
				...this.schema.properties.quantity,
				title: `Quantity Shipped`,
				title_translation_key: marker("form_field_label_quantity_shipped"),
			};
		}

		let quantity;
		try {
			quantity = this._injector.get("quantity", undefined);
		} catch (error) {
			// suppress error;
		}
		this.model.purchase_order_id = this.purchase_order_id;
		this.model.purchase_order_plants_id = this.purchase_order_plants_id;

		this.model.quantity = quantity ?? 0;
		if (this.model.quantity) {
			this.schema.properties.quantity = {
				...this.schema.properties.quantity,
				hidden: true,
			};
			this.schema.description = this.is_outgoing
				? this._translocoService.translate(
						"form_description_please_confirm_all_ordered_plants_were_shipped",
					)
				: this._translocoService.translate(
						"form_description_please_confirm_all_ordered_plants_were_received",
					);
		}

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(received) {
		this.loading$.next(true);
		this._itemService
			.update(
				`purchase_order/${this.purchase_order_id}/plants`,
				this.purchase_order_plants_id,
				received,
				{
					...PurchaseOrderDetailQuery,
					is_outgoing: this.is_outgoing ? "true" : "false",
				},
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "purchase_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
