import {
	Component,
	Input,
	Output,
	EventEmitter,
	OnInit,
	OnChanges,
	SimpleChanges,
} from "@angular/core";
import {
	UntypedFormGroup,
	Validators,
	UntypedFormBuilder,
	AbstractControl,
} from "@angular/forms";

import { PasswordReset } from "../../models/password-reset.model";

@Component({
	selector: "password-reset-input",
	template: `
		<div *ngIf="!verify_pending && !verify_success" class="ui message">
			<div class="header">Invalid Password Reset link</div>
			<p>
				Hey! It looks like you're trying to reset your password but the link you've
				used is invalid.
			</p>
			<ul class="list">
				<li>
					Try getting sending another password reset email
					<a routerLink="/forgot-password">here</a>.
				</li>
				<li>
					If remember your credentials you can sign in on the
					<a routerLink="/signin">signin page</a>.
				</li>
			</ul>
		</div>

		<div *ngIf="reset_success" class="ui message">
			<div class="header">Your Password has been reset</div>
			<p>
				You can now sign in with your new password.
				<a routerLink="/signin">Go to signin page.</a>
			</p>
		</div>

		<div *ngIf="verify_success && !reset_success" class="input-form">
			<div class="logo"></div>

			<form
				[formGroup]="passwordResetForm"
				(ngSubmit)="onSubmit()"
				class="ui form"
			>
				<div class="field">
					<input
						required
						formControlName="password"
						type="password"
						placeholder="New Password"
					/>
				</div>

				<div class="field">
					<input
						required
						formControlName="confirm_password"
						type="password"
						placeholder="Confirm Password"
					/>
				</div>

				<div *ngIf="error" class="ui negative message">
					<p>{{ error }}</p>
				</div>

				<div
					*ngIf="passwordResetForm.get.confirm_password?.errors?.MatchPassword"
					class="ui negative message"
				>
					<p>Passwords do not match</p>
				</div>

				<button
					class="ui"
					[ngClass]="pending ? 'loading button disabled blue' : 'button blue'"
					type="submit"
					[disabled]="!passwordResetForm.valid"
				>
					Reset Password
				</button>
			</form>
		</div>
	`,
	styleUrls: ["./index.scss"],
})
export class PasswordResetInputComponent implements OnInit, OnChanges {
	static MatchPassword(AC: AbstractControl) {
		const password = AC.get("password")?.value as string; // to get value in input tag
		const confirmPassword = AC.get("confirm_password")?.value as string; // to get value in input tag

		if (password !== confirmPassword) {
			// console.log('false');
			AC.get("confirm_password")?.setErrors({ MatchPassword: true });
		}

		// console.log('true');
		return null;
	}

	passwordResetForm: UntypedFormGroup;

	@Input() error: string;
	@Input() pending: boolean;
	@Input() reset_success: boolean;
	@Input() verify_error: string;
	@Input() verify_pending: boolean;
	@Input() verify_success: boolean;
	@Input() reset_key: string;
	@Output() resetPassword = new EventEmitter<PasswordReset>();

	constructor(private readonly _fb: UntypedFormBuilder) {}

	ngOnInit() {
		this.passwordResetForm = this._fb.group(
			{
				password: [null, Validators.required],
				confirm_password: [null, Validators.required],
			},
			{
				validator: PasswordResetInputComponent.MatchPassword, // your validation method
			},
		);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!this.passwordResetForm) {
			return;
		}
		if (changes.reset_key) {
			this.passwordResetForm.patchValue({
				reset_token: this.reset_key,
			});
		}
	}

	onSubmit() {
		if (this.passwordResetForm.valid) {
			// console.log(this.passwordResetForm.value);
			const formValue = this.passwordResetForm.value;
			formValue.token = this.reset_key;
			delete formValue.confirm_password;
			const value: PasswordReset = formValue as PasswordReset;
			// console.log("Reset Form", value);
			this.resetPassword.emit(value);
		}
	}

	// ngOnInit() {
	//   this.location$.subscribe(location=> this.model.location_id = location.id);
	// }
}
