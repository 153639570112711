import { MeasureUnit } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

export interface IGeneralInventoryBOMSource {
	id: number;
	input_type: string;
	source?: string;
	batch_id?: number;
	inventory_type?: string | MeasureUnit;
	use_remaining?: boolean;
	substance_type_id?: number;
	value?: number;

	plant_batch_id?: number;
	plant_lot_id?: number;
	plant_selection?: string;
	remaining_plants?: boolean;
	use_plant_ids?: boolean;
	plant_ids?: number[];
	plant_count?: number;

	location_id?: number;
	inventory_product_id?: number;
	vendor_id?: number;
	remaining_inventory?: boolean;
	inventory_unit_id?: number;
	inventory_ids?: number[];
	lot_id?: number;
	quantity?: number;

	timestamp?: Date;
	move_batch_to_work_order_location?: number;
}

export const GeneralInventoryBOMSourceSchema = {
	title: "",
	description: "",
	info: "",
	properties: {
		id: {
			type: "number",
			hidden: true,
		},
		input_type: {
			type: "string",
			title: "Input Type",
			title_translation_key: marker("form_field_label_input_type"),
			widget: "hidden",
			oneOf: [
				{
					value: "PLANTS",
					enum: ["PLANTS"],
					name: "Plants",
					name_translation_key: marker("word_plants"),
				},
				{
					value: "INVENTORY",
					enum: ["INVENTORY"],
					name: "Inventory",
					name_translation_key: marker("word_inventory"),
				},
			],
		},

		/**
		 * Plant Material Fields
		 */
		plant_batch_id: {
			type: "number",
			title: "Source Batch",
			title_translation_key: marker("form_field_label_source_batch"),
			widget: "data-select",
			// quick_create: false,
			oneOf: [
				{
					result_type: "batches",
					queryString: {
						input: "true",
						dont_show_expired: "true",
					},
				},
			],
			visibleIf: {
				input_type: ["PLANTS"],
			},
		},
		plant_lot_id: {
			type: "number",
			title: "Source Lot",
			title_translation_key: marker("form_field_label_source_lot"),
			widget: "data-select",
			quick_create: false,
			related_properties: ["plant_batch_id"],
			oneOf: [
				{
					result_type: "lots",
					queryString: {
						input: "true",
						dont_show_expired: "true",
					},
				},
			],
			visibleIf: {
				input_type: ["PLANTS"],
			},
		},
		use_remaining: {
			title: "Use Remaining Plants",
			title_translation_key: marker("form_field_label_use_remaining_plants"),
			type: "boolean",
			widget: "checkbox",
			default: false,
			visibleIf: {
				allOf: [{ input_type: ["PLANTS"] }],
			},
		},
		use_plant_ids: {
			title: "Use Specific Plant Ids",
			title_translation_key: marker("form_field_label_use_specific_plant_ids"),
			type: "boolean",
			widget: "checkbox",
			default: false,
			visibleIf: {
				allOf: [{ input_type: ["PLANTS"] }, { use_remaining: [false] }],
			},
		},
		plant_ids: {
			type: "array",
			title: "Plant Ids",
			title_translation_key: marker("form_field_label_plant_ids"),
			widget: "data-select",
			quick_create: false,
			minItems: 1,
			related_properties: ["plant_batch_id", "plant_lot_id"],
			items: {
				type: "number",
				oneOf: [
					{
						result_type: "plants",
						queryString: {
							plant_status: "ACTIVE",
						},
					},
				],
			},
			visibleIf: {
				allOf: [{ input_type: ["PLANTS"] }, { use_plant_ids: [true] }],
			},
		},
		plant_count: {
			type: "number",
			title: "Number of Plants",
			title_translation_key: marker("form_field_label_number_of_plants"),
			visibleIf: {
				allOf: [{ input_type: ["PLANTS"] }, { use_plant_ids: [false] }],
			},
		},

		/**
		 * Inventory Fields
		 */
		inventory_product_id: {
			title: "Product",
			title_translation_key: marker("word_product"),
			type: "number",
			widget: "data-select",
			readOnly: true,
			quick_create: false,
			oneOf: [
				{
					result_type: "inventory_products",
					queryString: {
						non_zero_inventory: "true",
					},
				},
			],
			visibleIf: {
				allOf: [{ input_type: ["INVENTORY"] }],
			},
		},
		vendor_id: {
			type: "number",
			title: "Vendor",
			title_translation_key: marker("word_vendor"),
			widget: "data-select",
			oneOf: [
				{
					result_type: "vendors",
				},
			],
			related_properties: ["inventory_product_id"],
			quick_create: false,
			visibleIf: {
				allOf: [{ input_type: ["INVENTORY"] }],
			},
		},
		batch_id: {
			type: "number",
			title: "Source Batch",
			title_translation_key: marker("form_field_label_source_batch"),
			widget: "data-select",
			quick_create: false,
			related_properties: ["inventory_product_id"],
			oneOf: [
				{
					result_type: "batches",
					queryString: {
						input: "true",
						dont_show_expired: "true",
						non_zero_inventory: "true",
					},
				},
			],
			visibleIf: {
				allOf: [{ input_type: ["INVENTORY"] }],
			},
		},
		location_id: {
			type: "number",
			title: "Source Location",
			title_translation_key: marker("form_field_label_source_location"),
			widget: "data-select",
			quick_create: false,
			related_properties: ["inventory_product_id", "batch_id"],
			oneOf: [
				{
					result_type: "locations",
					queryString: {
						input: "true",
						non_zero_inventory: "true",
					},
				},
			],
			visibleIf: {
				allOf: [{ input_type: ["INVENTORY"] }],
			},
		},
		lot_id: {
			type: "number",
			title: "Source Lot",
			title_translation_key: marker("form_field_label_source_lot"),
			widget: "data-select",
			quick_create: false,
			related_properties: ["inventory_product_id", "location_id", "batch_id"],
			oneOf: [
				{
					result_type: "lots",
					queryString: {
						input: "true",
						non_zero_inventory: "true",
						dont_show_expired: "false",
					},
				},
			],
			visibleIf: {
				allOf: [{ input_type: ["INVENTORY"] }],
			},
		},
		inventory_ids: {
			type: "array",
			title: "Inventory ID",
			title_translation_key: marker("form_field_label_inventory_id"),
			widget: "data-select",
			quick_create: false,
			multi: true,
			related_properties: [
				"inventory_product_id",
				"location_id",
				"batch_id",
				"lot_id",
			],
			items: {
				type: "number",
				oneOf: [
					{
						result_type: "inventories",
						text_key: ["name", "id"],
						text_format: "?1 (#?2)",
						value_key: "id",
						queryString: {
							non_zero_inventory: "true",
							dont_show_expired: "false",
						},
					},
				],
			},
			visibleIf: {
				input_type: ["INVENTORY"],
			},
		},
		remaining_inventory: {
			title: "Use Remaining Inventory",
			title_translation_key: marker("form_field_label_use_remaining_inventory"),
			type: "boolean",
			widget: "checkbox",
			default: false,
			visibleIf: {
				oneOf: [{ input_type: ["INVENTORY"] }],
			},
		},
		quantity: {
			type: "number",
			title: `Quantity`,
			title_translation_key: marker("word_quantity"),
			visibleIf: {
				allOf: [
					{ remaining_inventory: [false] },
					{ inventory_product_id: ["$EXP$ target.value > 0"] },
					{ inventory_ids: ["$ANY$"] },
				],
			},
			width: "50",
		},
		inventory_unit_id: {
			title: "Inventory Unit",
			title_translation_key: marker("word_inventory_unit"),
			type: "number",
			widget: "data-select",
			readOnly: true,
			quick_create: false,
			shorter_placeholder: true,
			related_properties: ["inventory_product_id", "inventory_ids"],
			oneOf: [
				{
					result_type: "inventory_units",
				},
			],
			visibleIf: {
				oneOf: [
					{ remaining_inventory: [false] },
					{ inventory_product_id: ["$EXP$ target.value > 0"] },
					{ inventory_ids: ["$ANY$"] },
				],
			},
			width: "50",
		},
		timestamp: {
			type: "string",
			title: "Timestamp",
			title_translation_key: marker("word_timestamp"),
			widget: "date",
		},

		/** These are just used for the required to make sure we have all the correct items selected before allowing the user to submit the form */

		remaining_inventory_selected: {
			type: "boolean",
			widget: "checkbox",
			hidden: true,
			default: false,
			visibleIf: {
				remaining_inventory: [true],
			},
		},
	},
	anyOf: [
		// Use specific number of plants
		{
			required: ["id", "plant_batch_id", "input_type", "plant_count"],
		},
		// Use specific plant ids
		{
			required: ["id", "plant_batch_id", "input_type", "plant_ids"],
		},
		// For remaining
		{ required: ["id", "plant_batch_id", "input_type", "use_remaining"] },

		/** **********************/
		/** INVENTORY REQUIRED **/
		/** **********************/

		/** FOR BATCH */
		// Enter specific quantity of inventory
		{
			required: [
				"id",
				"batch_id",
				"inventory_product_id",
				"inventory_unit_id",
				"quantity",
			],
		},
		// Use remaining inventory products
		{
			required: [
				"id",
				"batch_id",
				"inventory_product_id",
				"remaining_inventory_selected",
			],
		},
		/** FOR LOCATION */
		// Enter specific quantity of inventory
		{
			required: [
				"id",
				"location_id",
				"inventory_product_id",
				"inventory_unit_id",
				"quantity",
			],
		},
		// Use remaining inventory products
		{
			required: [
				"id",
				"location_id",
				"inventory_product_id",
				"remaining_inventory_selected",
			],
		},
		// Use remaining inventory
		{
			required: ["id", "inventory_ids", "remaining_inventory_selected"],
		},
		// Use specific quantity of inventory
		{
			required: ["id", "inventory_ids", "inventory_unit_id", "quantity"],
		},
	],
};
