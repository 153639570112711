import { ICustomFieldSchema } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.CustomFields;

export type State = SelectionState<ICustomFieldSchema>;

export const reducer = createSelectionReducer<ICustomFieldSchema>(reducerKey);
