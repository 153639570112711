/* jscpd:ignore-start */
import { TitleCasePipe } from "@angular/common";
import { MeasureUnit } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

// @deprecated
export const getSubstanceTypeDestructionReweighSchema = (
	weight_unit: string,
	_titleCasePipe: TitleCasePipe,
) => ({
	title: "",
	description: "",
	info: "",
	properties: {
		group: {
			type: "string",
			hidden: true,
		},

		measure_unit: {
			readOnly: true,
			type: "string",
			title: "Measure Type",
			title_translation_key: marker("form_field_label_measure_type"),
			widget: "select",
			oneOf: [
				{
					name: _titleCasePipe.transform(MeasureUnit.Mass),
					name_translation_key: marker("word_mass"),
					value: MeasureUnit.Mass,
					enum: [MeasureUnit.Mass],
				},
				{
					name: _titleCasePipe.transform(MeasureUnit.Volume),
					name_translation_key: marker("word_volume"),
					value: MeasureUnit.Volume,
					enum: [MeasureUnit.Volume],
				},
				{
					name: _titleCasePipe.transform(MeasureUnit.Quantity),
					name_translation_key: marker("word_quantity"),
					value: MeasureUnit.Quantity,
					enum: [MeasureUnit.Quantity],
				},
			],
			default: MeasureUnit.Mass,
		},
		value: {
			readOnly: true,
			type: "number",
			title: `Value (${weight_unit})`,
			title_translation_key: marker("form_field_label_value"),
			title_translation_params: { units: weight_unit },
		},
		reweigh_value: {
			type: "number",
			title: `Reweigh Value (${weight_unit})`,
			title_translation_key: marker("form_field_label_reweigh_value"),
			title_translation_params: { units: weight_unit },
		},

		timestamp: {
			type: "string",
			title: "Timestamp",
			title_translation_key: marker("word_timestamp"),
			widget: "date",
		},
	},
	required: ["group", "reweigh_value"],
});
/* jscpd:ignore-end */
