import { IBatchType, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { BatchTypePageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "batch_types";

export type State = Page<IBatchType>;

export const reducer = createPagingReducer<IBatchType>(reducerKey, {
	result_type: "batch_types",
	title: "Batch Type",
	title_translation_key: marker("page_title_batch_types"),
	title_plural_translation_key: marker("page_title_plural_batch_types"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	query: BatchTypePageQuery,
});
