import { createReducer, on } from "@ngrx/store";
import {
	ItemActions,
	SelectRelatedItemsAction,
} from "app/modules/dashboard/actions/item.actions";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";

export interface SelectionState<T> {
	selected_id?: number | string;
	selected_item?: T;
	error?: string;
	selected_related_items_tab?: string | null;
}

export const createSelectionReducer = <T>(
	name: string,
	initialState: SelectionState<T> = {
		selected_id: undefined,
		selected_item: undefined,
		error: undefined,
		selected_related_items_tab: null,
	},
) => {
	return createReducer(
		initialState,
		on(SelectionActions.select, (state, payload) => {
			const result_type = payload.result_type;
			if (result_type !== name) {
				return state;
			}

			return {
				...state,
				selected_id: payload.id,
			};
		}),
		on(ItemActions.fetchSuccess, (state, action) => {
			if (!action.result_type || !action.result) {
				console.error(
					"Select item did not contain valid result type",
					action.result_type,
				);
				return state;
			}

			if (action.result_type !== name) {
				return state;
			}

			const selected_item = Array.isArray(action.result)
				? action.result[0]
				: action.result;

			return {
				...state,
				selected_item,
				error: undefined,
			};
		}),
		on(ItemActions.fetchFail, (state, action) => {
			if (!action.result_type) {
				return state;
			}

			if (action.result_type !== name) {
				return state;
			}

			return {
				...state,
				error: action.error,
			};
		}),
		on(ItemActions.updateSuccess, (state, payload) => {
			if (payload.result_type !== name) {
				return state;
			}
			const selected_item =
				state.selected_id === payload.updatedItem.id
					? {
							...state.selected_item,
							...payload.updatedItem,
						}
					: payload.updatedItem;
			return {
				...state,
				selected_item,
				error: undefined,
			};
		}),
		on(SelectRelatedItemsAction, (state, payload) => {
			const select = payload;

			const { itemType, result_type } = select;

			if (itemType !== name) {
				return state;
			}

			return {
				...state,
				selected_related_items_tab: result_type,
			};
		}),
	);
};
