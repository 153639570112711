import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromPaging from "app/modules/dashboard/reducers/paging.reducer";
import { DashboardState } from "app/modules/dashboard/state/dashboard.state";

export const dashboardFeatureKey = "dashboardV2";

export const getDashboardState =
	createFeatureSelector<DashboardState>(dashboardFeatureKey);

export const getPagingState = createSelector(
	getDashboardState,
	(state: DashboardState) => state.paging,
);

export const getEntities = createSelector(
	getPagingState,
	fromPaging.getEntities,
);

export const getSelections = createSelector(
	getPagingState,
	fromPaging.getSelections,
);

// Facilities
export const getUserFacilities = createSelector(getEntities, (entities) => {
	return entities.facilities;
});

export const getSelectedFacility = createSelector(
	getSelections,
	(selections) => {
		return selections.facilities.selected_item;
	},
);

// Schemas
export const getSchemas = createSelector(getEntities, (entities) => {
	return entities.schemas;
});

export const getSelectedSchemaId = createSelector(
	getSelections,
	(selections) => {
		return selections.schemas.selected_id;
	},
);

export const getSelectedSchema = createSelector(getSelections, (entities) => {
	return entities.schemas.selected_item;
});

// Quick Record (schemas)
export const getQuickRecords = createSelector(getEntities, (entities) => {
	return entities.quick_records;
});

export const getSelectedQuickRecordsId = createSelector(
	getSelections,
	(selections) => {
		return selections.quick_records.selected_id;
	},
);
export const getSelectedQuickRecord = createSelector(
	getSelections,
	(selections) => {
		return selections.quick_records.selected_item;
	},
);
export const getSelectedQuickRecordError = createSelector(
	getSelections,
	(selections) => {
		return selections.quick_records.error;
	},
);

// Sops
export const getSops = createSelector(getEntities, (entities) => {
	return entities.sops;
});

export const getSelectedSopId = createSelector(getSelections, (selections) => {
	return selections.sops.selected_id;
});

export const getSelectedSop = createSelector(getSelections, (entities) => {
	return entities.sops.selected_item;
});

// SOP Categories
export const getSopCategories = createSelector(getEntities, (entities) => {
	return entities.sop_categories;
});

export const getSelectedSopCategoryId = createSelector(
	getSelections,
	(selections) => {
		return selections.sop_categories.selected_id;
	},
);

export const getSelectedSopCategory = createSelector(
	getSelections,
	(selections) => {
		return selections.sop_categories.selected_item;
	},
);

// Reports
export const getReportPage = createSelector(getEntities, (entities) => {
	return entities.reports;
});

export const getSelectedReportId = createSelector(
	getSelections,
	(selections) => {
		return selections.reports.selected_id;
	},
);

export const getSelectedReport = createSelector(getSelections, (selections) => {
	return selections.reports.selected_item;
});

// Reporting Categories
export const getReportingCategoryPage = createSelector(
	getEntities,
	(entities) => {
		return entities.reporting_categories;
	},
);

export const getSelectedReportingCategoryId = createSelector(
	getSelections,
	(selections) => {
		return selections.reporting_categories.selected_id;
	},
);

export const getSelectedReportingCategory = createSelector(
	getSelections,
	(selections) => {
		return selections.reporting_categories.selected_item;
	},
);

// Reporting Periods
export const getPeriodPage = createSelector(getEntities, (entities) => {
	return entities.periods;
});

export const getSelectedPeriodId = createSelector(
	getSelections,
	(selections) => {
		return selections.periods.selected_id;
	},
);

export const getSelectedPeriod = createSelector(getSelections, (selections) => {
	return selections.periods.selected_item;
});

// Report Types
export const getReportTypePage = createSelector(getEntities, (entities) => {
	return entities.report_types;
});

export const getSelectedReportTypeId = createSelector(
	getSelections,
	(selections) => {
		return selections.report_types.selected_id;
	},
);

export const getSelectedReportType = createSelector(
	getSelections,
	(selections) => {
		return selections.report_types.selected_item;
	},
);

export const getGiBatchPage = createSelector(getEntities, (entities) => {
	return entities.batch_gi;
});
export const getNonGiBatchPage = createSelector(getEntities, (entities) => {
	return entities.batch_non_gi;
});

export const getSelectedBatchId = createSelector(
	getSelections,
	(selections) => {
		return selections.batches.selected_id;
	},
);

export const getSelectedBatch = createSelector(getSelections, (selections) => {
	return selections.batches.selected_item;
});

export const getSelectedBatchError = createSelector(
	getSelections,
	(selections) => {
		return selections.batches.error;
	},
);

export const getRelatedBatchPage = createSelector(getEntities, (entities) => {
	return entities.related_batches;
});

// Locations
export const getLocationPage = createSelector(getEntities, (entities) => {
	return entities.locations;
});

export const getSelectedLocationId = createSelector(
	getSelections,
	(selections) => {
		return selections.locations.selected_id;
	},
);

export const getSelectedLocation = createSelector(
	getSelections,
	(selections) => {
		return selections.locations.selected_item;
	},
);

// Plants
export const getPlantPage = createSelector(getEntities, (entities) => {
	return entities.plants;
});

export const getSelectedPlantId = createSelector(
	getSelections,
	(selections) => {
		return selections.plants.selected_id;
	},
);

export const getSelectedPlant = createSelector(getSelections, (selections) => {
	return selections.plants.selected_item;
});

// Cultivars
export const getCultivarPage = createSelector(getEntities, (entities) => {
	return entities.cultivars;
});
export const getSelectedCultivarId = createSelector(
	getSelections,
	(selections) => {
		return selections.cultivars.selected_id;
	},
);
export const getSelectedCultivar = createSelector(
	getSelections,
	(selections) => {
		return selections.cultivars.selected_item;
	},
);

// Sensors
export const getSensorPage = createSelector(getEntities, (entities) => {
	return entities.sensors;
});
export const getSelectedSensorUuid = createSelector(
	getSelections,
	(selections) => {
		return selections.sensors.selected_id;
	},
);
export const getSelectedSensor = createSelector(getSelections, (selections) => {
	return selections.sensors.selected_item;
});

// Hubs
export const getHubPage = createSelector(getEntities, (entities) => {
	return entities.hubs;
});
export const getSelectedHubUuid = createSelector(
	getSelections,
	(selections) => {
		return selections.hubs.selected_id;
	},
);
export const getSelectedHub = createSelector(getSelections, (selections) => {
	return selections.hubs.selected_item;
});

// Records
export const getRecordPage = createSelector(getEntities, (entities) => {
	return entities.records;
});

export const getRelatedRecordPage = (
	type: "plant" | "location" | "equipment" | "batch" | "vendor",
) => {
	if (type === "plant") {
		return createSelector(getEntities, (entities) => {
			return entities.plant_related_records;
		});
	} else if (type === "location") {
		return createSelector(getEntities, (entities) => {
			return entities.location_related_records;
		});
	} else if (type === "batch") {
		return createSelector(
			getEntities,
			(entities) => entities.batch_related_records,
		);
	} else if (type === "vendor") {
		return createSelector(
			getEntities,
			(entities) => entities.vendor_related_records,
		);
	}

	return createSelector(
		getEntities,
		(entities) => entities.equipment_related_records,
	);
};
export const getSelectedRecordId = createSelector(getSelections, (entities) => {
	return entities.records.selected_id;
});
export const getSelectedRecord = createSelector(getSelections, (selections) => {
	return selections.records.selected_item;
});

// Form Schemas
export const getFormSchemaPage = createSelector(getEntities, (entities) => {
	return entities.schemas;
});
export const getSelectedFormSchemaId = createSelector(
	getSelections,
	(entities) => {
		return entities.schemas.selected_id;
	},
);
export const getSelectedFormSchema = createSelector(
	getSelections,
	(entities) => {
		return entities.schemas.selected_item;
	},
);

// Custom field Schemas
export const getCustomFieldSchemaPage = createSelector(
	getEntities,
	(entities) => {
		return entities.custom_fields;
	},
);
export const getSelectedCustomFieldSchemaId = createSelector(
	getSelections,
	(entities) => {
		return entities.custom_fields.selected_id;
	},
);
export const getSelectedCustomFieldSchema = createSelector(
	getSelections,
	(entities) => {
		return entities.custom_fields.selected_item;
	},
);

// Custom Scans
export const getCustomScansPage = createSelector(getEntities, (entities) => {
	return entities.custom_scans;
});
export const getSelectedCustomScanId = createSelector(
	getSelections,
	(entities) => {
		return entities.custom_scans.selected_id;
	},
);
export const getSelectedCustomScan = createSelector(
	getSelections,
	(entities) => {
		return entities.custom_scans.selected_item;
	},
);

// Notifications
export const getNotificationPage = createSelector(getEntities, (entities) => {
	return entities.notifications;
});

// User Pages

export const getUserPage = createSelector(getEntities, (entities) => {
	return entities.users;
});
export const getSelectedUserId = createSelector(getSelections, (selections) => {
	return selections.users.selected_id;
});
export const getSelectedUser = createSelector(getSelections, (selections) => {
	return selections.users.selected_item;
});

// Tag
export const getTagPage = createSelector(getEntities, (entities) => {
	return entities.tags;
});

export const getSelectedTagId = createSelector(getSelections, (selections) => {
	return selections.tags.selected_id;
});

export const getSelectedTag = createSelector(getSelections, (selections) => {
	return selections.tags.selected_item;
});

// Roles
export const getRolesPage = createSelector(getEntities, (entities) => {
	return entities.roles;
});

export const getSelectedRoleId = createSelector(getSelections, (selections) => {
	return selections.roles.selected_id;
});

export const getSelectedRole = createSelector(getSelections, (selections) => {
	return selections.roles.selected_item;
});

// Destruction Lot
export const getDestructionLotPage = createSelector(getEntities, (entities) => {
	return entities.destruction_lots;
});

export const getSelectedDestructionLotId = createSelector(
	getSelections,
	(entities) => {
		return entities.destruction_lots.selected_id;
	},
);

export const getSelectedDestructionLot = createSelector(
	getSelections,
	(entities) => {
		return entities.destruction_lots.selected_item;
	},
);

// Destruction Event
export const getDestructionEventPage = createSelector(
	getEntities,
	(entities) => {
		return entities.destruction_events;
	},
);

export const getGiBatchDestructionEventPage = createSelector(
	getEntities,
	(entities) => {
		return entities.destruction_events_batch_gi;
	},
);
export const getNonGiBatchDestructionEventPage = createSelector(
	getEntities,
	(entities) => {
		return entities.destruction_events_batch_non_gi;
	},
);

export const getSelectedDestructionEventId = createSelector(
	getSelections,
	(entities) => {
		return entities.destruction_events.selected_id;
	},
);

export const getSelectedDestructionEvent = createSelector(
	getSelections,
	(entities) => {
		return entities.destruction_events.selected_item;
	},
);

// Destruction Reason
export const getDestructionReasonPage = createSelector(
	getEntities,
	(entities) => {
		return entities.destruction_reasons;
	},
);

export const getSelectedDestructionReasonId = createSelector(
	getSelections,
	(entities) => {
		return entities.destruction_reasons.selected_id;
	},
);

export const getSelectedDestructionReason = createSelector(
	getSelections,
	(entities) => {
		return entities.destruction_reasons.selected_item;
	},
);

// Growth Stage
export const getGrowthStagePage = createSelector(getEntities, (entities) => {
	return entities.growth_stages;
});

export const getSelectedGrowthStageId = createSelector(
	getSelections,
	(entities) => {
		return entities.growth_stages.selected_id;
	},
);

export const getSelectedGrowthStage = createSelector(
	getSelections,
	(entities) => {
		return entities.growth_stages.selected_item;
	},
);

// Production Stage
export const getProductionStagePage = createSelector(
	getEntities,
	(entities) => {
		return entities.production_stages;
	},
);
export const getSelectedProductionStageId = createSelector(
	getSelections,
	(entities) => {
		return entities.production_stages.selected_id;
	},
);
export const getSelectedProductionStage = createSelector(
	getSelections,
	(entities) => {
		return entities.production_stages.selected_item;
	},
);

// Work Orders
export const getWorkOrderPage = createSelector(getEntities, (entities) => {
	return entities.work_orders;
});

export const getSelectedWorkOrderId = createSelector(
	getSelections,
	(entities) => {
		return entities.work_orders.selected_id;
	},
);

export const getSelectedWorkOrder = createSelector(
	getSelections,
	(entities) => {
		return entities.work_orders.selected_item;
	},
);

export const getSelectedWorkOrderError = createSelector(
	getSelections,
	(entities) => {
		return entities.work_orders.error;
	},
);

// Work Order Pending Inventory
export const getWorkOrderPendingInventoryPage = createSelector(
	getEntities,
	(entities) => {
		return entities.pending_inventories;
	},
);
export const getSelectedWorkOrderPendingId = createSelector(
	getSelections,
	(entities) => {
		return entities.pending_inventories.selected_id;
	},
);
export const getSelectedWorkOrderPendingInventory = createSelector(
	getSelections,
	(entities) => {
		return entities.pending_inventories.selected_item;
	},
);

// Work Order Types
export const getWorkOrderTypePage = createSelector(getEntities, (entities) => {
	return entities.work_order_types;
});
export const getSelectedWorkOrderTypeId = createSelector(
	getSelections,
	(entities) => {
		return entities.work_order_types.selected_id;
	},
);
export const getSelectedWorkOrderType = createSelector(
	getSelections,
	(entities) => {
		return entities.work_order_types.selected_item;
	},
);

// Production Plans
export const getProductionPlanPage = createSelector(getEntities, (entities) => {
	return entities.production_plans;
});

export const getSelectedProductionPlanId = createSelector(
	getSelections,
	(entities) => {
		return entities.production_plans.selected_id;
	},
);
export const getSelectedProductionPlan = createSelector(
	getSelections,
	(entities) => {
		return entities.production_plans.selected_item;
	},
);

// Substance Types
// @deprecated
export const getSubstanceTypePage = createSelector(getEntities, (entities) => {
	return entities.substance_types;
});

// @deprecated
export const getSelectedSubstanceTypeId = createSelector(
	getSelections,
	(entities) => {
		return entities.substance_types.selected_id;
	},
);

// @deprecated
export const getSelectedSubstanceType = createSelector(
	getSelections,
	(entities) => {
		return entities.substance_types.selected_item;
	},
);

// Location Types
export const getLocationTypePage = createSelector(getEntities, (entities) => {
	return entities.location_types;
});
export const getSelectedLocationTypeId = createSelector(
	getSelections,
	(entities) => {
		return entities.location_types.selected_id;
	},
);
export const getSelectedLocationType = createSelector(
	getSelections,
	(entities) => {
		return entities.location_types.selected_item;
	},
);

// Bill Of Materials
export const getBillOfMaterialPage = createSelector(getEntities, (entities) => {
	return entities.bill_of_materials;
});

export const getSelectedBillOfMaterialId = createSelector(
	getSelections,
	(entities) => {
		return entities.bill_of_materials.selected_id;
	},
);
export const getSelectedBillOfMaterial = createSelector(
	getSelections,
	(entities) => {
		return entities.bill_of_materials.selected_item;
	},
);

// Applicable Products
export const getApplicableProductPage = createSelector(
	getEntities,
	(entities) => {
		return entities.applicable_products;
	},
);

export const getSelectedApplicableProductId = createSelector(
	getSelections,
	(entities) => {
		return entities.applicable_products.selected_id;
	},
);
export const getSelectedApplicableProduct = createSelector(
	getSelections,
	(entities) => {
		return entities.applicable_products.selected_item;
	},
);

// Application Methods
export const getApplicationMethodPage = createSelector(
	getEntities,
	(entities) => {
		return entities.application_methods;
	},
);

export const getSelectedApplicationMethodId = createSelector(
	getSelections,
	(entities) => {
		return entities.application_methods.selected_id;
	},
);
export const getSelectedApplicationMethod = createSelector(
	getSelections,
	(entities) => {
		return entities.application_methods.selected_item;
	},
);

// Rate Types
export const getRateTypePage = createSelector(getEntities, (entities) => {
	return entities.rate_types;
});

export const getSelectedRateTypeId = createSelector(
	getSelections,
	(entities) => {
		return entities.rate_types.selected_id;
	},
);

export const getSelectedRateType = createSelector(getSelections, (entities) => {
	return entities.rate_types.selected_item;
});

// Vendors
export const getVendorPage = createSelector(getEntities, (entities) => {
	return entities.vendors;
});

export const getSelectedVendorId = createSelector(getSelections, (entities) => {
	return entities.vendors.selected_id;
});

export const getSelectedVendor = createSelector(getSelections, (entities) => {
	return entities.vendors.selected_item;
});

// Vendor Categories
export const getVendorCategoryPage = createSelector(getEntities, (entities) => {
	return entities.vendor_categories;
});

export const getSelectedVendorCategoryId = createSelector(
	getSelections,
	(selections) => {
		return selections.vendor_categories.selected_id;
	},
);

export const getSelectedVendorCategory = createSelector(
	getSelections,
	(selections) => {
		return selections.vendor_categories.selected_item;
	},
);

// Purchase Orders
export const getPurchaseOrderPage = createSelector(getEntities, (entities) => {
	return entities.purchase_orders;
});

export const getSelectedPurchaseOrderId = createSelector(
	getSelections,
	(selections) => {
		return selections.purchase_orders.selected_id;
	},
);

export const getSelectedPurchaseOrder = createSelector(
	getSelections,
	(selections) => {
		return selections.purchase_orders.selected_item;
	},
);

// Audit Log
export const getActivityLogPage = createSelector(getEntities, (entities) => {
	return entities.audit_logs;
});

export const getSelectedActivityLogId = createSelector(
	getSelections,
	(entities) => {
		return entities.audit_logs.selected_id;
	},
);

export const getSelectedActivityLog = createSelector(
	getSelections,
	(entities) => {
		return entities.audit_logs.selected_item;
	},
);

// Status
export const getStatusPage = createSelector(getEntities, (entities) => {
	return entities.statuses;
});

export const getSelectedStatusId = createSelector(getSelections, (entities) => {
	return entities.statuses.selected_id;
});

export const getSelectedStatus = createSelector(getSelections, (selections) => {
	return selections.statuses.selected_item;
});

// Batch Types
export const getBatchTypePage = createSelector(getEntities, (entities) => {
	return entities.batch_types;
});

export const getSelectedBatchTypeId = createSelector(
	getSelections,
	(selections) => {
		return selections.batch_types.selected_id;
	},
);

export const getSelectedBatchType = createSelector(
	getSelections,
	(selections) => {
		return selections.batch_types.selected_item;
	},
);

// Equipment
export const getEquipmentPage = createSelector(getEntities, (entities) => {
	return entities.equipments;
});

export const getSelectedEquipmentId = createSelector(
	getSelections,
	(selections) => {
		return selections.equipments.selected_id;
	},
);

export const getSelectedEquipment = createSelector(
	getSelections,
	(selections) => {
		return selections.equipments.selected_item;
	},
);

// Document Set
export const getDocumentSetPage = createSelector(getEntities, (entities) => {
	return entities.document_sets;
});
export const getSelectedDocumentSetId = createSelector(
	getSelections,
	(selections) => {
		return selections.document_sets.selected_id;
	},
);
export const getSelectedDocumentSet = createSelector(
	getSelections,
	(selections) => {
		return selections.document_sets.selected_item;
	},
);

// Printer
export const getPrinterPage = createSelector(getEntities, (entities) => {
	return entities.printers;
});
export const getSelectedPrinterId = createSelector(
	getSelections,
	(selections) => {
		return selections.printers.selected_id;
	},
);
export const getSelectedPrinter = createSelector(
	getSelections,
	(selections) => {
		return selections.printers.selected_item;
	},
);

export const getPrintJobsPage = createSelector(getEntities, (entities) => {
	return entities.jobs;
});

export const getPrintJobs = createSelector(getEntities, (entities) => {
	return entities.printer_jobs;
});

// Printer Job Templates
export const getPrinterJobTemplatePage = createSelector(
	getEntities,
	(selections) => {
		return selections.printer_job_templates;
	},
);
export const getSelectedPrinterJobTemplateId = createSelector(
	getSelections,
	(selections) => {
		return selections.printer_job_templates.selected_id;
	},
);
export const getSelectedPrinterJobTemplate = createSelector(
	getSelections,
	(selections) => {
		return selections.printer_job_templates.selected_item;
	},
);

// Sku
export const getSkuPage = createSelector(getEntities, (entities) => {
	return entities.skus;
});

export const getSelectedSkuId = createSelector(getSelections, (selections) => {
	return selections.skus.selected_id;
});

export const getSelectedSku = createSelector(getSelections, (selections) => {
	return selections.skus.selected_item;
});

// Inventory
export const getInventoryPage = createSelector(getEntities, (entities) => {
	return entities.inventories;
});

export const getSelectedInventoryId = createSelector(
	getSelections,
	(selections) => {
		return selections.inventories.selected_id;
	},
);
export const getSelectedInventory = createSelector(
	getSelections,
	(selections) => {
		return selections.inventories.selected_item;
	},
);

// Inventory Product
export const getInventoryProductPage = createSelector(
	getEntities,
	(entities) => {
		return entities.inventory_products;
	},
);
export const getSelectedInventoryProductId = createSelector(
	getSelections,
	(selections) => {
		return selections.inventory_products.selected_id;
	},
);
export const getSelectedInventoryProduct = createSelector(
	getSelections,
	(selections) => {
		return selections.inventory_products.selected_item;
	},
);

// Inventory Categories
export const getInventoryCategoryPage = createSelector(
	getEntities,
	(entities) => {
		return entities.inventory_categories;
	},
);

export const getSelectedInventoryCategoryId = createSelector(
	getSelections,
	(selections) => {
		return selections.inventory_categories.selected_id;
	},
);

export const getSelectedInventoryCategory = createSelector(
	getSelections,
	(selections) => {
		return selections.inventory_categories.selected_item;
	},
);

// Inventory Units
export const getInventoryUnitPage = createSelector(getEntities, (entities) => {
	return entities.inventory_units;
});

export const getSelectedInventoryUnitId = createSelector(
	getSelections,
	(selections) => {
		return selections.inventory_units.selected_id;
	},
);

export const getSelectedInventoryUnit = createSelector(
	getSelections,
	(selections) => {
		return selections.inventory_units.selected_item;
	},
);

// Equivalent Units

export const getEquivalentUnitPage = createSelector(getEntities, (entities) => {
	return entities.equivalent_units;
});

export const getSelectedEquivalentUnitId = createSelector(
	getSelections,
	(selections) => {
		return selections.equivalent_units.selected_id;
	},
);
export const getSelectedEquivalentUnit = createSelector(
	getSelections,
	(selections) => {
		return selections.equivalent_units.selected_item;
	},
);

// Inventory lot tracking
export const getLotPage = createSelector(getEntities, (entities) => {
	return entities.lots;
});

export const getSelectedLotId = createSelector(getSelections, (selections) => {
	return selections.lots.selected_id;
});

export const getSelectedLot = createSelector(getSelections, (selections) => {
	return selections.lots.selected_item;
});
