import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import {
	timeout,
	catchError,
	take,
	takeUntil,
	filter,
	tap,
} from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { IReportType } from "@elevatedsignals/amygoodman";
import { ReportTypeDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "report-type-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class ReportTypeUpdateComponent
	extends GenericUpdateComponent<IReportType>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			reporting_category_ids: {
				type: "array",
				title: "Reporting Category",
				title_translation_key: marker("word_reporting_category"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "reporting_categories",
							text_key: ["name"],
						},
					],
				},
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
		},
		required: ["name"],
	};

	report_type$ = this._store.select(fromDashboard.getSelectedReportType);
	report_type: IReportType;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
	) {
		super(_store, _cd);
		this.form_title = "Update Report Type";
		this.form_title_translation_key = marker("form_title_update_report_type");
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.report_type$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((report_type: IReportType) => {
				this.report_type = report_type;
				this.model.id = this.report_type.id;
				this.model.name = this.report_type.name;
				this.model.archived = this.report_type.archived;
				this.model.reporting_category_ids =
					this.report_type.reporting_categories?.map((category) => category.id);
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(type: Partial<IReportType>) {
		const updated_category: Partial<IReportType> = {
			name: type.name || undefined,
			reporting_category_ids: type.reporting_category_ids || [],
		};

		this.loading$.next(true);
		this._itemService
			.update(`report_type`, type.id!, updated_category, ReportTypeDetailQuery)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "report_types",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
