/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MeasureUnit } from "@elevatedsignals/amygoodman";
import { FacilitySettings } from "app/modules/dashboard/state/facilitySettings.state";
import { getSelectedFacilitySettings } from "app/modules/dashboard/selectors/facility-settings.selector";
import { Store } from "@ngrx/store";
import { State } from "app/modules/dashboard/state/dashboard.state";

type UnitMap = Record<string, Record<string, string>>;
@Injectable({
	providedIn: "root",
})
export class Globals {
	facilitySettings$ = this._store.select(getSelectedFacilitySettings);

	constructor(private readonly _store: Store<State>) {
		this.facilitySettings$.subscribe((facilitySettings) => {
			this.updateFacilityGlobals(facilitySettings);
		});
	}

	/**
	 * Set up default units map for anything that's not overridden by facility settings.
	 */
	private readonly _unitMap: UnitMap = {
		[MeasureUnit.Mass.toString()]: {
			default: "kg",
			seed_lot: "g",
		},
		[MeasureUnit.Volume.toString()]: {
			default: "litres",
		},
		[MeasureUnit.Quantity.toString()]: {
			default: "quantity",
			seed_lot: "seeds",
		},
	};

	getFacilityUnit(baseUnit: MeasureUnit, setting = "default") {
		if (!this._unitMap[baseUnit]) {
			return undefined;
		}
		return this._unitMap[baseUnit]?.[setting]
			? this._unitMap[baseUnit]![setting]
			: this._unitMap[baseUnit]!.default;
	}

	/**
	 *  Licence Settings
	 */
	// General Inventory
	public general_inventory_enabled_replay: BehaviorSubject<boolean> =
		new BehaviorSubject(false);

	get general_inventory_enabled(): boolean {
		return this.general_inventory_enabled_replay.getValue();
	}

	set general_inventory_enabled(value: boolean) {
		this.general_inventory_enabled_replay.next(value);
	}

	// @deprecated
	// EZ GI migration
	public ez_gi_migration_replay: BehaviorSubject<boolean> = new BehaviorSubject(
		false,
	);

	get ez_gi_migration(): boolean {
		return this.ez_gi_migration_replay.getValue();
	}

	set ez_gi_migration(value: boolean) {
		this.ez_gi_migration_replay.next(value);
	}

	// GMP
	public gmp_enabled_replay: BehaviorSubject<boolean> = new BehaviorSubject(
		false,
	);

	get gmp_enabled(): boolean {
		return String(this.gmp_enabled_replay.getValue()) == "true";
	}

	set gmp_enabled(value: boolean) {
		this.gmp_enabled_replay.next(value);
	}

	public weight_unit_replay = new BehaviorSubject(
		this._unitMap[MeasureUnit.Mass]!.default!,
	);

	get weight_unit(): string {
		return this._unitMap[MeasureUnit.Mass]!.default!;
	}

	set weight_unit(value: string) {
		this._unitMap[MeasureUnit.Mass]!.default = value;
		this.weight_unit_replay.next(value);
	}

	// Destruction Witness Number, if exists
	private _numberOfWitnessSignaturesRequired = 2;
	get numberOfWitnessSignaturesRequired(): number {
		return this._numberOfWitnessSignaturesRequired;
	}

	set numberOfWitnessSignaturesRequired(value: number) {
		this._numberOfWitnessSignaturesRequired = value;
	}

	// Seed Lot Weight Units
	public seed_lot_weight_unit_replay: BehaviorSubject<string> =
		new BehaviorSubject(this._unitMap[MeasureUnit.Mass]!.seed_lot!);

	get seed_lot_weight_unit(): string {
		return this._unitMap[MeasureUnit.Mass]!.seed_lot!;
	}

	set seed_lot_weight_unit(value: string) {
		this._unitMap[MeasureUnit.Mass]!.seed_lot = value;
		this.seed_lot_weight_unit_replay.next(value);
		// console.log("seed lot weight unit", value);
	}

	// Seed Lot Substance Types
	// @deprecated
	private _seedLotSubstanceTypes: number[] = [];
	get seedLotSubstanceTypes(): number[] {
		return this._seedLotSubstanceTypes;
	}

	// @deprecated
	set seedLotSubstanceTypes(value: number[]) {
		this._seedLotSubstanceTypes = value;
	}

	// Cultivation Substance Types
	// @deprecated
	private _cultivationSubstanceTypes: number[] = [];
	get cultivationSubstanceTypes(): number[] {
		return this._cultivationSubstanceTypes;
	}

	// @deprecated
	set cultivationSubstanceTypes(value: number[]) {
		this._cultivationSubstanceTypes = value;
	}

	// Retention Lot Substance Types
	// @deprecated
	private _retentionSubstanceTypes: number[] = [];
	get retentionSubstanceTypes(): number[] {
		return this._retentionSubstanceTypes;
	}

	// @deprecated
	set retentionSubstanceTypes(value: number[]) {
		this._retentionSubstanceTypes = value;
	}

	// Wet Weight Substance Types
	// @deprecated
	private _wetWeightSubstanceTypes: number[] = [];
	get wetWeightSubstanceTypes(): number[] {
		return this._wetWeightSubstanceTypes;
	}

	// @deprecated
	set wetWeightSubstanceTypes(value: number[]) {
		this._wetWeightSubstanceTypes = value;
	}

	// Dry Weight Substance Types
	// @deprecated
	private _dryWeightSubstanceTypes: number[] = [];
	get dryWeightSubstanceTypes(): number[] {
		return this._dryWeightSubstanceTypes;
	}

	// @deprecated
	set dryWeightSubstanceTypes(value: number[]) {
		this._dryWeightSubstanceTypes = value;
	}

	// Seed Lot Inventory Products
	private _seedLotInventoryProducts: number[] = [];
	get seedLotInventoryProducts(): number[] {
		return this._seedLotInventoryProducts;
	}

	set seedLotInventoryProducts(value: number[]) {
		this._seedLotInventoryProducts = value;
	}

	// Cultivation Inventory Products
	private _cultivationInventoryProducts: number[] = [];
	get cultivationInventoryProducts(): number[] {
		return this._cultivationInventoryProducts;
	}

	set cultivationInventoryProducts(value: number[]) {
		this._cultivationInventoryProducts = value;
	}

	// Retention Lot Inventory Products
	private _retentionInventoryProducts: number[] = [];
	get retentionInventoryProducts(): number[] {
		return this._retentionInventoryProducts;
	}

	set retentionInventoryProducts(value: number[]) {
		this._retentionInventoryProducts = value;
	}

	// Wet Weight Inventory Products
	private _wetWeightInventoryProducts: number[] = [];
	get wetWeightInventoryProducts(): number[] {
		return this._wetWeightInventoryProducts;
	}

	set wetWeightInventoryProducts(value: number[]) {
		this._wetWeightInventoryProducts = value;
	}

	// Dry Weight Inventory Products
	private _dryWeightInventoryProducts: number[] = [];
	get dryWeightInventoryProducts(): number[] {
		return this._dryWeightInventoryProducts;
	}

	set dryWeightInventoryProducts(value: number[]) {
		this._dryWeightInventoryProducts = value;
	}

	// Batch Split Work Order Type
	private _batchSplitWorkOrderType: number;
	get batchSplitWorkOrderType(): number {
		return this._batchSplitWorkOrderType;
	}

	set batchSplitWorkOrderType(value: number) {
		this._batchSplitWorkOrderType = value;
	}

	// Propagation Work Order Types
	private _propagationWorkOrderTypes: number[] = [];
	get propagationWorkOrderTypes(): number[] {
		return this._propagationWorkOrderTypes;
	}

	set propagationWorkOrderTypes(value: number[]) {
		this._propagationWorkOrderTypes = value;
	}

	// Growth Stage Change Work Order Type
	private _growthStageWorkOrderType: number;
	get growthStageWorkOrderType(): number {
		return this._growthStageWorkOrderType;
	}

	set growthStageWorkOrderType(value: number) {
		this._growthStageWorkOrderType = value;
	}

	// Batch Move Work Order Type
	private _batchMoveWorkOrderType: number;
	get batchMoveWorkOrderType(): number {
		return this._batchMoveWorkOrderType;
	}

	set batchMoveWorkOrderType(value: number) {
		this._batchMoveWorkOrderType = value;
	}

	// Plant Move Work Order Type
	private _plantMoveWorkOrderType: number;
	get plantMoveWorkOrderType(): number {
		return this._plantMoveWorkOrderType;
	}

	set plantMoveWorkOrderType(value: number) {
		this._plantMoveWorkOrderType = value;
	}

	// Harvest Work Order Type
	private _harvestWorkOrderType: number;
	get harvestWorkOrderType(): number {
		return this._harvestWorkOrderType;
	}

	set harvestWorkOrderType(value: number) {
		this._harvestWorkOrderType = value;
	}

	// Shipping Work Order Type
	private _shippingWorkOrderType: number;
	get shippingWorkOrderType(): number {
		return this._shippingWorkOrderType;
	}

	set shippingWorkOrderType(value: number) {
		this._shippingWorkOrderType = value;
	}

	// Work Order Page Expand All / Collapse All default state
	private _workOrderPageExpandTabsByDefault: string;
	get workOrderPageExpandTabsByDefault(): string {
		return this._workOrderPageExpandTabsByDefault;
	}

	set workOrderPageExpandTabsByDefault(value: string) {
		this._workOrderPageExpandTabsByDefault = value;
	}

	// Close Record on WO Close
	private _lockRecordsOnWorkOrderClose: boolean;
	get lockRecordsOnWorkOrderClose(): boolean {
		return this._lockRecordsOnWorkOrderClose;
	}

	set lockRecordsOnWorkOrderClose(value: boolean) {
		this._lockRecordsOnWorkOrderClose = value;
	}

	// Block duplicate batch names
	private _blockDuplicateBatchNames: boolean;
	get blockDuplicateBatchNames(): boolean {
		return this._blockDuplicateBatchNames;
	}

	set blockDuplicateBatchNames(value: boolean) {
		this._blockDuplicateBatchNames = value;
	}

	// Batch Page Inventory Ledger default state
	private _batchPageExpandInventoryLedgerByDefault: string;
	get batchPageExpandInventoryLedgerByDefault(): string {
		return this._batchPageExpandInventoryLedgerByDefault;
	}

	set batchPageExpandInventoryLedgerByDefault(value: string) {
		this._batchPageExpandInventoryLedgerByDefault = value;
	}

	// Barcode Type
	private _barcode_type = "N/A";
	public barcode_type_replay: BehaviorSubject<string> = new BehaviorSubject(
		this._barcode_type,
	);

	get barcode_type(): string {
		return this._barcode_type;
	}

	set barcode_type(value: string) {
		this._barcode_type = value;
		this.barcode_type_replay.next(value);
	}

	// Printer ID
	private _default_printer_id = 0;
	public printer_id_replay: BehaviorSubject<number> = new BehaviorSubject(
		this._default_printer_id,
	);

	get printer_id(): number {
		return this._default_printer_id;
	}

	set printer_id(value: number) {
		this._default_printer_id = value;
		this.printer_id_replay.next(value);
	}

	// PBR Settings
	private _pbr_settings: Record<string, string | string[]> = {};
	get pbr_settings(): Record<string, string | string[]> {
		return this._pbr_settings;
	}

	set pbr_settings(value: Record<string, string | string[]>) {
		this._pbr_settings = value;
	}

	// Facility Defaults
	private _default_vendor_id;
	get default_vendor_id(): number | undefined {
		return this._default_vendor_id;
	}

	set default_vendor_id(value: number | undefined) {
		this._default_vendor_id = value;
	}

	private _default_inventory_category_id;
	get default_inventory_category_id(): number | undefined {
		return this._default_inventory_category_id;
	}

	set default_inventory_category_id(value: number | undefined) {
		this._default_inventory_category_id = value;
	}

	updateFacilityGlobals(facility: FacilitySettings) {
		if (facility.data && facility.data.options) {
			if (facility.data.options.weight_unit) {
				this.weight_unit = facility.data.options.weight_unit;
			}
			if (facility.data.options.seed_lot_weight_unit) {
				this.seed_lot_weight_unit = facility.data.options.seed_lot_weight_unit;
			}
			if (facility.data.options.number_witness_signatures_required) {
				this.numberOfWitnessSignaturesRequired =
					facility.data.options.number_witness_signatures_required;
			}
			if (facility.data.options.default_printer) {
				this.printer_id = facility.data.options.default_printer;
			}
			// @deprecated
			if (facility.data.options.wet_weight_substance_types) {
				this.wetWeightSubstanceTypes =
					facility.data.options.wet_weight_substance_types;
			}
			// @deprecated
			if (facility.data.options.dry_weight_substance_types) {
				this.dryWeightSubstanceTypes =
					facility.data.options.dry_weight_substance_types;
			}
			// @deprecated
			if (facility.data.options.seed_lot_substance_types) {
				this.seedLotSubstanceTypes = facility.data.options.seed_lot_substance_types;
			}
			// @deprecated
			if (facility.data.options.cultivation_substance_types) {
				this.cultivationSubstanceTypes =
					facility.data.options.cultivation_substance_types;
			}
			// @deprecated
			if (facility.data.options.retention_substance_types) {
				this.retentionSubstanceTypes =
					facility.data.options.retention_substance_types;
			}
			if (facility.data.options.seed_lot_inventory_products) {
				this.seedLotInventoryProducts =
					facility.data.options.seed_lot_inventory_products;
			}
			if (facility.data.options.cultivation_inventory_products) {
				this.cultivationInventoryProducts =
					facility.data.options.cultivation_inventory_products;
			}
			if (facility.data.options.retention_inventory_products) {
				this.retentionInventoryProducts =
					facility.data.options.retention_inventory_products;
			}
			if (facility.data.options.wet_weight_inventory_products) {
				this.wetWeightInventoryProducts =
					facility.data.options.wet_weight_inventory_products;
			}
			if (facility.data.options.dry_weight_inventory_products) {
				this.dryWeightInventoryProducts =
					facility.data.options.dry_weight_inventory_products;
			}
			if (facility.data.options.batch_split_work_order_type) {
				this.batchSplitWorkOrderType =
					facility.data.options.batch_split_work_order_type;
			}
			if (facility.data.options.propagation_work_order_types) {
				this.propagationWorkOrderTypes =
					facility.data.options.propagation_work_order_types;
			}
			if (facility.data.options.growth_stage_work_order_type) {
				this.growthStageWorkOrderType =
					facility.data.options.growth_stage_work_order_type;
			}
			if (facility.data.options.batch_move_work_order_type) {
				this.batchMoveWorkOrderType =
					facility.data.options.batch_move_work_order_type;
			}
			if (facility.data.options.plant_move_work_order_type) {
				this.plantMoveWorkOrderType =
					facility.data.options.plant_move_work_order_type;
			}
		}

		this.general_inventory_enabled = facility.licence.general_inventory;
		this.ez_gi_migration = facility.licence.ez_gi_migration;
		this.gmp_enabled = facility.licence.gmp;

		if (
			facility.data?.options &&
			"show_audit_log_on_records" in facility.data.options
		) {
			this.pbr_settings = {
				show_audit_log_on_records: `${facility.data.options.show_audit_log_on_records}`,
			};
		}
		if (facility.data?.defaults) {
			this.default_vendor_id = facility.data.defaults.vendor_id;
			this.default_inventory_category_id =
				facility.data.defaults.inventory_category_id;
		}
	}
}
