import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
	selector: "submit-button",
	template: `
		<button
			*transloco="let t"
			class="ui button blue submit_button"
			[ngClass]="{
				loading,
				disabled: loading || !valid,
			}"
			[disabled]="loading || !valid"
			(click)="onSubmit.emit()"
		>
			<span>
				<i *ngIf="submit_icon" class="{{ submit_icon }} icon"></i
				><!--
    -->{{
					submit_button_translation_key
						? t(submit_button_translation_key)
						: submit_button
				}}<!--
			--></span
			>
		</button>
	`,
})
export class SubmitButtonComponent {
	@Input() loading: boolean;
	@Input() valid: boolean;

	@Input() submit_icon: string | null = null;
	@Input() submit_button = "Submit";
	@Input() submit_button_translation_key: string | null = null;

	@Output() onSubmit = new EventEmitter();
}
