import { MeasureUnit } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

export interface IBillOfMaterialInventory {
	id: number;
	is_input: boolean;
	input_type: string;
	source?: string;
	batch_id?: number;
	inventory_type?: string | MeasureUnit;
	use_remaining?: boolean;
	substance_type_id?: number;
	value?: number;

	plant_batch_id?: number;
	plant_selection?: string;
	remaining_plants?: boolean;
	use_plant_ids?: boolean;
	plant_ids?: number[];
	plant_count?: number;

	location_id?: number;
	inventory_product_id?: number;
	remaining_inventory?: boolean;
	inventory_unit_id?: number;
	inventory_ids?: number[];
	lot_id?: number;
	quantity?: number;

	timestamp?: Date;
	move_batch_to_work_order_location?: number;
}

export const BillOfMaterialInventorySchema = {
	title: "",
	description: "",
	info: "",
	properties: {
		id: {
			type: "number",
			hidden: true,
		},
		input_type: {
			type: "string",
			title: "Input Type",
			title_translation_key: marker("form_field_label_input_type"),
			widget: "select",
			default: "Inventory",
			readonly: true,
			oneOf: [
				{
					value: "Plant Material",
					enum: ["Plant Material"],
					name: "Plant Material",
					name_translation_key: marker("form_field_value_plant_material"),
				},
				{
					value: "Inventory",
					enum: ["Inventory"],
					name: "Inventory",
					name_translation_key: marker("word_inventory"),
				},
			],
		},
		inventory_product_id: {
			title: "Product",
			title_translation_key: marker("word_product"),
			type: "number",
			widget: "data-select",
			readonly: true,
			quick_create: false,
			related_properties: ["location_id", "batch_id"],
			oneOf: [
				{
					result_type: "inventory_products",
				},
			],
			visibleIf: {
				oneOf: [{ source: ["$ANY$"] }],
			},
		},
		inventory_unit_id: {
			title: "Inventory Unit",
			title_translation_key: marker("word_inventory_unit"),
			type: "number",
			widget: "data-select",
			readonly: true,
			quick_create: false,
			related_properties: ["inventory_product_id"],
			oneOf: [
				{
					result_type: "inventory_units",
				},
			],
			visibleIf: {
				allOf: [{ source: ["$ANY$"] }],
			},
		},
		quantity: {
			type: "number",
			title: `Quantity`,
			title_translation_key: marker("word_quantity"),
			readonly: true,
			visibleIf: {
				allOf: [
					{
						source: ["$ANY$"],
					},
				],
			},
		},

		/**
		 * Inventory Fields
		 */
		source: {
			type: "string",
			title: "Source",
			title_translation_key: marker("word_source"),
			widget: "select",
			default: "Everywhere",
			oneOf: [
				{
					value: "Batch",
					enum: ["Batch"],
					name: "Batch",
					name_translation_key: marker("word_batch"),
				},
				{
					value: "Location",
					enum: ["Location"],
					name: "Location",
					name_translation_key: marker("word_location"),
				},
				{
					value: "Lots",
					enum: ["Lots"],
					name: "Lots",
					name_translation_key: marker("word_lots"),
				},
				{
					value: "Everywhere",
					enum: ["Everywhere"],
					name: "Everywhere",
					name_translation_key: marker("word_everywhere"),
				},
			],
			visibleIf: {
				input_type: ["Inventory"],
			},
		},
		batch_id: {
			type: "number",
			title: "Source Batch",
			title_translation_key: marker("form_field_label_source_batch"),
			widget: "data-select",
			quick_create: false,
			oneOf: [
				{
					result_type: "batches",
					queryString: {
						input: "true",
						dont_show_expired: "true",
					},
				},
			],
			visibleIf: {
				source: ["Batch"],
			},
		},
		location_id: {
			type: "number",
			title: "Source Location",
			title_translation_key: marker("form_field_label_source_location"),
			widget: "data-select",
			quick_create: false,
			oneOf: [
				{
					result_type: "locations",
					queryString: {
						input: "true",
					},
				},
			],
			visibleIf: {
				oneOf: [
					{
						source: ["Location"],
					},
				],
			},
		},
		lot_id: {
			title: "Inventory Lot (optional)",
			title_translation_key: marker("form_field_label_inventory_lot_optional"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			related_properties: ["location_id", "batch_id"],
			oneOf: [
				{
					result_type: "lots",
					dont_show_expired: "true",
				},
			],
			visibleIf: {
				allOf: [{ source: ["Lots"] }],
			},
		},
		timestamp: {
			type: "string",
			title: "Timestamp",
			title_translation_key: marker("word_timestamp"),
			widget: "date",
		},

		/** These are just used for the required to make sure we have all the correct items selected before allowing the user to submit the form */
		location_selected: {
			type: "boolean",
			widget: "checkbox",
			hidden: true,
			default: true,
			visibleIf: {
				source: ["Location"],
			},
		},
		batch_selected: {
			type: "boolean",
			widget: "checkbox",
			hidden: true,
			default: true,
			visibleIf: {
				source: ["Batch"],
			},
		},
	},
	anyOf: [
		/** **********************/
		/** INVENTORY REQUIRED **/
		/** **********************/
		/** FOR BATCH */
		// Enter specific quantity of inventory
		{
			required: [
				"id",
				"batch_selected",
				"batch_id",
				"inventory_product_id",
				"inventory_unit_id",
				"quantity",
			],
		},
		/** FOR LOCATION */
		// Enter specific quantity of inventory
		{
			required: [
				"id",
				"location_selected",
				"location_id",
				"inventory_product_id",
				"inventory_unit_id",
				"quantity",
			],
		},
	],
};
