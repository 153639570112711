/* jscpd:ignore-start */
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
	SimpleChanges,
	SimpleChange,
} from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import {
	BatchDetailQuery,
	InventoryDetailQuery,
	WorkOrderDestructionEventsQuery,
} from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { canCloseBatch, handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import { ItemKeyType } from "app/modules/dashboard/reducers/selection/keys";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

import { GenericCreateComponent } from "../generic/generic-create.component";
import {
	fetchNewInventoryTotals,
	getDynamicFormChanges,
	hasInventoryIdsChanged,
	hasPropertyChanged,
	onVendorChange,
} from "../shared";
import { BatchCloseComponent } from "../batch";

import {
	InventoryDestructionSchema,
	InventoryDestruction,
} from "./schemas/inventory-destruction-create";

@Component({
	selector: "inventory-destruction-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class InventoryDestructionComponent
	extends GenericCreateComponent<InventoryDestruction>
	implements OnInit, OnDestroy
{
	schema: any = InventoryDestructionSchema;

	private batch_id: number;
	private vendor_id: number;
	private inventory_product_id: number;

	private destruction_lot_id: number;
	private inventory_id: number;
	private result_type: "destruction_lot" | "work_order" | "inventory" | "batch";
	private prevInventoryProductId: number | undefined;
	private readonly whatChanged: SimpleChanges = {};

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _injector: Injector,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store);
		this.form_title = "Inventory Destruction";
		this.form_title_translation_key = marker("form_title_inventory_destruction");
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
		this.submit_icon = "plus";
	}

	ngOnInit() {
		this.batch_id = this._injector.get("batch_id", null);
		this.inventory_product_id = this._injector.get("inventory_product_id", null);
		this.destruction_lot_id = this._injector.get("destruction_lot_id", null);
		this.inventory_id = this._injector.get("inventory_id", null);
		this.result_type = this._injector.get("result_type", "destruction_lot");
		this.vendor_id = this._injector.get("vendor_id", null);

		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		let changes = false;

		if (this.schema.properties.location_id) {
			this.schema.properties.location_id.hidden = true;
		}

		if (this.destruction_lot_id) {
			this.model = {
				...this.model,
				destruction_lot_id: this.destruction_lot_id,
			};
			this.schema.properties.destruction_lot_id = {
				...this.schema.properties.destruction_lot_id,
			};

			if (this.schema.properties.batch_id) {
				this.schema.properties.batch_id.readOnly = false;
				delete this.model.batch_id;
				changes = true;
			}
		}

		if (this.inventory_product_id) {
			this.model.inventory_product_id = this.inventory_product_id;
			this.schema.properties.inventory_product_id.readOnly = true;
		} else {
			this.schema.properties.inventory_product_id.readOnly = false;
		}

		if (this.batch_id) {
			this.model.batch_id = this.batch_id;
			this.schema.properties.batch_id.readOnly = true;
		} else {
			this.schema.properties.batch_id.readOnly = false;
		}

		if (changes) {
			this._cd.detectChanges();
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		getDynamicFormChanges(this.whatChanged, model, true);
		this.autoSelectForm();
		this.checkAndUpdateInventoryTotals();

		if (!model.inventory_product_id && this.prevInventoryProductId) {
			this.prevInventoryProductId = undefined;
			model.inventory_unit_id = undefined;
		} else if (
			model.inventory_product_id &&
			this.prevInventoryProductId !== model.inventory_product_id
		) {
			this.prevInventoryProductId = model.inventory_product_id;
			model.inventory_unit_id = undefined;
			const firstProductSet = !this.model.inventory_product_id;
			if (this.schema.properties.existing_inventory && !this.inventory_id) {
				model.existing_inventory =
					!this._globals.cultivationInventoryProducts.includes(
						model.inventory_product_id,
					);
			}
			this._itemService
				.fetchItem(`inventory_product`, `${model.inventory_product_id}`, {
					eager: "[vendors]",
				})
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((inventory_product) => {
					if (!firstProductSet || !this.model.inventory_unit_id) {
						this.model.inventory_unit_id = inventory_product.display_unit_id;
					}
					if (this.schema.properties.existing_inventory && !this.inventory_id) {
						this.model.existing_inventory = !(
							this._globals.cultivationInventoryProducts.includes(
								model.inventory_product_id,
							) || inventory_product.non_inventory_destruction
						);
					}

					this.schema.properties.sku_id.hidden = !inventory_product.require_sku;

					if (inventory_product.require_batch) {
						this.schema.properties.batch_id.hidden = false;
						this.schema.properties.existing_inventory.hidden = false;
					} else {
						this.schema.properties.batch_id.hidden = true;
						this.schema.properties.existing_inventory.hidden = true;
					}

					if (!this.vendor_id) {
						[this.model, this.schema] = onVendorChange(
							this.model,
							this.schema,
							inventory_product,
						);
					}

					this._cd.detectChanges();
				});
		}
	}

	autoSelectForm = (): void => {
		let requiresDetectChanges = false;
		if (this.itemChanged(this.whatChanged.batch_id)) {
			requiresDetectChanges = this.batchChanged();
		} else if (this.itemChanged(this.whatChanged.lot_id)) {
			requiresDetectChanges = this.lotChanged();
		}

		if (requiresDetectChanges) {
			this._cd.detectChanges();
		}
	};

	itemChanged = (change: SimpleChange | undefined): boolean => {
		if (change?.currentValue !== change?.previousValue) {
			return true;
		}
		return false;
	};

	batchChanged = () => {
		if (this.whatChanged.batch_id!.currentValue) {
			// Here we should be setting inventory_ids to [], lot_id to null. But, infinite loops.
		}

		return false;
	};

	lotChanged = () => {
		if (this.whatChanged.lot_id!.currentValue) {
			// Here we should be setting inventory_ids to []. But, infinite loops.
			return true;
		}
		return false;
	};

	checkAndUpdateInventoryTotals = () => {
		if (
			hasPropertyChanged(this.whatChanged.inventory_product_id) ||
			hasPropertyChanged(this.whatChanged.lot_id) ||
			hasPropertyChanged(this.whatChanged.batch_id) ||
			hasInventoryIdsChanged(this.whatChanged.inventory_ids) ||
			hasPropertyChanged(this.whatChanged.timestamp) ||
			hasPropertyChanged(this.whatChanged.vendor_id) ||
			hasPropertyChanged(this.whatChanged.sku_id)
		) {
			fetchNewInventoryTotals(this.whatChanged, this._itemService).subscribe(
				(availableInventoryAmount) => {
					this.model.amount_available = availableInventoryAmount.content
						.map((availableInventory) => {
							return `${availableInventory.sum.toFixed(2)} ${availableInventory.name}`;
						})
						.join("\n");

					this._cd.detectChanges();
				},
			);
		}
	};

	dispatchItem(item: any) {
		if (this.result_type === "destruction_lot") {
			this._store.dispatch(
				SelectionActions.select({
					result_type: "destruction_lots",
					id: this.model.destruction_lot_id,
				}),
			);
		} else {
			this._store.dispatch(
				ItemActions.updateSuccess({
					updatedItem: item,
					result_type: this.pluralize(this.result_type),
				}),
			);
		}
	}

	createItem(destruction_event: InventoryDestruction) {
		let query: any = null;
		switch (this.result_type) {
			case "work_order":
				query = WorkOrderDestructionEventsQuery;
				break;
			case "batch":
				query = BatchDetailQuery;
				break;
			case "inventory":
				query = InventoryDetailQuery;
				break;

			case "destruction_lot":
			default:
				query = {};
		}
		const item = {
			...destruction_event,
			vendor_id: this.model.vendor_id,
			result_type: this.result_type,
		};

		// Verify form has value if remaining_inventory is NOT selected
		if (
			!destruction_event.remaining_inventory &&
			(!destruction_event.quantity || destruction_event.quantity <= 0)
		) {
			this.error$.next(
				this._translocoService.translate(
					"error_inventory_destruction_must_include_positive_quantity",
				),
			);
			return;
		}

		this.loading$.next(true);
		this._itemService
			.add(
				`destruction_lots/${destruction_event.destruction_lot_id}/inventory`,
				item,
				query,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(50000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((item) => {
					this.dispatchItem(item);
					this.loading$.next(false);
					this.closeSidenav();

					if (this.pluralize(this.result_type) === "batches") {
						if (canCloseBatch(item, this._globals.general_inventory_enabled)) {
							this._store.dispatch(
								layoutActions.openSidenav({
									component: BatchCloseComponent,
								}),
							);
						}
					}
				}),
			)
			.subscribe();
	}

	private pluralize(
		item: "destruction_lot" | "work_order" | "inventory" | "batch",
	): ItemKeyType {
		if (item === "destruction_lot") {
			return "destruction_lots";
		} else if (item === "work_order") {
			return "work_orders";
		} else if (item === "inventory") {
			return "inventories";
		}

		return "batches";
	}
}

/* jscpd:ignore-end */
