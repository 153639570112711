<div
	*ngIf="harvest_type === Harvest_Type.wet_weight || harvest_type === Harvest_Type.dry_weight"
>
	<table class="ui very basic collapsing celled table">
		<thead>
			<tr>
				<th></th>
				<th>Weight ({{weight_unit}})</th>
				<th>Tared (y/n)</th>
				<th>Bin Weight ({{weight_unit}})</th>
				<th>Material Weight ({{weight_unit}})</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let partial_weight of weights; let i = index">
				<td></td>
				<td>{{partial_weight.weight | gramsToUnitPipe}}</td>
				<td>{{getTared(partial_weight) | gramsToUnitPipe}}</td>
				<td>{{getBinWeight(partial_weight) | gramsToUnitPipe}}</td>
				<td>{{getTotalWeight(partial_weight) | gramsToUnitPipe}}</td>
				<td *ngIf="isWet && !batch.harvested || !isWet && !batch.dry_weight">
					<button class="ui icon button" (click)="onHarvestDelete(batch, i)">
						<i class="close icon"></i>
					</button>
				</td>
			</tr>
			<tr>
				<th>Total</th>
				<td>--</td>
				<td>--</td>
				<td>--</td>
				<td>{{getTotalMaterialWeight(weights) | gramsToUnitPipe}}</td>
			</tr>
		</tbody>
	</table>
	<div
		*ngIf="isWet && !batch.harvested || !isWet && !batch.dry_weight"
		class="ui form"
	>
		<div class="inline fields">
			<label>Weight ({{weight_unit}})</label>
			<div class="field">
				<input [(ngModel)]="harvest_weight" type="number" step="0.00001" min="0" />
			</div>

			<label>Tared</label>
			<div class="field">
				<div class="ui checkbox">
					<fui-checkbox [(ngModel)]="harvest_tared"></fui-checkbox>
				</div>
			</div>

			<label *ngIf="!harvest_tared">Bin Weight ({{weight_unit}})</label>
			<div class="field" *ngIf="!harvest_tared">
				<input
					[(ngModel)]="harvest_bin_weight"
					type="number"
					step="0.00001"
					min="0"
				/>
			</div>

			<label
				>Total Weight ({{weight_unit}})
				<i
					*ngIf="calcTotalWeight() < 0"
					class="red circle info icon"
					fuiPopup
					popupPlacement="bottom center"
					popupText="Bin weight cannot exceed weighed amount."
				>
				</i>
			</label>
			<div class="field">{{calcTotalWeight()}}</div>

			<div class="field">
				<button
					class="circular ui"
					[ngClass]="(harvest_loading$ | async)? 'loading icon button' :
						(!harvestValid()? 'disabled icon button' : 'icon button')"
					(click)="onHarvestAdd(batch)"
				>
					<i class="green plus icon"></i>
				</button>
			</div>
		</div>
	</div>
	<div *ngIf="harvest_error$ | async" class="ui error message field">
		<span>{{harvest_error$ | async}}</span>
	</div>
</div>
