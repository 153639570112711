import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { TranslateErrorService } from "app/shared/errors/handleError";
import { storage } from "app/shared/storage";
import { environment } from "app/../environments/environment";
import {
	UserProfile,
	User,
	UserInvite,
	Role,
} from "@elevatedsignals/amygoodman";
import { ApiService } from "app/modules/auth/services/api.service";
import { TrackingService } from "app/tracking/tracking.service";

@Injectable({
	providedIn: "root",
})
export class UserService {
	private translateErrorService = inject(TranslateErrorService);
	private http = inject(HttpClient);

	constructor(private readonly _api: ApiService) {}

	fetch(): Observable<UserProfile> {
		return this.http.get<UserProfile>(this._api.profile.fetch()).pipe(
			map((userData) => {
				try {
					localStorage.setItem(storage.user, JSON.stringify(userData));
				} catch (error) {
					console.error(error);
				}
				return userData;
			}),
			tap((userData) => {
				try {
					TrackingService.identifyUser(userData);
				} catch (error) {
					console.error(error);
				}
			}),
			this.translateErrorService.catchAndTranslateError(
				"error_failed_to_fetch_user_data",
			),
		);
	}

	updateLastDashboardVersion(): Observable<UserProfile> {
		return this.http
			.patch<UserProfile>(this._api.users.esVersions(), {
				last_dashboard_version: environment.version,
			})
			.pipe(
				map((userData) => {
					localStorage.setItem(storage.user, JSON.stringify(userData));
					return userData;
				}),
				this.translateErrorService.catchAndTranslateError(
					"error_failed_to_update_users_last_seen_dashboard_version",
				),
			);
	}

	fetchUsers(): Observable<User[]> {
		return this.http
			.get<User[]>(this._api.users.fetch().url())
			.pipe(
				this.translateErrorService.catchAndTranslateError(
					"error_failed_to_get_user_list",
				),
			);
	}

	inviteUser(user: UserInvite): Observable<User> {
		return this.http
			.post<User>(this._api.users.invite().url(), user)
			.pipe(
				this.translateErrorService.catchAndTranslateError(
					"error_failed_to_send_user_invite",
				),
			);
	}

	reinviteUser(user: User): Observable<User> {
		return this.http
			.patch<User>(this._api.users.reinvite_user().url(user.id), user)
			.pipe(
				this.translateErrorService.catchAndTranslateError(
					"error_failed_to_send_user_invite",
				),
			);
	}

	fetchRoles(): Observable<Role[]> {
		return this.http
			.get<Role[]>(this._api.roles.list())
			.pipe(
				this.translateErrorService.catchAndTranslateError(
					"error_failed_to_get_roles_list",
				),
			);
	}

	patchSettings(user: UserProfile) {
		return this.http
			.patch(this._api.users.settings().url(), user)
			.pipe(
				this.translateErrorService.catchAndTranslateError(
					"error_failed_to_send_request_to_update_settings",
				),
			);
	}
}
