import { Action } from "@ngrx/store";
import { IRole, IPermission } from "@elevatedsignals/amygoodman";

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */

export const FETCH = "[Role] Fetch";
export const FETCH_SUCCESS = "[Role] Fetch Success";
export const FETCH_FAIL = "[Role] Fetch Fail";
export const ADD = "[Role] Add";
export const ADD_SUCCESS = "[Role] Add Success";
export const ADD_FAIL = "[Role] Add Fail";
export const UPDATE = "[Role] Update";
export const UPDATE_SUCCESS = "[Role] Update Success";
export const UPDATE_FAIL = "[Role] Update Fail";
export const SELECT = "[Role] Select";

export const FETCH_PERMISSIONS = "[Role] Fetch Permissions";
export const FETCH_PERMISSIONS_SUCCESS = "[Role] Fetch Permissions Success";
export const FETCH_PERMISSIONS_FAIL = "[Role] Fetch Permissions Fail";

export class FetchAction implements Action {
	readonly type = FETCH;

	constructor(public payload?) {}
}

export class FetchSuccessAction implements Action {
	readonly type = FETCH_SUCCESS;

	constructor(public payload: IRole[]) {}
}

export class FetchFailAction implements Action {
	readonly type = FETCH_FAIL;

	constructor(public payload: string) {}
}

export class AddAction implements Action {
	readonly type = ADD;

	constructor(public payload: IRole) {}
}

export class AddSuccessAction implements Action {
	readonly type = ADD_SUCCESS;

	constructor(public payload: Partial<IRole>) {}
}

export class AddFailAction implements Action {
	readonly type = ADD_FAIL;

	constructor(public payload: string) {}
}

export class UpdateAction implements Action {
	readonly type = UPDATE;

	constructor(public payload: Partial<IRole>) {}
}

export class UpdateSuccessAction implements Action {
	readonly type = UPDATE_SUCCESS;

	constructor(public payload: IRole) {}
}

export class UpdateFailAction implements Action {
	readonly type = UPDATE_FAIL;

	constructor(public payload: string) {}
}

export class SelectAction implements Action {
	readonly type = SELECT;

	constructor(public payload: number) {}
}

export class FetchPermissionsAction implements Action {
	readonly type = FETCH_PERMISSIONS;

	constructor(public payload?) {}
}

export class FetchPermissionsSuccessAction implements Action {
	readonly type = FETCH_PERMISSIONS_SUCCESS;

	constructor(public payload: IPermission[]) {}
}

export class FetchPermissionsFailAction implements Action {
	readonly type = FETCH_PERMISSIONS_FAIL;

	constructor(public payload: string) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
	| FetchAction
	| FetchSuccessAction
	| FetchFailAction
	| AddAction
	| AddSuccessAction
	| AddFailAction
	| UpdateAction
	| UpdateSuccessAction
	| UpdateFailAction
	| SelectAction
	| FetchPermissionsAction
	| FetchPermissionsSuccessAction
	| FetchPermissionsFailAction;
