/* jscpd:ignore-start */
import { Store } from "@ngrx/store";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY, ReplaySubject } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import {
	DestructionLotDetailQuery,
	InventoryDetailQuery,
} from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

@Component({
	selector: "inventory-destruction-by-id",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})

/*
	Inventory Destruction By Id
*/
export class InventoryDestructionByIdComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Inventory Destruction By Id";
	form_title_translation_key = marker("form_title_inventory_destruction_by_id");
	submit_button = "Create";
	submit_button_translation_key = marker("word_create");
	submit_icon = "plus";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	/* jscpd:ignore-start */
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			destruction_lot_id: {
				type: "number",
				minItems: 1,
				title: "Destruction Lot",
				title_translation_key: marker("word_destruction_lot"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "destruction_lots",
						pretext: "",
						text_key: ["name", "id"],
						queryString: {
							lot_status: "OPEN",
						},
					},
				],
			},
			inventory_ids: {
				type: "array",
				title: "Inventory ID",
				title_translation_key: marker("form_field_label_inventory_id"),
				widget: "data-select",
				quick_create: false,
				multi: true,
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventories",
							text_key: ["name", "id"],
							text_format: "?1 (#?2)",
							value_key: "id",
							queryString: {
								non_zero_inventory: "true",
								dont_show_expired: "false",
							},
						},
					],
				},
			},
			remaining_inventory: {
				title: "Use Remaining Inventory",
				title_translation_key: marker("form_field_label_use_remaining_inventory"),
				type: "boolean",
				widget: "checkbox",
				default: true,
			},
			quantity: {
				type: "number",
				title: `Quantity`,
				title_translation_key: marker("word_quantity"),
				visibleIf: {
					oneOf: [
						{
							allOf: [{ remaining_inventory: false }],
						},
					],
				},
				width: "50%",
			},
			inventory_unit_id: {
				title: "Inventory Unit",
				title_translation_key: marker("word_inventory_unit"),
				type: "number",
				widget: "data-select",
				quick_create: false,
				shorter_placeholder: true,
				related_properties: ["inventory_ids"],
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
				visibleIf: {
					oneOf: [
						{
							allOf: [{ remaining_inventory: false }],
						},
					],
				},
				width: "50%",
			},
			destruction_reason_id: {
				type: "number",
				title: "Destruction Reason",
				title_translation_key: marker("word_destruction_reason"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "destruction_reasons",
					},
				],
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
			timestamp: {
				type: "string",
				title: "Timestamp",
				title_translation_key: marker("word_timestamp"),
				widget: "date",
			},
		},
		required: ["destruction_lot_id", "inventory_ids", "destruction_reason_id"],
	};
	/* jscpd:ignore-end */

	validators = {};

	private result_type: "destruction_lot" | "inventory";
	private inventory_id: number;

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
		private readonly _translocoService: TranslocoService,
	) {}

	valid(valid) {
		this.valid$.next(valid);
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete (this.schema.properties as any).timestamp;
		}

		this.result_type = this._injector.get("result_type", "destruction_lot");
		const destruction_lot_id = this._injector.get("destruction_lot_id", null);
		this.inventory_id = this._injector.get("inventory_id", null);

		if (destruction_lot_id) {
			this.model.destruction_lot_id = destruction_lot_id;
			this.schema.properties.destruction_lot_id.readOnly = true;
		}

		if (this.inventory_id) {
			this.model.inventory_ids = [this.inventory_id];
			this.schema.properties.inventory_ids.readOnly = true;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		this.destroyRequest(this.model);
	}

	onChanges(_form_data): void {}

	destroyRequest(destruction_event: any) {
		let query = DestructionLotDetailQuery;
		if (this.result_type === "inventory") {
			query = InventoryDetailQuery;
		}

		const item = {
			...destruction_event,
			vendor_id: this.model.vendor_id,
			result_type: this.result_type,
		};

		if (
			!destruction_event.remaining_inventory &&
			(!destruction_event.quantity || destruction_event.quantity <= 0)
		) {
			this.error$.next(
				this._translocoService.translate(
					"error_inventory_destruction_must_include_positive_quantity",
				),
			);
			return;
		}

		this.loading$.next(true);
		this._itemService
			.add(`inventory/destroy`, item, query)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(50000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					if (this.result_type === "inventory") {
						this._store.dispatch(
							ItemActions.updateSuccess({
								updatedItem,
								result_type: "inventories",
							}),
						);
					} else {
						this._store.dispatch(
							SelectionActions.select({
								result_type: "destruction_lots",
								id: this.model.destruction_lot_id,
							}),
						);
					}

					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
/* jscpd:ignore-end */
