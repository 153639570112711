import { createAction, props } from "@ngrx/store";
import { ICustomFieldSchema } from "@elevatedsignals/amygoodman";

export const fetchCustomField = createAction(
	"[CustomField Component]",
	props<{ entity_id: number; entity: string }>(),
);

export const fetchCustomFieldSuccess = createAction(
	"[CustomField/API] Fetch Success",
	props<{
		schema: ICustomFieldSchema;
	}>(),
);

export const fetchCustomFieldFailure = createAction(
	"[CustomField/API] Fetch Failure",
	props<{
		error: any;
	}>(),
);
