/* eslint-disable @typescript-eslint/naming-convention */
import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { User } from "@elevatedsignals/amygoodman";

export const UserActions = createActionGroup({
	source: "User",
	events: {
		Fetch: emptyProps(),
		"Fetch success": props<{ payload: User[] }>(),
		"Fetch failed": props<{ payload: string }>(),
		Reinvite: props<{ payload: User }>(),
		"Reinvite Success": props<{ payload: User }>(),
		"Reinvite Fail": props<{ payload: string }>(),
		Update: props<{ payload: User }>(),
		"Update Success": props<{ payload: User }>(),
		"Update Fail": props<{ payload: string }>(),
	},
});
