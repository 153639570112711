import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, take, takeUntil, tap } from "rxjs/operators";
import { EMPTY, pipe } from "rxjs";
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { ISubstanceType } from "@elevatedsignals/amygoodman";
import { SubstanceTypeDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "substance-type-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
// @deprecated
export class SubstanceTypeUpdateComponent
	extends GenericUpdateComponent<ISubstanceType>
	implements OnInit, OnDestroy
{
	substance_type$ = this._store.select(fromDashboard.getSelectedSubstanceType);
	substance_type: ISubstanceType;

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
		},
		required: ["name"],
	};

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
	) {
		super(_store, _cd);
		this.form_title = "Update a Substance Type";
		this.form_title_translation_key = marker(
			"form_title_update_a_substance_type",
		);
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.substance_type$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((substance_type) => {
				this.substance_type = substance_type;
				this.model.id = this.substance_type.id;
				this.model.name = this.substance_type.name;
				this.model.archived = this.substance_type.archived;
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(substance: Partial<ISubstanceType>) {
		this.loading$.next(true);
		this._itemService
			.update(`substance_type`, substance.id!, substance, SubstanceTypeDetailQuery)
			.pipe(
				takeUntil(this.destroyed$),
				pipe(
					timeout(10000),
					catchError((error) => {
						this.error$.next(handleObservableError(error, true));
						this.loading$.next(false);
						return EMPTY;
					}),
				),
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "substance_types",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
