import { SimpleChange } from "@angular/core";

export const updateWhatChanged = (whatChanged, key: string, newValue: any) => {
	whatChanged[key] = new SimpleChange(
		whatChanged[key]?.currentValue,
		newValue,
		false,
	);
};

export const getDynamicFormChanges = (whatChanged, model, bySkuId = false) => {
	const idsToTrack = [
		"inventory_product_id",
		"inventory_ids",
		"batch_id",
		"lot_id",
		"timestamp",
		"vendor_id",
	];

	if (bySkuId) {
		idsToTrack.push("sku_id");
	}

	for (const id of idsToTrack) {
		updateWhatChanged(whatChanged, id, model[id]);
	}
};
