/* jscpd:ignore-start */
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY, ReplaySubject } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
	SimpleChanges,
	SimpleChange,
} from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { WorkOrderDestructionEventsQuery } from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";
import {
	fetchNewInventoryTotals,
	getDynamicFormChanges,
	hasInventoryIdsChanged,
	hasPropertyChanged,
	onVendorChange,
} from "../shared";

/* jscpd:ignore-end */

@Component({
	selector: "inventory-destruction-work-order",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class InventoryDestructionWorkOrderComponent
	extends GenericCreateComponent<any>
	implements OnInit, OnDestroy
{
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			work_order_id: {
				type: "number",
				minItems: 1,
				title: "Work Order Id",
				widget: "data-select",
				visible: false,
				oneOf: [
					{
						result_type: "work_orders",
					},
				],
				hidden: true,
			},
			destruction_lot_id: {
				type: "number",
				minItems: 1,
				title: "Destruction Lot",
				title_translation_key: marker("word_destruction_lot"),
				widget: "data-select",
				visible: false,
				oneOf: [
					{
						result_type: "destruction_lots",
						pretext: "",
						text_key: ["name", "id"],
						queryString: {
							lot_status: "OPEN",
						},
					},
				],
			},
			inventory_product_id: {
				title: "Product",
				title_translation_key: marker("word_product"),
				type: "number",
				widget: "data-select",
				quick_create: false,
				related_properties: ["non_inventory_destruction", "work_order_id"],
				oneOf: [
					{
						result_type: "inventory_products",
						queryString: {
							inventory_product_filter: "ACTIVE_ARCHIVED",
						},
					},
				],
			},
			sku_id: {
				type: "number",
				title: "SKU",
				title_translation_key: marker("word_sku"),
				widget: "data-select",
				quick_create: false,
				related_properties: ["inventory_product_id"],
				oneOf: [
					{
						result_type: "skus",
						text_key: ["sku"],
					},
				],
				hidden: true,
				visibleIf: {
					allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
				},
			},
			vendor_id: {
				type: "number",
				title: "Vendor",
				title_translation_key: marker("word_vendor"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "vendors",
					},
				],
				hidden: true,
				related_properties: ["inventory_product_id"],
				visibleIf: {
					allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
				},
			},
			batch_id: {
				type: "number",
				title: "Batch",
				title_translation_key: marker("word_batch"),
				widget: "data-select",
				related_properties: ["work_order_id"],
				oneOf: [
					{
						result_type: "batches",
						queryString: {
							dont_show_expired: "true",
							non_zero_inventory: "true",
						},
					},
				],
				visibleIf: {
					allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
				},
			},
			lot_id: {
				type: "number",
				title: "Inventory Lot",
				title_translation_key: marker("form_field_label_destroy_from_lot"),
				widget: "data-select",
				quick_create: false,
				related_properties: ["work_order_id"],
				oneOf: [
					{
						result_type: "lots",
						queryString: {
							input: "true",
						},
					},
				],
				visibleIf: {
					allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
				},
			},
			quantity: {
				type: "number",
				title: `Quantity`,
				title_translation_key: marker("word_quantity"),
				visibleIf: {
					oneOf: [
						{
							allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
						},
					],
				},
				width: "50%",
			},
			inventory_unit_id: {
				title: "Inventory Unit",
				title_translation_key: marker("word_inventory_unit"),
				type: "number",
				widget: "data-select",
				quick_create: false,
				shorter_placeholder: true,
				related_properties: ["inventory_product_id", "location_id", "batch_id"],
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
				visibleIf: {
					oneOf: [
						{
							allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
						},
					],
				},
				width: "50%",
			},
			destruction_reason_id: {
				type: "number",
				title: "Destruction Reason",
				title_translation_key: marker("word_destruction_reason"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "destruction_reasons",
					},
				],
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
			timestamp: {
				type: "string",
				title: "Timestamp",
				title_translation_key: marker("word_timestamp"),
				widget: "date",
			},
		},

		anyOf: [
			{
				required: [
					"destruction_lot_id",
					"inventory_product_id",
					"lot_id",
					"quantity",
					"inventory_unit_id",
					"destruction_reason_id",
					"vendor_id",
				],
			},
			{
				required: [
					"destruction_lot_id",
					"inventory_product_id",
					"batch_id",
					"quantity",
					"inventory_unit_id",
					"destruction_reason_id",
					"vendor_id",
				],
			},
		],
	};

	validators = {};

	private batch_id: number;
	private work_order_id: number;
	private vendor_id: number;
	private work_order_type_id: number;
	private destruction_lot_id: number;
	private readonly inventory_id: number;
	private is_duplicate: number;
	private requireBatch: boolean;

	private prevInventoryProductId: number | undefined;
	private readonly whatChanged: SimpleChanges = {};

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _injector: Injector,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store);
		this.form_title = "Inventory Destruction By Work Order";
		this.form_title_translation_key = marker(
			"form_title_inventory_destruction_work_order",
		);
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
		this.submit_icon = "plus";
	}

	ngOnInit() {
		this.work_order_id = this._injector.get("work_order_id", null);
		this.batch_id = this._injector.get("batch_id", null);

		this.work_order_type_id = this._injector.get("work_order_type_id", null);
		this.destruction_lot_id = this._injector.get("destruction_lot_id", null);

		const inventory_product_id = this._injector.get("inventory_product_id", null);
		const inventory_unit_id = this._injector.get("inventory_unit_id", null);
		const quantity = this._injector.get("quantity", null);
		const lot_id = this._injector.get("lot_id", null);
		const inventory = this._injector.get("inventory", null);
		this.vendor_id = this._injector.get("vendor_id", null);
		const destruction_reason_id = this._injector.get(
			"destruction_reason_id",
			null,
		);
		const wo_type = this._injector.get("work_order_type", null);

		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.is_duplicate = this._injector.get("is_duplicate", false);

		let changes = false;

		if (this.destruction_lot_id) {
			this.model.destruction_lot_id = this.destruction_lot_id;

			if (this.schema.properties.batch_id) {
				this.schema.properties.batch_id.readOnly = false;
				delete this.model.batch_id;
				changes = true;
			}
		}

		if (this.is_duplicate) {
			this.model.inventory_product_id = inventory_product_id;
			this.model.inventory_unit_id = inventory_unit_id;
			this.model.lot_id = lot_id;
			this.model.quantity = quantity;
			this.model.destruction_reason_id = destruction_reason_id;
			this.model.batch_id = this.batch_id;
			this.model.sku_id = inventory.sku?.id;
			this.model.inventory_ids = [inventory.id];

			if (inventory?.vendor_id) {
				this.model.vendor_id = inventory.vendor_id;
				this.schema.properties.vendor_id.hidden = false;
				this.vendor_id = inventory.vendor_id;
			}

			changes = true;
		}

		if (wo_type && wo_type.require_lot_for_destruction) {
			this.schema.anyOf[1].required.push("lot_id");

			changes = true;
		}

		if (this.work_order_id) {
			this.schema.properties.inventory_product_id = {
				...this.schema.properties.inventory_product_id,
				info_text: this._translocoService.translate(
					"inventory_destruction_not_seeing_products_expected_check_work_order_type_settings",
				),
			};
			this.schema.properties.batch_id = {
				...this.schema.properties.batch_id,
				oneOf: [
					{
						result_type: "batches",
						queryString: { work_order_id: this.work_order_id },
					},
				],
			};

			this.schema.properties.inventory_product_id.oneOf[0].queryString = {
				inventory_product_filter: "ACTIVE_ARCHIVED",
				destruction_inventory_products_for_work_order_type_id:
					this.work_order_type_id,
				filter_by_work_order_id_inputs: this.work_order_id,
				filter_by_work_order_id_outputs: this.work_order_id,
			};

			this.model.work_order_id = this.work_order_id;
		}

		if (changes) {
			this._cd.detectChanges();
		}
	}

	valid(valid) {
		this.valid$.next(valid);

		if (this.requireBatch && !this.model.batch_id) {
			this.valid$.next(false);
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		getDynamicFormChanges(this.whatChanged, model, true);
		if (!this.is_duplicate) {
			this.autoSelectForm();
		}
		this.checkAndUpdateInventoryTotals();

		if (!model.inventory_product_id && this.prevInventoryProductId) {
			this.prevInventoryProductId = undefined;
			model.inventory_unit_id = undefined;
		} else if (
			model.inventory_product_id &&
			this.prevInventoryProductId !== model.inventory_product_id
		) {
			this.prevInventoryProductId = model.inventory_product_id;
			model.inventory_unit_id = undefined;
			const firstProductSet = !this.model.inventory_product_id;
			if (this.schema.properties.existing_inventory && !this.inventory_id) {
				model.existing_inventory =
					!this._globals.cultivationInventoryProducts.includes(
						model.inventory_product_id,
					);
			}
			this._itemService
				.fetchItem(`inventory_product`, `${model.inventory_product_id}`, {
					eager: "[vendors]",
				})
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((inventory_product) => {
					if (!firstProductSet || !this.model.inventory_unit_id) {
						this.model.inventory_unit_id = inventory_product.display_unit_id;
					}

					this.schema.properties.sku_id.hidden = !inventory_product.require_sku;

					if (inventory_product.require_batch) {
						this.requireBatch = true;
						this.schema.properties.batch_id.hidden = false;
					} else {
						this.requireBatch = false;
						this.schema.properties.batch_id.hidden = true;
					}

					if (!this.vendor_id) {
						[this.model, this.schema] = onVendorChange(
							this.model,
							this.schema,
							inventory_product,
						);
					}

					this._cd.detectChanges();
				});
		}
	}

	autoSelectForm = (): void => {
		let requiresDetectChanges = false;
		if (this.itemChanged(this.whatChanged.batch_id)) {
			requiresDetectChanges = this.batchChanged();
		} else if (this.itemChanged(this.whatChanged.lot_id)) {
			requiresDetectChanges = this.lotChanged();
		}

		if (requiresDetectChanges) {
			this._cd.detectChanges();
		}
	};

	itemChanged = (change: SimpleChange | undefined): boolean => {
		if (change?.currentValue !== change?.previousValue) {
			return true;
		}
		return false;
	};

	batchChanged = () => {
		if (this.whatChanged.batch_id!.currentValue) {
			// Here we should be setting inventory_ids to [], lot_id to null. But, infinite loops.
		}

		return false;
	};

	lotChanged = () => {
		if (this.whatChanged.lot_id!.currentValue) {
			// Here we should be setting inventory_ids to []. But, infinite loops.
			return true;
		}
		return false;
	};

	checkAndUpdateInventoryTotals = () => {
		if (
			hasPropertyChanged(this.whatChanged.inventory_product_id) ||
			hasPropertyChanged(this.whatChanged.lot_id) ||
			hasPropertyChanged(this.whatChanged.batch_id) ||
			hasInventoryIdsChanged(this.whatChanged.inventory_ids) ||
			hasPropertyChanged(this.whatChanged.timestamp) ||
			hasPropertyChanged(this.whatChanged.vendor_id) ||
			hasPropertyChanged(this.whatChanged.sku_id)
		) {
			fetchNewInventoryTotals(this.whatChanged, this._itemService).subscribe(
				(availableInventoryAmount) => {
					this.model.amount_available = availableInventoryAmount.content
						.map((availableInventory) => {
							return `${availableInventory.sum.toFixed(2)} ${availableInventory.name}`;
						})
						.join("\n");

					this._cd.detectChanges();
				},
			);
		}
	};

	createItem(destruction_event: any) {
		const item = {
			...destruction_event,
			vendor_id: this.model.vendor_id,
			result_type: "work_order",
			existing_inventory: true,
			remaining_inventory: false,
			...(this.work_order_id && { work_order_id: this.work_order_id }),
		};

		if (!destruction_event.quantity || destruction_event.quantity <= 0) {
			this.error$.next(
				this._translocoService.translate(
					"error_inventory_destruction_must_include_positive_quantity",
				),
			);
			return;
		}

		this.loading$.next(true);
		this._itemService
			.add(
				`destruction_lots/${destruction_event.destruction_lot_id}/inventory`,
				item,
				WorkOrderDestructionEventsQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(50000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((item) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem: item,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
