import { UserProfile, Role } from "@elevatedsignals/amygoodman";

import { APP_PERMISSIONS as PERMISSIONS } from "./permissions.constants";

export abstract class Permissions {
	static hasPermission = (user?: UserProfile, name?: string) => {
		if (user?.permissions && name) {
			if (user.permissions[name] === true) {
				return true;
			} else if (user.permissions[name] === false) {
				return false;
			}
		}

		if (!user?.roles) {
			return false;
		}

		const roles: Role[] = user.roles;

		for (const role of roles) {
			const permissions = role.permissions;
			for (const permission of permissions) {
				if (permission.name === name) {
					return true;
				}
			}
		}

		// Permission not found
		return false;
	};

	static canAddLocations = (user: UserProfile) => {
		return Permissions.hasPermission(user, PERMISSIONS.LOCATIONS_VIEW);
	};

	static canViewLocations = (user: UserProfile) => {
		return Permissions.hasPermission(user, PERMISSIONS.LOCATIONS_VIEW);
	};
}
