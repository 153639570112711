<div class="ui grid dirty-mackenzie" *transloco="let t">
	<div class="sixteen wide column">
		<div class="left floated left aligned eight wide column">
			<button class="ui labeled icon button" (click)="closeSidenav()">
				<i class="left arrow icon"></i>
				{{ "word_cancel" | transloco }}
			</button>
		</div>
	</div>
	<div class="sixteen wide column">
		<h2>
			{{
				form_title_translation_key
					? t(form_title_translation_key, form_title_translation_params)
					: form_title
			}}
		</h2>
	</div>

	<div class="sixteen wide column">
		<sf-form
			[schema]="schema"
			[model]="model"
			(onChange)="onChanges($event.value); model = $event.value"
			[validators]="validators"
			(isValid)="valid($event)"
			[bindings]="fieldBindings"
		></sf-form>
		<sf-form
			*ngIf="secondarySchema"
			[schema]="secondarySchema"
			[model]="secondaryModel"
			(onChange)="secondaryModel = $event.value"
			(isValid)="secondaryValid($event)"
		></sf-form>
		<sf-form
			*ngIf="statusSchema"
			[schema]="statusSchema"
			[model]="statusModel"
			(onChange)="statusModel = $event.value"
		></sf-form>
		<span *ngIf="schema.warning" class="warningMessage">
			{{
				schema.warning_translation_key
					? t(schema.warning_translation_key)
					: schema.warning
			}}
		</span>
	</div>
	<br />

	<div class="sixteen wide column" *ngIf="error$ | async">
		<div class="ui error message">
			<span>{{ error$ | async }}</span>
		</div>
	</div>

	<div class="sixteen wide column" *ngIf="submit_button !== undefined">
		<submit-button
			[loading]="loading$ | async"
			[valid]="(valid$ | async) && (!secondarySchema || (secondaryValid$ | async))"
			[submit_icon]="submit_icon"
			[submit_button]="submit_button"
			[submit_button_translation_key]="submit_button_translation_key"
			(onSubmit)="onSubmit()"
		/>
	</div>
</div>
