import { tap, timeout, catchError, take, takeUntil } from "rxjs/operators";
import { ChangeDetectorRef, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { getTotalWeight, handleObservableError } from "app/shared/utils";
import { ReplaySubject, EMPTY } from "rxjs";
import {
	Batch,
	ISelectListItem,
	SubstanceTypes,
} from "@elevatedsignals/amygoodman";
import { Globals } from "app/shared/modules/globals/globals.service";
import { BatchDetailQuery } from "app/shared/eagers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { UnitToGramsPipe } from "app/modules/dashboard/modules/es-pipes/unit-to-grams.pipe";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

enum AdjustmentType {
	NewWeight = "NEW",
	Addition = "ADD",
	Subtraction = "SUBTRACT",
}

@Component({
	selector: "batch-adjustment",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
// @deprecated
export class BatchAdjustmentComponent extends GenericUpdateComponent<Batch> {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	batch$ = this._store.select(fromDashboard.getSelectedBatch);
	batch: Batch;

	form_title = "Adjust Batch Weight";
	form_title_translation_key: string = marker("form_title_adjust_batch_weight");
	submit_button = "Add Adjustment";
	submit_button_translation_key: string = marker("form_button_add_adjustment");
	submit_icon = "plus";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};
	adjustment_reasons: { name: string; name_translation_key?: string }[] = [
		{ name: "Drying Loss", name_translation_key: marker("word_drying_loss") },
		{
			name: "Lost/Theft",
			name_translation_key: marker("form_field_value_lost_theft"),
		},
		{
			name: "Lab Test",
			name_translation_key: marker("form_field_value_lab_test"),
		},
		{
			name: "Processing Loss",
			name_translation_key: marker("word_processing_loss"),
		},
		{ name: "Other", name_translation_key: marker("word_other") },
	];

	adjustment_types: ISelectListItem[] = [
		{
			name: "New Batch Weight",
			name_translation_key: marker("form_field_value_new_batch_weight"),
			value: AdjustmentType.NewWeight,
			enum: [AdjustmentType.NewWeight],
		},
		{
			name: "Addition",
			name_translation_key: marker("word_addition"),
			value: AdjustmentType.Addition,
			enum: [AdjustmentType.Addition],
		},
		{
			name: "Subtraction",
			name_translation_key: marker("word_subtraction"),
			value: AdjustmentType.Subtraction,
			enum: [AdjustmentType.Subtraction],
		},
	];

	validators = {};

	weight_unit: string = this._globals.weight_unit;

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			batch_id: {
				type: "number",
				hidden: true,
			},
			adjustment_type: {
				type: "string",
				title: "Adjustment Type",
				title_translation_key: marker("form_field_label_adjustment_type"),
				widget: "select",
				oneOf: this.adjustment_types,
			},
			weight: {
				widget: "number",
				type: "number",
				step: "0.00001",
				title: `New Batch Weight(${this.weight_unit})`,
				title_translation_key: marker("form_field_label_new_batch_weight"),
				title_translation_params: { weight_unit: this.weight_unit },
				visibleIf: {
					adjustment_type: [AdjustmentType.NewWeight],
				},
			},
			adjusted_weight: {
				widget: "number",
				type: "number",
				step: "0.00001",
				title: `Weight Adjustment(${this.weight_unit})`,
				title_translation_key: marker("form_field_label_weight_adjustment"),
				title_translation_params: { weight_unit: this.weight_unit },
				visibleIf: {
					adjustment_type: [AdjustmentType.Addition, AdjustmentType.Subtraction],
				},
			},
			reason: {
				type: "string",
				title: "Reason",
				title_translation_key: marker("word_reason"),
				widget: "select",
				oneOf: this.adjustment_reasons.map((item) => {
					return {
						name: item.name,
						name_translation_key: item.name_translation_key,
						value: item.name,
						enum: [item.name],
					};
				}),
			},
			reason_other: {
				type: "string",
				title: "Other Reason",
				title_translation_key: marker("form_field_label_other_reason"),
				visibleIf: {
					reason: ["Other"],
				},
				default: "",
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
			timestamp: {
				type: "string",
				title: "Adjustment Date",
				title_translation_key: marker("form_field_label_adjustment_date"),
				widget: "date",
			},
		},
		required: ["batch_id", "reason", "adjustment_type"],
	};

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		protected _cd: ChangeDetectorRef,
		private readonly _unitToGrams: UnitToGramsPipe,
		private readonly _globals: Globals,
	) {
		super(_store, _cd);
	}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		this.batch$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((batch) => {
				this.batch = batch;
				this.model = {
					batch_id: this.batch.id,
				};
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		let weight = getTotalWeight(this.batch, SubstanceTypes.DriedCannabis);
		switch (this.model.adjustment_type) {
			case AdjustmentType.NewWeight:
				weight = this._unitToGrams.transform(this.model.weight, this.weight_unit);
				break;
			case AdjustmentType.Addition:
				weight += this._unitToGrams.transform(
					this.model.adjusted_weight,
					this.weight_unit,
				);
				break;
			case AdjustmentType.Subtraction:
				weight -= this._unitToGrams.transform(
					this.model.adjusted_weight,
					this.weight_unit,
				);
				break;
			default:
				break;
		}
		const adjustment = {
			batch_id: this.model.batch_id,
			weight,
			reason:
				this.model.reason === "Other"
					? this.model.reason_other || this.model.reason
					: this.model.reason,
			description: this.model.description,
			timestamp: this.model.timestamp,
		};
		this.updateItem(adjustment);
	}

	updateItem(update_batch: any) {
		this.loading$.next(true);
		this._itemService
			.update(
				`batch`,
				`${this.batch.id}/adjustment/add`,
				update_batch,
				BatchDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "batches",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
