import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import {
	timeout,
	catchError,
	take,
	takeUntil,
	filter,
	tap,
} from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import { IApplicableProduct } from "@elevatedsignals/amygoodman";
import { ApplicableProductDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

@Component({
	selector: "applicable-product-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class ApplicableProductUpdateComponent
	extends GenericUpdateComponent<IApplicableProduct>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: Applicable Product name already in use.",
				warning_translation_key: marker(
					"form_field_warning_applicable_product_name_already_in_use",
				),
			},
			product_type: {
				type: "string",
				title: "Applicable Product Type",
				title_translation_key: marker("word_applicable_product_type"),
				widget: "select",
				// readOnly: true,
				oneOf: [
					{
						value: "BIO_CONTROL",
						enum: ["BIO_CONTROL"],
						name_translation_key: marker("word_bio_control"),
						name: "Bio Control",
					},
					{
						value: "PEST_CONTROL",
						enum: ["PEST_CONTROL"],
						name_translation_key: marker("word_pest_control"),
						name: "Pest Control",
					},
					{
						value: "SANITATION_PRODUCT",
						enum: ["SANITATION_PRODUCT"],
						name_translation_key: marker("word_sanitation_product"),
						name: "Sanitation Product",
					},
					{
						value: "NUTRIENTS",
						enum: ["NUTRIENTS"],
						name_translation_key: marker("word_nutrients"),
						name: "Nutrients",
					},
				],
			},
			application_method_ids: {
				type: "array",
				title: "Application Method",
				title_translation_key: marker("word_application_method"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "application_methods",
							text_key: ["name", "id"],
							text_format: "?1 (#?2)",
						},
					],
				},
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
		},
		required: ["name", "product_type"],
	};

	applicable_product$ = this._store.select(
		fromDashboard.getSelectedApplicableProduct,
	);

	applicable_product: IApplicableProduct;
	id: number;

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private names;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.form_title = "Update an Applicable Product";
		this.form_title_translation_key = marker(
			"form_title_update_an_applicable_product",
		);
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
		this.id = this._injector.get("id", null);
	}

	ngOnInit() {
		this.applicable_product$
			.pipe(
				takeUntil(this.destroyed$),
				isNotNullOrUndefined(),
				filter(
					(applicable_product): applicable_product is IApplicableProduct =>
						applicable_product.id === this.id,
				),
				take(1),
			)
			.subscribe((applicable_product) => {
				this.applicable_product = applicable_product;
				this.model.id = this.applicable_product.id;
				this.model.name = this.applicable_product.name;
				this.model.product_type = this.applicable_product.product_type;
				this.model.archived = this.applicable_product.archived;
				this.model.application_method_ids =
					this.applicable_product.application_methods?.map((method) => method.id);
				this._cd.detectChanges();
				this.getNames();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	// Used for schema validation - duplicate Work Order Type name check
	getNames() {
		this.loading$.next(true);
		this._itemService
			.fetchItem(`applicable_products/fetch/names`, "", {
				col: "name",
				except: this.applicable_product.name,
			})
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	updateItem(product: Partial<IApplicableProduct>) {
		this.loading$.next(true);
		this._itemService
			.update(
				`applicable_product`,
				product.id!,
				product,
				ApplicableProductDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "applicable_products",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
