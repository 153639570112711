/* eslint-disable @typescript-eslint/naming-convention */
import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const PrinterActions = createActionGroup({
	source: "Printer",
	events: {
		"fetch count": props<{ facilityId: number }>(),
		"fetch count success": props<{ count: number }>(),
		"fetch count fail": emptyProps(),
	},
});
