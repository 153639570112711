import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, take, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { ILot } from "@elevatedsignals/amygoodman";
import { LotDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "lot-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class LotUpdateComponent
	extends GenericUpdateComponent<ILot>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
			},
			lot_expires: {
				type: "boolean",
				widget: "checkbox",
				title: "Lot Expires?",
				title_translation_key: marker("form_field_label_lot_expires"),
				default: false,
			},
			expires: {
				type: "string",
				title: "Expiry Date",
				title_translation_key: marker("form_field_label_expiry_date"),
				widget: "date",
				visibleIf: {
					lot_expires: [true],
				},
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
		},
		required: ["name"],
	};

	lot$ = this._store.select(fromDashboard.getSelectedLot);
	lot: ILot;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
	) {
		super(_store, _cd);
		this.form_title = "Update Lot";
		this.form_title_translation_key = marker("form_title_update_lot");
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.lot$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((lot) => {
				this.lot = lot;
				this.model.id = this.lot.id;
				this.model.name = this.lot.name;
				this.model.description = this.lot.description;
				this.model.archived = this.lot.archived;
				if (this.lot.expires) {
					this.model.lot_expires = true;
					this.model.expires = this.lot.expires;
				}
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(lot: Partial<ILot>) {
		const updated_lot: Partial<ILot> = {
			id: lot.id,
			name: lot.name || undefined,
			archived: lot.archived || false,
			expires: lot.expires || undefined,
			description: lot.description || undefined,
		};

		this.loading$.next(true);
		this._itemService
			.update(`lot`, Number(lot.id), updated_lot, LotDetailQuery)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "lots",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
