import { HeaderQueryType, ITag, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "tags";

export type State = Page<ITag>;

export const reducer = createPagingReducer<ITag>(reducerKey, {
	result_type: "tags",
	title: "Tag",
	title_translation_key: marker("page_title_tags"),
	title_plural: "Tags",
	title_plural_translation_key: marker("page_title_plural_tags"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Show system tags",
			key: "systemTags",
			title_translation_key: marker("checkbox_filter_show_system_tags"),
			type: HeaderQueryType.checkbox,
			value: false,
		},
	],
});
