import { takeUntil, tap, timeout, catchError } from "rxjs/operators";
import {
	Component,
	OnInit,
	OnDestroy,
	Injector,
	ChangeDetectorRef,
} from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { Store } from "@ngrx/store";
import { ReplaySubject, EMPTY } from "rxjs";
import { Sensor } from "@elevatedsignals/amygoodman";
import { SensorPageQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

@Component({
	selector: "sensor-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class SensorCreateComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Add a Sensor";
	form_title_translation_key: string = marker("form_title_add_a_sensor");
	submit_button = "Add Sensor";
	submit_button_translation_key: string = marker("form_button_add_sensor");
	submit_icon = "plus";

	updating = false;
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			capabilities: {
				type: "array",
				title: "Capabilities",
				title_translation_key: marker("word_capabilities"),
				widget: "data-select",
				items: {
					type: "string",
					oneOf: [
						{
							result_type: "capabilities",
							value_key: "capability",
						},
					],
				},
			},
			attached_type: {
				type: "string",
				title: "Attached To",
				title_translation_key: marker("form_field_label_attached_to"),
				widget: "select",
				labelField: "title",
				oneOf: [
					{
						name: "Location",
						name_translation_key: marker("word_location"),
						value: "LOCATION",
						enum: ["LOCATION"],
					},
					{
						name: "Batch",
						name_translation_key: marker("word_batch"),
						value: "BATCH",
						enum: ["BATCH"],
					},
				],
			},
			location_id: {
				type: "number",
				title: "Location",
				title_translation_key: marker("word_location"),
				widget: "data-select",
				oneOf: [{ result_type: "locations" }],
				visibleIf: {
					attached_type: ["LOCATION"],
				},
			},
			batch_id: {
				type: "number",
				title: "Batches",
				title_translation_key: marker("word_batches"),
				widget: "data-select",
				oneOf: [{ result_type: "batches" }],
				visibleIf: {
					attached_type: ["BATCH"],
				},
			},
			manufacturer: {
				type: "string",
				title: "Manufacturer",
				title_translation_key: marker("word_manufacturer"),
				widget: "select",
				labelField: "title",
				oneOf: [
					{
						name: "Monnit",
						value: "monnit",
						enum: ["monnit"],
					},
					{
						name: "Generic",
						name_translation_key: marker("word_generic"),
						value: "generic",
						enum: ["generic"],
					},
				],
			},
			sensor_id_and_check_didgit: {
				type: "string",
				title: "Sensor ID : Check Didgit",
				title_translation_key: marker("form_field_label_sensor_id_check_didget"),
				visibleIf: {
					manufacturer: ["monnit"],
				},
			},
			hub_uuid: {
				type: "string",
				title: "Sensor Hub",
				title_translation_key: marker("form_field_label_sensor_hub"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "hubs",
						value_key: "uuid",
					},
				],
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
		},
		required: ["attached_type", "name", "capabilities"],
	};

	validators = {};

	// Injected from location details new plant button
	private location_id: number;
	private batch_id: number;
	private hub_uuid: string;

	constructor(
		private readonly _injector: Injector,
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
	) {}

	valid(v) {
		this.valid$.next(v);
	}

	ngOnInit() {
		this.location_id = this._injector.get("location_id", null);
		this.batch_id = this._injector.get("batch_id", null);
		this.hub_uuid = this._injector.get("hub_uuid", null);

		let attachedType: string | undefined;

		if (this.location_id) {
			attachedType = "LOCATION";
		} else if (this.batch_id) {
			attachedType = "BATCH";
		}

		this.model = {
			...this.model,
			hub_uuid: this.hub_uuid,
			attached_type: attachedType,
			location_id: this.location_id ? this.location_id : undefined,
			batch_id: this.batch_id ? this.batch_id : undefined,
		};

		if (this.location_id || this.batch_id) {
			this._cd.detectChanges();
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const sensor: Partial<Sensor> = {
			...this.model,
		};
		this.addSensor(sensor);
	}

	onChanges(_model): void {}

	addSensor(add_sensor: Partial<Sensor>) {
		this.loading$.next(true);
		this._itemService
			.add(`sensors`, add_sensor, SensorPageQuery)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							addedItem,
							result_type: "sensors",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
