/* jscpd:ignore-start */
import { marker } from "@jsverse/transloco-keys-manager/marker";

export const getPlantsDestructionReweighSchema = (weight_unit: string) => ({
	title: "",
	description: "",
	info: "",
	properties: {
		group: {
			type: "string",
			hidden: true,
		},

		weight: {
			readOnly: true,
			type: "number",
			title: `Weight (${weight_unit})`,
			title_translation_key: marker("form_field_label_weight"),
			title_translation_params: { weight_unit },
		},
		reweigh_value: {
			type: "number",
			title: `Reweigh Weight (${weight_unit})`,
			title_translation_key: marker("form_field_label_reweigh_weight"),
			title_translation_params: { weight_unit },
		},

		timestamp: {
			type: "string",
			title: "Timestamp",
			title_translation_key: marker("word_timestamp"),
			widget: "date",
		},
	},
	required: ["group", "reweigh_value"],
});
/* jscpd:ignore-end */
