import {
	ChangeDetectorRef,
	Component,
	Injector,
	OnDestroy,
	OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { handleObservableError } from "app/shared/utils";
import { EMPTY, ReplaySubject } from "rxjs";
import { catchError, timeout, tap, take, takeUntil } from "rxjs/operators";
import { ISchema } from "@elevatedsignals/es-ngx-schema-form";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import {
	ItemKey,
	ItemKeyType,
} from "app/modules/dashboard/reducers/selection/keys";

@Component({
	selector: "tag-input",
	templateUrl: "form-view.component.html",
	styleUrls: ["sidenav.scss"],
})
export class TagInputComponent implements OnDestroy, OnInit {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();
	form_title = "Add a new tag";
	form_title_translation_key: string = marker("form_title_add_a_new_tag");
	submit_button = "Add Tag";
	submit_button_translation_key: string = marker("form_button_add_tag");
	submit_icon = "plus";

	schema: ISchema = {
		title: "",
		description: "",
		info: "",
		properties: {
			tag_id: {
				type: "number",
				title: "Tag",
				title_translation_key: marker("word_tag"),
				widget: "data-select",
				oneOf: [{ result_type: "tags" }],
				readOnly: false,
			},
			reference_type: {
				type: "string",
				hidden: true,
			},
			reference_id: {
				type: "string",
				hidden: true,
			},
		},
		required: ["tag_id", "reference_id", "reference_type"],
	};

	model: any = {
		reference_id: null,
		reference_type: null,
		tag_id: null,
	};

	selector?: any;

	private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(0);

	constructor(
		private readonly _injector: Injector,
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
	) {}

	ngOnInit() {
		this.model.reference_id = this._injector.get("reference_id", null);
		this.model.reference_type = this._injector.get("reference_type", null);
		this.selector = this._injector.get("selector", null);
		this._cd.detectChanges();
	}

	valid(valid) {
		this.valid$.next(valid);
	}

	onErrors(_error): void {
		// console.log("on error", e);
	}

	// tslint:disable-next-line:no-empty
	onChanges(change): void {}

	ngOnDestroy() {
		this.valid$.complete();
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		this._itemService
			.add(`${this.model.reference_type}/${this.model.reference_id}/tag`, {
				id: this.model.tag_id,
			})
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err, true));
					return EMPTY;
				}),
			)
			.pipe(
				tap((t) => {
					const item$ = this._store.select(this.selector);
					item$.pipe(takeUntil(this.destroyed$), take(1)).subscribe((item) => {
						const withNewTag = {
							...item,
							tags: item.tags.concat(t.tag),
						};
						this._store.dispatch(
							ItemActions.updateSuccess({
								updatedItem: withNewTag,
								result_type: this.mapKeys(this.model.reference_type),
							}),
						);
					});
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}

	private mapKeys(singular: string) {
		const keys: Record<string, ItemKeyType> = {
			equipment: ItemKey.Equipments,
			status: ItemKey.Statuses,
			vendor: ItemKey.Vendors,
			vendor_category: ItemKey.VendorsCategories,
			sensor: ItemKey.Sensors,
			plant: ItemKey.Plants,
			inventory_product: ItemKey.InventoryProducts,
			inventory_category: ItemKey.InventoryCategories,
			inventory: ItemKey.Inventories,
			document_set: ItemKey.DocumentSets,
			cultivar: ItemKey.Cultivars,
			batch: ItemKey.Batches,
		};

		return keys[singular]!;
	}
}
