import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	OnDestroy,
	ChangeDetectorRef,
	Injector,
	OnInit,
} from "@angular/core";
import { IPurchaseOrderPlants } from "@elevatedsignals/amygoodman";
import { PurchaseOrderDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { Globals } from "app/shared/modules/globals/globals.service";
import { ESValidator } from "app/shared/es-validator";
import { dateIsBefore } from "app/shared/time-format";
import { DevCycleService } from "app/devcycle/devcycle.service";
import { DevCycleKey } from "app/devcycle/devcycleKeys";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

import { schema } from "./create-plant.schema";

@Component({
	selector: "purchase-order-plants-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PurchaseOrderPlantsCreateComponent
	extends GenericCreateComponent<IPurchaseOrderPlants>
	implements OnDestroy, OnInit
{
	schema: any = { ...schema };

	validators: Record<string, ESValidator> = {
		"/timestamp": (value, property, form) => {
			this.dateValidatorFailed = false;

			const isValueBefore = dateIsBefore(new Date(value), new Date(this.minDate));
			if (isValueBefore) {
				this.dateValidatorFailed = true;

				const error = {
					code: "INVALID_DATE",
					path: `#${property.path}`,
					message: "The date must be in the past",
					params: ["timestamp"],
				};
				return [error];
			}

			return null;
		},
	};

	private purchase_order_id: number;
	private is_outgoing: boolean;
	private minDate: Date;
	private cultivar_id: number;
	private growth_stage_id: number;
	private destination_batch_id: number;
	private number_of_plants: number;
	private lot_id: number;
	private name: string;

	private dateValidatorFailed: boolean;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _injector: Injector,
		private readonly _globals: Globals,
		private readonly _devCycleService: DevCycleService,
	) {
		super(_store);
		this.form_title = "Add Plants to a Purchase Order";
		this.form_title_translation_key = marker(
			"form_title_add_plants_to_a_purchase_order",
		);
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
	}

	valid(v) {
		this.valid$.next(v);

		if (this.dateValidatorFailed) {
			this.valid$.next(false);
		}
	}

	ngOnInit() {
		this.purchase_order_id = this._injector.get("purchase_order_id", undefined);
		this.is_outgoing = this._injector.get("is_outgoing", false);
		this.minDate = this._injector.get("minDate", null);
		this.cultivar_id = this._injector.get("cultivar_id", null);
		this.growth_stage_id = this._injector.get("growth_stage_id", null);
		this.destination_batch_id = this._injector.get("destination_batch_id", false);
		this.number_of_plants = this._injector.get("number_of_plants", null);
		this.lot_id = this._injector.get("lot_id", null);
		this.name = this._injector.get("name", null);

		this._devCycleService
			.getVariable(DevCycleKey.ShowPlantLots, false)
			.subscribe((variable: any) => {
				if (!variable.value) {
					delete this.schema.properties.lot_id;
				} else if (this.lot_id) {
					this.model.lot_id = this.lot_id;
				}

				if (this._globals.gmp_enabled) {
					delete this.schema.properties.timestamp;
				}

				if (this.purchase_order_id) {
					this.schema.properties.purchase_order_id = {
						...this.schema.properties.purchase_order_id,
						default: this.purchase_order_id,
						readOnly: true,
					};
					this.model.purchase_order_id = this.purchase_order_id;
				}

				if (this.schema.properties.timestamp && this.is_outgoing) {
					this.schema.properties.timestamp = {
						...this.schema.properties.timestamp,
						warning: "The date must be after Shipping Order Date.",
						warning_translation_key: marker(
							"form_field_warning_the_date_must_be_after_shipping_order_date",
						),
					};
				}
			});

		if (this.cultivar_id) {
			this.model.cultivar_id = this.cultivar_id;
		}
		if (this.growth_stage_id) {
			this.model.growth_stage_id = this.growth_stage_id;
		}
		if (this.destination_batch_id) {
			this.model.destination_batch_id = this.destination_batch_id;
		}
		if (this.number_of_plants) {
			this.model.number_of_plants = this.number_of_plants;
		}

		if (this.name) {
			this.model.name = this.name;
		}

		if (this.is_outgoing) {
			this.model.is_outgoing = true;
		}

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	createItem(inventory) {
		this.loading$.next(true);
		this._itemService
			.add(
				`purchase_order/${this.purchase_order_id}/plants`,
				inventory,
				PurchaseOrderDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "purchase_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
