/* jscpd:ignore-start */
import {
	tap,
	take,
	takeUntil,
	timeout,
	catchError,
	skipWhile,
} from "rxjs/operators";
import { Component, OnInit, OnDestroy, Injector, signal } from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { Store } from "@ngrx/store";
import { ReplaySubject, EMPTY, BehaviorSubject, Observable } from "rxjs";
import { IDestructionLot, UserProfile } from "@elevatedsignals/amygoodman";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { UnitToGramsPipe } from "app/modules/dashboard/modules/es-pipes/unit-to-grams.pipe";
import { GramsToUnitPipe } from "app/modules/dashboard/modules/es-pipes/grams-to-unit.pipe";
import { PasswordInputComponent } from "app/modules/auth/components";
import { TranslocoService } from "@jsverse/transloco";
import {
	generalInventoryEnabled,
	gmpEnabled as gmpSelector,
} from "app/modules/dashboard/selectors/facility-settings.selector";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { dateIsBefore } from "app/shared/time-format";
/* jscpd:ignore-end */

@Component({
	selector: "destruction-lot-close",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class DestructionLotCloseComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();
	user$: Observable<UserProfile | null> = this._store.select(
		fromDashboard.getProfile,
	);

	user: UserProfile;

	destruction_lot$ = this._store.select(fromDashboard.getSelectedDestructionLot);
	destruction_lot = signal<IDestructionLot | null>(null);

	form_title = "Close a Destruction Lot";
	form_title_translation_key: string = marker(
		"form_title_close_a_destruction_lot",
	);

	submit_button = "Close Lot";
	submit_button_translation_key: string = marker("form_button_close_lot");
	submit_icon = "edit";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	weight_unit: BehaviorSubject<string> = this._globals.weight_unit_replay;

	giEnabled = this._store.selectSignal(generalInventoryEnabled);
	gmpEnabled = this._store.selectSignal(gmpSelector);

	model: any = {};

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {},
		required: [],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/timestamp": (value, property, form) => {
			this.dateValidatorFailed = false;

			const isValueBefore = dateIsBefore(new Date(value), new Date(this.minDate));
			if (isValueBefore) {
				this.dateValidatorFailed = true;

				const error = {
					code: "INVALID_DATE",
					path: `#${property.path}`,
					message: "The date must be in the past",
					params: ["timestamp"],
				};
				return [error];
			}

			return null;
		},
	};

	product_weights = signal<any | null>(null);
	showFinalWeights;

	private readonly minDate: Date;
	private dateValidatorFailed: boolean;

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _unitToGrams: UnitToGramsPipe,
		private readonly _gramsToUnits: GramsToUnitPipe,
		private readonly _injector: Injector,
		private readonly _translocoService: TranslocoService,
	) {
		this.minDate = this._injector.get("minDate", null);
	}

	valid(val) {
		this.valid$.next(!val);

		if (this.dateValidatorFailed) {
			this.valid$.next(false);
		}
	}

	ngOnInit() {
		if (this.gmpEnabled()) {
			delete (this.schema.properties as any).timestamp;
		}

		this.showFinalWeights = this._injector.get("showFinalWeights", null);

		this.user$
			.pipe(
				takeUntil(this.destroyed$),
				skipWhile((user) => user === null),
			)
			.subscribe((user) => {
				this.user = user!;
			});

		this.destruction_lot$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((destruction_lot: IDestructionLot) => {
				this.destruction_lot.set(destruction_lot);
				this.model = destruction_lot;
				this.product_weights.set(destruction_lot.data?.product_weights);
			});

		if (
			this.showFinalWeights &&
			this.product_weights() &&
			this.product_weights().length > 0
		) {
			// Have to manually override the schema.description with the
			// translation in order for them to correctly translate
			if (this.giEnabled()) {
				this.schema.description = this._translocoService.translate(
					"form_description_final_weights_by_inventory_product",
				);
			} else {
				this.schema.description = this._translocoService.translate(
					"form_description_final_weights_by_substance_type",
				);
			}

			for (const final_weight of this.product_weights()) {
				if (this.giEnabled()) {
					this.schema.properties[final_weight.weight_id] = {
						type: "string",
						widget: "string",
						title: `${final_weight.product_name} (${final_weight.unit})`,
					};

					if (final_weight.reweigh_value) {
						this.model[final_weight.weight_id] = String(final_weight.reweigh_value);
					} else {
						this.model[final_weight.weight_id] = String(final_weight.value);
					}
				} else if (final_weight.value) {
					this.weight_unit.subscribe((unit) => {
						this.schema.properties[final_weight.weight_id] = {
							type: "number",
							widget: "number",
							title: `${final_weight.product_name} (${unit})`,
						};

						const weight = final_weight.product_name
							? this._gramsToUnits.transform(Number(final_weight.value), unit)
							: 0;

						this.model[final_weight.weight_id] = weight;
					});
				}
			}
		}

		this.schema.properties.timestamp = {
			type: "string",
			widget: "date",
			title: "Destruction Date",
			title_translation_key: marker("form_field_label_destruction_date"),
			warning: `The date must be after all events.`,
			warning_translation_key: marker(
				`form_field_warning_the_date_must_be_after_all_events`,
			),
		};
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		if (this.gmpEnabled()) {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: PasswordInputComponent,
					inputs: {
						onSubmit: (password) => {
							this.closeDestructionLot(true, password);
						},
					},
				}),
			);
		} else {
			this.closeDestructionLot();
		}
	}

	onChanges(_event): void {
		// empty
	}

	closeDestructionLot(signing = false, password?: string) {
		const finalWightToSubmit: any[] = [];
		if (
			this.showFinalWeights &&
			this.product_weights() &&
			this.product_weights().length > 0
		) {
			for (const final_weight of this.product_weights()) {
				if (this.giEnabled()) {
					const initial_weight = final_weight;
					initial_weight.value = this.model[final_weight.weight_id];
					finalWightToSubmit.push(initial_weight);
				} else {
					this.weight_unit.subscribe((unit) => {
						const initial_weight = final_weight;

						initial_weight.value = this.model[final_weight.weight_id]
							? this._unitToGrams.transform(this.model[final_weight.weight_id], unit)
							: 0;

						finalWightToSubmit.push(initial_weight);
					});
				}
			}
		}

		const authObject = password
			? { username: this.user.email, password }
			: undefined;

		this.loading$.next(true);
		this._itemService
			.update(
				`destruction_lot/${this.destruction_lot()?.id}/close`,
				"",
				{
					final_weight: finalWightToSubmit,
					// ESS-7429: Only send timestamp to the close endpoint if the user selected one
					timestamp: this.model.timestamp,
				},
				signing ? { signing: "true" } : undefined,
				authObject,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((item) => {
				this._store.dispatch(
					SelectionActions.select({
						result_type: "destruction_lots",
						id: Number(this.destruction_lot()?.id),
					}),
				);
				this.loading$.next(false);
				this.closeSidenav();
			});
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}

	get id(): number {
		const lot = this.destruction_lot();

		return lot ? lot.id : -1;
	}
}
