import { Pipe, PipeTransform } from "@angular/core";
import { Globals } from "app/shared/modules/globals/globals.service";
import {
	MeasureUnit,
	baseMeasureUnitToUnit,
} from "@elevatedsignals/amygoodman";

@Pipe({
	name: "gramsToUnitPipe",
})
export class GramsToUnitPipe implements PipeTransform {
	constructor(private readonly _globals: Globals) {}

	transform(
		weight_in_grams: number,
		units: string = this._globals.weight_unit,
	): string {
		if (isNaN(weight_in_grams)) {
			return `${weight_in_grams}`;
		}
		return baseMeasureUnitToUnit(weight_in_grams, MeasureUnit.Mass, units);
	}
}
