/* eslint-disable @typescript-eslint/naming-convention */
import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const layoutActions = createActionGroup({
	source: "Layout",
	events: {
		"open sidenav": props<{ component: any; inputs?: Record<string, any> }>(),
		closeSidenav: emptyProps(),
		closeAllSidenavs: emptyProps(),
		"open dropdown": props<{ component: any; inputs?: Record<string, any> }>(),
		closeDropdown: emptyProps(),
		"maximize sidenav requested": props<{
			expand: boolean;
		}>(),
		"maximize sidenav completed": props<{
			expanded: boolean;
		}>(),
	},
});
