import { IEquipment, IPurchaseOrder } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.PurchaseOrders;

export type State = SelectionState<IPurchaseOrder>;

export const reducer = createSelectionReducer<IPurchaseOrder>(reducerKey);
