import { tap, take, takeUntil, timeout, catchError } from "rxjs/operators";
import { IWorkOrder } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { EMPTY } from "rxjs";
import { Component, ChangeDetectorRef, OnDestroy, OnInit } from "@angular/core";
import { WorkOrderDetailQuery } from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

/** @deprecated Use UpdateReasonComponent instead. */
@Component({
	selector: "work-order-reopen",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderReopenComponent
	extends GenericUpdateComponent<IWorkOrder>
	implements OnInit, OnDestroy
{
	work_order$ = this._store.select(fromDashboard.getSelectedWorkOrder);
	work_order: IWorkOrder;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
	) {
		super(_store, _cd);
		this.form_title = "Reopen work order";
		this.form_title_translation_key = marker("form_title_reopen_work_order");
		this.submit_button = "Reopen";
		this.submit_button_translation_key = marker("word_reopen");

		this.schema = {
			title: "",
			description: ``,
			info: "",
			properties: {
				id: {
					type: "number",
					hidden: true,
				},
				reason: {
					type: "string",
					title: "Reopen Reason",
					title_translation_key: marker("form_field_label_reopen_reason"),
					widget: "textarea",
				},
			},
		};
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			this.schema.required = ["reason"];
		}

		this.work_order$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((work_order: IWorkOrder) => {
				this.work_order = work_order;
				this.model.id = this.work_order.id;
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(work_order: Partial<IWorkOrder>) {
		const work_order_data = {
			...work_order,
			closed_at: null,
		};

		this.loading$.next(true);
		this._itemService
			.update(
				`work_order`,
				`${work_order.id}/reopen`,
				work_order_data,
				WorkOrderDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
