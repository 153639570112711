import { Component } from "@angular/core";
// import { AuthService } from "app/modules/auth/services/auth.service";
import {
	ComponentModalConfig,
	FuiBaseModal,
	FuiModal,
	ModalSize,
} from "@elevatedsignals/ngx-fomantic-ui";

@Component({
	selector: "refresh-modal",
	template: `
		<div class="modal-container">
			<h4>
				<div class="logo"></div>
				<div class="content">You will be logged out soon.</div>
			</h4>
			<button
				class="ui button blue refresh"
				(click)="modal.approve(undefined)"
				autofocus
			>
				Stay logged in
			</button>
		</div>
	`,
	styleUrls: ["./expired-modal.scss"],
})
export class RefreshModalComponent {
	constructor(public modal: FuiBaseModal<string, void, void>) {}
}
export class RefreshModal extends ComponentModalConfig<string, void, void> {
	constructor(context: string, size = ModalSize.Small) {
		super(RefreshModalComponent, context);

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = size;
		this.context = context;
	}
}
