import { ICustomFieldSchema } from "@elevatedsignals/amygoodman";
import { createReducer, on } from "@ngrx/store";
import * as customFieldActions from "app/modules/dashboard/actions/customField.actions";

export interface State {
	customFields: Record<string, ICustomFieldSchema>;
	api: {
		fetch: {
			error: string | null;
			in_progress: boolean;
		};
	};
}

const initialState: State = {
	customFields: {},
	api: {
		fetch: {
			error: null,
			in_progress: false,
		},
	},
};

export const reducer = createReducer(
	initialState,
	on(customFieldActions.fetchCustomField, (state) => ({
		...state,
		api: { ...state.api, fetch: { error: null, in_progress: true } },
	})),
	on(customFieldActions.fetchCustomFieldSuccess, (state, payload) => {
		return {
			...state,
			api: { ...state.api, fetch: { error: null, in_progress: false } },
			customFields: {
				...state.customFields,
				[payload.schema.type]: payload.schema,
			},
		};
	}),
	on(customFieldActions.fetchCustomFieldFailure, (state, payload) => {
		return {
			...state,
			api: {
				fetch: { in_progress: false, error: payload.error },
			},
		};
	}),
);

/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */

export const getAllCustomFields = (state: State) => state.customFields;

export const getFetchInProgress = (state: State) => state.api.fetch.in_progress;

export const getFetchError = (state: State) => state.api.fetch.error;
