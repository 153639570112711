import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DashboardState } from "app/modules/dashboard/state/dashboard.state";
import { DateFormatFacilitySetting } from "app/shared/time-format";

export const dashboardFeatureKey = "dashboardV2";

const getDashboardState =
	createFeatureSelector<DashboardState>(dashboardFeatureKey);

const getFacilitySettingsState = createSelector(
	getDashboardState,
	(state) => state.facilitySettings,
);

export const getSelectedFacilitySettings = createSelector(
	getFacilitySettingsState,
	(facilitySettings) => facilitySettings.settings,
);

export const getFacilitySettingsFetchStatus = createSelector(
	getFacilitySettingsState,
	(facilitySettings) => {
		return facilitySettings.api.fetch.in_progress;
	},
);

export const getApiVersion = createSelector(
	getFacilitySettingsState,
	(facilitySettings) => {
		return facilitySettings.apiVersion;
	},
);

export const getInitialApiVersion = createSelector(
	getFacilitySettingsState,
	(facilitySettings) => {
		return facilitySettings.initialApiVersion;
	},
);

export const getReportingPeriods = createSelector(
	getFacilitySettingsState,
	(facilitySettings) => {
		return facilitySettings.settings.reporting_periods;
	},
);

export const reportingPeriodsEnabled = createSelector(
	getFacilitySettingsState,
	(facilitySettings) => {
		return (
			facilitySettings.settings.data?.options?.enable_reporting_periods ?? false
		);
	},
);

export const generalInventoryEnabled = createSelector(
	getFacilitySettingsState,
	(facilitySettings) => {
		return facilitySettings.settings.licence.general_inventory;
	},
);

const facilityTimeZone = createSelector(
	getFacilitySettingsState,
	(facilitySettings) => {
		return facilitySettings.settings.timezone;
	},
);

const dateFormat = createSelector(
	getFacilitySettingsState,
	(facilitySettings) => {
		return (
			facilitySettings.settings.data?.options?.facility_date_format ??
			"monthDayYear"
		);
	},
);

export const blockDuplicateBatchNames = createSelector(
	getFacilitySettingsState,
	(facilitySettings) => {
		return (
			facilitySettings.settings.data?.options?.block_duplicate_batch_names ?? false
		);
	},
);

const timeFormat = createSelector(
	getFacilitySettingsState,
	(facilitySettings) => {
		return facilitySettings.settings.time_format;
	},
);

export const facilityTimeInfo = createSelector(
	facilityTimeZone,
	dateFormat,
	timeFormat,
	(timeZone, dateFormat, timeFormat) => {
		return {
			timeZone,
			dateFormat,
			timeFormat,
		};
	},
);

export const gmpEnabled = createSelector(getDashboardState, (entities) => {
	return entities.facilitySettings.settings.licence.gmp;
});

// @deprecated
export const ezGiMigration = createSelector(getDashboardState, (entities) => {
	return entities.facilitySettings.settings.licence.ez_gi_migration;
});

export const showSOPCategoriesEnabled = createSelector(
	getDashboardState,
	(entities) => {
		let useSopCategories = true;
		if (
			entities.facilitySettings.settings.data?.options &&
			"use_sop_categories" in entities.facilitySettings.settings.data.options
		) {
			useSopCategories =
				entities.facilitySettings.settings.data.options.use_sop_categories ?? false;
		}
		return useSopCategories;
	},
);

export const getWetWeightInventoryProducts = createSelector(
	getDashboardState,
	(entities) => entities.facilitySettings.settings.wet_weight_inventory_products,
);

export const facilityTimezone = createSelector(
	getDashboardState,
	(entities) => entities.facilitySettings.settings.timezone,
);

export const facilityTimeFormat = createSelector(
	getDashboardState,
	(entities) => entities.facilitySettings.settings.time_format,
);

export const facilityDateFormat = createSelector(
	getSelectedFacilitySettings,
	(facilitySettings) =>
		facilitySettings.data?.options?.facility_date_format ??
		DateFormatFacilitySetting.MonthDayYear,
);

export const facilityLanguage = createSelector(
	getSelectedFacilitySettings,
	(facilitySettings) =>
		facilitySettings.data?.options?.facility_language ?? "en",
);
