import { Component, OnInit, OnDestroy, Injector } from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { Store } from "@ngrx/store";
import { handleObservableError } from "app/shared/utils";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { ReplaySubject, EMPTY } from "rxjs";
import { ILocation } from "@elevatedsignals/amygoodman";
import { LocationPageQuery } from "app/shared/eagers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "location-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class LocationCreateComponent
	extends GenericCreateComponent<ILocation>
	implements OnInit, OnDestroy
{
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Create a Location";
	form_title_translation_key: string = marker("form_title_create_a_location");
	submit_button = "Create Location";
	submit_button_translation_key: string = marker("form_button_create_location");
	submit_icon = "plus";

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	specific_field_id: string;

	model: any = {};

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: Location name already in use.",
				warning_translation_key: marker(
					"form_field_warning_location_name_already_in_use",
				),
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
			location_type_ids: {
				type: "array",
				title: "Location Type(s)",
				title_translation_key: marker("form_field_label_location_type_s"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "location_types",
						},
					],
				},
			},
			is_sublocation: {
				type: "boolean",
				widget: "checkbox",
				title: "Is Sublocation",
				title_translation_key: marker("form_field_label_is_sublocation"),
			},
			parent_location_id: {
				type: "number",
				title: "Parent Location",
				title_translation_key: marker("form_field_label_parent_location"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "locations",
					},
				],
				visibleIf: {
					is_sublocation: [true],
				},
			},
		},
		required: ["name"],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private names;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store);
		this.specific_field_id = this._injector.get("specific_field_id", null);
	}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`locations/fetch/names`, "", { col: "name" })
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const location: Partial<ILocation> = {
			...this.model,
		};
		this.createItem(location);
	}

	onChanges(_error): void {}

	createItem(add_location: Partial<ILocation>) {
		this.loading$.next(true);
		this._itemService
			.add(`locations`, add_location, LocationPageQuery)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							result_type: "locations",
							addedItem,
							...(this.specific_field_id && {
								specific_field_id: this.specific_field_id,
							}),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
