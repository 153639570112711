/* jscpd:ignore-start */
import { takeUntil, tap, timeout, catchError } from "rxjs/operators";
import {
	Component,
	OnInit,
	OnDestroy,
	Injector,
	ChangeDetectorRef,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ReplaySubject, EMPTY } from "rxjs";
import { Globals } from "app/shared/modules/globals/globals.service";
import {
	BatchDetailQuery,
	WorkOrderDestructionEventsQuery,
} from "app/shared/eagers";
import { IDestructionEvent, MeasureUnit } from "@elevatedsignals/amygoodman";
import { UnitToBaseMeasureUnitPipe } from "app/modules/dashboard/modules/es-pipes/units/unit_to_base_measure_unit.pipe";
import { TitleCasePipe } from "@angular/common";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import { ItemKeyType } from "app/modules/dashboard/reducers/selection/keys";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
/* jscpd:ignore-end */

@Component({
	selector: "destruction-event-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
// @deprecated
export class DestroyWeightComponent implements OnInit, OnDestroy {
	// locationAddError$ 			= this._store.select(fromDashboard.getLocationsAddError);
	// locationAddInProgress$ 	= this._store.select(fromDashboard.getLocationsAddInProgress);

	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	// destruction_event$ = this._store.select(fromDashboard.getSelectedDestructionEvent);
	// permissions$ = this._store.select(fromDashboard.getPermissions);

	form_title = "Create a Destruction Event";
	form_title_translation_key: string = marker(
		"form_title_create_a_destruction_event",
	);

	submit_button = "Create Destruction Event";
	submit_button_translation_key: string = marker(
		"form_button_create_destruction_event",
	);

	submit_icon = "plus";

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};
	weight_unit: string = this._globals.weight_unit;

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			destruction_lot_id: {
				type: "number",
				minItems: 1,
				title: "Destruction Lot",
				title_translation_key: marker("word_destruction_lot"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "destruction_lots",
						pretext: "",
						text_key: ["name", "id"],
						queryString: {
							lot_status: "OPEN",
						},
					},
				],
			},
			substance_type_id: {
				type: "number",
				title: "Substance Type",
				title_translation_key: marker("word_substance_type"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "substance_types",
					},
				],
			},
			batchOrLocation: {
				type: "string",
				title: "Destroy by",
				title_translation_key: marker("form_field_label_destroy_by"),
				widget: "radio",
				default: "Batch",
				oneOf: [
					{
						name: "Location",
						title_translation_key: marker("word_location"),
						value: "Location",
						enum: ["Location"],
					},
					{
						name: "Batch",
						title_translation_key: marker("word_batch"),
						value: "Batch",
						enum: ["Batch"],
					},
				],
			},
			batch_id: {
				type: "number",
				title: "Batch",
				title_translation_key: marker("word_batch"),
				widget: "data-select",
				oneOf: [{ result_type: "batches" }],
				visibleIf: {
					batchOrLocation: ["Batch"],
				},
			},
			location_id: {
				type: "number",
				title: "Location",
				title_translation_key: marker("word_location"),
				description:
					"Destruction weight will be distributed between all batches in this location.",
				description_translation_key: marker(
					"form_field_description_destruction_weight_will_be_distributed_between_all_batches_in_this_location",
				),
				widget: "data-select",
				oneOf: [{ result_type: "locations" }],
				visibleIf: {
					batchOrLocation: ["Location"],
				},
			},
			measure_unit: {
				type: "string",
				title: "Measure Type",
				title_translation_key: marker("form_field_label_measure_type"),
				widget: "select",
				oneOf: [
					{
						name: this._titleCasePipe.transform(MeasureUnit.Mass),
						name_translation_key: marker("word_mass"),
						value: MeasureUnit.Mass,
						enum: [MeasureUnit.Mass],
					},
					{
						name: this._titleCasePipe.transform(MeasureUnit.Volume),
						name_translation_key: marker("word_volume"),
						value: MeasureUnit.Volume,
						enum: [MeasureUnit.Volume],
					},
					{
						name: this._titleCasePipe.transform(MeasureUnit.Quantity),
						name_translation_key: marker("word_quantity"),
						value: MeasureUnit.Quantity,
						enum: [MeasureUnit.Quantity],
					},
				],
				default: MeasureUnit.Mass,
			},
			weight: {
				type: "number",
				title: `Value (${this.weight_unit})`,
				title_translation_key: marker("form_field_label_value"),
				title_translation_params: { units: this.weight_unit },
			},
			destruction_reason_id: {
				type: "number",
				title: "Destruction Reason",
				title_translation_key: marker("word_destruction_reason"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "destruction_reasons",
					},
				],
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
			timestamp: {
				type: "string",
				title: "Collected On",
				title_translation_key: marker("form_field_label_collected_on"),
				widget: "date",
			},
		},
		required: [
			"substance_type_id",
			"weight",
			"destruction_lot_id",
			"destruction_reason_id",
		],
	};

	validators = {};

	// Injected from other component
	private batch_id: number;
	private location_id: number;
	private work_order_id: number;
	private destruction_lot_id: number;
	private substance_type_id: number;
	private result_type: "destruction_lot" | "batch" | "work_order";
	private measure_unit: MeasureUnit | undefined;
	private measure_setting: string;
	private measure_setting_fallback: string;
	private prevMeasureUnit: MeasureUnit;

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
		private readonly _cd: ChangeDetectorRef,
		private readonly _unitToBaseMeasureUnitPipe: UnitToBaseMeasureUnitPipe,
		private readonly _globals: Globals,
		private readonly _titleCasePipe: TitleCasePipe,
	) {}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.batch_id = this._injector.get("batch_id", null);
		this.location_id = this._injector.get("location_id", null);
		this.work_order_id = this._injector.get("work_order_id", null);
		this.destruction_lot_id = this._injector.get("destruction_lot_id", null);
		this.substance_type_id = this._injector.get("substance_type_id", null);
		this.measure_unit = this._injector.get("measure_unit", null);
		this.measure_setting = this._injector.get("measure_setting", null);
		this.result_type = this._injector.get("result_type", "destruction_lot");

		const weight = this._injector.get("weight", null);
		const destruction_reason_id = this._injector.get(
			"destruction_reason_id",
			null,
		);
		const is_duplicate = this._injector.get("is_duplicate", false);

		this.schema.properties.batch_id = {
			...this.schema.properties.batch_id,
			oneOf: [
				{
					result_type: "batches",
					queryString: { work_order_id: this.work_order_id },
				},
			],
		};
		if (this.batch_id) {
			this.model = {
				...this.model,
				batch_id: this.batch_id,
			};
			this.schema.properties.batch_id = {
				...this.schema.properties.batch_id,
				default: this.batch_id,
				readOnly: !is_duplicate,
			};
			this.schema.properties.batchOrLocation = {
				...this.schema.properties.batchOrLocation,
				default: "Batch",
				readOnly: !is_duplicate,
			};
		}
		if (this.location_id) {
			this.model = {
				...this.model,
				location_id: this.location_id,
				batchOrLocation: "Location",
			};
			this.schema.properties.location_id = {
				...this.schema.properties.location_id,
				default: this.location_id,
				readOnly: !is_duplicate,
			};
			this.schema.properties.batchOrLocation = {
				...this.schema.properties.batchOrLocation,
				default: "Location",
				readOnly: !is_duplicate,
			};
		}
		if (this.destruction_lot_id) {
			this.model = {
				...this.model,
				destruction_lot_id: this.destruction_lot_id,
			};
			this.schema.properties.destruction_lot_id = {
				...this.schema.properties.destruction_lot_id,
				default: this.destruction_lot_id,
				readOnly: !is_duplicate,
			};
		}
		if (weight) {
			this.model = { ...this.model, weight };
		}
		if (destruction_reason_id) {
			this.model = { ...this.model, destruction_reason_id };
		}
		if (this.substance_type_id) {
			this.model = {
				...this.model,
				substance_type_id: this.substance_type_id,
			};
			this.schema.properties.substance_type_id = {
				...this.schema.properties.substance_type_id,
				default: this.substance_type_id,
				readOnly: !is_duplicate,
			};
		}
		if (this.measure_unit) {
			this.model = {
				...this.model,
				measure_unit: this.measure_unit,
			};
			this.schema.properties.measure_unit = {
				...this.schema.properties.measure_unit,
				default: this.measure_unit,
				readOnly: !is_duplicate,
			};
		}
		if (this.work_order_id) {
			this.model = {
				...this.model,
				work_order_id: this.work_order_id,
			};
			this.schema.properties = {
				work_order_id: {
					type: "number",
					title: "Work Order",
					title_translation_key: marker("word_work_order"),
					widget: "data-select",
					oneOf: [{ result_type: "work_orders" }],
					default: this.work_order_id,
					readOnly: true,
				},
				...this.schema.properties,
			};
		}
		// console.log("MODEL: ", this.model);
		// this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const destruction_event: Partial<IDestructionEvent> = {
			...this.model,
			weight: this._unitToBaseMeasureUnitPipe.transform(
				this.model.weight,
				this._globals.getFacilityUnit(
					this.measure_unit || MeasureUnit.Mass,
					"destruction",
				) ?? "",
				this.measure_unit!,
			),
			result_type: this.result_type,
		};
		this.addDestructionEvent(destruction_event);
	}

	onChanges(model) {
		if (model.measure_unit && model.measure_unit != this.prevMeasureUnit) {
			this.measure_setting_fallback = this.measure_setting || "batch";
			this.measure_unit = model.measure_unit;
			const units = this._globals.getFacilityUnit(
				model.measure_unit,
				this.measure_setting_fallback,
			);
			this.schema.properties.weight.title = `Value (${units})`;
			this.schema.properties.weight.title_translation_params = { units };
			if (this.measure_unit === MeasureUnit.Quantity) {
				this.schema.properties.weight.step = "1";
			} else {
				this.schema.properties.weight.step = ".0001";
			}
			this.prevMeasureUnit = model.measure_unit;
			this._cd.detectChanges();
		}
	}

	addDestructionEvent(add_destruction_event: Partial<IDestructionEvent>) {
		this.loading$.next(true);
		let query: Record<string, string | string[]> | undefined;
		switch (this.result_type) {
			case "work_order":
				query = WorkOrderDestructionEventsQuery;
				break;
			case "batch":
				query = BatchDetailQuery;
				break;
		}
		this._itemService
			.add(
				`destruction_lots/${add_destruction_event.destruction_lot_id}/event`,
				add_destruction_event,
				query,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((item) => {
					if (this.result_type === "destruction_lot") {
						this._store.dispatch(
							SelectionActions.select({
								result_type: "destruction_lots",
								id: add_destruction_event.destruction_lot_id!,
							}),
						);
					} else {
						this._store.dispatch(
							ItemActions.updateSuccess({
								updatedItem: item,
								result_type: this.pluralize(this.result_type),
							}),
						);
					}
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}

	private pluralize(
		item: "destruction_lot" | "batch" | "work_order",
	): ItemKeyType {
		if (item === "work_order") {
			return "work_orders";
		} else if (item === "destruction_lot") {
			return "destruction_lots";
		}

		return "batches";
	}
}
