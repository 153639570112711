import { marker } from "@jsverse/transloco-keys-manager/marker";

export const ShippingOrderAllocateInventoryBySkuSchema = {
	title: "",
	description: "",
	info: "",
	properties: {
		purchase_order_id: {
			type: "number",
			title: "Purchase Order",
			hidden: true,
		},
		sku_id: {
			type: "number",
			title: "SKU",
			title_translation_key: marker("word_sku"),
			widget: "data-select",
			quick_create: false,
			related_properties: ["inventory_product_id"],
			oneOf: [
				{
					result_type: "skus",
					text_key: ["sku"],
				},
			],
		},
		// always hidden
		inventory_product_id: {
			title: "Product",
			title_translation_key: marker("word_product"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			related_properties: ["sku_id"],
			selectFirstAndOnly: true,
			oneOf: [
				{
					result_type: "inventory_products",
					queryString: {
						non_zero_inventory: "false",
						showArchived: "false",
					},
				},
			],
			hidden: true,
		},
		vendor_id: {
			type: "number",
			title: "Vendor",
			title_translation_key: marker("word_vendor"),
			widget: "data-select",
			oneOf: [
				{
					result_type: "vendors",
					queryString: {
						show_vendor_by_inventory_sku: "true",
					},
				},
			],
			hidden: true,
			related_properties: ["inventory_product_id", "sku_id"],
			quick_create: false,
			selectFirstAndOnly: true,
		},
		batch_id: {
			type: "number",
			title: "Batch",
			title_translation_key: marker("word_batch"),
			widget: "data-select",
			hidden: true,
			related_properties: ["inventory_product_id", "vendor_id", "sku_id"],
			quick_create: false,
			selectFirstAndOnly: true,
			oneOf: [
				{
					result_type: "batches",
					queryString: {
						input: "true",
						dont_show_expired: "true",
						non_zero_inventory: "true",
					},
				},
			],
			visibleIf: {
				allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
			},
		},
		choose_source_lot: {
			title: "Choose Source Lot",
			title_translation_key: marker("form_field_label_choose_source_lot"),
			type: "boolean",
			widget: "checkbox",
			default: true,
			visibleIf: {
				allOf: [{ batch_id: ["$EXP$ target.value > 0"] }],
			},
			hidden: true,
		},
		lot_id: {
			type: "number",
			title: "Source Lot",
			title_translation_key: marker("form_field_label_source_lot"),
			widget: "data-select",
			quick_create: false,
			related_properties: [
				"inventory_product_id",
				"batch_id",
				"sku_id",
				"vendor_id",
			],
			selectFirstAndOnly: true,
			oneOf: [
				{
					result_type: "lots",
					queryString: {
						input: "true",
						dont_show_expired: "true",
						non_zero_inventory: "true",
					},
				},
			],
			visibleIf: {
				allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
			},
		},
		choose_source_inventory: {
			title: "Choose Source Inventory",
			title_translation_key: marker("form_field_label_choose_source_inventory"),
			type: "boolean",
			widget: "checkbox",
			default: false,
		},
		inventory_ids: {
			type: "array",
			title: "Inventory ID",
			title_translation_key: marker("form_field_label_inventory_id"),
			widget: "data-select",
			quick_create: false,
			multi: true,
			related_properties: ["inventory_product_id", "batch_id", "lot_id", "sku_id"],
			selectFirstAndOnly: true,
			items: {
				type: "number",
				oneOf: [
					{
						result_type: "inventories",
						text_key: ["name", "id"],
						text_format: "?1 (#?2)",
						value_key: "id",
						queryString: {
							non_zero_inventory: "true",
							dont_show_expired: "true",
						},
					},
				],
			},
			visibleIf: {
				allOf: [
					{ choose_source_inventory: [true] },
					{ inventory_product_id: ["$EXP$ target.value > 0"] },
				],
			},
		},
		use_remaining: {
			title: "Use Remaining Inventory",
			title_translation_key: marker("form_field_label_use_remaining_inventory"),
			type: "boolean",
			widget: "checkbox",
			default: false,
		},
		value: {
			type: "number",
			title: `Quantity`,
			title_translation_key: marker("word_quantity"),
			visibleIf: {
				allOf: [
					{ use_remaining: false },
					{ inventory_product_id: ["$EXP$ target.value > 0"] },
				],
			},
			width: "50%",
		},
		inventory_unit_id: {
			type: "number",
			title: "Inventory Unit",
			title_translation_key: marker("word_inventory_unit"),
			widget: "data-select",
			quick_create: false,
			shorter_placeholder: true,
			related_properties: ["inventory_product_id", "inventory_ids"],
			selectFirstAndOnly: true,
			oneOf: [
				{
					result_type: "inventory_units",
				},
			],
			visibleIf: {
				allOf: [
					{ use_remaining: false },
					{ inventory_product_id: ["$EXP$ target.value > 0"] },
				],
			},
			width: "50%",
		},
		amount_available: {
			type: "string",
			widget: "string",
			title: "Amount Available",
			readOnly: true,
			visibleIf: {
				allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
			},
		},
		timestamp: {
			type: "string",
			title: "Date",
			widget: "date",
			title_translation_key: marker("word_date"),
			warning: "The date must be after Shipping Order Date.",
			warning_translation_key: marker(
				"form_field_warning_the_date_must_be_after_shipping_order_date",
			),
		},
		/** These are just used for the required to make sure we have all the correct items selected before allowing the user to submit the form */
		purchase_order_vendor_is_customer: {
			type: "boolean",
			widget: "checkbox",
			hidden: true,
			default: false,
		},
	},
	anyOf: [
		/**
		 *  With Batch NOT required
		 */
		// With 'Choose Source Inventory' only
		{
			required: [
				"purchase_order_id",
				"sku_id",
				"inventory_product_id",
				"choose_source_inventory",
				"inventory_ids",
				"inventory_unit_id",
				"value",
			],
		},
		// Without 'Choose Source Inventory' or 'Use Remaining'
		{
			required: [
				"purchase_order_id",
				"sku_id",
				"inventory_product_id",
				"lot_id",
				"inventory_unit_id",
				"value",
			],
		},
		// With 'Use Remaining', but not 'Choose Source Inventory'
		{
			required: [
				"purchase_order_id",
				"sku_id",
				"inventory_product_id",
				"lot_id",
				"use_remaining",
			],
		},
		// With 'Choose Source Inventory' and 'Use Remaining'
		{
			required: [
				"purchase_order_id",
				"sku_id",
				"inventory_product_id",
				"choose_source_inventory",
				"inventory_ids",
				"use_remaining",
			],
		},
		/**
		 *  With Batch required
		 */
		// With 'Use Remaining' only
		{
			required: [
				"purchase_order_id",
				"sku_id",
				"inventory_product_id",
				"batch_id",
				"lot_id",
				"use_remaining",
			],
		},
		// With 'Choose Source Inventory' only
		{
			required: [
				"purchase_order_id",
				"sku_id",
				"inventory_product_id",
				"batch_id",
				"choose_source_inventory",
				"inventory_ids",
				"inventory_unit_id",
				"value",
			],
		},
		// Without 'Choose Source Inventory' or 'Use Remaining'
		{
			required: [
				"purchase_order_id",
				"sku_id",
				"inventory_product_id",
				"batch_id",
				"lot_id",
				"inventory_unit_id",
				"value",
			],
		},
		// With 'Choose Source Inventory' and 'Use Remaining'
		{
			required: [
				"purchase_order_id",
				"sku_id",
				"inventory_product_id",
				"batch_id",
				"choose_source_inventory",
				"inventory_ids",
				"use_remaining",
			],
		},
		{
			required: [
				"purchase_order_id",
				"sku_id",
				"inventory_product_id",
				"batch_id",
				"inventory_unit_id",
				"value",
			],
		},
	],
};
