import { Store } from "@ngrx/store";
import { Component, inject, Injector } from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { IInventoryProduct } from "@elevatedsignals/amygoodman";
import {
	EMPTY,
	NEVER,
	ReplaySubject,
	catchError,
	combineLatest,
	filter,
	race,
	takeUntil,
	tap,
	timeout,
} from "rxjs";
import { HttpResponseBase } from "@angular/common/http";
import { handleObservableError } from "app/shared/utils";
import { TranslateErrorService } from "app/shared/errors/handleError";
import { TranslocoService } from "@jsverse/transloco";
import {
	FormBuilder,
	FormGroup,
	FormArray,
	AbstractControl,
} from "@angular/forms";
import Big from "big.js";
import { RESERVED_SI_UNITS } from "app/shared/constants";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { InventoryProductDetailQuery } from "app/shared/eagers";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { getCustomizeUnits } from "app/shared/units";

import { IInventoryUnitCreate } from "../inventory-unit/create.component";
import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "inventory-product-customize-units",
	templateUrl: "./customize-units.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class InventoryProductCustomizeUnitsComponent extends GenericCreateComponent<IInventoryUnitCreate> {
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {},
		required: [],
	};

	unitsForm: FormGroup;

	displayUnitName?: string;

	inventory_product$ = new ReplaySubject<IInventoryProduct>();
	inventory_product: IInventoryProduct;
	onUpdated: any;
	copyProduct = false;

	private readonly inventory_product_id$ = this._store.select(
		fromDashboard.getSelectedInventoryProductId,
	);

	private readonly inventory_product_detail$ = this._store.select(
		fromDashboard.getSelectedInventoryProduct,
	);

	private readonly loading_error$ = new ReplaySubject<string | null>();

	private translateErrorService = inject(TranslateErrorService);

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _translocoService: TranslocoService,
		private readonly formBuilder: FormBuilder,
		private readonly _injector: Injector,
		private readonly _itemService: ItemService,
	) {
		super(_store);

		this.submit_button = "Continue";
		this.submit_button_translation_key = marker("form_button_continue");
		this.onUpdated = this._injector.get("onUpdated", null);
		this.copyProduct = this._injector.get("copyProduct", false);

		race(
			combineLatest([
				this.inventory_product_id$,
				this.inventory_product_detail$,
			]).pipe(
				takeUntil(this.destroyed$),
				filter((values): values is [number | string, IInventoryProduct] => {
					if (values[1] instanceof HttpResponseBase) {
						throw values[1];
					}
					return values[1] !== undefined && values[1]!.id === values[0];
				}),
				this.translateErrorService.catchAndTranslateError(
					"error_failed_to_fetch_inventory_product",
				),
			),
			NEVER.pipe(timeout(10000)),
		).subscribe({
			next: (values) => {
				this.inventory_product$.next(values[1]!);
				this.inventory_product = values[1]!;

				this.unitsForm = this.formBuilder.group({
					displayUnit: this.formBuilder.control(
						this.inventory_product.display_unit_id,
					),
					units: this.formBuilder.array([]),
				});

				const units = getCustomizeUnits(this.inventory_product);
				for (const unit of units) {
					this.addUnit(unit);
				}
			},
			error: (error) => {
				this.loading_error$.next(handleObservableError(error));
				return EMPTY;
			},
		});
	}

	get units(): FormArray {
		return this.unitsForm.get("units") as FormArray;
	}

	get validDisplayUnits() {
		return this.units.controls.filter(
			(unit) => unit.getRawValue().name && unit.getRawValue().conversion,
		);
	}

	displayUnitSelectOption(unit: AbstractControl, index: number) {
		return { ...unit.getRawValue(), index };
	}

	disableAllUnits() {
		const unitsArray = this.unitsForm.get("units") as FormArray;
		// eslint-disable-next-line github/array-foreach
		unitsArray.controls.forEach((unit) => {
			unit.disable();
		});
	}

	addUnit(unit?: {
		id: number;
		isDisplayUnit: boolean;
		name: string;
		conversion: number;
		reverse_conversion: boolean;
	}) {
		const name = unit?.name ?? "";
		const isDisplayUnit = unit?.isDisplayUnit ?? false;

		const unitGroup = this.formBuilder.group({
			id: [unit?.id ?? 0],
			isDisplayUnit: [isDisplayUnit],
			name: [name],
			conversion: [unit?.conversion ?? 0],
			reverse_conversion: unit?.reverse_conversion ?? false,
		});

		if (isDisplayUnit) {
			this.displayUnitName = name;
			unitGroup.get("conversion")?.disable();
		}
		if (RESERVED_SI_UNITS.includes(name)) {
			unitGroup.get("name")?.disable();
			unitGroup.get("conversion")?.disable();
		}

		this.units.push(unitGroup);

		if (unit && !this.copyProduct) {
			unitGroup.disable();
		}
	}

	removeUnit(index: number) {
		this.units.removeAt(index);
	}

	reverseConversion(index: number) {
		const control = this.units.controls.at(index);
		const currentState = control?.getRawValue();
		if (currentState.conversion) {
			const reverseConversion = new Big(1).div(currentState.conversion).toNumber();
			// do not allow to reverse if too many decimals
			if ((reverseConversion.toString().split(".")[1] ?? "").length > 6) return;
			control?.get("conversion")?.setValue(reverseConversion);
		}
		control
			?.get("reverse_conversion")
			?.setValue(!currentState.reverse_conversion);
	}

	switchDisplayUnit(selectedIndex: number) {
		const currentCtrl = this.units.controls.at(selectedIndex);

		const newDisplayUnit = currentCtrl!.getRawValue();
		this.displayUnitName = newDisplayUnit.name;

		// const selectedIndex = this.units.controls.findIndex(
		// 	(unit) => unit.getRawValue().name === this.displayUnitName,
		// );

		// update conversions
		for (let index = 0; index < this.units.controls.length; index += 1) {
			const control = this.units.controls.at(index);

			if (selectedIndex !== index) {
				// set other units' isDisplayUnit property to false
				control?.get("isDisplayUnit")?.setValue(false);

				const unit = control?.getRawValue();

				let conversion: number | undefined;
				if (RESERVED_SI_UNITS.includes(unit.name)) {
					// use default conversion for reserved units
					if (RESERVED_SI_UNITS.includes(newDisplayUnit.name)) {
						conversion = unit.reverse_conversion
							? this.inventory_product.unit_conversion[newDisplayUnit.id]?.[unit.id]
							: this.inventory_product.unit_conversion[unit.id]?.[newDisplayUnit.id];
					}
				} else if (control?.value.id === 0 || this.copyProduct) {
					control?.get("conversion")?.enable();
				}

				if (conversion === undefined) {
					conversion =
						unit.reverse_conversion === newDisplayUnit.reverse_conversion
							? new Big(unit.conversion).div(newDisplayUnit.conversion).toNumber()
							: new Big(unit.conversion).times(newDisplayUnit.conversion).toNumber();
				}

				control?.get("conversion")?.setValue(conversion);
			} else {
				control?.get("isDisplayUnit")?.setValue(true);
			}
		}

		currentCtrl?.get("conversion")?.setValue(1);
		currentCtrl?.get("conversion")?.disable();
	}

	onSubmit() {
		if (this.copyProduct) {
			// return the this.unitsForm.getRawValue() back to parent component
			this.onUpdated(this.unitsForm.getRawValue());
			this.closeSidenav();
		} else {
			// update the units

			this.loading$.next(true);
			this._itemService
				.update(
					`inventory_product/${this.inventory_product.id}`,
					"inventory_units",
					this.unitsForm.getRawValue(),
					InventoryProductDetailQuery,
				)
				.pipe(takeUntil(this.destroyed$))
				.pipe(
					timeout(10000),
					catchError((error) => {
						this.error$.next(handleObservableError(error, true));
						this.loading$.next(false);
						return EMPTY;
					}),
				)
				.pipe(
					tap((updatedItem) => {
						this._store.dispatch(
							ItemActions.updateSuccess({
								updatedItem,
								result_type: "inventory_products",
							}),
						);
						this.loading$.next(false);
						this.closeSidenav();
					}),
				)
				.subscribe();
		}
	}

	createItem() {}
}
