/* jscpd:ignore-start */
import {
	tap,
	take,
	takeUntil,
	skipWhile,
	timeout,
	catchError,
} from "rxjs/operators";
import {
	IDestructionLot,
	UserProfile,
	IDestructionLotReopen,
} from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { EMPTY, Observable } from "rxjs";
import { Component, ChangeDetectorRef, OnDestroy, OnInit } from "@angular/core";
import { Globals } from "app/shared/modules/globals/globals.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { PasswordInputComponent } from "app/modules/auth/components/password-input";
import { handleObservableError } from "app/shared/utils";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
/* jscpd:ignore-end */

@Component({
	selector: "destruction-lot-reopen",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class DestructionLotReopenComponent
	extends GenericUpdateComponent<IDestructionLotReopen>
	implements OnInit, OnDestroy
{
	destruction_lot$ = this._store.select(fromDashboard.getSelectedDestructionLot);

	user$: Observable<UserProfile | null> = this._store.select(
		fromDashboard.getProfile,
	);

	user: UserProfile;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
	) {
		super(_store, _cd);
		this.form_title = "Reopen a Destruction Lot";
		this.form_title_translation_key = marker(
			"form_title_reopen_a_destruction_lot",
		);
		this.submit_button = "Reopen";
		this.submit_button_translation_key = marker("word_reopen");

		this.schema = {
			title: "",
			description: ``,
			info: "",
			properties: {
				id: {
					type: "number",
					hidden: true,
				},
				invalidate_witness_signatures: {
					type: "boolean",
					widget: "checkbox",
					title: "Invalidate current witness signatures.",
					title_translation_key: marker(
						"form_field_label_invalidate_current_witness_signatures",
					),
					default: true,
				},
				reason: {
					type: "string",
					title: "Reopen Reason",
					title_translation_key: marker("form_field_label_reopen_reason"),
					widget: "textarea",
				},
			},
		};
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			this.schema.required = ["reason"];
		}

		this.user$
			.pipe(
				takeUntil(this.destroyed$),
				skipWhile((user: UserProfile | null) => user == null),
			)
			.subscribe((user) => {
				this.user = user!;
			});

		this.destruction_lot$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((destruction_lot: IDestructionLot) => {
				this.model.id = destruction_lot.id;
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(destruction_lot_reopen: IDestructionLotReopen) {
		if (this._globals.gmp_enabled) {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: PasswordInputComponent,
					inputs: {
						onSubmit: (password) => {
							this.reopenLot(destruction_lot_reopen, true, password);
						},
					},
				}),
			);
		} else {
			this.reopenLot(destruction_lot_reopen, true);
		}
	}

	reopenLot(
		destruction_lot_reopen: IDestructionLotReopen,
		signing = false,
		password?: string,
	) {
		const destruction_lot_data = {
			timestamp: null,
			reason: destruction_lot_reopen.reason,
			invalidate_witness_signatures:
				destruction_lot_reopen.invalidate_witness_signatures,
		};
		const authObject = password
			? { username: this.user.email, password }
			: undefined;
		this.loading$.next(true);
		this._itemService
			.update(
				`destruction_lot/${destruction_lot_reopen.id}/reopen`,
				"",
				destruction_lot_data,
				signing ? { signing: "true" } : undefined,
				authObject,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((_item) => {
					this._store.dispatch(
						SelectionActions.select({
							result_type: "destruction_lots",
							id: destruction_lot_reopen.id,
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
