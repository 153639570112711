import { Store } from "@ngrx/store";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import { IInventory } from "@elevatedsignals/amygoodman";
import { ReplaySubject } from "rxjs";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "measure-event-activity",
	template: `
		<div class="ui grid dirty-mackenzie">
			<div class="sixteen wide column">
				<div class="left floated left aligned eight wide column">
					<button class="ui labeled icon button" (click)="closeSidenav()">
						<i class="left arrow icon"></i>
						Close
					</button>
				</div>
			</div>
			<div class="sixteen wide column">
				<h2>{{ form_title }}</h2>
			</div>

			<div class="sixteen wide column">
				<measure-event-history
					[measureEventId]="measure_event_id"
					[inventory]="inventory"
					[weightUnit]="weight_unit"
					[isSeed]="is_seed"
				></measure-event-history>
			</div>
		</div>
	`,
	styleUrls: ["../sidenav.scss"],
})
export class MeasureEventActivityComponent implements OnInit, OnDestroy {
	measure_event_id: number;
	inventory?: IInventory;
	weight_unit?: string;
	is_seed: boolean;

	form_title = "Measure Event History";

	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _injector: Injector,
	) {}

	ngOnInit() {
		this.measure_event_id = this._injector.get("measure_event_id", null);
		const inventory = this._injector.get("inventory", undefined);

		if (inventory === undefined) {
			this.weight_unit = this._injector.get("weight_unit", undefined);
			this.is_seed = this._injector.get("is_seed", false);
		} else {
			this.inventory = inventory;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
