import {
	HeaderQueryType,
	IPrinterJob,
	Page,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "jobs";

export type State = Page<IPrinterJob>;

// Related items?
// TODO: sort this out
export const reducer = createPagingReducer<IPrinterJob>(reducerKey, {
	result_type: "jobs",
	title: "Printer Jobs",
	title_translation_key: marker("page_title_printer_jobs"),
	title_plural_translation_key: marker("page_title_plural_printer_jobs"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Filter Completed",
			key: "filterCompleted",
			title_translation_key: marker("checkbox_filter_show_completed"),
			type: HeaderQueryType.checkbox,
			value: true,
		},
	],
});
