import { ChangeDetectorRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ReplaySubject } from "rxjs";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

export abstract class GenericUpdateComponent<T> {
	valid$: ReplaySubject<boolean> = new ReplaySubject();
	secondaryValid$: ReplaySubject<boolean> = new ReplaySubject();
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Update an Item";
	form_title_translation_key: string = marker("form_title_update_an_item");
	form_title_translation_params = {};
	submit_button = "Update";
	submit_button_translation_key: string = marker("word_update");
	submit_icon = "edit";

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	public schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
		},
		required: ["name"],
	};

	validators = {};

	public model: any = {};

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
	) {}

	valid(val) {
		this.valid$.next(val);
	}

	secondaryValid(val) {
		this.secondaryValid$.next(val);
	}

	onChanges(_error): void {}

	onError(_error): void {}

	onSubmit() {
		const updated_model: Partial<T> = {
			...this.model,
		};
		this.updateItem(updated_model);
	}

	abstract updateItem(itemModel);

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
