import { combineReducers } from "@ngrx/store";

import * as applicableProducts from "./applicable-products";
import * as applicationMethods from "./application-methods";
import * as auditLogs from "./audit-logs";
import * as batches from "./batches";
import * as batchTypes from "./batch-types";
import * as billOfMaterials from "./bill-of-materials";
import * as cultivar from "./cultivars";
import * as customFields from "./custom-fields";
import * as customScans from "./custom-scans";
import * as destructionEvents from "./destruction-events";
import * as destructionLots from "./destruction-lots";
import * as destructionReasons from "./destruction-reasons";
import * as documentSets from "./document-sets";
import * as equipments from "./equipment";
import * as equivalentUnits from "./equivalent-units";
import * as facilities from "./facilities";
import * as growthStages from "./growth-stage";
import * as growthStageTypes from "./growth-stage-types";
import * as hubs from "./hubs";
import * as inventories from "./inventories";
import * as inventoryCategories from "./inventory-categories";
import * as inventoryProducts from "./inventory-products";
import * as inventoryUnits from "./inventory-units";
import * as locations from "./locations";
import * as locationTypes from "./location-types";
import * as lots from "./lots";
import * as plants from "./plants";
import * as pendingInventories from "./pending-inventories";
import * as printers from "./printers";
import * as printerJobs from "./printer-jobs";
import * as printerJobTemplates from "./printer-job-templates";
import * as productionStages from "./production-stages";
import * as productionStageTypes from "./production-stage-types";
import * as purchaseOrders from "./purchase-orders";
import * as quickRecords from "./quick-records";
import * as rateTypes from "./rate-types";
import * as records from "./records";
import * as reports from "./reports";
import * as reportingCategories from "./reporting-categories";
import * as reportTypes from "./report-types";
import * as roles from "./roles";
import * as schemas from "./schemas";
import * as seedLots from "./seed-lots";
import * as sensors from "./sensors";
import * as skus from "./skus";
import * as sops from "./sops";
import * as sopCategories from "./sop-categories";
import * as statuses from "./statuses";
import * as substanceTypes from "./substance-types";
import * as tags from "./tags";
import * as users from "./users";
import * as vendors from "./vendors";
import * as vendorCategories from "./vendor-categories";
import * as workOrders from "./work-orders";
import * as WorkOrderOutputs from "./work-order-outputs";
import * as WorkOrderTypes from "./work-order-types";
import * as Periods from "./periods";
import * as ProductionPlan from "./production-plans";

export interface SelectionState {
	[x: string]: any;
	[applicableProducts.reducerKey]: applicableProducts.State;
	[applicationMethods.reducerKey]: applicationMethods.State;
	[auditLogs.reducerKey]: auditLogs.State;
	[batchTypes.reducerKey]: batchTypes.State;
	[batches.reducerKey]: batches.State;
	[billOfMaterials.reducerKey]: billOfMaterials.State;
	[cultivar.reducerKey]: cultivar.State;
	[customFields.reducerKey]: customFields.State;
	[customScans.reducerKey]: customScans.State;
	[documentSets.reducerKey]: documentSets.State;
	[destructionEvents.reducerKey]: destructionEvents.State;
	[destructionLots.reducerKey]: destructionLots.State;
	[destructionReasons.reducerKey]: destructionReasons.State;
	[equipments.reducerKey]: equipments.State;
	[equivalentUnits.reducerKey]: equivalentUnits.State;
	[facilities.reducerKey]: facilities.State;
	[growthStages.reducerKey]: growthStages.State;
	[growthStageTypes.reducerKey]: growthStageTypes.State;
	[hubs.reducerKey]: hubs.State;
	[inventories.reducerKey]: inventories.State;
	[inventoryCategories.reducerKey]: inventoryCategories.State;
	[inventoryProducts.reducerKey]: inventoryProducts.State;
	[inventoryUnits.reducerKey]: inventoryUnits.State;
	[locations.reducerKey]: locations.State;
	[locationTypes.reducerKey]: locationTypes.State;
	[lots.reducerKey]: lots.State;
	[plants.reducerKey]: plants.State;
	[pendingInventories.reducerKey]: pendingInventories.State;
	[printers.reducerKey]: printers.State;
	[printerJobs.reducerKey]: printerJobs.State;
	[printerJobTemplates.reducerKey]: printerJobTemplates.State;
	[productionStages.reducerKey]: productionStages.State;
	[productionStageTypes.reducerKey]: productionStageTypes.State;
	[purchaseOrders.reducerKey]: purchaseOrders.State;
	[quickRecords.reducerKey]: quickRecords.State;
	[rateTypes.reducerKey]: rateTypes.State;
	[records.reducerKey]: records.State;
	[reports.reducerKey]: reports.State;
	[reportingCategories.reducerKey]: reportingCategories.State;
	[reportTypes.reducerKey]: reportTypes.State;
	[roles.reducerKey]: roles.State;
	[schemas.reducerKey]: schemas.State;
	[seedLots.reducerKey]: seedLots.State;
	[sensors.reducerKey]: sensors.State;
	[skus.reducerKey]: skus.State;
	[sops.reducerKey]: sops.State;
	[sopCategories.reducerKey]: sopCategories.State;
	[statuses.reducerKey]: statuses.State;
	[substanceTypes.reducerKey]: substanceTypes.State;
	[tags.reducerKey]: tags.State;
	[users.reducerKey]: users.State;
	[vendors.reducerKey]: vendors.State;
	[vendorCategories.reducerKey]: vendorCategories.State;
	[workOrders.reducerKey]: workOrders.State;
	[WorkOrderOutputs.reducerKey]: WorkOrderOutputs.State;
	[WorkOrderTypes.reducerKey]: WorkOrderTypes.State;
	[Periods.reducerKey]: Periods.State;
	[ProductionPlan.reducerKey]: ProductionPlan.State;
}

export const selectionReducers = combineReducers({
	[applicableProducts.reducerKey]: applicableProducts.reducer,
	[applicationMethods.reducerKey]: applicationMethods.reducer,
	[auditLogs.reducerKey]: auditLogs.reducer,
	[batches.reducerKey]: batches.reducer,
	[batchTypes.reducerKey]: batchTypes.reducer,
	[billOfMaterials.reducerKey]: billOfMaterials.reducer,
	[cultivar.reducerKey]: cultivar.reducer,
	[customFields.reducerKey]: customFields.reducer,
	[customScans.reducerKey]: customScans.reducer,
	[destructionEvents.reducerKey]: destructionEvents.reducer,
	[destructionLots.reducerKey]: destructionLots.reducer,
	[destructionReasons.reducerKey]: destructionReasons.reducer,
	[documentSets.reducerKey]: documentSets.reducer,
	[equipments.reducerKey]: equipments.reducer,
	[equivalentUnits.reducerKey]: equivalentUnits.reducer,
	[facilities.reducerKey]: facilities.reducer,
	[growthStages.reducerKey]: growthStages.reducer,
	[growthStageTypes.reducerKey]: growthStageTypes.reducer,
	[hubs.reducerKey]: hubs.reducer,
	[inventories.reducerKey]: inventories.reducer,
	[inventoryCategories.reducerKey]: inventoryCategories.reducer,
	[inventoryProducts.reducerKey]: inventoryProducts.reducer,
	[inventoryUnits.reducerKey]: inventoryUnits.reducer,
	[locations.reducerKey]: locations.reducer,
	[locationTypes.reducerKey]: locationTypes.reducer,
	[lots.reducerKey]: lots.reducer,
	[plants.reducerKey]: plants.reducer,
	[pendingInventories.reducerKey]: pendingInventories.reducer,
	[printers.reducerKey]: printers.reducer,
	[printerJobs.reducerKey]: printerJobs.reducer,
	[printerJobTemplates.reducerKey]: printerJobTemplates.reducer,
	[productionStages.reducerKey]: productionStages.reducer,
	[productionStageTypes.reducerKey]: productionStageTypes.reducer,
	[purchaseOrders.reducerKey]: purchaseOrders.reducer,
	[quickRecords.reducerKey]: quickRecords.reducer,
	[rateTypes.reducerKey]: rateTypes.reducer,
	[records.reducerKey]: records.reducer,
	[reports.reducerKey]: reports.reducer,
	[reportTypes.reducerKey]: reportTypes.reducer,
	[reportingCategories.reducerKey]: reportingCategories.reducer,
	[roles.reducerKey]: roles.reducer,
	[schemas.reducerKey]: schemas.reducer,
	[seedLots.reducerKey]: seedLots.reducer,
	[sensors.reducerKey]: sensors.reducer,
	[skus.reducerKey]: skus.reducer,
	[sops.reducerKey]: sops.reducer,
	[sopCategories.reducerKey]: sopCategories.reducer,
	[statuses.reducerKey]: statuses.reducer,
	[substanceTypes.reducerKey]: substanceTypes.reducer,
	[tags.reducerKey]: tags.reducer,
	[users.reducerKey]: users.reducer,
	[vendors.reducerKey]: vendors.reducer,
	[vendorCategories.reducerKey]: vendorCategories.reducer,
	[workOrders.reducerKey]: workOrders.reducer,
	[WorkOrderOutputs.reducerKey]: WorkOrderOutputs.reducer,
	[WorkOrderTypes.reducerKey]: WorkOrderTypes.reducer,
	[Periods.reducerKey]: Periods.reducer,
	[ProductionPlan.reducerKey]: ProductionPlan.reducer,
});
