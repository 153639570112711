import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, tap, takeUntil, take } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	OnInit,
	OnDestroy,
	Injector,
	ChangeDetectorRef,
} from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { EquivalentUnit, IInventoryProduct } from "@elevatedsignals/amygoodman";
import {
	EquivalentUnitDetailQuery,
	InventoryProductDetailQuery,
} from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "equivalent-unit-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class EquivalentUnitCreateComponent
	extends GenericCreateComponent<EquivalentUnit>
	implements OnDestroy, OnInit
{
	inventory_product$ = this._store.select(
		fromDashboard.getSelectedInventoryProduct,
	);

	inventory_product: IInventoryProduct | undefined;

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			// ESS-6346: Refactor units to have multiple inventory_products
			inventory_product_id: {
				type: "number",
				title: "Product",
				title_translation_key: marker("word_product"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "inventory_products",
					},
				],
			},
			input_inventory_unit_id: {
				type: "number",
				title: "Unit 1",
				title_translation_key: marker("form_field_label_unit_1"),
				widget: "data-select",
				related_properties: ["inventory_product_id"],
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
			},
			conversion_constant: {
				type: "number",
				title: "Makes",
				minimum: 0.0000001,
				title_translation_key: marker("word_makes"),
			},
			output_inventory_unit_id: {
				type: "number",
				title: "Unit 2",
				title_translation_key: marker("form_field_label_unit_2"),
				widget: "data-select",
				related_properties: ["inventory_product_id"],
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
			},
			disconnected_units: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: true,
			},
			warning: {
				widget: "section",
				type: "string",
				title: "WARNING",
				title_translation_key: marker("form_field_label_warning"),
				description:
					"The two units selected are currently disconnected. When combining units you have the option to zero out all inventory for one of the units. This is used to prevent duplication of inventory in cases where inventory was double counted in different units.",
				description_translation_key: marker(
					"form_field_description_two_units_selected_are_disconnected",
				),
				warning: true,
				visibleIf: {
					allOf: [{ disconnected_units: true }],
				},
			},
			zero_inventory: {
				type: "boolean",
				title: "Zero Inventory?",
				title_translation_key: marker("form_field_label_zero_inventory"),
				widget: "checkbox",
				description:
					"Checking this box will zero out all inventory recorded under the unit selected below.",
				description_translation_key: marker(
					"form_field_description_checking_this_box_will_zero_out_inventory",
				),
				default: false,
				visibleIf: {
					allOf: [{ disconnected_units: true }],
				},
			},
			zero_unit: {
				type: "number",
				title: "Zero Unit",
				description: "  Select the unit to zero out.",
				title_translation_key: marker("form_field_label_zero_unit"),
				widget: "radio",
				default: 1,
				oneOf: [
					{
						value: 1,
						enum: [1],
						name_translation_key: marker("word_unit1"),
						name: "Unit 1",
					},
					{
						value: 2,
						enum: [2],
						name_translation_key: marker("word_unit2"),
						name: "Unit 2",
					},
				],
				visibleIf: {
					zero_inventory: true,
				},
			},
			conversion_preview: {
				type: "string",
				widget: "section",
				title: " ",
			},
		},
		required: [
			"inventory_product_id",
			"input_inventory_unit_id",
			"conversion_constant",
			"output_inventory_unit_id",
		],
	};

	result_type: "equivalent_units" | "inventory_product" = "equivalent_units";
	inventory_product_id: number | null = null;
	input_unit;
	output_unit;
	specific_field_id: string;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store);
		this.form_title = "Create Equivalent Units";
		this.form_title_translation_key = marker(
			"form_title_create_equivalent_units",
		);
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
		this.specific_field_id = this._injector.get("specific_field_id", null);
	}

	ngOnInit() {
		this.inventory_product_id = this._injector.get("inventory_product_id", null);
		this.result_type = this._injector.get("result_type", "equivalent_units");
		if (this.inventory_product_id) {
			this.model = {
				...this.model,
				inventory_product_id: this.inventory_product_id,
			};
			this.schema.properties = {
				...this.schema.properties,
				inventory_product_id: {
					...this.schema.properties.inventory_product_id,
					readOnly: true,
				},
			};
			this.inventory_product$
				.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
				.subscribe((inventory_product: IInventoryProduct) => {
					this.inventory_product = inventory_product;
					this._cd.detectChanges();
				});
		}
	}

	onChanges(model) {
		if (!this.model.input_inventory_unit_id) {
			this.input_unit = undefined;
		}
		if (!this.model.output_inventory_unit_id) {
			this.output_unit = undefined;
		}
		const outputChanged =
			this.model.output_inventory_unit_id !== this.output_unit?.id;
		if (
			(this.model.input_inventory_unit_id &&
				this.model.input_inventory_unit_id !== this.input_unit?.id) ||
			(outputChanged && this.model.output_inventory_unit_id)
		) {
			this._itemService
				.fetchItem(
					`inventory_unit`,
					`${
						outputChanged
							? this.model.output_inventory_unit_id
							: model.input_inventory_unit_id
					}`,
				)
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((unit) => {
					if (outputChanged) {
						this.output_unit = unit;
					} else {
						this.input_unit = unit;
					}
					this._cd.detectChanges();
				});
		}
		if (this.input_unit && this.output_unit) {
			model.disconnected_units =
				this.input_unit.inventory_product_id !==
				this.output_unit.inventory_product_id;
		} else {
			model.disconnected_units = false;
		}
		if (
			this.model.input_inventory_unit_id &&
			this.model.output_inventory_unit_id &&
			this.model.conversion_constant &&
			this.inventory_product
		) {
			this.schema.properties.conversion_preview.title = `${this._translocoService.translate(
				"word_unit_conversion",
			)}: ${this.model.conversion_constant}${
				this.findInventoryUnitById(
					this.model.output_inventory_unit_id,
					this.inventory_product,
				)?.name
			} = ${
				this.findInventoryUnitById(
					this.model.input_inventory_unit_id,
					this.inventory_product,
				)?.name
			}`;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	createItem(equivalent_unit_form: any) {
		const { warning, ...equivalent_unit } = equivalent_unit_form;
		this.loading$.next(true);
		let query: Record<string, string | string[]> | undefined;
		switch (this.result_type) {
			case "inventory_product":
				query = InventoryProductDetailQuery;
				break;
			default:
				query = EquivalentUnitDetailQuery;
		}
		this._itemService
			.add(
				`equivalent_units`,
				{
					...equivalent_unit,
					result_type: this.result_type,
					inventory_product_id: this.inventory_product_id, // needed for looking up inventory product on return from api
				},
				query,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((item) => {
					switch (this.result_type) {
						case "inventory_product":
							this._store.dispatch(
								ItemActions.updateSuccess({
									updatedItem: item,
									result_type: "inventory_products",
								}),
							);
							break;
						default:
							this._store.dispatch(
								ItemActions.addSuccess({
									result_type: this.result_type,
									addedItem: item,
									specific_field_id: this.specific_field_id,
								}),
							);
					}

					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	findInventoryUnitById(
		inventory_unit_id: number,
		inventoryProduct: IInventoryProduct,
	) {
		for (const unit of inventoryProduct.inventory_units ?? []) {
			if (inventory_unit_id === unit.id) {
				return unit;
			}
		}

		for (const vp of inventoryProduct.virtual_products ?? []) {
			for (const unit of vp.inventory_units ?? []) {
				if (inventory_unit_id === unit.id) {
					return unit;
				}
			}
		}
		return undefined;
	}
}
