export const getProductCode = (name: string) => {
	let productCode = name
		.replace(/[aeiou]/gi, "")
		.replace(/ /g, "-")
		.toUpperCase();

	// Replace any sequential dashes with a single dash
	productCode = productCode.replace(/-+/g, "-");

	return productCode;
};
