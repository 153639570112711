import { IPurchaseOrder } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { PurchaseOrderDetailQuery } from "app/shared/eagers";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import {
	timeout,
	catchError,
	take,
	takeUntil,
	filter,
	tap,
} from "rxjs/operators";
import { EMPTY } from "rxjs";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "purchase-order-add-record-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PurchaseOrderAddRecordComponent
	extends GenericUpdateComponent<{
		id: number;
	}>
	implements OnInit, OnDestroy
{
	purchase_order$ = this._store.select(fromDashboard.getSelectedPurchaseOrder);
	purchase_order: IPurchaseOrder;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.form_title = "Add Record to Purchase Order";
		this.form_title_translation_key = marker(
			"form_title_add_record_to_purchase_order",
		);
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";

		this.schema = {
			title: "",
			description: "",
			info: "",
			properties: {
				id: {
					type: "number",
					hidden: true,
				},
				record_ids: {
					type: "array",
					title: "Record",
					title_translation_key: marker("word_record"),
					widget: "data-select",
					items: {
						type: "number",
						oneOf: [
							{
								result_type: "records",
								text_key: ["id", "form_schema_name"],
								text_format: "#?1 ?2",
								queryString: {
									input: "true",
								},
							},
						],
					},
				},
			},
			anyOf: [{ required: ["record_ids"] }],
		};
	}

	ngOnInit() {
		this.purchase_order$
			.pipe(
				takeUntil(this.destroyed$),
				filter((purchase_order: IPurchaseOrder | null) => purchase_order != null),
				take(1),
			)
			.subscribe((purchase_order: IPurchaseOrder) => {
				this.purchase_order = purchase_order;
				this.model.id = this.purchase_order.id;
				this._cd.detectChanges();
			});

		const is_outgoing = this._injector.get("is_outgoing", false);
		if (is_outgoing) {
			this.form_title = `Add Record to Shipping Order`;
			this.form_title_translation_key = marker(
				"form_title_add_record_to_shipping_order",
			);
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(
		purchase_order_record: Partial<{
			id: number;
			record_ids: number[];
		}>,
	) {
		this.loading$.next(true);
		const new_record: any[] = [];

		purchase_order_record.record_ids?.map((id) => {
			new_record.push({
				purchase_order_id: purchase_order_record.id,
				form_id: id,
			});
		});
		const update = {
			records: [...(this.purchase_order.records ?? []), ...new_record],
		};

		this._itemService
			.update(
				`purchase_order`,
				`${purchase_order_record.id}/records`,
				update,
				PurchaseOrderDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "purchase_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
