import { createReducer, on, Action } from "@ngrx/store";

import { AuthActions } from "../actions/auth.actions";

export interface State {
	error: string | null;
	pending: boolean;
}

const initialState: State = {
	error: null,
	pending: false,
};

const signinPageReducer = createReducer(
	initialState,
	on(AuthActions.navigationSuccess, (state) => {
		return {
			...state,
			pending: false,
		};
	}),
	on(AuthActions.signin, (state) => {
		return {
			...state,
			error: null,
			pending: true,
		};
	}),
	on(AuthActions.signinSuccess, (state) => {
		return {
			...state,
			error: null,
			// pending: false, // Stay true while navigating
		};
	}),
	on(AuthActions.signinFail, (state, { error }) => {
		return {
			...state,
			error,
			pending: false,
		};
	}),
	on(AuthActions.signinErrorClear, (state) => {
		return {
			...state,
			error: null,
		};
	}),
);

export const reducer = (state: State | undefined, action: Action) =>
	signinPageReducer(state, action);

export const getPending = (state: State) => state.pending;
export const getError = (state: State) => state.error;
