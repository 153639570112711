import { ILocationType } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.LocationTypes;

export type State = SelectionState<ILocationType>;

export const reducer = createSelectionReducer<ILocationType>(reducerKey);
