import {
	HeaderQueryType,
	IStatus,
	Page,
	StatusType,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { StatusPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "statuses";

export type State = Page<IStatus>;

export const reducer = createPagingReducer<IStatus>(reducerKey, {
	result_type: "statuses",
	title: "Status",
	title_plural: "Statuses",
	title_translation_key: marker("page_title_statuses"),
	title_plural_translation_key: marker("page_title_plural_statuses"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Status Type Filter",
			key: "status_type",
			type: HeaderQueryType.select,
			options: [
				{
					label: "All Status Types",
					translationKey: marker("status_type_filter_all"),
					value: "All",
				},
				{
					label: "Batch Statuses",
					translationKey: marker("status_type_filter_batches"),
					value: StatusType.Batch,
				},
				{
					label: "Inventory Statuses",
					translationKey: marker("status_type_filter_inventories"),
					value: StatusType.Inventory,
				},
			],
			gi_options: [
				{
					label: "All Status Types",
					translationKey: marker("status_type_filter_all"),
					value: "All",
				},
				{
					label: "Batch Statuses",
					translationKey: marker("status_type_filter_batches"),
					value: StatusType.Batch,
				},
				{
					label: "Inventory Statuses",
					translationKey: marker("status_type_filter_inventories"),
					value: StatusType.Inventory,
				},
				{
					label: "Lot Statuses",
					translationKey: marker("status_type_filter_lots"),
					value: StatusType.Lot,
				},
				{
					label: "Purchase Order Statuses",
					translationKey: marker("status_type_filter_purchase_orders"),
					value: StatusType.PurchaseOrder,
				},
				{
					label: "Vendor Statuses",
					translationKey: marker("status_type_filter_vendors"),
					value: StatusType.Vendor,
				},
			],
			value: "All",
		},
		{
			title: "Show Archived",
			key: "showArchived",
			title_translation_key: marker("checkbox_filter_show_archived"),
			type: HeaderQueryType.checkbox,
			value: false,
		},
		{
			title: "Show Blocked Only",
			key: "showBlockingStatus",
			title_translation_key: marker("checkbox_filter_show_blocked_only"),
			type: HeaderQueryType.checkbox,
			value: false,
		},
	],
	query: StatusPageQuery,
});
