import {
	IDestructionEvent,
	IInventory,
	IInventoryProduct,
	IWorkOrder,
	IWorkOrderOutput,
	IWorkOrderProcessingLoss,
	IWorkOrderSource,
	MeasureUnit,
	MeasureUnitDict,
	SubstanceDict,
} from "@elevatedsignals/amygoodman";
import {
	getConversionProduct,
	getInventoriesTotal,
	getTotalsMeasureUnitDict,
	groupInventoryProductsByConversion,
} from "app/shared/utils";
import Big from "big.js";

export const getRemaining = (
	work_order: IWorkOrder,
	substance_type_id?: number,
	measure_unit?: MeasureUnit,
): number => {
	const result =
		getInputsTotal(work_order.sources ?? [], substance_type_id, measure_unit) -
		getInputsTotal(work_order.outputs ?? [], substance_type_id, measure_unit) -
		getDestructionTotal(
			work_order.destruction_events ?? [],
			substance_type_id,
			measure_unit,
		) -
		Number(work_order.manual_processing_loss) *
			getInputsTotal(
				work_order.processing_losses ?? [],
				substance_type_id,
				measure_unit,
			);

	return result;
};

export const getInputsTotal = (
	work_order_inputs: (
		| IWorkOrderSource
		| IWorkOrderOutput
		| IWorkOrderProcessingLoss
	)[],
	substance_type_id?: number,
	measure_unit?: MeasureUnit,
): number => {
	if (work_order_inputs.length > 0) {
		const relevantInputs = work_order_inputs.filter((input) => {
			if (input.measure_event) {
				return (
					// @deprecated
					((substance_type_id &&
						input.measure_event.substance_type_id === substance_type_id) ||
						!substance_type_id) &&
					((measure_unit && input.measure_event.measure_unit === measure_unit) ||
						!measure_unit)
				);
			}
			return false;
		});

		return relevantInputs
			.reduce((result, input) => {
				return isNaN(input.measure_event!.value)
					? result
					: result.plus(input.measure_event!.value);
			}, new Big(0))
			.toNumber();
	}

	return 0;
};

export const getDestructionTotal = (
	events: IDestructionEvent[],
	substance_type_id?: number,
	measure_unit?: MeasureUnit,
): number => {
	if (events.length > 0) {
		const relevantEvents = events.filter((event) => {
			if (event.measure_event) {
				// @deprecated
				return (
					((substance_type_id &&
						event.measure_event.substance_type_id === substance_type_id) ||
						!substance_type_id) &&
					((measure_unit && event.measure_event.measure_unit === measure_unit) ||
						!measure_unit)
				);
			}
			return false;
		});
		return relevantEvents
			.reduce((result, input) => {
				const measureEventValue = input.measure_event!.value;

				return isNaN(measureEventValue) ? result : result.plus(measureEventValue);
			}, new Big(0))
			.toNumber();
	}

	return 0;
};

/**
 * @returns total of remaining sources
 */
export const getTotalRemaining = (wo): MeasureUnitDict | undefined => {
	const result: MeasureUnitDict = {};
	// Copy sources/outputs/destruction_events as they will be removed in getInventoriesTotal once added to calc
	let sourceInventory = [
		...(wo.sources ?? [])
			.map((input) => {
				return { ...input.inventory } as IInventory;
			})
			.filter((item): item is IInventory => Boolean(item)),
	];
	let outputDestructionInventory = [
		...(wo.outputs ?? [])
			.map((input) => {
				return { ...input.inventory } as IInventory;
			})
			.filter((item): item is IInventory => Boolean(item)),
		...(wo.destruction_events ?? [])
			.map((input) => {
				return { ...input.inventory } as IInventory;
			})
			.filter((item): item is IInventory => Boolean(item)),
	];

	const plProducts = wo.work_order_type?.processing_loss_inventory_products;
	if (plProducts && plProducts.length > 0) {
		const filterInventories = (
			inventories: IInventory[],
			products: IInventoryProduct[],
		) =>
			inventories.filter((inventory) =>
				products.some((product) => product.id === inventory.inventory_product_id),
			);

		sourceInventory = filterInventories(sourceInventory, plProducts);
		outputDestructionInventory = filterInventories(
			outputDestructionInventory,
			plProducts,
		);
	}

	// Identify common units, prefer SI unit then display unit of inputs then common
	const inventoryProducts: IInventoryProduct[] = [
		...sourceInventory,
		...outputDestructionInventory,
	]
		.map((item) => item.conversionProduct ?? getConversionProduct(item))
		.filter((item): item is IInventoryProduct => Boolean(item));
	const groupsByUnit = groupInventoryProductsByConversion(
		[...new Map(inventoryProducts.map((item) => [item.id, item])).values()],
		undefined,
		true,
	);
	for (const unit of Object.keys(groupsByUnit)) {
		const sourcesTotal = getTotalsMeasureUnitDict(
			getInventoriesTotal(sourceInventory, unit, false, true),
		);
		const outputDestructionsTotal = getTotalsMeasureUnitDict(
			getInventoriesTotal(outputDestructionInventory, unit, false, true),
		);
		const used = outputDestructionsTotal[unit];
		const added = sourcesTotal[unit];

		if (added) {
			if (used) {
				result[unit] = Number(new Big(added).minus(used));
			} else {
				result[unit] = added;
			}
		} else {
			result[unit] = -(used ?? 0);
		}
	}
	return result;
};

export const getTotalProductRemaining = (wo): SubstanceDict | undefined => {
	if (!wo.sources || !wo.outputs || !wo.destruction_events) {
		return undefined;
	}

	let sourceInventory = wo.sources
		.map((input) => input.inventory as IInventory)
		.filter((item): item is IInventory => Boolean(item));
	let outputInventory = wo.outputs
		.map((input) => input.inventory as IInventory)
		.filter((item): item is IInventory => Boolean(item));
	let destructionInventory = wo.destruction_events
		.map((input) => input.inventory as IInventory)
		.filter((item): item is IInventory => Boolean(item));

	const plProducts = wo.work_order_type?.processing_loss_inventory_products;
	if (plProducts && plProducts.length > 0) {
		const filterInventories = (
			inventories: IInventory[],
			products: IInventoryProduct[],
		) =>
			inventories.filter((inventory) =>
				products.some((product) => product.id === inventory.inventory_product_id),
			);

		sourceInventory = filterInventories(sourceInventory, plProducts);
		outputInventory = filterInventories(outputInventory, plProducts);
		destructionInventory = filterInventories(destructionInventory, plProducts);
	}

	const inputs = getInventoryTotalsByProduct(sourceInventory);
	const outputs = getInventoryTotalsByProduct(outputInventory);
	const destructions = getInventoryTotalsByProduct(destructionInventory);

	const totalRemainingValues = Object.values(wo.totalRemaining).filter(
		(value) => value !== 0,
	);
	const processingLossPerProduct = totalRemainingValues.length
		? getProcessingLoss(inputs, outputs, destructions)
		: {};

	return processingLossPerProduct;
};

/**
 * @returns total of all source products
 */
const getTotalProductInputs = (
	work_order_inputs: (
		| IWorkOrderSource
		| IWorkOrderOutput
		| IWorkOrderProcessingLoss
		| IDestructionEvent
	)[],
): SubstanceDict | undefined => {
	return getInventoryTotalsByProduct(
		work_order_inputs
			.map((input) => input.inventory as IInventory)
			.filter((item): item is IInventory => Boolean(item)),
	);
};

const getProcessingLoss = (
	total_inputs: SubstanceDict,
	total_outputs: SubstanceDict,
	total_destruction: SubstanceDict,
): SubstanceDict => {
	const result: ResultOutput = {};

	processItems(result, total_inputs);
	processItems(result, total_outputs, true);
	processItems(result, total_destruction, true);

	return result;
};

type ResultOutput = Record<
	string,
	Record<string, number | undefined> | undefined
>;

const processItems = (
	result: ResultOutput,
	items: SubstanceDict,
	subtract = false,
) => {
	for (const substance of Object.keys(items)) {
		if (!result[substance]) {
			result[substance] = {
				[MeasureUnit.Quantity]: 0,
				[MeasureUnit.Mass]: 0,
				[MeasureUnit.Volume]: 0,
			};
		}

		const safeInput = items[substance];
		if (!safeInput) {
			continue;
		}

		for (const unit of Object.keys(safeInput)) {
			const safeValue = safeInput[unit];

			if (safeValue) {
				if (result[substance]?.[unit] && subtract) {
					result[substance]![unit] = new Big(result[substance]![unit]!)
						.minus(safeValue)
						.toNumber();
				} else if (result[substance]?.[unit]) {
					result[substance]![unit] = new Big(result[substance]![unit]!)
						.plus(safeValue)
						.toNumber();
				} else {
					result[substance]![unit] = (subtract ? -1 : 1) * safeValue;
				}
			}
		}
	}
};

const getInventoryTotalsByProduct = (
	inventories: IInventory[],
	oppositeSign = false,
	inventoryProduct?: IInventoryProduct,
): SubstanceDict => {
	let result: SubstanceDict = {};
	if (inventoryProduct) {
		result[inventoryProduct!.name!] = {};
		const total = getInventoriesTotal(
			inventories.filter(
				(inventory) => inventory.inventory_product_id === inventoryProduct.id,
			),
		);
		// eslint-disable-next-line guard-for-in
		for (const unit in total) {
			result[inventoryProduct!.name!]![unit] =
				(oppositeSign ? -1 : 1) * Number(total[unit]);
		}
	} else {
		// Get conversion products omitting undefined
		let inventoryProducts: IInventoryProduct[] = inventories
			.map((item) => item.inventory_product)
			.filter((item): item is IInventoryProduct => Boolean(item));
		// Get unique products
		inventoryProducts = [
			...new Map(inventoryProducts.map((item) => [item.id, item])).values(),
		];
		result = inventoryProducts.reduce<SubstanceDict>((result, product) => {
			result[product!.name!] = {};
			const total = getInventoriesTotal(
				inventories.filter(
					(inventory) => inventory.inventory_product_id === product.id,
				),
			);
			result[product!.name!] = getTotalsMeasureUnitDict(total, oppositeSign);
			return result;
		}, {} as SubstanceDict);
	}
	return result;
};
