import {
	HeaderQueryType,
	Page,
	Record,
	StatusType,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "records";

export type State = Page<Record>;

export const reducer = createPagingReducer<Record>(reducerKey, {
	result_type: "records",
	title: "Record",
	title_translation_key: marker("page_title_records"),
	title_plural_translation_key: marker("page_title_plural_records"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Show my Records",
			title_translation_key: marker("checkbox_filter_show_my_records"),
			key: "myRecords",
			type: HeaderQueryType.checkbox,
			value: false,
		},
		{
			title: "Reverification Required",
			title_translation_key: marker("checkbox_filter_reverification_required"),
			key: "reverificationRequired",
			type: HeaderQueryType.checkbox,
			value: false,
		},
		{
			title: "Users",
			title_translation_key: marker("page_title_plural_users"),
			key: "user_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "users",
			value_key: "id",
			text_key: ["first_name", "last_name"],
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		},
		{
			title: "Verifications",
			title_translation_key: marker("page_title_plural_verifications"),
			key: "verification_status",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Verified",
					translationKey: marker("word_verified"),
					value: "VERIFIED",
				},
				{
					label: "Unverified",
					translationKey: marker("word_unverified"),
					value: "UNVERIFIED",
				},
			],
			multiple: true,
			disabled: false,
			value: ["VERIFIED", "UNVERIFIED"],
		},
		{
			title: "Locks",
			title_translation_key: marker("page_title_plural_locks"),
			key: "lock_status",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Locked",
					translationKey: marker("word_locked"),
					value: "LOCKED",
				},
				{
					label: "Unlocked",
					translationKey: marker("word_unlocked"),
					value: "UNLOCKED",
				},
			],
			multiple: true,
			disabled: false,
			value: ["UNLOCKED"],
		},
		{
			title: "Record Progress Status",
			title_translation_key: marker("page_title_status"),
			key: "progress_status",
			type: HeaderQueryType.select,
			options: [
				{
					label: "In Progress",
					translationKey: marker("status_filter_in_progress"),
					value: "IN_PROGRESS",
				},
				{
					label: "Submitted",
					translationKey: marker("word_submitted"),
					value: "SUBMITTED",
				},
			],
			multiple: true,
			disabled: false,
			value: [],
		},
		{
			title: "Record Filter",
			key: "record_filter",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Active Records",
					translationKey: marker("record_filter_active_records"),
					value: "ACTIVE",
				},
				{
					label: "Show All Records",
					translationKey: marker("record_filter_show_all_records"),
					value: "ALL",
				},
				{
					label: "Show Archived Records",
					translationKey: marker("record_filter_show_archived_records"),
					value: "ARCHIVED",
				},
			],
			value: "ACTIVE",
		},
		{
			title: "Batches",
			title_translation_key: marker("page_title_plural_batches"),
			key: "batch_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "batches",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		},
		{
			title: "Locations",
			title_translation_key: marker("page_title_plural_locations"),
			key: "location_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "locations",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		},
		{
			title: "Status",
			title_translation_key: marker("page_title_status"),
			key: "status_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "statuses",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: null,
			query_string: {
				status_type: StatusType.Record,
			},
		},
		{
			title: "Schema Type",
			title_translation_key: marker("page_title_schema_types"),
			key: "schema_types",
			type: HeaderQueryType.dataSelect,
			result_type: "schemas",
			value_key: "type",
			text_key: "name",
			hide_description: true,
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		},
		{
			title: "Start",
			title_translation_key: marker("word_start"),
			key: "start_date",
			type: HeaderQueryType.date,
			value: "",
		},
		{
			title: "End",
			title_translation_key: marker("word_end"),
			key: "end_date",
			type: HeaderQueryType.date,
			value: "",
		},
		{
			title: "Performed On (start)",
			title_translation_key: marker("records_filter_performed_on_start"),
			key: "performed_on_start",
			type: HeaderQueryType.date,
			value: "",
		},
		{
			title: "Performed On (end)",
			title_translation_key: marker("records_filter_performed_on_end"),
			key: "performed_on_end",
			type: HeaderQueryType.date,
			value: "",
		},
	],
});
