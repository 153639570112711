import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, take, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import { IInventoryUnit } from "@elevatedsignals/amygoodman";
import {
	InventoryProductDetailQuery,
	InventoryUnitDetailQuery,
} from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { RESERVED_SI_UNITS } from "app/shared/constants";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "inventory-unit-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class InventoryUnitUpdateComponent
	extends GenericUpdateComponent<IInventoryUnit>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
				readOnly: false,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
				readOnly: false,
			},
		},
		required: ["name"],
	};

	inventory_unit$ = this._store.select(fromDashboard.getSelectedInventoryUnit);
	inventory_unit: IInventoryUnit;

	result_type: "inventory_units" | "inventory_product" = "inventory_units";
	real_inventory_product_id: number | null = null;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _injector: Injector,
		private readonly _itemService: ItemService,
	) {
		super(_store, _cd);
		this.result_type = this._injector.get("result_type", "inventory_units");
		this.real_inventory_product_id = this._injector.get(
			"real_inventory_product_id",
			null,
		);
		this.form_title = "Update Inventory Unit";
		this.form_title_translation_key = marker("form_title_update_inventory_unit");
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.inventory_unit$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((inventory_unit: IInventoryUnit) => {
				this.inventory_unit = inventory_unit;
				this.model.id = this.inventory_unit.id;
				this.model.name = this.inventory_unit.name;
				this.model.archived = this.inventory_unit.archived;
				if (
					this.inventory_unit.name &&
					RESERVED_SI_UNITS.includes(this.inventory_unit.name)
				) {
					this.schema.properties.name.readOnly = true;
				}
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(type: Partial<IInventoryUnit>) {
		this.loading$.next(true);
		let query: any = null;
		switch (this.result_type) {
			case "inventory_product":
				query = InventoryProductDetailQuery;
				break;
			default:
				query = InventoryUnitDetailQuery;
		}
		this._itemService
			.update(
				`inventory_unit`,
				Number(type.id),
				{
					...type,
					...(this.real_inventory_product_id && {
						real_inventory_product_id: this.real_inventory_product_id,
					}),
					result_type: this.result_type,
				},
				query,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					const result_type =
						this.result_type === "inventory_product"
							? "inventory_products"
							: this.result_type;

					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type,
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
