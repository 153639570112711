import { Component, OnDestroy, Injector } from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { IPrinter } from "@elevatedsignals/amygoodman";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { EMPTY } from "rxjs";
import { handleObservableError } from "app/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "printer-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PrinterCreateComponent
	extends GenericCreateComponent<IPrinter>
	implements OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			connection_type: {
				type: "string",
				title: "Printer Type",
				title_translation_key: marker("form_field_label_printer_type"),
				widget: "radio",
				oneOf: [
					{
						name: "USB Printer",
						name_translation_key: marker("form_field_value_usb_printer"),
						value: "usb",
						enum: ["usb"],
					},
					{
						name: "Network Printer",
						name_translation_key: marker("form_field_value_network_printer"),
						value: "ip",
						enum: ["ip"],
					},
				],
			},
			name: {
				type: "string",
				title: "Printer Name",
				title_translation_key: marker("form_field_label_printer_name"),
				widget: "string",
				visibleIf: {
					connection_type: ["$ANY$"],
				},
			},
			ip: {
				type: "string",
				title: "Printer IP",
				title_translation_key: marker("form_field_label_printer_ip"),
				widget: "string",
				visibleIf: {
					connection_type: ["ip"],
				},
			},
			port: {
				type: "number",
				title: "Printer Port",
				title_translation_key: marker("form_field_label_printer_port"),
				widget: "number",
				visibleIf: {
					connection_type: ["ip"],
				},
			},
			location: {
				type: "string",
				title: "Printer Location",
				title_translation_key: marker("form_field_label_printer_location"),
				description: "- optional",
				description_translation_key: marker("form_field_description_optional"),
				widget: "string",
				visibleIf: {
					connection_type: ["$ANY$"],
				},
			},
			manufacturer: {
				type: "string",
				title: "Manufacturer",
				title_translation_key: marker("form_field_label_manufacturer"),
				description: "- optional",
				description_translation_key: marker("form_field_description_optional"),
				widget: "string",
				visibleIf: {
					connection_type: ["$ANY$"],
				},
			},
			serial_number: {
				type: "string",
				title: "Serial Number",
				title_translation_key: marker("form_field_label_serial_number"),
				description: "- optional",
				description_translation_key: marker("form_field_description_optional"),
				widget: "string",
				visibleIf: {
					connection_type: ["$ANY$"],
				},
			},
			usb_connection_type: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: true,
				visibleIf: {
					connection_type: ["usb"],
				},
			},
			ip_connection_type: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: true,
				visibleIf: {
					connection_type: ["ip"],
				},
			},
			// Need this but always will be set to 'zpl'
			speaks: {
				type: "string",
				widget: "string",
				hidden: true,
				default: "zpl",
			},
		},
		oneOf: [
			{ required: ["name", "usb_connection_type"] },
			{ required: ["name", "ip_connection_type", "ip", "port"] },
		],
	};

	specific_field_id: string;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store);
		this.form_title = "Create a Printer";
		this.form_title_translation_key = marker("form_title_add_a_printer");
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
		this.specific_field_id = this._injector.get("specific_field_id", null);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	createItem(printer: Partial<IPrinter>) {
		this.loading$.next(true);
		this._itemService
			.add(`printers`, printer)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							addedItem,
							result_type: "printers",
							...(this.specific_field_id && {
								specific_field_id: this.specific_field_id,
							}),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
