import { createSelector } from "reselect";
import {
	facilityDateFormat,
	facilityTimeFormat,
	facilityTimezone,
} from "app/modules/dashboard/selectors/facility-settings.selector";

import { getDesiredLanguage } from "../../reducers";

export const dateFormatSelector = createSelector(
	facilityTimezone,
	facilityDateFormat,
	facilityTimeFormat,
	getDesiredLanguage,
	(timezone, dateFormat, timeFormat, language) => ({
		timezone,
		dateFormat,
		timeFormat,
		language,
	}),
);
