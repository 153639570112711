import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, take, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { IInventoryCategory } from "@elevatedsignals/amygoodman";
import { InventoryCategoryDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "inventory-category-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class InventoryCategoryUpdateComponent
	extends GenericUpdateComponent<IInventoryCategory>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			inventory_product_ids: {
				type: "array",
				title: "Inventory Products",
				title_translation_key: marker("word_inventory_products"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_products",
							text_key: ["name"],
						},
					],
				},
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
		},
		required: ["name"],
	};

	inventory_category$ = this._store.select(
		fromDashboard.getSelectedInventoryCategory,
	);

	inventory_category: IInventoryCategory;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
	) {
		super(_store, _cd);
		this.form_title = "Update Inventory Category";
		this.form_title_translation_key = marker(
			"form_title_update_inventory_category",
		);
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.inventory_category$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((inventory_category: IInventoryCategory) => {
				this.inventory_category = inventory_category;
				this.model.id = this.inventory_category.id;
				this.model.name = this.inventory_category.name;
				this.model.archived = this.inventory_category.archived;
				this.model.inventory_product_ids =
					this.inventory_category.inventory_products?.map((product) => product.id);
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(type: IInventoryCategory & { inventory_product_ids: number[] }) {
		const updated_category: Partial<
			IInventoryCategory & { inventory_product_ids: number[] }
		> = {
			name: type.name,
			inventory_product_ids: type.inventory_product_ids,
			archived: type.archived,
		};

		this.loading$.next(true);
		this._itemService
			.update(
				`inventory_category`,
				type.id,
				updated_category,
				InventoryCategoryDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "inventory_categories",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
