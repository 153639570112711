import {
	HeaderQueryType,
	IWorkOrder,
	Page,
	StatusType,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { WorkOrderPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "work_orders";

export type State = Page<IWorkOrder>;

export const reducer = createPagingReducer<IWorkOrder>(reducerKey, {
	result_type: "work_orders",
	title: "Work Order",
	title_translation_key: marker("page_title_work_orders"),
	title_plural: "Work Orders",
	title_plural_translation_key: marker("page_title_plural_work_orders"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Status Filter",
			key: "wo_status",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Unopened",
					translationKey: marker("word_unopened"),
					value: "UNOPENED",
				},
				{
					label: "Planned",
					translationKey: marker("word_planned"),
					value: "PLANNED",
				},
				{ label: "Open", translationKey: marker("word_open"), value: "OPEN" },
				{
					label: "Closed",
					translationKey: marker("word_closed"),
					value: "CLOSED",
				},
			],
			value: ["OPEN", "UNOPENED"],
			multiple: true,
		},
		{
			title: "Verifications",
			title_translation_key: marker("page_title_plural_verifications"),
			key: "verification_status",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Verified",
					translationKey: marker("word_verified"),
					value: "VERIFIED",
				},
				{
					label: "Unverified",
					translationKey: marker("word_unverified"),
					value: "UNVERIFIED",
				},
			],
			multiple: true,
			disabled: false,
			value: ["VERIFIED", "UNVERIFIED"],
		},
		{
			title: "Locks",
			title_translation_key: marker("page_title_plural_locks"),
			key: "lock_status",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Locked",
					translationKey: marker("word_locked"),
					value: "LOCKED",
				},
				{
					label: "Unlocked",
					translationKey: marker("word_unlocked"),
					value: "UNLOCKED",
				},
			],
			multiple: true,
			disabled: false,
			value: ["UNLOCKED"],
		},
		{
			title: "Work Order Type",
			title_translation_key: marker("page_title_work_order_types"),
			key: "work_order_type",
			text_key: "name",
			value_key: "id",
			type: HeaderQueryType.dataSelect,
			result_type: "work_order_types",
			multiple: true,
			search: true,
			disabled: false,
			value: null,
		},
		{
			title: "Locations",
			title_translation_key: marker("page_title_plural_locations"),
			key: "location_id",
			type: HeaderQueryType.dataSelect,
			result_type: "locations",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		},
		{
			title: "Status",
			title_translation_key: marker("page_title_status"),
			key: "status_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "statuses",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: null,
			query_string: {
				status_type: StatusType.WorkOrder,
			},
		},
		{
			title: "Open",
			title_translation_key: marker("word_open"),
			key: "start_date",
			type: HeaderQueryType.date,
			value: "",
		},
		{
			title: "Close",
			title_translation_key: marker("word_close"),
			key: "end_date",
			type: HeaderQueryType.date,
			value: "",
		},
	],
	query: WorkOrderPageQuery,
});
