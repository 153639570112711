import { Pipe, PipeTransform } from "@angular/core";
import {
	baseMeasureUnitToUnit,
	MeasureUnit,
} from "@elevatedsignals/amygoodman";

@Pipe({
	name: "baseMeasureUnitToUnit",
})
export class BaseMeasureUnitToUnitPipe implements PipeTransform {
	constructor() {}

	transform(value: number, baseUnits: MeasureUnit, units?: string): string {
		if (isNaN(value)) {
			return `${value}`;
		}
		return baseMeasureUnitToUnit(value, baseUnits, units);
	}
}
