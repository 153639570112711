import { Page, UserProfile, UserSettingKey } from "@elevatedsignals/amygoodman";
import { createReducer, on } from "@ngrx/store";
import { AuthActions } from "app/modules/auth/actions/auth.actions";

import { ItemActions } from "../../actions/item.actions";
import { FetchPageActions } from "../../actions/paging.actions";
import { ProfileActions } from "../../actions/profile.actions";

export const createPagingReducer = <T extends { id?: number; uuid?: string }>(
	name: string,
	initialState: Page<T>,
) => {
	return createReducer<Page<T>>(
		initialState,
		on(
			ProfileActions.fetchSuccess,
			ProfileActions.updateSuccess,
			(state, action) => {
				const userProfile = action.payload as UserProfile;
				const defaultBatchFilter = userProfile.settings?.[
					UserSettingKey.DEFAULT_BATCH_TYPE_FILTER
				] as string;

				// ESS-7829: Always use the eagers from initial state for a page,
				// so that the possibly stale eagers from the existing state are not used
				if (initialState.query?.eager) {
					if (!state.query) {
						state.query = {};
					}

					state.query.eager = initialState.query.eager;
				}

				if (!defaultBatchFilter) {
					return state;
				}

				if (name !== "batches") {
					return state;
				}

				return {
					...state,
					header_queries:
						state.header_queries?.map((query) => {
							if (query.key === "batch_type_filter") {
								return {
									...query,
									value: defaultBatchFilter,
								};
							} else {
								return query;
							}
						}) ?? [],
				};
			},
		),
		on(FetchPageActions.fetchPageSuccess, (state, action) => {
			const fetched_page = action.payload;

			if (!fetched_page.result_type) {
				console.error("Fetched Page did not return result type");
				return state;
			}
			const result_type = fetched_page.paging_key ?? fetched_page.result_type;

			if (result_type !== name) {
				return state;
			}

			return {
				...state,
				...fetched_page,
			};
		}),
		on(ItemActions.addSuccess, (state, payload) => {
			if (!payload.result_type) {
				console.error(
					"New item did not return valid result type",
					payload.result_type,
				);
				return state;
			}

			const result_type = payload.result_type;

			if (result_type !== name) {
				return state;
			}

			const item_entity: Page<T> = JSON.parse(JSON.stringify(state));

			item_entity.results?.unshift(payload.addedItem as T);

			const pageSize = item_entity.page_size ?? 0;
			const results = item_entity.results ?? [];

			if (pageSize < results.length) {
				results.pop();
			}

			return item_entity;
		}),
		on(ItemActions.addItemsSuccess, (state, payload) => {
			if (payload.result_type !== name) {
				return state;
			}

			const temp: T[] = payload.items.reverse();

			state.results?.unshift(...temp);

			return state;
		}),
		on(ItemActions.updateSuccess, (state, payload) => {
			if (payload.result_type !== name) {
				return state;
			}

			const updated_item = payload;

			const list_item_entity: Page<T> = JSON.parse(JSON.stringify(state));

			const outList: T[] = [];

			let updated = false;
			if (list_item_entity.results) {
				for (const item of list_item_entity.results) {
					if (item.id === updated_item.updatedItem.id) {
						updated = true;
						outList.push({
							...item,
							...payload.updatedItem,
						});
					} else {
						outList.push(item);
					}
				}
			}

			list_item_entity.results = outList;

			if (updated) {
				return {
					...list_item_entity,
				};
			}

			return state;
		}),
		on(AuthActions.logoutSuccess, () => {
			return initialState;
		}),
	);
};
