import { HttpParams } from "@angular/common/http";
import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const DownloadActions = createActionGroup({
	source: "Download",
	events: {
		pdfEmailRequestStart: props<{
			url: string;
			params?:
				| HttpParams
				| {
						[param: string]: string | boolean | number;
				  };
		}>(),
		pdfDownloadStart: props<{
			url: string;
			params?:
				| HttpParams
				| {
						[param: string]: string | boolean | number;
				  };
			fileName?: string;
		}>(),
		pdfDownloadSuccess: emptyProps(),
		pdfDownloadFail: props<{ error: string }>(),
		pdfDownloadClearError: emptyProps(),
	},
});
