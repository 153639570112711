import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, take, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	Injector,
	OnInit,
	OnDestroy,
} from "@angular/core";
import { Router } from "@angular/router";
import { FormSchema } from "@elevatedsignals/amygoodman";
import { FormSchemaDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { Globals } from "app/shared/modules/globals/globals.service";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { DevCycleService } from "app/devcycle/devcycle.service";
import { DevCycleKey } from "app/devcycle/devcycleKeys";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "schema-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class SchemaUpdateComponent
	extends GenericUpdateComponent<FormSchema>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: Form Schema name already in use.",
				warning_translation_key: marker(
					"form_field_warning_form_schema_name_already_in_use",
				),
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
			},
			witness_number: {
				type: "number",
				title: "Number of Witnesses",
				title_translation_key: marker("form_field_witness_number"),
				default: 1,
				min: 1,
				description:
					"Number of witness signatures required to fully verify record.",
				description_translation_key: marker(
					"form_field_description_number_of_witness_signatures_required_to_fully_verify_record",
				),
			},
			version_major: {
				type: "number",
				title: "Major Version",
				title_translation_key: marker("form_field_label_major_version"),
			},
			version_minor: {
				type: "number",
				title: "Minor Version",
				title_translation_key: marker("form_field_label_minor_version"),
			},
			released: {
				type: "boolean",
				widget: "checkbox",
				title: "Release",
				title_translation_key: marker("word_release"),
				description:
					"Releasing a form schema will un-release all other versions of it",
				description_translation_key: marker(
					"form_field_description_releasing_a_form_schema_will_un_release_all_other_versions_of_it",
				),
				hidden: true,
			},
			sop_id: {
				type: "number",
				title: "Related SOP",
				title_translation_key: marker("form_field_label_related_sop"),
				widget: "data-select",
				oneOf: [{ result_type: "sops" }],
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
			allow_backdate: {
				type: "boolean",
				title: "Allow Backdate",
				title_translation_key: marker("form_field_label_allow_backdate"),
				widget: "checkbox",
				description:
					"When checked, users have the option to manually set the record's submission date.",
				description_translation_key: marker(
					"form_field_description_when_checked_users_have_the_option_to_manually_set_the_records_submission_date",
				),
			},
		},
		required: ["name"],
	};

	formSchema$ = this._store.select(fromDashboard.getSelectedSchema);
	formSchema: FormSchema;

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private schema_update: any;
	private names;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
		private readonly _router: Router,
		private readonly _globals: Globals,
		private readonly _devCycleService: DevCycleService,
	) {
		super(_store, _cd);
		this.form_title = "Update a Form Schema";
		this.form_title_translation_key = marker("form_title_update_a_form_schema");
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.schema_update = this._injector.get("schema_update", null);

		if (this._globals.gmp_enabled) {
			delete this.schema.properties.allow_backdate;
		}

		this.formSchema$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((formSchema: FormSchema) => {
				this.formSchema = formSchema;

				this.model.id = this.formSchema.id;
				this.model.name = this.formSchema.name;
				this.model.description = this.formSchema.schema?.description;
				this.model.version_major = this.formSchema.version_major;
				this.model.version_minor = this.formSchema.version_minor;
				this.model.sop_id = this.formSchema.sop_id;
				this.model.archived = this.formSchema.archived;
				this.model.released = this.formSchema.released;
				this.model.allow_backdate = this.formSchema.allow_backdate;
				this.model.witness_number = this.formSchema.witness_number;
				this._cd.detectChanges();
				this.getNames();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	// Used for schema validation - duplicate Production Stage name check
	getNames() {
		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`schemas/fetch/names`, "", {
				col: "name",
				except: this.model.name,
			})
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	updateItem(formSchemaUpdate: Partial<FormSchema>) {
		let newFormSchemaUpdate: Partial<FormSchema> = formSchemaUpdate;

		if (this.schema_update) {
			newFormSchemaUpdate = {
				...formSchemaUpdate,
				schema: {
					...this.schema_update,
					title: formSchemaUpdate.name,
					description: formSchemaUpdate.description,
				},
			};
		}
		this.loading$.next(true);
		this._itemService
			.update(
				`schema`,
				newFormSchemaUpdate!.id!,
				newFormSchemaUpdate!,
				FormSchemaDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((item) => {
					if (newFormSchemaUpdate.id === item.id) {
						this._store.dispatch(
							ItemActions.updateSuccess({
								updatedItem: item,
								result_type: "schemas",
							}),
						);
						this.loading$.next(false);
						this.closeSidenav();
					} else {
						this.loading$.next(false);
						this.closeSidenav();
						this._router.navigate([`/dashboard/schemas/${item.id}`]);
					}
				}),
			)
			.subscribe();
	}
}
