/* eslint-disable @typescript-eslint/prefer-readonly */
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY, Observable } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	Injector,
	OnInit,
	OnDestroy,
} from "@angular/core";
import { WorkOrderService } from "app/modules/dashboard/services/work-order.service";
import { Globals } from "app/shared/modules/globals/globals.service";
import { IBillOfMaterial } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "work-order-edit-bom",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderEditBOMComponent
	extends GenericUpdateComponent<IBillOfMaterial>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			work_order_id: {
				type: "number",
				title: "Work Order",
				widget: "data-select",
				readOnly: true,
				oneOf: [
					{
						result_type: "work_orders",
					},
				],
				hidden: true,
			},
			bill_of_material_id: {
				type: "number",
				title: "Bill of Material",
				title_translation_key: marker("word_bill_of_material"),
				widget: "data-select",
				readOnly: true,
				oneOf: [
					{
						result_type: "bill_of_materials",
					},
				],
			},
			bill_of_material_multiplier: {
				type: "number",
				title: "Bill Of Material Multiplier",
				title_translation_key: marker(
					"form_field_label_bill_of_material_multiplier",
				),
				widget: "number",
				default: 1,
				step: 0.01,
				min: 0.01,
				visibleIf: {
					bill_of_material_id: ["$ANY$"],
				},
			},
		},
		anyOf: [
			{
				required: ["bill_of_material_id"],
			},
		],
	};

	private work_order_id: number;
	private bill_of_material: any;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _workOrderService: WorkOrderService,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.form_title = "Edit Bill of Material";
		this.form_title_translation_key = marker(
			"form_title_update_bill_of_material",
		);
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
		this.submit_icon = "plus";

		this.bill_of_material = this._injector.get("bill_of_material", null);
		this.work_order_id = this._injector.get("work_order_id", null);

		this.model = {
			bill_of_material_id: this.bill_of_material.id,
			work_order_id: this.work_order_id,
			bill_of_material_multiplier: this.bill_of_material.multiplier,
		};
	}

	ngOnInit() {}

	onChanges(_model) {}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(work_order_bom) {
		this.loading$.next(true);

		work_order_bom = {
			...work_order_bom,
			work_order_bill_of_material_id:
				this.bill_of_material.work_order_bill_of_material_id,
		};

		const request: Observable<any> = this._workOrderService.updateBOM(
			this.work_order_id,
			work_order_bom,
		);

		request
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
