import { Injectable, Signal, computed, effect, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import {
	initializeDevCycle,
	DevCycleClient,
	DevCycleUser,
	DevCycleOptions,
	DVCVariableValue,
	DevCycleOptionsWithDeferredInitialization,
} from "@devcycle/js-client-sdk";
import { DVCDefaultLogLevel } from "@devcycle/types";
import { EnvService } from "app/shared/services/env.service";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { TrackingService } from "app/tracking/tracking.service";
import { DevCycleKey } from "./devcycleKeys";

type ESVariableValue = DVCVariableValue;

@Injectable({
	providedIn: "root",
})
export class DevCycleService {
	private readonly devCycleClient: DevCycleClient;

	private readonly clientInitialized$: Observable<DevCycleClient>;

	private readonly clientSignal: Signal<DevCycleClient | undefined>;

	constructor(private readonly _env: EnvService) {
		const user: DevCycleUser = {
			isAnonymous: true,
		};

		this.devCycleClient = this.initializeDevCycle(user);
		this.clientInitialized$ = from(this.devCycleClient.onClientInitialized());
		this.devCycleClient.subscribe("variableEvaluated:*", (key, variable) => {
			// track all variable evaluations
			TrackingService.addFeatureFlagEvaluation(key, variable.value);
		});

		this.clientSignal = toSignal(this.clientInitialized$);
	}

	setDevCycleUser(facilityId: string, email: string) {
		const user: DevCycleUser = {
			user_id: facilityId,
			email,
			isAnonymous: false,
		};

		this.devCycleClient.identifyUser(user);

		return from(this.devCycleClient.onClientInitialized()).pipe(
			map((_client) => true),
		);
	}

	getVariableSignal<T extends ESVariableValue>(
		name: DevCycleKey,
		defaultValue: T,
	) {
		const variableValue = computed(
			() =>
				this.clientSignal()?.variable(name, defaultValue).value ?? defaultValue,
		);
		return variableValue;
	}

	getVariable<T extends ESVariableValue>(name: DevCycleKey, defaultValue: T) {
		const variableObserver = from(this.devCycleClient.onClientInitialized()).pipe(
			map((client) => {
				return client.variable(name, defaultValue);
			}),
		);

		return variableObserver;
	}

	allVariables() {
		const variableObserver = from(this.devCycleClient.onClientInitialized()).pipe(
			map((client) => {
				return client.allVariables();
			}),
		);

		return variableObserver;
	}

	private initializeDevCycle(
		user: DevCycleUser,
		logLevel: DVCDefaultLogLevel = "error",
	) {
		const options: DevCycleOptions | DevCycleOptionsWithDeferredInitialization = {
			logLevel,
			deferInitialization: true,
		};

		return initializeDevCycle(this._env.dev_cycle_environment_key, user, options);
	}
}
