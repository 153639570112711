import { ReplaySubject, EMPTY } from "rxjs";
import {
	catchError,
	filter,
	take,
	takeUntil,
	tap,
	timeout,
} from "rxjs/operators";
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { TranslocoService } from "@jsverse/transloco";
import { Plant } from "@elevatedsignals/amygoodman";
import { PlantDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { getSelectedFacilitySettings } from "app/modules/dashboard/selectors/facility-settings.selector";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

import { GeneralBatchActionSchemaProperties } from "../work-order/schemas/batch-action";

@Component({
	selector: "plant-move",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})

// @deprecated
export class PlantMoveComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	facilitySettings$ = this._store.select(getSelectedFacilitySettings);

	plant$ = this._store.select(fromDashboard.getSelectedPlant);
	plant: Plant;

	showLocationDestination = true;
	form_title = "Move a Plant";
	form_title_translation_key: string = marker("form_title_move_a_plant");
	submit_button = "Move Plant";
	submit_button_translation_key: string = marker("form_button_move_plant");
	submit_icon = "edit";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				widget: "hidden",
			},
			location: {
				type: "string",
				title: "Destination",
				title_translation_key: marker("word_destination"),
				widget: "select",
				oneOf: [
					{
						name: "Batch",
						name_translation_key: marker("word_batch") as string,
						value: "BATCH",
						enum: ["BATCH"],
					},
				],
			},
			batch_id: {
				type: "number",
				title: "Destination Batch",
				title_translation_key: marker("form_field_label_destination_batch"),
				widget: "data-select",
				oneOf: [{ result_type: "batches" }],
				visibleIf: {
					location: ["BATCH"],
				},
			},
			location_id: {
				type: "number",
				title: "Destination Location",
				title_translation_key: marker("form_field_label_destination_location"),
				widget: "data-select",
				oneOf: [{ result_type: "locations" }],
				visibleIf: {
					location: ["LOCATION"],
				},
			},
			timestamp: {
				type: "string",
				title: "Time in",
				title_translation_key: marker("form_field_label_time_in"),
				widget: "date",
			},
			equipment_ids: {
				...GeneralBatchActionSchemaProperties.equipment_ids,
				visibleIf: {
					location: ["BATCH"],
				},
			},
			record_ids: {
				...GeneralBatchActionSchemaProperties.record_ids,
				visibleIf: {
					location: ["BATCH"],
				},
			},
		},
		required: ["location", "id"],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/location_id": (value, property, form) => {
			if (
				form.properties.location &&
				form.properties.location.value === "LOCATION"
			) {
				if (form.properties.location_id && form.properties.location_id.value) {
					return {
						location: {
							expectedValue: this._translocoService.translate(
								"form_validation_you_must_select_a_location",
							),
						},
					};
				}
			}
			return null;
		},
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/batch_id": (value, property, form) => {
			if (form.properties.location && form.properties.location.value === "BATCH") {
				if (form.properties.batch_id && form.properties.batch_id.value) {
					return {
						location: {
							expectedValue: this._translocoService.translate(
								"form_validation_you_must_select_a_batch",
							),
						},
					};
				}
			}
			return null;
		},
	};

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _translocoService: TranslocoService,
	) {}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		this.facilitySettings$
			.pipe(takeUntil(this.destroyed$))
			.subscribe((facilitySettings) => {
				if (!facilitySettings.data?.options?.disable_plant_in_location) {
					this.schema.properties.location.oneOf = [
						...this.schema.properties.location.oneOf,
						{
							name: "Location",
							name_translation_key: marker("word_location"),
							value: "LOCATION",
							enum: ["LOCATION"],
						},
					];
					this.showLocationDestination = false;
				}
			});

		this.plant$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((plant: Plant) => {
				this.plant = plant;
				this.model.id = this.plant.id;
				this.model.name = this.plant.name;
				this.model.description = this.model.description ?? "";
				this.model.cultivar_id = this.plant.cultivar_id;
				this.model.growth_stage_id = this.plant.growth_stage
					? this.plant.growth_stage.id
					: null;
				if (
					this.plant.location &&
					this.plant.location.id &&
					this.showLocationDestination
				) {
					this.model.location = "LOCATION";
					this.model.location_id = this.plant.location.id;
				}
				if (this.plant.batch && this.plant.batch.id) {
					this.model.location = "BATCH";
					this.model.batch_id = this.plant.batch.id;
				}

				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const plant: Partial<Plant> = {
			...this.model,
		};
		this.updatePlant(plant);
	}

	onChanges(_error): void {}

	updatePlant(add_plant: Partial<Plant>) {
		this.loading$.next(true);
		this._itemService
			.update(`plant`, this.plant.id, add_plant, PlantDetailQuery)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "plants",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
