import {
	IFacilityData,
	IFacilityOptions,
	IInventoryProduct,
	IReportingPeriod,
} from "@elevatedsignals/amygoodman";
import { DateFormatFacilitySetting } from "app/shared/time-format";

interface ExtendedFacilityOptions extends IFacilityOptions {
	sso_only?: boolean;
	facility_date_format?: DateFormatFacilitySetting;

	default_roles_for_sso?: {
		id: number;
		name: string;
	}[];
}

interface ExtendedFacilityData extends IFacilityData {
	options?: ExtendedFacilityOptions;
}

export enum LicenceType {
	Cultivation = "cultivation",
	Processing = "processing",
	Quality = "quality",
	NonCannabis = "non_cannabis",
}
export interface FacilityLicence {
	general_inventory: boolean;
	ez_gi_migration: boolean;
	gmp: boolean;
	cultivation: boolean;
	processing: boolean;
	quality: boolean;
	non_cannabis: boolean;
}
export interface FacilitySettings {
	is_production: boolean;
	timezone: string;
	time_format: "12h" | "24h";
	data?: ExtendedFacilityData;
	licence: FacilityLicence;
	drying_archived: boolean;
	harvest_archived: boolean;
	country: string;
	reporting_periods: IReportingPeriod[];
	wet_weight_inventory_products: IInventoryProduct[];
}
