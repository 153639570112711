import { FormSchema, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { FormSchemaPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "quick_records";

export type State = Page<FormSchema>;

export const reducer = createPagingReducer<FormSchema>(reducerKey, {
	result_type: "quick_records",
	title: "Form Schema",
	title_plural: "Form Schemas",
	title_translation_key: marker("page_title_form_schemas"),
	title_plural_translation_key: marker("page_title_plural_form_schemas"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	query: FormSchemaPageQuery,
});
