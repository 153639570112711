import { tap, take, takeUntil, timeout, catchError } from "rxjs/operators";
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ReplaySubject, EMPTY } from "rxjs";
import { Sensor } from "@elevatedsignals/amygoodman";
import { SensorDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

@Component({
	selector: "sensor-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class SensorUpdateComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	sensor$ = this._store.select(fromDashboard.getSelectedSensor);

	form_title = "Update a Sensor";
	form_title_translation_key: string = marker("form_title_update_a_sensor");
	submit_button = "Update Sensor";
	submit_button_translation_key: string = marker("form_button_update_sensor");
	submit_icon = "edit";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			uuid: {
				type: "string",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name (optional)",
				title_translation_key: marker("form_field_label_name_optional"),
			},
			capabilities: {
				type: "array",
				title: "Capabilities",
				title_translation_key: marker("word_capabilities"),
				widget: "data-select",
				items: {
					type: "string",
					oneOf: [
						{
							result_type: "capabilities",
							value_key: "capability",
						},
					],
				},
			},
			attached_type: {
				type: "string",
				title: "Attached To",
				title_translation_key: marker("form_field_label_attached_to"),
				widget: "select",
				labelField: "title",
				oneOf: [
					{
						name: "Location",
						name_translation_key: marker("word_location"),
						value: "LOCATION",
						enum: ["LOCATION"],
					},
					{
						name: "Batch",
						name_translation_key: marker("word_batch"),
						value: "BATCH",
						enum: ["BATCH"],
					},
				],
			},
			location_id: {
				type: "number",
				title: "Location",
				title_translation_key: marker("word_location"),
				widget: "data-select",
				oneOf: [{ result_type: "locations" }],
				visibleIf: {
					attached_type: ["LOCATION"],
				},
			},
			batch_id: {
				type: "number",
				title: "Batches",
				title_translation_key: marker("word_batches"),
				widget: "data-select",
				oneOf: [{ result_type: "batches" }],
				visibleIf: {
					attached_type: ["BATCH"],
				},
			},
			manufacturer: {
				type: "string",
				title: "Manufacturer",
				title_translation_key: marker("word_manufacturer"),
				widget: "select",
				labelField: "title",
				oneOf: [
					{
						name: "Monnit",
						value: "monnit",
						enum: ["monnit"],
					},
					{
						name: "Generic",
						name_translation_key: marker("word_generic"),
						value: "generic",
						enum: ["generic"],
					},
				],
			},
			sensor_id_and_check_didgit: {
				type: "string",
				title: "Sensor ID : Check Didgit",
				title_translation_key: marker("form_field_label_sensor_id_check_didget"),
				description: "Not shown if sensor is already registered",
				description_translation_key: marker(
					"form_field_description_not_shown_if_sensor_is_already_registered",
				),
				visibleIf: {
					manufacturer: ["monnit"],
				},
			},
			hub_uuid: {
				type: "string",
				title: "Sensor Hub",
				title_translation_key: marker("form_field_label_sensor_hub"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "hubs",
						value_key: "uuid",
					},
				],
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
		},

		anyOf: [
			{
				required: ["name", "capabilities", "uuid"],
			},
		],
	};

	validators = {};

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
	) {}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		this.sensor$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((sensor: Sensor) => {
				this.model = {
					uuid: sensor.uuid,
					name: sensor.name,
					description: sensor.description,
					manufacturer: sensor.manufacturer_information
						? sensor.manufacturer_information.manufacturer
						: undefined,
					hub_uuid: sensor.hub_uuid,
					capabilities: sensor.capabilities.map((item) => item.capability),
				};
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				if (sensor.batch) {
					this.model = {
						...this.model,
						attached_type: "BATCH",
						batch_id: sensor.batch.id,
					};
					// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				} else if (sensor.location) {
					this.model = {
						...this.model,
						attached_type: "LOCATION",
						location_id: sensor.location.id,
					};
				}
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const sensor = {
			...this.model,
		};
		this.updateSensor(sensor);
	}

	onChanges(_model): void {}

	updateSensor(update_sensor) {
		this.loading$.next(true);
		this._itemService
			.update(`sensor`, update_sensor.uuid, update_sensor, SensorDetailQuery)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "sensors",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
