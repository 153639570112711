import { createSelector } from "reselect";
import { Notification, Page } from "@elevatedsignals/amygoodman";
import { AuthActions } from "app/modules/auth/actions/auth.actions";

import { notificationActions } from "../actions/notification.actions";

export interface State {
	notification: {
		uuids: string[];
		entities: Record<string, Notification>;
	};
	display: {
		page: Page<Notification>;
	};
	api: {
		fetch: {
			error?: string | null;
			in_progress: boolean;
		};
	};
}

const initialState: State = {
	notification: {
		uuids: [],
		entities: {},
	},
	display: {
		page: {
			result_type: "notifications",
			title: "Notification",
			results: [],
			page: 1,
			page_size: 50,
			total: 0,
		},
	},
	api: {
		fetch: {
			in_progress: false,
		},
	},
};

export const reducer = (state = initialState, action): State => {
	switch (action.type) {
		case notificationActions.fetch: {
			return {
				...state,
				api: {
					...state.api,
					fetch: {
						in_progress: true,
					},
				},
			};
		}
		case notificationActions.fetchSuccess: {
			const notifications = action.payload as Notification[];
			const newNotifications = notifications.filter(
				(notification) => !state.notification.entities[notification.uuid!],
			);

			const newNotificationUuids = newNotifications.map(
				(notification) => notification.uuid,
			);
			const newNotificationEntities = newNotifications.reduce(
				(entities: Record<string, Notification>, notification: Notification) => {
					return Object.assign(entities, {
						[notification.uuid!]: notification,
					});
				},
				{},
			);

			return {
				notification: {
					uuids: [
						...state.notification.uuids,
						...newNotificationUuids.filter((item): item is string => Boolean(item)),
					],
					entities: {
						...state.notification.entities,
						...newNotificationEntities,
					},
				},
				display: state.display,
				api: {
					...state.api,
					fetch: {
						in_progress: false,
					},
				},
			};
		}
		case notificationActions.fetchFail: {
			const error = action.payload as string;
			return {
				...state,
				api: {
					...state.api,
					fetch: {
						in_progress: false,
						error,
					},
				},
			};
		}

		case notificationActions.fetchPageSuccess: {
			const new_notification = action.payload as Page<Notification>;
			const old_page = { ...state.display.page };
			const new_page = Object.assign(old_page, new_notification);

			return {
				...state,
				display: {
					...state.display,
					page: new_page,
				},
			};
		}

		case AuthActions.logoutSuccess: {
			return initialState;
		}

		default: {
			return state;
		}
	}
};

/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */

export const getNotificationEntities = (state: State) =>
	state.notification.entities;

export const getNotificationUuids = (state: State) => state.notification.uuids;

export const getPage = (state: State) => state.display.page;

export const getAllNotifications = createSelector(
	getNotificationEntities,
	getNotificationUuids,
	(entities, uuids) => {
		return uuids.map((id) => entities[id]);
	},
);

export const getFetchInProgress = (state: State) => state.api.fetch.in_progress;

/** Export Errors **/
export const getFetchError = (state: State) => state.api.fetch.error;
