/* eslint-disable @typescript-eslint/naming-convention */
import { createActionGroup, props } from "@ngrx/store";
import { Page } from "@elevatedsignals/amygoodman";

export const FetchPageActions = createActionGroup({
	source: "Paging",
	events: {
		"fetch page success": props<{ payload: Page<any> }>(),
	},
});
