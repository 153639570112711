import { Record } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.Records;

export type State = SelectionState<Record>;

export const reducer = createSelectionReducer<Record>(reducerKey);
