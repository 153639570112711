import {
	ChangeDetectorRef,
	Component,
	Injector,
	OnDestroy,
	OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { findIndex } from "app/modules/dashboard/vendor/lodash";
import {
	IMeasureEvent,
	IInventory,
	IInventoryProduct,
} from "@elevatedsignals/amygoodman";
import {
	BatchDetailQuery,
	InventoryDetailQuery,
	LocationDetailQuery,
	LotDetailQuery,
} from "app/shared/eagers";
import { EMPTY, ReplaySubject } from "rxjs";
import { catchError, timeout, takeUntil, tap } from "rxjs/operators";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { handleObservableError } from "app/shared/utils";
import { ItemKeyType } from "app/modules/dashboard/reducers/selection/keys";

@Component({
	selector: "inventory-measure-event-update",
	templateUrl: "../../form-view.component.html",
	styleUrls: ["../../sidenav.scss"],
})
export class UpdateInventoryMeasureEventComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Update Adjustment";
	form_title_translation_key: string = marker("form_title_update_adjustment");
	submit_button = "Update Adjustment";
	submit_button_translation_key: string = marker(
		"form_button_update_adjustment",
	);

	submit_icon = "edit";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	measure_event: IMeasureEvent;
	inventory: IInventory;
	inventory_product: IInventoryProduct;
	related_item: "lot" | "inventory" | "batch" | "location" = "batch";
	related_item_id: number;

	model: any = {};
	adjustment_reasons: { name: string; name_translation_key?: string }[] = [
		{ name: "Harvested", name_translation_key: marker("word_harvested") },
		{ name: "Dried", name_translation_key: marker("word_dried") },
		{
			name: "Processing Loss",
			name_translation_key: marker("word_processing_loss"),
		},
		{ name: "Packaged", name_translation_key: marker("word_packaged") },
		{ name: "Transferred", name_translation_key: marker("word_transferred") },
		{ name: "Drying Loss", name_translation_key: marker("word_drying_loss") },
		{ name: "Destroyed", name_translation_key: marker("word_destroyed") },
		{
			name: "Lost/Theft",
			name_translation_key: marker("form_field_value_lost_theft"),
		},
		{
			name: "Lab Test",
			name_translation_key: marker("form_field_value_lab_test"),
		},
		{ name: "Returns", name_translation_key: marker("word_returns") },
		{
			name: "Opening Inventory",
			name_translation_key: marker("word_opening_inventory"),
		},
		{ name: "Other", name_translation_key: marker("word_other") },
	];

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			inventory_product_id: {
				type: "number",
				title: "Inventory Product",
				title_translation_key: marker("word_inventory_product"),
				widget: "data-select",
				quick_create: false,
				oneOf: [
					{
						result_type: "inventory_products",
						queryString: { non_inventory_destruction: "false" },
					},
				],
			},
			value: {
				widget: "number",
				type: "number",
				step: "0.00001",
				title: `Value`,
				title_translation_key: marker("word_value"),
			},
			inventory_unit_id: {
				type: "number",
				title: "Unit",
				title_translation_key: marker("word_unit"),
				widget: "data-select",
				related_properties: ["inventory_product_id"],
				quick_create: false,
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
			},
			reason: {
				type: "string",
				title: "Reason",
				title_translation_key: marker("word_reason"),
				widget: "select",
				oneOf: this.adjustment_reasons.map((item) => {
					return {
						name: item.name,
						name_translation_key: item.name_translation_key,
						value: item.name,
						enum: [item.name],
					};
				}),
			},
			reason_other: {
				type: "string",
				title: "Other Reason",
				title_translation_key: marker("form_field_label_other_reason"),
				visibleIf: {
					reason: ["Other"],
				},
				default: "",
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
			timestamp: {
				type: "string",
				title: "Date",
				title_translation_key: marker("word_date"),
				widget: "date",
			},
		},
		required: ["id", "reason", "value"],
	};

	validators = {};

	constructor(
		private readonly _injector: Injector,
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
	) {}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		this.measure_event = this._injector.get("measure_event", null);
		this.related_item = this._injector.get("related_item", "batch");
		this.related_item_id = this._injector.get(
			"related_item_id",
			this._injector.get("batch_id", null),
		);

		this.model = {
			id: this.measure_event.id,
			value: this.measure_event.value,
			timestamp: this.measure_event.timestamp,
		};

		const inventory_product_id =
			this._injector.get("inventory_product_id", null) ??
			this.measure_event.inventory?.inventory_product_id;
		if (inventory_product_id) {
			this.schema.properties.inventory_product_id = {
				...this.schema.properties.inventory_product_id,
				default: inventory_product_id,
				readOnly: true,
			};
		}
		const inventory_unit_id =
			this._injector.get("inventory_unit_id", null) ??
			this.measure_event.inventory?.inventory_unit_id;
		if (inventory_unit_id) {
			this.schema.properties.inventory_unit_id = {
				...this.schema.properties.inventory_unit_id,
				default: inventory_unit_id,
				readOnly: true,
			};
		}

		if (this.measure_event.notes && this.measure_event.notes[0]) {
			const note = this.measure_event.notes[0];

			const foundAdjustmentReasonIndex = findIndex(
				this.adjustment_reasons,
				(item) => item.name === note.subject,
			);

			if (foundAdjustmentReasonIndex >= 0) {
				this.model = {
					...this.model,
					reason: note.subject,
					description: note.content,
				};
			} else {
				this.model = {
					...this.model,
					reason: "Other",
					reason_other: note.subject,
					description: note.content,
				};
			}
		}

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const adjustment = {
			id: this.model.id,
			value: this.model.value,
			reason:
				this.model.reason === "Other"
					? this.model.reason_other || this.model.reason
					: this.model.reason,
			description: this.model.description,
			timestamp: this.model.timestamp,
		};
		this.updateEvent(adjustment);
	}

	onChanges(_form_data): void {}

	updateEvent(update_event: any) {
		this.loading$.next(true);
		let query = BatchDetailQuery;
		switch (this.related_item) {
			case "location":
				query = LocationDetailQuery;
				break;
			case "batch":
				query = BatchDetailQuery;
				break;
			case "inventory":
				query = InventoryDetailQuery;
				break;
			case "lot":
				query = LotDetailQuery;
				break;
		}
		query = {
			...query,
			result_type: this.related_item,
			result_type_id: `${this.related_item_id}`,
		};
		this._itemService
			.update(`measurement`, this.measure_event.id, update_event, query)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: this.pluralize(this.related_item),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}

	private pluralize(
		item: "lot" | "inventory" | "batch" | "location",
	): ItemKeyType {
		if (item === "batch") {
			return "batches";
		} else if (item === "location") {
			return "locations";
		} else if (item === "inventory") {
			return "inventories";
		}
		return "lots";
	}
}
