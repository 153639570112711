import { IApplicationMethod, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ApplicationMethodPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "application_methods";

export type State = Page<IApplicationMethod>;

export const reducer = createPagingReducer<IApplicationMethod>(reducerKey, {
	result_type: "application_methods",
	title: "Application Method",
	title_translation_key: marker("page_title_application_methods"),
	title_plural_translation_key: marker("page_title_plural_application_methods"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	query: ApplicationMethodPageQuery,
});
