import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Injector,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable } from "rxjs";
import { take } from "rxjs/operators";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { UserProfile } from "@elevatedsignals/amygoodman";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";

@Component({
	selector: "view-form-page",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<selected-form-page
			[items]="items"
			[type]="type"
			[recordLabelSize]="recordLabelSize$ | async"
		></selected-form-page>
	`,
	styles: [":host { display: block }"],
})
export class ViewFormPageComponent implements OnInit {
	items: any;
	type: any;
	user$: Observable<UserProfile | null> = this._store.select(
		fromDashboard.getProfile,
	);

	recordLabelSize$: BehaviorSubject<string> = new BehaviorSubject<string>(
		".92857143em",
	);

	private document_set_id: number;
	private work_order_id: number;
	private purchase_order_id: number;

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _injector: Injector,
	) {}

	ngOnInit() {
		const id = this._injector.get("schema_id", null);
		this.type = this._injector.get("type", null);
		this.items = this._injector.get("items", null);

		if (id) {
			this._store.dispatch(
				SelectionActions.select({
					id,
					result_type: "quick_records",
				}),
			);
		}

		this.document_set_id = this._injector.get("document_set_id", null);
		this.work_order_id = this._injector.get("work_order_id", null);
		this.purchase_order_id = this._injector.get("purchase_order_id", null);

		this.user$.pipe(isNotNullOrUndefined(), take(1)).subscribe((user) => {
			const labelSize = user.settings?.RECORDS_LABEL_SIZE ?? "regular";
			this.recordLabelSize$.next(
				labelSize === "regular" ? ".92857143em" : labelSize,
			);
		});
	}
}
