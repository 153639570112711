import { Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "custom_scans";

export type State = Page<any>;

export const reducer = createPagingReducer<any>(reducerKey, {
	result_type: "custom_scans",
	title: "Custom Scan",
	title_plural: "Custom Scans",
	title_translation_key: marker("page_title_custom_scans"),
	title_plural_translation_key: marker("page_title_plural_custom_scans"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [],
	query: {},
});
