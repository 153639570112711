import {
	ChangeDetectorRef,
	Component,
	Injector,
	OnDestroy,
	OnInit,
} from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { Store } from "@ngrx/store";
import { ISeedLot, ISelectListItem } from "@elevatedsignals/amygoodman";
import { InventoryDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { EMPTY, ReplaySubject } from "rxjs";
import { catchError, timeout, takeUntil } from "rxjs/operators";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";

import { GenericCreateComponent } from "../../../generic/generic-create.component";

export enum AdjustmentType {
	New = "NEW",
	Addition = "ADD",
	Subtraction = "SUBTRACT",
}

@Component({
	selector: "inventory-detail-measure-event-create",
	templateUrl: "../../../form-view.component.html",
	styleUrls: ["../../../sidenav.scss"],
})
export class CreateInventoryDetailMeasureEventComponent
	extends GenericCreateComponent<ISeedLot>
	implements OnInit, OnDestroy
{
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Create Adjustment";
	form_title_translation_key = marker("form_title_create_adjustment");
	submit_button = "Add Adjustment";
	submit_button_translation_key = marker("form_button_add_adjustment");
	submit_icon = "plus";

	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};
	adjustment_reasons: { name: string; name_translation_key?: string }[] = [
		{ name: "Harvested", name_translation_key: marker("word_harvested") },
		{ name: "Dried", name_translation_key: marker("word_dried") },
		{
			name: "Processing Loss",
			name_translation_key: marker("word_processing_loss"),
		},
		{ name: "Packaged", name_translation_key: marker("word_packaged") },
		{ name: "Transferred", name_translation_key: marker("word_transferred") },
		{ name: "Drying Loss", name_translation_key: marker("word_drying_loss") },
		{ name: "Destroyed", name_translation_key: marker("word_destroyed") },
		{
			name: "Lost/Theft",
			name_translation_key: marker("form_field_value_lost_theft"),
		},
		{
			name: "Lab Test",
			name_translation_key: marker("form_field_value_lab_test"),
		},
		{ name: "Returns", name_translation_key: marker("word_returns") },
		{
			name: "Opening Inventory",
			name_translation_key: marker("word_opening_inventory"),
		},
		{ name: "Other", name_translation_key: marker("word_other") },
	];

	adjustment_types: ISelectListItem[] = [
		{
			name: "Addition",
			name_translation_key: marker("word_addition"),
			value: AdjustmentType.Addition,
			enum: [AdjustmentType.Addition],
		},
		{
			name: "Subtraction",
			name_translation_key: marker("word_subtraction"),
			value: AdjustmentType.Subtraction,
			enum: [AdjustmentType.Subtraction],
		},
	];

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			inventory_product_id: {
				title: "Product",
				hidden: true,
				type: "number",
				widget: "data-select",
				quick_create: false,
				readOnly: true,
				oneOf: [
					{
						result_type: "inventory_products",
					},
				],
			},
			inventory_id: {
				type: "number",
				title: "Inventory",
				title_translation_key: marker("word_inventory"),
				widget: "data-select",
				oneOf: [{ result_type: "inventory" }],
				readOnly: true,
			},

			adjustment_type: {
				type: "string",
				title: "Adjustment Type",
				title_translation_key: marker("form_field_label_adjustment_type"),
				widget: "select",
				oneOf: this.adjustment_types,
				default: AdjustmentType.Addition,
			},
			value: {
				widget: "number",
				type: "number",
				step: "1",
				title: `Value`,
				title_translation_key: marker("word_value"),
				width: "50%",
			},
			inventory_unit_id: {
				type: "number",
				title: "Unit",
				title_translation_key: marker("word_unit"),
				widget: "data-select",
				quick_create: false,
				shorter_placeholder: true,
				related_properties: ["inventory_product_id"],
				selectFirstAndOnly: true,
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
				width: "50%",
			},
			reason: {
				type: "string",
				title: "Reason",
				title_translation_key: marker("word_reason"),
				widget: "select",
				oneOf: this.adjustment_reasons.map((item) => ({
					name: item.name,
					name_translation_key: item.name_translation_key,
					value: item.name,
					enum: [item.name],
				})),
			},
			reason_other: {
				type: "string",
				title: "Other Reason",
				title_translation_key: marker("form_field_label_other_reason"),
				visibleIf: {
					reason: ["Other"],
				},
				default: "",
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
			timestamp: {
				type: "string",
				title: "Date",
				widget: "date",
			},
		},
		required: ["adjustment_type", "value", "reason"],
	};

	validators = {};

	constructor(
		private readonly _injector: Injector,
		_store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
	) {
		super(_store);
	}

	valid(valid) {
		this.valid$.next(valid);
	}

	ngOnInit() {
		const inventory_id = this._injector.get("inventory_id", null);
		const inventory_product_id = this._injector.get("inventory_product_id", null);
		const inventory_unit_id = this._injector.get("inventory_unit_id", null);

		this.model = {
			...this.model,
			inventory_id,
			inventory_product_id,
			inventory_unit_id,
		};

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	createItem(model) {
		let value = model.value;
		switch (this.model.adjustment_type) {
			case AdjustmentType.Addition:
				if (value < 0) {
					value *= -1;
				}
				break;
			case AdjustmentType.Subtraction:
				if (value > 0) {
					value *= -1;
				}
				break;
			default:
				break;
		}
		const adjustment = {
			value,
			reason:
				this.model.reason === "Other"
					? this.model.reason_other || this.model.reason
					: this.model.reason,
			description: this.model.description,
			timestamp: this.model.timestamp,
			inventory_unit_id: this.model.inventory_unit_id,
			inventory_product_id: this.model.inventory_product_id,
		};
		this.createMeasureEvent(model.inventory_id, adjustment);
	}

	createMeasureEvent(inventoryId: number, measureEvent: any) {
		this.loading$.next(true);
		this._itemService
			.add(
				`inventory/${inventoryId}/measurements`,
				measureEvent,
				InventoryDetailQuery,
			)
			.pipe(
				takeUntil(this.destroyed$),
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((updatedItem) => {
				this._store.dispatch(
					ItemActions.updateSuccess({
						updatedItem,
						result_type: "inventories",
					}),
				);
				this.loading$.next(false);
				this.closeSidenav();
			});
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
