import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import {
	timeout,
	catchError,
	take,
	takeUntil,
	tap,
	filter,
} from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import { ISopCategory } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "sop-category-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class SopCategoryUpdateComponent
	extends GenericUpdateComponent<ISopCategory>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: SOP Category name already in use.",
				warning_translation_key: marker(
					"form_field_warning_sop_category_name_already_in_use",
				),
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
		},
		required: ["name"],
	};

	sopCategory$ = this._store.select(fromDashboard.getSelectedSopCategory);
	sopCategory: ISopCategory;
	id: number;

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, _property, _form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private names;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.form_title = "Update a SOP Category";
		this.form_title_translation_key = marker("form_title_update_a_sop_category");
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
		this.id = this._injector.get("id", null);
	}

	ngOnInit() {
		this.sopCategory$
			.pipe(
				takeUntil(this.destroyed$),
				isNotNullOrUndefined(),
				filter(
					(sopCategory): sopCategory is ISopCategory => sopCategory.id === this.id,
				),
				take(1),
			)
			.subscribe((sopCategory) => {
				this.sopCategory = sopCategory;

				this.model.id = this.sopCategory.id;
				this.model.name = this.sopCategory.name;
				this.model.archived = this.sopCategory.archived;
				this._cd.detectChanges();
				this.getNames();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	// Used for schema validation - duplicate name check
	getNames() {
		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`sop_categories/fetch/names`, "", {
				col: "name",
				except: this.model.name,
			})
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	updateItem(sopCategoryUpdate: Partial<ISopCategory>) {
		this.loading$.next(true);
		this._itemService
			.update(`sop_category`, sopCategoryUpdate.id!, sopCategoryUpdate)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "sop_categories",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
