import { ISelectListItem, MeasureUnit } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

export const InventoryTypes: ISelectListItem[] = [
	{
		name: "Weight",
		name_translation_key: marker("word_weight"),
		value: MeasureUnit.Mass,
		enum: [MeasureUnit.Mass],
	},
	{
		name: "Plants",
		name_translation_key: marker("word_plants"),
		value: "PLANTS",
		enum: ["PLANTS"],
	},
	{
		name: "Quantity",
		name_translation_key: marker("word_quantity"),
		value: MeasureUnit.Quantity,
		enum: [MeasureUnit.Quantity],
	},
	{
		name: "Volume",
		name_translation_key: marker("word_volume"),
		value: MeasureUnit.Volume,
		enum: [MeasureUnit.Volume],
	},
];
