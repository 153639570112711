import {
	HeaderQueryType,
	IProductionPlan,
	Page,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ProductionPlanPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "production_plans";

export type State = Page<IProductionPlan>;

export const reducer = createPagingReducer<IProductionPlan>(reducerKey, {
	result_type: "production_plans",
	title: "Production Plan",
	title_plural: "ProductionPlans",
	title_translation_key: marker("page_title_production_plans"),
	title_plural_translation_key: marker("page_title_plural_production_plans"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Show Archived",
			key: "showArchived",
			title_translation_key: marker("checkbox_filter_show_archived"),
			type: HeaderQueryType.checkbox,
			value: false,
		},
	],
	query: ProductionPlanPageQuery,
});
