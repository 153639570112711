import { Hub, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "hubs";

export type State = Page<Hub>;

export const reducer = createPagingReducer<Hub>(reducerKey, {
	result_type: "hubs",
	title: "Hub",
	title_translation_key: marker("page_title_hubs"),
	title_plural_translation_key: marker("page_title_plural_hubs"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
});
