/* jscpd:ignore-start */
import { marker } from "@jsverse/transloco-keys-manager/marker";

export const InventoryDestructionReweighSchema = {
	title: "",
	description: "",
	info: "",
	properties: {
		group: {
			type: "string",
			hidden: true,
		},

		inventory_unit_id: {
			readOnly: true,
			title: "Inventory Unit",
			title_translation_key: marker("word_inventory_unit"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			oneOf: [{ result_type: "inventory_units" }],
		},
		quantity: {
			readOnly: true,
			type: "number",
			title: `Quantity`,
			title_translation_key: marker("word_quantity"),
		},
		reweigh_value: {
			type: "number",
			widget: "number",
			title: `Reweigh Quantity`,
			title_translation_key: marker("form_field_label_reweigh_quantity"),
		},
		timestamp: {
			type: "string",
			title: "Timestamp",
			title_translation_key: marker("word_timestamp"),
			widget: "date",
		},
	},
	required: ["group", "reweigh_value"],
};
/* jscpd:ignore-end */
