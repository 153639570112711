import { createFeatureSelector, createSelector } from "@ngrx/store";
import { DashboardState } from "app/modules/dashboard/state/dashboard.state";

export const dashboardFeatureKey = "dashboardV2";

export const getDashboardState =
	createFeatureSelector<DashboardState>(dashboardFeatureKey);
// Facilities
export const getFacilitySettings = createSelector(
	getDashboardState,
	(entities) => {
		return entities.facilitySettings.settings;
	},
);
