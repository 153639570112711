import { tap, take, takeUntil, timeout, catchError } from "rxjs/operators";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { Store } from "@ngrx/store";
import { handleObservableError } from "app/shared/utils";
import { ReplaySubject, EMPTY } from "rxjs";
import { Batch } from "@elevatedsignals/amygoodman";
import { BatchDetailQuery } from "app/shared/eagers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { Globals } from "app/shared/modules/globals/globals.service";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

@Component({
	selector: "batch-reopen",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class BatchReopenComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	batch$ = this._store.select(fromDashboard.getSelectedBatch);
	batch: Batch;

	form_title = "Reopen an archived Batch";
	form_title_translation_key: string = marker(
		"form_title_reopen_an_archived_batch",
	);

	submit_button = "Reopen Batch";
	submit_button_translation_key: string = marker("form_button_reopen_batch");
	submit_icon = "edit";

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			timestamp: {
				type: "string",
				title: "Date/time batch reopened.",
				title_translation_key: marker("form_field_label_date_time_batch_reopened"),
				widget: "date",
				hidden: false,
			},
		},
	};

	validators = {};

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
	) {}

	ngOnInit() {
		this.batch$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((batch: Batch) => {
				this.batch = batch;
				this.addLocationId(batch.location_id);
			});

		if (
			this._globals.gmp_enabled &&
			this.schema.properties.timestamp.hidden === false
		) {
			this.schema.properties.timestamp.hidden = true;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const batch: Partial<Batch> = {
			...this.model,
			archived: false,
		};
		this.reopenBatch(batch);
	}

	onChanges(e): void {
		// empty
	}

	valid(v) {
		this.valid$.next(v);
	}

	reopenBatch(update_batch: Partial<Batch>) {
		this.loading$.next(false);
		this._itemService
			.update(`batch`, Number(this.batch.id), update_batch, BatchDetailQuery)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "batches",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}

	// if batch has location_id don't show location_id selector
	// This is to support the old way of handling reopens.
	private addLocationId(location_id?: number | null) {
		if (!location_id) {
			this.schema.properties = {
				location_id: {
					type: "number",
					title: "Select Batch Location",
					title_translation_key: marker("form_field_label_select_batch_location"),
					widget: "data-select",
					oneOf: [
						{
							result_type: "locations",
						},
					],
				},
				timestamp: this.schema.properties.timestamp,
			};

			this.schema.required = ["location_id"];
		}
	}
}
