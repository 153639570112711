import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	OnDestroy,
	ChangeDetectorRef,
	Injector,
	OnInit,
} from "@angular/core";
import { PurchaseOrderDetailQuery } from "app/shared/eagers";
import { IInventory, IMeasureEvent } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "purchase-order-inventory-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PurchaseOrderInventoryUpdateComponent
	extends GenericUpdateComponent<IInventory>
	implements OnDestroy, OnInit
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			purchase_order_id: {
				type: "number",
				title: "Purchase Order",
				title_translation_key: marker("word_purchase_order"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "purchase_orders",
					},
				],
			},
			inventory_product_id: {
				type: "number",
				title: "Product",
				title_translation_key: marker("word_product"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "inventory_products",
					},
				],
			},
			inventory_unit_id: {
				type: "number",
				title: "Unit",
				title_translation_key: marker("word_unit"),
				widget: "data-select",
				related_properties: ["inventory_product_id"],
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
			},
			destination: {
				type: "string",
				title: "Destination",
				title_translation_key: marker("word_destination"),
				widget: "select",
				oneOf: [
					{
						value: "Batch",
						enum: ["Batch"],
						name: "Batch",
						name_translation_key: marker("word_batch"),
					},
					{
						value: "Location",
						enum: ["Location"],
						name: "Location",
						name_translation_key: marker("word_location"),
					},
				],
			},
			location_id: {
				type: "number",
				title: "Location",
				title_translation_key: marker("word_location"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "locations",
					},
				],
				visibleIf: {
					destination: ["Location"],
				},
			},
			batch_id: {
				type: "number",
				title: "Source Batch",
				title_translation_key: marker("form_field_label_source_batch"),
				widget: "data-select",
				quick_create: false,
				oneOf: [
					{
						result_type: "batches",
						queryString: {
							input: "true",
						},
					},
				],
				visibleIf: {
					destination: ["Batch"],
				},
			},
			value: {
				widget: "number",
				type: "number",
				step: "1",
				title: `Quantity`,
				title_translation_key: marker("word_quantity"),
			},
			timestamp: {
				type: "string",
				title: "Date",
				title_translation_key: marker("word_date"),
				widget: "date",
			},
		},
		required: [
			"id",
			"purchase_order_id",
			"inventory_product_id",
			"inventory_unit_id",
			"value",
		],
	};

	private purchase_order_id: number;
	private inventory: IInventory;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.form_title = "Update Inventory in a Purchase Order";
		this.form_title_translation_key = marker(
			"form_title_update_inventory_in_a_purchase_order",
		);
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
	}

	ngOnInit() {
		this.purchase_order_id = this._injector.get("purchase_order_id", undefined);
		this.inventory = this._injector.get("inventory", null);
		this.model.purchase_order_id = this.purchase_order_id;
		this.model.id = this.inventory.id;
		this.model.inventory_product_id = this.inventory.inventory_product_id;
		this.model.inventory_unit_id = this.inventory.inventory_unit_id;

		if (this.inventory.batch_id) {
			this.model.destination = "Batch";
		}

		if (this.inventory.location_id) {
			this.model.destination = "Location";
		}

		this.model.location_id = this.inventory.location_id;
		this.model.batch_id = this.inventory.batch_id;
		const measure_event: IMeasureEvent | undefined =
			this.inventory.measurements!.length > 0
				? this.inventory.measurements![0]
				: undefined;

		this.model.value = measure_event?.value;
		this.model.timestamp = measure_event ? measure_event.timestamp : undefined;

		if (this.purchase_order_id) {
			this.schema.properties.purchase_order_id = {
				...this.schema.properties.purchase_order_id,
				default: this.purchase_order_id,
				readOnly: true,
			};
		}

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(inventory) {
		this.loading$.next(true);
		this._itemService
			.update(
				`purchase_order/${this.purchase_order_id}/inventory`,
				inventory.id,
				inventory,
				PurchaseOrderDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "purchase_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
