import { IFacility } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.Facilities;

export type State = SelectionState<IFacility>;

export const reducer = createSelectionReducer<IFacility>(reducerKey);
