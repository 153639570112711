import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, take, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, ChangeDetectorRef, OnDestroy, OnInit } from "@angular/core";
import { IPrinter } from "@elevatedsignals/amygoodman";
import { PrinterDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "printer-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PrinterUpdateComponent
	extends GenericUpdateComponent<IPrinter>
	implements OnDestroy, OnInit
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			section_0: {
				type: "string",
				widget: "section",
				title: "Printer Info",
				title_translation_key: marker("form_field_label_printer_info"),
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			section_1: {
				type: "string",
				widget: "section",
				title: "Printer Settings",
				title_translation_key: marker("form_field_label_printer_settings"),
			},
			default_template_id: {
				type: "number",
				widget: "data-select",
				title: "Default Printer Job Template",
				title_translation_key: marker(
					"form_field_label_default_printer_job_template",
				),
				oneOf: [
					{
						result_type: "printer_job_templates",
					},
				],
			},
			labels_per_row: {
				type: "string",
				title: "Labels per Row",
				title_translation_key: marker("form_field_label_labels_per_row"),
				widget: "select",
				oneOf: [
					{
						value: "1",
						enum: ["1"],
						name: "1",
					},
					{
						value: "2",
						enum: ["2"],
						name: "2",
					},
					{
						value: "4",
						enum: ["4"],
						name: "4",
					},
					{
						value: "5",
						enum: ["5"],
						name: "5",
					},
				],
			},
			section_2: {
				type: "string",
				widget: "section",
				title: "Network Settings",
				title_translation_key: marker("form_field_label_network_settings"),
			},
			ip: {
				type: "string",
				title: "IP",
				title_translation_key: marker("acronym_ip"),
			},
			port: {
				type: "string",
				title: "Port",
				title_translation_key: marker("word_port"),
			},
			// TODO -- Validate with team; should status be editable?
			// status: {
			// 	type: "string",
			// 	title: "Status",
			// },
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
		},
		required: ["name"],
	};

	printer$ = this._store.select(fromDashboard.getSelectedPrinter);
	printer: IPrinter;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
	) {
		super(_store, _cd);
		this.form_title = "Update a printer";
		this.form_title_translation_key = marker("form_title_update_a_printer");
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.printer$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((printer: IPrinter) => {
				this.printer = printer;

				this.model.id = this.printer.id;
				this.model.name = this.printer.name;
				this.model.serial_number = this.printer.status;
				this.model.labels_per_row = this.printer.labels_per_row;
				this.model.archived = this.printer.archived;
				this.model.ip = this.printer.ip;
				this.model.port = this.printer.port;
				this.model.default_template_id = this.printer.default_template_id;
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(type: Partial<IPrinter>) {
		this.loading$.next(true);
		this._itemService
			.update(`printer`, type.id!, type, PrinterDetailQuery)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "printers",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
