import { Store } from "@ngrx/store";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	Injector,
	OnInit,
	OnDestroy,
} from "@angular/core";
import { WorkOrderService } from "app/modules/dashboard/services/work-order.service";
import { Globals } from "app/shared/modules/globals/globals.service";
import { IWorkOrderOutput, MeasureUnit } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

// @deprecated
@Component({
	selector: "work-order-harvest-output-ez-gi",
	templateUrl: "../../form-view.component.html",
	styleUrls: ["../../sidenav.scss"],
})
export class WorkOrderHarvestOutputEzGiComponent
	extends GenericUpdateComponent<{
		id: number;
		batch_id: number;
		work_order_id: boolean;
		weight: number;
	}>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			batch_id: {
				type: "number",
				title: "Destination Batch",
				title_translation_key: marker("form_field_label_destination_batch"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "batches",
						queryString: {
							input: "true",
							dont_show_expired: "true",
						},
					},
				],
				readOnly: false,
			},
			value: {
				type: "number",
				title: `Weight (${this._globals.getFacilityUnit(MeasureUnit.Mass)})`,
				title_translation_key: marker("form_field_label_value"),
				title_translation_params: {
					units: this._globals.getFacilityUnit(MeasureUnit.Mass),
				},
			},
			timestamp: {
				type: "string",
				title: "Date",
				title_translation_key: marker("word_date"),
				widget: "date",
			},
		},
		required: ["batch_id", "value"],
	};

	// Injected from other component
	private batch_id: number;
	private work_order_id: number;
	private duplicate: IWorkOrderOutput | null = null;

	private readonly duplicate_timestamp: boolean = false;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _workOrderService: WorkOrderService,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.form_title = "Record Fresh Cannabis";
		this.form_title_translation_key = marker("form_title_record_fresh_cannabis");
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";

		const use_work_order_location_output = this._injector.get(
			"use_work_order_location_output",
			false,
		);
		const work_order_location_id = this._injector.get(
			"work_order_location_id",
			null,
		);

		if (use_work_order_location_output) {
			this.model.location_id = work_order_location_id;
		}

		this.duplicate_timestamp = this._injector.get("duplicate_timestamp", false);
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.batch_id = this._injector.get("batch_id", null);
		this.work_order_id = this._injector.get("work_order_id", null);

		this.model.batch_id = this.batch_id;

		const duplicate: IWorkOrderOutput | null = this._injector.get(
			"duplicate",
			null,
		);
		this.duplicate = duplicate;

		if (duplicate) {
			this.model = {
				...this.model,
				inventory_product_id: duplicate.inventory.inventory_product_id,
				batch_id: duplicate.batch_id,
			};
			if (
				duplicate.inventory.measurements &&
				duplicate.inventory.measurements.length > 0
			) {
				this.model = {
					...this.model,
					value: -1 * duplicate.inventory.measurements[0]!.value,
					timestamp: duplicate.inventory.measurements[0]!.timestamp,
				};
			}
			this.batch_id = duplicate.batch_id!;
			this.work_order_id = duplicate.work_order_id;

			// clear timestamp for non GMP
			if (
				!this._globals.gmp_enabled &&
				this.schema.properties.timestamp &&
				!this.duplicate_timestamp
			) {
				delete this.model.timestamp;
			}
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {}

	updateItem(work_order_output: {
		batch_id: number;
		value: number;
		timestamp?: Date;
	}) {
		this.loading$.next(true);
		const input: any = {
			...work_order_output,
			work_order_id: this.work_order_id,
			batch_id: work_order_output.batch_id
				? work_order_output.batch_id
				: this.batch_id,
			timestamp: work_order_output.timestamp ?? new Date(),
			type: "Inventory",
		};

		this._workOrderService
			.addOutputs(input)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
