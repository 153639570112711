import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { IMeasureEvent } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import {
	BatchDetailQuery,
	InventoryDetailQuery,
	LotDetailQuery,
	SeedLotDetailQuery,
} from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { EMPTY, ReplaySubject } from "rxjs";
import { catchError, timeout, takeUntil, tap } from "rxjs/operators";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { ItemKeyType } from "app/modules/dashboard/reducers/selection/keys";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";

@Component({
	selector: "measure-event-delete",
	template: `
		<div class="ui grid" *transloco="let t">
			<div class="sixteen wide column">
				<div class="left floated left aligned eight wide column">
					<button class="ui labeled icon button" (click)="closeSidenav()">
						<i class="left arrow icon"></i>
						{{ "word_cancel" | transloco }}
					</button>
				</div>
			</div>
			<div class="sixteen wide column">
				<h2>
					{{
						form_title_translation_key ? t(form_title_translation_key) : form_title
					}}
				</h2>
			</div>

			<div class="sixteen wide column" *ngIf="error$ | async">
				<div class="ui error message">
					<span>{{ error$ | async }}</span>
				</div>
			</div>

			<div class="sixteen wide column">
				<button
					class="ui"
					[ngClass]="
						(loading$ | async) ? 'loading button disabled blue' : 'button blue'
					"
					(click)="onSubmit()"
				>
					<span
						><i *ngIf="submit_icon" class="{{ submit_icon }} icon"></i
						>{{
							submit_button_translation_key
								? t(submit_button_translation_key)
								: submit_button
						}}</span
					>
				</button>
			</div>
		</div>
	`,
	styleUrls: ["../sidenav.scss"],
})
export class DeleteMeasureEventComponent implements OnInit, OnDestroy {
	measure_event: IMeasureEvent;
	related_item: "batch" | "lot" | "seed_lot" | "inventory" = "batch";
	related_item_id: number;

	form_title = "Are you sure you want to delete this measure event?";
	form_title_translation_key: string = marker(
		"form_title_are_you_sure_you_want_to_delete_this_measure_event",
	);

	submit_button = "Yes";
	submit_button_translation_key: string = marker("word_yes");
	submit_icon = "bomb";

	error$: ReplaySubject<string> = new ReplaySubject();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	loading_error$: ReplaySubject<string> = new ReplaySubject<string>();
	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _injector: Injector,
		private readonly _itemService: ItemService,
	) {}

	ngOnInit() {
		this.measure_event = this._injector.get("measure_event", null);
		this.related_item = this._injector.get("related_item", "batch");
		this.related_item_id = this._injector.get(
			"related_item_id",
			this._injector.get("batch_id", null),
		);
	}

	onSubmit() {
		this.deleteEvent();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}

	deleteEvent() {
		this.loading$.next(true);
		let query = BatchDetailQuery;
		switch (this.related_item) {
			case "seed_lot":
				query = SeedLotDetailQuery;
				break;
			case "batch":
				query = BatchDetailQuery;
				break;
			case "inventory":
				query = InventoryDetailQuery;
				break;
			case "lot":
				query = LotDetailQuery;
				break;
		}
		query = {
			...query,
			result_type: this.related_item,
			result_type_id: `${this.related_item_id}`,
		};
		this._itemService
			.delete(`measurement`, this.measure_event.id, query)
			.pipe(
				takeUntil(this.destroyed$),
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: this.pluralize(this.related_item),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	private pluralize(
		item: "batch" | "seed_lot" | "inventory" | "lot",
	): ItemKeyType {
		if (item === "batch") {
			return "batches";
		} else if (item === "seed_lot") {
			return "seed_lots";
		} else if (item === "inventory") {
			return "inventories";
		}
		return "lots";
	}
}
