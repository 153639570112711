import { IPrinterJob } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.PrinterJobs;

export type State = SelectionState<IPrinterJob>;

export const reducer = createSelectionReducer<IPrinterJob>(reducerKey);
