export const GeneralBatchActionSchemaProperties = {
	equipment_ids: {
		type: "array",
		title: "Equipments",
		widget: "data-select",
		items: {
			type: "number",
			oneOf: [
				{
					result_type: "equipments",
					text_key: ["name", "serial_number"],
					text_format: "?1 #?2",
					queryString: {
						input: "true",
					},
				},
			],
		},
	},
	record_ids: {
		type: "array",
		title: "Records",
		widget: "data-select",
		quick_create: false,
		items: {
			type: "number",
			oneOf: [
				{
					result_type: "records",
					text_key: ["id", "form_schema_name"],
					text_format: "#?1 ?2",
					queryString: {
						input: "true",
					},
				},
			],
		},
	},
};
