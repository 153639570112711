import {
	ISubstanceType,
	IWorkOrder,
	MeasureUnit,
} from "@elevatedsignals/amygoodman";
import { isAfter } from "date-fns";

export const maxPlantNumber = 25000;

export const workOrderIsFromPurchaseOrder = (
	work_order: IWorkOrder,
): boolean => {
	return Boolean(work_order.purchaseOrderId);
};

export const workOrderIsClosed = (work_order: IWorkOrder): boolean => {
	return work_order.close_action_id !== null;
};

export const workOrderIsOpen = (work_order: IWorkOrder): boolean => {
	const openDateInFuture = hasOpenDateInFuture(work_order);
	if (openDateInFuture) {
		return false;
	}

	return (
		work_order.close_action_id === null && work_order.open_action_id !== null
	);
};

export const hasOpenDateInFuture = (work_order: IWorkOrder): boolean => {
	if (work_order.open_action_id && work_order.open_action?.timestamp) {
		return isAfter(new Date(work_order.open_action.timestamp), new Date());
	}
	return false;
};

// @deprecated
export const getRemainingSubstanceTypes = (
	work_order: IWorkOrder,
): ISubstanceType[] => {
	const result: Record<number, ISubstanceType> = {};

	if (!work_order.manual_processing_loss || !workOrderIsClosed(work_order)) {
		if (work_order.destruction_events) {
			for (const event of work_order.destruction_events) {
				if (
					event.measure_event?.substance_type &&
					event.measure_event.substance_type_id
				) {
					result[event.measure_event.substance_type_id] =
						event.measure_event.substance_type;
				}
			}
		}
		if (work_order.sources) {
			for (const event of work_order.sources) {
				if (
					event.measure_event?.substance_type &&
					event.measure_event.substance_type_id
				) {
					result[event.measure_event.substance_type_id] =
						event.measure_event.substance_type;
				}
			}
		}
		if (work_order.outputs) {
			for (const event of work_order.outputs) {
				if (
					event.measure_event?.substance_type &&
					event.measure_event.substance_type_id
				) {
					result[event.measure_event.substance_type_id] =
						event.measure_event.substance_type;
				}
			}
		}
	}
	if (work_order.processing_losses && work_order.manual_processing_loss) {
		const processingLossSubstanceType =
			work_order.processing_losses[0]?.measure_event.substance_type;

		if (processingLossSubstanceType) {
			result[processingLossSubstanceType.id] = processingLossSubstanceType;
		}
	}
	return Object.values(result);
};

export const getRemainingMeasureUnits = (
	work_order?: IWorkOrder,
	substance_type_id?: number,
): MeasureUnit[] => {
	if (work_order) {
		const measure_events = [
			...(work_order.sources ?? []),
			...(work_order.outputs ?? []),
			...(work_order.manual_processing_loss
				? work_order.processing_losses ?? []
				: []),
			...(work_order.destruction_events ?? []),
		]
			// @deprecated
			.filter(
				(item) =>
					item.measure_event &&
					(!substance_type_id ||
						item.measure_event.substance_type_id === substance_type_id),
			)
			.map((item) => item.measure_event!)
			.reduce((result, event) => {
				result[event.measure_unit] = event.measure_unit;
				return result;
			}, {});
		return Object.values(measure_events);
	}

	return [];
};
