import { IWorkOrder } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import {
	timeout,
	catchError,
	takeUntil,
	tap,
	map,
	combineLatestWith,
	take,
} from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, OnDestroy, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import {
	generalInventoryEnabled,
	getSelectedFacilitySettings,
} from "app/modules/dashboard/selectors/facility-settings.selector";
import { LicenceType } from "app/modules/dashboard/state/facilitySettings.state";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "work-order-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderCreateComponent
	extends GenericCreateComponent<IWorkOrder>
	implements OnDestroy
{
	returnTo: string[];

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	giEnabled$ = this._store.select(generalInventoryEnabled);
	facilitySettings$ = this._store.select(getSelectedFacilitySettings);

	showBillOfMaterialSection$ = this.facilitySettings$.pipe(
		map((settings) => {
			return (
				settings.licence[LicenceType.Cultivation] === true ||
				settings.licence[LicenceType.Processing] === true
			);
		}),
		combineLatestWith(this.giEnabled$),
		map(([res, giEnabled]) => {
			return giEnabled && res;
		}),
	);

	ezGiMigrated = false;
	private names;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _router: Router,
		private readonly injector: Injector,
	) {
		super(_store);
		this.form_title = "Create a Work Order";
		this.form_title_translation_key = marker("form_title_create_a_work_order");
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");

		const workOrderTypeId = this.injector.get("workOrderTypeId", null);
		const billOfMaterialId = this.injector.get("billOfMaterialId", null);
		this.returnTo = this.injector.get("returnTo", ["dashboard", "work_orders"]);
		this.ezGiMigrated = this.injector.get("ez_gi_migrated", null);

		this.model = {
			work_order_type_id: workOrderTypeId ? workOrderTypeId : undefined,
			bill_of_material_id: billOfMaterialId ? billOfMaterialId : undefined,
		};

		this.schema = {
			title: "",
			description: "",
			info: "",
			properties: {
				work_order_type_id: {
					type: "number",
					title: "Work Order Type",
					title_translation_key: marker("word_work_order_type"),
					widget: "data-select",
					oneOf: [
						{
							result_type: "work_order_types",
							queryString: {
								not_special_filter: true,
							},
						},
					],
					readOnly: Boolean(workOrderTypeId),
				},
				bill_of_material_id: {
					type: "number",
					title: "Bill Of Material",
					title_translation_key: marker("word_bill_of_material"),
					widget: "data-select",
					related_properties: ["work_order_type_id"],
					oneOf: [
						{
							result_type: "bill_of_materials",
						},
					],
					readOnly: Boolean(billOfMaterialId),
				},
				bill_of_material_multiplier: {
					type: "number",
					title: "Bill Of Material Multiplier",
					title_translation_key: marker(
						"form_field_label_bill_of_material_multiplier",
					),
					widget: "number",
					default: 1,
					step: 0.01,
					min: 0.01,
					visibleIf: {
						bill_of_material_id: ["$ANY$"],
					},
				},
				name: {
					type: "string",
					title: "Name",
					title_translation_key: marker("word_name"),
					warning: "Warning: Work Order name already in use.",
					warning_translation_key: marker(
						"form_field_warning_work_order_name_already_in_use",
					),
				},
				description: {
					type: "string",
					widget: "textarea",
					title: "Description",
					title_translation_key: marker("word_description"),
				},
			},
			required: ["work_order_type_id"],
		};
	}

	ngOnInit() {
		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`work_orders/fetch/names`, "", { col: "name" })
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
		this.showBillOfMaterialSection$.subscribe((show) => {
			if (
				this.schema.properties.bill_of_material_id &&
				(!show || this.ezGiMigrated)
			) {
				delete this.schema.properties.bill_of_material_id;
			}
		});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	createItem(work_order: Partial<IWorkOrder>) {
		this.loading$.next(true);
		this._itemService
			.add(`work_orders`, work_order)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							addedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
					this._router.navigate(this.returnTo);
				}),
			)
			.subscribe();
	}
}
