import { Component, OnInit, OnDestroy, Injector } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { handleObservableError } from "app/shared/utils";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { ReplaySubject, EMPTY } from "rxjs";
import { ISopCategory } from "@elevatedsignals/amygoodman";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "sop-category-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class SopCategoryCreateComponent
	extends GenericCreateComponent<ISopCategory>
	implements OnDestroy
{
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Create a SOP Category";
	form_title_translation_key: string = marker(
		"form_title_create_a_sop_category",
	);

	submit_button = "Create SOP Category";
	submit_button_translation_key: string = marker(
		"form_button_create_sop_category",
	);

	submit_icon = "add";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	specific_field_id: string | null;

	model: any = {};

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: SOP Category name already in use.",
				warning_translation_key: marker(
					"form_field_warning_sop_category_name_already_in_use",
				),
			},
		},
		required: ["name"],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private names;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store);
		this.specific_field_id = this._injector.get("specific_field_id", null);
	}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`sop_categories/fetch/names`, "", { col: "name" })
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				this.names = value;
			});
		this.loading$.next(false);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		this.createItem(this.model);
	}

	onChanges(e): void {
		// empty
	}

	createItem(newSopCategory: ISopCategory) {
		this.loading$.next(true);
		this._itemService
			.add("sop_categories", newSopCategory)
			.pipe(
				takeUntil(this.destroyed$),
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							addedItem,
							result_type: "sop_categories",
							...(this.specific_field_id && {
								specific_field_id: this.specific_field_id,
							}),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
