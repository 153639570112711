import { FormSchema } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.QuickRecords;

export type State = SelectionState<FormSchema>;

export const reducer = createSelectionReducer<FormSchema>(reducerKey);
