import { Batch } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, take, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import { BatchDetailQuery } from "app/shared/eagers";
import { getISOString } from "app/shared/time-format";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

@Component({
	selector: "batch-close",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class BatchCloseComponent
	extends GenericUpdateComponent<Batch>
	implements OnInit, OnDestroy
{
	batch$ = this._store.select(fromDashboard.getSelectedBatch);
	batch_id: number | undefined;
	batch_ids: number[] | undefined;
	only_show_valid_batches = false;

	schema: any = {
		title: "",
		description: `NOTE: Batches can only be archived once all the material has been removed from them.`,
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			batch_ids: {
				type: "array",
				title: "Selected Batches",
				title_translation_key: marker("form_field_label_selected_batches"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "batches",
						},
					],
				},
				warning:
					"Warning: Bulk Actions can only be reversed at an individual batch level, please ensure you have selected the correct batches before continuing with the action.",
				warning_translation_key: marker(
					"form_field_warning_bulk_actions_can_only_be_reversed_at_an_individual_batch_level",
				),
			},
			timestamp: {
				type: "string",
				title: "Date/time batch updated.",
				title_translation_key: marker("form_field_label_date_time_batch_updated"),
				widget: "date",
			},
		},
	};

	// eslint-disable-next-line @typescript-eslint/naming-convention
	validators = { "/batch_ids": () => ({ batch_ids: {} }) };

	onUpdated: any;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _translocoService: TranslocoService,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);

		this.batch_ids = this._injector.get("ids", null);
		this.only_show_valid_batches = this._injector.get("ids", null);

		if (this.batch_ids && this.only_show_valid_batches) {
			this.form_title = "Close Empty Batches";
			this.form_title_translation_key = marker("form_title_close_empty_batches");
		} else if (this.batch_ids) {
			this.form_title = "Close Batches";
			this.form_title_translation_key = marker("form_title_close_batches");
		} else {
			this.form_title = "Close a Batch";
			this.form_title_translation_key = marker("form_title_close_a_batch");
		}

		this.submit_button = "Close";
		this.submit_button_translation_key = marker("word_close");

		// Have to manually override the schema.description with the
		// translation in order for them to correctly translate
		this.schema.description = this._translocoService.translate(
			"form_description_note_batches_can_only_be_archived_once_all_the_material_has_been_removed_from_them",
		);

		this.model = {
			opened_at: getISOString(),
		};
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.onUpdated = this._injector.get("onUpdated", null);

		if (this.batch_ids) {
			delete this.schema.properties.id;
			delete this.model.id;

			// filter out empty batches
			if (this.only_show_valid_batches) {
				if (this.batch_ids.length) {
					this.model = {
						...this.model,
						batch_ids: this.batch_ids,
					};
					this._cd.detectChanges();
				} else {
					this.closeSidenav();
				}
			} else {
				this.model = {
					...this.model,
					batch_ids: this.batch_ids,
				};
			}
		} else {
			delete this.schema.properties.batch_ids;

			this.batch$
				.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
				.subscribe((batch) => {
					this.batch_id = batch.id;
					this.model.id = this.batch_id;
					this.model.opened_at = getISOString();
					this._cd.detectChanges();
				});
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(update: Partial<Batch>) {
		this.loading$.next(true);

		let moveEndpoint = "batch";
		let moveParams: string | number = this.batch_id ?? 0;

		if (this.batch_ids) {
			moveEndpoint = "batches";
			moveParams = "close";
		}

		this._itemService
			.update(
				moveEndpoint,
				moveParams,
				{
					...update,
					archived: true,
				},
				BatchDetailQuery,
			)
			.pipe(
				takeUntil(this.destroyed$),
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					if (this.batch_ids && this.onUpdated()) {
						this.onUpdated();
					} else {
						this._store.dispatch(
							ItemActions.updateSuccess({
								updatedItem,
								result_type: "batches",
							}),
						);
					}
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
