import {
	Batch,
	HeaderQueryType,
	Page,
	StatusType,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { BatchPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "related_batches";

export type State = Page<Batch>;

// TODO: See if this can be combined with Batches
export const reducer = createPagingReducer<Batch>(reducerKey, {
	result_type: "batches",
	title: "Batch",
	title_translation_key: marker("page_title_batches"),
	title_plural: "Batches",
	title_plural_translation_key: marker("page_title_plural_batches"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Batch Filter",
			key: "batch_filter",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Active Batches",
					translationKey: marker("batch_filter_active_batches"),
					value: "ACTIVE",
				},
				{
					label: "Show All Batches",
					translationKey: marker("batch_filter_all_batches"),
					value: "ALL",
				},
				{
					label: "Show Archived Batches",
					translationKey: marker("batch_filter_archived_batches"),
					value: "ARCHIVED",
				},
				{
					label: "Show Cultivation Batches",
					translationKey: marker("batch_filter_cultivation_batches"),
					value: "CULTIVATION",
				},
				{
					label: "Show Processing Batches",
					translationKey: marker("batch_filter_processing_batches"),
					value: "PROCESSING",
				},
			],
			value: "ACTIVE",
		},
		{
			title: "Non-Zero Inventory",
			title_translation_key: marker("checkbox_filter_non_zero_inventory"),
			key: "non_zero_inventory",
			type: HeaderQueryType.checkbox,
			value: false,
		},
		{
			title: "Cultivar",
			title_translation_key: marker("page_title_cultivars"),
			key: "cultivar_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "cultivars",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		},
		{
			title: "Batch Types",
			title_translation_key: marker("page_title_batch_types"),
			key: "batch_type_filter",
			type: HeaderQueryType.dataSelect,
			result_type: "batch_types",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: null,
		},
		{
			title: "Status",
			title_translation_key: marker("page_title_status"),
			key: "status_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "statuses",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: null,
			query_string: {
				status_type: StatusType.Batch,
			},
		},
		{
			title: "Tags",
			title_translation_key: marker("page_title_plural_tags"),
			key: "tag_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "tags",
			value_key: "id",
			text_key: ["name"],
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		},
		{
			title: "Start",
			title_translation_key: marker("word_start"),
			key: "start_date",
			type: HeaderQueryType.date,
			value: "",
		},
		{
			title: "End",
			title_translation_key: marker("word_end"),
			key: "end_date",
			type: HeaderQueryType.date,
			value: "",
		},
		{
			title: "Order By",
			title_translation_key: marker("batch_filter_sort"),
			key: "orderBy",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Created At Date",
					translationKey: marker("batch_filter_sort_created"),
					value: "created_at",
				},
				{
					label: "# of Plants",
					translationKey: marker("batch_filter_sort_plants"),
					value: "plant_count",
				},
				{
					label: "Alphabetical",
					translationKey: marker("batch_filter_sort_alphabetical"),
					value: "alphabetical",
				},
			],
			value: "",
		},
	],
	query: BatchPageQuery,
});
