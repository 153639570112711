import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	Injector,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { handleObservableError } from "app/shared/utils";
import { EMPTY } from "rxjs";
import { catchError, timeout, takeUntil, tap } from "rxjs/operators";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ISku } from "@elevatedsignals/amygoodman";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "sku-create",
	templateUrl: "../form-view.component.html",
})
export class SkuCreateComponent
	extends GenericCreateComponent<ISku>
	implements OnDestroy, OnInit
{
	specific_field_id: string;

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			sku: {
				type: "string",
				title: "SKU",
				title_translation_key: marker("word_sku"),
			},
			inventory_product_ids: {
				type: "array",
				title: "Inventory Products",
				title_translation_key: marker("form_field_label_inventory_products"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [{ result_type: "inventory_products" }],
				},
			},
			vendor_id: {
				type: "number",
				title: "Vendor",
				title_translation_key: marker("word_vendor"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "vendors",
					},
				],
			},
		},
		required: ["sku"],
	};

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _injector: Injector,
	) {
		super(_store);
		this.form_title = "Add a SKU";
		this.form_title_translation_key = marker("form_title_add_a_sku");
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
		this.specific_field_id = this._injector.get("specific_field_id", null);
	}

	ngOnInit() {
		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.valid$.complete();
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	createItem(_sku: ISku) {
		this.loading$.next(true);
		this._itemService
			.add("skus", {
				sku: this.model.sku,
				inventory_product_ids: this.model.inventory_product_ids,
				vendor_id: this.model.vendor_id,
			})
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							addedItem,
							result_type: "skus",
							...(this.specific_field_id && {
								specific_field_id: this.specific_field_id,
							}),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
