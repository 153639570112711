import { Injector, Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { ReplaySubject, EMPTY } from "rxjs";
import { IWorkOrder, IWorkOrderOutput } from "@elevatedsignals/amygoodman";
import { WorkOrderService } from "app/modules/dashboard/services/work-order.service";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "work-order-output-delete",
	template: `
		<div class="ui grid" *transloco="let t">
			<div class="sixteen wide column">
				<div class="left floated left aligned eight wide column">
					<button class="ui labeled icon button" (click)="closeSidenav()">
						<i class="left arrow icon"></i>
						{{ "word_cancel" | transloco }}
					</button>
				</div>
			</div>
			<div class="sixteen wide column">
				<h2>
					{{
						form_title_translation_key ? t(form_title_translation_key) : form_title
					}}
				</h2>
			</div>

			<div class="sixteen wide column" *ngIf="error$ | async">
				<div class="ui error message">
					<span>{{ error$ | async }}</span>
				</div>
			</div>

			<div class="sixteen wide column">
				<button
					class="ui"
					[ngClass]="
						(loading$ | async) ? 'loading button disabled blue' : 'button blue'
					"
					(click)="onSubmit()"
				>
					<span
						><i *ngIf="submit_icon" class="{{ submit_icon }} icon"></i
						>{{
							submit_button_translation_key
								? t(submit_button_translation_key)
								: submit_button
						}}</span
					>
				</button>
			</div>
		</div>
	`,
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderOutputDeleteComponent implements OnInit, OnDestroy {
	work_order: IWorkOrder;
	item: IWorkOrderOutput;

	form_title =
		"Are you sure you want to delete this output? This action cannot be undone.";

	form_title_translation_key = marker(
		"form_title_are_you_sure_you_want_to_delete_this_output_this_action_cannot_be_undone",
	);

	submit_button = "Yes";
	submit_button_translation_key = marker("word_yes");
	submit_icon = "bomb";

	error$: ReplaySubject<string> = new ReplaySubject();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	loading_error$: ReplaySubject<string> = new ReplaySubject<string>();
	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _injector: Injector,
		private readonly _workOrderService: WorkOrderService,
	) {}

	ngOnInit() {
		this.work_order = this._injector.get("work_order", null);
		this.item = this._injector.get("item", null);
	}

	onSubmit() {
		this.deleteEvent();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}

	deleteEvent() {
		this.loading$.next(true);
		this._workOrderService
			.removeOutput({
				work_order_id: this.work_order.id,
				output_id: this.item.id,
			})
			.pipe(
				timeout(10000),
				catchError((error) => {
					// console.log(e);
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
