import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	ChangeDetectorRef,
	Component,
	Injector,
	OnDestroy,
} from "@angular/core";
import { IPurchaseOrder } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "purchase-order-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PurchaseOrderCreateComponent
	extends GenericCreateComponent<IPurchaseOrder>
	implements OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: Purchase Order name already in use.",
				warning_translation_key: marker(
					"form_field_warning_purchase_order_name_already_in_use",
				),
			},
			po_number: {
				type: "string",
				title: "PO Number",
				title_translation_key: marker("form_field_label_po_number"),
			},
			po_date: {
				type: "string",
				title: "PO Date",
				title_translation_key: marker("form_field_label_po_date"),
				widget: "date",
			},
			vendor_id: {
				type: "number",
				title: "Vendor",
				title_translation_key: marker("word_vendor"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "vendors",
						queryString: {
							excludeBlocked: "true",
						},
					},
				],
			},
		},
		required: ["vendor_id"],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	specific_field_id: string;

	private names;
	private _isOutgoing = false;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
		protected _cd: ChangeDetectorRef,
	) {
		super(_store);
		this.form_title = "Create a Purchase Order";
		this.form_title_translation_key = marker(
			"form_title_create_a_purchase_order",
		);
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
		this.specific_field_id = this._injector.get("specific_field_id", null);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	ngOnInit() {
		this._isOutgoing = this._injector.get("is_outgoing", false);

		if (this._isOutgoing) {
			this.form_title = "Create a Shipping Order";
			this.form_title_translation_key = marker(
				"form_title_create_a_shipping_order",
			);
			this.schema.properties.po_date.title = "Shipping Date";
			this.schema.properties.po_date.title_translation_key = marker(
				"form_field_label_shipping_date",
			);
			this.schema.properties.po_number.title = "Shipping number";
			this.schema.properties.po_number.title_translation_key = marker(
				"form_field_label_shipping_number",
			);
			this.schema.properties.name.warning =
				"Warning: Shipping Order name already in use.";
			this.schema.properties.name.warning = marker(
				"form_field_warning_shipping_order_name_already_in_use",
			);

			this._cd.detectChanges();
		}

		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`purchase_orders/fetch/names`, "", {
				col: "name",
				is_outgoing: this._isOutgoing.toString(),
			})
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	createItem(purchase_order: Partial<IPurchaseOrder>) {
		this.loading$.next(true);
		this._itemService
			.add(`purchase_orders`, {
				...purchase_order,
				is_outgoing: this._isOutgoing,
			})
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							addedItem,
							result_type: "purchase_orders",
							...(this.specific_field_id && {
								specific_field_id: this.specific_field_id,
							}),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
