import { HeaderQueryType, Page, Record } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { FormSchemaPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "schemas";

export type State = Page<Record>;

export const reducer = createPagingReducer<Record>(reducerKey, {
	result_type: "schemas",
	title: "Form Schema",
	title_plural: "Form Schemas",
	title_translation_key: marker("page_title_form_schemas"),
	title_plural_translation_key: marker("page_title_plural_form_schemas"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Release States",
			title_translation_key: marker("form_placeholder_release_states"),
			key: "recordFilter",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Released",
					translationKey: marker("word_released"),
					value: "RELEASED",
				},
				{
					label: "Unreleased",
					translationKey: marker("word_unreleased"),
					value: "UNRELEASED",
				},
				{
					label: "Archived",
					translationKey: marker("word_archived"),
					value: "ARCHIVED",
				},
			],
			value: ["RELEASED"],
			multiple: true,
		},
		{
			title: "SOP category",
			title_translation_key: marker("page_title_sop_categories"),
			key: "sop_category_id",
			text_key: "name",
			value_key: "id",
			type: HeaderQueryType.dataSelect,
			result_type: "sop_categories",
			multiple: false,
			search: true,
			disabled: false,
			value: null,
			required_facility_settings: { use_sop_categories: true },
		},
		{
			title: "SOP",
			title_translation_key: marker("page_title_sops"),
			key: "sop_ids",
			text_key: "name",
			value_key: "id",
			type: HeaderQueryType.dataSelect,
			result_type: "sops",
			multiple: true,
			search: true,
			disabled: false,
			value: null,
			related_query_keys: ["sop_category_id"],
			required_facility_settings: { use_sop_categories: true },
		},
	],
	query: FormSchemaPageQuery,
});
