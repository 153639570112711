import { Injectable } from "@angular/core";
import { Page, Sensor } from "@elevatedsignals/amygoodman";
import {
	ItemKey,
	MorphKey,
} from "app/modules/dashboard/reducers/selection/keys";
import { PERMISSIONS } from "app/shared/permissions";
import { EnvService } from "app/shared/services/env.service";

class Endpoints {
	constructor(
		public es_api_server: string,
		public es_client_api: string,
	) {}
}

class AuthEndpoints extends Endpoints {
	signin() {
		return `${this.es_api_server}${this.es_client_api}/user/signin`;
	}

	signup() {
		return `${this.es_api_server}${this.es_client_api}/user/register`;
	}

	authenticate() {
		return `${this.es_api_server}${this.es_client_api}/user/signin/refresh`;
	}

	forgotPassword() {
		return `${this.es_api_server}${this.es_client_api}/user/password/forgot`;
	}

	passwordReset() {
		return `${this.es_api_server}${this.es_client_api}/user/password/reset`;
	}

	validateRegistrationToken() {
		return `${this.es_api_server}${this.es_client_api}/user/validate/register`;
	}

	validateResetToken() {
		return `${this.es_api_server}${this.es_client_api}/user/validate/reset`;
	}
}

class ProfileEndpoints extends Endpoints {
	fetch() {
		return `${this.es_api_server}${this.es_client_api}/user/profile`;
	}
}

class ItemsEndpoints extends Endpoints {
	get(type: string, ids: (number | string)[]) {
		const id_string = ids.join(";");
		return `${this.es_api_server}${this.es_client_api}/${type}/${id_string}`;
	}

	upload_csv(type: string) {
		return `${this.es_api_server}${this.es_client_api}/${type}/upload_csv`;
	}
}

class ItemEndpoints extends Endpoints {
	add(type: string) {
		return `${this.es_api_server}${this.es_client_api}/${type}`;
	}

	get(type: string, id: number | string) {
		return `${this.es_api_server}${this.es_client_api}/${type}/${id}`;
	}

	getAll(type: string) {
		return `${this.es_api_server}${this.es_client_api}/${type}`;
	}

	update(type: string, id: number | string) {
		return `${this.es_api_server}${this.es_client_api}/${type}/${id}`;
	}

	delete(type: string, id: number | string) {
		return `${this.es_api_server}${this.es_client_api}/${type}/${id}`;
	}
}

class UsersEndpoints extends Endpoints {
	fetch() {
		return {
			url: () => {
				return `${this.es_api_server}${this.es_client_api}/users?should_page=false&eager=roles`;
			},
			permission: PERMISSIONS.USERS_VIEW,
		};
	}

	invite() {
		return {
			url: () => {
				return `${this.es_api_server}${this.es_client_api}/users/invite`;
			},
			permission: PERMISSIONS.USERS_ADD,
		};
	}

	reinvite_user() {
		return {
			url: (user_id: number) => {
				return `${this.es_api_server}${this.es_client_api}/user/${user_id}/invite`;
			},
			permission: PERMISSIONS.USERS_ADD,
		};
	}

	settings() {
		return {
			url: () => {
				return `${this.es_api_server}${this.es_client_api}/users/settings`;
			},
			permission: PERMISSIONS.USERS_EDIT,
		};
	}

	esVersions() {
		return `${this.es_api_server}${this.es_client_api}/users/esVersions`;
	}

	update(user_account_id: number) {
		return `${this.es_api_server}${this.es_client_api}/user/${user_account_id}`;
	}
}

class RolesEndpoints extends Endpoints {
	list() {
		return `${this.es_api_server}${this.es_client_api}/roles?should_page=false`;
	}
}

class ReadingsEndpoints extends Endpoints {
	location() {
		return {
			url: (location_id: number, from: number, to: number) => {
				return `${this.es_api_server}${this.es_client_api}/location/${location_id}/sensor/readings?from=${from}&to=${to}`;
			},
			permission: PERMISSIONS.READINGS_VIEW,
		};
	}

	sensor() {
		return {
			url: (sensor_uuid: string, from: number, to: number) => {
				return `${this.es_api_server}${this.es_client_api}/sensor/${sensor_uuid}/readings?from=${from}&to=${to}`;
			},
			permission: PERMISSIONS.READINGS_VIEW,
		};
	}
}

class AuditLogEndpoints extends Endpoints {
	history(entity_type: string, entity_id: number) {
		return `${this.es_api_server}${this.es_client_api}/${entity_type}/${entity_id}/audit_log`;
	}
}

class RecordEndpoints extends Endpoints {
	update() {
		return `${this.es_api_server}${this.es_client_api}/records/update`;
	}

	download(record_id: number) {
		return `${this.es_api_server}${this.es_client_api}/record/${record_id}/download`;
	}

	batchDownload(batch_id: number) {
		return `${this.es_api_server}${this.es_client_api}/batch/${batch_id}/records/pdf`;
	}

	poDownload(po_id: number) {
		return `${this.es_api_server}${this.es_client_api}/purchase_order/${po_id}/records/pdf`;
	}

	history(recordId: number) {
		return `${this.es_api_server}${this.es_client_api}/records/${recordId}/history`;
	}

	historyAtVersion(recordId: number, auditId: number) {
		return `${this.es_api_server}${this.es_client_api}/records/${recordId}/history/${auditId}`;
	}

	downloadHistoryAtVersion(recordId: number, auditId: number) {
		return `${this.es_api_server}${this.es_client_api}/records/${recordId}/history/${auditId}/download`;
	}

	verify(recordId: number) {
		return `${this.es_api_server}${this.es_client_api}/records/${recordId}/verify`;
	}

	archive(recordId: number) {
		return `${this.es_api_server}${this.es_client_api}/records/${recordId}/archive`;
	}

	lock(recordId: number) {
		return `${this.es_api_server}${this.es_client_api}/records/${recordId}/lock`;
	}

	unlock(recordId: number) {
		return `${this.es_api_server}${this.es_client_api}/records/${recordId}/unlock`;
	}

	// @deprecated
	// deprecated due to fix done for ESS-7520
	archiveFile(recordId: number, fileId: number) {
		return `${this.es_api_server}${this.es_client_api}/records/${recordId}/archive/${fileId}`;
	}
}

class ReportsEndpoints extends Endpoints {
	pdf(resource_name: string, id: number | string) {
		return `${this.es_api_server}${this.es_client_api}/${resource_name}/${id}/pdf`;
	}

	ctls(facility_id: number, year: number, month: number) {
		return `${this.es_api_server}${this.es_client_api}/facility/${facility_id}/ctls_report?year=${year}&month=${month}`;
	}

	reportsEndpoint() {
		return `${this.es_api_server}${this.es_client_api}/reports`;
	}

	reportingPeriod() {
		return `${this.es_api_server}${this.es_client_api}/reporting_periods`;
	}
}

class SchemaEndpoints extends Endpoints {
	list() {
		return `${this.es_api_server}${this.es_client_api}/schemas`;
	}
}

class SubscriptionsEndpoints extends Endpoints {
	list() {
		return {
			url: () => {
				return `${this.es_api_server}${this.es_client_api}/user/subscriptions`;
			},
			permission: PERMISSIONS.SUBSCRIPTIONS_VIEW,
		};
	}

	listForUser() {
		return {
			url: (connection_type: string, connection_id: string | number) => {
				return `${this.es_api_server}${this.es_client_api}/user/subscription/${connection_type}/${connection_id}`;
			},
			permission: PERMISSIONS.SUBSCRIPTIONS_VIEW,
		};
	}

	listForConnection() {
		return {
			url: (
				connection_type: string,
				connection_id: string | number,
				eager: string[],
			) => {
				return `${this.es_api_server}${
					this.es_client_api
				}/${connection_type}/${connection_id}/subscriptions${
					eager ? `?eager=${eager.join(", ")}` : ""
				}`;
			},
			permission: PERMISSIONS.SUBSCRIPTIONS_VIEW,
		};
	}

	add() {
		return {
			url: () => {
				return `${this.es_api_server}${this.es_client_api}/subscriptions`;
			},
			permission: PERMISSIONS.SUBSCRIPTIONS_ADD,
		};
	}

	update() {
		return {
			url: (subscription_id: number) => {
				return `${this.es_api_server}${this.es_client_api}/subscriptions/${subscription_id}`;
			},
			permission: PERMISSIONS.SUBSCRIPTIONS_EDIT,
		};
	}

	delete() {
		return {
			url: (subscription_id: number) => {
				return `${this.es_api_server}${this.es_client_api}/subscriptions/${subscription_id}`;
			},
			permission: PERMISSIONS.SUBSCRIPTIONS_REMOVE,
		};
	}
}

class RoleEndpoints extends Endpoints {
	get() {
		return `${this.es_api_server}${this.es_client_api}/roles`;
	}
}

class PermissionEndpoints extends Endpoints {
	get() {
		return `${this.es_api_server}${this.es_client_api}/permissions`;
	}
}

class NotificationsEndpoints extends Endpoints {
	count() {
		return {
			url: () => {
				return `${this.es_api_server}${this.es_client_api}/user/notifications/unread?count`;
			},
			permission: PERMISSIONS.NOTIFICATIONS_VIEW,
		};
	}

	list() {
		return {
			url: () => {
				return `${this.es_api_server}${this.es_client_api}/user/notifications`;
			},
			permission: PERMISSIONS.NOTIFICATIONS_VIEW,
		};
	}

	listUnread() {
		return {
			url: () => {
				return `${this.es_api_server}${this.es_client_api}/user/notifications/unread`;
			},
			permission: PERMISSIONS.NOTIFICATIONS_VIEW,
		};
	}

	update() {
		return {
			url: (notification_id: number, action: string, token: string) => {
				return `${this.es_api_server}${this.es_client_api}/notifications/${notification_id}/${action}?token=${token}`;
			},
			permission: PERMISSIONS.NOTIFICATIONS_EDIT,
		};
	}
}

class PrinterJobEndpoints extends Endpoints {
	count(facility_id: number) {
		return {
			url: () => {
				return `${this.es_api_server}/facility/${facility_id}/printer_jobs/count`;
			},
			permission: PERMISSIONS.PRINTERS_VIEW,
		};
	}

	cancelJob(job_id: number) {
		return {
			url: () => {
				return `${this.es_api_server}${this.es_client_api}/printer_jobs/${job_id}/cancel`;
			},
			permission: PERMISSIONS.PRINTER_JOBS_EDIT,
		};
	}
}

class ThresholdsEndpoints extends Endpoints {
	list() {
		return {
			url: (connection_type: string, type_id: number, eager?: string) => {
				const params = eager ? `?eager=${eager}` : "";
				return `${this.es_api_server}${this.es_client_api}/${connection_type}/${type_id}/thresholds${params}`;
			},
			permission: PERMISSIONS.THRESHOLDS_VIEW,
		};
	}

	add() {
		return {
			url: () => {
				return `${this.es_api_server}${this.es_client_api}/thresholds/segments`;
			},
			permission: PERMISSIONS.THRESHOLDS_ADD,
		};
	}

	update() {
		return {
			url: (threshold_id: number) => {
				return `${this.es_api_server}${this.es_client_api}/thresholds/${threshold_id}/segments`;
			},
			permission: PERMISSIONS.THRESHOLDS_EDIT,
		};
	}

	delete() {
		return {
			url: (threshold_id: number) => {
				return `${this.es_api_server}${this.es_client_api}/thresholds/${threshold_id}/segments`;
			},
			permission: PERMISSIONS.THRESHOLDS_REMOVE,
		};
	}
}

class PurchaseOrderEndpoints extends Endpoints {
	close(id: number | string) {
		return `${this.es_api_server}${this.es_client_api}/purchase_order/${id}/close`;
	}

	lock(id: number | string) {
		return `${this.es_api_server}${this.es_client_api}/purchase_order/${id}/lock`;
	}

	unlock(id: number | string) {
		return `${this.es_api_server}${this.es_client_api}/purchase_order/${id}/unlock`;
	}

	verify(id: number) {
		return `${this.es_api_server}${this.es_client_api}/purchase_order/${id}/verify`;
	}
}

class SensorEndpoints extends Endpoints {
	page() {
		return {
			url: (page: Page<Sensor>) => {
				if (page.type) {
					return `${this.es_api_server}${this.es_client_api}/${page.type}/${
						page.type_id
					}/sensors?page=${page.page || 1}&page_size=${page.page_size || 5}${
						page.search ? `&search=${page.search}` : ""
					}`;
				} else {
					return `${this.es_api_server}${this.es_client_api}/sensors?page=${
						page.page || 1
					}&page_size=${page.page_size || 5}${
						page.search ? `&search=${page.search}` : ""
					}`;
				}
			},
		};
	}

	generateApiKey() {
		return {
			url: (sensor_uuid: string) => {
				return `${this.es_api_server}${this.es_client_api}/sensors/${sensor_uuid}/token`;
			},
			permission: PERMISSIONS.SENSORS_EDIT,
		};
	}
}

class DataEndpoints extends Endpoints {
	floorPlan() {
		return `${this.es_api_server}${this.es_client_api}/floorplan`;
	}
}

class PageEndpoints extends Endpoints {
	url(page: Page<any>) {
		// Base Url
		let url = `${this.es_api_server}${this.es_client_api}/`;

		// Handle getting related info if availible
		if (page.type) {
			// Get rid of this once API ready to handle all plurals
			const morphedType =
				MorphKey[getKeyByValue(ItemKey, page.type)!] ?? page.type;
			url += `${morphedType}/${page.type_id ? page.type_id : page.type_uuid}/${
				page.result_type
			}`;
		} else {
			url += `${page.result_type}${page.result_ids ? `/${page.result_ids}` : ""}`;
		}

		// Add Page and search params
		url += `?page=${page.page || 1}&page_size=${page.page_size || 5}${
			page.search ? `&search=${`${encodeURIComponent(page.search)}`}` : ""
		}`;
		return url;
	}
}

class WorkOrderEndpoints extends Endpoints {
	lock(woId: number) {
		return `${this.es_api_server}${this.es_client_api}/work_order/${woId}/lock`;
	}

	unlock(woId: number) {
		return `${this.es_api_server}${this.es_client_api}/work_order/${woId}/unlock`;
	}

	verify(woId: number) {
		return `${this.es_api_server}${this.es_client_api}/work_order/${woId}/verify`;
	}

	eventLog(woId: number) {
		return `${this.es_api_server}${this.es_client_api}/work_order/${woId}/event_log`;
	}

	downloadOutputInventoryCSV(woId: number) {
		return `${this.es_api_server}${this.es_client_api}/work_order/${woId}/output-inventory-report`;
	}
}

class DestructionLotEndpoints extends Endpoints {
	lock(id: number) {
		return `${this.es_api_server}${this.es_client_api}/destruction_lot/${id}/lock`;
	}

	unlock(id: number) {
		return `${this.es_api_server}${this.es_client_api}/destruction_lot/${id}/unlock`;
	}

	verify(id: number) {
		return `${this.es_api_server}${this.es_client_api}/destruction_lot/${id}/verify`;
	}
}

class CustomFieldEndpoints extends Endpoints {
	get(entity_type: string, entity_id: number) {
		return `${this.es_api_server}${this.es_client_api}/${entity_type}/${entity_id}/custom_field`;
	}
}

class FacilitySettings extends Endpoints {
	get(facility_id: number) {
		return `${this.es_api_server}${this.es_client_api}/facility/${facility_id}/settings`;
	}
}

class HealthCheck extends Endpoints {
	get() {
		return `${this.es_api_server}/health_check`;
	}
}

@Injectable({
	providedIn: "root",
})
export class ApiService {
	public es_api_server: string;
	public es_client_api: string;

	public version = 1;
	public name: "elevated_signals";

	public audit: AuditLogEndpoints;
	public auth: AuthEndpoints;
	public profile: ProfileEndpoints;
	public items: ItemsEndpoints;
	public item: ItemEndpoints;
	public users: UsersEndpoints;
	public roles: RolesEndpoints;
	public record: RecordEndpoints;
	public reports: ReportsEndpoints;
	public schema: SchemaEndpoints;
	public subscriptions: SubscriptionsEndpoints;
	public readings: ReadingsEndpoints;
	public role: RoleEndpoints;
	public permission: PermissionEndpoints;
	public notifications: NotificationsEndpoints;
	public thresholds: ThresholdsEndpoints;
	public sensor: SensorEndpoints;
	public data: DataEndpoints;
	public page: PageEndpoints;
	public printerJobs: PrinterJobEndpoints;
	public po: PurchaseOrderEndpoints;
	public wo: WorkOrderEndpoints;
	public dl: DestructionLotEndpoints;
	public custom_field: CustomFieldEndpoints;
	public facilitySettings: FacilitySettings;
	public healthCheck: HealthCheck;

	constructor(private readonly _env: EnvService) {
		this.es_api_server = this._env.es_api_server;
		this.es_client_api = this._env.es_client_api;

		this.audit = new AuditLogEndpoints(this.es_api_server, this.es_client_api);
		this.auth = new AuthEndpoints(this.es_api_server, this.es_client_api);
		this.profile = new ProfileEndpoints(this.es_api_server, this.es_client_api);
		this.items = new ItemsEndpoints(this.es_api_server, this.es_client_api);
		this.item = new ItemEndpoints(this.es_api_server, this.es_client_api);
		this.users = new UsersEndpoints(this.es_api_server, this.es_client_api);
		this.roles = new RolesEndpoints(this.es_api_server, this.es_client_api);
		this.record = new RecordEndpoints(this.es_api_server, this.es_client_api);
		this.reports = new ReportsEndpoints(this.es_api_server, this.es_client_api);
		this.schema = new SchemaEndpoints(this.es_api_server, this.es_client_api);
		this.subscriptions = new SubscriptionsEndpoints(
			this.es_api_server,
			this.es_client_api,
		);
		this.readings = new ReadingsEndpoints(this.es_api_server, this.es_client_api);
		this.role = new RoleEndpoints(this.es_api_server, this.es_client_api);
		this.permission = new PermissionEndpoints(
			this.es_api_server,
			this.es_client_api,
		);
		this.notifications = new NotificationsEndpoints(
			this.es_api_server,
			this.es_client_api,
		);
		this.thresholds = new ThresholdsEndpoints(
			this.es_api_server,
			this.es_client_api,
		);
		this.data = new DataEndpoints(this.es_api_server, this.es_client_api);
		this.page = new PageEndpoints(this.es_api_server, this.es_client_api);
		this.printerJobs = new PrinterJobEndpoints(
			this.es_api_server,
			this.es_client_api,
		);
		this.po = new PurchaseOrderEndpoints(this.es_api_server, this.es_client_api);
		this.wo = new WorkOrderEndpoints(this.es_api_server, this.es_client_api);
		this.dl = new DestructionLotEndpoints(this.es_api_server, this.es_client_api);
		this.custom_field = new CustomFieldEndpoints(
			this.es_api_server,
			this.es_client_api,
		);
		this.facilitySettings = new FacilitySettings(
			this.es_api_server,
			this.es_client_api,
		);
		this.healthCheck = new HealthCheck(this.es_api_server, this.es_client_api);
	}
}

const getKeyByValue = (object, value) => {
	return Object.keys(object).find((key) => object[key] === value);
};
