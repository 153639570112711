import { HeaderQueryType, Page, Record } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { FormSchemaPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "custom_fields";

export type State = Page<any>;

export const reducer = createPagingReducer<any>(reducerKey, {
	result_type: "custom_fields",
	title: "Form Schema",
	title_plural: "Form Schemas",
	title_translation_key: marker("page_title_custom_fields"),
	title_plural_translation_key: marker("page_title_plural_custom_fields"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "type",
			key: "type",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Location",
					translationKey: marker("word_location"),
					value: "location",
				},
				{
					label: "Work Order",
					translationKey: marker("word_work_order"),
					value: "work_order",
				},
				{
					label: "Batch",
					translationKey: marker("word_batch"),
					value: "batch",
				},
				{
					label: "Inventory Product",
					translationKey: marker("word_inventory_product"),
					value: "inventory_product",
				},
			],
			value: [],
		},
		{
			title: "Show Archived",
			key: "archived",
			title_translation_key: marker("checkbox_filter_show_archived"),
			type: HeaderQueryType.checkbox,
			value: false,
		},
	],
	query: FormSchemaPageQuery,
});
