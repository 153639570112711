import { filter, Observable } from "rxjs";

const inputIsNotNullOrUndefined = <T>(
	input: null | undefined | T,
): input is T => {
	return input !== null && input !== undefined;
};

export const isNotNullOrUndefined = <T>() => {
	return (source$: Observable<null | undefined | T>) =>
		source$.pipe(filter(inputIsNotNullOrUndefined));
};
