import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ReplaySubject } from "rxjs";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

export abstract class GenericCreateComponent<T> {
	valid$: ReplaySubject<boolean> = new ReplaySubject();
	secondaryValid$: ReplaySubject<boolean> = new ReplaySubject();
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Create an Item";
	form_title_translation_key: string = marker("form_title_create_an_item");
	form_title_translation_params = {};
	submit_button = "Create";
	submit_button_translation_key: string = marker("word_create");
	submit_icon = "plus";

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	public schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
		},
		required: ["name"],
	};

	validators = {};

	public model: any = {};

	constructor(protected _store: Store<fromDashboard.State>) {}

	valid(val) {
		this.valid$.next(val);
	}

	secondaryValid(val) {
		this.secondaryValid$.next(val);
	}

	onChanges(_error): void {}

	onError(_error): void {}

	onSubmit() {
		const new_item: Partial<T> = {
			...this.model,
		};
		this.createItem(new_item);
	}

	abstract createItem(itemModel: Partial<T>);

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
