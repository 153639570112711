import {
	HeaderQueryType,
	IGrowthStage,
	Page,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "growth_stages";

export type State = Page<IGrowthStage>;

export const reducer = createPagingReducer<IGrowthStage>(reducerKey, {
	result_type: "growth_stages",
	title: "Growth Stage",
	title_translation_key: marker("page_title_growth_stages"),
	title_plural_translation_key: marker("page_title_plural_growth_stages"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Status Filter",
			key: "growth_stage_status",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Active Growth Stages",
					translationKey: marker("growth_stage_filter_active"),
					value: "ACTIVE",
				},
				{
					label: "Archived Growth Stages",
					translationKey: marker("growth_stage_filter_archived"),
					value: "ARCHIVED",
				},
				{
					label: "Show All Growth Stages",
					translationKey: marker("growth_stage_filter_all"),
					value: "All",
				},
			],
			value: "ACTIVE",
		},
	],
});
