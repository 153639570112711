import { inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TranslocoService } from "@jsverse/transloco";
import { TranslateErrorService } from "app/shared/errors/handleError";
import { ILocation, Sensor } from "@elevatedsignals/amygoodman";
import { ApiService } from "app/modules/auth/services/api.service";

@Injectable({
  providedIn: "root",
})
export class ReadingsService {
	private translateErrorService = inject(TranslateErrorService);

	constructor(
		private readonly http: HttpClient,
		private readonly _api: ApiService,
		private readonly _translocoService: TranslocoService,
	) {
	}

	getLocationReadings(location: ILocation, from: number, to: number) {
		if (!location || !from || !to) {
			throw new Error(
				`${this._translocoService.translate(
					"fragment_one_of",
				)} ${this._translocoService.translate(
					"fragment_location",
				)} ${location}, ${this._translocoService.translate(
					"fragment_to",
				)} ${to}, ${this._translocoService.translate(
					"fragment_from",
				)} ${from} ${this._translocoService.translate("fragment_missing")}`,
			);
		}
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
		});
		return this.http
			.get(this._api.readings.location().url(location.id!, from, to), { headers })
			.pipe(
				this.translateErrorService.catchAndTranslateError(
					"error_failed_to_get_readings",
				),
			);
	}

	getSensorReadings(sensor: Sensor, from: number, to: number) {
		if (!sensor || !from || !to) {
			throw new Error(
				`${this._translocoService.translate(
					"fragment_one_of",
				)} ${this._translocoService.translate(
					"fragment_sensor",
				)} ${sensor}, ${this._translocoService.translate(
					"fragment_to",
				)} ${to}, ${this._translocoService.translate(
					"fragment_from",
				)} ${from} ${this._translocoService.translate("fragment_missing")}`,
			);
		}
		const headers = new HttpHeaders({
			"Content-Type": "application/json",
		});
		return this.http
			.get(this._api.readings.sensor().url(sensor.uuid!, from, to), { headers })
			.pipe(
				this.translateErrorService.catchAndTranslateError(
					"error_failed_to_get_readings",
				),
			);
	}
}
