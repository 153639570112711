import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, OnDestroy, Injector } from "@angular/core";
import { IVendor, StatusType } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "vendor-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class VendorCreateComponent
	extends GenericCreateComponent<IVendor>
	implements OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			vendor_category_ids: {
				type: "array",
				title: "Vendors Categories",
				title_translation_key: marker("word_vendor_categories"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "vendor_categories",
							text_key: ["name"],
						},
					],
				},
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: Vendor name already in use.",
				warning_translation_key: marker(
					"form_field_warning_vendor_name_already_in_use",
				),
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
			},
			address: {
				type: "string",
				title: "Address",
				title_translation_key: marker("word_address"),
			},
			primary_phone: {
				type: "string",
				title: "Phone 1",
				title_translation_key: marker("word_phone_1"),
			},
			secondary_phone: {
				type: "string",
				title: "Phone 2",
				title_translation_key: marker("word_phone_2"),
			},
			email: {
				type: "string",
				title: "Email",
				title_translation_key: marker("word_email"),
			},
			customer: {
				type: "boolean",
				widget: "checkbox",
				title: "Customer",
				title_translation_key: marker("form_field_label_customer"),
			},
			facility_vendor: {
				type: "boolean",
				widget: "checkbox",
				title: "Facility Vendor",
				title_translation_key: marker("form_field_label_facility_vendor"),
			},
			status_id: {
				type: "number",
				title: "Vendor Status",
				title_translation_key: marker("form_field_label_vendor_status"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "statuses",
						value_key: "id",
						queryString: {
							status_type: StatusType.Vendor,
						},
					},
				],
			},
		},
		required: ["name"],
	};

	specific_field_id: string;
	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private names;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store);
		this.form_title = "Add a Vendor";
		this.form_title_translation_key = marker("form_title_add_a_vendor");
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
		this.specific_field_id = this._injector.get("specific_field_id", null);
	}

	ngOnInit() {
		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`vendors/fetch/names`, "", { col: "name" })
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	createItem(vendor: Partial<IVendor>) {
		this.loading$.next(true);
		this._itemService
			.add(`vendors`, vendor)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							addedItem,
							result_type: "vendors",
							...(this.specific_field_id && {
								specific_field_id: this.specific_field_id,
							}),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
