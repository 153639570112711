import { Store } from "@ngrx/store";
import { ItemService } from "app/modules/dashboard/services/item.service";
import {
	timeout,
	catchError,
	take,
	takeUntil,
	tap,
	skipWhile,
} from "rxjs/operators";
import { EMPTY, Observable } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	Injector,
	OnInit,
	OnDestroy,
} from "@angular/core";
import { Router } from "@angular/router";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { UserProfile } from "@elevatedsignals/amygoodman";
import { CustomFieldSchemaDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { Globals } from "app/shared/modules/globals/globals.service";
import { PasswordInputComponent } from "app/modules/auth/components/password-input";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

@Component({
	selector: "custom-field-schema-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class CustomFieldSchemaUpdateComponent
	extends GenericUpdateComponent<any>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		type: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			entity: {
				type: "string",
				readOnly: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				readOnly: true,
			},
			type: {
				type: "string",
				title: "Type",
				title_translation_key: marker("word_type"),
				hidden: true,
			},
			version_major: {
				type: "number",
				title: "Version",
				title_translation_key: marker("word_version"),
				readOnly: true,
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
		},
		required: ["name"],
	};

	formSchema$ = this._store.select(fromDashboard.getSelectedCustomFieldSchema);
	formSchema: any;

	user$: Observable<UserProfile | null> = this._store.select(
		fromDashboard.getProfile,
	);

	user: UserProfile | null;

	private schema_update: any;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
		private readonly _router: Router,
		private readonly _globals: Globals,
	) {
		super(_store, _cd);
		this.form_title = "Update Custom Field Schema";
		this.form_title_translation_key = marker(
			"form_title_update_custom_field_schema",
		);
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.schema_update = this._injector.get("schema_update", null);

		this.user$
			.pipe(
				takeUntil(this.destroyed$),
				skipWhile((user: UserProfile | null) => user == null),
			)
			.subscribe((user) => {
				this.user = user;
			});

		this.formSchema$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((formSchema) => {
				this.formSchema = formSchema;
				this.model.id = this.formSchema.id;
				this.model.name = this.formSchema.name;
				this.model.type = this.formSchema.type;
				this.model.version_major = this.formSchema.version_major;
				this.model.version_minor = this.formSchema.version_minor;
				this.model.archived = this.formSchema.archived;
				this.model.entity = this.formSchema.entity;
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(formSchema: Partial<any>) {
		let formSchemaUpdate;
		if (this.schema_update) {
			formSchemaUpdate = {
				...formSchema,
				schema: {
					...this.schema_update,
				},
				title: formSchema.name,
				type: formSchema.type,
				archived: formSchema.archived,
				entity: formSchema.entity,
			};
		}

		if (this._globals.gmp_enabled) {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: PasswordInputComponent,
					inputs: {
						onSubmit: (password) => {
							this.updateRequest(formSchemaUpdate, true, password);
						},
					},
				}),
			);
		} else {
			this.updateRequest(formSchemaUpdate, true);
		}
	}

	updateRequest(formSchemaUpdate: any, signing = false, password?: string) {
		const authObject = password
			? { username: this.user!.email, password }
			: undefined;

		this.loading$.next(true);
		this._itemService
			.update(
				`custom_fields`,
				formSchemaUpdate.id,
				formSchemaUpdate,
				signing
					? {
							...CustomFieldSchemaDetailQuery,
							signing: "true",
						}
					: CustomFieldSchemaDetailQuery,
				authObject,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					// If a new form version was cut (created), take the user to that page
					if (formSchemaUpdate.id === updatedItem.id) {
						this._store.dispatch(
							ItemActions.updateSuccess({
								updatedItem,
								result_type: "custom_fields",
							}),
						);
						this.loading$.next(false);
						this.closeSidenav();
					}
					// Otherwise, update the form in place
					else {
						this.loading$.next(false);
						this.closeSidenav();
						this._router.navigate([`/dashboard/custom_fields/${updatedItem.id}`]);
					}
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
