import { IWorkOrderPendingInventory, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "pending_inventories";

export type State = Page<IWorkOrderPendingInventory>;

export const reducer = createPagingReducer<IWorkOrderPendingInventory>(
	reducerKey,
	{
		result_type: "work_order_pending_inventories",
		title: "Work Order Pending Inventory",
		title_translation_key: marker("page_title_work_order_pending_inventories"),
		title_plural_translation_key: marker(
			"page_title_work_order_pending_inventories",
		),
		results: [],
		page: 1,
		page_size: 50,
		total: 0,
	},
);
