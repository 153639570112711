import { IInventoryProduct } from "@elevatedsignals/amygoodman";

export interface UnitRawValue {
	id: number;
	isFirstUnit: boolean;
	isDisplayUnit: boolean;
	unit_id?: number;
	unit_name: string;
	conversion: number;
	reverse_conversion: boolean;
	output_unit_id?: number;
	output_name: string;
}

const findUnitById = (id: number, inventory_product: IInventoryProduct) => {
	const unit = inventory_product.inventory_units?.find((unit) => unit.id === id);
	return unit;
};

export const getRawUnits = (
	inventory_product: IInventoryProduct,
	copyProduct: boolean,
) => {
	const rawUnits: UnitRawValue[] = [];

	const onInitUnitNames: string[] = [];

	// For Product Copy don't include ids
	// First unit had no equivalent_units when was created
	const firstUnit = inventory_product.inventory_units?.[0]!;
	rawUnits.push({
		id: 0,
		unit_id: copyProduct ? 0 : firstUnit.id,
		isFirstUnit: true,
		isDisplayUnit: firstUnit.id === inventory_product.display_unit_id,
		unit_name: firstUnit.name,
		conversion: 1,
		reverse_conversion: false,
		output_unit_id: copyProduct ? 0 : firstUnit.id,
		output_name: firstUnit.name,
	});
	onInitUnitNames.push(firstUnit.name);

	if (inventory_product.equivalent_units?.length) {
		for (const eq of inventory_product.equivalent_units) {
			const inputUnit = findUnitById(
				eq.input_inventory_unit_id,
				inventory_product,
			)!;
			const outputUnit = findUnitById(
				eq.output_inventory_unit_id,
				inventory_product,
			)!;
			// Avoid equivalent units created by mistake
			if (
				onInitUnitNames.includes(inputUnit.name) &&
				onInitUnitNames.includes(outputUnit.name)
			) {
				continue;
			}
			// Check if unit was created with reverse conversion
			const reverse_conversion = onInitUnitNames.includes(inputUnit.name);
			const oldUnit = reverse_conversion ? inputUnit : outputUnit;
			const newUnit = reverse_conversion ? outputUnit : inputUnit;
			rawUnits.push({
				id: copyProduct ? 0 : eq.id,
				isFirstUnit: false,
				isDisplayUnit: newUnit.id === inventory_product.display_unit_id,
				unit_id: copyProduct ? 0 : newUnit.id,
				unit_name: newUnit.name,
				conversion: eq.conversion_constant,
				output_unit_id: copyProduct ? 0 : oldUnit.id,
				output_name: oldUnit.name,
				reverse_conversion,
			});
			onInitUnitNames.push(newUnit.name);
		}
	}

	return rawUnits;
};

const findEquivalentUnit = (
	inventoryProduct: IInventoryProduct,
	unitId: number,
) => {
	const eqUnits =
		inventoryProduct.equivalent_units?.sort((a_el, b_el) => a_el.id - b_el.id) ??
		[];
	for (const eu of eqUnits) {
		const {
			input_inventory_unit_id: inputId,
			output_inventory_unit_id: outputId,
		} = eu;
		if (inputId === unitId || outputId === unitId) {
			return eu;
		}
	}
	return undefined;
};

export const getCustomizeUnits = (inventory_product: IInventoryProduct) => {
	const units: {
		id: number;
		isDisplayUnit: boolean;
		name: string;
		conversion: number;
		reverse_conversion: boolean;
	}[] = [];

	for (const unit of inventory_product.inventory_units ?? []) {
		const eq = findEquivalentUnit(inventory_product, unit.id);
		const reverse_conversion = eq?.input_inventory_unit_id !== unit.id;
		units.push({
			id: unit.id,
			isDisplayUnit: unit.id === inventory_product.display_unit_id,
			name: unit.name,
			conversion:
				(reverse_conversion
					? inventory_product.unit_conversion[inventory_product.display_unit_id]?.[
							unit.id
						]
					: inventory_product.unit_conversion[unit.id]?.[
							inventory_product.display_unit_id
						]) ?? 0,
			reverse_conversion,
		});
	}

	return units;
};
