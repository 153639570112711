import { IProductionPlan } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.ProductionPlans;

export type State = SelectionState<IProductionPlan>;

export const reducer = createSelectionReducer<IProductionPlan>(
	ItemKey.ProductionPlans,
);
