import {
	IWorkOrder,
	ISelectListItem,
	IWorkOrderOutput,
	MeasureUnit,
} from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { EMPTY } from "rxjs";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { Globals } from "app/shared/modules/globals/globals.service";
import { WorkOrderOutputQuery } from "app/shared/eagers";
import { UnitToBaseMeasureUnitPipe } from "app/modules/dashboard/modules/es-pipes/units/unit_to_base_measure_unit.pipe";
import { BaseMeasureUnitToUnitPipe } from "app/modules/dashboard/modules/es-pipes/units/base_measure_unit_to_unit.pipe";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "work-order-add-output",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
// @deprecated
export class WorkOrderAddOutputComponent
	extends GenericCreateComponent<IWorkOrder>
	implements OnInit, OnDestroy
{
	weight_unit = this._globals.weight_unit;

	inventory_types: ISelectListItem[] = [
		{
			name: "Weight",
			name_translation_key: marker("word_weight"),
			value: MeasureUnit.Mass,
			enum: [MeasureUnit.Mass],
		},
		{
			name: "Plants",
			name_translation_key: marker("word_plants"),
			value: "PLANTS",
			enum: ["PLANTS"],
		},
		{
			name: "Quantity",
			name_translation_key: marker("word_quantity"),
			value: MeasureUnit.Quantity,
			enum: [MeasureUnit.Quantity],
		},
	];

	private measure_unit: MeasureUnit;
	private readonly measure_setting: string;
	private measure_setting_fallback: string;
	private readonly work_order_id: number;
	private readonly seed_substance_ids: number[];

	private prevMeasureUnit: MeasureUnit;
	private prevSubstanceType: number;
	private isSubstanceSeed = false;
	private readonly workOrderSourcesIds: number[] = [];
	private readonly duplicate_timestamp: boolean = false;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _unitToBaseMeasureUnitPipe: UnitToBaseMeasureUnitPipe,
		private readonly _baseMeasureUnitToUnitPipe: BaseMeasureUnitToUnitPipe,
		private readonly _injector: Injector,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store);

		this.work_order_id = this._injector.get("work_order_id", null);
		this.seed_substance_ids = this._injector.get("seed_substance_types", null);
		this.model.id = this.work_order_id;
		this.form_title = `Add Output Items to Work Order #${this.work_order_id}`;
		this.form_title_translation_key = marker(
			"form_title_add_output_items_to_work_order",
		);
		this.form_title_translation_params = {
			work_order_id: `#${this.work_order_id}`,
		};
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";
		this.workOrderSourcesIds = this._injector.get(
			"work_order_source_substance_ids",
			null,
		);
		const duplicate: IWorkOrderOutput | null = this._injector.get(
			"duplicate",
			null,
		);

		this.duplicate_timestamp = this._injector.get("duplicate_timestamp", false);

		if (duplicate) {
			this.model = {
				...this.model,
				id: duplicate.work_order_id,
				batch_id: duplicate.batch_id,
			};
			if (duplicate.plants.length > 0) {
				this.model = {
					...this.model,
					inventory_type: "PLANTS",
					plant_selection: "CREATE",
					cultivar_id: duplicate.plants[0]?.cultivar_id,
					growth_stage_id: duplicate.plants[0]?.growth_stage?.id,
					plant_count_create: duplicate.plants.length,
					timestamp: duplicate.plants[0]?.action_updated_at,
				};
			} else if (duplicate.measure_event) {
				const units = this._globals.getFacilityUnit(
					duplicate.measure_event.measure_unit,
					this.measure_setting || "batch",
				);
				const value = Number(
					_baseMeasureUnitToUnitPipe.transform(
						duplicate.measure_event.value,
						duplicate.measure_event.measure_unit,
						units,
					),
				);
				this.prevSubstanceType = Number(duplicate.measure_event.substance_type_id);
				this.model = {
					...this.model,
					inventory_type: duplicate.measure_event.measure_unit,
					substance_type_id: duplicate.measure_event.substance_type_id,
					value,
					timestamp: duplicate.measure_event.timestamp,
				};
			}
		}
		this.schema = {
			title: "",
			description: "",
			info: "",
			properties: {
				id: {
					type: "number",
					hidden: true,
				},
				batch_id: {
					type: "number",
					title: "Destination Batch",
					title_translation_key: marker("form_field_label_destination_batch"),
					widget: "data-select",
					oneOf: [
						{
							result_type: "batches",
							queryString: {
								input: "true",
								dont_show_expired: "true",
							},
						},
					],
				},
				inventory_type: {
					title: "Inventory Type",
					title_translation_key: marker("form_field_label_inventory_type"),
					type: "string",
					widget: "select",
					oneOf: this.inventory_types,
				},
				substance_type_id: {
					title: "Substance Type",
					title_translation_key: marker("word_substance_type"),
					type: "number",
					widget: "data-select",
					oneOf: [
						{
							result_type: "substance_types",
						},
					],
					visibleIf: {
						inventory_type: [MeasureUnit.Mass, MeasureUnit.Quantity],
					},
				},
				use_remaining: {
					title: "Use Remaining",
					title_translation_key: marker("form_field_label_use_remaining"),
					type: "boolean",
					widget: "checkbox",
					default: false,
					visibleIf: {
						allOf: [{ inventory_type: [MeasureUnit.Mass, MeasureUnit.Quantity] }],
					},
				},
				value: {
					type: "number",
					title: `Value`,
					title_translation_key: marker("form_field_label_value"),
					visibleIf: {
						allOf: [
							{
								use_remaining: [false],
							},
							{
								inventory_type: [MeasureUnit.Mass, MeasureUnit.Quantity],
							},
						],
					},
				},
				plant_selection: {
					title: "Plant Output",
					title_translation_key: marker("form_field_label_plant_output"),
					type: "string",
					widget: "radio",
					oneOf: [
						{
							name: "Create new plants.",
							name_translation_key: marker("form_field_value_create_new_plants"),
							value: "CREATE",
							enum: ["CREATE"],
						},
						{
							name: "Output existing plants in work order.",
							name_translation_key: marker(
								"form_field_value_output_existing_plants_in_work_order",
							),
							value: "EXISTING",
							enum: ["EXISTING"],
						},
					],
					visibleIf: {
						allOf: [{ inventory_type: ["PLANTS"] }],
					},
				},
				remaining_plants: {
					title: "Use Remaining Plants",
					title_translation_key: marker("form_field_label_use_remaining_plants"),
					type: "boolean",
					widget: "checkbox",
					default: false,
					visibleIf: {
						allOf: [{ plant_selection: ["EXISTING"] }],
					},
				},
				// use_plant_ids: {
				// 	title: "Use Specific Plant Ids",
				// 	type: "boolean",
				// 	widget: "checkbox",
				// 	default: false,
				// 	visibleIf: {
				// 		allOf: [{ inventory_type: ["PLANTS"] }, { remaining_plants: [false] }],
				// 	},
				// },
				// plant_ids: {
				// 	type: "array",
				// 	title: "Plant Ids",
				// 	widget: "data-select",
				// 	items: {
				// 		type: "number",
				// 		oneOf: [
				// 			{
				// 				result_type: "plants",
				// 				format_text: "#?1 ?2",
				// 				text_key: ["id", "name"],
				// 			},
				// 		],
				// 	},
				// 	visibleIf: {
				// 		allOf: [{ inventory_type: ["PLANTS"] }, { use_plant_ids: [true] }],
				// 	},
				// },
				cultivar_id: {
					type: "number",
					title: "Cultivar",
					title_translation_key: marker("word_cultivar"),
					widget: "data-select",
					oneOf: [
						{
							result_type: "cultivars",
						},
					],
					visibleIf: {
						allOf: [{ inventory_type: ["PLANTS"] }, { plant_selection: ["CREATE"] }],
					},
				},
				growth_stage_id: {
					type: "number",
					title: "Growth Stage",
					title_translation_key: marker("word_growth_stage"),
					widget: "data-select",
					oneOf: [
						{
							result_type: "growth_stages",
							queryString: {
								growth_stage_status: ["ACTIVE"],
							},
						},
					],
					visibleIf: {
						allOf: [{ inventory_type: ["PLANTS"] }, { plant_selection: ["CREATE"] }],
					},
				},
				plant_count_create: {
					type: "number",
					title: "Number of Plants",
					title_translation_key: marker("form_field_label_number_of_plants"),
					visibleIf: {
						allOf: [{ inventory_type: ["PLANTS"] }, { plant_selection: ["CREATE"] }],
					},
				},
				plant_count_use: {
					type: "number",
					title: "Number of Plants",
					title_translation_key: marker("form_field_label_number_of_plants"),
					visibleIf: {
						allOf: [
							{ inventory_type: ["PLANTS"] },
							{ plant_selection: ["EXISTING"] },
							{ remaining_plants: [false] },
						],
					},
				},
				timestamp: {
					type: "string",
					title: "Timestamp",
					title_translation_key: marker("word_timestamp"),
					widget: "date",
				},
				move_batch_to_destination: {
					type: "boolean",
					widget: "checkbox",
					title: "Move destination batch to new location",
					title_translation_key: marker(
						"form_field_label_move_destination_batch_to_new_location",
					),
				},
				destination_location_id: {
					type: "number",
					title: "Destination Location",
					title_translation_key: marker("form_field_label_destination_location"),
					description:
						"Destination batch will be moved to this location when the work order is closed.",
					description_translation_key: marker(
						"form_field_description_destination_batch_will_be_moved_to_this_location_when_the_work_order_is_closed",
					),
					widget: "data-select",
					quick_create: false,
					oneOf: [
						{
							result_type: "locations",
							queryString: {
								input: "true",
							},
						},
					],
					visibleIf: {
						move_batch_to_destination: [true],
					},
				},
			},
			anyOf: [
				{
					required: [
						"id",
						"inventory_type",
						"batch_id",
						"substance_type_id",
						"use_remaining",
					],
				},
				{
					required: [
						"id",
						"inventory_type",
						"batch_id",
						"substance_type_id",
						"value",
					],
				},
				// {
				// 	required: ["id", "batch_id", "use_plant_ids", "plant_ids"],
				// },
				{
					required: [
						"id",
						"inventory_type",
						"batch_id",
						"plant_selection",
						"plant_count_use",
						// "growth_stage_id",
					],
				},
				{
					required: [
						"id",
						"inventory_type",
						"batch_id",
						"plant_selection",
						"plant_count_create",
						// "growth_stage_id",
					],
				},
				{
					required: [
						"id",
						"inventory_type",
						"batch_id",
						"plant_selection",
						"remaining_plants",
						// "growth_stage_id",
					],
				},
			],
		};
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		// clear timestamp for non GMP
		if (
			!this._globals.gmp_enabled &&
			this.schema.properties.timestamp &&
			!this.duplicate_timestamp
		) {
			delete this.model.timestamp;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		if (
			model.inventory_type &&
			model.inventory_type != this.prevMeasureUnit &&
			[MeasureUnit.Mass, MeasureUnit.Quantity].includes(this.model.inventory_type)
		) {
			this.measure_setting_fallback = this.measure_setting || "batch";
			this.measure_unit = model.inventory_type;
			const units = this._globals.getFacilityUnit(
				model.inventory_type,
				this.measure_setting_fallback,
			);
			this.schema.properties.value.title = `Value (${units})`;
			this.schema.properties.value.title_translation_params = { units };
			this.prevMeasureUnit = model.inventory_type;
		}

		if (
			model.substance_type_id &&
			this.prevSubstanceType !== model.substance_type_id
		) {
			let use_remaining_read_only = false;
			if (
				this.workOrderSourcesIds !== null &&
				!this.workOrderSourcesIds.includes(model.substance_type_id)
			) {
				use_remaining_read_only = true;
			}
			this.schema.properties.use_remaining.readOnly = use_remaining_read_only;
			let unitTypeSetting: string;
			if (
				this.seed_substance_ids &&
				this.seed_substance_ids.includes(model.substance_type_id)
			) {
				this.isSubstanceSeed = true;
				unitTypeSetting = "seed_lot";
			} else {
				this.isSubstanceSeed = false;
				unitTypeSetting = this.measure_setting || "batch";
			}

			const units = this._globals.getFacilityUnit(
				model.inventory_type,
				unitTypeSetting,
			);
			this.schema.properties.value.title = `Value (${units})`;
			this.schema.properties.value.title_translation_params = { units };
			this.prevSubstanceType = model.substance_type_id;
		}
	}

	createItem(
		work_order_output: Partial<{
			id: number;
			batch_id: number;
			inventory_type: string | MeasureUnit;
			substance_type_id: number;
			use_remaining?: boolean;
			value?: number;
			plant_selection: string;
			remaining_plants?: boolean;
			use_plant_ids?: boolean;
			plant_ids?: number[];
			cultivar_id?: number;
			growth_stage_id?: number;
			plant_count_create?: number;
			plant_count_use?: number;
			timestamp?: Date;
			destination_location_id?: number;
		}>,
	) {
		// Verify form has use_remaining or value
		if (
			work_order_output.substance_type_id &&
			!work_order_output.use_remaining &&
			(!work_order_output.value || work_order_output.value <= 0)
		) {
			this.error$.next(
				this._translocoService.translate(
					"error_work_order_input_must_include_use_remaining_or_positive_value",
				),
			);
			return;
		}
		this.loading$.next(true);

		let update: any = {
			batch_id: work_order_output.batch_id,
			timestamp: work_order_output.timestamp,
			destination_location_id: work_order_output.destination_location_id,
		};
		if (work_order_output.inventory_type === "PLANTS") {
			update = {
				...update,
				plant_selection: work_order_output.plant_selection,
			};
			if (work_order_output.use_plant_ids) {
				update = {
					...update,
					plant_ids: work_order_output.plant_ids,
				};
			} else if (work_order_output.remaining_plants) {
				update = {
					...update,
					remaining_plants: work_order_output.remaining_plants,
				};
			} else {
				update = {
					...update,
					cultivar_id: work_order_output.cultivar_id,
					growth_stage_id: work_order_output.growth_stage_id,
					plant_count:
						work_order_output.plant_count_create || work_order_output.plant_count_use,
				};
			}
		} else {
			update = {
				...update,
				substance_type_id: work_order_output.substance_type_id,
			};
			if (work_order_output.use_remaining) {
				update = {
					...update,
					use_remaining: work_order_output.use_remaining,
					measure_unit: this.measure_unit,
				};
			} else {
				update = {
					...update,
					value: this._unitToBaseMeasureUnitPipe.transform(
						Number(work_order_output.value),
						this._globals.getFacilityUnit(
							this.measure_unit,
							this.isSubstanceSeed ? "seed_lot" : this.measure_setting,
						) ?? "",
						this.measure_unit,
					),
					measure_unit: this.measure_unit,
				};
			}
		}
		this._itemService
			.add(
				`work_order/${work_order_output.id}/outputs`,
				update,
				WorkOrderOutputQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
