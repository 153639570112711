import {
	tap,
	take,
	takeUntil,
	timeout,
	catchError,
	skipWhile,
} from "rxjs/operators";
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { handleObservableError } from "app/shared/utils";
import { ReplaySubject, Observable, EMPTY } from "rxjs";
import { ILot, UserProfile } from "@elevatedsignals/amygoodman";
import { LotDetailQuery } from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { PasswordInputComponent } from "app/modules/auth/components/password-input";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "lot-move",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class LotMoveComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	lot$ = this._store.select(fromDashboard.getSelectedLot);
	lot: ILot;

	user$: Observable<UserProfile | null> = this._store.select(
		fromDashboard.getProfile,
	);

	user: UserProfile;

	form_title = "Move Lot";
	form_title_translation_key = marker("form_title_move_lot");
	submit_button = "Move Lot";
	submit_button_translation_key = marker("form_button_move_lot");
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			location_id: {
				type: "number",
				title: "Destination Location",
				title_translationkey: marker("form_field_label_destination_location"),
				widget: "data-select",
				oneOf: [{ result_type: "locations" }],
			},
			timestamp: {
				type: "string",
				title: "Date lot moved",
				title_translation_key: marker("form_field_label_date_lot_moved"),
				widget: "date",
			},
		},
		required: ["location_id"],
	};

	validators = {};

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _globals: Globals,
	) {}

	valid(valid) {
		this.valid$.next(valid);
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete (this.schema.properties as any).timestamp;
		}

		this.user$
			.pipe(
				takeUntil(this.destroyed$),
				skipWhile((user: UserProfile | null) => user == null),
			)
			.subscribe((user) => {
				if (user) {
					this.user = user;
				}
			});

		this.lot$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((lot) => {
				this.lot = lot;
				this.model = {
					...this.model,
					id: this.lot.id,
				};
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const movement: any = {
			...this.model,
		};

		if (this._globals.gmp_enabled) {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: PasswordInputComponent,
					inputs: {
						onSubmit: (password) => {
							this.moveRequest(movement, true, password);
						},
					},
				}),
			);
		} else {
			this.moveRequest(movement, true);
		}
	}

	// tslint:disable-next-line:no-empty
	onChanges(form_data): void {}

	moveRequest(move_model: any, signing = false, password?: string) {
		const authObject = password
			? { username: this.user.email, password }
			: undefined;
		this.loading$.next(true);
		this._itemService
			.update(
				`lot`,
				`${this.lot.id}/move`,
				move_model,
				signing
					? {
							...LotDetailQuery,
							signing: "true",
						}
					: LotDetailQuery,
				authObject,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "lots",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
