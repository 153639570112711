/* eslint-disable @typescript-eslint/naming-convention */
import { UserProfile } from "@elevatedsignals/amygoodman";
import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const ProfileActions = createActionGroup({
	source: "Profile",
	events: {
		fetch: emptyProps(),
		"fetch success": props<{ payload: UserProfile }>(),
		"fetch failed": props<{ error: string }>(),
		update: emptyProps(),
		"update success": props<{ payload: UserProfile }>(),
		"update fail": props<{ error: string }>(),
		"update settings": props<{ payload: UserProfile }>(),
		"Update Last Dashboard Version": emptyProps(),
	},
});
