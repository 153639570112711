import { tap, takeUntil, timeout, catchError } from "rxjs/operators";
import {
	Component,
	OnInit,
	OnDestroy,
	Injector,
	ChangeDetectorRef,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { EMPTY } from "rxjs";
import { handleObservableError } from "app/shared/utils";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

@Component({
	selector: "plant-edit-custom-fields",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PlantEditCustomFieldsComponent
	extends GenericUpdateComponent<any>
	implements OnInit, OnDestroy
{
	plant_ids: number[] | undefined;

	schema;

	onUpdated: any;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
	}

	ngOnInit() {
		this.form_title = "Edit Plants' Custom Fields";
		this.form_title_translation_key = marker(
			"form_title_edit_plants_custom_fields",
		);

		this.plant_ids = this._injector.get("ids", null);
		this.onUpdated = this._injector.get("onUpdated", null);

		this._itemService
			.fetchItem("custom_field_model", "plant")
			.subscribe((item) => {
				this.schema = {
					title: "",
					description: "",
					info: "",
					properties: {
						plant_ids: {
							type: "array",
							title: "Selected Plants",
							title_translation_key: marker("form_field_label_selected_plants"),
							widget: "data-select",
							items: { type: "number", oneOf: [{ result_type: "plants" }] },
						},
						...item.schema.properties,
					},
				};

				this.model = {
					...this.model,
					plant_ids: this.plant_ids,
				};
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(formUpdate) {
		const { plant_ids, ...rest } = formUpdate;
		const item = { plant_ids, field_model: rest };
		this.loading$.next(true);
		this._itemService
			.update("plants/custom_fields", "", item, {
				signing: "false",
			})
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((_item) => {
					this.onUpdated();
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
