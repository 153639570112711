import { IWorkOrder, IWorkOrderType, Page } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	Injector,
	OnInit,
	OnDestroy,
	inject,
} from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import {
	WorkOrderDetailQuery,
	BatchSpecialWorkOrdersQuery,
} from "app/shared/eagers";
import { Router } from "@angular/router";
import { PagerService } from "app/modules/dashboard/services/pager.service";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "work-order-start",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderStartComponent
	extends GenericUpdateComponent<IWorkOrder>
	implements OnInit, OnDestroy
{
	work_order_type: IWorkOrderType;
	harvest: boolean | null;
	batch_id: number;

	schema: any = {
		title: "",
		description: this._translocoService.translate(
			"form_description_once_work_order_is_open_any_weight_added",
		),
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			timestamp: {
				type: "string",
				title: "Opening Date",
				title_translation_key: marker("form_field_label_opening_date"),
				widget: "date",
			},
			work_order_description: {
				type: "string",
				widget: "textarea",
				title: "Work Order Description",
				title_translation_key: marker("form_field_label_work_order_description"),
				hidden: true,
			},
			location_id: {
				type: "number",
				readOnly: true,
				title: "Work Order Location",
				title_translation_key: marker("form_field_label_work_order_location"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "locations",
					},
				],
			},
			in_progress_form_schema_uuids: {
				type: "array",
				widget: "data-select",
				title: "Create In Progress Records for:",
				title_translation_key: marker(
					"form_field_label_create_in_progress_records_for",
				),
				quick_create: false,
				items: {
					type: "string",
					oneOf: [
						{
							result_type: "schemas",
							value_key: "type",
						},
					],
				},
			},
			related_equipment_ids: {
				type: "array",
				widget: "data-select",
				title: "Add Related Equipment:",
				title_translation_key: marker("form_field_label_add_related_equipment"),
				quick_create: false,
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "equipments",
						},
					],
				},
			},
		},
		required: ["timestamp"],
	};

	model: any = {};
	private readonly work_order: IWorkOrder | null;

	private readonly _pagerService = inject(PagerService);

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
		private readonly _router: Router,
		private readonly _globals: Globals,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store, _cd);
		this.work_order = this._injector.get("work_order", null);
		this.form_title = this.work_order
			? `Open work order #${this.work_order.id}`
			: `Open work order`;
		this.form_title_translation_key = marker("form_title_open_work_order");
		this.form_title_translation_params = this.work_order
			? { work_order_id: `#${this.work_order.id}` }
			: {};
		this.submit_button = "Open";
		this.submit_button_translation_key = marker("word_open");

		this.model = {
			timestamp: new Date().toISOString(),
		};
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
			delete this.schema.required;
		}
		this.batch_id = this._injector.get("batch_id", null);
		this.harvest = this._injector.get("harvest", null);
		if (this.harvest !== null) {
			this.schema.properties.work_order_description.hidden = false;
		}
		if (this.batch_id) {
			if (this.harvest) {
				this.schema.description = this._translocoService.translate(
					"form_description_note_once_harvest_work_order_is_open_any_plants_added_to_sources_marked_as_harvested",
				);
			} else {
				this.schema.description = this._translocoService.translate(
					"form_description_note_once_drying_work_order_is_open_any_fresh_cannabis_added_to_sources_will_be_removed_from_batch",
				);
			}

			this._pagerService
				.fetchPage({
					result_type: "work_order_types",
					page_size: 999,
					query: {
						search: this.harvest ? "Harvest" : "Drying",
					},
					page: 0,
				} as Page<IWorkOrderType>)
				.pipe(
					takeUntil(this.destroyed$),
					timeout(10000),
					catchError((error) => {
						this.error$.next(handleObservableError(error));
						this.loading$.next(false);
						return EMPTY;
					}),
				)
				.subscribe((items) => {
					if (items.results && items.results.length > 0) {
						const workOrderType: IWorkOrderType = items.results[0]!;
						let updated = false;
						if (workOrderType.default_location_id) {
							this.model.location_id = workOrderType.default_location_id;
							updated = true;
						}

						if (workOrderType.allow_location_change) {
							delete this.schema.properties.location_id.readOnly;
							updated = true;
						}

						if (
							workOrderType.default_form_schema_uuids &&
							workOrderType.default_form_schema_uuids.length > 0
						) {
							this.model.in_progress_form_schema_uuids =
								workOrderType.default_form_schema_uuids;
							updated = true;
						} else {
							delete this.schema.properties.in_progress_form_schema_uuids;
						}

						if (
							this.work_order?.work_order_type?.related_equipment_ids &&
							this.work_order.work_order_type.related_equipment_ids.length > 0
						) {
							this.model.related_equipment_ids =
								this.work_order.work_order_type.related_equipment_ids;
							updated = true;
						} else {
							delete this.schema.properties.related_equipment_ids;
						}

						if (updated) {
							this._cd.detectChanges();
						}
					}
				});
		} else {
			this.model.id = this.work_order?.id;
			this.model.timestamp = new Date().toISOString();

			if (this.work_order?.work_order_type?.default_location_id) {
				this.model.location_id =
					this.work_order.work_order_type.default_location_id;
			}
			if (this.work_order?.work_order_type?.allow_location_change) {
				delete this.schema.properties.location_id.readOnly;
			}

			if (
				this.work_order?.work_order_type?.default_form_schema_uuids &&
				this.work_order.work_order_type.default_form_schema_uuids.length > 0
			) {
				this.model.in_progress_form_schema_uuids =
					this.work_order.work_order_type.default_form_schema_uuids;
			} else {
				delete this.schema.properties.in_progress_form_schema_uuids;
			}

			if (
				this.work_order?.work_order_type?.related_equipment_ids &&
				this.work_order.work_order_type.related_equipment_ids.length > 0
			) {
				this.model.related_equipment_ids =
					this.work_order.work_order_type.related_equipment_ids;
			} else {
				delete this.schema.properties.related_equipment_ids;
			}
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(work_order: any) {
		this.loading$.next(true);
		if (this.batch_id) {
			this._itemService
				.update(
					`batch`,
					`${this.batch_id}/${this.harvest ? "harvest" : "dried"}/begin`,
					{
						...this.model,
						timestamp: this.model.timestamp,
					},
					BatchSpecialWorkOrdersQuery,
				)
				.pipe(
					takeUntil(this.destroyed$),
					timeout(10000),
					catchError((e) => {
						this.error$.next(handleObservableError(e, true));

						this.loading$.next(false);
						return EMPTY;
					}),
				)
				.subscribe((item) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem: item,
							result_type: "batches",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
					if (
						this.harvest &&
						item.harvest_work_orders &&
						item.harvest_work_orders.length > 0
					) {
						this._router.navigate([
							"dashboard",
							"work_orders",
							item.harvest_work_orders[item.harvest_work_orders.length - 1].id,
						]);
					} else if (
						!this.harvest &&
						item.drying_work_orders &&
						item.drying_work_orders.length > 0
					) {
						this._router.navigate([
							"dashboard",
							"work_orders",
							item.drying_work_orders[item.drying_work_orders.length - 1].id,
						]);
					}
				});
		} else {
			let outWorkOrder: any = {
				location_id: work_order.location_id,
				in_progress_form_schema_uuids: work_order.in_progress_form_schema_uuids,
				related_equipment_ids: work_order.related_equipment_ids,
				timestamp: work_order.timestamp ?? new Date().toISOString(),
			};

			if (this._globals.gmp_enabled) {
				outWorkOrder = {
					...outWorkOrder,
					timestamp: new Date().toISOString(),
				};
			}

			this._itemService
				.update(
					`work_order`,
					`${work_order.id}/open`,
					outWorkOrder,
					WorkOrderDetailQuery,
				)
				.pipe(
					takeUntil(this.destroyed$),
					timeout(10000),
					catchError((e) => {
						this.error$.next(handleObservableError(e, true));
						this.loading$.next(false);
						return EMPTY;
					}),
				)
				.subscribe((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				});
		}
	}
}
