import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { TranslocoService } from "@jsverse/transloco";
import { timeout, catchError, tap, take, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	Injector,
	OnInit,
	OnDestroy,
} from "@angular/core";
import { WorkOrderService } from "app/modules/dashboard/services/work-order.service";
import { IWorkOrder } from "@elevatedsignals/amygoodman";
import { Globals } from "app/shared/modules/globals/globals.service";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "work-order-add-plants",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderAddPlantSourceComponent
	extends GenericUpdateComponent<{
		id: number;
		batch_id: number;
		work_order_id: boolean;
		weight: number;
	}>
	implements OnInit, OnDestroy
{
	work_order$ = this._store.select(fromDashboard.getSelectedWorkOrder);
	work_order: IWorkOrder;

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/growth_stage_id": (value, property, form) => {
			if (value !== null) {
				if (this.gs_ids.length > 0) {
					if (this.gs_ids.includes(value)) {
						return null;
					} else {
						return { growth_stage_id: {} };
					}
				}
			}
			return null;
		},
	};

	// Injected from other component
	private batch_id: number;
	private work_order_id: number;
	private total_plants: number;
	private gs_ids: number[] = [];

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _workOrderService: WorkOrderService,
		private readonly _injector: Injector,
		private readonly _globals: Globals,
		private readonly _itemService: ItemService,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store, _cd);
		this.form_title = "Add Plants To Harvest";
		this.form_title_translation_key = marker("form_title_add_plants_to_harvest");
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";

		this.schema = {
			title: "",
			description: "",
			info: "",
			properties: {
				batch_id: {
					type: "number",
					readOnly: true,
					hidden: true,
				},
				plant_type: {
					type: "string",
					title: "Harvest By",
					title_translation_key: marker("form_field_label_harvest_by"),
					widget: "select",
					oneOf: [
						{
							name: "Remaining Plants",
							name_translation_key: marker("form_field_value_remaining_plants"),
							value: "REMAINING",
							enum: ["REMAINING"],
						},
						{
							name: "Plant Count",
							name_translation_key: marker("form_field_value_plant_count"),
							value: "PLANT_COUNT",
							enum: ["PLANT_COUNT"],
						},
						{
							name: "Plant ID(s)",
							name_translation_key: marker("form_field_value_plant_id_s"),
							value: "PLANT_IDS",
							enum: ["PLANT_IDS"],
						},
					],
				},
				plant_ids: {
					type: "array",
					title: "Plant ID(s)",
					title_translation_key: marker("form_field_value_plant_id_s"),
					widget: "data-select",
					related_properties: ["batch_id"],
					items: {
						type: "number",
						oneOf: [
							{
								result_type: "plants",
								queryString: {
									plant_status: "FLOWERING",
								},
							},
						],
					},
					visibleIf: {
						plant_type: ["PLANT_IDS"],
					},
				},
				plant_count: {
					type: "number",
					title: "Number of Plants",
					title_translation_key: marker("form_field_label_number_of_plants"),
					visibleIf: {
						plant_type: ["PLANT_COUNT"],
					},
				},
				growth_stage_type: {
					type: "string",
					title: "Transition Growth Stage To...",
					title_translation_key: marker(
						"form_field_label_transition_growth_stage_to",
					),
					warning:
						"Warning: Selected growth stage is not a harvest growth stage, this will leave the plants unharvested.",
					warning_translation_key: marker(
						"form_field_warning_selected_growth_stage_is_not_a_harvest_growth_stage_this_will_leave_the_plants_unharvested",
					),
					widget: "select",
					oneOf: [
						{
							name: "Harvested",
							name_translation_key: marker("word_harvested"),
							value: "Harvested",
							enum: ["Harvested"],
						},
						{
							name: "Other",
							name_translation_key: marker("word_other"),
							value: "OTHER",
							enum: ["OTHER"],
						},
					],
					default: "Harvested",
				},
				growth_stage_id: {
					type: "number",
					title: "Growth Stage",
					title_translation_key: marker("word_growth_stage"),
					widget: "data-select",
					oneOf: [
						{
							result_type: "growth_stages",
							queryString: {
								excludeTypes: ["Harvested"],
								growth_stage_status: ["ACTIVE"],
							},
						},
					],
					visibleIf: {
						growth_stage_type: ["OTHER"],
					},
				},
				timestamp: {
					type: "string",
					title: "Harvest Date",
					title_translation_key: marker("form_field_label_harvest_date"),
					widget: "date",
				},
				other_growth_stage_selected: {
					type: "boolean",
					widget: "checkbox",
					hidden: true,
					default: true,
					visibleIf: {
						growth_stage_type: ["OTHER"],
					},
				},
				harvest_growth_stage_selected: {
					type: "boolean",
					widget: "checkbox",
					hidden: true,
					default: true,
					visibleIf: {
						growth_stage_type: ["Harvested"],
					},
				},
			},
			anyOf: [
				{ required: ["plant_ids", "harvest_growth_stage_selected"] },
				{ required: ["plant_count", "harvest_growth_stage_selected"] },
				{ required: ["plant_type", "harvest_growth_stage_selected"] },
				{
					required: ["plant_ids", "other_growth_stage_selected", "growth_stage_id"],
				},
				{
					required: [
						"plant_count",
						"other_growth_stage_selected",
						"growth_stage_id",
					],
				},
				{
					required: ["plant_type", "other_growth_stage_selected", "growth_stage_id"],
				},
			],
		};
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.batch_id = this._injector.get("batch_id", null);

		if (this.batch_id) {
			this.model = {
				...this.model,
				batch_id: this.batch_id,
			};
		}

		const plant_count = this._injector.get("plant_count", null);
		if (plant_count && plant_count > 0) {
			this.model = {
				...this.model,
				plant_type: "PLANT_COUNT",
				plant_count,
			};
		}

		const growth_stage_id = this._injector.get("growth_stage_id", null);
		if (growth_stage_id) {
			this.model = {
				...this.model,
				growth_stage_id,
			};
		}

		this.work_order_id = this._injector.get("work_order_id", null);
		this.total_plants = this._injector.get("total_plants", null);
		this.work_order$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((work_order) => {
				this.work_order = work_order;
				if (this.work_order.location_id) {
					this.schema.properties.move_batch_to_work_order_location = {
						type: "boolean",
						widget: "checkbox",
						title: "Move batch to work order location",
						title_translation_key: marker(
							"form_field_label_move_batch_to_work_order_location",
						),
						default: this.work_order.work_order_type?.move_batch_to_location ?? false,
					};
					this.model.move_batch_to_work_order_location =
						this.work_order.work_order_type?.move_batch_to_location ?? false;
				}
				this._cd.detectChanges();
			});

		this._itemService
			.fetchItem(`growth_stages/type`, "Harvested")
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For harvest growth stage validation.
				this.gs_ids = (value ?? []).map((gs) => gs.id);
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(_model) {}

	updateItem(work_order_source: {
		batch_id: number;
		plant_type: string;
		plant_ids: number[] | undefined;
		plant_count: number | undefined;
		growth_stage_type: string;
		growth_stage_id: number;
		timestamp: Date;
	}) {
		this.loading$.next(true);
		if (work_order_source.plant_type === "REMAINING") {
			work_order_source.plant_count = this.total_plants;
		}

		let growth_stage_id = work_order_source.growth_stage_id;
		if (work_order_source.growth_stage_type === "Harvested") {
			if (this.gs_ids[0]) {
				growth_stage_id = this.gs_ids[0];
			} else {
				this.error$.next(
					this._translocoService.translate("error_harvested_growth_stage_not_found"),
				);
				this.loading$.next(false);
				return;
			}
		}
		const update = {
			plant_ids: work_order_source.plant_ids,
			plant_count: work_order_source.plant_count,
			growth_stage_id,
			timestamp: work_order_source.timestamp,
			substance_type: "Plant",
			work_order_id: this.work_order_id,
			batch_id: this.batch_id,
			plant_selection: "EXISTING",
		};
		this._workOrderService
			.addSources(update)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
