import { IDocumentSet } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.DocumentSets;

export type State = SelectionState<IDocumentSet>;

export const reducer = createSelectionReducer<IDocumentSet>(reducerKey);
