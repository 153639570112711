import { IInventory } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.Inventories;

export type State = SelectionState<IInventory>;

export const reducer = createSelectionReducer<IInventory>(reducerKey);
