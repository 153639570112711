import { Component, OnInit } from "@angular/core";
import { PdfViewerComponent, PdfViewerModule } from "ng2-pdf-viewer";
import {
	ComponentModalConfig,
	FuiBaseModal,
	ModalSize,
} from "@elevatedsignals/ngx-fomantic-ui";
import {
	convertToDotsPerMM,
	privateLabelaryUrl,
} from "app/shared/printer-label";

export interface PreviewPrintJobConfig {
	processedTemplate: string;
	labelHeight: number;
	labelWidth: number;
	printerDpi: number;
}

interface LabelPreviewModelContext {
	processedTemplateAndConfig: PreviewPrintJobConfig;
	pdfData: Uint8Array;
}

@Component({
	selector: "label-preview-modal",
	template: `
		<div class="modal-container">
			<h2>Previewing {{ labelCountText }} Labels</h2>
			<div class="pdf-viewer-container">
				<pdf-viewer
					[src]="{ data: modal.context.pdfData }"
					[render-text]="false"
					[original-size]="false"
					[zoom-scale]="'page-width'"
					style="width: 600px; height: 400px; background-color: lightgrey"
				></pdf-viewer>
			</div>
			<div class="label-preview-button-container">
				<button class="ui button refresh" (click)="modal.deny(undefined)">
					Not yet.
				</button>
				<button class="ui button blue refresh" (click)="modal.approve(undefined)">
					<i class="icon print"></i> Send it!
				</button>
			</div>
		</div>
	`,
	styleUrls: ["./label-preview-modal.css"],
	imports: [PdfViewerModule],
	providers: [PdfViewerComponent],
	standalone: true,
})
export class LabelPreviewModalComponent implements OnInit {
	labelCountText: string;

	constructor(
		public modal: FuiBaseModal<LabelPreviewModelContext, void, void>,
	) {}

	ngOnInit(): void {
		this.showPDFs(this.modal.context.processedTemplateAndConfig);
	}

	// Sends a fully substituted and arranged zpl template string to our private labelary server
	// also stores the count of labels printed
	showPDFs = async (previewPrintJobConfig: PreviewPrintJobConfig) => {
		const processedTemplate = previewPrintJobConfig.processedTemplate;
		const labelHeight = previewPrintJobConfig.labelHeight || 1;
		const labelWidth = previewPrintJobConfig.labelWidth || 2;

		const printerDpi = previewPrintJobConfig.printerDpi || 203;
		// Labelary needs printer dpi as a 6/8/12/24 dpmm number
		const printerDpmm = convertToDotsPerMM(printerDpi);

		const formData = new FormData();
		formData.append("file", processedTemplate);

		const hardcodeLabelaryUrl = `${privateLabelaryUrl}/v1/printers/${printerDpmm}dpmm/labels/${labelWidth}x${labelHeight}`;
		const response = await fetch(hardcodeLabelaryUrl, {
			method: "POST",
			headers: [
				["Accept", "application/pdf"],
				["Content-Type", "application/x-www-form-urlencoded"],
			],
			body: formData,
		});

		// Holds the count of labels that will be sent to the printer
		this.labelCountText = response.headers.get("X-Total-Count") ?? "?";
		this.modal.context.pdfData = new Uint8Array(await response.arrayBuffer());
	};
}

export class LabelPreviewModal extends ComponentModalConfig<
	LabelPreviewModelContext,
	void,
	void
> {
	constructor(
		printPreviewJobConfig: PreviewPrintJobConfig,
		size = ModalSize.Small,
	) {
		super(LabelPreviewModalComponent, {
			processedTemplateAndConfig: printPreviewJobConfig,
			pdfData: new Uint8Array(),
		});

		this.isClosable = false;
		this.transitionDuration = 100;
		this.size = size;
	}
}
