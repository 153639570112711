import { IWorkOrderOutput, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "work_order_outputs";

export type State = Page<IWorkOrderOutput>;

export const reducer = createPagingReducer<IWorkOrderOutput>(reducerKey, {
	result_type: "work_order_outputs",
	title: "Work Order Output",
	title_plural: "Work Order Outputs",
	title_translation_key: marker("page_title_work_order_output"),
	title_plural_translation_key: marker("page_title_plural_work_order_outputs"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [],
});
