import { marker } from "@jsverse/transloco-keys-manager/marker";

export const WorkOrderPlantInputSchema = {
	plant_batch_id: {
		type: "number",
		title: "Source Batch",
		title_translation_key: marker("form_field_label_source_batch"),
		widget: "data-select",
		// quick_create: false,
		oneOf: [
			{
				result_type: "batches",
				queryString: {
					input: "true",
					dont_show_expired: "true",
				},
			},
		],
		visibleIf: {
			input_type: ["PLANTS"],
		},
	},
	plant_lot_id: {
		type: "number",
		title: "Source Lot",
		title_translation_key: marker("form_field_label_source_lot"),
		widget: "data-select",
		quick_create: false,
		related_properties: ["plant_batch_id"],
		oneOf: [
			{
				result_type: "lots",
				queryString: {
					input: "true",
					dont_show_expired: "true",
				},
			},
		],
		visibleIf: {
			input_type: ["PLANTS"],
		},
	},
	use_remaining: {
		title: "Use Remaining Plants",
		title_translation_key: marker("form_field_label_use_remaining_plants"),
		type: "boolean",
		widget: "checkbox",
		default: false,
		visibleIf: {
			allOf: [{ input_type: ["PLANTS"] }],
		},
	},
	use_plant_ids: {
		title: "Use Specific Plant Ids",
		title_translation_key: marker("form_field_label_use_specific_plant_ids"),
		type: "boolean",
		widget: "checkbox",
		default: false,
		visibleIf: {
			allOf: [{ input_type: ["PLANTS"] }, { use_remaining: [false] }],
		},
	},
	plant_ids: {
		type: "array",
		title: "Plant Ids",
		title_translation_key: marker("form_field_label_plant_ids"),
		widget: "data-select",
		quick_create: false,
		minItems: 1,
		related_properties: ["plant_batch_id", "plant_lot_id"],
		items: {
			type: "number",
			oneOf: [
				{
					result_type: "plants",
					queryString: {
						plant_status: "ACTIVE",
					},
				},
			],
		},
		visibleIf: {
			allOf: [{ input_type: ["PLANTS"] }, { use_plant_ids: [true] }],
		},
	},
	plant_count: {
		type: "number",
		title: "Number of Plants",
		title_translation_key: marker("form_field_label_number_of_plants"),
		visibleIf: {
			allOf: [{ input_type: ["PLANTS"] }, { use_plant_ids: [false] }],
		},
	},
};
