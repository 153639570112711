import {
	HeaderQueryType,
	IDocumentSet,
	Page,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { DocumentSetDetailQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "document_sets";

export type State = Page<IDocumentSet>;

export const reducer = createPagingReducer<IDocumentSet>(reducerKey, {
	result_type: "document_sets",
	title: "Document Set",
	title_translation_key: marker("page_title_document_sets"),
	title_plural_translation_key: marker("page_title_plural_document_sets"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Show Archived",
			title_translation_key: marker("document_set_filter_show_archived"),
			key: "showArchived",
			type: HeaderQueryType.checkbox,
			value: false,
		},
	],
	query: DocumentSetDetailQuery,
});
