import { ISop } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.Sops;

export type State = SelectionState<ISop>;

export const reducer = createSelectionReducer<ISop>(reducerKey);
