import { Action, createReducer, on } from "@ngrx/store";

import { LimitedUser } from "../models/limited-user.model";
import { AuthActions } from "../actions/auth.actions";

export interface State {
	error: string | null;
	pending: boolean;
	user: LimitedUser | null;
	verify_error: string | null;
	verify_pending: boolean;
}

const initialState: State = {
	error: null,
	pending: false,
	user: null,
	verify_error: null,
	verify_pending: false,
};

const registerPageReducer = createReducer(
	initialState,
	on(AuthActions.navigationSuccess, (state) => {
		return {
			...state,
			pending: false,
		};
	}),
	on(AuthActions.register, (state) => {
		return {
			...state,
			error: null,
			pending: true,
		};
	}),
	on(AuthActions.registerSuccess, (state) => {
		return {
			...state,
			error: null,
			// pending: false, // Stay true while navigating
		};
	}),
	on(AuthActions.registerFail, (state, { error }) => {
		return {
			...state,
			error,
			pending: false,
		};
	}),
	on(AuthActions.registerErrorClear, (state) => {
		return {
			...state,
			error: null,
		};
	}),
	on(AuthActions.verifyRegisterToken, (state) => {
		return {
			...state,
			verify_pending: true,
		};
	}),
	on(AuthActions.verifyRegisterTokenSuccess, (state, { user }) => {
		return {
			...state,
			user,
			// verify_pending: false, // Stay true while navigating
			verify_error: null,
		};
	}),
	on(AuthActions.verifyRegisterTokenFail, (state, { error }) => {
		return {
			...state,
			verify_error: error,
			verify_pending: false,
		};
	}),
);

export const reducer = (state: State | undefined, action: Action) =>
	registerPageReducer(state, action);

export const getPending = (state: State) => state.pending;
export const getError = (state: State) => state.error;

export const getLimitedUser = (state: State) => state.user;
export const getVerifyPending = (state: State) => state.verify_pending;
export const getVerifyError = (state: State) => state.verify_error;
