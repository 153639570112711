import { IProductionStage, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "production_stages";

export type State = Page<IProductionStage>;

export const reducer = createPagingReducer<IProductionStage>(reducerKey, {
	result_type: "production_stages",
	title: "Production Stage",
	title_translation_key: marker("page_title_production_stages"),
	title_plural_translation_key: marker("page_title_plural_production_stages"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
});
