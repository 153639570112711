import { IBatchType } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.BatchTypes;

export type State = SelectionState<IBatchType>;

export const reducer = createSelectionReducer<IBatchType>(reducerKey);
