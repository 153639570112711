import { ISelectListItem, MeasureUnit } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { Globals } from "app/shared/modules/globals/globals.service";
import { WorkOrderInputQuery } from "app/shared/eagers";
import { UnitToBaseMeasureUnitPipe } from "app/modules/dashboard/modules/es-pipes/units/unit_to_base_measure_unit.pipe";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "work-order-add-source-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
// @deprecated
export class WorkOrderAddSourceComponent
	extends GenericCreateComponent<{
		id: number;
		batch_id: number;
		whole_batch: boolean;
		weight: number;
	}>
	implements OnInit, OnDestroy
{
	weight_unit = this._globals.weight_unit;

	inventory_types: ISelectListItem[] = [
		{
			name: "Weight",
			name_translation_key: marker("word_weight"),
			value: MeasureUnit.Mass,
			enum: [MeasureUnit.Mass],
		},
		{
			name: "Plants",
			name_translation_key: marker("word_plants"),
			value: "PLANTS",
			enum: ["PLANTS"],
		},
		{
			name: "Quantity",
			name_translation_key: marker("word_quantity"),
			value: MeasureUnit.Quantity,
			enum: [MeasureUnit.Quantity],
		},
	];

	private measure_unit: MeasureUnit;
	private readonly measure_setting: string;
	private measure_setting_fallback: string;
	private readonly work_order_id: number;
	private readonly move_batch_to_location: boolean;
	private readonly location_id: number;
	private readonly seed_substance_ids: number[] | null;
	private prevMeasureUnit: MeasureUnit;
	private prevSubstanceType: number;
	private isSubstanceSeed = false;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _unitToBaseMeasureUnitPipe: UnitToBaseMeasureUnitPipe,
		private readonly _injector: Injector,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store);
		this.work_order_id = this._injector.get("work_order_id", null);
		this.move_batch_to_location = this._injector.get(
			"move_batch_to_location",
			null,
		);
		this.location_id = this._injector.get("location_id", null);
		this.seed_substance_ids = this._injector.get("seed_substance_types", null);
		this.form_title = `Add Source Items To Work Order #${this.work_order_id}`;
		this.form_title_translation_key = marker(
			"form_title_add_source_items_to_work_order",
		);
		this.form_title_translation_params = {
			work_order_id: `#${this.work_order_id}`,
		};
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";

		this.schema = {
			title: "",
			description: "",
			info: "",
			properties: {
				id: {
					type: "number",
					hidden: true,
				},
				batch_id: {
					type: "number",
					title: "Source Batch",
					title_translation_key: marker("form_field_label_source_batch"),
					widget: "data-select",
					oneOf: [
						{
							result_type: "batches",
							queryString: {
								input: "true",
								dont_show_expired: "true",
							},
						},
					],
				},
				inventory_type: {
					title: "Inventory Type",
					title_translation_key: marker("form_field_label_inventory_type"),
					type: "string",
					widget: "select",
					oneOf: this.inventory_types,
				},
				substance_type_id: {
					title: "Substance Type",
					title_translation_key: marker("word_substance_type"),
					type: "number",
					widget: "data-select",
					oneOf: [
						{
							result_type: "substance_types",
						},
					],
					visibleIf: {
						inventory_type: [MeasureUnit.Mass, MeasureUnit.Quantity],
					},
				},
				use_remaining: {
					title: "Use Remaining",
					title_translation_key: marker("form_field_label_use_remaining"),
					type: "boolean",
					widget: "checkbox",
					default: false,
					visibleIf: {
						inventory_type: [MeasureUnit.Mass, MeasureUnit.Quantity, "PLANTS"],
					},
				},
				value: {
					type: "number",
					title: `Value`,
					title_translation_key: marker("form_field_label_value"),
					visibleIf: {
						allOf: [
							{
								use_remaining: [false],
							},
							{
								inventory_type: [MeasureUnit.Mass, MeasureUnit.Quantity],
							},
						],
					},
				},
				use_plant_ids: {
					title: "Use Specific Plant Ids",
					title_translation_key: marker("form_field_label_use_specific_plant_ids"),
					type: "boolean",
					widget: "checkbox",
					default: false,
					visibleIf: {
						allOf: [{ inventory_type: ["PLANTS"] }, { use_remaining: [false] }],
					},
				},
				plant_ids: {
					type: "array",
					title: "Plant Ids",
					title_translation_key: marker("form_field_label_plant_ids"),
					widget: "data-select",
					related_properties: ["batch_id"],
					items: {
						type: "number",
						oneOf: [
							{
								result_type: "plants",
								queryString: {
									plant_status: "ACTIVE",
								},
							},
						],
					},
					visibleIf: {
						allOf: [
							{ inventory_type: ["PLANTS"] },
							{ use_plant_ids: [true] },
							{ use_remaining: [false] },
						],
					},
				},
				plant_count: {
					type: "number",
					title: "Number of Plants",
					title_translation_key: marker("form_field_label_number_of_plants"),
					visibleIf: {
						allOf: [
							{ inventory_type: ["PLANTS"] },
							{ use_plant_ids: [false] },
							{ use_remaining: [false] },
						],
					},
				},
				timestamp: {
					type: "string",
					title: "Timestamp",
					title_translation_key: marker("word_timestamp"),
					widget: "date",
				},
			},
			anyOf: [
				{
					required: [
						"id",
						"batch_id",
						"inventory_type",
						"substance_type_id",
						"use_remaining",
					],
				},
				{
					required: [
						"id",
						"batch_id",
						"inventory_type",
						"substance_type_id",
						"value",
					],
				},
				{
					required: [
						"id",
						"batch_id",
						"inventory_type",
						"use_plant_ids",
						"plant_ids",
					],
				},
				{
					required: ["id", "batch_id", "inventory_type", "plant_count"],
				},
				{
					required: ["id", "batch_id", "inventory_type", "use_remaining"],
				},
			],
		};
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.model.id = this.work_order_id;

		if (this.location_id) {
			this.schema.properties = {
				...this.schema.properties,
				move_batch_to_work_order_location: {
					type: "boolean",
					widget: "checkbox",
					title: "Move batch to work order location",
					title_translation_key: marker(
						"form_field_label_move_batch_to_work_order_location",
					),
					default: this.move_batch_to_location,
				},
			};
			this.model.move_batch_to_work_order_location = this.move_batch_to_location;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		if (
			model.inventory_type &&
			model.inventory_type !== this.prevMeasureUnit &&
			[MeasureUnit.Mass, MeasureUnit.Quantity].includes(this.model.inventory_type)
		) {
			this.measure_setting_fallback = this.measure_setting || "batch";
			this.measure_unit = model.inventory_type;
			const units = this._globals.getFacilityUnit(
				model.inventory_type,
				this.measure_setting_fallback,
			);

			// ESS-5451 schema not updating on changes
			this.schema = {
				properties: {
					...this.schema.properties,
					value: {
						...this.schema.properties.value,
						title: `Value (${units})`,
						title_translation_params: { units },
					},
				},
			};
			this.prevMeasureUnit = model.inventory_type;
			this._cd.detectChanges();
		}

		if (
			model.substance_type_id &&
			this.prevSubstanceType !== model.substance_type_id
		) {
			let unitTypeSetting: string;
			if (this.seed_substance_ids?.includes(model.substance_type_id)) {
				this.isSubstanceSeed = true;
				unitTypeSetting = "seed_lot";
			} else {
				this.isSubstanceSeed = false;
				unitTypeSetting = this.measure_setting || "batch";
			}

			const units = this._globals.getFacilityUnit(
				model.inventory_type,
				unitTypeSetting,
			);
			this.schema.properties.value.title = `Value (${units})`;
			this.schema.properties.value.title_translation_params = { units };
			this.prevSubstanceType = model.substance_type_id;
		}
	}

	createItem(
		work_order_source: Partial<{
			id: number;
			batch_id: number;
			inventory_type: string;
			substance_type_id: string;
			use_remaining?: boolean;
			value?: number;
			use_plant_ids?: boolean;
			plant_ids?: number[];
			plant_count?: number;
			timestamp?: Date;
			move_batch_to_work_order_location?: boolean;
		}>,
	) {
		// Verify form has remaining_weight or weight
		if (
			work_order_source.substance_type_id &&
			!work_order_source.use_remaining &&
			(!work_order_source.value || work_order_source.value <= 0)
		) {
			this.error$.next(
				this._translocoService.translate(
					"error_work_order_input_must_include_remaining_weight_or_positive_weight",
				),
			);
			return;
		}
		this.loading$.next(true);

		let update: any = {
			batch_id: work_order_source.batch_id,
			timestamp: work_order_source.timestamp,
			move_batch_to_work_order_location:
				work_order_source.move_batch_to_work_order_location ?? false,
		};
		switch (work_order_source.inventory_type) {
			case "PLANTS":
				if (work_order_source.use_plant_ids) {
					update = {
						...update,
						plant_ids: work_order_source.plant_ids,
					};
				} else if (work_order_source.use_remaining) {
					update = {
						...update,
						use_remaining: work_order_source.use_remaining,
					};
				} else {
					update = {
						...update,
						plant_count: work_order_source.plant_count,
					};
				}
				break;
			default:
				update = {
					...update,
					substance_type_id: work_order_source.substance_type_id,
					measure_unit: this.measure_unit,
				};
				if (work_order_source.use_remaining) {
					update = {
						...update,
						use_remaining: work_order_source.use_remaining,
					};
				} else {
					update = {
						...update,
						value: this._unitToBaseMeasureUnitPipe.transform(
							work_order_source.value ?? 0,
							this._globals.getFacilityUnit(
								this.measure_unit,
								this.isSubstanceSeed ? "seed_lot" : this.measure_setting,
							)!,
							this.measure_unit,
						),
					};
				}
		}
		this._itemService
			.add(
				`work_order/${work_order_source.id}/sources`,
				update,
				WorkOrderInputQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
