import { IWorkOrder } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { WorkOrderEquipmentsQuery } from "app/shared/eagers";
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { timeout, catchError, take, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "work-order-add-equipment-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderAddEquipmentComponent
	extends GenericUpdateComponent<{
		id: number;
	}>
	implements OnInit, OnDestroy
{
	work_order$ = this._store.select(fromDashboard.getSelectedWorkOrder);
	work_order: IWorkOrder;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
	) {
		super(_store, _cd);
		this.form_title = "Add Equipment";
		this.form_title_translation_key = marker("form_title_add_equipment");
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";

		this.schema = {
			title: "",
			description: "",
			info: "",
			properties: {
				id: {
					type: "number",
					hidden: true,
				},
				equipment_ids: {
					type: "array",
					title: "Equipment",
					title_translation_key: marker("word_equipment"),
					widget: "data-select",
					items: {
						type: "number",
						oneOf: [
							{
								result_type: "equipments",
								text_key: ["name", "serial_number"],
								text_format: "?1 #?2",
								queryString: {
									input: "true",
								},
							},
						],
					},
				},
			},
			anyOf: [{ required: ["equipment_ids"] }],
		};
	}

	ngOnInit() {
		this.work_order$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((work_order: IWorkOrder) => {
				this.work_order = work_order;
				this.model.id = this.work_order.id;
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(
		work_order_equipment: Partial<{
			id: number;
			equipment_ids: number[];
		}>,
	) {
		this.loading$.next(true);
		const new_equipment: {
			work_order_id: number | undefined;
			equipment_id: number;
		}[] = [];
		work_order_equipment.equipment_ids?.map((id) => {
			new_equipment.push({
				work_order_id: work_order_equipment.id,
				equipment_id: id,
			});
		});
		const update = {
			equipments: [...(this.work_order.equipments || []), ...new_equipment],
		};

		this._itemService
			.update(
				`work_order`,
				`${work_order_equipment.id}/equipments`,
				update,
				WorkOrderEquipmentsQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
