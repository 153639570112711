/* eslint-disable @typescript-eslint/naming-convention */
import { createActionGroup, emptyProps, props } from "@ngrx/store";

import { FacilitySettings } from "../state/facilitySettings.state";

export const FacilitySettingsActions = createActionGroup({
	source: "Facility Settings",
	events: {
		fetch: props<{ facilityId: number }>(),
		"fetch success": props<{ facilityInfo: FacilitySettings }>(),
		"fetch fail": props<{ error: string }>(),
		"fetch api version": emptyProps(),
		"fetch api version success": props<{ apiVersion: string }>(),
		"fetch api version fail": props<{ error: string }>(),
	},
});
