import { Component, Signal, computed } from "@angular/core";
import {
	ComponentModalConfig,
	FuiBaseModal,
	ModalSize,
} from "@elevatedsignals/ngx-fomantic-ui";
import { AuthActions } from "app/modules/auth/actions/auth.actions";
import * as fromAuth from "app/modules/auth/reducers";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { Store } from "@ngrx/store";
import { UserSignin } from "app/modules/auth/models/user-signin.model";
import { AuthService } from "app/modules/auth/services/auth.service";
import { ProfileActions } from "app/modules/dashboard/actions/profile.actions";
import { EnvService } from "app/shared/services/env.service";

interface ExpiredModalContext {
	email?: string;
	isExternalUser: boolean;
}

@Component({
	selector: "es-expired-modal",
	template: `
		<div class="modal-container">
			<h4>
				<div class="logo"></div>
				<div class="content">Your session has expired, sign in to resume.</div>
			</h4>
			<es-signin-input
				*ngIf="isExternalUser === false"
				(signin)="signin($event)"
				[pending]="pending$ | async"
				[errorMessage]="error$ | async"
				[showLogo]="false"
				[email]="modal.context.email"
			>
			</es-signin-input>
			<es-auth-sso-section
				*ngIf="isExternalUser"
				(initSignIn)="signInOAuth()"
			></es-auth-sso-section>
		</div>
	`,
	styleUrls: ["./expired-modal.scss"],
})
export class ExpiredModalComponent {
	dark = true;
	pending$ = this._authStore.select(fromAuth.getSigninPagePending);
	error$ = this._authStore.select(fromAuth.getSigninPageError);

	isExternalUser: boolean;

	constructor(
		public modal: FuiBaseModal<ExpiredModalContext, void, void>,
		private readonly _authStore: Store<fromAuth.State>,
		private readonly _dashStore: Store<fromDashboard.State>,
		private readonly _authService: AuthService,
		private readonly _env: EnvService,
	) {
		this.isExternalUser = modal.context.isExternalUser;
	}

	signInOAuth() {
		const url = new URL(
			`${this._env.es_api_server}${this._env.es_client_api}/providers/microsoft`,
		);
		url.searchParams.set("token_redirect", window.location.origin);

		window.location.href = url.toString();
	}

	signin(userSignin: UserSignin) {
		this.modal.approve();
		this._authService.signin(userSignin).subscribe((auth) => {
			this._authStore.dispatch(AuthActions.authenticateSuccess({ auth }));
			this._dashStore.dispatch(ProfileActions.fetch());
		});
	}
}
export class ExpiredModal extends ComponentModalConfig<
	ExpiredModalContext,
	void,
	void
> {
	constructor(context: ExpiredModalContext, size = ModalSize.Small) {
		super(ExpiredModalComponent, context, false);

		this.isClosable = false;
		this.transitionDuration = 200;
		this.size = size;
		this.context = context;
	}
}
