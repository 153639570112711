import { StatusType } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const NON_GI_STATUS_TYPES: {
	name: string;
	name_translation_key: string;
}[] = [
	{ name: StatusType.Batch, name_translation_key: marker("word_batch") },
	{ name: StatusType.Inventory, name_translation_key: marker("word_inventory") },
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const GI_STATUS_TYPES: { name: string; name_translation_key: string }[] =
	[
		{ name: StatusType.Batch, name_translation_key: marker("word_batch") },
		{
			name: StatusType.Inventory,
			name_translation_key: marker("word_inventory"),
		},
		{ name: StatusType.Lot, name_translation_key: marker("word_lot") },
		{
			name: StatusType.PurchaseOrder,
			name_translation_key: marker("word_purchase_order"),
		},
		{ name: StatusType.Vendor, name_translation_key: marker("word_vendor") },
		{
			name: StatusType.WorkOrder,
			name_translation_key: marker("word_work_order"),
		},
		{ name: StatusType.Record, name_translation_key: marker("word_record") },
	];
