import {
	EquivalentUnit,
	HeaderQueryType,
	Page,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { EquivalentUnitPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "equivalent_units";

export type State = Page<EquivalentUnit>;

export const reducer = createPagingReducer<EquivalentUnit>(reducerKey, {
	result_type: "equivalent_units",
	title: "Equivalent Unit",
	title_translation_key: marker("page_title_equivalent_units"),
	title_plural_translation_key: marker("page_title_plural_equivalent_units"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Filter by Product",
			key: "inventory_product_id",
			type: HeaderQueryType.dataSelect,
			result_type: "inventory_products",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: false,
		},
	],
	query: EquivalentUnitPageQuery,
});
