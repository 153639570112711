import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, take, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { IBillOfMaterial } from "@elevatedsignals/amygoodman";
import { BillOfMaterialDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

@Component({
	selector: "bill-of-material-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class BillOfMaterialUpdateComponent
	extends GenericUpdateComponent<IBillOfMaterial>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			work_order_type_id: {
				type: "number",
				title: "Work Order Type",
				title_translation_key: marker("word_work_order_type"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "work_order_types",
					},
				],
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
		},
	};

	bill_of_material$ = this._store.select(
		fromDashboard.getSelectedBillOfMaterial,
	);

	bill_of_material: IBillOfMaterial;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
	) {
		super(_store, _cd);
		this.form_title = "Update Bill of Material";
		this.form_title_translation_key = marker(
			"form_title_update_bill_of_material",
		);
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.bill_of_material$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((bill_of_material: IBillOfMaterial) => {
				this.bill_of_material = bill_of_material;
				this.model.id = this.bill_of_material.id;
				this.model.name = this.bill_of_material.name;
				this.model.work_order_type_id = this.bill_of_material.work_order_type_id;
				this.model.archived = this.bill_of_material.archived;
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(type: Partial<IBillOfMaterial>) {
		const updated_bom: Partial<IBillOfMaterial> = {
			name: type.name || undefined,
			work_order_type_id: type.work_order_type_id || undefined,
			archived: type.archived || undefined,
		};

		this.loading$.next(true);
		this._itemService
			.update(
				`bill_of_material`,
				Number(type.id),
				updated_bom,
				BillOfMaterialDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "bill_of_materials",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
