import Moment from "moment-timezone";
import "moment/locale/fr";
import "moment/locale/fr-ca";
import {
	isBefore,
	isAfter,
	isEqual,
	format,
	subSeconds,
	getUnixTime,
	startOfDay,
	endOfDay,
	startOfMonth,
	getTime,
	endOfMonth,
} from "date-fns";
import { formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { enCA, frCA } from "date-fns/locale";
import {
	getAllTimezones,
	getTimezonesForCountry,
} from "countries-and-timezones";

// Some formatters don't directly translate to date-fns so just need to look into that.
// To eventually replace DateFormats
export enum DateFormatsForDateFns {
	yearMonthDay = "yyyy-MM-dd",
	hourMinute = "HH:mm",
	isoWeekOfTheYear = "I",
	monthDayYearTime24HourWithDashes = "MMMM-do-yyyy-h-mm",
}

export enum DateFormatsSetting {
	monthDayYear = "MMM Do YYYY",
	monthDayYearShort = "MMM/D/Y",
	monthDayYearTimeDetailed = "MMM Do YYYY HH:mm:ss.SSSSSS",
	monthDayYear12Hour = "MMM Do YYYY, hh:mm a",
	monthDayYear24Hour = "MMM Do YYYY, HH:mm",
	monthDayYear12HourWeekday = "dddd, MMM Do YYYY, hh:mm a",
	monthDayYear24HourWeekday = "dddd, MMM Do YYYY, HH:mm",
	monthDayYear12HourMonthNameWeekday = "dddd, MMMM Do YYYY, hh:mm a",
	monthDayYear24HourMonthNameWeekday = "dddd, MMMM Do YYYY, HH:mm",
	monthDayYearFullMonth = "MMMM Do, YYYY",

	dayMonthYear = "Do MMM YYYY",
	dayMonthYearShort = "D/MMM/Y",
	dayMonthYearTimeDetailed = "Do MMM YYYY HH:mm:ss.SSSSSS",
	dayMonthYear12Hour = "Do MMM YYYY, hh:mm a",
	dayMonthYear24Hour = "Do MMM YYYY, HH:mm",
	dayMonthYear12HourWeekday = "dddd, Do MMM YYYY, hh:mm a",
	dayMonthYear24HourWeekday = "dddd, Do MMM YYYY, HH:mm",
	dayMonthYear12HourMonthNameWeekday = "dddd, Do MMMM YYYY, hh:mm a",
	dayMonthYear24HourMonthNameWeekday = "dddd, Do MMMM YYYY, HH:mm",
	dayMonthYearFullMonth = "Do MMMM YYYY",

	yearMonthDay = "YYYY MMM Do",
	yearMonthDayShort = "Y/MMM/D",
	yearMonthDayTimeDetailed = "YYYY MMM Do HH:mm:ss.SSSSSS",
	yearMonthDay12Hour = "YYYY MMM Do, hh:mm a",
	yearMonthDay24Hour = "YYYY MMM Do, HH:mm",
	yearMonthDay12HourWeekday = "dddd, YYYY MMM Do, hh:mm a",
	yearMonthDay24HourWeekday = "dddd, YYYY MMM Do, HH:mm",
	yearMonthDay12HourMonthNameWeekday = "dddd, YYYY MMMM Do, hh:mm a",
	yearMonthDay24HourMonthNameWeekday = "dddd, YYYY MMMM Do, HH:mm",
	yearMonthDayFullMonth = "YYYY MMMM Do",
}

export enum DateFormatsSettingForDateFns {
	monthDayYear = "MMM do yyyy",
	dayMonthYear = "do MMM yyyy",
	yearMonthDay = "yyyy MMM do",
}

export enum DateFormatFacilitySetting {
	MonthDayYear = "monthDayYear",
	DayMonthYear = "dayMonthYear",
	YearMonthDay = "yearMonthDay",
}

export interface MomentParams {
	moment?: Moment.Moment;
	date?: Date | string | number;
	utc?: boolean;
	add?: number;
	addInterval?: string;
	subtract?: number;
	subtractInterval?: string;
	startOf?: string;
	endOf?: string;
	clone?: boolean;
	timezone?: string;
	parsezone?: string;
}

export const createAndFormatDateTime = (
	date: Date | string = new Date(),
	format?: DateFormatsSetting,
	timezone?: string,
	desiredLanguage?: string,
): string => {
	const momentDate = timezone
		? Moment(date)
				.locale(desiredLanguage ?? "en")
				.tz(timezone)
		: Moment(date).locale(desiredLanguage ?? "en");
	return format ? momentDate.format(format) : momentDate.format();
};

export const createMomentWithFormat = (
	date: Date | string | Moment.Moment = new Date(),
	format?: DateFormatsSetting,
): Moment.Moment => {
	return format ? Moment(date, format) : Moment(date);
};

// Intakes a lang string from transloco.getActiveLang(), could be 'en' or 'fr',
// and returns the correct date-fns locale
const getDateFnsLocale = (desiredLanguage?: string): Locale => {
	if (desiredLanguage === "en") {
		return enCA;
	} else if (desiredLanguage === "fr") {
		return frCA;
	} else {
		return enCA;
	}
};

export const getISOString = (
	date: Date | string | Moment.Moment = new Date(),
): string => {
	return Moment(date).toISOString();
};

export const dateIsBefore = (date1: Date, date2: Date): boolean => {
	return isBefore(date1, date2);
};

export const dateIsAfter = (date1: Date, date2: Date): boolean => {
	return isAfter(date1, date2);
};

export const getIsSameOrBefore = (left: Date, right: Date): boolean => {
	return isBefore(left, right) || isEqual(left, right);
};

export const getNow = (): number => {
	return Number(new Date());
};

export const getMilliseconds = (date: Date): number => {
	return getTime(date);
};

const oldCanadaTimeZones: TzOut[] = [
	{ label: "Canada/Pacific (GMT-8:00)", key: "Canada/Pacific" },
	{ label: "Canada/Mountain (GMT-7:00)", key: "Canada/Mountain" },
	{ label: "Canada/Yukon (GMT-7:00)", key: "Canada/Yukon" },
	{ label: "Canada/Saskatchewan (GMT-6:00)", key: "Canada/Saskatchewan" },
	{ label: "Canada/Central (GMT-6:00)", key: "Canada/Central" },
	{ label: "Canada/Eastern (GMT-5:00)", key: "Canada/Eastern" },
	{ label: "Canada/Atlantic (GMT-4:00)", key: "Canada/Atlantic" },
	{ label: "Canada/Newfoundland (GMT-3:30)", key: "Canada/Newfoundland" },
];

export interface TzOut {
	label: string;
	key: string;
}

export const getTzNames = (): TzOut[] => {
	const timezones = getAllTimezones();
	const tz = Object.values(timezones).map((tz) => {
		return {
			label: `${tz.name} (GMT${tz.utcOffsetStr})`,
			key: tz.name,
		} as TzOut;
	});

	// Preferably don't want the old canada timezones to be used.
	// So they've been move to the end.
	return [...tz, ...oldCanadaTimeZones];
};

export const getTzNamesForCountry = (countryId: string): TzOut[] => {
	const countryTimezones = getTimezonesForCountry(countryId);
	const countryTzNames = Object.values(countryTimezones!).map((tz) => {
		return {
			label: `${tz.name} (GMT${tz.utcOffsetStr})`,
			key: tz.name,
		} as TzOut;
	});

	return countryId === "CA"
		? [...countryTzNames, ...oldCanadaTimeZones]
		: countryTzNames;
};

export const itemIsNew = (itemCreationDate: Date): boolean => {
	const itemIsNewDate = subSeconds(new Date(), 30);
	return dateIsBefore(itemIsNewDate, itemCreationDate);
};

export const getFormattedStartOfMonthISO = (date: Date = new Date()) => {
	return startOfMonth(date).toISOString();
};

export const getFormattedEndOfMonthISO = (date: Date = new Date()) => {
	return endOfMonth(date).toISOString();
};

export const getFormattedEndOfDayISO = (date: Date = new Date()) => {
	return endOfDay(date).toISOString();
};

export const getFormattedStartOfDay = (date: Date = new Date()) => {
	return formatWithDateFns(startOfDay(date), DateFormatsForDateFns.yearMonthDay);
};

export const getFormattedEndOfDay = (date: Date = new Date()) => {
	return formatWithDateFns(endOfDay(date), DateFormatsForDateFns.yearMonthDay);
};

const formatWithDateFns = (
	date: Date,
	dateFormat: DateFormatsForDateFns | DateFormatsSettingForDateFns,
	timeZone?: string,
	desiredLanguage?: string,
): string => {
	const dateFnsLocale = getDateFnsLocale(desiredLanguage);

	if (timeZone) {
		return formatInTimeZone(date, timeZone, dateFormat, {
			locale: dateFnsLocale,
		});
	}

	return format(date, dateFormat, { locale: dateFnsLocale });
};

export const getUnix = (date: Date): number => {
	return getUnixTime(date);
};

export enum FormatOption {
	timeDetailed = "TimeDetailed",
	includeWeekName = "Weekday",
	includeWeekNameAndMonthName = "MonthNameWeekday",
	includeFullMonthName = "FullMonth",
}

export const getDateFormat = (
	facility_date_format: DateFormatFacilitySetting,
) => {
	switch (facility_date_format) {
		case DateFormatFacilitySetting.MonthDayYear:
			return "MM/dd/yy";
		case DateFormatFacilitySetting.DayMonthYear:
			return "dd/MM/yy";
		case DateFormatFacilitySetting.YearMonthDay:
			return "yy/MM/dd";
		default:
			return "MM/dd/yy";
	}
};

export const timeFormat = (format: "24h" | "12h") => {
	return format === "24h" ? "HH:mm" : "h:mm a";
};
