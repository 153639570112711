import {
	Component,
	OnInit,
	OnDestroy,
	Injector,
	ChangeDetectorRef,
	inject,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { Plant } from "@elevatedsignals/amygoodman";
import { Globals } from "app/shared/modules/globals/globals.service";
import { BatchDetailQuery, LotDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ReplaySubject, EMPTY, combineLatest, switchMap } from "rxjs";
import { tap, takeUntil, timeout, catchError } from "rxjs/operators";
import { DevCycleService } from "app/devcycle/devcycle.service";
import { DevCycleKey } from "app/devcycle/devcycleKeys";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import {
	ezGiMigration,
	generalInventoryEnabled,
} from "app/modules/dashboard/selectors/facility-settings.selector";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { FetchPageActions } from "app/modules/dashboard/actions/paging.actions";
import { PagerService } from "app/modules/dashboard/services/pager.service";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

import { schema } from "./create-plant.schema";

@Component({
	selector: "plant-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PlantCreateComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();
	showLocationDestination = true;
	generalInventoryEnabled$ = this._store.select(generalInventoryEnabled);
	giEnabled = false;
	ezGiMigrated$ = this._store.select(ezGiMigration);
	ezGiMigrated = false;

	form_title = "Create a Plant";
	form_title_translation_key: string = marker("form_title_create_a_plant");
	submit_button = "Create Plant";
	submit_button_translation_key: string = marker("form_button_create_plant");
	submit_icon = "plus";
	specific_field_id: string;

	updating = false;
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema: any;

	validators = {};

	// Injected from location details new plant button
	private cultivar_id: number;
	private batch_id: number;
	private result_type: "batch" | "lot" | "plants";
	private lot_id: number;

	private _pagerService = inject(PagerService);

	constructor(
		private readonly _injector: Injector,
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _globals: Globals,
		private readonly _devCycleService: DevCycleService,
	) {
		this.specific_field_id = this._injector.get("specific_field_id", null);

		combineLatest([this.generalInventoryEnabled$, this.ezGiMigrated$]).subscribe(
			(values) => {
				this.giEnabled = values[0];
				this.ezGiMigrated = values[1];
			},
		);
	}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		this._devCycleService
			.getVariable(DevCycleKey.ShowPlantLots, false)
			.subscribe((variable: any) => {
				this.schema = schema;

				if (this.ezGiMigrated) {
					delete this.schema.properties.lot_id;
				} else if (!this.giEnabled) {
					delete this.schema.properties.lot_id;
				} else if (!variable.value) {
					delete this.schema.properties.lot_id;
				}

				if (this._globals.gmp_enabled) {
					delete (this.schema.properties as any).timestamp;
				}
			});

		this.batch_id = this._injector.get("batch_id", null);
		this.cultivar_id = this._injector.get("cultivar_id", null);
		this.result_type = this._injector.get("result_type", "plants");
		this.lot_id = this._injector.get("lot_id", null);

		if (this.cultivar_id || this.batch_id || this.lot_id) {
			if (this.cultivar_id) {
				this.model.cultivar_id = this.cultivar_id;
			}

			if (this.batch_id) {
				this.model.location = "BATCH";
				this.model.batch_id = this.batch_id;
			}

			if (this.lot_id) {
				this.model.lot_id = this.lot_id;
			}

			this._cd.detectChanges();
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const plant: Partial<Plant> & { result_type: string } = {
			...this.model,
			result_type: this.result_type,
		};
		this.addPlant(plant);
	}

	onChanges(_error): void {}

	addPlant(add_plant: Partial<Plant> & { result_type: string }) {
		this.loading$.next(true);
		let query: any = null;
		switch (add_plant.result_type) {
			case "batch":
				query = BatchDetailQuery;
				break;
			case "lot":
				query = LotDetailQuery;
				break;
		}
		this._itemService
			.add(`plants`, add_plant, query)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					let type;
					let type_id;
					switch (this.result_type) {
						case "batch":
							type = "batches";
							type_id = this.batch_id;
							break;
						case "lot":
							type = "lots";
							type_id = this.lot_id;
							break;
						default:
							this._store.dispatch(
								ItemActions.addItemsSuccess({
									items: addedItem,
									result_type: "plants",
									...(this.specific_field_id && {
										specific_field_id: this.specific_field_id,
									}),
								}),
							);
					}

					if (type && type_id) {
						this._store.dispatch(
							ItemActions.updateSuccess({
								updatedItem: addedItem,
								result_type: type,
							}),
						);

						this._store
							.select(fromDashboard.getPlantPage)
							.pipe(
								takeUntil(this.destroyed$),
								isNotNullOrUndefined(),
								switchMap((page) => {
									return this._pagerService.fetchPage({
										...page,
										type,
										type_id,
										result_type: "plants",
										page_type: "plants",
									});
								}),
							)
							.subscribe((updatedPage) => {
								this._store.dispatch(
									FetchPageActions.fetchPageSuccess({
										payload: updatedPage,
									}),
								);
							});
					}

					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
