import { tap, takeUntil, timeout, catchError } from "rxjs/operators";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { handleObservableError } from "app/shared/utils";
import { ReplaySubject, EMPTY } from "rxjs";
import { Batch } from "@elevatedsignals/amygoodman";
import { BatchDetailQuery } from "app/shared/eagers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";

@Component({
	selector: "batch-record-dried",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class BatchRecordDriedComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	batch$ = this._store.select(fromDashboard.getSelectedBatch);
	batch: Batch;

	form_title = "Record dried batch weight";
	form_title_translation_key: string = marker(
		"form_title_record_dried_batch_weight",
	);

	submit_button = "Record Weight";
	submit_button_translation_key: string = marker("form_button_record_weight");
	submit_icon: string | null = null;
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			location_id: {
				type: "number",
				title: "Destination Location",
				title_translation_key: marker("form_field_label_destination_location"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "locations",
					},
				],
			},
			production_stage_id: {
				type: "number",
				title: "Production Stage",
				title_translation_key: marker("word_production_stage"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "production_stages",
					},
				],
			},
			timestamp: {
				type: "string",
				title: "Dried Date",
				title_translation_key: marker("form_field_label_dried_date"),
				widget: "date",
			},
		},
		required: ["location_id", "production_stage_id"],
	};

	validators = {};

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
	) {}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		this.batch$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined())
			.subscribe((batch: Batch) => {
				this.batch = batch;
				this.model = {
					id: this.batch.id,
					production_stage_id: this.batch.production_stage_id
						? this.batch.production_stage_id
						: undefined,
					location_id: this.batch.location_id,
				};
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const harvest: any = {
			...this.model,
		};
		this.updateBatch(harvest);
	}

	onChanges(form_data): void {
		// empty
	}

	updateBatch(update_batch: any) {
		this.loading$.next(true);
		this._itemService
			.update(
				`batch`,
				`${this.batch.id}/dried/close`,
				update_batch,
				BatchDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "batches",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
