import { createReducer, on } from "@ngrx/store";

import { FacilitySettingsActions } from "../actions/facilitySettings.actions";
import { FacilitySettings } from "../state/facilitySettings.state";

export interface State {
	settings: FacilitySettings;
	apiVersion: string;
	initialApiVersion: string;
	api: {
		fetch: {
			error?: string | null;
			in_progress: boolean;
		};
		fetchApiVersion: {
			error?: string | null;
			in_progress: boolean;
		};
	};
}

const initialState: State = {
	settings: {
		is_production: true,
		data: {},
		time_format: "12h",
		timezone: "America/Vancouver",
		drying_archived: true,
		harvest_archived: true,
		licence: {
			general_inventory: false,
			gmp: false,
			ez_gi_migration: false,
			cultivation: false,
			processing: false,
			quality: false,
			non_cannabis: false,
		},
		country: "CA",
		reporting_periods: [],
		wet_weight_inventory_products: [],
	},
	apiVersion: "",
	initialApiVersion: "",
	api: {
		fetch: {
			in_progress: true,
		},
		fetchApiVersion: {
			in_progress: false,
		},
	},
};

export const reducer = createReducer(
	initialState,
	on(FacilitySettingsActions.fetch, (state) => ({
		...state,
		api: { ...state.api, fetch: { in_progress: true } },
	})),
	on(FacilitySettingsActions.fetchSuccess, (state, updateValue) => {
		return {
			...state,
			api: { ...state.api, fetch: { in_progress: false } },
			settings: updateValue.facilityInfo,
		};
	}),
	on(FacilitySettingsActions.fetchApiVersion, (state) => ({
		...state,
		api: {
			...state.api,
			fetchApiVersion: {
				in_progress: true,
			},
		},
	})),
	on(FacilitySettingsActions.fetchApiVersionSuccess, (state, updateValue) => {
		const { apiVersion } = updateValue;

		const initialApiVersion =
			state.initialApiVersion === "" ? apiVersion : state.initialApiVersion;

		return {
			...state,
			apiVersion,
			initialApiVersion,
		};
	}),
	on(FacilitySettingsActions.fetchApiVersionFail, (state, errorValue) => ({
		...state,
		api: {
			...state.api,
			fetchApiVersion: {
				in_progress: false,
				error: errorValue.error,
			},
		},
	})),
);

/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */

export const getFacilitySettings = (state: State) => state.settings;

export const getFetchInProgress = (state: State) => state.api.fetch.in_progress;

export const getFetchError = (state: State) => state.api.fetch.error;
