import { marker } from "@jsverse/transloco-keys-manager/marker";

export const GeneralInventorySourceDynamicSchemaWithNoProduct = {
	title: "",
	description: "",
	info: "",
	properties: {
		id: {
			type: "number",
			hidden: true,
		},
		lot_id: {
			type: "number",
			title: "Source Lot",
			title_translation_key: marker("form_field_label_source_lot"),
			widget: "data-select",
			quick_create: false,
			selectFirstAndOnly: true,
			oneOf: [
				{
					result_type: "lots",
					queryString: {
						input: "true",
						dont_show_expired: "false",
						non_zero_inventory: "true",
					},
				},
			],
			hidden: false,
		},
		choose_source_inventory: {
			title: "Choose Source Inventory",
			title_translation_key: marker("form_field_label_choose_source_inventory"),
			type: "boolean",
			widget: "checkbox",
			default: false,
		},
		inventory_ids: {
			type: "array",
			title: "Inventory ID",
			title_translation_key: marker("form_field_label_inventory_id"),
			widget: "data-select",
			quick_create: false,
			multi: true,
			related_properties: ["lot_id"],
			selectFirstAndOnly: true,
			items: {
				type: "number",
				oneOf: [
					{
						result_type: "inventories",
						text_key: ["name", "id"],
						text_format: "?1 (#?2)",
						value_key: "id",
						queryString: {
							dont_show_expired: "false",
							non_zero_inventory: "true",
						},
					},
				],
			},
			visibleIf: {
				allOf: [{ choose_source_inventory: [true] }],
			},
		},
		remaining_inventory: {
			title: "Use Remaining Inventory",
			title_translation_key: marker("form_field_label_use_remaining_inventory"),
			type: "boolean",
			widget: "checkbox",
			default: true,
		},
		quantity: {
			type: "number",
			title: `Quantity`,
			title_translation_key: marker("word_quantity"),
			width: "50%",
			visibleIf: {
				allOf: [{ remaining_inventory: [false] }],
			},
		},
		inventory_unit_id: {
			title: "Inventory Unit",
			title_translation_key: marker("word_inventory_unit"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			related_properties: ["inventory_ids", "lot_id"],
			selectFirstAndOnly: true,
			width: "50%",
			shorter_placeholder: true,
			oneOf: [
				{
					result_type: "inventory_units",
				},
			],
			visibleIf: {
				allOf: [{ remaining_inventory: [false] }],
			},
		},
		amount_available: {
			type: "string",
			widget: "string",
			title: "Amount Available",
			readOnly: true,
			visibleIf: {
				allOf: [{ inventory_product_id: [true] }],
			},
		},
		timestamp: {
			type: "string",
			title: "Timestamp",
			title_translation_key: marker("word_timestamp"),
			widget: "date",
			hidden: false,
		},

		/** These are just used for the required to make sure we have all the correct items selected before allowing the user to submit the form */
		remaining_inventory_selected: {
			type: "boolean",
			widget: "checkbox",
			hidden: true,
			default: false,
			visibleIf: {
				remaining_inventory: [true],
			},
		},
	},
	anyOf: [
		{
			required: ["id", "lot_id", "inventory_unit_id", "quantity"],
		},
		{
			required: ["id", "inventory_ids", "inventory_unit_id", "quantity"],
		},
		{
			required: ["id", "lot_id", "remaining_inventory"],
		},
		{
			required: ["id", "inventory_ids", "remaining_inventory"],
		},
	],
};
