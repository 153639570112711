/* jscpd:ignore-start */
import { marker } from "@jsverse/transloco-keys-manager/marker";

export interface InventoryDestruction {
	destruction_lot_id: number;
	consumptionSource: string;
	batch_id?: number;
	location_id?: number;
	inventory_lot_id?: number;
	inventory_product_id: number;
	lot_id?: number;
	inventory_ids?: number[];
	inventory_unit_id?: number;
	quantity?: number;
	timestamp?: string;
	destruction_reason_id: number;
	remaining_inventory?: boolean;
	work_order_id?: number;
}

export const InventoryDestructionSchema = {
	title: "",
	description: "",
	info: "",
	properties: {
		destruction_lot_id: {
			type: "number",
			minItems: 1,
			title: "Destruction Lot",
			title_translation_key: marker("word_destruction_lot"),
			widget: "data-select",
			visible: false,
			oneOf: [
				{
					result_type: "destruction_lots",
					pretext: "",
					text_key: ["name", "id"],
					queryString: {
						lot_status: "OPEN",
					},
				},
			],
		},
		inventory_product_id: {
			title: "Product",
			title_translation_key: marker("word_product"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			related_properties: ["non_inventory_destruction", "work_order_id"],
			oneOf: [
				{
					result_type: "inventory_products",
					queryString: {
						inventory_product_filter: "ACTIVE_ARCHIVED",
					},
				},
			],
		},
		vendor_id: {
			type: "number",
			title: "Vendor",
			title_translation_key: marker("word_vendor"),
			widget: "data-select",
			oneOf: [
				{
					result_type: "vendors",
				},
			],
			hidden: true,
			related_properties: ["inventory_product_id"],
			visibleIf: {
				allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
			},
		},
		batch_id: {
			type: "number",
			title: "Batch",
			title_translation_key: marker("word_batch"),
			widget: "data-select",
			// related_properties: ["inventory_product_id", "vendor_id", "sku_id"],
			oneOf: [
				{
					result_type: "batches",
					queryString: {
						dont_show_expired: "true",
						// non_zero_inventory: "true",
					},
				},
			],
			visibleIf: {
				allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
			},
		},
		sku_id: {
			type: "number",
			title: "SKU",
			title_translation_key: marker("word_sku"),
			widget: "data-select",
			quick_create: false,
			related_properties: ["inventory_product_id"],
			oneOf: [
				{
					result_type: "skus",
					text_key: ["sku"],
				},
			],
			hidden: true,
			visibleIf: {
				allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
			},
		},
		location_id: {
			type: "number",
			title: "Location",
			title_translation_key: marker("word_location"),
			widget: "data-select",
			oneOf: [
				{
					result_type: "locations",
				},
			],
			visibleIf: {
				allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
			},
		},
		existing_inventory: {
			title: "Inventory Exists in System",
			title_translation_key: marker("form_field_label_inventory_exists_in_system"),
			description:
				"Sometimes you'd like to destroy inventory that doesn't yet exist in the system, such as foliage from plants etc.",
			description_translation_key: marker(
				"form_field_description_sometimes_you_would_like_to_destroy_inventory_that_does_not_yet_exist_in_the_system",
			),
			type: "boolean",
			widget: "checkbox",
			default: true,
		},
		lot_id: {
			type: "number",
			title: "Inventory Lot (Optional)",
			title_translation_key: marker("form_field_label_destroy_from_lot_optional"),
			widget: "data-select",
			quick_create: false,
			related_properties: [
				"inventory_product_id",
				"vendor_id",
				"batch_id",
				"sku_id",
			],
			oneOf: [
				{
					result_type: "lots",
					queryString: {
						input: "true",
					},
				},
			],
			visibleIf: {
				allOf: [
					{ inventory_product_id: ["$EXP$ target.value > 0"] },
					{ existing_inventory: [true] },
				],
			},
		},
		inventory_ids: {
			type: "array",
			title: "Inventory ID",
			title_translation_key: marker("form_field_label_inventory_id"),
			widget: "data-select",
			quick_create: false,
			multi: true,
			related_properties: [
				"inventory_product_id",
				"location_id",
				"batch_id",
				"lot_id",
			],
			items: {
				type: "number",
				oneOf: [
					{
						result_type: "inventories",
						text_key: ["name", "id"],
						text_format: "?1 (#?2)",
						value_key: "id",
						queryString: {
							non_zero_inventory: "true",
							dont_show_expired: "false",
						},
					},
				],
			},
			visibleIf: {
				allOf: [
					{
						existing_inventory: true,
					},
					{ inventory_product_id: ["$EXP$ target.value > 0"] },
				],
			},
		},
		remaining_inventory: {
			title: "Use Remaining Inventory",
			title_translation_key: marker("form_field_label_use_remaining_inventory"),
			type: "boolean",
			widget: "checkbox",
			default: false,
			visibleIf: { existing_inventory: true },
		},
		quantity: {
			type: "number",
			title: `Quantity`,
			title_translation_key: marker("word_quantity"),
			visibleIf: {
				oneOf: [
					{
						allOf: [
							{ existing_inventory: true },
							{ remaining_inventory: false },
							{ inventory_product_id: ["$EXP$ target.value > 0"] },
						],
					},
					{
						allOf: [
							{ existing_inventory: false },
							{ inventory_product_id: ["$EXP$ target.value > 0"] },
						],
					},
				],
			},
			width: "50%",
		},
		inventory_unit_id: {
			title: "Inventory Unit",
			title_translation_key: marker("word_inventory_unit"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			shorter_placeholder: true,
			related_properties: ["inventory_product_id", "location_id", "batch_id"],
			oneOf: [
				{
					result_type: "inventory_units",
				},
			],
			visibleIf: {
				oneOf: [
					{
						allOf: [
							{ existing_inventory: true },
							{ remaining_inventory: false },
							{ inventory_product_id: ["$EXP$ target.value > 0"] },
						],
					},
					{
						allOf: [
							{ existing_inventory: false },
							{ inventory_product_id: ["$EXP$ target.value > 0"] },
						],
					},
				],
			},
			width: "50%",
		},

		amount_available: {
			type: "string",
			widget: "string",
			title: "Amount Available",
			readOnly: true,
			visibleIf: {
				allOf: [
					{ inventory_product_id: [true] },
					{ inventory_product_id: ["$EXP$ target.value > 0"] },
				],
			},
		},
		destruction_reason_id: {
			type: "number",
			title: "Destruction Reason",
			title_translation_key: marker("word_destruction_reason"),
			widget: "data-select",
			oneOf: [
				{
					result_type: "destruction_reasons",
				},
			],
		},
		description: {
			type: "string",
			title: "Description",
			title_translation_key: marker("word_description"),
			widget: "textarea",
		},
		timestamp: {
			type: "string",
			title: "Timestamp",
			title_translation_key: marker("word_timestamp"),
			widget: "date",
		},
	},
	anyOf: [
		/***** Default *******/
		{
			// Destroy quantity
			required: [
				"destruction_lot_id",
				"inventory_product_id",
				// "lot_id",
				"quantity",
				"inventory_unit_id",
				"destruction_reason_id",
				"vendor_id",
			],
		},
		{
			// Destroy remaining inventory
			required: [
				"destruction_lot_id",
				"inventory_product_id",
				"lot_id",
				"remaining_inventory",
				"destruction_reason_id",
				"vendor_id",
			],
		},
		/***** FOR Location *******/
		{
			// Destroy quantity
			required: [
				"destruction_lot_id",
				"inventory_product_id",
				// "lot_id",
				"location_id",
				"quantity",
				"inventory_unit_id",
				"destruction_reason_id",
				"vendor_id",
			],
		},
		{
			// Destroy remaining inventory
			required: [
				"destruction_lot_id",
				"inventory_product_id",
				"location_id",
				// "lot_id",
				"remaining_inventory",
				"destruction_reason_id",
				"vendor_id",
			],
		},
	],
};

/* jscpd:ignore-end */
