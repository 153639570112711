import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import {
	timeout,
	catchError,
	takeUntil,
	tap,
	take,
	skipWhile,
} from "rxjs/operators";
import { EMPTY, Observable } from "rxjs";
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { IPurchaseOrder, UserProfile } from "@elevatedsignals/amygoodman";
import { PurchaseOrderDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { Globals } from "app/shared/modules/globals/globals.service";
import { PasswordInputComponent } from "app/modules/auth/components";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { ItemActions } from "../../../actions/item.actions";

@Component({
	selector: "purchase-order-reopen",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PurchaseOrderReopenComponent
	extends GenericUpdateComponent<IPurchaseOrder>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			reason: {
				type: "string",
				title: "Reopen Reason",
				title_translation_key: marker("form_field_label_reopen_reason"),
				widget: "textarea",
			},
		},
	};

	user$: Observable<UserProfile | null> = this._store.select(
		fromDashboard.getProfile,
	);

	user: UserProfile;

	purchase_order$ = this._store.select(fromDashboard.getSelectedPurchaseOrder);
	purchase_order: IPurchaseOrder;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
	) {
		super(_store, _cd);
		this.form_title = "Reopen Purchase Order";
		this.form_title_translation_key = marker("form_title_update_purchase_order");
		this.submit_button = "Reopen";
		this.submit_button_translation_key = marker("word_reopen");
	}

	ngOnInit() {
		this.user$
			.pipe(
				takeUntil(this.destroyed$),
				skipWhile((user: UserProfile | null) => user == null),
			)
			.subscribe((user) => {
				if (user) {
					this.user = user;
				}
			});

		this.purchase_order$
			.pipe(isNotNullOrUndefined(), take(1))
			.subscribe((purchase_order: IPurchaseOrder) => {
				this.model.id = purchase_order.id;
				if (purchase_order.is_outgoing) {
					this.form_title = "Reopen Shipping Order";
					this.form_title_translation_key = marker(
						"form_title_reopen_shipping_order",
					);
				} else {
					this.form_title = "Reopen Purchase Order";
					this.form_title_translation_key = marker(
						"form_title_reopen_purchase_order",
					);
				}
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(purchase_order: Partial<IPurchaseOrder>): void {
		if (this._globals.gmp_enabled) {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: PasswordInputComponent,
					inputs: {
						onSubmit: (password) => {
							this.updatePurchaseOrder(purchase_order, true, password);
						},
					},
				}),
			);
		} else {
			this.updatePurchaseOrder(purchase_order);
		}
	}

	updatePurchaseOrder(
		order: Partial<IPurchaseOrder>,
		signing = false,
		password?: string,
	) {
		const authObject = password
			? { username: this.user.email, password }
			: undefined;

		this.loading$.next(true);
		this._itemService
			.update(
				`purchase_order`,
				`${order.id}/reopen`,
				order,
				signing
					? {
							...PurchaseOrderDetailQuery,
							signing: "true",
							is_outgoing: order.is_outgoing ? "true" : "false",
						}
					: {
							...PurchaseOrderDetailQuery,
							is_outgoing: order.is_outgoing ? "true" : "false",
						},
				authObject,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "purchase_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
