import { Pipe, PipeTransform } from "@angular/core";
import {
	MeasureUnit,
	unitToBaseMeasureUnit,
} from "@elevatedsignals/amygoodman";

@Pipe({
	name: "unitToBaseMeasureUnit",
})
export class UnitToBaseMeasureUnitPipe implements PipeTransform {
	constructor() {}

	transform(value: number, unit: string, baseUnit: MeasureUnit): number {
		if (isNaN(value)) {
			return value;
		}
		return Number(unitToBaseMeasureUnit(value, unit, baseUnit));
	}
}
