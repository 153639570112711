import { Injectable, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { DashboardState } from "app/modules/dashboard/state/dashboard.state";
import {
	createAndFormatDateTime,
	DateFormatsForDateFns,
	DateFormatsSetting,
	DateFormatsSettingForDateFns,
	FormatOption,
} from "app/shared/time-format";
import { formatDistance, isValid, Locale } from "date-fns";
import { format, formatInTimeZone } from "date-fns-tz";
import { enCA, frCA } from "date-fns/locale";
import { IUserAction } from "@elevatedsignals/amygoodman";
import { TranslocoService } from "@jsverse/transloco";

import { FormatNamePipe } from "../../modules/es-pipes/format-name.pipe";

import { dateFormatSelector } from "./date-format.selector";

@Injectable()
export class DateFormatService implements OnDestroy {
	private facility_date_format: string;
	private facility_timezone: string;
	private facility_time_format: string;
	private desiredLanguage: string;

	private readonly dateFormatServiceData = this._store
		.select(dateFormatSelector)
		.subscribe((item) => {
			this.facility_timezone = item.timezone;
			this.facility_date_format = item.dateFormat;
			this.facility_time_format = item.timeFormat;
			this.desiredLanguage = item.language;
		});

	constructor(
		private readonly _store: Store<DashboardState>,
		private readonly _formatNamePipe: FormatNamePipe,
		private readonly _translocoService: TranslocoService,
	) {}

	ngOnDestroy(): void {
		this.dateFormatServiceData.unsubscribe();
	}

	formatDate(
		date: Date | string | number,
		settings?: string,
		options: "normal" | "short" = "normal",
	) {
		if (!date) {
			return "N/A";
		}

		const option =
			options === "normal" ? "" : this.capitalizeFirstLetter(options);

		const dateObject = new Date(date);
		return createAndFormatDateTime(
			dateObject,
			settings ? settings : DateFormatsSetting[this.facility_date_format + option],
			this.facility_timezone,
			this.desiredLanguage,
		);
	}

	formatDateTime(dateTime: Date | string | number, options?: FormatOption) {
		if (!dateTime) {
			return "N/A";
		}
		const dateObject = new Date(dateTime);
		const dateFormat = this.getDateFormatsBySetting(
			this.facility_date_format,
			this.facility_time_format,
			options,
		);
		// createAndFormatDateTime uses Moment
		return createAndFormatDateTime(
			dateObject,
			DateFormatsSetting[dateFormat],
			this.facility_timezone,
			this.desiredLanguage,
		);
	}

	formatWithDateFns(
		date: Date,
		format:
			| DateFormatsForDateFns
			| DateFormatsSettingForDateFns = DateFormatsSettingForDateFns[
			this.facility_date_format
		],
	): string {
		const finalDate = isValid(date) ? date : new Date();

		return this.dateFnsFormatter(
			finalDate,
			format,
			this.facility_timezone,
			this.desiredLanguage,
		);
	}

	getFromNow(date: Date): string {
		return formatDistance(date, new Date(), {
			addSuffix: true,
			locale: this.getDateFnsLocale(this.desiredLanguage),
		});
	}

	formatUserAction(action: IUserAction): string {
		const name = this._formatNamePipe.transform(action.user);
		const date = this.formatDateTime(action.timestamp);

		return `${date} ${this._translocoService.translate("fragment_by")} ${name}`;
	}

	private getDateFormatsBySetting(
		dateFormat: string,
		timeFormat: string,
		options?: FormatOption,
	): string {
		const timeToken = timeFormat === "24h" ? "24Hour" : "12Hour";

		const dateToken: string = dateFormat;

		if (options === FormatOption.timeDetailed) {
			return `${dateToken}${FormatOption.timeDetailed}`;
		}

		if (options === FormatOption.includeWeekName) {
			return `${dateToken}${timeToken}${FormatOption.includeWeekName}`;
		}

		if (options === FormatOption.includeWeekNameAndMonthName) {
			return `${dateToken}${timeToken}${FormatOption.includeWeekNameAndMonthName}`;
		}

		if (options === FormatOption.includeFullMonthName) {
			return `${dateToken}${FormatOption.includeFullMonthName}`;
		}

		return `${dateToken}${timeToken}`;
	}

	private dateFnsFormatter(
		date: Date,
		dateFormat: DateFormatsForDateFns | DateFormatsSettingForDateFns,
		timeZone?: string,
		desiredLanguage?: string,
	): string {
		const dateFnsLocale = this.getDateFnsLocale(desiredLanguage);

		if (timeZone) {
			return formatInTimeZone(date, timeZone, dateFormat, {
				locale: dateFnsLocale,
			});
		}

		return format(date, dateFormat, { locale: dateFnsLocale });
	}

	// Intakes a lang string from transloco.getActiveLang(), could be 'en' or 'fr',
	// and returns the correct date-fns locale
	private getDateFnsLocale(desiredLanguage?: string): Locale {
		if (desiredLanguage === "fr") {
			return frCA;
		}

		return enCA;
	}

	private capitalizeFirstLetter(string: string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
}
