import {
	HeaderQueryType,
	IDestructionEvent,
	IHeaderQuery,
	Page,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export type State = Page<IDestructionEvent>;

export const batchDestructionEventsReducerBuilder = (
	generalInventory: boolean,
) => {
	const sharedHeaderQueries: IHeaderQuery[] = [
		{
			title: "Growth Stages",
			title_translation_key: marker("page_title_plural_growth_stages"),
			key: "growth_stage_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "growth_stages",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		},
		{
			title: "Hide Plant Destructions",
			title_translation_key: marker("checkbox_filter_hide_plant_destructions"),
			key: "hidePlantDestructions",
			type: HeaderQueryType.checkbox,
			value: false,
		},
	];

	if (generalInventory) {
		sharedHeaderQueries.unshift({
			title: "Inventory Products",
			title_translation_key: marker("page_title_plural_inventory_products"),
			key: "inventory_product_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "inventory_products",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		});
	} // @deprecated else
	else {
		sharedHeaderQueries.unshift({
			title: "Substance Types",
			title_translation_key: marker("page_title_plural_substance_types"),
			key: "substance_type_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "substance_types",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		});
	}

	const key = generalInventory
		? "destruction_events_batch_gi"
		: "destruction_events_batch_non_gi";

	return createPagingReducer<IDestructionEvent>(key, {
		result_type: "destruction_events",
		title: "Destruction Event",
		title_translation_key: marker("page_title_destruction_events"),
		title_plural_translation_key: marker("page_title_plural_destruction_events"),
		results: [],
		page: 1,
		page_size: 50,
		total: 0,
		header_queries: sharedHeaderQueries,
	});
};
