/* jscpd:ignore-start */
import { marker } from "@jsverse/transloco-keys-manager/marker";

export interface InventoryDestructionUpdate {
	destruction_lot_id: number;
	group: string;
	inventory_product_id: number;
	inventory_unit_id: number;
	value: number;
	timestamp?: string;
	reason?: string;
}

export const InventorDestructionUpdateSchema = {
	title: "",
	description: "",
	info: "",
	properties: {
		group: {
			type: "string",
			title: "Group UUID",
			hidden: true,
			readOnly: true,
		},
		destruction_lot_id: {
			type: "number",
			title: "Destruction Lot",
			title_translation_key: marker("word_destruction_lot"),
			widget: "data-select",
			visible: false,
			quick_create: false,
			oneOf: [
				{
					result_type: "destruction_lots",
					pretext: "",
					text_key: ["name", "id"],
				},
			],
			readOnly: true,
		},
		inventory_product_id: {
			title: "Product",
			title_translation_key: marker("word_product"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			oneOf: [
				{
					result_type: "inventory_products",
				},
			],
			readOnly: true,
		},
		value: {
			type: "number",
			title: `Quantity`,
			widget: "number",
			width: "50%",
			title_translation_key: marker("word_quantity"),
			visibleIf: {
				inventory_unit_id: ["$ANY$"],
			},
		},
		inventory_unit_id: {
			type: "number",
			title: "Inventory Unit",
			title_translation_key: marker("word_inventory_unit"),
			widget: "data-select",
			width: "50%",
			shorter_placeholder: true,
			quick_create: false,
			related_properties: ["inventory_product_id"],
			oneOf: [
				{
					result_type: "inventory_units",
				},
			],
			visibleIf: {
				inventory_product_id: ["$EXP$ target.value > 0"],
			},
			readOnly: true,
		},
	},
};
/* jscpd:ignore-end */
