import { IDestructionEvent, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "destruction_events";

export type State = Page<IDestructionEvent>;

export const reducer = createPagingReducer<IDestructionEvent>(reducerKey, {
	result_type: "destruction_events",
	title: "Destruction Event",
	title_translation_key: marker("page_title_destruction_events"),
	title_plural_translation_key: marker("page_title_plural_destruction_events"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
});
