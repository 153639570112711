import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, take, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { InventoryProductDetailQuery } from "app/shared/eagers";
import { IInventoryProduct } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import {
	Binding,
	FormProperty,
	PropertyGroup,
} from "@elevatedsignals/es-ngx-schema-form";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { getProductCode } from "./utils";

@Component({
	selector: "inventory-product-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class InventoryProductUpdateComponent
	extends GenericUpdateComponent<IInventoryProduct>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			inventory_product_id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			product_code: {
				type: "string",
				title: "Product Code",
				title_translation_key: marker("word_product_code"),
			},
			vendor_ids: {
				type: "array",
				title: "Vendors",
				title_translation_key: marker("word_vendor"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "vendors",
						},
					],
				},
			},
			display_unit_id: {
				type: "number",
				title: "Display Unit",
				title_translation_key: marker("word_display_unit"),
				widget: "data-select",
				related_properties: ["inventory_product_id"],
				oneOf: [
					{
						result_type: "inventory_units",
						text_key: ["name"],
						queryString: {
							hideVirtuals: true,
						},
					},
				],
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
			inventory_category_ids: {
				type: "array",
				title: "Inventory Categories",
				title_translation_key: marker("word_inventory_categories"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_categories",
							text_key: ["name"],
						},
					],
				},
			},
			sku_ids: {
				type: "array",
				title: "Associated Skus",
				title_translation_key: marker("form_field_label_associated_skus"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "skus",
							text_key: ["sku"],
						},
					],
				},
			},
			require_sku: {
				type: "boolean",
				widget: "checkbox",
				title: "Require SKU",
				title_translation_key: marker("form_field_label_require_sku"),
				description:
					"Require a SKU when creating Inventory of a specific Inventory Product.",
				description_translation_key: marker(
					"form_field_description_require_a_sku_when_creating_inventory_of_a_specific_inventory_product",
				),
			},
			non_inventory_destruction: {
				title: "Non-Inventory Destruction",
				title_translation_key: marker("form_field_label_non_inventory_destruction"),
				description:
					"Enabling this option will only allow the destruction of this Product and will prevent any inventory of this Product from being created.",
				description_translation_key: marker(
					"form_field_description_enabling_this_option_will_only_allow_the_destruction_of_this_product",
				),
				type: "boolean",
				widget: "checkbox",
				// default: false,
			},
			require_batch: {
				type: "boolean",
				widget: "checkbox",
				title: "Require Batch",
				title_translation_key: marker("form_field_label_require_batch"),
				description:
					"Require a Batch when creating Inventory of a specific Inventory Product.",
				description_translation_key: marker(
					"form_field_description_require_a_batch_when_creating_inventory_of_a_specific_inventory_product",
				),
			},
		},
		required: ["name"],
	};

	fieldBindings: Record<string, Binding> = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": {
			input: (event, formProperty: FormProperty) => {
				if (this.isUserDefinedProductCode) {
					return;
				}

				const name = event.target.value;
				if (name && name !== this.previousName) {
					this.previousName = name;

					const productCode = getProductCode(name);

					const parent: PropertyGroup = formProperty.findRoot();
					const product_code: FormProperty = parent.getProperty("product_code");
					product_code.setValue(productCode, false);
				}
			},
		},
	};

	workOrderOutputToLocation = false;

	inventory_product$ = this._store.select(
		fromDashboard.getSelectedInventoryProduct,
	);

	inventory_product: IInventoryProduct;

	private previousName = "";
	private isUserDefinedProductCode = false;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
	) {
		super(_store, _cd);
		this.form_title = "Update Inventory Product";
		this.form_title_translation_key = marker(
			"form_title_update_inventory_product",
		);
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.inventory_product$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((inventory_product) => {
				this.inventory_product = inventory_product;
				this.model.inventory_product_id = this.inventory_product.id;

				this.model.name = this.inventory_product.name;
				this.model.product_code = this.inventory_product.product_code;

				if (
					this.inventory_product.product_code !==
					getProductCode(this.inventory_product.name)
				) {
					this.isUserDefinedProductCode = true;
				}

				this.model.display_unit_id = this.inventory_product.display_unit_id;
				this.model.archived = this.inventory_product.archived;
				this.model.require_sku = this.inventory_product.require_sku;
				this.model.non_inventory_destruction =
					this.inventory_product.non_inventory_destruction;
				this.model.inventory_category_ids =
					this.inventory_product.inventory_categories?.map((product) => product.id);
				this.model.sku_ids = this.inventory_product.skus?.map((sku) => sku.id);
				this.previousName = this.inventory_product.name;
				this.model.require_batch = this.inventory_product.require_batch;

				this.model.vendor_ids = this.inventory_product.vendors?.map(
					(vendor) => vendor.id,
				);
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(type: any) {
		this.loading$.next(true);
		this._itemService
			.update(
				`inventory_product`,
				type.inventory_product_id,
				type,
				InventoryProductDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "inventory_products",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
