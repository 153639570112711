import { User } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.Users;

export type State = SelectionState<User>;

export const reducer = createSelectionReducer<User>(reducerKey);
