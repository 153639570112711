import { IWorkOrderOutput } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.WorkOrderOutputs;

export type State = SelectionState<IWorkOrderOutput>;

export const reducer = createSelectionReducer<IWorkOrderOutput>(reducerKey);
