import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, take, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import { IPeriod } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";

@Component({
	selector: "period-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PeriodUpdateComponent
	extends GenericUpdateComponent<IPeriod>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			reason: {
				type: "string",
				title: "Unlock Reason",
				title_translation_key: marker("form_field_label_unlock_reason"),
				widget: "textarea",
				hidden: true,
			},
			user_confirm: {
				title: "I Understand",
				title_translation_key: marker("form_field_label_i_understand"),
				description:
					"Locking this reporting period means that users will no longer be allowed to backdate any events during this period. Are you sure you want to continue?",
				description_translation_key: marker("form_field_warning_period_lock"),
				type: "boolean",
				widget: "checkbox",
			},
		},
		required: ["user_confirm"],
	};

	period$ = this._store.select(fromDashboard.getSelectedPeriod);

	period: Partial<IPeriod> | undefined;
	locked: boolean;
	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.period = this._injector.get("period", undefined);
		this.locked = this.period?.locked === true;
		this.form_title = this.locked
			? "Unlock Reporting Period"
			: "Lock Reporting Period";
		this.form_title_translation_key = this.locked
			? marker("form_title_unlock_reporting_period")
			: marker("form_title_lock_reporting_period");
		this.submit_button = this.locked ? "Unlock" : "Lock";
		this.submit_button_translation_key = this.locked
			? marker("word_unlock")
			: marker("word_lock");
		if (this.locked) {
			this.schema.properties.user_confirm.description =
				"Unlocking this reporting period means that users will be allowed to backdate events during this period, which may create discrepancies in your inventory. Are you sure you want to continue?";
			this.schema.properties.user_confirm.description_translation_key = marker(
				"form_field_warning_period_unlock",
			);
			this.schema.properties.reason.hidden = false;
		} else {
			this.schema.properties.user_confirm.description =
				"Locking this reporting period means that users will no longer be allowed to backdate any events during this period. Are you sure you want to continue?";
			this.schema.properties.user_confirm.description_translation_key = marker(
				"form_field_warning_period_lock",
			);
		}
	}

	ngOnInit() {
		if (this.period?.id) {
			this.period$
				.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
				.subscribe((period) => {
					this.period = period;
					this.model.id = this.period.id;
					this._cd.detectChanges();
				});
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(model: any) {
		this.loading$.next(true);
		if (model.id) {
			const updatedPeriod = {
				id: model.id,
				locked: !this.locked,
			};
			this._itemService
				.update(`period`, model.id, updatedPeriod)
				.pipe(takeUntil(this.destroyed$))
				.pipe(
					timeout(10000),
					catchError((error) => {
						this.error$.next(handleObservableError(error, true));
						this.loading$.next(false);
						return EMPTY;
					}),
				)
				.pipe(
					tap((updatedItem) => {
						this._store.dispatch(
							ItemActions.updateSuccess({
								updatedItem,
								result_type: "periods",
							}),
						);
						this.loading$.next(false);
						this.closeSidenav();
					}),
				)
				.subscribe();
		} else {
			const createPeriod = {
				...this.period,
				locked: !this.locked,
			};
			this._itemService
				.add(`periods`, createPeriod)
				.pipe(takeUntil(this.destroyed$))
				.pipe(
					timeout(10000),
					catchError((err) => {
						this.error$.next(handleObservableError(err, true));
						this.loading$.next(false);
						return EMPTY;
					}),
				)
				.pipe(
					tap((addedItem) => {
						this._store.dispatch(
							ItemActions.addSuccess({
								result_type: "periods",
								addedItem,
							}),
						);
						this.loading$.next(false);
						this.closeSidenav();
					}),
				)
				.subscribe();
		}
	}
}
