import { IDestructionEvent } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.DestructionEvents;

export type State = SelectionState<IDestructionEvent>;

export const reducer = createSelectionReducer<IDestructionEvent>(reducerKey);
