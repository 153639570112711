import {
	ChangeDetectorRef,
	Component,
	inject,
	OnDestroy,
	OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { handleObservableError } from "app/shared/utils";
import { EMPTY, switchMap } from "rxjs";
import {
	catchError,
	timeout,
	takeUntil,
	tap,
	filter,
	take,
} from "rxjs/operators";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { FetchPageActions } from "app/modules/dashboard/actions/paging.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ISku } from "@elevatedsignals/amygoodman";
import { SkuDetailQuery } from "app/shared/eagers";
import { PagerService } from "app/modules/dashboard/services/pager.service";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

@Component({
	selector: "sku-update",
	templateUrl: "../form-view.component.html",
})
export class SkuUpdateComponent
	extends GenericUpdateComponent<ISku>
	implements OnDestroy, OnInit
{
	private _pagerService = inject(PagerService);

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			sku_id: {
				type: "number",
				hidden: true,
			},
			sku: {
				type: "string",
				title: "SKU",
				title_translation_key: marker("word_sku"),
			},
			inventory_product_ids: {
				type: "array",
				title: "Inventory Products",
				title_translation_key: marker("form_field_label_inventory_products"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [{ result_type: "inventory_products" }],
				},
			},
			vendor_id: {
				type: "number",
				title: "Vendor",
				title_translation_key: marker("word_vendor"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "vendors",
					},
				],
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
		},
		required: ["sku"],
	};

	sku$ = this._store.select(fromDashboard.getSelectedSku);
	sku: ISku;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
	) {
		super(_store, _cd);
		this.form_title = "Update SKU";
		this.form_title_translation_key = marker("form_title_update_sku");
	}

	ngOnInit() {
		this.sku$
			.pipe(
				takeUntil(this.destroyed$),
				filter((sku: ISku | null) => sku !== null),
				take(1),
			)
			.subscribe((sku: ISku) => {
				this.sku = sku;
				this.model = sku;
				this.model.inventory_product_ids = sku.inventory_products?.map(
					(product) => product.id,
				);
				// ESS-7558 Note that currently we only support 1 vendor per SKU.
				this.model.vendor_id = sku.vendors?.[0]?.id ?? undefined;
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.valid$.complete();
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(_sku: ISku) {
		this.loading$.next(true);
		this._itemService
			.update("sku", this.sku.id, _sku, SkuDetailQuery)
			.pipe(
				takeUntil(this.destroyed$),
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "skus",
						}),
					);
				}),
				switchMap(() => {
					return this._store.select(fromDashboard.getInventoryProductPage).pipe(
						takeUntil(this.destroyed$),
						isNotNullOrUndefined(),
						switchMap((page) => {
							return this._pagerService.fetchPage(page);
						}),
					);
				}),
			)
			.subscribe((updatedPage) => {
				this._store.dispatch(
					FetchPageActions.fetchPageSuccess({
						payload: updatedPage,
					}),
				);

				this.loading$.next(false);
				this.closeSidenav();
			});
	}
}
