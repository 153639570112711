import { IApplicableProduct } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.ApplicableProducts;

export type State = SelectionState<IApplicableProduct>;

export const reducer = createSelectionReducer<IApplicableProduct>(reducerKey);
