import { createSelector } from "reselect";
import { IRole, IPermission } from "@elevatedsignals/amygoodman";
import { AuthActions } from "app/modules/auth/actions/auth.actions";

import * as role from "../actions/role.actions";

export interface State {
	ids: number[];
	entities: Record<number, IRole>;
	permissions: IPermission[];
	display: {
		selected_role_id: number | null;
	};
	api: {
		fetch: {
			error?: string | null;
			in_progress: boolean;
		};
		add: {
			error?: string | null;
			in_progress: boolean;
		};
		update: {
			error?: string | null;
			in_progress: boolean;
		};
		fetch_permissions: {
			error?: string | null;
			in_progress: boolean;
		};
	};
}

const initialState: State = {
	ids: [],
	entities: {},
	permissions: [],
	display: {
		selected_role_id: null,
		// page: {
		// 	result_type: "roles",
		// 	page: 1,
		// 	page_size: 5,
		// 	total: 0,
		// 	title: "Roles",
		// },
	},
	api: {
		fetch: {
			in_progress: false,
		},
		add: {
			in_progress: false,
		},
		update: {
			in_progress: false,
		},
		fetch_permissions: {
			in_progress: false,
		},
	},
};

export const reducer = (state = initialState, action): State => {
	switch (action.type) {
		case role.FETCH: {
			return {
				...state,
				api: {
					...state.api,
					fetch: {
						in_progress: true,
					},
				},
			};
		}

		case role.FETCH_SUCCESS: {
			const roles = action.payload as IRole[];
			const newRoles = roles.filter((role) => !state.entities[role.id]);

			const newRoleIds = newRoles.map((role) => role.id);
			const newRoleEntities = newRoles.reduce(
				(entities: Record<number, IRole>, role: IRole) => {
					return Object.assign(entities, {
						[role.id]: role,
					});
				},
				{},
			);

			return {
				...state,
				ids: [...state.ids, ...newRoleIds],
				entities: { ...state.entities, ...newRoleEntities },
				api: {
					...state.api,
					fetch: {
						in_progress: false,
					},
				},
			};
		}
		case role.FETCH_FAIL: {
			const error = action.payload as string;
			return {
				...state,
				api: {
					...state.api,
					fetch: {
						in_progress: false,
						error,
					},
				},
			};
		}

		case role.ADD: {
			return {
				...state,
				api: {
					...state.api,
					add: {
						in_progress: true,
					},
				},
			};
		}
		case role.ADD_SUCCESS: {
			const role = action.payload as IRole;

			if (state.ids.includes(role.id)) {
				return state;
			}

			return {
				...state,
				ids: [...state.ids, role.id],
				entities: { ...state.entities, [role.id]: role },
				api: {
					...state.api,
					add: {
						in_progress: false,
					},
				},
			};
		}
		case role.ADD_FAIL: {
			const error = action.payload as string;
			return {
				...state,
				api: {
					...state.api,
					add: {
						in_progress: false,
						error,
					},
				},
			};
		}

		case role.UPDATE: {
			return {
				...state,
				api: {
					...state.api,
					update: {
						in_progress: true,
					},
				},
			};
		}
		case role.UPDATE_SUCCESS: {
			const role = action.payload as IRole;

			if (!state.ids.includes(role.id)) {
				// Error, id not found....
				return state;
			}

			return {
				...state,
				entities: { ...state.entities, [role.id]: role },
				api: {
					...state.api,
					update: {
						in_progress: false,
					},
				},
			};
		}
		case role.UPDATE_FAIL: {
			const error = action.payload as string;
			return {
				...state,
				api: {
					...state.api,
					update: {
						in_progress: false,
						error,
					},
				},
			};
		}

		case role.SELECT: {
			return {
				...state,
				display: {
					...state.display,
					selected_role_id: action.payload as number,
				},
			};
		}

		case role.FETCH_PERMISSIONS: {
			const permissions: IPermission[] = action.payload;

			return {
				...state,
				api: {
					...state.api,
					fetch_permissions: {
						in_progress: true,
					},
				},
			};
		}

		case role.FETCH_PERMISSIONS_SUCCESS: {
			const permissions: IPermission[] = action.payload;
			return {
				...state,
				permissions,
				api: {
					...state.api,
					fetch_permissions: {
						in_progress: false,
					},
				},
			};
		}

		case role.FETCH_PERMISSIONS_FAIL: {
			const error: string = action.payload;
			return {
				...state,
				permissions: [],
				api: {
					...state.api,
					fetch_permissions: {
						in_progress: false,
						error,
					},
				},
			};
		}

		case AuthActions.logout: {
			return initialState;
		}

		default: {
			return state;
		}
	}
};

/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */

export const getEntities = (state: State) => {
	return state.entities;
};

export const getIds = (state: State) => state.ids;

export const getPermissions = (state: State) => state.permissions;

export const getSelectedId = (state: State) => state.display.selected_role_id;

export const getRoles = createSelector(getEntities, getIds, (entities, ids) => {
	return ids.map((id) => entities[id]);
});

export const getFetchInProgress = (state: State) => state.api.fetch.in_progress;
export const getAddInProgress = (state: State) => state.api.fetch.in_progress;
export const getUpdateInProgress = (state: State) =>
	state.api.update.in_progress;

export const getFetchPermissionsInProgress = (state: State) =>
	state.api.fetch_permissions.in_progress;

/** Export Errors **/
export const getFetchError = (state: State) => state.api.fetch.error;
export const getAddError = (state: State) => state.api.add.error;
export const getUpdateError = (state: State) => state.api.update.error;

export const getFetchPermissionsError = (state: State) =>
	state.api.fetch_permissions.error;
