/* eslint-disable github/array-foreach */
import {
	tap,
	takeUntil,
	skipWhile,
	timeout,
	catchError,
	combineLatestWith,
} from "rxjs/operators";
import { IWorkOrder, UserProfile } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { EMPTY, Observable } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnDestroy,
	OnInit,
	Injector,
} from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { WorkOrderDetailQuery } from "app/shared/eagers";
import { dateIsBefore, getISOString } from "app/shared/time-format";
import { Globals } from "app/shared/modules/globals/globals.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { PasswordInputComponent } from "app/modules/auth/components/password-input";
import { handleObservableError } from "app/shared/utils";
import { getRemaining } from "app/shared/work-order-input-processing";
import {
	getRemainingMeasureUnits,
	getRemainingSubstanceTypes,
} from "app/shared/work-order-functions";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import _ from "lodash";
import { DevCycleService } from "app/devcycle/devcycle.service";
import { DevCycleKey } from "app/devcycle/devcycleKeys";
import endOfDay from "date-fns/endOfDay";
import {
	ezGiMigration,
	generalInventoryEnabled,
} from "app/modules/dashboard/selectors/facility-settings.selector";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

import { GramsToUnitPipe } from "../../../modules/es-pipes/grams-to-unit.pipe";
import { BatchCloseComponent } from "../batch/close.component";

@Component({
	selector: "work-order-end",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderEndComponent
	extends GenericUpdateComponent<IWorkOrder>
	implements OnInit, OnDestroy
{
	user$: Observable<UserProfile | null> = this._store.select(
		fromDashboard.getProfile,
	);

	ezGiMigrated$ = this._store.select(ezGiMigration);
	generalInventoryEnabled$ = this._store.select(generalInventoryEnabled);
	ezGiMigrated: boolean;
	generalInventoryEnabled: boolean;

	user: UserProfile;

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/remaining": (value, property, form) => {
			if (value !== "") {
				return { remaining: {} };
			}
			return null;
		},
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/user_confirm": (value, property, form) => {
			if (this.model.remaining !== "" && (value === false || value === null)) {
				return { user_confirm: {} };
			}
			return null;
		},
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/timestamp": (value, property, form) => {
			const isValueBefore = dateIsBefore(endOfDay(new Date()), new Date(value));
			if (isValueBefore) {
				this.schema.warning =
					"The Date selected is past the current Date. Please verify this is the intended Date selected before Closing the Work Order.";
				this.schema.warning_translation_key = marker(
					"form_field_warning_the_date_must_be_in_the_past",
				);
				// return { timestamp: {} };
			} else {
				delete this.schema.warning;
				delete this.schema.warning_translation_key;
			}

			return null;
		},
	};

	private readonly work_order: IWorkOrder;
	private archiveOnClose = false;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _gramsToUnitPipe: GramsToUnitPipe,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
		private readonly _translocoService: TranslocoService,
		private readonly _devCycleService: DevCycleService,
	) {
		super(_store, _cd);
		this.work_order = this._injector.get("work_order", null);
		this.form_title = `Close work order #${this.work_order.id}`;
		this.form_title_translation_key = marker("form_title_close_work_order");
		this.form_title_translation_params = {
			work_order_id: `#${this.work_order.id}`,
		};
		this.submit_button = "Close";
		this.submit_button_translation_key = marker("word_close");
		this.ezGiMigrated$
			.pipe(combineLatestWith(this.generalInventoryEnabled$))
			.subscribe(([ezGiMigrated, giEnabled]) => {
				this.generalInventoryEnabled = giEnabled;
				this.ezGiMigrated = ezGiMigrated;
			});
		this.schema = {
			title: "",
			description: this._translocoService.translate(
				"form_description_note_after_ending_the_work_order_the_selected_weights_will_be_distributed_to_the_output_batches",
			),
			info: "",
			properties: {
				id: {
					type: "number",
					hidden: true,
				},
				timestamp: {
					type: "string",
					title: "Closing Date",
					title_translation_key: marker("form_field_label_closing_date"),
					widget: "date",
				},
				remaining: {
					title: "Remaining Inputs",
					title_translation_key: marker("form_field_label_remaining_inputs"),
					type: "string",
					widget: "textarea",
					rows: "3",
					warning:
						"Warning: Closing a work order will convert any unused (remaining) inputs into some form of Processing Loss.  Make sure this is what you want to do!",
					warning_translation_key: marker(
						"form_field_warning_closing_a_work_order_will_convert_any_unused_remaining_inputs_into_some_form_of_processing_loss",
					),
					readOnly: true,
					hidden: true,
					default: "",
				},
				user_confirm: {
					title: "I Understand",
					title_translation_key: marker("form_field_label_i_understand"),
					type: "boolean",
					widget: "checkbox",
					visibleIf: {
						remaining: [true],
					},
				},
			},
			required: ["timestamp"],
		};

		this._devCycleService
			.getVariable(DevCycleKey.ArchiveBatchesOnCLose, false)
			.subscribe((variable: any) => {
				this.archiveOnClose = variable.value;
			});
	}

	valid(v) {
		this.valid$.next(v);
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
			delete this.schema.required;
		}
		this.user$
			.pipe(
				takeUntil(this.destroyed$),
				skipWhile((user: any) => user == null),
			)
			.subscribe((user) => {
				this.user = user;
			});

		this.model.id = this.work_order.id;
		this.model.timestamp = getISOString();
		const comboRemainingItems: any[] = [];
		if (this.ezGiMigrated) {
			const remainingProducts = this.work_order.totalProductRemaining ?? {};
			for (const [productName, remainingPerUnit] of Object.entries(
				remainingProducts,
			)) {
				for (const [unitName, value] of Object.entries(remainingPerUnit ?? {})) {
					if (value) {
						const finalString = `${value}${unitName} ${productName}`;
						comboRemainingItems.push(finalString);
					}
				}
			}
		} else if (!this.generalInventoryEnabled) {
			let finalString = "";
			const remainingSubstanceTypes = getRemainingSubstanceTypes(this.work_order);
			remainingSubstanceTypes.forEach((remainingSubstanceType) => {
				const remainingMeasureUnitsForSubstanceType = getRemainingMeasureUnits(
					this.work_order,
					remainingSubstanceType.id,
				);

				remainingMeasureUnitsForSubstanceType.forEach((remainingMeasureUnit) => {
					const relevantFacilityUnit =
						this._globals.getFacilityUnit(remainingMeasureUnit);

					if (remainingMeasureUnit === "MASS") {
						finalString = `${this._gramsToUnitPipe.transform(
							getRemaining(
								this.work_order,
								remainingSubstanceType.id,
								remainingMeasureUnit,
							),
							relevantFacilityUnit,
						)} ${relevantFacilityUnit} ${remainingSubstanceType.name}`;
					} else if (remainingMeasureUnit === "QUANTITY") {
						finalString = `${getRemaining(
							this.work_order,
							remainingSubstanceType.id,
							remainingMeasureUnit,
						)} ${relevantFacilityUnit} ${remainingSubstanceType.name}`;
						// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
					} else if (remainingMeasureUnit === "VOLUME") {
						finalString = `${getRemaining(
							this.work_order,
							remainingSubstanceType.id,
							remainingMeasureUnit,
						)} ${relevantFacilityUnit} ${remainingSubstanceType.name}`;
					} else {
						finalString = "Other substance type";
					}

					comboRemainingItems.push(finalString);
				});
			});
		}

		let hideRemainingFields = true;
		if (this.ezGiMigrated) {
			if (comboRemainingItems.length) {
				hideRemainingFields = false;
			}
		} else if (!this.generalInventoryEnabled) {
			hideRemainingFields = getRemaining(this.work_order) === 0;
		}

		if (!hideRemainingFields) {
			this.schema.required = [...(this.schema.required ?? []), "user_confirm"];
			this.model.remaining = comboRemainingItems.join("\n");
			this.schema.properties.remaining.hidden = false;
		}

		const work_order_type = this.work_order.work_order_type;
		if (
			work_order_type !== undefined &&
			(this.work_order.harvest_batch !== undefined ||
				this.work_order.drying_batch !== undefined ||
				this.generalInventoryEnabled)
		) {
			this.schema.properties.destination_location_id = {
				type: "number",
				title: work_order_type.location_change_on_close
					? "Destination Location"
					: "Destination Location (optional)",
				title_translation_key: work_order_type.location_change_on_close
					? marker("form_field_label_destination_location")
					: marker("form_field_label_destination_location_optional"),
				description: `Move ${
					this.generalInventoryEnabled ? "outputs" : "batch"
				} to destination on closing date`,
				description_translation_key: this.generalInventoryEnabled
					? marker(
							"form_field_description_move_outputs_to_destination_on_closing_date",
						)
					: marker(
							"form_field_description_move_batch_to_destination_on_closing_date",
						),
				widget: "data-select",
				oneOf: [
					{
						result_type: "locations",
					},
				],
			};
			if (work_order_type.location_change_on_close) {
				this.schema.required.push("destination_location_id");
			}
		}
		if (!this._globals.gmp_enabled) {
			this._itemService
				.fetchItem(`work_order`, `${this.work_order.id}/event_log`)
				.pipe(
					takeUntil(this.destroyed$),
					timeout(2500),
					catchError((error) => {
						return EMPTY;
					}),
				)
				.subscribe((items) => {
					const lastClose = items
						.sort((one, two) => {
							return (
								new Date(two.created_at).getTime() - new Date(one.created_at).getTime()
							);
						})
						.find((log) => log.action === "CLOSE");
					if (lastClose.date) {
						this.model.timestamp = lastClose.date;
					}
				});
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(work_order: Partial<IWorkOrder>): void {
		if (this._globals.gmp_enabled) {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: PasswordInputComponent,
					inputs: {
						onSubmit: (password) => {
							this.updateWorkOrder(work_order, true, password);
						},
					},
				}),
			);
		} else {
			this.updateWorkOrder(work_order);
		}
	}

	updateWorkOrder(
		work_order: Partial<IWorkOrder>,
		signing = false,
		password?: string,
	) {
		const work_order_data = {
			...work_order,
			timestamp: (work_order as any).timestamp ?? new Date().toISOString(),
		};

		const authObject = password
			? { username: this.user.email, password }
			: undefined;
		this.loading$.next(true);
		this._itemService
			.update(
				`work_order`,
				`${work_order.id}/close`,
				work_order_data,
				signing
					? { ...WorkOrderDetailQuery, signing: "true" }
					: WorkOrderDetailQuery,
				authObject,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);

					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this.loading$.next(false);
					this.closeSidenav();

					const batch_ids = _.union(
						updatedItem.sources?.map((item) => item.batch_id),
						updatedItem.outputs?.map((item) => item.batch_id),
						updatedItem.destruction_events?.map((item) => item.batch_id),
					)
						// ESS-8045: Need this filter to remove any null values as sources, outputs,
						// and destruction events could all have null batch_id values if they are using
						// a non batched product
						.filter((batch_id) => Boolean(batch_id));

					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);

					if (batch_ids.length && this.archiveOnClose) {
						this.getEmptyBatch(batch_ids)
							.pipe(
								catchError(() => {
									return EMPTY;
								}),
							)
							.subscribe((batchesToClose) => {
								if (batchesToClose.length) {
									this._store.dispatch(
										layoutActions.openSidenav({
											component: BatchCloseComponent,
											inputs: {
												ids: batchesToClose,
												only_show_valid_batches: true,
												onUpdated: () => {
													this.closeSidenav();
												},
											},
										}),
									);
								} else {
									this.closeSidenav();
								}
							});
					}
				}),
			)
			.subscribe();
	}

	getEmptyBatch(batch_ids): any {
		return this._itemService.fetchItem("batches/empty", `${batch_ids.join(";")}`);
	}
}
