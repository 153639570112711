import { IInventoryProduct } from "@elevatedsignals/amygoodman";

export const onVendorChange = (
	model,
	schema,
	inventory_product: IInventoryProduct,
) => {
	if (inventory_product.vendors?.length === 1) {
		model.vendor_id = inventory_product.vendors[0]!.id;
		schema.properties.vendor_id.hidden = true;
	} else if ((inventory_product.vendors?.length ?? 0) > 1) {
		delete model.vendor_id;
		schema.properties.vendor_id.hidden = false;
	} else {
		schema.properties.vendor_id.hidden = true;
		delete model.vendor_id;
	}

	return [model, schema];
};
