import {
	ChangeDetectorRef,
	Component,
	Injector,
	OnDestroy,
	OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import {
	MeasureUnit,
	IMeasureEvent,
	ISeedLot,
	ISelectListItem,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { BatchDetailQuery } from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { getTotal, handleObservableError } from "app/shared/utils";
import { EMPTY, ReplaySubject } from "rxjs";
import { catchError, timeout, takeUntil } from "rxjs/operators";
import { UnitToBaseMeasureUnitPipe } from "app/modules/dashboard/modules/es-pipes/units/unit_to_base_measure_unit.pipe";
import { TitleCasePipe } from "@angular/common";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";

import { GenericCreateComponent } from "../generic/generic-create.component";

export enum AdjustmentType {
	New = "NEW",
	Addition = "ADD",
	Subtraction = "SUBTRACT",
}

@Component({
	selector: "measure-event-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
// @deprecated
export class CreateMeasureEventComponent
	extends GenericCreateComponent<ISeedLot>
	implements OnInit, OnDestroy
{
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Create Reconciliation";
	submit_button = "Add Reconciliation";
	form_title_translation_key: string = marker(
		"form_title_create_reconciliation",
	);

	submit_button_translation_key: string = marker(
		"form_button_add_reconciliation",
	);

	submit_icon = "plus";

	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	measurements: IMeasureEvent[];
	substance_type_id: number;
	measure_unit: MeasureUnit | undefined;
	measure_setting: string;
	related_item_id: number;
	model: any = {};
	adjustment_reasons: { name: string; name_translation_key?: string }[] = [
		{ name: "Harvested", name_translation_key: marker("word_harvested") },
		{ name: "Dried", name_translation_key: marker("word_dried") },
		{
			name: "Processing Loss",
			name_translation_key: marker("word_processing_loss"),
		},
		{ name: "Packaged", name_translation_key: marker("word_packaged") },
		{ name: "Transferred", name_translation_key: marker("word_transferred") },
		{ name: "Drying Loss", name_translation_key: marker("word_drying_loss") },
		{ name: "Destroyed", name_translation_key: marker("word_destroyed") },
		{
			name: "Lost/Theft",
			name_translation_key: marker("form_field_value_lost_theft"),
		},
		{
			name: "Lab Test",
			name_translation_key: marker("form_field_value_lab_test"),
		},
		{ name: "Returns", name_translation_key: marker("word_returns") },
		{
			name: "Opening Inventory",
			name_translation_key: marker("word_opening_inventory"),
		},
		{ name: "Other", name_translation_key: marker("word_other") },
	];

	adjustment_types: ISelectListItem[] = [
		{
			name: "Addition",
			name_translation_key: marker("word_addition"),
			value: AdjustmentType.Addition,
			enum: [AdjustmentType.Addition],
		},
		{
			name: "Subtraction",
			name_translation_key: marker("word_subtraction"),
			value: AdjustmentType.Subtraction,
			enum: [AdjustmentType.Subtraction],
		},
		{
			name: "New Value",
			name_translation_key: marker("form_field_value_new_value"),
			value: AdjustmentType.New,
			enum: [AdjustmentType.New],
		},
	];

	// substance_types: ISelectListItem[] = [
	// 	{
	// 		name: "Dried Cannabis",
	// 		value: MeasureContentsType.DriedCannabis,
	// 		enum: [MeasureContentsType.DriedCannabis],
	// 	},
	// 	{
	// 		name: "Fresh Cannabis",
	// 		value: MeasureContentsType.FreshCannabis,
	// 		enum: [MeasureContentsType.FreshCannabis],
	// 	},
	// ];
	weight_unit: string = this._globals.weight_unit;

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			substance_type_id: {
				type: "number",
				title: "Substance",
				title_translation_key: marker("word_substance"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "substance_types",
					},
				],
			},
			adjustment_type: {
				type: "string",
				title: "Adjustment Type",
				title_translation_key: marker("form_field_label_adjustment_type"),
				widget: "select",
				oneOf: this.adjustment_types,
			},
			measure_unit: {
				type: "string",
				title: "Measure Type",
				title_translation_key: marker("form_field_label_measure_type"),
				widget: "select",
				oneOf: [
					{
						name: this._titleCasePipe.transform(MeasureUnit.Mass),
						name_translation_key: marker("word_mass"),
						value: MeasureUnit.Mass,
						enum: [MeasureUnit.Mass],
					},
					{
						name: this._titleCasePipe.transform(MeasureUnit.Volume),
						name_translation_key: marker("word_volume"),
						value: MeasureUnit.Volume,
						enum: [MeasureUnit.Volume],
					},
					{
						name: this._titleCasePipe.transform(MeasureUnit.Quantity),
						name_translation_key: marker("word_quantity"),
						value: MeasureUnit.Quantity,
						enum: [MeasureUnit.Quantity],
					},
				],
				default: MeasureUnit.Mass,
			},
			value: {
				widget: "number",
				type: "number",
				step: "0.00001",
				title: `Set Value (${this.weight_unit})`,
				title_translation_key: marker("form_field_label_set_value"),
				title_translation_params: { units: this.weight_unit },
				visibleIf: {
					allOf: [
						{ adjustment_type: [AdjustmentType.New] },
						{
							measure_unit: [
								MeasureUnit.Mass,
								MeasureUnit.Quantity,
								MeasureUnit.Volume,
							],
						},
					],
				},
			},
			adjusted: {
				widget: "number",
				type: "number",
				step: "0.00001",
				title: `Adjustment (${this.weight_unit})`,
				title_translation_key: marker("form_field_label_adjustment"),
				title_translation_params: { units: this.weight_unit },
				visibleIf: {
					allOf: [
						{
							adjustment_type: [AdjustmentType.Addition, AdjustmentType.Subtraction],
						},
						{
							measure_unit: [
								MeasureUnit.Mass,
								MeasureUnit.Quantity,
								MeasureUnit.Volume,
							],
						},
					],
				},
			},
			reason: {
				type: "string",
				title: "Reason",
				title_translation_key: marker("word_reason"),
				widget: "select",
				oneOf: this.adjustment_reasons.map((item) => {
					return {
						name: item.name,
						name_translation_key: item.name_translation_key,
						value: item.name,
						enum: [item.name],
					};
				}),
			},
			reason_other: {
				type: "string",
				title: "Other Reason",
				title_translation_key: marker("form_field_label_other_reason"),
				visibleIf: {
					reason: ["Other"],
				},
				default: "",
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
			timestamp: {
				type: "string",
				title: "Date",
				title_translation_key: marker("word_date"),
				widget: "date",
			},
		},
		required: ["reason", "substance_type_id", "adjustment_type", "measure_unit"],
	};

	validators = {};

	private measure_setting_fallback: string;
	private prevMeasureUnit: MeasureUnit;

	constructor(
		private readonly _injector: Injector,
		_store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _unitToBaseMeasureUnit: UnitToBaseMeasureUnitPipe,
		private readonly _globals: Globals,
		private readonly _titleCasePipe: TitleCasePipe,
	) {
		super(_store);
	}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.substance_type_id = this._injector.get("substance_type_id", null);
		this.measurements = this._injector.get("measurements", []);
		this.measure_unit = this._injector.get("measure_unit", null);
		this.measure_setting = this._injector.get("measure_setting", undefined);
		this.related_item_id = this._injector.get("related_item_id", null);

		const units = this._globals.getFacilityUnit(
			this.measure_unit || MeasureUnit.Mass,
			this.measure_setting,
		);
		this.schema.properties.value.title = `Set Value (${units})`;
		this.schema.properties.value.title_translation_params = {
			units,
		};
		this.schema.properties.adjusted.title = `Adjustment (${units})`;
		this.schema.properties.adjusted.title_translation_params = {
			units,
		};

		if (this.substance_type_id) {
			this.model = {
				...this.model,
				substance_type_id: this.substance_type_id,
			};
		}

		if (this.measure_unit) {
			this.model = {
				...this.model,
				measure_unit: this.measure_unit,
			};
		} else {
			this.measure_unit = MeasureUnit.Mass;
		}
		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		if (model.measure_unit && model.measure_unit != this.prevMeasureUnit) {
			this.measure_setting_fallback = this.measure_setting || "batch";
			this.measure_unit = model.measure_unit;
			const units = this._globals.getFacilityUnit(
				model.measure_unit,
				this.measure_setting_fallback,
			);
			this.schema.properties.value.title = `Set Value (${units})`;
			this.schema.properties.value.title_translation_params = {
				units,
			};
			this.schema.properties.adjusted.title = `Adjustment (${units})`;
			this.schema.properties.adjusted.title_translation_params = {
				units,
			};
			if (this.measure_unit === MeasureUnit.Quantity) {
				this.schema.properties.value.step = "1";
				this.schema.properties.adjusted.step = "1";
			} else {
				this.schema.properties.value.step = ".0001";
				this.schema.properties.adjusted.step = ".0001";
			}
			this.prevMeasureUnit = model.measure_unit;
		}
	}

	createItem(model) {
		let value = this._unitToBaseMeasureUnit.transform(
			this.model.adjustment_type === AdjustmentType.New
				? model.value
				: model.adjusted,
			this._globals.getFacilityUnit(this.measure_unit!, "batch") ?? "",
			this.measure_unit!,
		);
		switch (this.model.adjustment_type) {
			case AdjustmentType.New: {
				const total = getTotal(
					this.measurements,
					this.model.substance_type_id,
					this.measure_unit!,
				);

				value -= total;
				break;
			}
			case AdjustmentType.Addition:
				if (value < 0) {
					value *= -1;
				}
				break;
			case AdjustmentType.Subtraction:
				if (value > 0) {
					value *= -1;
				}
				break;
			default:
				break;
		}
		const adjustment = {
			substance_type_id: this.model.substance_type_id,
			measure_unit: this.measure_unit,
			value,
			reason:
				this.model.reason === "Other"
					? this.model.reason_other || this.model.reason
					: this.model.reason,
			description: this.model.description,
			timestamp: this.model.timestamp,
		};
		// console.log("Adjustment:", adjustment);
		this.createEvent(adjustment);
	}

	createEvent(event: any) {
		this.loading$.next(true);
		const query = BatchDetailQuery;

		this._itemService
			.add(`batch/${this.related_item_id}/measurements`, event, query)
			.pipe(
				takeUntil(this.destroyed$),
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((updatedItem) => {
				this._store.dispatch(
					ItemActions.updateSuccess({
						updatedItem,
						result_type: "batches",
					}),
				);
				this.loading$.next(false);
				this.closeSidenav();
			});
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
