import { IFacility, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "facilities";

export type State = Page<IFacility>;

export const reducer = createPagingReducer<IFacility>(reducerKey, {
	result_type: "facilities",
	title: "Facility",
	title_plural: "Facilities",
	title_translation_key: marker("page_title_facilities"),
	title_plural_translation_key: marker("page_title_plural_facilities"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
});
