import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import {
	UntypedFormGroup,
	FormControl,
	Validators,
	UntypedFormBuilder,
} from "@angular/forms";

import { ForgotPassword } from "../../models/forgot-password.model";

@Component({
	selector: "forgot-password-input",
	template: `
		<div *ngIf="send_success" class="ui message">
			<div class="header">Email sent!</div>
			<p>We have sent an email to {{ forgotEmail }} with further instructions.</p>
			<ul class="list">
				<li>
					This email will help you recover your account and create a new password.
				</li>
				<li>The email link must be clicked within a limited time.</li>
			</ul>
		</div>

		<div *ngIf="!send_success" class="input-form">
			<div class="logo"></div>
			<form
				[formGroup]="forgotPasswordForm"
				(ngSubmit)="onSubmit()"
				class="ui form"
			>
				<div class="field forgot">
					<input
						mdInput
						required
						formControlName="email"
						type="email"
						placeholder="Email"
					/>
				</div>

				<div *ngIf="errorMessage" class="ui negative message">
					<p>{{ errorMessage }}</p>
				</div>

				<button
					class="send ui"
					[ngClass]="
						pending ? 'loading button disabled blue login' : 'button blue login'
					"
					type="submit"
					[disabled]="!forgotPasswordForm.valid"
				>
					Send Email
				</button>

				<p class="signup">
					<span class="blend">Already know your password?</span>
					<a routerLink="/signin"> Sign In</a>
				</p>
			</form>
		</div>
	`,
	styleUrls: ["./index.scss"],
})
export class ForgotPasswordInputComponent implements OnInit {
	forgotPasswordForm: UntypedFormGroup;

	@Input() errorMessage: string;
	@Input() pending: boolean;
	@Input() send_success: boolean;
	@Output() forgotPassword = new EventEmitter<ForgotPassword>();

	forgotEmail: string;

	constructor(private readonly _fb: UntypedFormBuilder) {}

	ngOnInit() {
		this.forgotPasswordForm = this._fb.group({
			email: [null, Validators.required],
		});
	}

	onSubmit() {
		if (this.forgotPasswordForm.valid) {
			// console.log(this.forgotPasswordForm.value);
			const value: ForgotPassword = this.forgotPasswordForm
				.value as ForgotPassword;
			// console.log("Forgot Password", value);
			this.forgotEmail = value.email;
			this.forgotPassword.emit(value);
		}
	}

	// onSubmit({ value, valid }: { value: UserSignin, valid: boolean }) {
	//   console.log(value, valid);
	// }
	// ngOnInit() {
	//   this.location$.subscribe(location=> this.model.location_id = location.id);
	// }
}
