import { IInventory } from "@elevatedsignals/amygoodman";

// Gets the first inventory found in the facility that was received from this pending inventory
// If copied inventory is provided, it will also check that inventory for the destination inventory
// If no destination inventory is found, it will return undefined
export const getDestinationInventory = (
	pendingInventory: IInventory | null,
	copiedPendingInventory: IInventory | null,
	debitOrCredit: "debit_event" | "credit_event" = "debit_event",
) => {
	for (const measureEvent of pendingInventory?.measurements ?? []) {
		const debitInventory = measureEvent[debitOrCredit]?.inventory;
		if (
			debitInventory &&
			(debitInventory.batch_id || debitInventory.location_id)
		) {
			return debitInventory;
		}
	}
	for (const measureEvents of copiedPendingInventory?.measurements ?? []) {
		const debitInventory = measureEvents[debitOrCredit]?.inventory;
		if (
			debitInventory &&
			(debitInventory.batch_id || debitInventory.location_id)
		) {
			return debitInventory;
		}
	}
	return undefined;
};
