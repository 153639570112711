import {
	HeaderQueryType,
	IInventoryProduct,
	Page,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { InventoryProductPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "inventory_products";

export type State = Page<IInventoryProduct>;

export const inventoryProductsHeaderQueries = [
	{
		title: "Inventory Product Filter",
		key: "inventory_product_filter",
		type: HeaderQueryType.select,
		options: [
			{
				label: "Show Active",
				translationKey: marker("inventory_product_filter_show_active"),
				value: "ACTIVE",
			},
			{
				label: "Show Archived",
				translationKey: marker("inventory_product_filter_show_archived"),
				value: "ARCHIVED",
			},
			{
				label: "Show All",
				translationKey: marker("inventory_product_filter_show_all"),
				value: "ALL",
			},
		],
		value: "ACTIVE",
	},
	{
		title: "Vendors",
		title_translation_key: marker("page_title_plural_vendors"),
		key: "vendor_id",
		type: HeaderQueryType.dataSelect,
		result_type: "vendors",
		value_key: "id",
		text_key: "name",
		disabled: false,
		multiple: false,
		search: true,
		value: [],
	},
	{
		title: "Categories",
		title_translation_key: marker("page_title_plural_categories"),
		key: "inventory_category_id",
		type: HeaderQueryType.dataSelect,
		result_type: "inventory_categories",
		value_key: "id",
		text_key: "name",
		disabled: false,
		multiple: false,
		search: true,
	},
];

export const reducer = createPagingReducer<IInventoryProduct>(reducerKey, {
	result_type: "inventory_products",
	title: "Inventory Product",
	title_translation_key: marker("page_title_inventory_products"),
	title_plural_translation_key: marker("page_title_plural_inventory_products"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: inventoryProductsHeaderQueries,
	query: InventoryProductPageQuery,
});
