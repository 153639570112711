import { createReducer, on, createFeature } from "@ngrx/store";
import { Floorplan } from "@elevatedsignals/amygoodman";

import { DownloadActions } from "../actions/download.actions";

export interface State {
	fileName: string | undefined;
	pdfDownloadInProgress: boolean;
	error: string | undefined;
}
export const initialState: State = {
	fileName: undefined,
	pdfDownloadInProgress: false,
	error: undefined,
};

const downloadReducer = createReducer(
	initialState,
	on(DownloadActions.pdfEmailRequestStart, (state, action) => {
		return {
			...state,
			fileName: undefined,
			pdfDownloadInProgress: true,
			error: undefined,
		};
	}),
	on(DownloadActions.pdfDownloadStart, (state, action) => {
		return {
			...state,
			fileName: action.fileName,
			pdfDownloadInProgress: true,
			error: undefined,
		};
	}),
	on(DownloadActions.pdfDownloadSuccess, (state) => {
		return {
			...state,
			pdfDownloadInProgress: false,
			error: undefined,
		};
	}),
	on(DownloadActions.pdfDownloadFail, (state, action) => {
		return {
			...state,
			pdfDownloadInProgress: false,
			error: action.error,
		};
	}),
	on(DownloadActions.pdfDownloadClearError, (state) => {
		return {
			...state,
			error: undefined,
		};
	}),
);

const downloadFeature = {
	reducer: downloadReducer,
	selectpdfDownloadInProgress: (state: State) => state.pdfDownloadInProgress,
	selectPDFDownloadError: (state: State) => state.error,
	selectPdfFileName: (state: State) => state.fileName,
};

export const {
	reducer, // feature reducer
	// Automatically generated selectors for the feature state
	selectpdfDownloadInProgress,
	selectPDFDownloadError,
	selectPdfFileName,
} = downloadFeature;
