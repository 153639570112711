import { createReducer, on, Action } from "@ngrx/store";

import { AuthActions } from "../actions/auth.actions";

export interface State {
	error: string | null;
	pending: boolean;
	success: boolean;
}

const initialState: State = {
	error: null,
	pending: false,
	success: false,
};

const forgotPasswordReducer = createReducer(
	initialState,
	on(AuthActions.navigationSuccess, (state) => {
		return {
			...state,
			pending: false,
		};
	}),
	on(AuthActions.forgotPassword, (state) => {
		return {
			...state,
			error: null,
			pending: true,
		};
	}),
	on(AuthActions.forgotPasswordSuccess, (state) => {
		return {
			...state,
			error: null,
			pending: false,
			success: true,
		};
	}),
	on(AuthActions.forgotPasswordFail, (state, { error }) => {
		return {
			...state,
			error,
			pending: false,
			success: false,
		};
	}),
);

export const reducer = (state: State | undefined, action: Action) =>
	forgotPasswordReducer(state, action);

export const getPending = (state: State) => state.pending;
export const getError = (state: State) => state.error;
export const getSuccess = (state: State) => state.success;
