import {
	tap,
	take,
	takeUntil,
	timeout,
	catchError,
	skipWhile,
	switchMap,
} from "rxjs/operators";
import { ReplaySubject, EMPTY, Observable, from } from "rxjs";
import {Component, OnInit, OnDestroy, ChangeDetectorRef, inject} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { Batch, UserProfile } from "@elevatedsignals/amygoodman";
import { BatchDetailQuery } from "app/shared/eagers";
import { PasswordInputComponent } from "app/modules/auth/components/password-input";
import { Globals } from "app/shared/modules/globals/globals.service";
import { Permissions, PERMISSIONS } from "app/shared/permissions";
import { canCloseBatch, handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { FetchPageActions } from "app/modules/dashboard/actions/paging.actions";
import { PagerService } from "app/modules/dashboard/services/pager.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { TranslocoService } from "@jsverse/transloco";

import { GeneralBatchActionSchemaProperties } from "../work-order/schemas/batch-action";

import { BatchCloseComponent } from "./close.component";

@Component({
	selector: "batch-split-plant",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class BatchSplitPlantComponent implements OnInit, OnDestroy {
  private _pagerService = inject(PagerService);

	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	// This will only get batches from last Batch List page from storage -> not full validation
	batches$ = this._store.select(fromDashboard.getGiBatchPage);
	batch_names: string[] = [];
	batch$ = this._store.select(fromDashboard.getSelectedBatch);
	batch: Batch;
	batches: any[] | undefined;

	user$: Observable<UserProfile | null> = this._store.select(
		fromDashboard.getProfile,
	);

	user: UserProfile | null;

	form_title = "Split a growing batch";
	form_title_translation_key: string = marker(
		"form_title_split_a_growing_batch",
	);

	submit_button = "Split Batch";
	submit_button_translation_key: string = marker("form_button_split_batch");
	submit_icon = "code branch";
	loading = false;
	isNewBatchSelected = true;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			split_type: {
				type: "string",
				title: "Split type",
				title_translation_key: marker("form_field_label_split_type"),
				widget: "select",
				oneOf: [
					{
						name: "Two New Batches",
						name_translation_key: marker("form_field_value_two_new_batches"),
						value: "NEW",
						enum: ["NEW"],
					},
					{
						name: "Offshoot Batch",
						name_translation_key: marker("form_field_value_offshoot_batch"),
						value: "OFFSHOOT",
						enum: ["OFFSHOOT"],
					},
				],
			},
			section1: {
				type: "string",
				widget: "section",
				title: "New Batch #1",
				title_translation_key: marker("form_field_label_new_batch_one"),
				visibleIf: {
					split_type: ["NEW"],
				},
			},
			split_to_existing_batch_or_new: {
				type: "string",
				title: "Split to:",
				title_translation_key: marker("form_field_label_split_to"),
				widget: "radio",
				oneOf: [
					{
						name: "New Batch",
						name_translation_key: marker("form_field_value_new_batch"),
						value: "New Batch",
						enum: ["New Batch"],
					},
					{
						name: "Existing Batch",
						name_translation_key: marker("form_field_value_existing_batch"),
						value: "Existing Batch",
						enum: ["Existing Batch"],
					},
				],
				default: "New Batch",
				visibleIf: {
					split_type: ["OFFSHOOT"],
				},
			},
			section2: {
				type: "string",
				widget: "section",
				title: "New Batch",
				title_translation_key: marker("form_field_label_new_batch"),
				visibleIf: {
					allOf: [
						{
							split_type: ["OFFSHOOT"],
						},
						{ split_to_existing_batch_or_new: ["New Batch"] },
					],
				},
			},
			split_existing_batch_id: {
				type: "number",
				title: "Existing Batch",
				title_translation_key: marker("form_field_label_existing_batch"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "batches",
						queryString: {
							eager: "[growth_stages.[growth_stage_type]]",
						},
					},
				],
				visibleIf: {
					allOf: [
						{ split_type: ["OFFSHOOT"] },
						{ split_to_existing_batch_or_new: ["Existing Batch"] },
					],
				},
				warning:
					"Warning: The Existing Batch's Cultivar/Growth Stage do not match this batch's",
				warning_translation_key: marker(
					"form_field_warning_batch_cultivar_growth_stage_do_not_match",
				),
			},
			split_batch_name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				visibleIf: {
					allOf: [{ split_type: ["$ANY$"] }, { new_batch_is_selected: [true] }],
				},
				default: "",
				warning: "Warning: Batch name already in use.",
				warning_translation_key: marker(
					"form_field_warning_batch_name_already_in_use",
				),
				info_text: this._translocoService.translate(
					"batch_name_will_be_set_to_batch_id_if_name_is_left_blank",
				),
			},
			split_batch_location_id: {
				type: "number",
				title: "Location",
				title_translation_key: marker("word_location"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "locations",
					},
				],
				visibleIf: {
					allOf: [{ split_type: ["$ANY$"] }, { new_batch_is_selected: [true] }],
				},
			},
			number_of_plants: {
				type: "number",
				title: "Number of Plants",
				title_translation_key: marker("form_field_label_number_of_plants"),
				visibleIf: {
					split_type: ["NEW", "OFFSHOOT"],
				},
			},
			split_batch_description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
				visibleIf: {
					split_type: ["NEW", "OFFSHOOT"],
				},
			},
			section3: {
				type: "string",
				widget: "section",
				title: "New Batch #2",
				title_translation_key: marker("form_field_label_new_batch_two"),
				visibleIf: {
					split_type: ["NEW"],
				},
			},
			remaining_plant_batch_name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				visibleIf: {
					split_type: ["NEW"],
				},
				default: "",
				warning: "Warning: Batch name already in use.",
				warning_translation_key: marker(
					"form_field_warning_batch_name_already_in_use",
				),
			},
			remaining_plant_batch_location_id: {
				type: "number",
				title: "Location",
				title_translation_key: marker("word_location"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "locations",
					},
				],
				visibleIf: {
					split_type: ["NEW"],
				},
			},
			number_of_plants2: {
				type: "number",
				title: "Number of Plants",
				title_translation_key: marker("form_field_label_number_of_plants"),
				readonly: true,
				visibleIf: {
					split_type: ["NEW"],
				},
			},
			remaining_plant_batch_description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
				visibleIf: {
					split_type: ["NEW"],
				},
			},
			timestamp: {
				type: "string",
				title: "Move Date",
				title_translation_key: marker("form_field_label_move_date"),
				widget: "date",
				minDate: undefined,
			},
			work_order_description: {
				type: "string",
				widget: "textarea",
				title: "Work Order Description",
				title_translation_key: marker("form_field_label_work_order_description"),
			},
			...GeneralBatchActionSchemaProperties,

			new_batch_is_selected: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
			},
			existing_batch_is_selected: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: true,
				visibleIf: {
					allOf: [
						{ split_type: ["OFFSHOOT"] },
						{ split_to_existing_batch_or_new: ["Existing Batch"] },
					],
				},
			},
		},
		required: ["split_type", "number_of_plants"],
	};

	validators = {
		"/number_of_plants": (value, _property, _form) => {
			if (value !== "") {
				if (value < 0 || value > this.batch ? this.batch.plant_count : 0) {
					return { number_of_plants: {} };
				}
			}
			return null;
		},
		"/split_batch_name": (value, _property, _form) => {
			if (value !== "") {
				if (
					this.batch_names.length > 0 &&
					this.batch_names.includes(value.trim())
				) {
					return { name: {} };
				}
			}
			return null;
		},
		"/remaining_plant_batch_name": (value, _property, _form) => {
			if (value !== "") {
				if (
					this.batch_names.length > 0 &&
					this.batch_names.includes(value.trim())
				) {
					return { name: {} };
				}
			}
			return null;
		},
		"/split_existing_batch_id": (value, _property, _form) => {
			if (value !== "" && this.batches && this.batches.length > 0) {
				const selectedSplitBatch = this.batches.filter(
					(batch) => batch.id === value,
				);
				if (selectedSplitBatch.length !== 1) return null;

				// Check for Cultivar mismatch
				if (selectedSplitBatch[0].cultivar_id !== this.batch.cultivar_id) {
					return { split_existing_batch_id: {} };
				}

				// Check for Growth stage mismatch
				if (
					selectedSplitBatch[0] &&
					selectedSplitBatch[0]?.growth_stages &&
					selectedSplitBatch[0]?.growth_stages.length > 0
				) {
					const splitGrowthStageNames = selectedSplitBatch[0].growth_stages.map(
						(stage) => stage.name,
					);
					const selectedBatchGrowthStageNames = this.batch.growth_stages!.map(
						(stage) => stage.name,
					);
					for (const name of selectedBatchGrowthStageNames) {
						if (!splitGrowthStageNames.includes(name)) {
							return { split_existing_batch_id: {} };
						}
					}
				}
			}
			return null;
		},
	};

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _globals: Globals,
		private readonly _translocoService: TranslocoService,
	) {}

	onError(_error) {}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.user$
			.pipe(
				takeUntil(this.destroyed$),
				skipWhile((user: UserProfile | null) => user == null),
			)
			.subscribe((user: UserProfile) => {
				this.user = user;

				// Remove New Batch if user doesn't have Batch -> Add permission
				if (!Permissions.hasPermission(user, PERMISSIONS.BATCHES_ADD)) {
					this.schema.properties.split_type.oneOf = [
						{
							name: "Offshoot Batch",
							name_translation_key: marker("form_field_value_offshoot_batch"),
							value: "OFFSHOOT",
							enum: ["OFFSHOOT"],
						},
					];
					this.schema.properties.split_to_existing_batch_or_new.oneOf = [
						{
							name: "Existing Batch",
							name_translation_key: marker("form_field_value_existing_batch"),
							value: "Existing Batch",
							enum: ["Existing Batch"],
						},
					];

					this.model.split_type = "OFFSHOOT";
					this.model.split_to_existing_batch_or_new = "Existing Batch";
				}
			});

		this.batch$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((batch: Batch) => {
				this.batch = batch;
				// const growth_stage_id =
				// 	this.batch.growth_stages && this.batch.growth_stages.length > 0
				// 		? this.batch.growth_stages[0]?.growth_stage_type_id
				// 		: undefined;
				this.model = {
					...this.model,
					id: this.batch.id,
				};
				this.schema.properties = {
					...this.schema.properties,
					number_of_plants: {
						...this.schema.properties.number_of_plants,
						max: this.batch.plant_count,
						min: 0,
					},
				};
				if (!this._globals.gmp_enabled) {
					this.schema.properties = {
						...this.schema.properties,
						timestamp: {
							...this.schema.properties.timestamp,
							minDate: new Date(this.batch.created_at),
						},
						split_batch_location_id: {
							...this.schema.properties.split_batch_location_id,
							default: this.batch.location_id,
						},
						remaining_plant_batch_location_id: {
							...this.schema.properties.remaining_plant_batch_location_id,
							default: this.batch.location_id,
						},
					};
				}
				this._cd.detectChanges();
			});

		this.batches$.pipe(takeUntil(this.destroyed$)).subscribe((batches) => {
			this.batches = batches ? batches.results : [];
		});
		this._itemService
			.fetchItem(`batches/fetch/names`, "", { col: "name" })
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err));
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.batch_names = value;
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const split: any = {
			...this.model,
		};
		if (this._globals.gmp_enabled) {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: PasswordInputComponent,
					inputs: {
						onSubmit: (password) => {
							this.updateBatch(split, true, password);
						},
					},
				}),
			);
		} else {
			this.updateBatch(split, true);
		}
	}

	onChanges(form_data): void {
		if (this.isNewBatchSelected !== !this.model.existing_batch_is_selected) {
			this.model.new_batch_is_selected = !this.model.existing_batch_is_selected;
			this.isNewBatchSelected = !this.model.existing_batch_is_selected;
			this._cd.detectChanges();
		}

		const numberOfPlants1 = form_data.number_of_plants;
		const numberOfPlants2 =
			Number(this.batch.plant_count) - form_data.number_of_plants;
		if (form_data.split_type === "NEW") {
			this.schema.required = ["split_type", "number_of_plants"];
		}
		if (form_data.split_type === "OFFSHOOT") {
			if (form_data.split_to_existing_batch_or_new === "New Batch") {
				this.schema.required = ["split_type", "number_of_plants"];
			} else {
				this.schema.required = [
					"split_type",
					"number_of_plants",
					"split_existing_batch_id",
				];
			}
		}
		if (
			form_data.split_type === "NEW" &&
			numberOfPlants1 &&
			numberOfPlants2 !== form_data.number_of_plants2
		) {
			if (numberOfPlants1 > Number(this.batch.plant_count)) {
				this.schema.properties.number_of_plants.warning =
					"Number of plants exceeds plants in batch";
				this.schema.properties.number_of_plants.warning_translation_key = marker(
					"form_field_warning_number_of_plants_exceeds_plants_in_batch",
				);
			} else if (numberOfPlants1 < 0) {
				this.schema.properties.number_of_plants.warning =
					"Number of plants below zero";
				this.schema.properties.number_of_plants.warning_translation_key = marker(
					"form_field_warning_number_of_plants_below_zero",
				);
			} else {
				this.model = {
					...this.model,
					number_of_plants2: Number(this.batch.plant_count) - numberOfPlants1,
				};
				form_data.number_of_plants2 = this.model.number_of_plants2;
			}
		}
	}

	updateBatch(update_batch: any, signing = false, password?: string) {
		const authObject = password
			? { username: this.user?.email ?? "", password }
			: undefined;

		this.loading$.next(true);
		this._itemService
			.update(
				`batch`,
				`${this.batch.id}/split_plants`,
				update_batch,
				signing
					? {
							...BatchDetailQuery,
							signing: "true",
						}
					: BatchDetailQuery,
				authObject,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "batches",
						}),
					);

					this._store
						.select(fromDashboard.getPlantPage)
						.pipe(
							takeUntil(this.destroyed$),
							isNotNullOrUndefined(),
							switchMap((page) => {
								return this._pagerService.fetchPage({
									...page,
									type: "batch",
									type_id: this.batch.id,
									result_type: "plants",
									page_type: "plants",
								});
							}),
						)
						.subscribe((updatedPage) => {
							this._store.dispatch(
								FetchPageActions.fetchPageSuccess({
									payload: updatedPage,
								}),
							);
						});

					this.loading$.next(false);
					this.closeSidenav();

					if (canCloseBatch(updatedItem, this._globals.general_inventory_enabled)) {
						this._store.dispatch(
							layoutActions.openSidenav({
								component: BatchCloseComponent,
							}),
						);
					}
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
