/* jscpd:ignore-start */
import { Injector, Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { IDestructionLot } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { handleObservableError } from "app/shared/utils";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { ReplaySubject, EMPTY } from "rxjs";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
/* jscpd:ignore-end */

@Component({
	selector: "grouped-destruction-event-delete",
	template: `
		<div class="ui grid" *transloco="let t">
			<div class="sixteen wide column">
				<div class="left floated left aligned eight wide column">
					<button class="ui labeled icon button" (click)="closeSidenav()">
						<i class="left arrow icon"></i>
						{{ "word_cancel" | transloco }}
					</button>
				</div>
			</div>
			<div class="sixteen wide column">
				<h2>
					{{
						form_title_translation_key ? t(form_title_translation_key) : form_title
					}}
				</h2>
			</div>

			<div class="sixteen wide column" *ngIf="error$ | async">
				<div class="ui error message">
					<span>{{ error$ | async }}</span>
				</div>
			</div>

			<div class="sixteen wide column">
				<button
					class="ui"
					[ngClass]="
						(loading$ | async) ? 'loading button disabled blue' : 'button blue'
					"
					(click)="onSubmit()"
				>
					<span
						><i *ngIf="submit_icon" class="{{ submit_icon }} icon"></i
						>{{
							submit_button_translation_key
								? t(submit_button_translation_key)
								: submit_button
						}}</span
					>
				</button>
			</div>
		</div>
	`,
	styleUrls: ["../sidenav.scss"],
})
export class GroupedDestructionEventDeleteComponent
	implements OnInit, OnDestroy
{
	event;
	destruction_lot: IDestructionLot;

	form_title = "Are you sure you want to delete this destruction event?";
	form_title_translation_key: string = marker(
		"form_title_are_you_sure_you_want_to_delete_this_destruction_event",
	);

	submit_button = "Yes";
	submit_button_translation_key: string = marker("word_yes");
	submit_icon = "bomb";

	error$: ReplaySubject<string> = new ReplaySubject();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	loading_error$: ReplaySubject<string> = new ReplaySubject<string>();
	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _injector: Injector,
		private readonly _itemService: ItemService,
	) {}

	ngOnInit() {
		this.event = this._injector.get("event", null);
		this.destruction_lot = this._injector.get("destruction_lot", null);
	}

	onSubmit() {
		this.deleteEvent();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}

	deleteEvent() {
		this.loading$.next(true);
		this._itemService
			.delete(
				`destruction_lots/${this.destruction_lot.id}/events/group`,
				this.event.group,
				{},
			)
			.pipe(
				takeUntil(this.destroyed$),
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
				tap((item) => {
					this._store.dispatch(
						SelectionActions.select({
							result_type: "destruction_lots",
							id: this.destruction_lot.id,
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
