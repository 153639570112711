/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, createActionGroup, props } from "@ngrx/store";

import { ItemKeyType } from "../reducers/selection/keys";

export interface AddSuccessProps {
	result_type: string;
	specific_field_id?: string;
	addedItem: {
		name?: string;
		[key: string]: any;
	};
}

export const ItemActions = createActionGroup({
	source: "Items",
	events: {
		"fetch success": props<{ result_type: string; result: any }>(),
		"fetch fail": props<{ result_type: string; error: string }>(),
		"update success": props<{ result_type: ItemKeyType; updatedItem: any }>(),
		"update fail": props<{ result_type: ItemKeyType; error: string }>(),
		"add success": props<AddSuccessProps>(),
		"add items success": props<{
			result_type: string;
			items: any[];
			specific_field_id?: string;
		}>(),
		"add items fail": props<{ result_type: ItemKeyType; error: string }>(),
	},
});

export const SelectRelatedItemsAction = createAction(
	"[Related Items Tab] Select",
	props<{ itemType: string; result_type: string }>(),
);
