import { IPrinterJobTemplate } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.PrinterJobTemplates;

export type State = SelectionState<IPrinterJobTemplate>;

export const reducer = createSelectionReducer<IPrinterJobTemplate>(reducerKey);
