import { IWorkOrder } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { take, takeUntil } from "rxjs/operators";
import { ReplaySubject } from "rxjs";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "work-order-activity",
	template: `
		<div
			class="ui grid dirty-mackenzie"
			*transloco="let t; read: 'sidenav_work_order_history'"
		>
			<div class="sixteen wide column">
				<div class="left floated left aligned eight wide column">
					<button class="ui labeled icon button" (click)="closeSidenav()">
						<i class="left arrow icon"></i>
						{{ "word_close" | transloco }}
					</button>
				</div>
			</div>
			<div class="sixteen wide column">
				<h2>{{ t("title") }}</h2>
			</div>

			<div class="sixteen wide column">
				<work-order-history
					[entityId]="work_order.id"
					[refTable]="ref_table"
					[entityName]="entity"
				></work-order-history>
			</div>
		</div>
	`,
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderActivityComponent implements OnInit, OnDestroy {
	work_order$ = this._store.select(fromDashboard.getSelectedWorkOrder);
	work_order: IWorkOrder;
	ref_table = "work_order";
	entity = "work order";
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
	) {}

	ngOnInit() {
		this.work_order$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((work_order) => {
				this.work_order = work_order;
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
