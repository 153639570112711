import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { Store } from "@ngrx/store";
import { ILocation } from "@elevatedsignals/amygoodman";
import { EMPTY, ReplaySubject } from "rxjs";
import { catchError, timeout, take, takeUntil, tap } from "rxjs/operators";
import { handleObservableError } from "app/shared/utils";
import { LocationDetailQuery } from "app/shared/eagers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";

@Component({
	selector: "location-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class LocationUpdateComponent implements OnInit {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	location$ = this._store.select(fromDashboard.getSelectedLocation);
	location: ILocation;

	form_title = "Update a Location";
	form_title_translation_key: string = marker("form_title_update_a_location");
	submit_button = "Update Location";
	submit_button_translation_key: string = marker("form_button_update_location");
	submit_icon = "edit";

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};
	form_disabled = false;

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				title: "id",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: Location name already in use.",
				warning_translation_key: marker(
					"form_field_warning_location_name_already_in_use",
				),
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
			location_type_ids: {
				type: "array",
				title: "Location Type(s)",
				title_translation_key: marker("form_field_label_location_type_s"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "location_types",
						},
					],
				},
			},
			is_sublocation: {
				type: "boolean",
				widget: "checkbox",
				title: "Is Sublocation",
				title_translation_key: marker("form_field_label_is_sublocation"),
			},
			parent_location_id: {
				type: "number",
				title: "Parent Location",
				title_translation_key: marker("form_field_label_parent_location"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "locations",
					},
				],
				visibleIf: {
					is_sublocation: [true],
				},
			},
			archived: {
				type: "boolean",
				title: "Archived",
				title_translation_key: marker("word_archived"),
				widget: "checkbox",
			},
			reason: {
				type: "string",
				title: "Update Reason",
				title_translation_key: marker("form_field_label_update_reason"),
				widget: "textarea",
			},
		},
		required: ["name"],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private names;

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
	) {}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		this.location$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((location) => {
				this.location = location;
				this.model = {
					...this.model,
					id: this.location.id,
					name: this.location.name,
					description: this.location.description,
					location_type_ids: this.location.location_types?.map((type) => type.id),
					is_sublocation: Boolean(this.location.parent_location),
					parent_location_id: this.location.parent_location
						? this.location.parent_location.id!
						: [],
					archived: this.location.archived,
				};
				this._cd.detectChanges();
			});

		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`locations/fetch/names`, "", {
				col: "name",
				except: this.location.name,
			})
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const location: Partial<Location> = {
			...this.model,
		};
		this.updateLocation(location);
	}

	onChanges(e): void {
		// empty
	}

	updateLocation(add_location: Partial<Location>) {
		this.loading$.next(true);
		this._itemService
			.update(
				`location`,
				Number(this.location.id),
				add_location,
				LocationDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "locations",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
