import { HeaderQueryType, ILocation, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "locations";

export type State = Page<ILocation>;

export const reducer = createPagingReducer<ILocation>(reducerKey, {
	result_type: "locations",
	title: "Location",
	title_translation_key: marker("page_title_locations"),
	title_plural: "Locations",
	title_plural_translation_key: marker("page_title_plural_locations"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Location Filter",
			key: "location_filter",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Active Locations",
					translationKey: marker("location_filter_active_locations"),
					value: "ACTIVE",
				},
				{
					label: "Show All Locations",
					translationKey: marker("location_filter_all_locations"),
					value: "ALL",
				},
				{
					label: "Show Archived Locations",
					translationKey: marker("location_filter_archived_locations"),
					value: "ARCHIVED",
				},
			],
			value: "ACTIVE",
		},
		{
			title: "Location Type",
			title_translation_key: marker("page_title_location_types"),
			key: "location_type_filter",
			type: HeaderQueryType.dataSelect,
			result_type: "location_types",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: false,
			search: false,
			value: null,
		},
		{
			title: "Sort by",
			key: "sortBy",
			title_translation_key: marker("location_filter_sort"),
			type: HeaderQueryType.select,
			options: [
				{
					label: "Alphabetical",
					translationKey: marker("location_filter_sort_alphabetical"),
					value: "alphabetical",
				},
				{
					label: "Creation Date",
					translationKey: marker("location_filter_sort_created_at"),
					value: "created_at",
				},
			],
			value: "created_at",
		},
	],
});
