import {
	HeaderQueryType,
	ISubstanceType,
	Page,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { SubstanceTypePageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "substance_types";

export type State = Page<ISubstanceType>;

// @deprecated
export const reducer = createPagingReducer<ISubstanceType>(reducerKey, {
	result_type: "substance_types",
	title: "Substance Type",
	title_translation_key: marker("page_title_substance_types"),
	title_plural_translation_key: marker("page_title_plural_substance_types"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Archived Filter",
			key: "archived_filter",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Show Active",
					translationKey: marker("substance_type_filter_active"),
					value: "ACTIVE",
				},
				{
					label: "Show All",
					translationKey: marker("substance_type_filter_all"),
					value: "ALL",
				},
				{
					label: "Show Archived",
					translationKey: marker("substance_type_filter_archived"),
					value: "ARCHIVED",
				},
			],
			value: "ACTIVE",
		},
	],
	query: SubstanceTypePageQuery,
});
