import { marker } from "@jsverse/transloco-keys-manager/marker";
import {
	Batch,
	HeaderQueryType,
	IHeaderQuery,
	Page,
	StatusType,
} from "@elevatedsignals/amygoodman";
import { BatchPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "batches";

export type State = Page<Batch>;

export const batchReducerBuilder = (generalInventory: boolean) => {
	const sharedHeaderQueries: IHeaderQuery[] = [
		{
			title: "Batch Filter",
			key: "batch_filter",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Active Batches",
					translationKey: marker("batch_filter_active_batches"),
					value: "ACTIVE",
				},
				{
					label: "Show All Batches",
					translationKey: marker("batch_filter_all_batches"),
					value: "ALL",
				},
				{
					label: "Show Archived Batches",
					translationKey: marker("batch_filter_archived_batches"),
					value: "ARCHIVED",
				},
				{
					label: "Show Empty Batches",
					translationKey: marker("batch_filter_empty_batches"),
					value: "EMPTY",
				},
			],
			value: "ACTIVE",
		},
		{
			title: "Cultivar",
			title_translation_key: marker("page_title_cultivars"),
			key: "cultivar_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "cultivars",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		},
		{
			title: "Growth Stages",
			title_translation_key: marker("page_title_plural_growth_stages"),
			key: "growth_stage_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "growth_stages",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: null,
			show_on: ["production_batches", "cultivation_batches"],
		},
		{
			title: "Production Stages",
			title_translation_key: marker("page_title_plural_production_stages"),
			key: "production_stage_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "production_stages",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: null,
			show_on: ["production_batches", "cultivation_batches"],
		},
		{
			title: "Locations",
			title_translation_key: marker("page_title_plural_locations"),
			key: "location_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "locations",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		},
		{
			title: "Batch Type",
			title_translation_key: marker("page_title_batch_types"),
			key: "batch_type_filter",
			type: HeaderQueryType.dataSelect,
			result_type: "batch_types",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: null,
		},
		{
			title: "Status",
			title_translation_key: marker("page_title_status"),
			key: "status_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "statuses",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: null,
			query_string: {
				status_type: StatusType.Batch,
			},
		},
		{
			title: "Tags",
			title_translation_key: marker("page_title_plural_tags"),
			key: "tag_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "tags",
			value_key: "id",
			text_key: ["name"],
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		},
		{
			title: "Start",
			title_translation_key: marker("word_start"),
			key: "start_date",
			type: HeaderQueryType.date,
			value: undefined,
		},
		{
			title: "End",
			title_translation_key: marker("word_end"),
			key: "end_date",
			type: HeaderQueryType.date,
			value: undefined,
		},
		{
			title: "Batches in open work orders",
			title_translation_key: marker("checkbox_filter_batches_in_open_work_orders"),
			key: "open_work_orders",
			type: HeaderQueryType.checkbox,
			value: false,
		},
		{
			title: "Order By",
			title_translation_key: marker("batch_filter_sort"),
			key: "orderBy",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Created At Date",
					translationKey: marker("batch_filter_sort_created"),
					value: "created_at",
				},
				{
					label: "# of Plants",
					translationKey: marker("batch_filter_sort_plants"),
					value: "plant_count",
				},
				{
					label: "Alphabetical",
					translationKey: marker("batch_filter_sort_alphabetical"),
					value: "alphabetical",
				},
			],
			value: "",
		},
	];

	if (generalInventory) {
		sharedHeaderQueries.unshift({
			title: "Inventory Products",
			title_translation_key: marker("page_title_plural_inventory_products"),
			key: "inventory_product_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "inventory_products",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: [],
			show_on: ["production_batches"],
		});
	} // @deprecated else
	else {
		sharedHeaderQueries.unshift({
			title: "Substance Types",
			title_translation_key: marker("page_title_plural_substance_types"),
			key: "substance_type_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "substance_types",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: [],
			show_on: ["production_batches"],
		});
	}

	const key = generalInventory ? "batch_gi" : "batch_non_gi";

	return createPagingReducer<Batch>(key, {
		result_type: "batches",
		paging_key: key,
		title: "Batches",
		title_translation_key: marker("page_title_batches"),
		title_plural_translation_key: marker("page_title_plural_batches"),
		results: [],
		page: 1,
		page_size: 50,
		total: 0,
		header_queries: sharedHeaderQueries,
		query: BatchPageQuery,
	});
};
