/* jscpd:ignore-start */
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
	SimpleChanges,
	SimpleChange,
} from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { DestructionLotDetailQuery } from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";
import {
	fetchNewInventoryTotals,
	getDynamicFormChanges,
	hasInventoryIdsChanged,
	hasPropertyChanged,
	onVendorChange,
} from "../shared";

import {
	InventoryDestructionSchema,
	InventoryDestruction,
} from "./schemas/inventory-destruction-create";

@Component({
	selector: "inventory-destruction-by-location",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class InventoryDestructionByLocationComponent
	extends GenericCreateComponent<InventoryDestruction>
	implements OnInit, OnDestroy
{
	schema: any = InventoryDestructionSchema;

	private prevInventoryProductId: number | undefined;
	private readonly whatChanged: SimpleChanges = {};
	private readonly inventory_id: number;
	private readonly vendor_id: number;
	private destruction_lot_id: number;
	amount_valid: boolean;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
		private readonly _translocoService: TranslocoService,
		private readonly _injector: Injector,
	) {
		super(_store);
		this.form_title = "Inventory Destruction By Location";
		this.form_title_translation_key = marker(
			"form_title_inventory_destruction_by_location",
		);
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
		this.submit_icon = "plus";
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.destruction_lot_id = this._injector.get("destruction_lot_id", null);
		this.schema.properties.existing_inventory.hidden = false;
		if (this.destruction_lot_id) {
			this.model.destruction_lot_id = this.destruction_lot_id;
			this.schema.properties.destruction_lot_id.readOnly = true;
		}

		if (this.schema.properties.batch_id) {
			this.schema.properties.batch_id.hidden = true;
		}

		this.schema.properties.location_id.hidden = false;
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		getDynamicFormChanges(this.whatChanged, model, true);
		this.autoSelectForm();
		this.checkAndUpdateInventoryTotals();

		if (!model.inventory_product_id && this.prevInventoryProductId) {
			this.prevInventoryProductId = undefined;
			model.inventory_unit_id = undefined;
		} else if (
			model.inventory_product_id &&
			this.prevInventoryProductId !== model.inventory_product_id
		) {
			this.prevInventoryProductId = model.inventory_product_id;
			model.inventory_unit_id = undefined;
			const firstProductSet = !this.model.inventory_product_id;
			if (this.schema.properties.existing_inventory && !this.inventory_id) {
				model.existing_inventory =
					!this._globals.cultivationInventoryProducts.includes(
						model.inventory_product_id,
					);
			}
			this._itemService
				.fetchItem(`inventory_product`, `${model.inventory_product_id}`, {
					eager: "[vendors]",
				})
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((inventory_product) => {
					if (!firstProductSet || !this.model.inventory_unit_id) {
						this.model.inventory_unit_id = inventory_product.display_unit_id;
					}
					if (this.schema.properties.existing_inventory && !this.inventory_id) {
						this.model.existing_inventory = !(
							this._globals.cultivationInventoryProducts.includes(
								model.inventory_product_id,
							) || inventory_product.non_inventory_destruction
						);
					}

					this.schema.properties.sku_id.hidden = !inventory_product.require_sku;

					if (!this.vendor_id) {
						[this.model, this.schema] = onVendorChange(
							this.model,
							this.schema,
							inventory_product,
						);
					}

					this._cd.detectChanges();
				});
		}
	}

	autoSelectForm = (): void => {
		let requiresDetectChanges = false;
		if (this.itemChanged(this.whatChanged.location)) {
			requiresDetectChanges = this.loocationchanged();
		} else if (this.itemChanged(this.whatChanged.lot_id)) {
			requiresDetectChanges = this.lotChanged();
		}

		if (requiresDetectChanges) {
			this._cd.detectChanges();
		}
	};

	itemChanged = (change: SimpleChange | undefined): boolean => {
		if (change?.currentValue !== change?.previousValue) {
			return true;
		}
		return false;
	};

	loocationchanged = () => {
		// if (this.whatChanged.batch_id!.currentValue) {
		// 	// Here we should be setting inventory_ids to [], lot_id to null. But, infinite loops.
		// }

		return false;
	};

	lotChanged = () => {
		if (this.whatChanged.lot_id!.currentValue) {
			// Here we should be setting inventory_ids to []. But, infinite loops.
			return true;
		}
		return false;
	};

	checkAndUpdateInventoryTotals = () => {
		if (
			hasPropertyChanged(this.whatChanged.inventory_product_id) ||
			hasPropertyChanged(this.whatChanged.lot_id) ||
			hasPropertyChanged(this.whatChanged.location_id) ||
			hasInventoryIdsChanged(this.whatChanged.inventory_ids) ||
			hasPropertyChanged(this.whatChanged.timestamp) ||
			hasPropertyChanged(this.whatChanged.vendor_id) ||
			hasPropertyChanged(this.whatChanged.sku_id)
		) {
			fetchNewInventoryTotals(this.whatChanged, this._itemService).subscribe(
				(availableInventoryAmount) => {
					this.model.amount_available = availableInventoryAmount.content
						.map((availableInventory) => {
							return `${availableInventory.sum.toFixed(2)} ${availableInventory.name}`;
						})
						.join("\n");

					this.amount_valid = availableInventoryAmount.content.length > 0;

					this._cd.detectChanges();
				},
			);
		}
	};

	createItem(destruction_event: InventoryDestruction) {
		const item = {
			...destruction_event,
			vendor_id: this.model.vendor_id,
			result_type: "destruction_lot",
		};

		if (
			!destruction_event.remaining_inventory &&
			(!destruction_event.quantity || destruction_event.quantity <= 0)
		) {
			this.error$.next(
				this._translocoService.translate(
					"error_inventory_destruction_must_include_positive_quantity",
				),
			);
			return;
		}

		this.loading$.next(true);
		this._itemService
			.add(
				`destruction_lots/${destruction_event.destruction_lot_id}/inventory`,
				item,
				DestructionLotDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(50000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((_item) => {
					this._store.dispatch(
						SelectionActions.select({
							result_type: "destruction_lots",
							id: this.model.destruction_lot_id,
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
/* jscpd:ignore-end */
