import { Action, on, createReducer } from "@ngrx/store";

import { AuthActions } from "../actions/auth.actions";

export interface State {
	error: string | null;
	pending: boolean;
	reset_success: boolean;
	verify_success: boolean;
	verify_error: string | null;
	verify_pending: boolean;
}

const initialState: State = {
	error: null,
	pending: false,
	reset_success: false,
	verify_success: false,
	verify_error: null,
	verify_pending: false,
};

const passwordResetReducer = createReducer(
	initialState,
	on(AuthActions.navigationSuccess, (state) => {
		return {
			...state,
			pending: false,
		};
	}),
	on(AuthActions.resetPassword, (state) => {
		return {
			...state,
			error: null,
			pending: true,
		};
	}),
	on(AuthActions.resetPasswordSuccess, (state) => {
		return {
			...state,
			reset_success: true,
			error: null,
			pending: false,
		};
	}),
	on(AuthActions.resetPasswordFail, (state, { error }) => {
		return {
			...state,
			reset_success: false,
			error,
			pending: false,
		};
	}),
	on(AuthActions.verifyResetToken, (state) => {
		return {
			...state,
			verify_error: null,
			verify_pending: true,
		};
	}),
	on(AuthActions.verifyResetTokenSuccess, (state) => {
		return {
			...state,
			verify_pending: false,
			verify_error: null,
			verify_success: true,
		};
	}),
	on(AuthActions.verifyResetTokenFail, (state, { error }) => {
		return {
			...state,
			verify_success: false,
			verify_error: error,
			verify_pending: false,
		};
	}),
);

export const reducer = (state: State | undefined, action: Action) =>
	passwordResetReducer(state, action);

export const getPending = (state: State) => state.pending;
export const getError = (state: State) => state.error;

export const getResetSuccess = (state: State) => state.reset_success;
export const getVerifySuccess = (state: State) => state.verify_success;
export const getVerifyPending = (state: State) => state.verify_pending;
export const getVerifyError = (state: State) => state.verify_error;
