/* eslint-disable @typescript-eslint/prefer-readonly */
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY, Observable } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	Injector,
	OnInit,
	OnDestroy,
} from "@angular/core";
import { WorkOrderService } from "app/modules/dashboard/services/work-order.service";
import { Globals } from "app/shared/modules/globals/globals.service";
import { IWorkOrder, IWorkOrderOutput } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";

import { GenericUpdateComponent } from "../generic/generic-update.component";
import * as fromDashboard from "../../../reducers";

@Component({
	selector: "work-order-edit-output",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderOutputEditComponent
	extends GenericUpdateComponent<any>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			value: {
				type: "number",
				title: "New Value",
				title_translation_key: marker("form_field_label_new_value"),
				widget: "number",
				step: 0.01,
				min: 0.01,
			},
			note: {
				type: "string",
				widget: "textarea",
				title: "Note",
				title_translation_key: marker("word_note"),
			},
		},
		required: ["value"],
	};

	private work_order: IWorkOrder;
	private work_order_output: IWorkOrderOutput;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _workOrderService: WorkOrderService,
		private readonly _injector: Injector,
		private readonly _globals: Globals,
	) {
		super(_store, _cd);
		if (this._globals.gmp_enabled) {
			this.schema.required = ["value", "note"];
		}

		this.form_title = "Edit Work Order Output Amount";
		this.form_title_translation_key = marker("form_title_edit_work_order_output");
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
		this.submit_icon = "plus";

		this.work_order_output = this._injector.get("item", null);
		this.work_order = this._injector.get("work_order", null);
		const currentValue =
			this.work_order_output.inventory.measurements?.[0]?.value ?? 0;
		this.model.value = Math.abs(currentValue);
	}

	ngOnInit() {}

	onChanges(_model) {}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem() {
		this.loading$.next(true);
		this._workOrderService
			.editOutput({
				work_order_id: this.work_order.id,
				output_id: this.work_order_output.id,
				note: this.model.note,
				value: this.model.value,
			})
			.pipe(
				timeout(10000),
				catchError((error) => {
					// console.log(e);
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
