import { HttpErrorResponse } from "@angular/common/http";
import { inject, Inject } from "@angular/core";
import { Observable, throwError as observableThrowError } from "rxjs";
import { TranslocoService } from "@jsverse/transloco";

import { NotFoundError } from "../errors";
import { catchError } from "rxjs/operators";

@Inject({
	providedIn: "root",
})
export class TranslateErrorService {
	private translocoService = inject(TranslocoService);

	catchAndTranslateError<T>(errorKey: string) {
		return (source$: Observable<T>) =>
			source$.pipe(
				catchError((error: HttpErrorResponse) => {
          return this.handleError(error, errorKey);
				}),
			);
	}

	/*
  This function receives an response from an http error and produces a
  Observable error containing an Error Object.
*/
	private handleError = (error: HttpErrorResponse, actionFailed = "") => {
		// console.log("Error Status ", error);
		let body = {
			human_message: null,
			correlation_id: null,
		};

		let actionString = actionFailed;
		// Translate if actionFailed is a key
		if (this.hasTranslation(actionFailed)) {
			actionString = this.translocoService.translate(actionFailed);
		}

		try {
			body = error.error;
			// body = json;
		} catch (err) {
			// Failed to parse json;
		}

		switch (error.status) {
			case 0:
				return observableThrowError(() => {
					return (
						body.human_message ||
						`${actionString} ${this.translate("error_unable_to_contact_server")}`
					);
				});

			case 400:
				return observableThrowError(() => {
					return (
						body.human_message ||
						this.translate(
							"error_unable_to_process_bad_request_please_contact_us_if_this_persists",
						)
					);
				});

			case 401:
				return observableThrowError(
					() =>
						body.human_message ||
						`${actionString} ${this.translate("word_unauthorized")}`,
				);
			case 403:
				return observableThrowError(() => {
					const baseMessage =
						body.human_message ||
						`${actionString} ${this.translate("error_unknown_error_occurred")}`;

					return `${baseMessage}, ${this.translate(
						"error_please_contact_us_if_this_error_cannot_be_solved",
					)}`;
				});
      case 404:
        if (body.human_message) {
          return observableThrowError(() => body.human_message);
        } else {
          return observableThrowError(() => new NotFoundError());
        }
			case 409:
				return observableThrowError(
					() =>
						body.human_message ||
						`${actionString} ${this.translate("error_conflict_detected")}`,
				);

			case 500:
				return observableThrowError(() => {
					const baseMessage =
						body.human_message || this.translate("error_server_error_occurred");

					return (
						`${baseMessage}, ${this.translate(
							"error_please_contact_us_if_this_persists",
						)}` +
						(body.correlation_id
							? `

			(Include this error code: ${body.correlation_id})`
							: "")
					);
				});

			default: {
				return observableThrowError(() => {
					const baseMessage =
						body.human_message ||
						`${actionString} ${this.translate("error_unknown_error_occurred")}`;

					return (
						`${baseMessage}, ${this.translate(
							"error_please_contact_us_if_this_persists",
						)}` +
						(body.correlation_id
							? `

      (Include this error code: ${body.correlation_id})`
							: "")
					);
				});
			}
		}
	};

	private hasTranslation = (key: string): boolean => {
		const translation = this.translate(key);

		return translation !== key && translation !== "";
	};

	private translate = (key: string): string => {
		return this.translocoService.translate(key);
	};
}
