import { Pipe, PipeTransform } from "@angular/core";
import {
	MeasureUnit,
	unitToBaseMeasureUnit,
} from "@elevatedsignals/amygoodman";

import { UnitToBaseMeasureUnitPipe } from "./units/unit_to_base_measure_unit.pipe";

@Pipe({
	name: "unitToGramsPipe",
})
export class UnitToGramsPipe implements PipeTransform {
	transform(weight_in_unit: number, units: string): number {
		if (isNaN(weight_in_unit)) {
			return weight_in_unit;
		}
		return Number(unitToBaseMeasureUnit(weight_in_unit, units, MeasureUnit.Mass));
	}
}
