import { tap, takeUntil, timeout, catchError } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { TranslocoService } from "@jsverse/transloco";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { EMPTY } from "rxjs";
import {
	ChangeDetectorRef,
	Component,
	Injector,
	OnDestroy,
	OnInit,
} from "@angular/core";
import { StatusType } from "@elevatedsignals/amygoodman";
import {
	BatchDetailQuery,
	InventoryDetailQuery,
	LotDetailQuery,
	PurchaseOrderDetailQuery,
	VendorDetailQuery,
	WorkOrderDetailQuery,
} from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { startCase } from "app/modules/dashboard/vendor/lodash";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GI_STATUS_TYPES, NON_GI_STATUS_TYPES } from "./constants";

interface StatusChange {
	id: number;
	status_type: string;
	status_id: number;
	lot_id: number;
}

@Component({
	selector: "status-set",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class SetStatusComponent
	extends GenericUpdateComponent<StatusChange>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			lot_id: {
				type: "number",
				title: "Lot",
				widget: "data-select",
				oneOf: [{ result_type: "lots" }],
				quick_create: false,
				readOnly: true,
			},
			status_type: {
				type: "string",
				title: "Status Type",
				title_translation_key: marker("form_field_label_status_types"),
				widget: "select",
				oneOf: [],
			},
			status_id: {
				type: "number",
				title: "Status",
				title_translation_key: marker("word_status"),
				widget: "data-select",
				related_properties: ["status_type"],
				oneOf: [
					{
						result_type: "statuses",
						text_key: ["name"],
					},
				],
			},
			reason: {
				type: "string",
				title: "Reason",
				title_translation_key: marker("word_reason"),
				widget: "textarea",
			},
		},
		required: ["id", "status_type", "status_id"],
	};

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
		private readonly _globals: Globals,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store, _cd);
		this.form_title = "Set Status";
		this.form_title_translation_key = marker("form_title_set_status");
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		const statusTypes = this._globals.general_inventory_enabled
			? GI_STATUS_TYPES
			: NON_GI_STATUS_TYPES;
		statusTypes.map((type) => {
			this.schema.properties.status_type.oneOf.push({
				// converts snake case to start case
				name: startCase(type.name.toLowerCase()),
				name_translation_key: type.name_translation_key,
				value: type.name,
				enum: [type.name],
			});
		});

		this.model.id = this._injector.get("id", undefined);
		this.model.status_type = this._injector.get("status_type", null);
		this.model.status_id = this._injector.get("status_id", null);
		this.model.lot_id = this._injector.get("lot_id", null);

		if (this.model.lot_id) {
			this.schema.required.push("lot_id");
		} else {
			delete this.schema.properties.lot_id;
		}

		this.schema.properties.status_id.default_values = {
			status_type: this.model.status_type,
		};

		if (
			this.model.status_type === StatusType.Batch &&
			this._globals.gmp_enabled
		) {
			this.schema.required.push("reason");
		}

		// Once readonly is set the form will not update the value of status_type so we have to do that first
		this.schema.properties.status_type.readOnly = true;
		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(statusChange: Partial<StatusChange>) {
		this.loading$.next(true);
		this.error$.next("");
		let result_type:
			| "vendors"
			| "purchase_orders"
			| "work_orders"
			| "lots"
			| "inventories"
			| "batches"
			| "records";
		let eagerQuery: Record<string, string | string[]> = {};

		switch (statusChange.status_type) {
			case StatusType.Vendor:
				result_type = "vendors";
				eagerQuery = VendorDetailQuery;
				break;
			case StatusType.PurchaseOrder:
				result_type = "purchase_orders";
				eagerQuery = PurchaseOrderDetailQuery;
				break;
			case StatusType.WorkOrder:
				result_type = "work_orders";
				eagerQuery = WorkOrderDetailQuery;
				break;
			case StatusType.Record:
				result_type = "records";
				eagerQuery = {};
				break;
			case StatusType.Lot:
				result_type = "lots";
				eagerQuery = LotDetailQuery;
				break;
			case StatusType.Inventory:
				if (statusChange.lot_id) {
					result_type = "lots";
					eagerQuery = LotDetailQuery;
				} else {
					result_type = "inventories";
					eagerQuery = InventoryDetailQuery;
				}
				break;
			case StatusType.Batch:
				result_type = "batches";
				eagerQuery = BatchDetailQuery;
				break;
			default:
				this.loading$.next(false);
				this.error$.next(
					this._translocoService.translate(
						"error_invalid_status_type_unable_to_complete_transaction",
						{ status_type: statusChange.status_type },
					),
				);
		}

		let item_type: string;
		if (
			statusChange.lot_id &&
			statusChange.status_type === StatusType.Inventory
		) {
			item_type = `/inventories/lot/${statusChange.lot_id}/status`;
		} else {
			item_type = `${statusChange.status_type?.toLowerCase()}/${
				statusChange.id
			}/status`;
		}
		this._itemService
			.update(item_type, statusChange.status_id!, statusChange, eagerQuery)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type,
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
