import { tap, take, takeUntil, timeout, catchError } from "rxjs/operators";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { Store } from "@ngrx/store";
import { handleObservableError } from "app/shared/utils";
import { CultivarDetailQuery } from "app/shared/eagers";
import { ReplaySubject, EMPTY } from "rxjs";
import { Cultivar } from "@elevatedsignals/amygoodman";
import { Globals } from "app/shared/modules/globals/globals.service";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

import { UnitToGramsPipe } from "../../../modules/es-pipes/unit-to-grams.pipe";
import { GramsToUnitPipe } from "../../../modules/es-pipes/grams-to-unit.pipe";

@Component({
	selector: "cultivar-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class CultivarUpdateComponent implements OnInit {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	cultivar$ = this._store.select(fromDashboard.getSelectedCultivar);
	cultivar: Cultivar;

	form_title = "Update a Cultivar";
	form_title_translation_key: string = marker("form_title_update_a_cultivar");
	submit_button = "Update Cultivar";
	submit_button_translation_key: string = marker("form_button_update_cultivar");
	submit_icon = "edit";

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};
	form_disabled = false;
	weight_unit: string = this._globals.weight_unit;

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				title: "id",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: Cultivar name already in use.",
				warning_translation_key: marker(
					"form_field_warning_cultivar_name_already_in_use",
				),
			},
			predicted_yield: {
				type: "number",
				title: `Predicted Yield Per Plant(${this.weight_unit})`,
				// title_translation_key has param of weight_unit
				title_translation_key: marker("form_field_label_predicted_yield_per_plant"),
				title_translation_params: { weight_unit: this.weight_unit },
			},
			reason: {
				type: "string",
				title: "Update Reason",
				title_translation_key: marker("form_field_label_update_reason"),
				widget: "textarea",
			},
		},
		required: ["name"],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private names;

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _unitToGrams: UnitToGramsPipe,
		private readonly _gramsToUnit: GramsToUnitPipe,
		private readonly _globals: Globals,
	) {}

	valid(v) {
		this.valid$.next(v);
	}

	ngOnInit() {
		this.cultivar$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((cultivar) => {
				this.cultivar = cultivar;
				this.model = {
					...this.model,
					id: this.cultivar.id,
					name: this.cultivar.name,
					predicted_yield: this.cultivar.predicted_yield
						? Number(
								this._gramsToUnit.transform(
									this.cultivar.predicted_yield,
									this.weight_unit,
								),
							)
						: undefined,
				};
				this._cd.detectChanges();
			});

		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`cultivars/fetch/names`, "", {
				col: "name",
				except: this.cultivar.name,
			})
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const cultivar: Partial<Cultivar> = {
			...this.model,
			predicted_yield: this.model.predicted_yield
				? this._unitToGrams.transform(this.model.predicted_yield, this.weight_unit)
				: undefined,
		};
		this.updateCultivar(cultivar);
	}

	onChanges(e): void {
		// empty
	}

	updateCultivar(add_cultivar: Partial<Cultivar>) {
		this.loading$.next(true);
		this._itemService
			.update(`cultivar`, this.cultivar.id, add_cultivar, CultivarDetailQuery)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "cultivars",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
