import { Big } from "big.js";
import { round, MeasureUnit } from "../measurement";
import { NumDecimalPlaces } from "../measurement/utils";
import { isBefore } from "./date";
export const destructionEventsToGroupedEvents = (destruction_events = [], giEnabled = false) => {
  var _a, _b, _c, _d, _e, _f, _g;
  const groupedEvents = {};
  for (const event of destruction_events) {
    // TODO pull not migrated with marking it as error
    // skip non-inventory, non-plants destruction for GI
    if (giEnabled && !event.inventory && !event.plant_id) {
      continue;
    }
    // skip inventory destruction for non-GI
    if (!giEnabled && event.inventory && !event.measure_event) {
      continue;
    }
    let groupedEvent = groupedEvents[event.group];
    if (!groupedEvent) {
      groupedEvent = {
        _id: event.group,
        event_date: event.event_date,
        created_at: event.created_at,
        total_values: {
          [MeasureUnit.Mass]: 0,
          [MeasureUnit.Quantity]: 0,
          [MeasureUnit.Volume]: 0
        },
        events: [],
        batches: [],
        inventories: [],
        plants: [],
        users: [],
        reasons: [],
        substance_types: [],
        notes: []
      };
    }
    groupedEvent.events.push(event);
    if (event.destruction_reason) {
      groupedEvent.reasons.push(event.destruction_reason.name);
    }
    if (event.reason) {
      groupedEvent.reasons.push(event.reason);
    }
    if (event.measure_event && !giEnabled) {
      groupedEvent.total_values[event.measure_event.measure_unit] = new Big(groupedEvent.total_values[event.measure_event.measure_unit]).plus(event.measure_event.value).toNumber();
      if (event.measure_event.substance_type) {
        groupedEvent.substance_types.push(event.measure_event.substance_type.name);
      }
      if (event.measure_event.notes && event.measure_event.notes.length > 0) {
        if ((_a = event.measure_event.notes[0]) === null || _a === void 0 ? void 0 : _a.subject) {
          groupedEvent.reasons.push(event.measure_event.notes[0].subject);
        }
        for (const note of event.measure_event.notes) {
          if (note.content) {
            groupedEvent.notes.push(note.content);
          }
        }
      }
    } else if (event.inventory && giEnabled) {
      groupedEvent.inventories.push(event.inventory);
      if (event.inventory.measurements && event.inventory.measurements.length > 0) {
        const unit = (_b = event.inventory.inventory_unit) === null || _b === void 0 ? void 0 : _b.name;
        groupedEvent.substance_types.push((_c = event.inventory.inventory_product) === null || _c === void 0 ? void 0 : _c.name);
        for (const measurement of event.inventory.measurements) {
          groupedEvent.total_values[unit] = new Big((_d = groupedEvent.total_values[unit]) !== null && _d !== void 0 ? _d : 0).plus(measurement.value).toNumber();
          if (measurement.notes && measurement.notes.length > 0) {
            if ((_e = measurement.notes[0]) === null || _e === void 0 ? void 0 : _e.subject) {
              groupedEvent.reasons.push(measurement.notes[0].subject);
            }
            for (const note of measurement.notes) {
              if (note.content) {
                groupedEvent.notes.push(note.content);
              }
            }
          }
        }
      }
    } else if (event.weight) {
      groupedEvent.total_values[MeasureUnit.Mass] = new Big(groupedEvent.total_values[MeasureUnit.Mass]).plus(event.weight).toNumber();
      if (event.substance_type) {
        groupedEvent.substance_types.push(event.substance_type);
      }
      if (event.reason) {
        groupedEvent.reasons.push(event.reason);
      }
      if (event.notes && event.notes.length > 0) {
        const subject = (_f = event.notes[0]) === null || _f === void 0 ? void 0 : _f.subject;
        if (subject) {
          groupedEvent.reasons.push(subject);
        }
        for (const note of event.notes) {
          if (note.content) {
            groupedEvent.notes.push(note.content);
          }
        }
      }
    }
    if (event.work_order) {
      groupedEvent.work_order = event.work_order;
    }
    let userExists = false;
    if (groupedEvent.users.length > 0) {
      for (const user of groupedEvent.users) {
        if (user.email === ((_g = event.user) === null || _g === void 0 ? void 0 : _g.email)) {
          userExists = true;
        }
      }
    }
    if (!userExists && event.user) {
      groupedEvent.users.push(event.user);
    }
    // Add batch optionally
    if (event.batch) {
      let hasBatch = false;
      const batches = groupedEvent.batches;
      for (const batch of batches) {
        if (batch.id === event.batch.id) {
          hasBatch = true;
        }
      }
      if (!hasBatch) {
        groupedEvent.batches.push(event.batch);
      }
    }
    // Add plant optionally
    if (event.plant) {
      let hasPlant = false;
      const plants = groupedEvent.plants;
      for (const plant of plants) {
        if (plant.id === event.plant.id) {
          hasPlant = true;
        }
      }
      if (!hasPlant) {
        groupedEvent.plants.push(event.plant);
      }
    }
    if (event.location && !groupedEvent.location) {
      groupedEvent.location = event.location;
    }
    groupedEvents[event.group] = groupedEvent;
  }
  const groupedDestructionEvents = Object.values(groupedEvents).sort((left, right) => {
    const aEvent = left.events.length > 0 ? left.events[0] : undefined;
    const bEvent = right.events.length > 0 ? right.events[0] : undefined;
    if (!aEvent || !bEvent || isBefore(new Date(aEvent.event_date), new Date(bEvent.event_date))) {
      // a comes before b
      return -1;
    }
    return 1;
  });
  // Round all weight values
  for (const event of groupedDestructionEvents) {
    if (event.total_values[MeasureUnit.Mass]) {
      event.total_values[MeasureUnit.Mass] = round(event.total_values[MeasureUnit.Mass], NumDecimalPlaces);
    }
    // Removing duplicates
    event.reasons = Array.from(new Set(event.reasons));
    event.substance_types = Array.from(new Set(event.substance_types));
    event.notes = Array.from(new Set(event.notes));
  }
  return groupedDestructionEvents;
};
