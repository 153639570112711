import {
	HeaderQueryType,
	IWorkOrderType,
	Page,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { WorkOrderTypePageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "work_order_types";

export type State = Page<IWorkOrderType>;

export const reducer = createPagingReducer<IWorkOrderType>(reducerKey, {
	result_type: "work_order_types",
	title: "Work Order Type",
	title_translation_key: marker("page_title_work_order_types"),
	title_plural_translation_key: marker("page_title_plural_work_order_types"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Archived Filter",
			key: "archived_filter",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Show Active",
					translationKey: marker("work_order_type_filter_active"),
					value: "ACTIVE",
				},
				{
					label: "Show All",
					translationKey: marker("work_order_type_filter_all"),
					value: "ALL",
				},
				{
					label: "Show Archived",
					translationKey: marker("work_order_type_filter_archived"),
					value: "ARCHIVED",
				},
			],
			value: "ACTIVE",
		},
	],
	query: WorkOrderTypePageQuery,
});
