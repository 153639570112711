import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	OnDestroy,
	ChangeDetectorRef,
	Injector,
	OnInit,
} from "@angular/core";
import { PurchaseOrderDetailQuery } from "app/shared/eagers";
import { IInventory } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import {
	CurrencySelect,
	NumberInput,
} from "app/modules/dashboard/modules/es-forms/schemas/fields/costing";
import { EsWidgetOneOfProperty } from "app/modules/dashboard/modules/es-forms/widgets/es-widget-schema.model";
import { getSelectedFacilitySettings } from "app/modules/dashboard/selectors/facility-settings.selector";

@Component({
	selector: "purchase-order-add-other-fee",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PurchaseOrderAddOtherFeeComponent
	extends GenericUpdateComponent<IInventory>
	implements OnDestroy, OnInit
{
	static itemTypes = ["Fee", "Discount"];
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			purchase_order_id: {
				type: "number",
				title: "Purchase Order",
				hidden: true,
			},
			type: {
				type: "string",
				title: "Type",
				widget: "select",
				oneOf: PurchaseOrderAddOtherFeeComponent.itemTypes.map((key) => ({
					name: key,
					value: key,
					enum: [key],
				})) as EsWidgetOneOfProperty[],
				default: "Fee",
			},
			name: {
				type: "string",
				title: "Name",
			},
			currency: { ...CurrencySelect, hidden: true, widget: undefined },
			amount: { title: "Amount", ...NumberInput },
		},
		required: ["purchase_order_id", "name", "amount"],
	};

	facilitySettings$ = this._store.select(getSelectedFacilitySettings);

	private purchase_order_id: number;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.form_title = "Add Fee or Discount";
		this.form_title_translation_key = marker("form_title_add_fee_or_discount");
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("form_button_add");
	}

	ngOnInit() {
		this.purchase_order_id = this._injector.get("purchase_order_id", undefined);
		this.model.purchase_order_id = this.purchase_order_id;
		this.facilitySettings$.subscribe((facilitySettings) => {
			this.model.currency =
				facilitySettings.data?.options?.facility_currency ?? "CAD";
		});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(update) {
		this.loading$.next(true);
		const { type, ...data } = update;
		this._itemService
			.add(
				`purchase_order/${this.purchase_order_id}/other_fee`,
				{
					...data,
					expense: type === "Fee",
				},
				PurchaseOrderDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "purchase_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
