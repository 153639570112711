import { Store } from "@ngrx/store";
import { timeout, catchError, take, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnDestroy,
	OnInit,
	Injector,
} from "@angular/core";
import { IPrinterJobTemplate } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { Globals } from "app/shared/modules/globals/globals.service";
import { PrinterJobTemplateDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

@Component({
	selector: "printer-job-template-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PrinterJobTemplateUpdateComponent
	extends GenericUpdateComponent<IPrinterJobTemplate>
	implements OnDestroy, OnInit
{
	// Base list of options, applicable to both substance types and GI
	entityOptions: { name: string; value: string; t_key: string }[] = [
		{ name: "Batch", value: "batch", t_key: marker("word_batch") },
		{ name: "Plant", value: "plant", t_key: marker("word_plant") },
		{ name: "Location", value: "location", t_key: marker("word_location") },
		{ name: "Work Order", value: "work_order", t_key: marker("word_work_order") },
		{
			name: "Work Order Output",
			value: "work_order_output",
			t_key: marker("word_work_order_output"),
		},
		{
			name: "Destruction Lot",
			value: "destruction_lot",
			t_key: marker("word_destruction_lot"),
		},
		{
			name: "Destruction Event",
			value: "destruction_event",
			t_key: marker("word_destruction_event"),
		},
	];

	// Extra options that we can support for GI facilities
	additionalGiOptions: { name: string; value: string; t_key: string }[] = [
		{ name: "Lot", value: "lot", t_key: marker("word_lot") },
		{
			name: "Inventory",
			value: "inventory",
			t_key: marker("word_inventory"),
		},
	];

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			label_width: {
				type: "number",
				title: "Label Width (inches)",
				title_translation_key: marker("form_field_label_label_width_inches"),
			},
			label_height: {
				type: "number",
				title: "Label Height (inches)",
				title_translation_key: marker("form_field_label_label_height_inches"),
			},
			template: {
				type: "string",
				title: "Template",
				title_translation_key: marker("word_template"),
			},
			label_dpi: {
				type: "number",
				title: "Label DPI",
				title_translation_key: marker("form_field_label_label_dpi"),
			},
			entity_type: {
				type: "string",
				title: "Entity Type",
				title_translation_key: marker("form_field_label_entity_type"),
				widget: "select",
				oneOf: this.entityOptions.map((item) => {
					return {
						name: item.name,
						name_translation_key: item.t_key,
						value: item.value,
						enum: [item.value],
					};
				}),
				readOnly: false,
			},
			date_format: {
				type: "string",
				title: "Date Format",
				title_translation_key: marker("form_field_label_date_format"),
			},
			rotated: {
				type: "boolean",
				title: "Rotate Label",
				title_translation_key: marker("form_field_label_rotate_label"),
				description:
					"Enable this option if the labels should be rotated 90 degrees before being sent to the printer",
				description_translation_key: marker(
					"form_field_description_enable_this_option_if_the_labels_should_be_rotated_90_degrees_before_being_sent_to_the_printer",
				),
				widget: "checkbox",
				visibleIf: { entity_type: ["$ANY$"] },
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
		},
		required: [
			"name",
			"label_width",
			"label_height",
			"template",
			"label_dpi",
			"entity_type",
		],
	};

	printerJobTemplate$ = this._store.select(
		fromDashboard.getSelectedPrinterJobTemplate,
	);

	printerJobTemplate: IPrinterJobTemplate;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
		private readonly _globals: Globals,
	) {
		super(_store, _cd);
		this.form_title = "Update a printer job template";
		this.form_title_translation_key = marker(
			"form_title_update_a_printer_job_template",
		);
		this.submit_button = "Update";
	}

	ngOnInit() {
		this.printerJobTemplate$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((printerJobTemplate) => {
				this.printerJobTemplate = printerJobTemplate;

				this._globals.general_inventory_enabled_replay.subscribe((gi_enabled) => {
					if (gi_enabled) {
						this.schema.properties.entity_type.oneOf = [
							...this.entityOptions,
							...this.additionalGiOptions,
						].map((item) => {
							return {
								name: item.name,
								name_translation_key: item.t_key,
								value: item.value,
								enum: [item.value],
							};
						});
					}
				});

				this.model.id = this.printerJobTemplate.id;
				this.model.name = this.printerJobTemplate.name;
				this.model.archived = this.printerJobTemplate.archived;
				this.model.label_width = this._injector.get(
					"labelWidth",
					this.printerJobTemplate.label_width,
				);
				this.model.label_height = this._injector.get(
					"labelHeight",
					this.printerJobTemplate.label_height,
				);
				this.model.template = this._injector.get(
					"template",
					this.printerJobTemplate.template,
				);
				this.model.label_dpi = this._injector.get(
					"labelDpi",
					this.printerJobTemplate.label_dpi,
				);

				this.model.entity_type = this._injector.get(
					"entityType",
					this.printerJobTemplate.entity_type,
				);

				this.model.rotated = this._injector.get(
					"rotated",
					this.printerJobTemplate.rotated,
				);

				this.model.date_format = this._injector.get(
					"date_format",
					this.printerJobTemplate.date_format,
				);

				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(type: Partial<IPrinterJobTemplate>) {
		this.loading$.next(true);
		this._itemService
			.update(
				`printer_job_template`,
				type.id!,
				type,
				PrinterJobTemplateDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem: IPrinterJobTemplate) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "printer_job_templates",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
