import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	Injector,
	OnInit,
	OnDestroy,
} from "@angular/core";
import { WorkOrderService } from "app/modules/dashboard/services/work-order.service";
import { Globals } from "app/shared/modules/globals/globals.service";
import {
	IInventoryUnit,
	IWorkOrderOutput,
	MeasureUnit,
	SubstanceTypes,
} from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

import { onVendorChange } from "../../shared";

// @deprecated
@Component({
	selector: "work-order-drying-output-ez-gi",
	templateUrl: "../../form-view.component.html",
	styleUrls: ["../../sidenav.scss"],
})
export class WorkOrderDryingOutputEzGiComponent
	extends GenericUpdateComponent<{
		id: number;
		batch_id: number;
		work_order_id: boolean;
		work_order_type_id: number;
		weight: number;
	}>
	implements OnInit, OnDestroy
{
	prevInventoryProductId: number | undefined;
	prevMeasureUnit: MeasureUnit | undefined;

	inventory_types = [
		{
			name: "Weight",
			name_translation_key: marker("word_weight"),
			value: MeasureUnit.Mass,
			enum: [MeasureUnit.Mass],
		},
		{
			name: "Quantity",
			name_translation_key: marker("word_quantity"),
			value: MeasureUnit.Quantity,
			enum: [MeasureUnit.Quantity],
		},
	];

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			batch_id: {
				type: "number",
				title: "Destination Batch",
				title_translation_key: marker("form_field_label_destination_batch"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "batches",
						queryString: {
							input: "true",
							dont_show_expired: "true",
						},
					},
				],
				readOnly: false,
			},
			output_type: {
				title: "Output",
				title_translation_key: marker("word_output"),
				type: "string",
				widget: "select",
				default: SubstanceTypes.DriedCannabis,
				oneOf: [
					{
						name: SubstanceTypes.DriedCannabis,
						name_translation_key: marker("word_dried_cannabis"),
						value: SubstanceTypes.DriedCannabis,
						enum: [SubstanceTypes.DriedCannabis],
					},
					{
						name: "Other",
						name_translation_key: marker("word_other"),
						value: "OTHER",
						enum: ["OTHER"],
					},
				],
			},
			inventory_type: {
				title: "Inventory Type",
				title_translation_key: marker("form_field_label_inventory_type"),
				type: "string",
				widget: "select",
				oneOf: this.inventory_types,
				visibleIf: { output_type: ["OTHER"] },
			},
			inventory_product_id: {
				title: "Product",
				title_translation_key: marker("word_product"),
				type: "number",
				widget: "data-select",
				oneOf: [
					{
						result_type: "inventory_products",
						queryString: {
							internal_vendor: "true",
							non_inventory_destruction: "false",
						},
					},
				],
				visibleIf: {
					output_type: ["OTHER"],
				},
			},
			value: {
				type: "number",
				title: `Value`,
			},
			inventory_unit_id: {
				hidden: true,
				title: "Inventory Unit",
				title_translation_key: marker("word_inventory_unit"),
				type: "number",
				widget: "data-select",
				quick_create: false,
				related_properties: ["inventory_product_id"],
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
				visibleIf: {
					inventory_product_id: ["$EXP$ target.value > 0"],
				},
			},
			timestamp: {
				type: "string",
				title: "Date",
				title_translation_key: marker("word_date"),
				widget: "date",
			},
		},
		required: ["batch_id", "value"],
	};

	// Injected from other component
	private batch_id: number | undefined;
	private work_order_id: number;
	private work_order_type_id: number;
	private measure_unit: MeasureUnit;
	private readonly measure_setting: string;
	private measure_setting_fallback: string;
	private prevInventoryType: MeasureUnit | undefined;
	private readonly duplicate_timestamp: boolean = false;

	constructor(
		protected readonly _store: Store<fromDashboard.State>,
		protected readonly _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _workOrderService: WorkOrderService,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.form_title = "Record Dried Output";
		this.form_title_translation_key = marker("form_title_record_dried_output");
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.batch_id = this._injector.get("batch_id", null);
		this.work_order_id = this._injector.get("work_order_id", null);
		this.work_order_type_id = this._injector.get("work_order_type_id", null);

		this.schema.properties.inventory_product_id.oneOf[0].queryString = {
			// Retain the queryString already in this schema
			...this.schema.properties.inventory_product_id.oneOf[0].queryString,
			output_inventory_products_for_work_order_type_id: this.work_order_type_id,
		};

		this.model.batch_id = this.batch_id;

		const duplicate: IWorkOrderOutput | null = this._injector.get(
			"duplicate",
			null,
		);

		if (duplicate) {
			this.model = {
				...this.model,
				id: duplicate.work_order_id,
				inventory_product_id: duplicate.inventory.inventory_product_id,
				inventory_unit_id: duplicate.inventory.inventory_unit_id,
				batch_id: duplicate.batch_id,
				location_id: duplicate.location_id,
			};

			if (
				duplicate.inventory.inventory_product?.name !== SubstanceTypes.DriedCannabis
			) {
				this.model.output_type = "OTHER";
			}
			if (
				duplicate.inventory.measurements &&
				duplicate.inventory.measurements[0]
			) {
				this.prevInventoryProductId = Number(
					duplicate.inventory.inventory_product_id,
				);
				this.prevInventoryType =
					duplicate.inventory.displayUnit?.name.toLowerCase() === "ea"
						? MeasureUnit.Quantity
						: MeasureUnit.Mass;

				this.model = {
					...this.model,
					...this.model,
					inventory_type: this.prevInventoryType,
					inventory_product_id: this.prevInventoryProductId,
					value: -1 * duplicate.inventory.measurements[0]!.value,
					inventory_unit_id: duplicate.inventory.inventory_unit_id,
					timestamp: duplicate.inventory.measurements[0]!.timestamp,
				};
			}
			this.batch_id = duplicate.batch_id;
			this.work_order_id = duplicate.work_order_id;

			// clear timestamp for non GMP
			if (
				!this._globals.gmp_enabled &&
				this.schema.properties.timestamp &&
				!this.duplicate_timestamp
			) {
				delete this.model.timestamp;
			}
		} else {
			this.model.output_type = SubstanceTypes.DriedCannabis;

			const units = this._globals.getFacilityUnit(
				MeasureUnit.Mass,
				this.measure_setting_fallback,
			);
			this.schema.properties.value.title = `Value (${units})`;
			this.schema.properties.value.title_translation_params = { units };
		}

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		if (!model.inventory_product_id && this.prevInventoryProductId) {
			this.prevInventoryProductId = undefined;
			model.inventory_unit_id = undefined;
		} else if (
			model.inventory_product_id &&
			this.prevInventoryProductId !== model.inventory_product_id
		) {
			this.prevInventoryProductId = model.inventory_product_id;
			model.inventory_unit_id = undefined;
			this.setUnit(model.inventory_product_id);
		}

		if (!model.inventory_type && this.prevInventoryType) {
			this.prevInventoryType = undefined;
			model.inventory_type = undefined;
		} else if (
			model.inventory_type &&
			this.prevInventoryType !== model.inventory_type
		) {
			this.prevInventoryType = model.inventory_type;

			this._itemService
				.fetchItem(`inventory_product`, `${model.inventory_product_id}`, {
					eager: "[vendors]",
				})
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((inventory_product) => {
					[this.model, this.schema] = onVendorChange(
						this.model,
						this.schema,
						inventory_product,
					);
				});

			if (model.inventory_product_id) {
				model.inventory_unit_id = undefined;
				this.setUnit(model.inventory_product_id);
			}
		}

		if (
			model.inventory_type &&
			model.inventory_type !== this.prevMeasureUnit &&
			[MeasureUnit.Mass, MeasureUnit.Quantity].includes(this.model.inventory_type)
		) {
			this.measure_setting_fallback = this.measure_setting || "batch";
			this.measure_unit = model.inventory_type;
			const units = this._globals.getFacilityUnit(
				model.inventory_type,
				this.measure_setting_fallback,
			);
			this.schema.properties.value.title = `Value (${units})`;
			this.schema.properties.value.title_translation_params = { units };
			this.prevMeasureUnit = model.inventory_type;
			this._cd.detectChanges();
		}
	}

	setUnit(inventory_product_id: number) {
		this._itemService
			.fetchItem(`inventory_product`, `${inventory_product_id}`, {
				eager: "[inventory_units, virtual_products.[inventory_units]]",
			})
			.pipe(
				takeUntil(this.destroyed$),
				timeout(50000),
				catchError((error) => {
					/* eslint no-console: off */
					console.error(error);
					return EMPTY;
				}),
			)
			.subscribe((inventory_product) => {
				const productUnits: IInventoryUnit[] = inventory_product.inventory_units;
				const measureUnitType = this.model.inventory_type;
				let unitIdToUse: number | undefined = inventory_product.display_unit_id;
				if (measureUnitType === MeasureUnit.Quantity) {
					for (const virtualProduct of inventory_product.virtual_products ?? []) {
						for (const unit of virtualProduct.inventory_units ?? []) {
							productUnits.push(unit);
						}
					}
					const quantityUnit = productUnits.find(
						(unit) => unit.name.toLowerCase() === "ea",
					);
					if (quantityUnit) {
						unitIdToUse = quantityUnit.id;
					}
				}

				this.model.inventory_unit_id = unitIdToUse;
				this._cd.detectChanges();
			});
	}

	updateItem(work_order_output: {
		batch_id: number;
		value: number;
		inventory_product_id: boolean;
		inventory_unit_id: number;
		timestamp?: Date;
	}) {
		this.loading$.next(true);
		const input: any = {
			...work_order_output,
			work_order_id: this.work_order_id,
			batch_id: work_order_output.batch_id
				? work_order_output.batch_id
				: this.batch_id,
			timestamp: work_order_output.timestamp ?? new Date(),
			type: "Inventory",
		};

		this._workOrderService
			.addOutputs(input)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
