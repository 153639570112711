import {
	HeaderQueryType,
	IApplicableProduct,
	Page,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ApplicableProductPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "applicable_products";

export type State = Page<IApplicableProduct>;

export const reducer = createPagingReducer<IApplicableProduct>(reducerKey, {
	result_type: "applicable_products",
	title: "Applicable Product",
	title_translation_key: marker("page_title_applicable_products"),
	title_plural_translation_key: marker("page_title_plural_applicable_products"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Applicable Product Filter",
			key: "applicableProductType",
			type: HeaderQueryType.select,
			options: [
				{
					label: "All",
					translationKey: marker("word_all"),
					value: "ALL",
				},
				{
					label: "Bio Controls",
					translationKey: marker("applicable_products_filter_bio_controls"),
					value: "BIO_CONTROL",
				},
				{
					label: "Pest Controls",
					translationKey: marker("applicable_products_filter_pest_control"),
					value: "PEST_CONTROL",
				},
				{
					label: "Sanitation Products",
					translationKey: marker("applicable_products_filter_sanitation_products"),
					value: "SANITATION_PRODUCT",
				},
				{
					label: "Nutrients",
					translationKey: marker("word_nutrients"),
					value: "NUTRIENTS",
				},
			],
			value: "ALL",
		},
		{
			title: "Show Archived",
			key: "showArchived",
			title_translation_key: marker("checkbox_filter_show_archived"),
			type: HeaderQueryType.checkbox,
			value: false,
		},
	],
	query: ApplicableProductPageQuery,
});
