import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";

interface SidenavConfirmButton {
	title: string;
	icon?: string;
	class: string;
	callback: string;
	requiresUnderstand?: boolean;
}
export interface SidenavConfirmConfig {
	icon?: string;
	title?: string;
	description?: string;
	attachedFile?: any;
	iUnderstandText?: string;
	buttons: SidenavConfirmButton[];
	type: string;
}

@Component({
	selector: "sidenav-confirm",
	template: `
		<div class="ui grid sidenav-confirm">
			<div class="sixteen wide column">
				@if (config.icon) {
					<i [className]="config.icon + ' icon big sidenav-confirm__icon'"></i>
				}
				@if (config.title) {
					<p class="sidenav-confirm__title">{{ config.title }}</p>
				}
				@if (config.attachedFile) {
					<div class="summary" *ngIf="config.attachedFile">
						<a
							target="_blank"
							href="{{ config.attachedFile.location }}"
							class="ng-star-inserted"
							>{{ config.attachedFile.originalname }}</a
						>
					</div>
				}
				@if (config.description) {
					<p class="sidenav-confirm__description">{{ config.description }}</p>
				}
				@if (config.iUnderstandText) {
					<div class="ui form">
						<fui-checkbox [(ngModel)]="iUnderstand">
							<p>{{ config.iUnderstandText }}</p>
						</fui-checkbox>
					</div>
				}
			</div>

			<div class="sixteen wide column">
				<div *ngFor="let button of config.buttons" class="sidenav-confirm__button">
					<button
						[className]="getButtonClass(button)"
						[name]="button.callback"
						(click)="onCallback($event)"
					>
						<i *ngIf="button.icon" [className]="button.icon + ' icon'"></i>
						{{ button.title }}
					</button>
				</div>
			</div>
		</div>
	`,
})
export class SidenavConfirmComponent implements OnInit {
	@Input() config: any;
	@Output() callback = new EventEmitter<any>();

	iUnderstand = false;

	constructor() {}

	ngOnInit() {}

	getButtonClass(button: SidenavConfirmButton) {
		const classes: string[] = [];
		if (button.requiresUnderstand && !this.iUnderstand) {
			classes.push("disabled");
		}
		classes.push(button.class);
		classes.push("ui button");
		return classes.join(" ");
	}

	onCallback(event: any) {
		this.callback.emit({
			type: this.config.type,
			response: event.target.name,
		});
	}
}
