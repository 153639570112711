import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap, filter } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import { InventoryProductDetailQuery } from "app/shared/eagers";
import { IInventoryProduct } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "inventory-product-update-virtual",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class InventoryProductUpdateVirtualComponent
	extends GenericUpdateComponent<IInventoryProduct>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			virtual_product_id: {
				type: "number",
				hidden: true,
			},
			display_unit_id: {
				type: "number",
				title: "Display Unit",
				title_translation_key: marker("word_display_unit"),
				widget: "data-select",
				related_properties: ["virtual_product_id"],
				oneOf: [
					{
						result_type: "inventory_units",
						text_key: ["name"],
					},
				],
			},
		},
		required: ["virtual_product_id", "display_unit_id"],
	};

	inventory_product$ = this._store.select(
		fromDashboard.getSelectedInventoryProduct,
	);

	inventory_product: IInventoryProduct;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.form_title = "Change Display Unit";
		this.form_title_translation_key = marker("form_title_change_display_unit");
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.model.virtual_product_id = this._injector.get(
			"virtual_product_id",
			null,
		);
		this.model.display_unit_id = this._injector.get("display_unit_id", null);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(type: any) {
		this.loading$.next(true);
		this._itemService
			.update(
				`inventory_product`,
				type.virtual_product_id,
				type,
				InventoryProductDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "inventory_products",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
