import { Pipe, PipeTransform } from "@angular/core";
import { DateFormatService } from "app/modules/dashboard/services/date-format/dateFormat.service";
import { FormatOption } from "app/shared/time-format";

@Pipe({
	name: "formatDateTime",
})
export class FormatDateTimePipe implements PipeTransform {
	constructor(private readonly _dateFormatService: DateFormatService) {}

	transform(date: string, options?: FormatOption): string {
		return this._dateFormatService.formatDateTime(date, options);
	}
}
