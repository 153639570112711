import { HeaderQueryType, ILot, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { LotPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "lots";

export type State = Page<ILot>;

export const reducer = createPagingReducer<ILot>(reducerKey, {
	result_type: "lots",
	title: "Lot",
	title_plural: "Lots",
	title_translation_key: marker("page_title_lots"),
	title_plural_translation_key: marker("page_title_plural_lots"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Lot Filter",
			key: "lot_filter",
			type: HeaderQueryType.select,
			options: [
				{
					label: "All Lots",
					translationKey: marker("lot_filter_all_lots"),
					value: "ALL",
				},
				{
					label: "Active Lots",
					translationKey: marker("lot_filter_active_lots"),
					value: "ACTIVE",
				},
				{
					label: "Empty Lots",
					translationKey: marker("lot_filter_empty_lots"),
					value: "EMPTY",
				},
				{
					label: "Archived Lots",
					translationKey: marker("lot_filter_archived_lots"),
					value: "ARCHIVED",
				},
			],
			value: "ACTIVE",
		},
		{
			title: "Product Filter",
			key: "inventory_product_ids",
			title_translation_key: marker("page_title_plural_products"),
			type: HeaderQueryType.dataSelect,
			result_type: "inventory_products",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		},
	],
	query: LotPageQuery,
});
