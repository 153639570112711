import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, take, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import { IReportingCategory } from "@elevatedsignals/amygoodman";
import { ReportingCategoryDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";

@Component({
	selector: "reporting-category-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class ReportingCategoryUpdateComponent
	extends GenericUpdateComponent<IReportingCategory>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			inventory_product_ids: {
				type: "array",
				title: "Inventory Products",
				title_translation_key: marker("word_inventory_products"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_products",
							text_key: ["name"],
						},
					],
				},
			},
			inventory_category_ids: {
				type: "array",
				title: "Inventory Product Categories",
				title_translation_key: marker(
					"form_field_label_inventory_product_categories",
				),
				description:
					"Select entire product categories to include in this reporting category",
				description_translation_key: marker(
					"form_field_description_select_entire_product_categories_to_include_in_this_reporting_category",
				),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_categories",
							text_key: ["id", "name"],
						},
					],
				},
			},
			exclude_inventory_product_ids: {
				type: "array",
				title: "Exclude Inventory Products",
				title_translation_key: marker(
					"form_field_label_exclude_inventory_products",
				),
				description:
					"Select individual inventory products to exclude from this reporting category. This will override any includes.",
				description_translation_key: marker(
					"form_field_description_select_individual_inventory_products_to_exclude_from_this_reporting_category",
				),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_products",
							text_key: ["id", "name"],
						},
					],
				},
			},
			exclude_inventory_category_ids: {
				type: "array",
				title: "Exclude Inventory Product Categories",
				title_translation_key: marker(
					"form_field_label_exclude_inventory_product_categories",
				),
				description:
					"Select entire inventory categories to exclude from this reporting category. This will override any includes.",
				description_translation_key: marker(
					"form_field_description_select_entire_inventory_categories_to_exclude_from_this_reporting_category",
				),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_categories",
							text_key: ["id", "name"],
						},
					],
				},
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
		},
		required: ["name"],
	};

	reporting_category$ = this._store.select(
		fromDashboard.getSelectedReportingCategory,
	);

	reporting_category: IReportingCategory;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
	) {
		super(_store, _cd);
		this.form_title = "Update Reporting Category";
		this.form_title_translation_key = marker(
			"form_title_update_reporting_category",
		);
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.reporting_category$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((reporting_category) => {
				this.reporting_category = reporting_category;
				this.model.id = this.reporting_category.id;
				this.model.name = this.reporting_category.name;
				this.model.archived = this.reporting_category.archived;
				this.model.inventory_product_ids = (
					this.reporting_category.inventory_products ?? []
				).map((product) => product.id);
				this.model.exclude_inventory_product_ids = (
					this.reporting_category.exclude_inventory_products ?? []
				).map((product) => product.id);
				this.model.inventory_category_ids = (
					this.reporting_category.inventory_categories ?? []
				).map((category) => category.id);
				this.model.exclude_inventory_category_ids = (
					this.reporting_category.exclude_inventory_categories ?? []
				).map((category) => category.id);
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(type) {
		const updated_category = {
			name: type.name || null,
			inventory_product_ids: type.inventory_product_ids || [],
			inventory_category_ids: type.inventory_category_ids || [],
			exclude_inventory_product_ids: type.exclude_inventory_product_ids || [],
			exclude_inventory_category_ids: type.exclude_inventory_category_ids || [],
			archived: type.archived,
		};

		this.loading$.next(true);
		this._itemService
			.update(
				`reporting_category`,
				type.id,
				updated_category,
				ReportingCategoryDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "reporting_categories",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
