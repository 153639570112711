import { HeaderQueryType, Page, Record } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ActivityLogPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "audit_logs";

export type State = Page<Record>;

export const reducer = createPagingReducer<Record>(reducerKey, {
	result_type: "audit_logs",
	title: "Activity Log",
	title_plural: "Activity Logs",
	title_translation_key: marker("page_title_activity_logs"),
	title_plural_translation_key: marker("page_title_plural_activity_logs"),
	results: [],
	page: 1,
	page_size: 10,
	total: 0,
	header_queries: [
		{
			title: "Work Orders",
			key: "work_order",
			title_translation_key: marker("page_title_plural_work_orders"),
			type: HeaderQueryType.checkbox,
			value: false,
		},
		{
			title: "Records",
			key: "record",
			title_translation_key: marker("page_title_plural_records"),
			type: HeaderQueryType.checkbox,
			value: false,
		},
		{
			title: "Destructions",
			key: "destruction",
			title_translation_key: marker("page_title_plural_destructions"),
			type: HeaderQueryType.checkbox,
			value: false,
		},
		{
			title: "Batches",
			key: "batch",
			title_translation_key: marker("page_title_plural_batches"),
			type: HeaderQueryType.checkbox,
			value: false,
		},
		{
			title: "Miscellaneous Adjustment",
			key: "measure_event",
			title_translation_key: marker(
				"activity_log_filter_miscellaneous_adjustment",
			),
			type: HeaderQueryType.checkbox,
			value: false,
		},
		{
			title: "Users",
			key: "user_ids",
			title_translation_key: marker("page_title_plural_users"),
			type: HeaderQueryType.dataSelect,
			result_type: "users",
			value_key: "id",
			text_key: ["first_name", "last_name"],
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		},
		{
			title: "Start",
			key: "start_date",
			title_translation_key: marker("word_start"),
			type: HeaderQueryType.date,
			value: "",
		},
		{
			title: "End",
			title_translation_key: marker("word_end"),
			key: "end_date",
			type: HeaderQueryType.date,
			value: "",
		},
	],
	query: ActivityLogPageQuery,
});
