import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { User } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { combineLatest, EMPTY, NEVER, race, ReplaySubject } from "rxjs";
import {
	timeout,
	take,
	takeUntil,
	filter,
	tap,
	catchError,
} from "rxjs/operators";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";

@Component({
	selector: "user-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class UserUpdateComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	user$ = this._store.select(fromDashboard.getSelectedUser);
	profileId$ = this._store.select(fromDashboard.getProfileId);
	sessionUser$ = this._store.select(fromDashboard.getProfile);
	user: User;
	session_user: any;

	form_title = "Update a User";
	form_title_translation_key: string = marker("form_title_update_a_user");
	submit_button = "Update User";
	submit_button_translation_key: string = marker("form_button_update_user");
	submit_icon = "edit";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				title: "Id",
				hidden: true,
			},
			email: {
				type: "string",
				title: "Email",
				title_translation_key: marker("word_email"),
				widget: "string",
				readOnly: false,
			},
			first_name: {
				type: "string",
				title: "First Name",
				title_translation_key: marker("word_first_name"),
				widget: "string",
			},
			last_name: {
				type: "string",
				title: "Last Name",
				title_translation_key: marker("word_last_name"),
				widget: "string",
			},
			job_title: {
				type: "string",
				title: "Job Title",
				title_translation_key: marker("form_field_label_job_title"),
				widget: "string",
			},
			activated: {
				type: "boolean",
				title: "Activated",
				title_translation_key: marker("word_activated"),
				widget: "checkbox",
				readOnly: false,
			},
			role_ids: {
				type: "array",
				minItems: 1,
				title: "Roles",
				title_translation_key: marker("word_roles"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [{ result_type: "roles" }],
				},
				readOnly: false,
			},
			facility_owner: {
				type: "boolean",
				title: "Facility Owner",
				title_translation_key: marker("word_facility_owner"),
				widget: "checkbox",
				readOnly: false,
			},
		},
		required: ["email", "role_ids"],
	};

	validators = {};

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
	) {}

	valid(v) {
		this.valid$.next(v);
	}

	ngOnInit() {
		race(
			combineLatest([this.profileId$, this.user$, this.sessionUser$]).pipe(
				takeUntil(this.destroyed$),
				filter((values) => values[1] !== undefined && values[0] !== null),
				take(1),
			),
			NEVER.pipe(timeout(10000)),
		).subscribe({
			next: (values) => {
				this.session_user = values[2];

				this.user = values[1]!;
				this.model = {
					...this.model,
					id: this.user.id,
					email: this.user.email,
					first_name: this.user.first_name,
					last_name: this.user.last_name,
					job_title: this.user.job_title,
					activated: this.user.activated,
					facility_owner: this.user.facility_owner,
					role_ids: this.user.roles?.map((role) => role.id),
				};
				this.schema.properties.activated.readOnly =
					this.user.id === values[0] || this.user.facility_owner;

				this.schema.properties.email.readOnly = Boolean(
					(this.schema.properties.facility_owner.readOnly =
						this.user.facility_owner),
				);
			},
			error: (error) => {
				this.error$.next(handleObservableError(error));
				return EMPTY;
			},
		});

		// Session user should not be able to update own role
		if (this.session_user.id === this.model.id) {
			this.schema.properties.role_ids.readOnly = true;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const user: Partial<User> = {
			...this.model,
		};
		this.updateUser(user);
	}

	onChanges(_model): void {}

	updateUser(update_user: Partial<User>) {
		this.loading$.next(true);
		const query: Record<string, string | string[]> = {};
		query.eager = "[roles]";
		this._itemService
			.update(`user`, this.user.id, update_user, query)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "users",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
