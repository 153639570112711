import { IPrinter } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.Printers;

export type State = SelectionState<IPrinter>;

export const reducer = createSelectionReducer<IPrinter>(reducerKey);
