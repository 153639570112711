import { Component, OnInit, OnDestroy, Injector } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { handleObservableError } from "app/shared/utils";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { ReplaySubject, EMPTY } from "rxjs";
import { UserInvite } from "@elevatedsignals/amygoodman";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

import { UserService } from "../../../services/user.service";

@Component({
	selector: "user-invite",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class UserInviteComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Invite a User";
	form_title_translation_key: string = marker("form_title_invite_a_user");
	submit_button = "Invite User";
	submit_button_translation_key: string = marker("form_button_invite_user");
	submit_icon = "plus";

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};
	specific_field_id: string;

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			email: {
				type: "string",
				title: "Email",
				title_translation_key: marker("word_email"),
				widget: "string",
			},
			first_name: {
				type: "string",
				title: "First Name",
				title_translation_key: marker("word_first_name"),
				widget: "string",
			},
			last_name: {
				type: "string",
				title: "Last Name",
				title_translation_key: marker("word_last_name"),
				widget: "string",
			},
			job_title: {
				type: "string",
				title: "Job Title",
				title_translation_key: marker("form_field_label_job_title"),
				widget: "string",
			},
			role_ids: {
				type: "array",
				minItems: 1,
				title: "Roles",
				title_translation_key: marker("word_roles"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [{ result_type: "roles" }],
				},
			},
		},
		required: ["email", "role_ids"],
	};

	validators = {};

	private readonly location_id: number;
	private readonly cultivar_id: number;

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _userService: UserService,
		private readonly _injector: Injector,
	) {
		this.specific_field_id = this._injector.get("specific_field_id", null);
	}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const user: UserInvite = {
			...this.model,
		};
		this.addUser(user);
	}

	onChanges(_model): void {}

	addUser(add_user: UserInvite) {
		this.loading$.next(true);
		this._userService
			.inviteUser(add_user)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							addedItem,
							result_type: "users",
							...(this.specific_field_id && {
								specific_field_id: this.specific_field_id,
							}),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
