import { EsWidgetOneOfProperty } from "../../widgets/es-widget-schema.model";

export const NumberInput = {
	type: "number",
	widget: "number",
	min: 0,
};

export const CostPerUnitInput = {
	...NumberInput,
	title: "Cost per unit",
	// width: "70%",
	visibleIf: {
		oneOf: [
			{ value: ["$EXP$ target.value > 0"] },
			{ amount: ["$EXP$ target.value > 0"] },
		],
	},
};

export const CalculatedCostInput = {
	...NumberInput,
	visibleIf: {
		cost_per_unit: ["$ANY$"],
	},
	readOnly: true,
};

export const OtherFeesInput = {
	...NumberInput,
	title: "Other fees",
	visibleIf: {
		cost_per_unit: ["$ANY$"],
	},
};

export const DiscountsInput = {
	...NumberInput,
	title: "Discounts",
	visibleIf: {
		cost_per_unit: ["$ANY$"],
	},
};

export const TotalTaxInput = {
	...NumberInput,
	title: "Tax Total",
	visibleIf: {
		cost_per_unit: ["$ANY$"],
	},
};

const allowedCurrency = ["CAD", "USD", "AUD", "NZD", "EU", "BPD", "EUR", "THB"];

export const CurrencySelect = {
	type: "string",
	title: "Currency",
	widget: "select",
	oneOf: allowedCurrency.map((key) => ({
		name: key,
		value: key,
		enum: [key],
	})) as EsWidgetOneOfProperty[],
	// width: "30%",
	default: "CAD",
	visibleIf: {
		value: ["$EXP$ target.value > 0"],
	},
};
