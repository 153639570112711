import { Injectable } from "@angular/core";
import { UserProfile } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { combineLatestWith } from "rxjs";
import { DevCycleService } from "app/devcycle/devcycle.service";
import { DevCycleKey } from "app/devcycle/devcycleKeys";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { show, hide, boot, shutdown, update } from "@intercom/messenger-js-sdk";
import { EnvService } from "app/shared/services/env.service";

import * as fromDashboard from "../reducers";

@Injectable({
	providedIn: "root",
})
export class IntercomService {
	private readonly appId = "q6rdhdbx";

	private readonly user$ = this._store.select(fromDashboard.getProfile);

	private readonly isIntercomEnabled$ = this._devCycleService.getVariable(
		DevCycleKey.ShowIntercomMessenger,
		false,
	);

	constructor(
		private readonly _devCycleService: DevCycleService,
		private readonly _store: Store<fromDashboard.State>,
		private readonly _env: EnvService,
	) {
		this.user$
			.pipe(isNotNullOrUndefined(), combineLatestWith(this.isIntercomEnabled$))
			.subscribe(([user, isIntercomEnabled]) => {
				if (isIntercomEnabled.value) {
					this.initialize(user);
				} else {
					this.shutdown();
				}
			});
	}

	initialize(user: UserProfile) {
		this.boot(user);
		// this.show(); // Opens the intercom message box on page load
	}

	boot(user: UserProfile) {
		const userData = {
			user_id: `${user.id}`,
			email: user.email,
			name: user.first_name,
			environment: this._env.es_env,
			company: {
				id: user.facility_id,
				name: user.facility.name,
			},
		};
		boot({
			app_id: this.appId,
			api_base: "https://api-iam.intercom.io",
			...userData,
		});
	}

	update(data: any) {
		update(data);
	}

	shutdown() {
		shutdown();
	}

	show() {
		show();
	}

	hide() {
		hide();
	}
}
