<ng-container *transloco="let t; read: 'customize_units_component'">
	<div class="ui grid dirty-mackenzie">
		<div class="sixteen wide column">
			<div class="left floated left aligned eight wide column">
				<button class="ui labeled icon button" (click)="closeSidenav()">
					<i class="left arrow icon"></i>
					{{ "word_cancel" | transloco }}
				</button>
			</div>
		</div>
		<div class="sixteen wide column">
			<h2>
				{{ "form_title_customize_inventory_units" | transloco }}
			</h2>
		</div>

		<div class="sixteen wide column">
			<form [formGroup]="unitsForm" class="ui form">
				<div class="eight wide field">
					<label>{{ t("display_unit") }}</label>
					<fui-select
						class="selection"
						[formControlName]="'displayUnit'"
						[ngModel]="inventory_product.display_unit_id"
						(selectedOptionChange)="switchDisplayUnit($event)"
						labelField="name"
						valueField="index"
						[isSearchable]="false"
					>
						<fui-select-option
							*ngFor="let unit of validDisplayUnits; let i = index"
							[value]="displayUnitSelectOption(unit, i)"
						>
						</fui-select-option>
					</fui-select>
				</div>
				<table>
					<thead>
						<tr>
							<th colspan="1"></th>
							<th colspan="2">{{ t("unit_name") }}</th>
							<th colspan="2">{{ t("converts_to") }}</th>
							<th colspan="1"></th>
						</tr>
					</thead>
					<tbody formArrayName="units">
						<tr
							*ngFor="let unit of units.controls; let i = index"
							[formGroupName]="i"
						>
							<td>
								<button class="ui icon button" (click)="reverseConversion(i)">
									<i class="exchange alternate icon"></i>
								</button>
							</td>
							<ng-container
								*ngIf="!unit.getRawValue().reverse_conversion; else reverseBlock"
							>
								<td>
									<div [ngClass]="unit.disabled ? 'ui disabled input' : 'ui input'">
										<input type="text" formControlName="name" />
									</div>
								</td>
								<td class="center aligned">=</td>
								<td>
									<div [ngClass]="unit.disabled ? 'ui disabled input' : 'ui input'">
										<input type="number" formControlName="conversion" />
									</div>
								</td>
								<td>{{ displayUnitName }}</td>
							</ng-container>
							<ng-template #reverseBlock>
								<td>{{ displayUnitName }}</td>
								<td>=</td>
								<td>
									<div [ngClass]="unit.disabled ? 'ui disabled input' : 'ui input'">
										<input type="number" formControlName="conversion" />
									</div>
								</td>
								<td>
									<div [ngClass]="unit.disabled ? 'ui disabled input' : 'ui input'">
										<input type="text" formControlName="name" />
									</div>
								</td>
							</ng-template>
							<td>
								<div class="text" *ngIf="!unit.disabled">
									<button class="ui icon button" (click)="removeUnit(i)">
										<i class="close icon"></i>
									</button>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<button type="button" (click)="addUnit()" class="ui button">
					+ {{ "word_new" | transloco }}
				</button>
			</form>
		</div>
		<br />
		<div class="sixteen wide column">
			<p>
				{{ "word_note" | transloco }}: <br />
				{{ t("if_a_unit_is_entered_more_than_once") }}
			</p>
		</div>

		<div class="sixteen wide column" *ngIf="error$ | async">
			<div class="ui error message">
				<span>{{ error$ | async }}</span>
			</div>
		</div>

		<div class="sixteen wide column" *ngIf="submit_button !== undefined">
			<submit-button
				[loading]="false"
				[valid]="true"
				[submit_button]="submit_button"
				[submit_button_translation_key]="submit_button_translation_key"
				(onSubmit)="onSubmit()"
			/>
		</div>
	</div>
</ng-container>
