import { IInventoryProduct } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.InventoryProducts;

export type State = SelectionState<IInventoryProduct>;

export const reducer = createSelectionReducer<IInventoryProduct>(reducerKey);
