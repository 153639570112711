import { IProductionStageType, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "production_stage_types";

export type State = Page<IProductionStageType>;

export const reducer = createPagingReducer<IProductionStageType>(reducerKey, {
	result_type: "production_stage_types",
	title: "Production Stage Type",
	title_translation_key: marker("page_title_production_stage_types"),
	title_plural_translation_key: marker(
		"page_title_plural_production_stage_types",
	),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
});
