import { Injectable } from "@angular/core";
import {
	WorkOrderDetailQuery,
	WorkOrderInputQuery,
	WorkOrderOutputQuery,
	WorkOrderPendingInventoryQuery,
	WorkOrderProcessingLossesQuery,
} from "app/shared/eagers";
import { IBillOfMaterial, IWorkOrderInput } from "@elevatedsignals/amygoodman";

import { ItemService } from "./item.service";

@Injectable()
export class WorkOrderService {
	constructor(private readonly _itemService: ItemService) {}

	addSources(input: IWorkOrderInput) {
		return this._itemService.add(
			`work_order/${input.work_order_id}/sources`,
			input,
			WorkOrderInputQuery,
		);
	}

	addOutputs(input: IWorkOrderInput) {
		return this._itemService.add(
			`work_order/${input.work_order_id}/outputs`,
			input,
			WorkOrderOutputQuery,
		);
	}

	addProcessingLosses(input: IWorkOrderInput) {
		return this._itemService.add(
			`work_order/${input.work_order_id}/processing_losses`,
			input,
			WorkOrderProcessingLossesQuery,
		);
	}

	addBOM(workOrderId: number, billOfMaterial: IBillOfMaterial) {
		return this._itemService.add(
			`work_order/${workOrderId}/bom`,
			billOfMaterial,
			WorkOrderPendingInventoryQuery,
		);
	}

	updateBOM(workOrderId: number, billOfMaterial: IBillOfMaterial) {
		return this._itemService.update(
			`work_order`,
			`${workOrderId}/bom`,
			billOfMaterial,
			WorkOrderPendingInventoryQuery,
		);
	}

	deleteBOM(workOrderId: number, billOfMaterial: any) {
		return this._itemService.delete(`work_order`, `${workOrderId}/bom`, {
			...WorkOrderPendingInventoryQuery,
			work_order_bill_of_material_id: `${billOfMaterial.work_order_bill_of_material_id}`,
			bill_of_material_id: `${billOfMaterial.id}`,
		});
	}

	addWeightOutputs(weightHarvest: IWorkOrderInput) {
		return this._itemService.add(
			`work_order/${weightHarvest.work_order_id}/outputs`,
			weightHarvest,
			WorkOrderOutputQuery,
		);
	}

	removePendingInventory(pendingInventory: {
		work_order_id: number;
		pending_inventory_id: number;
	}) {
		return this._itemService.delete(
			`work_order/${pendingInventory.work_order_id}/pending_inventory`,
			pendingInventory.pending_inventory_id,
			WorkOrderPendingInventoryQuery,
		);
	}

	removeSource(source: { work_order_id: number; source_id: number }) {
		return this._itemService.delete(
			`work_order/${source.work_order_id}/source`,
			source.source_id,
			WorkOrderInputQuery,
		);
	}

	editOutput(output: {
		work_order_id: number;
		output_id: number;
		value: number;
		note?: string;
	}) {
		return this._itemService.update(
			`work_order/${output.work_order_id}/output`,
			output.output_id,
			output,
			WorkOrderOutputQuery,
		);
	}

	removeOutput(output: { work_order_id: number; output_id: number }) {
		return this._itemService.delete(
			`work_order/${output.work_order_id}/output`,
			output.output_id,
			WorkOrderOutputQuery,
		);
	}

	removeProcessingLoss(output: {
		work_order_id: number;
		processing_loss_id: number;
	}) {
		return this._itemService.delete(
			`work_order/${output.work_order_id}/processing_loss`,
			output.processing_loss_id,
			WorkOrderProcessingLossesQuery,
		);
	}

	reOpen(work_order_id) {
		return this._itemService.update(
			`work_order`,
			`${work_order_id}/reopen`,
			{},
			WorkOrderDetailQuery,
		);
	}

	revert(work_order_id) {
		return this._itemService.update(
			`work_order`,
			`${work_order_id}/revert`,
			{},
			WorkOrderDetailQuery,
		);
	}

	removeWorkOrder(
		work_order_id: number,
		signing = false,
		authObject?: { username: string; password: string },
	) {
		return this._itemService.delete(
			`work_order`,
			work_order_id,
			signing
				? {
						...WorkOrderDetailQuery,
						signing: "true",
					}
				: WorkOrderDetailQuery,
			authObject,
		);
	}
}
