/* jscpd:ignore-start */
import { MeasureUnit } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

export interface InventoryDestruction {
	destruction_lot_id: number;
	consumptionSource: string;
	batch_id?: number;
	location_id?: number;
	inventory_lot_id?: number;
	inventory_product_id: number;
	lot_id?: number;
	inventory_ids?: number[];
	inventory_unit_id?: number;
	quantity?: number;
	timestamp?: string;
	destruction_reason_id: number;
	remaining_inventory?: boolean;
}
export const InventoryDestructionEzSchema = {
	title: "",
	description: "",
	info: "",
	properties: {
		work_order_id: {
			type: "number",
			title: "Work Order",
			title_translation_key: marker("word_work_order"),
			widget: "data-select",
			oneOf: [{ result_type: "work_orders" }],
			hidden: true,
		},
		destruction_lot_id: {
			type: "number",
			minItems: 1,
			title: "Destruction Lot",
			title_translation_key: marker("word_destruction_lot"),
			widget: "data-select",
			visible: false,
			oneOf: [
				{
					result_type: "destruction_lots",
					pretext: "",
					text_key: ["name", "id"],
					queryString: {
						lot_status: "OPEN",
					},
				},
			],
		},
		inventory_product_id: {
			title: "Product",
			title_translation_key: marker("word_product"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			related_properties: ["non_inventory_destruction"],
			oneOf: [
				{
					result_type: "inventory_products",
				},
			],
			visibleIf: {
				oneOf: [{ consumptionSource: ["Location", "Batch"] }],
			},
		},
		consumptionSource: {
			type: "string",
			title: "Destroy by",
			title_translation_key: marker("form_field_label_destroy_by"),
			widget: "radio",
			default: "Batch",
			oneOf: [
				{
					name: "Location",
					name_translation_key: marker("word_location"),
					value: "Location",
					enum: ["Location"],
				},
				{
					name: "Batch",
					name_translation_key: marker("word_batch"),
					value: "Batch",
					enum: ["Batch"],
				},
			],
		},
		batch_id: {
			type: "number",
			title: "Batch",
			title_translation_key: marker("word_batch"),
			widget: "data-select",
			oneOf: [{ result_type: "batches" }],
			visibleIf: {
				consumptionSource: ["Batch"],
			},
		},
		location_id: {
			type: "number",
			title: "Location",
			title_translation_key: marker("word_location"),
			description:
				"Destruction weight will be distributed between all batches in this location.",
			description_translation_key: marker(
				"form_field_description_destruction_weight_will_be_distributed_between_all_batches_in_this_location",
			),
			widget: "data-select",
			oneOf: [{ result_type: "locations" }],
			visibleIf: {
				consumptionSource: ["Location"],
			},
		},
		measure_unit: {
			type: "string",
			title: "Measure Type",
			title_translation_key: marker("form_field_label_measure_type"),
			widget: "select",
			oneOf: [
				{
					name: "Mass",
					name_translation_key: marker("word_mass"),
					value: MeasureUnit.Mass,
					enum: [MeasureUnit.Mass],
				},
				{
					name: "Quantity",
					name_translation_key: marker("word_quantity"),
					value: MeasureUnit.Quantity,
					enum: [MeasureUnit.Quantity],
				},
			],
		},
		quantity: {
			type: "number",
			title: `Value`,
			visibleIf: {
				allOf: [
					{
						consumptionSource: ["Location", "Batch", "Lot"],
					},
				],
			},
		},
		inventory_unit_id: {
			hidden: true,
			title: "Inventory Unit",
			title_translation_key: marker("word_inventory_unit"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			related_properties: ["inventory_product_id"],
			oneOf: [
				{
					result_type: "inventory_units",
				},
			],
			visibleIf: {
				allOf: [
					{
						consumptionSource: ["Location", "Batch"],
					},
				],
			},
		},
		destruction_reason_id: {
			type: "number",
			title: "Destruction Reason",
			title_translation_key: marker("word_destruction_reason"),
			widget: "data-select",
			oneOf: [
				{
					result_type: "destruction_reasons",
				},
			],
		},
		description: {
			type: "string",
			title: "Description",
			title_translation_key: marker("word_description"),
			widget: "textarea",
		},
		timestamp: {
			type: "string",
			title: "Timestamp",
			title_translation_key: marker("word_timestamp"),
			widget: "date",
		},
		/** These are just used for the required to make sure we have all the correct items selected before allowing the user to submit the form */
		location_selected: {
			type: "boolean",
			widget: "checkbox",
			hidden: true,
			default: true,
			visibleIf: {
				consumptionSource: ["Location"],
			},
		},
		batch_selected: {
			type: "boolean",
			widget: "checkbox",
			hidden: true,
			default: true,
			visibleIf: {
				consumptionSource: ["Batch"],
			},
		},
	},
	anyOf: [
		/** *** FOR BATCH *******/
		{
			// Destroy quantity
			required: [
				"destruction_lot_id",
				"batch_selected",
				"batch_id",
				"inventory_product_id",
				"inventory_unit_id",
				"quantity",
				"destruction_reason_id",
			],
		},
		/** *** FOR LOCATION *******/
		{
			// Destroy quantity
			required: [
				"destruction_lot_id",
				"location_selected",
				"location_id",
				"inventory_product_id",
				"inventory_unit_id",
				"quantity",
				"destruction_reason_id",
			],
		},
	],
};

/* jscpd:ignore-end */
