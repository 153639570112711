/* jscpd:ignore-start */
import {
	Component,
	OnInit,
	OnDestroy,
	Injector,
	ChangeDetectorRef,
	inject,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { timeout, catchError, takeUntil, tap, switchMap } from "rxjs/operators";
import { ReplaySubject, EMPTY } from "rxjs";
import { IDestructionEvent } from "@elevatedsignals/amygoodman";
import { canCloseBatch, handleObservableError } from "app/shared/utils";
import { UnitToGramsPipe } from "app/modules/dashboard/modules/es-pipes/unit-to-grams.pipe";
import { Globals } from "app/shared/modules/globals/globals.service";
import {
	BatchDetailQuery,
	TagDetailQuery,
	PlantDetailQuery,
	WorkOrderDestructionEventsQuery,
} from "app/shared/eagers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import { ItemKeyType } from "app/modules/dashboard/reducers/selection/keys";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import { PagerService } from "app/modules/dashboard/services/pager.service";
import { FetchPageActions } from "app/modules/dashboard/actions/paging.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

import { BatchCloseComponent } from "../batch/close.component";

/* jscpd:ignore-end */

@Component({
	selector: "destroy-plant",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class DestroyPlantComponent implements OnInit, OnDestroy {
	weight_unit = this._globals.weight_unit;

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			destruction_lot_id: {
				type: "number",
				minItems: 1,
				title: "Destruction Lot",
				title_translation_key: marker("word_destruction_lot"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "destruction_lots",
						pretext: "",
						text_key: ["name", "id"],
						queryString: {
							lot_status: "OPEN",
						},
					},
				],
			},
			weight: {
				type: "number",
				title: `Weight (${this.weight_unit})`,
				title_translation_key: marker("form_field_label_weight"),
				title_translation_params: { weight_unit: this.weight_unit },
			},
			destruction_reason_id: {
				type: "number",
				title: "Destruction Reason",
				title_translation_key: marker("word_destruction_reason"),
				widget: "data-select",
				quick_create: true,
				oneOf: [
					{
						result_type: "destruction_reasons",
					},
				],
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
			timestamp: {
				type: "string",
				title: "Collected On",
				title_translation_key: marker("form_field_label_collected_on"),
				widget: "date",
			},
		},
		anyOf: [
			{
				required: [
					"destruction_lot_id",
					"destruction_reason_id",
					"plant_count",
					"batch_id",
				],
			},
			{
				required: [
					"destruction_lot_id",
					"destruction_reason_id",
					"plant_ids",
					"batch_id",
				],
			},
			{
				required: ["destruction_lot_id", "destruction_reason_id", "tag_id"],
			},
		],
	};

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Plant Destruction";
	form_title_translation_key: string = marker("form_title_plant_destruction");
	submit_button = "Create Destruction Event";
	submit_button_translation_key: string = marker(
		"form_button_create_destruction_event",
	);

	submit_icon = "plus";

	validators = {};

	// Injected from other component
	private plant_id: number;
	private batch_id: number;
	private tag_id: number;
	private work_order_id: number;
	private destruction_lot_id: number;
	private result_type:
		| "work_order"
		| "plant"
		| "destruction_lot"
		| "tag"
		| "batch";

	private tag_query: any;

	private _pagerService = inject(PagerService);

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
		private readonly _cd: ChangeDetectorRef,
		private readonly _unitToGrams: UnitToGramsPipe,
		private readonly _globals: Globals,
	) {}

	valid(v) {
		this.valid$.next(v);
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.plant_id = this._injector.get("plant_id", null);

		this.batch_id = this._injector.get("batch_id", null);

		this.tag_id = this._injector.get("tag_id", null);
		this.tag_query = this._injector.get("query", null);

		this.work_order_id = this._injector.get("work_order_id", null);
		this.destruction_lot_id = this._injector.get("destruction_lot_id", null);
		this.result_type = this._injector.get("result_type", "destruction_lot");

		const plant_count = this._injector.get("plant_count", null);
		const weight = this._injector.get("weight", null);
		const destruction_reason_id = this._injector.get(
			"destruction_reason_id",
			null,
		);
		const is_duplicate = this._injector.get("is_duplicate", false);

		if (
			this.plant_id ||
			this.batch_id ||
			this.destruction_lot_id ||
			this.work_order_id
		) {
			// batch field
			this.schema.properties = {
				batch_id: {
					type: "number",
					title: "Batch",
					title_translation_key: marker("word_batch"),
					widget: "data-select",
					oneOf: [
						{
							result_type: "batches",
							queryString: { work_order_id: this.work_order_id },
						},
					],
				},
				plant_type: {
					type: "string",
					title: "Destroy By",
					title_translation_key: marker("form_field_label_destroy_by"),
					widget: "select",
					oneOf: [
						{
							name: "Plant Count",
							name_translation_key: marker("form_field_value_plant_count"),
							value: "PLANT_COUNT",
							enum: ["PLANT_COUNT"],
						},
						{
							name: "Plant ID(s)",
							name_translation_key: marker("form_field_value_plant_id_s"),
							value: "PLANT_IDS",
							enum: ["PLANT_IDS"],
						},
					],
				},
				plant_ids: {
					type: "array",
					title: "Select Plants",
					title_translation_key: marker("form_field_label_select_plants"),
					widget: "data-select",
					related_properties: ["batch_id"],
					items: {
						type: "number",
						oneOf: [
							{
								result_type: "plants",
								queryString: {
									plant_status: "ACTIVE",
								},
							},
						],
					},
					visibleIf: {
						plant_type: ["PLANT_IDS"],
					},
				},
				plant_count: {
					type: "number",
					title: "Number of Plants",
					minimum: 1,
					title_translation_key: marker("form_field_label_number_of_plants"),
					visibleIf: {
						plant_type: ["PLANT_COUNT"],
					},
				},
				...this.schema.properties,
			};

			if (this.plant_id) {
				this.model = {
					...this.model,
					plant_type: "PLANT_IDS",
					plant_ids: [this.plant_id],
				};
			}
			if (plant_count) {
				this.model = {
					...this.model,
					plant_type: "PLANT_COUNT",
					plant_count,
				};
			}
			if (weight) {
				this.model = { ...this.model, weight };
			}
			if (this.batch_id) {
				this.model = {
					...this.model,
					batch_id: this.batch_id,
				};
				this.schema.properties.batch_id.readOnly = !is_duplicate;
			}

			if (this.destruction_lot_id) {
				this.model = {
					...this.model,
					destruction_lot_id: this.destruction_lot_id,
				};
				this.schema.properties.destruction_lot_id.readOnly = !is_duplicate;
			}
			if (destruction_reason_id) {
				this.model = { ...this.model, destruction_reason_id };
			}

			if (this.work_order_id) {
				this.model = {
					...this.model,
					work_order_id: this.work_order_id,
				};
				this.schema.properties = {
					work_order_id: {
						type: "number",
						default: this.work_order_id,
						hidden: true,
					},
					...this.schema.properties,
				};
			}

			this._cd.detectChanges();
		}

		if (this.tag_id) {
			if (this.tag_id) {
				this.model = {
					...this.model,
					tag_id: this.tag_id,
					tag_query: this.tag_query,
				};

				this.schema.properties = {
					tag_id: {
						type: "number",
						title: "Tag",
						title_translation_key: marker("word_tag"),
						widget: "data-select",
						oneOf: [{ result_type: "tags" }],
						readOnly: true,
					},
					...this.schema.properties,
				};
			}

			this._cd.detectChanges();
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const destruction_event: Partial<IDestructionEvent> = {
			...this.model,
			weight: this.model.weight
				? this._unitToGrams.transform(this.model.weight, this.weight_unit)
				: 0,
			result_type: this.result_type,
		};
		this.addDestructionEvent(destruction_event);
	}

	onChanges(e): void {
		// empty
	}

	addDestructionEvent(add_destruction_event: Partial<IDestructionEvent>) {
		this.loading$.next(true);

		let query: Record<string, string | string[]> | undefined;
		switch (this.result_type) {
			case "work_order":
				query = WorkOrderDestructionEventsQuery;
				break;
			case "batch":
				query = BatchDetailQuery;
				break;
			case "plant":
				query = PlantDetailQuery;
				break;
			case "tag":
				query = TagDetailQuery;
				break;
		}

		this._itemService
			.add(
				`destruction_lots/${add_destruction_event.destruction_lot_id}/event/plants`,
				{ ...add_destruction_event, tag_query: this.tag_query ?? null },
				query,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(100000),
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((item) => {
					if (this.result_type === "destruction_lot") {
						this._store.dispatch(
							SelectionActions.select({
								result_type: "destruction_lots",
								id: add_destruction_event.destruction_lot_id!,
							}),
						);
					} else {
						let currentPlant;
						if (this.result_type === "plant" && Array.isArray(item)) {
							currentPlant = item.find((plant) => this.plant_id === plant.id);
						}
						this._store.dispatch(
							ItemActions.updateSuccess({
								updatedItem: currentPlant ?? item,
								result_type: this.pluralize(this.result_type),
							}),
						);

						if (this.result_type === "batch") {
							this._store
								.select(fromDashboard.getPlantPage)
								.pipe(
									takeUntil(this.destroyed$),
									isNotNullOrUndefined(),
									switchMap((page) => {
										return this._pagerService.fetchPage({
											...page,
											type: "batch",
											type_id: this.batch_id,
											result_type: "plants",
											page_type: "plants",
										});
									}),
								)
								.subscribe((updatePage) => {
									this._store.dispatch(
										FetchPageActions.fetchPageSuccess({
											payload: updatePage,
										}),
									);
								});
						}
					}
					this.loading$.next(false);
					this.closeSidenav();

					if (this.result_type === "batch") {
						if (canCloseBatch(item, this._globals.general_inventory_enabled)) {
							this._store.dispatch(
								layoutActions.openSidenav({ component: BatchCloseComponent }),
							);
						}
					}
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}

	private pluralize(
		item: "work_order" | "plant" | "destruction_lot" | "tag" | "batch",
	): ItemKeyType {
		if (item === "work_order") {
			return "work_orders";
		} else if (item === "plant") {
			return "plants";
		} else if (item === "destruction_lot") {
			return "destruction_lots";
		} else if (item === "tag") {
			return "tags";
		}

		return "batches";
	}
}
