import { createActionGroup, props } from "@ngrx/store";

import { ItemKeyType } from "../reducers/selection/keys";

export const SelectionActions = createActionGroup({
	source: "Selection",
	events: {
		select: props<{
			/**
			 * @deprecated Eventually everything should be set to false and then we can just get rid of this and use pluralized keys
			 */
			dontUseMorphKey?: boolean;
			result_type: ItemKeyType;
			id: string | number;
			query?: Record<string, string | string[]>;
		}>(),
	},
});
