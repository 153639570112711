// Basic copy of https://github.com/date-fns/date-fns/blob/4544f0cb1d724bfa7ee846b762bacb8fc303a961/src/isBefore/index.ts
export const isBefore = (date, dateToCompare) => {
  return date.getTime() < dateToCompare.getTime();
};
// Basic copy of https://github.com/date-fns/date-fns/blob/4544f0cb1d724bfa7ee846b762bacb8fc303a961/src/isEqual/index.ts
export const isEqual = (dateLeft, dateRight) => {
  return dateLeft.getTime() === dateRight.getTime();
};
export const isEqualOrBefore = (dateLeft, dateRight) => {
  return isEqual(dateLeft, dateRight) || isBefore(dateLeft, dateRight);
};
