/* jscpd:ignore-start */
import { skipWhile, takeUntil, tap, timeout, catchError } from "rxjs/operators";
import {
	Injector,
	Component,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ReplaySubject, EMPTY } from "rxjs";
import { Globals } from "app/shared/modules/globals/globals.service";
import {
	IDestructionLot,
	GroupedDestructionEventUpdate,
	MeasureUnit,
	round,
	UserProfile,
} from "@elevatedsignals/amygoodman";
import { BaseMeasureUnitToUnitPipe } from "app/modules/dashboard/modules/es-pipes/units/base_measure_unit_to_unit.pipe";
import { UnitToBaseMeasureUnitPipe } from "app/modules/dashboard/modules/es-pipes/units/unit_to_base_measure_unit.pipe";
import { TitleCasePipe } from "@angular/common";
import { handleObservableError } from "app/shared/utils";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

import { UpdateReasonComponent } from "../generic/update-reason.component";
/* jscpd:ignore-end */

@Component({
	selector: "grouped-destruction-event-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class GroupedDestructionEventUpdateComponent
	extends GenericUpdateComponent<GroupedDestructionEventUpdate>
	implements OnInit, OnDestroy
{
	event;
	destruction_lot: IDestructionLot;

	form_title = "Update a Destruction Event";
	form_title_translation_key: string = marker(
		"form_title_update_a_destruction_event",
	);

	submit_button = "Update";
	submit_button_translation_key: string = marker("word_update");
	submit_icon = "edit";

	model: any = {};
	weight_unit: string = this._globals.weight_unit;
	measure_unit: MeasureUnit = MeasureUnit.Mass;

	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	loading_error$: ReplaySubject<string> = new ReplaySubject<string>();
	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	user: UserProfile;
	user$ = this._store.select(fromDashboard.getProfile);

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			group: {
				type: "string",
				hidden: true,
			},
			batch_id: {
				type: "number",
				hidden: true,
			},
			measure_unit: {
				type: "string",
				title: "Measure Type",
				title_translation_key: marker("form_field_label_measure_type"),
				widget: "select",
				oneOf: [
					{
						name: this._titleCasePipe.transform(MeasureUnit.Mass),
						name_translation_key: marker("word_mass"),
						value: MeasureUnit.Mass,
						enum: [MeasureUnit.Mass],
					},
					{
						name: this._titleCasePipe.transform(MeasureUnit.Volume),
						name_translation_key: marker("word_volume"),
						value: MeasureUnit.Volume,
						enum: [MeasureUnit.Volume],
					},
					{
						name: this._titleCasePipe.transform(MeasureUnit.Quantity),
						name_translation_key: marker("word_quantity"),
						value: MeasureUnit.Quantity,
						enum: [MeasureUnit.Quantity],
					},
				],
				default: MeasureUnit.Mass,
				readOnly: true,
			},
			weight: {
				type: "number",
				step: 0.0001,
				title: `Total (${this.weight_unit})`,
				title_translation_key: marker("form_field_label_total"),
				title_translation_params: { units: this.weight_unit },
			},
			substance_type_id: {
				type: "number",
				title: "Substance Type",
				title_translation_key: marker("word_substance_type"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "substance_types",
					},
				],
			},
			destruction_reason_id: {
				type: "number",
				title: "Destruction Reason",
				title_translation_key: marker("word_destruction_reason"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "destruction_reasons",
					},
				],
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
			timestamp: {
				type: "string",
				title: "Collected On",
				title_translation_key: marker("form_field_label_collected_on"),
				widget: "date",
			},
		},
		required: ["timestamp", "group", "weight", "destruction_reason_id"],
	};

	constructor(
		_store: Store<fromDashboard.State>,
		_cd: ChangeDetectorRef,
		private readonly _injector: Injector,
		private readonly _itemService: ItemService,
		private readonly _baseMeasureUnitToUnitPipe: BaseMeasureUnitToUnitPipe,
		private readonly _unitToBaseMeasureUnitPipe: UnitToBaseMeasureUnitPipe,
		private readonly _globals: Globals,
		private readonly _titleCasePipe: TitleCasePipe,
	) {
		super(_store, _cd);

		this.user$
			.pipe(
				takeUntil(this.destroyed$),
				skipWhile((user: UserProfile | null) => user == null),
			)
			.subscribe((user) => {
				if (user) {
					this.user = user;
				}
			});
	}

	valid(val) {
		this.valid$.next(val);
	}

	onChanges(_error): void {}

	onError(_error): void {}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
			this.schema.required = ["group", "weight", "destruction_reason_id"];
		}

		this.event = this._injector.get("event", null);
		this.destruction_lot = this._injector.get("destruction_lot", null);

		this.model.group = this.event.group;
		this.model.batch_id = this.event.batch_id;

		if (this.event.plant_ids) {
			this.measure_unit = MeasureUnit.Mass;
			this.model.weight = Number(
				this._baseMeasureUnitToUnitPipe.transform(
					round(this.event.weight * this.event.group_events_count, 4),
					this.measure_unit,
					this._globals.getFacilityUnit(this.measure_unit),
				),
			);
		} else if (this.event.measure_event) {
			this.measure_unit = this.event.measure_event.measure_unit;
			this.model.weight = Number(
				this._baseMeasureUnitToUnitPipe.transform(
					this.event.measure_event.value,
					this.measure_unit,
					this._globals.getFacilityUnit(this.measure_unit),
				),
			);
		}
		this.model.measure_unit = this.measure_unit;
		this.schema.properties.weight.title = `Total (${this._globals.getFacilityUnit(
			this.measure_unit,
		)})`;
		this.schema.properties.weight.title_translation_params = {
			units: this._globals.getFacilityUnit(this.measure_unit),
		};

		if (this.measure_unit === MeasureUnit.Quantity) {
			this.schema.properties.weight.step = 1;
		}

		// @deprecated
		if (this.event.measure_event) {
			this.model.description = this.event.measure_event.notes
				? this.event.measure_event.notes[0].content
				: null;
			this.model.substance_type_id = this.event.measure_event.substance_type
				? this.event.measure_event.substance_type.id
				: undefined;
		}
		if (this.event.destruction_reason_id) {
			this.model.destruction_reason_id = this.event.destruction_reason_id;
		}
		this.model.timestamp = this.event.event_date;

		if (this.event.plant_ids) {
			delete this.schema.properties.substance_type_id;
			delete this.schema.properties.measure_unit;
			this.schema.properties.plant_ids = {
				type: "array",
				title: "Plants Destroyed",
				title_translation_key: marker("form_field_label_plants_destroyed"),
				widget: "data-select",
				related_properties: ["batch_id"],
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "plants",
							queryString: {
								plant_status: "ACTIVE",
							},
						},
					],
				},
			};
			if (this._globals.gmp_enabled) {
				this.schema.order = [
					"group",
					"batch_id",
					"weight",
					"plant_ids",
					"destruction_reason_id",
					"description",
				];
			} else {
				this.schema.order = [
					"group",
					"batch_id",
					"timestamp",
					"weight",
					"plant_ids",
					"destruction_reason_id",
					"description",
				];
			}
			this.model.plant_ids = this.event.plant_ids
				.split(",")
				.map((id) => Number(id));
		}
		if (Array.isArray(this.event.notes) && this.event.notes.length > 0) {
			this.model.description = this.event.notes[0].content;
		}
		this._cd.detectChanges();
	}

	onSubmit() {
		const destruction_event: GroupedDestructionEventUpdate = {
			...this.model,
			weight: this._unitToBaseMeasureUnitPipe.transform(
				this.model.weight,
				this._globals.getFacilityUnit(
					this.model.measure_unit || this.measure_unit,
				) ?? "",
				this.model.measure_unit || this.measure_unit,
			),
		};

		this._store.dispatch(
			layoutActions.openSidenav({
				component: UpdateReasonComponent,
				inputs: {
					onSubmit: (reason, password) => {
						this.updateItem(destruction_event, reason, password);
					},
					form_title: "Update Reason",
					form_title_translation_key: marker("form_title_update_reason"),
					submit_button: "Update",
					submit_button_translation_key: marker("word_update"),
					update_reason_field_label_translation_key: marker(
						"form_field_label_update_reason",
					),
					gmpAskPassword: this._globals.gmp_enabled,
				},
			}),
		);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}

	updateItem(
		updateDestructionEvent: GroupedDestructionEventUpdate,
		reason?: string,
		password?: string,
	) {
		const authObject = password
			? { username: this.user.email, password }
			: undefined;

		this.loading$.next(true);
		this._itemService
			.update(
				`destruction_lots/${this.destruction_lot.id}/events/group`,
				this.event.group,
				{
					...updateDestructionEvent,
					reason,
				},
				{},
				authObject,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((item) => {
					this._store.dispatch(
						SelectionActions.select({
							result_type: "destruction_lots",
							id: this.destruction_lot.id,
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
