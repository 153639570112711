<div class="ui grid dirty-mackenzie">
	<div class="column">
		<div class="ui feed" *ngIf="auditLog$ | async as auditLog">
			<div class="event" *ngFor="let log of auditLog as logs; let i = index">
				<div class="label">
					<a class="ui circular label" *ngIf="log.user">
						{{ getInitials(log.user.first_name, log.user.last_name) }}
					</a>
				</div>
				<div class="content">
					<div class="date">
						{{ log.created_at | formatDateTime }} ({{
						parseRelativeDate(log.created_at) }})
					</div>
					<div class="summary" *ngIf="getHistorySummary(log) as summary">
						{{ log.user.first_name}} {{ log.user.last_name }} {{summary}} this measure
						event.
						<span style="font-weight: normal">
							<br />
							Value: {{ formatMeasureData(log) }}
							<br />
							Date: {{ log.data.timestamp | formatDateTime }}
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
