import { ISopCategory } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.SopCategories;

export type State = SelectionState<ISopCategory>;

export const reducer = createSelectionReducer<ISopCategory>(reducerKey);
