import { HeaderQueryType, IPeriod, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { getYear } from "date-fns";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "periods";

export type State = Page<IPeriod>;

export const getListOfYears = (numOfYears = 5) => {
	const currentYear = getYear(new Date());
	const years: number[] = [];
	for (let i = 0; i < numOfYears; i++) {
		years.push(currentYear - i);
	}
	return years;
};

export const reducer = createPagingReducer<IPeriod>(reducerKey, {
	result_type: "periods",
	title: "Period",
	title_translation_key: marker("page_title_periods"),
	title_plural_translation_key: marker("page_title_plural_periods"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Year",
			title_translation_key: marker("checkbox_legacy_reporting_periods"),
			key: "year",
			type: HeaderQueryType.select,
			options: getListOfYears().map((year) => {
				return {
					label: `${year}`,
					value: `${year}`,
				};
			}),
			value: `${getYear(new Date())}`,
		},
	],
});
