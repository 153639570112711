import { Component, OnInit, OnDestroy, Injector } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { handleObservableError } from "app/shared/utils";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { ReplaySubject, EMPTY } from "rxjs";
import { IRole } from "@elevatedsignals/amygoodman";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

@Component({
	selector: "role-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class RoleCreateComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Create a Role";
	form_title_translation_key: string = marker("form_title_create_a_role");
	submit_button = "Create Role";
	submit_button_translation_key: string = marker("form_button_create_role");
	submit_icon = "add";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	specific_field_id: string;

	model: any = {};

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
		},
		required: ["name"],
	};

	validators = {};

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		this.specific_field_id = this._injector.get("specific_field_id", null);
	}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		this.addRole(this.model);
	}

	onChanges(_model): void {}

	addRole(new_role: IRole) {
		this.loading$.next(true);
		this._itemService
			.add("roles", new_role)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							addedItem,
							result_type: "roles",
							...(this.specific_field_id && {
								specific_field_id: this.specific_field_id,
							}),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
