import { Component, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { ReplaySubject, EMPTY } from "rxjs";
import { FormSchema } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { FormSchemaDetailQuery } from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "schema-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class SchemaCreateComponent
	extends GenericCreateComponent<FormSchema>
	implements OnDestroy
{
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Create a Form Schema";
	form_title_translation_key: string = marker("form_title_create_a_form_schema");
	submit_button = "Create Schema";
	submit_button_translation_key: string = marker("form_button_create_schema");
	submit_icon = "add";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: Form Schema name already in use.",
				warning_translation_key: marker(
					"form_field_warning_form_schema_name_already_in_use",
				),
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
			},
			sop_id: {
				type: "number",
				title: "Related SOP",
				title_translation_key: marker("form_field_label_related_sop"),
				widget: "data-select",
				oneOf: [{ result_type: "sops" }],
			},
			allow_backdate: {
				type: "boolean",
				title: "Allow Backdate",
				title_translation_key: marker("form_field_label_allow_backdate"),
				widget: "checkbox",
				description:
					"When checked, users have the option to manually set the record's submission date.",
				description_translation_key: marker(
					"form_field_description_when_checked_users_have_the_option_to_manually_set_the_records_submission_date",
				),
			},
		},
		required: ["name"],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private names;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _globals: Globals,
	) {
		super(_store);
	}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.allow_backdate;
		}

		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`schemas/fetch/names`, "", { col: "name" })
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		this.createItem(this.model);
	}

	onChanges(_model): void {}

	createItem(new_schema: FormSchema) {
		this.loading$.next(true);
		this._itemService
			.add("schemas", new_schema, FormSchemaDetailQuery)
			.pipe(
				takeUntil(this.destroyed$),
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err, true));
					this.loading$.next(false);
					return EMPTY;
				}),
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							addedItem,
							result_type: "schemas",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
