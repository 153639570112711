import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import {
	timeout,
	catchError,
	take,
	takeUntil,
	tap,
	filter,
} from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import { IApplicationMethod } from "@elevatedsignals/amygoodman";
import { ApplicationMethodDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

@Component({
	selector: "application-method-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class ApplicationMethodUpdateComponent
	extends GenericUpdateComponent<IApplicationMethod>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
			rate_type_id: {
				type: "number",
				title: "Rate Type",
				title_translation_key: marker("word_rate_type"),
				widget: "data-select",
				oneOf: [{ result_type: "rate_types" }],
			},
		},
		required: ["name"],
	};

	application_method$ = this._store.select(
		fromDashboard.getSelectedApplicationMethod,
	);

	application_method: IApplicationMethod;
	id: number;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.form_title = "Update an Application Method";
		this.form_title_translation_key = marker(
			"form_title_update_an_application_method",
		);
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
		this.id = this._injector.get("id", null);
	}

	ngOnInit() {
		this.application_method$
			.pipe(
				takeUntil(this.destroyed$),
				isNotNullOrUndefined(),
				filter(
					(application_method): application_method is IApplicationMethod =>
						application_method.id === this.id,
				),
				take(1),
			)
			.subscribe((application_method) => {
				this.application_method = application_method;
				this.model.id = this.application_method.id;
				this.model.name = this.application_method.name;
				this.model.archived = this.application_method.archived;
				this.model.rate_type_id = this.application_method.rate_type_id;
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(method: Partial<IApplicationMethod>) {
		this.loading$.next(true);
		this._itemService
			.update(
				`application_method`,
				method.id!,
				method,
				ApplicationMethodDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "application_methods",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
