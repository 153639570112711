import { IWorkOrderPendingInventory } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.PendingInventories;

export type State = SelectionState<IWorkOrderPendingInventory>;

export const reducer =
	createSelectionReducer<IWorkOrderPendingInventory>(reducerKey);
