import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { User } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { combineLatest, EMPTY, NEVER, race, ReplaySubject } from "rxjs";
import { timeout, takeUntil, filter, tap, catchError } from "rxjs/operators";
import { State as DashboardState } from "app/modules/dashboard/state/dashboard.state";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";

@Component({
	selector: "user-password-change",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class UserPasswordChangeComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	user$ = this._store.select(fromDashboard.getSelectedUser);
	profileId$ = this._store.select(fromDashboard.getProfileId);
	sessionUser$ = this._store.select(fromDashboard.getProfile);
	user: User;
	session_user: any;

	form_title = "Change your Password";
	form_title_translation_key: string = marker("form_title_change_your_password");
	submit_button = "Change Password";
	submit_button_translation_key: string = marker("form_button_change_password");
	submit_icon = "edit";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				title: "Id",
				hidden: true,
			},
			email: {
				type: "string",
				title: "Email",
				title_translation_key: marker("word_email"),
				widget: "string",
				readOnly: true,
				hidden: false,
			},
			first_name: {
				type: "string",
				title: "First Name",
				title_translation_key: marker("word_first_name"),
				widget: "string",
				readOnly: true,
			},
			last_name: {
				type: "string",
				title: "Last Name",
				title_translation_key: marker("word_last_name"),
				widget: "string",
				readOnly: true,
			},
			current_password: {
				type: "string",
				title: "Current Password",
				title_translation_key: marker("form_field_label_current_password"),
				widget: "string",
				isPassword: true,
			},
			new_password: {
				type: "string",
				title: "New Password",
				title_translation_key: marker("form_field_label_new_password"),
				widget: "string",
				isPassword: true,
				warning: "New password must be between 4 and 55 characters long",
				warning_translation_key: marker(
					"form_warning_new_password_must_be_between",
				),
				minLength: 4,
				maxLength: 55,
			},
			new_password_confirm: {
				type: "string",
				title: "Confirm New Password",
				title_translation_key: marker("form_field_label_confirm_new_password"),
				widget: "string",
				isPassword: true,
				minLength: 4,
				maxLength: 55,
				warning: "Both new passwords must match",
				warning_translation_key: marker(
					"form_warning_both_new_passwords_must_match",
				),
				visibleIf: { new_password: [true] },
				valid: false,
			},
		},
		required: [
			"id",
			"email",
			"current_password",
			"new_password",
			"new_password_confirm",
		],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/new_password_confirm": (value, property, form) => {
			this.confirmPasswordValidatorFailed = false;

			const passwordsDoNotMatch =
				value !== "" && this.model.new_password !== value;

			if (passwordsDoNotMatch) {
				this.confirmPasswordValidatorFailed = true;
				const error = {
					code: "NO_MATCH",
					path: `#${property.path}`,
					message: "both passwords should match",
					params: ["new_password_confirm"],
				};

				return [error];
			}
			return null;
		},
	};

	private confirmPasswordValidatorFailed: boolean;

	constructor(
		private readonly _store: Store<DashboardState>,
		private readonly _itemService: ItemService,
	) {}

	valid(v) {
		this.valid$.next(v);

		if (this.confirmPasswordValidatorFailed) {
			this.valid$.next(false);
		}
	}

	ngOnInit() {
		race(
			combineLatest([this.profileId$, this.sessionUser$]).pipe(
				takeUntil(this.destroyed$),
				filter((values) => values[1] != null && values[0] != null),
			),
			NEVER.pipe(timeout(10000)),
		).subscribe(
			(values) => {
				this.session_user = values[1];

				this.model = {
					...this.model,
					id: this.session_user.id,
					email: this.session_user.email,
					first_name: this.session_user.first_name,
					last_name: this.session_user.last_name,
					current_password: "",
					new_password: "",
					new_password_confirm: "",
				};
			},
			(error) => {
				// eslint-disable-next-line no-console
				console.error(error);
				this.error$.next(handleObservableError(error));
				return EMPTY;
			},
		);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const { id, email, current_password, new_password } = this.model;

		const user = {
			id,
			email,
			current_password,
			new_password,
		} as Partial<User>;
		this.updateUser(user);
	}

	onChanges(_model): void {}

	updateUser(update_user: Partial<User>) {
		this.loading$.next(false);
		const query: Record<string, string | string[]> = {};
		query.eager = "[roles]";
		this._itemService
			.update(`user`, `${this.session_user.id}/password`, update_user, query)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "users",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
