import { Component, Input, OnDestroy } from "@angular/core";
import { Batch } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { ReplaySubject, EMPTY } from "rxjs";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";

import { UnitToGramsPipe } from "../../es-pipes/unit-to-grams.pipe";

// eslint-disable-next-line @typescript-eslint/naming-convention, @shopify/typescript/prefer-pascal-case-enums
export enum Harvest_Type {
	// eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums
	dry_weight,
	// eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums
	wet_weight,
	// eslint-disable-next-line @shopify/typescript/prefer-pascal-case-enums
	destruction,
}

@Component({
	selector: "es-harvest",
	templateUrl: "./index.html",
	styleUrls: ["./index.scss"],
})
export class HarvestComponent implements OnDestroy {
	@Input() harvest_type: Harvest_Type;
	@Input() batch: Batch;
	@Input() weight_unit: string = this._globals.weight_unit;

	// So we can reference this in our template
	// eslint-disable-next-line @typescript-eslint/naming-convention
	Harvest_Type = Harvest_Type;
	harvest_weight: number;
	harvest_tared = false;
	harvest_bin_weight: number;

	private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

	// Harvest
	private readonly harvest_loading$ = new ReplaySubject<boolean>();
	private readonly harvest_error$ = new ReplaySubject<string | null>();

	constructor(
		private readonly _itemService: ItemService,
		private readonly _store: Store<fromDashboard.State>,
		private readonly _unitToGrams: UnitToGramsPipe,
		private readonly _globals: Globals,
	) {
		// empty
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
	}

	get weights(): any[] {
		if (this.harvest_type === Harvest_Type.wet_weight) {
			return this.batch.wet_weights ?? [];
		} else if (this.harvest_type === Harvest_Type.dry_weight) {
			return this.batch.dry_weights ?? [];
		} else {
			return [];
		}
	}

	get isWet(): boolean {
		return this.harvest_type === Harvest_Type.wet_weight;
	}

	wetWeighingInProgress(batch: Batch) {
		return batch.wet_weights && !batch.harvested;
	}

	dryWeighingInProgress(batch: Batch) {
		return batch.dry_weights && !batch.dry_weight;
	}

	wetWeights(batch: Batch) {
		return batch.wet_weights ? batch.wet_weights : [];
	}

	dryWeights(batch: Batch) {
		return batch.dry_weights ? batch.dry_weights : [];
	}

	getTared(partial_weight: any) {
		return partial_weight.tared ? "Yes" : "No";
	}

	getBinWeight(partial_weight: any) {
		return partial_weight.bin_weight ? partial_weight.bin_weight : "--";
	}

	getTotalWeight(partial_weight: any) {
		return partial_weight.tared
			? partial_weight.weight
			: partial_weight.weight - partial_weight.bin_weight;
	}

	getTotalMaterialWeight(harvest_weights: any[]) {
		let total = 0;
		for (const partial_weight of harvest_weights) {
			total += this.getTotalWeight(partial_weight);
		}
		return total;
	}

	calcTotalWeight() {
		if (this.harvest_weight && this.harvest_tared) {
			return this.harvest_weight;
		} else if (
			this.harvest_weight &&
			!this.harvest_tared &&
			this.harvest_bin_weight
		) {
			return this.harvest_weight - this.harvest_bin_weight;
		} else {
			return "--";
		}
	}

	harvestValid() {
		if (this.harvest_weight && this.harvest_tared) {
			return true;
		} else if (
			this.harvest_weight &&
			!this.harvest_tared &&
			this.harvest_bin_weight
		) {
			return this.harvest_weight >= this.harvest_bin_weight;
		} else {
			return false;
		}
	}

	onHarvestAdd(batch: Batch) {
		if (this.harvestValid()) {
			const partial_harvest = {
				batch_id: batch.id!,
				weight: this._unitToGrams.transform(this.harvest_weight, this.weight_unit),
				tared: this.harvest_tared,
				bin_weight: this.harvest_tared
					? undefined
					: this._unitToGrams.transform(this.harvest_bin_weight, this.weight_unit),
			};
			this.addHarvest(partial_harvest);
		}
	}

	onHarvestDelete(batch: Batch, index: number) {
		const partial_delete = {
			batch_id: batch.id!,
			index,
		};
		this.deleteHarvest(partial_delete);
	}

	addHarvest(partial_harvest: {
		batch_id: number;
		weight: number;
		tared: boolean;
		plant_ids?: number[];
		plant_count?: number;
	}) {
		this.harvest_loading$.next(true);
		const query: Record<string, string | string[]> = {};
		query.eager =
			"[tags, location.sensors.[capabilities, attachments], cultivar, sensors.[capabilities, attachments], growth_stages, production_stage, parent_batches, child_batches]";
		this._itemService
			.update(
				`batch`,
				`${partial_harvest.batch_id}/${this.isWet ? "harvest" : "dried"}/add`,
				partial_harvest,
				query,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.harvest_error$.next(handleObservableError(error, true));
					this.harvest_loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this.harvest_error$.next(null);
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "batches",
						}),
					);
					this.harvest_weight = 0;
					this.harvest_bin_weight = 0;
					this.harvest_loading$.next(false);
				}),
			)
			.subscribe();
	}

	deleteHarvest(partial_delete: { batch_id: number; index: number }) {
		this.harvest_loading$.next(true);
		const query: Record<string, string | string[]> = {};
		query.eager =
			"[tags, location.sensors.[capabilities, attachments], cultivar, sensors.[capabilities, attachments], growth_stages, production_stage, parent_batches, child_batches]";
		this._itemService
			.update(
				`batch`,
				`${partial_delete.batch_id}/${this.isWet ? "harvest" : "dried"}/delete`,
				partial_delete,
				query,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.harvest_error$.next(handleObservableError(error, true));
					this.harvest_loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this.harvest_error$.next(null);
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "batches",
						}),
					);
					this.harvest_weight = 0;
					this.harvest_bin_weight = 0;
					this.harvest_loading$.next(false);
				}),
			)
			.subscribe();
	}
}
