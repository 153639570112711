import { Hub } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.Hubs;

export type State = SelectionState<Hub>;

export const reducer = createSelectionReducer<Hub>(reducerKey);
