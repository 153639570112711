/* jscpd:ignore-start */
import { Component, OnInit, OnDestroy, Injector } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ReplaySubject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { PasswordInputComponent } from "app/modules/auth/components/password-input";
import { IDestructionLot } from "@elevatedsignals/amygoodman";
import { Globals } from "app/shared/modules/globals/globals.service";
import {
	getDestructionEventContent,
	getDestructionEventTitle,
} from "app/shared/utils";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

import { DestructionLotCloseComponent } from "./lot-close.component";
/* jscpd:ignore-end */

@Component({
	selector: "destruction-lot-close-checklist",
	template: `
		<div class="ui grid" *transloco="let t">
			<div class="sixteen wide column">
				<div class="left floated left aligned eight wide column">
					<button class="ui labeled icon button" (click)="closeSidenav()">
						<i class="left arrow icon"></i>
						{{ "word_cancel" | transloco }}
					</button>
				</div>
			</div>
			<div class="sixteen wide column">
				<h2>
					{{
						form_title_translation_key ? t(form_title_translation_key) : form_title
					}}
				</h2>
			</div>

			<div *ngFor="let event of destruction_events" class="sixteen wide column">
				<fui-checkbox [(ngModel)]="event.checked" (checkChange)="checkChange()">
					<span style="font-size: .92857143em; font-weight: 700;">
						{{ event.title }}
					</span>

					<span> - {{ event.description }} </span>
				</fui-checkbox>
			</div>
			<br />

			<div class="sixteen wide column" *ngIf="error$ | async">
				<div class="ui error message">
					<span>{{ error$ | async }}</span>
				</div>
			</div>

			<div class="sixteen wide column">
				<button
					class="ui button blue"
					[class.disabled]="!all_events_checked"
					(click)="onSubmit()"
				>
					<span>
						<i *ngIf="submit_icon" class="{{ submit_icon }} icon"></i>
						{{
							submit_button_translation_key
								? t(submit_button_translation_key)
								: submit_button
						}}
					</span>
				</button>
			</div>
		</div>
	`,
	styleUrls: ["../sidenav.scss"],
})
export class DestructionLotCloseChecklistComponent
	implements OnInit, OnDestroy
{
	destruction_lot$ = this._store.select(fromDashboard.getSelectedDestructionLot);
	destruction_events: any[] = [];

	form_title = "Confirm Destruction Lot Closure";
	form_title_translation_key: string = marker(
		"form_title_confirm_destruction_lot_closure",
	);

	submit_button = "Close Lot";
	submit_button_translation_key: string = marker("form_button_close_lot");
	submit_icon = "edit";

	error$: ReplaySubject<string> = new ReplaySubject();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	all_events_checked = false;
	parentOnSubmit;
	showFinalWeights = false;

	private readonly minDate: Date;

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
	) {
		this.parentOnSubmit = this._injector.get("onSubmit", null);
		this.minDate = this._injector.get("minDate", null);
		this.showFinalWeights = this._injector.get("showFinalWeights", null);
	}

	ngOnInit() {
		this.destruction_lot$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((destruction_lot: IDestructionLot) => {
				for (const event of destruction_lot.destruction_events_by_group ?? []) {
					this.destruction_events.push({
						checked: false,
						title: getDestructionEventContent(event, this._globals.weight_unit),
						description: getDestructionEventTitle(event),
					});
				}
				this.checkChange();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	checkChange() {
		this.all_events_checked = this.destruction_events.every(
			(event) => event.checked,
		);
	}

	onSubmit() {
		this.closeSidenav();

		this._store.dispatch(
			layoutActions.openSidenav({
				component: DestructionLotCloseComponent,
				inputs: {
					showFinalWeights: this.showFinalWeights,
					minDate: this.minDate,
				},
			}),
		);
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
