import { UserProfile } from "@elevatedsignals/amygoodman";
import { createReducer, on } from "@ngrx/store";
import { AuthActions } from "app/modules/auth/actions/auth.actions";

import { ProfileActions } from "../actions/profile.actions";

export interface State {
	profile: UserProfile | null;
	api: {
		fetch: {
			error?: string | null;
			in_progress: boolean;
		};
		update: {
			error?: string | null;
			in_progress: boolean;
		};
	};
}

const initialState: State = {
	profile: null,
	api: {
		fetch: {
			in_progress: false,
		},
		update: {
			in_progress: false,
		},
	},
};

export const reducer = createReducer(
	initialState,
	on(ProfileActions.fetch, (state) => ({
		...state,
		api: { ...state.api, fetch: { in_progress: true } },
	})),
	on(ProfileActions.fetchSuccess, (state, updateValue) => ({
		...state,
		api: { ...state.api, fetch: { in_progress: false } },
		profile: updateValue.payload,
	})),
	on(ProfileActions.fetchFailed, (state, value) => ({
		...state,
		api: { ...state.api, fetch: { in_progress: false, error: value.error } },
	})),
	on(ProfileActions.update, (state) => ({
		...state,
		api: { ...state.api, update: { in_progress: true } },
	})),
	on(ProfileActions.updateSuccess, (state, updateValue) => ({
		...state,
		api: { ...state.api, update: { in_progress: false } },
		profile: updateValue.payload,
	})),
	on(ProfileActions.fetchFailed, (state, value) => ({
		...state,
		api: { ...state.api, update: { in_progress: false, error: value.error } },
	})),
	on(AuthActions.logoutSuccess, () => initialState),
);

/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */

export const getProfile = (state: State) => state.profile;
export const getId = (state: State) =>
	state.profile ? state.profile.id : null;
export const getFacilityId = (state: State) =>
	state.profile ? state.profile.facility_id : null;

export const getFetchInProgress = (state: State) => state.api.fetch.in_progress;
export const getUpdateInProgress = (state: State) =>
	state.api.update.in_progress;

export const getFetchError = (state: State) => state.api.fetch.error;
export const getUpdateError = (state: State) => state.api.update.error;
