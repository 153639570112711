export const strings = {
	en: {
		networking: {
			not_found: `Hmm, looks like we're trying to access something that doesn't exist. This may be a mistake on our end. If this problem continues to persist please contact support.`,
			taking_to_long: `Hmm, looks like the server is taking to long to respond, this can be caused by either poor connectivity or an error with our servers. Please try again in a moment. If this problem continues to persist please contact support.`,
			problem_loading: (r) =>
				`Hmm, looks like we're having trouble loading this ${r}. Perhaps it doesn't exist? If you believe this is an error please contact support.`,
			other: "I'm sorry Dave, I'm afraid I can't do that — HAL",
		},
		new: {
			not_found: `Hmm, looks like we're trying to access something that doesn't exist. If this problem persists, please contact our support team.`,
			time_out_try_again: `Hmm, this is taking a long time. Please try again in a moment - if this problem persists, please contact our support team.`,
			time_out_wait: `Hmm, this is taking a long time. Please wait a few moments, then try refreshing the page to see if your changes have been applied. `,
			problem_loading: `It looks like we're having trouble loading this section. If this problem persists, please contact our support team.`,
			other:
				"Sorry, something's gone wrong. If this problem persists, please contact our support team.",
		},
	},
};
