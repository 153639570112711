import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Component, OnDestroy, Injector } from "@angular/core";
import { IRateType } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "rate-type-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class RateTypeCreateComponent
	extends GenericCreateComponent<IRateType>
	implements OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
		},
		required: ["name"],
	};

	specific_field_id: string;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store);
		this.form_title = "Create a Rate Type";
		this.form_title_translation_key = marker("form_title_create_a_rate_type");
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
		this.specific_field_id = this._injector.get("specific_field_id", null);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	createItem(rate_type: Partial<IRateType>) {
		this.loading$.next(true);
		this._itemService
			.add(`rate_types`, rate_type)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							addedItem,
							result_type: "rate_types",
							...(this.specific_field_id && {
								specific_field_id: this.specific_field_id,
							}),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
