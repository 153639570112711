import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import {
	Component,
	ChangeDetectorRef,
	OnDestroy,
	OnInit,
	Injector,
} from "@angular/core";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { PasswordInputComponent } from "app/modules/auth/components/password-input";
import { gmpEnabled } from "app/modules/dashboard/selectors/facility-settings.selector";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericUpdateComponent } from "./generic-update.component";

@Component({
	selector: "update-reason",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class UpdateReasonComponent
	extends GenericUpdateComponent<any>
	implements OnInit, OnDestroy
{
	parentSubmit;
	gmpAskPassword;
	extraWarning;
	extraWarningTranslationKey;

	gmpEnabled$ = this._store.select(gmpEnabled);
	gmpEnabled = false;
	requireUpdateReason = false;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.form_title = "Update Reason";
		this.form_title_translation_key = marker("form_title_update_reason");
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");

		this.schema = {
			title: "",
			description: ``,
			info: "",
			properties: {
				reason: {
					type: "string",
					title: "Update Reason",
					title_translation_key: marker("form_field_label_update_reason"),
					widget: "textarea",
				},
			},
		};
		this.parentSubmit = this._injector.get("onSubmit", null);
		this.gmpAskPassword = this._injector.get("gmpAskPassword", true);

		this.form_title = this._injector.get("form_title") ?? this.form_title;
		this.form_title_translation_key =
			this._injector.get("form_title_translation_key", null) ??
			this.form_title_translation_key;

		this.submit_button =
			this._injector.get("submit_button") ?? this.submit_button;
		this.submit_button_translation_key =
			this._injector.get("submit_button_translation_key", null) ??
			this.submit_button_translation_key;

		this.schema.properties.reason.title = `${
			this._injector.get("submit_button") ?? this.submit_button
		} Reason`;
		this.schema.properties.reason.title_translation_key =
			this._injector.get("update_reason_field_label_translation_key", null) ??
			marker("form_field_label_update_reason");

		this.extraWarning = this._injector.get("extraWarning", null);
		this.extraWarningTranslationKey = this._injector.get(
			"extraWarningTranslationKey",
			null,
		);
		this.requireUpdateReason = this._injector.get("require_update_reason", false);

		if (this.extraWarning && this.extraWarningTranslationKey) {
			this.schema.properties = {
				...this.schema.properties,
				warning_section: {
					type: "string",
					title: this.extraWarning,
					title_translation_key: this.extraWarningTranslationKey,
					widget: "sub-section",
				},
			};
		}
		this.gmpEnabled$.subscribe((enabled) => {
			this.gmpEnabled = enabled;
		});
	}

	ngOnInit() {
		if (this.gmpEnabled || this.requireUpdateReason) {
			this.schema.required = ["reason"];
		}
	}

	updateItem(formData) {
		if (this.gmpEnabled && this.gmpAskPassword) {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: PasswordInputComponent,
					inputs: {
						onSubmit: (password) => {
							this.parentSubmit(formData.reason, password);
							this.closeSidenav();
						},
					},
				}),
			);
		} else {
			this.parentSubmit(formData.reason);
			this.closeSidenav();
		}
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}
}
