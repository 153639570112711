import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { UntypedFormGroup, FormBuilder } from "@angular/forms";
import { UserProfile } from "@elevatedsignals/amygoodman";

import { UserSignin } from "../../models/user-signin.model";

@Component({
	selector: "es-signin-input",
	template: `
		<div *ngIf="showLogo" class="logo"></div>

		<form [formGroup]="signinForm" (ngSubmit)="onSubmit()" class="ui form">
			<div class="field login">
				<input required formControlName="email" type="email" placeholder="Email" />
			</div>

			<div class="field login">
				<input
					required
					formControlName="password"
					type="password"
					placeholder="Password"
				/>
			</div>

			<div *ngIf="errorMessage" class="ui negative message">
				<p>{{ errorMessage }}</p>
			</div>

			<button
				class="ui"
				[ngClass]="
					pending ? 'loading button disabled blue login' : 'button blue login'
				"
				type="submit"
				[disabled]="!signinForm.valid"
			>
				Sign In
			</button>

			<p class="signup">
				<span class="blend">Forgot your password?</span>
				<a routerLink="/forgot-password"> Reset Password</a>
			</p>
		</form>
	`,

	styleUrls: ["./index.scss"],
})
export class SigninInputComponent implements OnInit {
	signinForm: UntypedFormGroup;

	@Input() errorMessage: string;
	@Input() pending: boolean;
	@Input() showLogo = true;
	@Input() email: string | undefined;

	@Output() signin = new EventEmitter<any>();

	constructor(private readonly _fb: FormBuilder) {}

	ngOnInit() {
		this.signinForm = this._fb.group<UserSignin>({
			email: this.email,
			password: undefined,
		});
	}

	onSubmit() {
		if (this.signinForm.valid) {
			// console.log(this.signinForm.value);
			const value: UserSignin = this.signinForm.value;
			// console.log(value);
			this.signin.emit(value);
		}
	}
}
