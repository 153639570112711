/* jscpd:ignore-start */
import { tap, timeout, catchError, takeUntil } from "rxjs/operators";
import { Component, OnInit, OnDestroy, Injector } from "@angular/core";
import { Store } from "@ngrx/store";
import { ReplaySubject, EMPTY } from "rxjs";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { handleObservableError } from "app/shared/utils";
import { IDestructionLot } from "@elevatedsignals/amygoodman";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
/* jscpd:ignore-end */

@Component({
	selector: "destruction-lot-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class DestructionLotCreateComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();
	destruction_lot: IDestructionLot | undefined;

	form_title = "Create a Destruction Lot";
	form_title_translation_key: string = marker(
		"form_title_create_a_destruction_lot",
	);

	submit_button = "Create Destruction Lot";
	submit_button_translation_key: string = marker(
		"form_button_create_destruction_lot",
	);

	submit_icon = "edit";
	loading = false;
	specific_field_id: string;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name (optional)",
				title_translation_key: marker("form_field_label_name_optional"),
				warning: "Warning: Destruction Lot name already in use.",
				warning_translation_key: marker(
					"form_field_warning_destruction_lot_name_already_in_use",
				),
			},
		},
		required: [],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private names;

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		this.specific_field_id = this._injector.get("specific_field_id", null);
	}

	valid(v) {
		this.valid$.next(!v);
	}

	ngOnInit() {
		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`destruction_lots/fetch/names`, "", { col: "name" })
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		this.addDestructionLot(this.model);
	}

	onChanges(e): void {
		// empty
	}

	addDestructionLot(add_destruction_lot: IDestructionLot) {
		this.loading$.next(true);
		this._itemService
			.add("destruction_lots", add_destruction_lot)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							result_type: "destruction_lots",
							addedItem,
							specific_field_id: this.specific_field_id,
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}

	get id(): number {
		return this.destruction_lot ? this.destruction_lot.id : -1;
	}
}
