export const ItemKey = {
	ApplicableProducts: "applicable_products",
	ApplicationMethods: "application_methods",
	AuditLogs: "audit_logs",
	Batches: "batches",
	BatchTypes: "batch_types",
	BillOfMaterials: "bill_of_materials",
	Cultivars: "cultivars",
	CustomFields: "custom_fields",
	CustomScans: "custom_scans",
	DestructionEvents: "destruction_events",
	DestructionLots: "destruction_lots",
	DestructionReasons: "destruction_reasons",
	DocumentSets: "document_sets",
	Equipments: "equipments",
	EquivalentUnits: "equivalent_units",
	Facilities: "facilities",
	GrowthStages: "growth_stages",
	GrowthStageTypes: "growth_stage_types",
	Hubs: "hubs",
	Inventories: "inventories",
	InventoryCategories: "inventory_categories",
	InventoryProducts: "inventory_products",
	InventoryUnits: "inventory_units",
	Locations: "locations",
	LocationTypes: "location_types",
	Lots: "lots",
	Notifications: "notifications",
	Periods: "periods",
	Plants: "plants",
	PendingInventories: "pending_inventories",
	Printers: "printers",
	PrinterJobs: "printer_jobs",
	PrinterJobTemplates: "printer_job_templates",
	ProductionPlans: "production_plans",
	ProductionStages: "production_stages",
	ProductionStageTypes: "production_stage_types",
	PurchaseOrders: "purchase_orders",
	RateTypes: "rate_types",
	RelatedBatches: "related_batches",
	Records: "records",
	RelatedRecords: "related_records",
	Roles: "roles",
	QuickRecords: "quick_records",
	Reports: "reports",
	ReportingCategories: "reporting_categories",
	ReportTypes: "report_types",
	Schemas: "schemas",
	SeedLots: "seed_lots",
	Sensors: "sensors",
	Skus: "skus",
	Sops: "sops",
	SopCategories: "sop_categories",
	Statuses: "statuses",
	SubstanceTypes: "substance_types",
	Tags: "tags",
	Users: "users",
	Vendors: "vendors",
	VendorsCategories: "vendor_categories",
	WorkOrders: "work_orders",
	WorkOrderOutputs: "work_order_outputs",
	WorkOrderTypes: "work_order_types",
} as const;

export const customReducerKeys = [
	"batch_gi",
	"batch_non_gi",
	"destruction_events_batch_gi",
	"destruction_events_batch_non_gi",
	"jobs", // printer_jobs
	"plant_related_records",
	"equipment_related_records",
	"location_related_records",
	"batch_related_records",
	"vendor_related_records",
	"reporting_periods",
];

export type ItemKeyType = (typeof ItemKey)[keyof typeof ItemKey];

/**
 * @deprecated Please don't just copy and paste keys from this. Ideally we get rid of these keys. Please understand what you're doing if you choose to use these keys.
 */
export const MorphKey = {
	ApplicableProducts: "applicable_product",
	ApplicationMethods: "application_method",
	AuditLogs: "audit_log",
	Batches: "batch",
	BatchTypes: "batch_type",
	BillOfMaterials: "bill_of_material",
	Cultivars: "cultivar",
	CustomFields: "custom_field",
	CustomScans: "custom_scan",
	DestructionEvents: "destruction_event",
	DestructionLots: "destruction_lot",
	DestructionReasons: "destruction_reason",
	DocumentSets: "document_set",
	Equipments: "equipment",
	EquivalentUnits: "equivalent_unit",
	Facilities: "facility",
	GrowthStages: "growth_stage",
	GrowthStageTypes: "growth_stage_type",
	Hubs: "hub",
	Inventories: "inventory",
	InventoryCategories: "inventory_category",
	InventoryProducts: "inventory_product",
	InventoryUnits: "inventory_unit",
	Locations: "location",
	LocationTypes: "location_type",
	Lots: "lot",
	Notifications: "notification",
	Plants: "plant",
	PendingInventories: "pending_inventory",
	Periods: "period",
	Printers: "printer",
	PrinterJobs: "printer_job",
	PrinterJobTemplates: "printer_job_template",
	ProductionPlans: "production_plan",
	ProductionStages: "production_stage",
	ProductionStageTypes: "production_stage_type",
	PurchaseOrders: "purchase_order",
	RateTypes: "rate_type",
	RelatedBatches: "related_batch",
	Records: "record",
	RelatedRecords: "related_record",
	Roles: "role",
	QuickRecords: "schema",
	Reports: "report",
	ReportingCategories: "reporting_category",
	ReportTypes: "report_type",
	Schemas: "schema",
	SeedLots: "seed_lot",
	Sensors: "sensor",
	Skus: "sku",
	Sops: "sop",
	SopCategories: "sop_category",
	Statuses: "status",
	SubstanceTypes: "substance_type",
	Tags: "tag",
	Users: "user",
	Vendors: "vendor",
	VendorsCategories: "vendor_category",
	WorkOrders: "work_order",
	WorkOrderOutputs: "work_order_output",
	WorkOrderTypes: "work_order_type",
} as const;

/**
 * @deprecated Please don't just copy and paste keys from this. Ideally we get rid of these keys.
 */
export type MorphKeyType = (typeof MorphKey)[keyof typeof MorphKey];
