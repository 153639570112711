import { IDestructionLot } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.DestructionLots;

export type State = SelectionState<IDestructionLot>;

export const reducer = createSelectionReducer<IDestructionLot>(reducerKey);
