import { marker } from "@jsverse/transloco-keys-manager/marker";
import { takeUntil, tap, timeout, catchError, map } from "rxjs/operators";
import {
	Component,
	OnInit,
	OnDestroy,
	Injector,
	ChangeDetectorRef,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { handleObservableError } from "app/shared/utils";
import { ReplaySubject, EMPTY } from "rxjs";
import { Globals } from "app/shared/modules/globals/globals.service";
import { BatchPageQuery } from "app/shared/eagers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import {
	blockDuplicateBatchNames,
	getSelectedFacilitySettings,
} from "app/modules/dashboard/selectors/facility-settings.selector";
import { LicenceType } from "app/modules/dashboard/state/facilitySettings.state";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { UnitToGramsPipe } from "app/modules/dashboard/modules/es-pipes/unit-to-grams.pipe";
import { TranslocoService } from "@jsverse/transloco";

@Component({
	selector: "batch-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class BatchCreateComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();
	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	form_title = "Create a Batch";
	form_title_translation_key: string = marker("form_title_create_a_batch");
	submit_button = "Create Batch";
	submit_button_translation_key: string = marker("form_button_create_batch");
	submit_icon = "plus";
	specific_field_id: string | null;
	result_type = "batches";
	blockDuplicateBatchNames$ = this._store.select(blockDuplicateBatchNames);

	model: any = {};
	weight_unit: string = this._globals.weight_unit;

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "",
				error: "",
				warning_translation_key: marker(
					"form_field_warning_batch_name_already_in_use",
				),
				info_text: this._translocoService.translate(
					"batch_name_will_be_set_to_batch_id_if_name_is_left_blank",
				),
			},
			location_id: {
				type: "number",
				title: "Location",
				title_translation_key: marker("word_location"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "locations",
					},
				],
			},
			cultivar_id: {
				type: "number",
				title: "Cultivar",
				title_translation_key: marker("word_cultivar"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "cultivars",
					},
				],
			},
			batch_type_id: {
				type: "number",
				title: "Batch Type",
				title_translation_key: marker("word_batch_type"),
				description: "- optional",
				description_translation_key: marker("form_field_description_optional"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "batch_types",
					},
				],
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				description: "- optional",
				description_translation_key: marker("form_field_description_optional"),
				widget: "textarea",
			},
			timestamp: {
				type: "string",
				title: "Date/Time batch created.",
				title_translation_key: marker("form_field_label_date_time_batch_created"),
				description: "- optional",
				description_translation_key: marker("form_field_description_optional"),
				widget: "date",
			},
		},
		required: ["location_id", "cultivar_id"],
	};

	validators = {
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	facilitySettings$ = this._store.select(getSelectedFacilitySettings);

	showCultivar$ = this.facilitySettings$.pipe(
		map((settings) => {
			return (
				settings.licence[LicenceType.Cultivation] ||
				settings.licence[LicenceType.NonCannabis] !== true
			);
		}),
	);

	// 	// Injected from location details new batch button
	private location_id: number;
	private cultivar_id: number;
	private names;

	constructor(
		private readonly _injector: Injector,
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _globals: Globals,
		private readonly _unitToGramsPipe: UnitToGramsPipe,
		private readonly _translocoService: TranslocoService,
	) {
		this.specific_field_id = this._injector.get("specific_field_id", null);
		this.result_type = this._injector.get("result_type", "batches");
	}

	valid(v) {
		this.valid$.next(v);
	}

	ngOnInit() {
		this.showCultivar$.subscribe((variable) => {
			this.schema.required = variable
				? ["location_id", "cultivar_id"]
				: ["location_id"];
			if (!variable) {
				delete (this.schema.properties as any).cultivar_id;
			}
		});
		this.blockDuplicateBatchNames$.subscribe((block) => {
			if (block) {
				this.schema.properties.name.error = "Warning: Batch name already in use.";
			} else {
				this.schema.properties.name.warning = "Warning: Batch name already in use.";
			}
		});

		if (this._globals.gmp_enabled) {
			delete (this.schema.properties as any).timestamp;
		}

		this.location_id = this._injector.get("location_id", null);
		this.cultivar_id = this._injector.get("cultivar_id", null);

		if (this.location_id) {
			this.model.location_id = this.location_id;
		}

		if (this.cultivar_id) {
			this.model.cultivar_id = this.cultivar_id;
		}

		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`batches/fetch/names`, "", { col: "name" })
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const batch: any = {
			...this.model,
			weight: this._unitToGramsPipe.transform(this.model.weight, this.weight_unit),
		};
		this.addBatch(batch);
	}

	// tslint:disable-next-line:no-empty
	onChanges(e): void {}

	addBatch(add_batch: any) {
		this.loading$.next(true);
		this._itemService
			.add(`batches`, add_batch, BatchPageQuery)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							result_type: this.result_type,
							addedItem,
							...(this.specific_field_id && {
								specific_field_id: this.specific_field_id,
							}),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
