import {
	Observable,
	ReplaySubject,
	combineLatest,
	EMPTY,
	race,
	NEVER,
} from "rxjs";
import { takeUntil, filter, timeout } from "rxjs/operators";
import {
	Injector,
	Component,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
	inject,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { FetchPageActions } from "app/modules/dashboard/actions/paging.actions";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import { FormSchema, Page } from "@elevatedsignals/amygoodman";
import { ViewFormPageComponent } from "app/modules/dashboard/pages/sidenav/form/view-form.component";
import { PagerService } from "app/modules/dashboard/services/pager.service";
import { handleObservableError } from "app/shared/utils";

@Component({
	selector: "quick-report",
	templateUrl: "./index.html",
	styleUrls: ["../sidenav.scss"],
})
export class NewQuickRecordComponent implements OnInit, OnDestroy {
	schemas$: Observable<Page<FormSchema> | undefined> = this._store.select(
		fromDashboard.getQuickRecords,
	);

	facility_id$: Observable<number | null> = this._store.select(
		fromDashboard.getFacilityId,
	);

	schema_page: Page<FormSchema>;

	facility_id: number;
	searchForForms = false;
	page$ = this._store.select(fromDashboard.getQuickRecords);

	type: any = null;
	items: any = null;
	loading = false;
	schemasLoaded = false;

	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	loading_error$: ReplaySubject<string> = new ReplaySubject<string>();

	private document_set_id: number;
	private work_order_id: number;
	private purchase_order_id: number;
	private _pagerService = inject(PagerService);

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _injector: Injector,
		private readonly _cd: ChangeDetectorRef,
	) {
		this.type = this._injector.get("type", null);
		this.items = this._injector.get("items", null);
	}

	ngOnInit() {
		this.work_order_id = this._injector.get("work_order_id", null);
		this.purchase_order_id = this._injector.get("purchase_order_id", null);

		race(
			combineLatest([this.schemas$, this.facility_id$]).pipe(
				takeUntil(this.destroyed$),
				filter((values) => values[1] != null && values[0] != null),
			),
			NEVER.pipe(timeout(10000)),
		).subscribe({
			next: (values) => {
				this.facility_id = Number(values[1] ?? null);
				if (!this.schemasLoaded) {
					this.fetchSchemasPage(values[0]!, this.facility_id);
					this.schemasLoaded = true;
				}
				this.schema_page = {
					...values[0]!,
				};
				this._cd.detectChanges();
			},
			error: (error) => {
				// eslint-disable-next-line no-console
				console.error(error);
				this.loading_error$.next(handleObservableError(error));
				return EMPTY;
			},
		});
		this.document_set_id = this._injector.get("document_set_id", null);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
	}

	goToReport(schema: any) {
		this._store.dispatch(layoutActions.closeSidenav());
		this.openFormSidenav(schema);
	}

	openFormSidenav(schema: FormSchema) {
		this._store.dispatch(
			SelectionActions.select({
				result_type: "quick_records",
				id: schema.id!,
			}),
		);

		this._store.dispatch(
			layoutActions.openSidenav({
				component: ViewFormPageComponent,
				inputs: {
					schema_id: schema.id,
					type: this.type,
					items: this.items,
					document_set_id: this.document_set_id,
					work_order_id: this.work_order_id,
					purchase_order_id: this.purchase_order_id,
				},
			}),
		);
	}

	fetchSchemasPage(changed_page: Page<FormSchema>, facility_id: number) {
		this.loading = true;
		const new_changed_page = {
			...changed_page,
			type: "facility",
			type_id: facility_id,
			result_type: "schemas",
		};

		this._pagerService
			.fetchPage(new_changed_page)
			.pipe(takeUntil(this.destroyed$))
			.subscribe({
				next: (updated_page: Page<FormSchema>) => {
					this.loading = false;
					this.loading_error$.next("");
					this.schema_page = {
						...updated_page,
						result_type: "quick_records",
					};
					const new_updated_page = {
						...updated_page,
						result_type: "quick_records",
					};
					this._store.dispatch(
						FetchPageActions.fetchPageSuccess({ payload: new_updated_page }),
					);
					this._cd.detectChanges();
				},
				error: (error) => {
					this.loading = false;
					this.loading_error$.next(error);
					this._cd.detectChanges();
				},
			});
	}

	itemSelected(form) {
		this._store.dispatch(
			SelectionActions.select({
				result_type: "quick_records",
				id: form.id!,
			}),
		);

		this._store.dispatch(
			layoutActions.openSidenav({
				component: ViewFormPageComponent,
				inputs: {
					schema_id: form.id,
					document_set_id: this.document_set_id,
					work_order_id: this.work_order_id,
					purchase_order_id: this.purchase_order_id,
				},
			}),
		);
	}

	handlePageChange(changed_page: Page<FormSchema>, oldPage: Page<FormSchema>) {
		const changedPage = {
			...changed_page,
			type: "facility",
			type_id: this.facility_id,
			result_type: "schemas",
		};

		this._pagerService.fetchPage(changedPage).subscribe((updatedPage) => {
			const payload: Page<FormSchema> = {
				...oldPage,
				...updatedPage,
				result_type: "quick_records",
			};
			this._store.dispatch(FetchPageActions.fetchPageSuccess({ payload }));
			this._cd.detectChanges();
		});
	}

	closeSidenav(_event: any) {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
