import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { IPrinterJob, PrinterJobStatus } from "@elevatedsignals/amygoodman";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { EMPTY } from "rxjs";
import { handleObservableError } from "app/shared/utils";
import { DateFormatService } from "app/modules/dashboard/services/date-format/dateFormat.service";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "printer-job",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PrinterJobViewComponent
	extends GenericCreateComponent<IPrinterJob>
	implements OnDestroy, OnInit
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			printer_id: {
				type: "number",
				title: "Printer",
				title_translation_key: marker("word_printer"),
				widget: "data-select",
				oneOf: [{ result_type: "printers" }],
				readOnly: true,
			},
			user_account_id: {
				type: "number",
				title: "User",
				title_translation_key: marker("word_user"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "users",
						text_key: ["first_name", "last_name"],
						text_format: "?1 ?2",
					},
				],
				readOnly: true,
			},
			created_at: {
				type: "string",
				title: "Created At",
				title_translation_key: marker("form_field_label_created_at"),
				widget: "string",
				readOnly: true,
			},
			message: {
				type: "string",
				title: "Message",
				title_translation_key: marker("form_field_label_message"),
				widget: "string",
				readOnly: true,
			},
			status: {
				type: "string",
				title: "Status",
				title_translation_key: marker("word_status"),
				widget: "string",
				readOnly: true,
			},
			data: {
				type: "string",
				title: "Data",
				title_translation_key: marker("word_data"),
				widget: "textarea",
			},
		},
	};

	model: any = {};

	private job: IPrinterJob;

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
		private readonly _dateFormatService: DateFormatService,
	) {
		super(_store);
		this.form_title = "Printer Job";
		this.form_title_translation_key = marker("form_title_printer_job");
		this.submit_button = "";
	}

	ngOnInit() {
		this.job = this._injector.get("job", null);

		if (this.job.status === "error" || this.job.status === "completed") {
			this.submit_button = "Reprint";
		}
		this.model = {
			...this.model,
			...this.job,
			created_at: this._dateFormatService.formatDateTime(
				this.job.created_at,
				undefined,
			), // Not sure if need to use facility timezone
		};
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const printer_job: Partial<IPrinterJob> = {
			...this.job,
		};
		printer_job.status = PrinterJobStatus.Pending;
		this.createItem(printer_job);
	}

	createItem(printer_job: Partial<IPrinterJob>) {
		this.loading$.next(true);
		this._itemService
			.add(
				`printers/${printer_job.printer_id}/jobs/${printer_job.id}/reprint`,
				printer_job,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "printers",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
