export enum InventoryUnitType {
	Mass = "MASS",
	Volume = "VOLUME",
	Custom = "CUSTOM",
}
// eslint-disable-next-line @typescript-eslint/naming-convention
export const RESERVED_MASS_SI_UNITS: string[] = ["mg", "g", "kg", "lbs"];
// eslint-disable-next-line @typescript-eslint/naming-convention
export const RESERVED_VOLUME_SI_UNITS: string[] = ["ml", "l", "gallons"];
// eslint-disable-next-line @typescript-eslint/naming-convention
export const RESERVED_SI_UNITS: string[] = [
	...RESERVED_MASS_SI_UNITS,
	...RESERVED_VOLUME_SI_UNITS,
];
