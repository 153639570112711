import { IStatus } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.Statuses;

export type State = SelectionState<IStatus>;

export const reducer = createSelectionReducer<IStatus>(reducerKey);
