import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ISelectListItem } from "@elevatedsignals/amygoodman";
import { maxPlantNumber } from "app/shared/work-order-functions";

import { NewLotCreationSchema } from "../lot/schemas/index";
import { AdjustmentType } from "../measure-event/create.component";

const adjustment_reasons: string[] = [
	"Harvested",
	"Dried",
	"Processing Loss",
	"Packaged",
	"Transferred",
	"Drying Loss",
	"Destroyed",
	"Lost/Theft",
	"Lab Test",
	"Returns",
	"Opening Inventory",
	"Other",
];

const adjustment_types: ISelectListItem[] = [
	{
		name: "Addition",
		value: AdjustmentType.Addition,
		enum: [AdjustmentType.Addition],
	},
	{
		name: "Subtraction",
		value: AdjustmentType.Subtraction,
		enum: [AdjustmentType.Subtraction],
	},
];

export const schema = {
	title: "",
	description: "",
	info: "",
	properties: {
		batch_id: {
			type: "number",
			hidden: true,
		},
		adjustment_type: {
			type: "string",
			title: "Adjustment Type",
			widget: "select",
			oneOf: adjustment_types,
		},
		reason: {
			type: "string",
			title: "Reason",
			widget: "select",
			oneOf: adjustment_reasons.map((item) => {
				return {
					name: item,
					value: item,
					enum: [item],
				};
			}),
		},
		reason_other: {
			type: "string",
			title: "Other Reason",
			visibleIf: {
				reason: ["Other"],
			},
			default: "",
		},
		// For Plant Subtractions/Reductions
		use_remaining: {
			title: "Use Remaining",
			type: "boolean",
			widget: "checkbox",
			default: false,
			visibleIf: {
				adjustment_type: ["SUBTRACT"],
			},
		},
		use_plant_ids: {
			title: "Use Specific Plant Ids",
			type: "boolean",
			widget: "checkbox",
			default: false,
			visibleIf: {
				allOf: [{ adjustment_type: ["SUBTRACT"] }, { use_remaining: [false] }],
			},
		},
		plant_ids: {
			type: "array",
			title: "Plant Ids",
			widget: "data-select",
			related_properties: ["batch_id"],
			items: {
				type: "number",
				oneOf: [
					{
						result_type: "plants",
						queryString: {
							plant_status: "ACTIVE",
						},
					},
				],
			},
			visibleIf: {
				allOf: [
					{ adjustment_type: ["SUBTRACT"] },
					{ use_plant_ids: [true] },
					{ use_remaining: [false] },
				],
			},
		},
		plant_count_input: {
			type: "number",
			title: "Number of Plants",
			visibleIf: {
				allOf: [
					{ adjustment_type: ["SUBTRACT"] },
					{ use_plant_ids: [false] },
					{ use_remaining: [false] },
				],
			},
		},
		// For Plant Addition/Creation
		cultivar_id: {
			type: "number",
			title: "Cultivar",
			widget: "data-select",
			oneOf: [{ result_type: "cultivars" }],
			visibleIf: {
				adjustment_type: ["ADD"],
			},
		},
		growth_stage_id: {
			type: "number",
			title: "Plant Stage",
			widget: "data-select",
			oneOf: [
				{
					result_type: "growth_stages",
					queryString: {
						growth_stage_status: ["ACTIVE"],
					},
				},
			],
			visibleIf: {
				adjustment_type: ["ADD"],
			},
		},
		lot_id: {
			...NewLotCreationSchema.lot_id({ dont_show_expired: "true" }),
			visibleIf: {
				adjustment_type: ["ADD"],
			},
			quick_create: true,
		},
		plant_count_output: {
			type: "number",
			title: "Number Of Plants",
			max: maxPlantNumber,
			warning:
				"Number of plants in output exceeds the recommended maximum - Please lower your plant count.",
			warning_translation_key: marker(
				"form_field_warning_number_of_plants_exceeds_recommended",
			),
			visibleIf: {
				adjustment_type: ["ADD"],
			},
		},
		work_order_description: {
			type: "string",
			widget: "textarea",
			title: "Work Order Description",
			title_translation_key: marker("form_field_label_work_order_description"),
		},
		action_updated_at: {
			type: "string",
			title: "Time in",
			widget: "date",
		},
	},
	anyOf: [
		{
			required: ["batch_id", "adjustment_type", "reason", "use_remaining"],
		},
		{
			required: [
				"batch_id",
				"adjustment_type",
				"reason",
				"use_plant_ids",
				"plant_ids",
			],
		},
		{
			required: ["batch_id", "adjustment_type", "reason", "plant_count_input"],
		},
		{
			required: [
				"batch_id",
				"adjustment_type",
				"reason",
				"cultivar_id",
				"growth_stage_id",
				"plant_count_output",
			],
		},
	],
};
