import { IVendor } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.Vendors;

export type State = SelectionState<IVendor>;

export const reducer = createSelectionReducer<IVendor>(reducerKey);
