import { takeUntil, tap, timeout, catchError } from "rxjs/operators";
import {
	Component,
	OnInit,
	OnDestroy,
	Injector,
	ChangeDetectorRef,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ReplaySubject, EMPTY } from "rxjs";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

@Component({
	selector: "tag-plant",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class TagPlantComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Plant Tagging";
	form_title_translation_key: string = marker("form_title_plant_tagging");
	submit_button = "Create Plant Tag";
	submit_button_translation_key: string = marker("form_button_create_plant_tag");
	submit_icon = "plus";

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};
	weight_unit: string = this._globals.weight_unit;

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			batch_id: {
				type: "number",
				hidden: true,
			},
			tag_id: {
				type: "number",
				title: "Tag",
				title_translation_key: marker("word_tag"),
				widget: "data-select",
				oneOf: [{ result_type: "tags" }],
			},
			plant_type: {
				type: "string",
				title: "Tag By",
				title_translation_key: marker("form_field_label_tag_by"),
				widget: "select",
				oneOf: [
					{
						name: "Plant Count",
						name_translation_key: marker("form_field_value_plant_count"),
						value: "PLANT_COUNT",
						enum: ["PLANT_COUNT"],
					},
					{
						name: "Plant ID(s)",
						name_translation_key: marker("form_field_value_plant_id_s"),
						value: "PLANT_IDS",
						enum: ["PLANT_IDS"],
					},
				],
			},
			plant_ids: {
				type: "array",
				title: "Select Plants",
				title_translation_key: marker("form_field_label_select_plants"),
				widget: "data-select",
				related_properties: ["batch_id"],
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "plants",
							queryString: {
								plant_status: "ACTIVE",
							},
						},
					],
				},
				visibleIf: {
					plant_type: ["PLANT_IDS"],
				},
			},
			plant_count: {
				type: "number",
				title: "Number of Plants",
				title_translation_key: marker("form_field_label_number_of_plants"),
				visibleIf: {
					plant_type: ["PLANT_COUNT"],
				},
			},
		},
		anyOf: [
			{
				required: ["tag_id", "plant_count"],
			},
			{
				required: ["tag_id", "plant_ids"],
			},
		],
	};

	validators = {};

	// Injected from other component
	private plant_id: number;
	private batch_id: number;
	private tag_id: number;

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
		private readonly _cd: ChangeDetectorRef,
		private readonly _globals: Globals,
	) {}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		this.plant_id = this._injector.get("plant_id", null);
		this.batch_id = this._injector.get("batch_id", null);
		this.tag_id = this._injector.get("tag_id", null);

		if (this.plant_id || this.batch_id || this.tag_id) {
			if (this.plant_id) {
				this.model = {
					...this.model,
					plant_type: "PLANT_IDS",
					plant_ids: [this.plant_id],
					batch_id: this.batch_id,
				};
			}
			if (this.batch_id) {
				this.model = {
					...this.model,
					batch_id: this.batch_id,
				};
			}
		}

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const tag_plants: any = {
			...this.model,
			batch_id: this.batch_id,
			result_type: "batch",
		};

		this.tagPlants(tag_plants);
	}

	onChanges(_error): void {}

	tagPlants(tag_plants: any) {
		this.loading$.next(true);

		this._itemService
			.update(`plants`, `tag/${tag_plants.tag_id}`, tag_plants)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "batches",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
