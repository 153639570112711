<div
	class="ui grid dirty-mackenzie"
	*transloco="let t; read: 'work_order_history_component'"
>
	<div class="column">
		<div class="ui feed" *ngIf="workOrderHistoryEvents$ | async as auditLog">
			<div class="event" *ngFor="let log of auditLog as logs; let i = index">
				<div class="label">
					<a class="ui circular label" *ngIf="log.user">
						{{ getInitials(log.user.first_name, log.user.last_name) }}
					</a>
				</div>
				<div class="content">
					<div class="date">
						{{ log.date | formatDateTime }} ({{ parseRelativeDate(log.date) }})
					</div>
					<div class="summary" *ngIf="log.action_summary">
						<i
							*ngIf="isBackdated(log) && hasBackdateSummary(log)"
							fuiPopup
							popupPlacement="bottom center"
							class="arrow alternate circle left yellow icon"
							popupText="{{ log.backdate_summary }}"
						>
						</i>
						<i
							*ngIf="(showReportingPeriods$ | async) && isReconciled(log) && reportingPeriods$ | async as reportingPeriods"
							fuiPopup
							popupPlacement="bottom center"
							class="exclamation circle orange icon"
							popupText="{{ t('this_event_was_recorded') }} {{ log.created_at | formatDateTime }} ({{ 'word_reporting_period' | transloco }}: {{ getPeriodRecordedOn(log, reportingPeriods) }})"
						>
						</i>
						<i
							*ngIf="(showReportingPeriods$ | async) && log.action == 'REOPEN' && log.reporting_period.closedAtDate"
							fuiPopup
							popupPlacement="bottom center"
							class="door open orange icon"
							popupText="{{ t('this_work_order_was_reopened') }} {{ log.reporting_period.closedAtDate | formatDateTime }}."
						>
						</i>
						{{log.action_summary}}
						<span *ngIf="log.note" style="font-weight: normal">
							<br />
							{{ 'word_reason' | transloco }}: {{ log.note.subject }}
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
