import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	Injector,
	OnInit,
	OnDestroy,
} from "@angular/core";
import { WorkOrderService } from "app/modules/dashboard/services/work-order.service";
import { Globals } from "app/shared/modules/globals/globals.service";
import { IWorkOrderSource, SubstanceTypes } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

import { onVendorChange } from "../../shared";

@Component({
	selector: "work-order-drying-source",
	templateUrl: "../../form-view.component.html",
	styleUrls: ["../../sidenav.scss"],
})
export class WorkOrderDryingSourceComponent
	extends GenericUpdateComponent<{
		id: number;
		batch_id: number;
		work_order_id: boolean;
		weight: number;
	}>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			batch_id: {
				type: "number",
				hidden: true,
			},

			inventory_product_id: {
				title: "Product",
				title_translation_key: marker("word_product"),
				type: "number",
				widget: "data-select",
				quick_create: false,
				related_properties: ["vendor_id", "location_id", "batch_id"],
				oneOf: [
					{
						result_type: "inventory_products",
						queryString: {
							wet_weight: "true",
						},
					},
				],
			},
			vendor_id: {
				type: "number",
				title: "Vendor",
				title_translation_key: marker("word_vendor"),
				widget: "data-select",
				oneOf: [{ result_type: "vendors" }],
				quick_create: false,
			},
			location_id: {
				type: "number",
				title: "Source Location",
				title_translation_key: marker("form_field_label_source_location"),
				widget: "data-select",
				quick_create: false,
				related_properties: ["inventory_product_id", "batch_id"],
				oneOf: [
					{
						result_type: "locations",
						queryString: {
							input: "true",
							non_zero_inventory: "true",
						},
					},
				],
			},
			lot_id: {
				type: "number",
				title: "Source Lot (optional)",
				title_translation_key: marker("form_field_label_source_lot_optional"),
				widget: "data-select",
				quick_create: false,
				related_properties: ["batch_id", "location_id", "inventory_product_id"],
				oneOf: [
					{
						result_type: "lots",
						queryString: {
							input: "true",
							non_zero_inventory: "true",
						},
					},
				],
			},
			inventory_ids: {
				type: "array",
				title: "Inventory ID",
				title_translation_key: marker("form_field_label_inventory_id"),
				widget: "data-select",
				quick_create: false,
				multi: true,
				related_properties: [
					"inventory_product_id",
					"location_id",
					"batch_id",
					"lot_id",
				],
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventories",
							text_key: ["name", "id"],
							text_format: "?1 (#?2)",
							value_key: "id",
							queryString: {
								non_zero_inventory: "true",
							},
						},
					],
				},
			},
			remaining_inventory: {
				title: "Use Remaining Inventory",
				title_translation_key: marker("form_field_label_use_remaining_inventory"),
				type: "boolean",
				widget: "checkbox",
				default: false,
			},
			value: {
				type: "number",
				title: `Quantity`,
				title_translation_key: marker("word_quantity"),
				visibleIf: {
					allOf: [
						{ remaining_inventory: [false] },
						{ inventory_product_id: ["$EXP$ target.value > 0"] },
					],
				},
				width: "50%",
			},
			inventory_unit_id: {
				title: "Inventory Unit",
				title_translation_key: marker("word_inventory_unit"),
				type: "number",
				widget: "data-select",
				quick_create: false,
				shorter_placeholder: true,
				related_properties: ["inventory_product_id"],
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
				visibleIf: {
					allOf: [
						{ remaining_inventory: [false] },
						{ inventory_product_id: ["$EXP$ target.value > 0"] },
					],
				},
				width: "50%",
			},
			timestamp: {
				type: "string",
				title: "Date",
				title_translation_key: marker("word_date"),
				widget: "date",
			},

			/** These are just used for the required to make sure we have all the correct items selected before allowing the user to submit the form */
			remaining_inventory_selected: {
				type: "boolean",
				widget: "checkbox",
				hidden: true,
				default: false,
				visibleIf: {
					remaining_inventory: [true],
				},
			},
		},
		anyOf: [
			{
				required: ["inventory_product_id", "inventory_unit_id", "value"],
			},
			{
				required: ["inventory_product_id", "remaining_inventory_selected"],
			},
		],
	};

	weight_unit = this._globals.weight_unit;
	// Injected from other component
	private batch_id: number;
	private work_order_id: number;

	private prevInventoryProductId?: number;
	private initialCopy = false;
	private duplicate: IWorkOrderSource | null = null;

	constructor(
		protected readonly _store: Store<fromDashboard.State>,
		protected readonly _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _workOrderService: WorkOrderService,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.form_title = "Add Fresh Cannabis";
		this.form_title_translation_key = marker("form_title_add_fresh_cannabis");
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.batch_id = this._injector.get("batch_id", null);
		this.model.batch_id = this.batch_id;
		this.work_order_id = this._injector.get("work_order_id", null);

		this.duplicate = this._injector.get("duplicate", null);
		if (this.duplicate) {
			this.model = {
				...this.model,
				inventory_product_id: this.duplicate.inventory.inventory_product_id,
				inventory_unit_id: this.duplicate.inventory.inventory_unit_id,
				batch_id: this.duplicate.batch_id,
				location_id: this.duplicate.location_id,
				new_lot: "Use Existing Lot",
				lot_id: this.duplicate.inventory.lot_id,
				vendor_id: this.duplicate.inventory.vendor_id,
			};
			if (
				this.duplicate.inventory.measurements &&
				this.duplicate.inventory.measurements[0]
			) {
				this.model = {
					...this.model,
					value: this.duplicate.inventory.measurements[0].value,
					timestamp: this.duplicate.inventory.measurements[0].timestamp,
				};
			}
			this.batch_id = this.duplicate.batch_id!;
			this.work_order_id = this.duplicate.work_order_id;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		if (!model.inventory_product_id && this.prevInventoryProductId) {
			this.prevInventoryProductId = undefined;
			model.inventory_unit_id = undefined;
		} else if (
			model.inventory_product_id &&
			this.prevInventoryProductId !== model.inventory_product_id
		) {
			this.prevInventoryProductId = model.inventory_product_id;
			model.inventory_unit_id = undefined;
			const firstProductSet = !this.model.inventory_product_id;
			this._itemService
				.fetchItem(`inventory_product`, `${model.inventory_product_id}`, {
					eager: "[vendors]",
				})
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((inventory_product) => {
					if (!firstProductSet || !this.model.inventory_unit_id) {
						this.model.inventory_unit_id = inventory_product.display_unit_id;
					}

					[this.model, this.schema] = onVendorChange(
						this.model,
						this.schema,
						inventory_product,
					);

					if (this.duplicate && !this.initialCopy) {
						this.model.vendor_id = this.duplicate.inventory.vendor_id;
						this.initialCopy = true;
					}

					this._cd.detectChanges();
				});
		}
	}

	updateItem(work_order_output: {
		value: number;
		inventory_product_id: boolean;
		inventory_unit_id: number;
		timestamp?: Date;
	}) {
		const newWorkOrderOutput = {
			...work_order_output,
			timestamp: work_order_output.timestamp ?? new Date(),
		};

		this.loading$.next(true);
		const input: any = {
			...newWorkOrderOutput,
			work_order_id: this.work_order_id,
			batch_id: this.batch_id,
			type: "Inventory",
		};

		this._workOrderService
			.addSources(input)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
