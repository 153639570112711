import { HeaderQueryType, IEquipment, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { EquipmentDetailQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "equipments";

export type State = Page<IEquipment>;

export const reducer = createPagingReducer<IEquipment>(reducerKey, {
	result_type: "equipments",
	title: "Equipment",
	title_translation_key: marker("page_title_equipments"),
	title_plural_translation_key: marker("page_title_plural_equipments"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Show Archived",
			key: "showArchived",
			title_translation_key: marker("checkbox_filter_show_archived"),
			type: HeaderQueryType.checkbox,
			value: false,
		},
	],
	query: EquipmentDetailQuery,
});
