import { HeaderQueryType, Page, User } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "users";

export type State = Page<User>;

export const reducer = createPagingReducer<User>(reducerKey, {
	result_type: "users",
	title: "User",
	title_translation_key: marker("page_title_users"),
	title_plural_translation_key: marker("page_title_plural_users"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Active Filter",
			key: "active_filter",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Active Users",
					translationKey: marker("users_filter_active_users"),
					value: "ACTIVE",
				},
				{
					label: "Deactivated Users",
					translationKey: marker("users_filter_deactivated_users"),
					value: "DEACTIVATED",
				},
			],
			value: "ACTIVE",
		},
		{
			title: "User Role",
			key: "role_ids",
			type: HeaderQueryType.dataSelect,
			result_type: "roles",
			value_key: "id",
			text_key: "name",
			disabled: false,
			multiple: true,
			search: true,
			value: [],
		},
	],
});
