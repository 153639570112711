import { EquivalentUnit } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.EquivalentUnits;

export type State = SelectionState<EquivalentUnit>;

export const reducer = createSelectionReducer<EquivalentUnit>(reducerKey);
