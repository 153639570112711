import { IEquipment } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.Equipments;

export type State = SelectionState<IEquipment>;

export const reducer = createSelectionReducer<IEquipment>(reducerKey);
