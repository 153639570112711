import {
	HeaderQueryType,
	ISopCategory,
	Page,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "sop_categories";

export type State = Page<ISopCategory>;

export const reducer = createPagingReducer<ISopCategory>(reducerKey, {
	result_type: "sop_categories",
	title: "SOP Category",
	title_plural: "SOP Categories",
	title_translation_key: marker("page_title_sop_categories"),
	title_plural_translation_key: marker("page_title_plural_sop_categories"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Show Archived",
			key: "showArchived",
			title_translation_key: marker("checkbox_filter_show_archived"),
			type: HeaderQueryType.checkbox,
			value: false,
		},
	],
});
