import { Store } from "@ngrx/store";
import { Component, inject, Injector } from "@angular/core";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import {
	IInventoryProduct,
	MEASURE_UNIT_MAP,
} from "@elevatedsignals/amygoodman";
import {
	EMPTY,
	NEVER,
	ReplaySubject,
	catchError,
	combineLatest,
	filter,
	race,
	takeUntil,
	tap,
	timeout,
} from "rxjs";
import { HttpResponseBase } from "@angular/common/http";
import { handleObservableError } from "app/shared/utils";
import { TranslateErrorService } from "app/shared/errors/handleError";
import { TranslocoService } from "@jsverse/transloco";
import {
	FormBuilder,
	FormGroup,
	FormArray,
	AbstractControl,
} from "@angular/forms";
import Big from "big.js";
import {
	RESERVED_MASS_SI_UNITS,
	RESERVED_SI_UNITS,
	RESERVED_VOLUME_SI_UNITS,
	InventoryUnitType,
} from "app/shared/constants";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { InventoryProductDetailQuery } from "app/shared/eagers";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { getRawUnits, UnitRawValue } from "app/shared/units";

import { IInventoryUnitCreate } from "../inventory-unit/create.component";
import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "inventory-product-manage-units",
	templateUrl: "./manage-units.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class InventoryProductManageUnitsComponent extends GenericCreateComponent<IInventoryUnitCreate> {
	InventoryUnitType = InventoryUnitType;

	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {},
		required: [],
	};

	unitsForm: FormGroup;
	unitTypeBlocks: Record<InventoryUnitType, FormArray>;

	inventory_product$ = new ReplaySubject<IInventoryProduct>();
	inventory_product: IInventoryProduct;
	onUpdated: any;
	copyProduct = false;

	private readonly inventory_product_id$ = this._store.select(
		fromDashboard.getSelectedInventoryProductId,
	);

	private readonly inventory_product_detail$ = this._store.select(
		fromDashboard.getSelectedInventoryProduct,
	);

	private readonly loading_error$ = new ReplaySubject<string | null>();

	private translateErrorService = inject(TranslateErrorService);

	constructor(
		protected _store: Store<fromDashboard.State>,
		private readonly _translocoService: TranslocoService,
		private readonly formBuilder: FormBuilder,
		private readonly _injector: Injector,
		private readonly _itemService: ItemService,
	) {
		super(_store);

		this.submit_button = "Continue";
		this.submit_button_translation_key = marker("form_button_continue");
		this.onUpdated = this._injector.get("onUpdated", null);
		this.copyProduct = this._injector.get("copyProduct", false);

		race(
			combineLatest([
				this.inventory_product_id$,
				this.inventory_product_detail$,
			]).pipe(
				takeUntil(this.destroyed$),
				filter((values): values is [number | string, IInventoryProduct] => {
					if (values[1] instanceof HttpResponseBase) {
						throw values[1];
					}
					return values[1] !== undefined && values[1]!.id === values[0];
				}),
				this.translateErrorService.catchAndTranslateError(
					"error_failed_to_fetch_inventory_product",
				),
			),
			NEVER.pipe(timeout(10000)),
		).subscribe({
			next: (values) => {
				this.inventory_product$.next(values[1]!);
				this.inventory_product = values[1]!;

				this.unitsForm = this.formBuilder.group({
					displayUnit: this.formBuilder.control({
						value: this.inventory_product.display_unit?.name,
						disabled: false,
					}),
					custom_units: this.formBuilder.array([]),
					mass_units: this.formBuilder.array([]),
					volume_units: this.formBuilder.array([]),
				});
				this.display_unit.valueChanges.subscribe((value) => {
					if (value) this.switchDisplayUnit(value);
				});

				this.unitTypeBlocks = {
					CUSTOM: this.custom_units,
					MASS: this.mass_units,
					VOLUME: this.volume_units,
				};

				const rawUnits = getRawUnits(this.inventory_product, this.copyProduct);
				for (const unit of rawUnits) {
					this.addUnit(unit);
				}
			},
			error: (error) => {
				this.loading_error$.next(handleObservableError(error));
				return EMPTY;
			},
		});
	}

	get display_unit(): FormArray {
		return this.unitsForm.get("displayUnit") as FormArray;
	}

	get custom_units(): FormArray {
		return this.unitsForm.get("custom_units") as FormArray;
	}

	get mass_units(): FormArray {
		return this.unitsForm.get("mass_units") as FormArray;
	}

	get volume_units(): FormArray {
		return this.unitsForm.get("volume_units") as FormArray;
	}

	get allUnitControls(): AbstractControl[] {
		return [
			...this.custom_units.controls,
			...this.mass_units.controls,
			...this.volume_units.controls,
		];
	}

	get availableUnits(): AbstractControl[] {
		return getUnitsData(this.allUnitControls);
	}

	getUnitControl = (
		index: number,
		type: InventoryUnitType,
	): AbstractControl | undefined => {
		return this.unitTypeBlocks[type].controls.at(index);
	};

	getUnitControlValue = (
		index: number,
		type: InventoryUnitType,
	): UnitRawValue => {
		return this.getUnitControl(index, type)!.getRawValue();
	};

	getUnitOptions = (
		index: number,
		type: InventoryUnitType,
	): AbstractControl[] => {
		const currentUnit = this.getUnitControlValue(index, type);
		let controls: AbstractControl[] = [];
		if (index === 0 && !currentUnit.isFirstUnit) {
			// First unit can be connected to any unit unless it's ????
			for (const [typeOfUnit, unitsOfType] of Object.entries(
				this.unitTypeBlocks,
			)) {
				if (typeOfUnit === type) continue;
				controls = [...controls, ...unitsOfType.controls];
			}
		} else {
			// Custom Units can be linked to any unit, SI only to same type
			controls =
				type === InventoryUnitType.Custom
					? this.allUnitControls
					: this.unitTypeBlocks[type].controls;
		}
		return getUnitsData(controls, currentUnit.unit_name);
	};

	getReverseBtnClass = (index: number, type: InventoryUnitType) => {
		const result: string[] = ["ui", "icon", "button"];
		if (this.getUnitControlValue(index, type).reverse_conversion) {
			result.push("green");
		}
		if (!this.canReverse(index, type)) {
			result.push("disabled");
		}
		return result.join(" ");
	};

	canReverse = (index: number, type: InventoryUnitType): boolean => {
		const currentUnit = this.getUnitControlValue(index, type);
		if (currentUnit.conversion) {
			const reverseConversion = new Big(1).div(currentUnit.conversion).toNumber();
			// do not allow to reverse if too many decimals
			if ((reverseConversion.toString().split(".")[1] ?? "").length > 6)
				return false;
		}
		return true;
	};

	canRemove = (index: number, type: InventoryUnitType): boolean => {
		const currentUnit = this.getUnitControlValue(index, type);
		if (
			currentUnit.isFirstUnit ||
			currentUnit.unit_id ||
			(this.unitTypeBlocks[type].length !== 1 && !index)
		) {
			// Cannot remove First unit or SI unit if more than 1 in type
			return false;
		}
		return true;
	};

	getAvailableSIUnits = (type: string): string[] => {
		const usedNames = getUnitsData(this.unitTypeBlocks[type].controls).map(
			(unit) => unit.getRawValue().unit_name,
		);
		let result: string[];
		switch (type) {
			case InventoryUnitType.Mass:
				result = RESERVED_MASS_SI_UNITS.filter((name) => !usedNames.includes(name));
				break;
			case InventoryUnitType.Volume:
				result = RESERVED_VOLUME_SI_UNITS.filter(
					(name) => !usedNames.includes(name),
				);
				break;
			default:
				result = [];
		}
		return result;
	};

	addSIUnit = (name: string) => {
		if (RESERVED_SI_UNITS.includes(name)) {
			const unitType = siUnitType(name);
			const firstControlInType = this.getUnitControl(0, unitType);
			const firstUnitInType = firstControlInType?.getRawValue();
			let conversion = 0;
			if (firstUnitInType) {
				// Calculate conversion if possible
				const mtConstants = MEASURE_UNIT_MAP[unitType];
				if (mtConstants?.[firstUnitInType.unit_name] && mtConstants[name]) {
					conversion =
						mtConstants[firstUnitInType.unit_name]!.conversion /
						mtConstants[name]!.conversion;
				}
			}
			// Add unit
			this.addUnit({
				id: 0,
				isFirstUnit: false,
				isDisplayUnit: false,
				unit_id: 0,
				unit_name: name,
				conversion,
				output_unit_id: firstUnitInType?.unit_id ?? 0,
				output_name: firstUnitInType?.unit_name ?? "",
				reverse_conversion: false,
			});
		}
	};

	addUnit = (unit?: UnitRawValue) => {
		const name = unit?.unit_name ?? "";
		const isDisplayUnit = unit?.isDisplayUnit ?? false;
		const unitType = siUnitType(name);
		const controlsOfType = this.unitTypeBlocks[unitType].controls;

		const unitGroup = this.formBuilder.group({
			id: [unit?.id ?? 0],
			isFirstUnit: [unit?.isFirstUnit],
			isDisplayUnit: [isDisplayUnit],
			unit_id: [unit?.unit_id ?? 0],
			unit_name: [name],
			conversion: [unit?.conversion ?? 0],
			reverse_conversion: unit?.reverse_conversion ?? false,
			output_unit_id: unit?.output_unit_id ?? 0,
			output_name: [unit?.output_name ?? ""],
		});

		if (RESERVED_SI_UNITS.includes(name)) {
			unitGroup.get("unit_name")?.disable();
			// Should allow to convert only first unit of SI Type
			if (controlsOfType.length !== 0) {
				unitGroup.get("conversion")?.disable();
				unitGroup.get("output_name")?.disable();
			}
		}

		controlsOfType.push(unitGroup);

		if ((unit?.unit_id && !this.copyProduct) || unit?.isFirstUnit) {
			// if already saved or First unit of product
			unitGroup.disable();
		}
	};

	removeUnit = (index: number, type: InventoryUnitType) => {
		const controlsOfType = this.unitTypeBlocks[type];
		const currentUnit = this.getUnitControlValue(index, type);
		// Can remove should handle condition but double check
		if (index || controlsOfType.length === 1) {
			// Update all units conversions connected thought this unit
			for (const unitsOfType of Object.values(this.unitTypeBlocks)) {
				for (let idx = 0; idx < unitsOfType.length; idx += 1) {
					const control = unitsOfType.at(idx)!;
					const unit = control.getRawValue();
					if (
						unit.output_name === currentUnit.unit_name &&
						currentUnit.unit_name !== unit.unit_name
					) {
						const multiply =
							currentUnit.reverse_conversion === unit.reverse_conversion;
						const newConversion = multiply
							? currentUnit.conversion * unit.conversion
							: unit.conversion / currentUnit.conversion;
						control.get("conversion")?.setValue(newConversion);
						control.get("output_name")?.setValue(currentUnit.output_name);
					}
				}
			}
			// Update display unit to connected unit
			if (currentUnit.isDisplayUnit) {
				this.display_unit.setValue(currentUnit.output_name as any);
				this.switchDisplayUnit(currentUnit.output_name);
			}
			controlsOfType.removeAt(index);
		}
	};

	reverseConversion = (index: number, type: InventoryUnitType) => {
		const control = this.getUnitControl(index, type)!;
		const currentUnit = control.getRawValue();
		if (currentUnit.conversion) {
			const reverseConversion = new Big(1).div(currentUnit.conversion).toNumber();
			control.get("conversion")?.setValue(reverseConversion);
		}
		control.get("reverse_conversion")?.setValue(!currentUnit.reverse_conversion);
	};

	switchDisplayUnit = (unit_name: string) => {
		for (const unitsOfType of Object.values(this.unitTypeBlocks)) {
			for (let index = 0; index < unitsOfType.length; index += 1) {
				const control = unitsOfType.at(index);
				const unit = control.getRawValue();
				control.get("isDisplayUnit")?.setValue(unit.unit_name === unit_name);
			}
		}
	};

	onSubmit() {
		const rawData = this.unitsForm.getRawValue();
		const data = {
			units: [
				...rawData.custom_units,
				...rawData.mass_units,
				...rawData.volume_units,
			],
		};
		if (this.copyProduct) {
			// return the this.unitsForm.getRawValue() back to parent component
			this.onUpdated(data);
			this.closeSidenav();
		} else {
			// update the units

			this.loading$.next(true);
			this._itemService
				.update(
					`inventory_product/${this.inventory_product.id}`,
					"inventory_units",
					data,
					InventoryProductDetailQuery,
				)
				.pipe(takeUntil(this.destroyed$))
				.pipe(
					timeout(10000),
					catchError((error) => {
						this.error$.next(handleObservableError(error, true));
						this.loading$.next(false);
						return EMPTY;
					}),
				)
				.pipe(
					tap((updatedItem) => {
						this._store.dispatch(
							ItemActions.updateSuccess({
								updatedItem,
								result_type: "inventory_products",
							}),
						);
						this.loading$.next(false);
						this.closeSidenav();
					}),
				)
				.subscribe();
		}
	}

	createItem() {}
}

const siUnitType = (name: string): InventoryUnitType => {
	for (const [index, measure_type] of Object.entries(MEASURE_UNIT_MAP)) {
		// To not confuse Grams with Gallons
		if ("gallons" in measure_type) {
			delete measure_type.g;
		}
		if (name in measure_type) {
			return index as InventoryUnitType;
		}
	}
	return InventoryUnitType.Custom;
};

const getUnitsData = (
	controls: AbstractControl[],
	excludeName?: string,
): AbstractControl[] => {
	return controls.reduce((acc: any[], unit) => {
		const unitName = unit.getRawValue().unit_name;
		if (unitName && (!excludeName || excludeName !== unitName)) {
			acc.push(unit);
		}
		return acc;
	}, []);
};
