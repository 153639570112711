<div class="ui grid" *transloco="let t; read: 'sidenav_quick_record'">
	<div class="sixteen wide column">
		<div class="left floated left aligned eight wide column">
			<button class="ui labeled icon button" (click)="closeSidenav($event)">
				<i class="left arrow icon"></i>
				{{ 'word_cancel' | transloco }}
			</button>
		</div>
	</div>
	<div class="sixteen wide column">
		<button class="ui blue button" (click)="searchForForms = !searchForForms">
			<span>{{ t('search_for_forms') }}...</span>
		</button>
	</div>
	<div *ngIf="searchForForms" class="sixteen wide column">
		<paging-list-view
			*ngIf="page$ | async; let page;"
			[page]="page$ | async"
			[create]="false"
			[navigate]="false"
			[showHeader]="false"
			[isSideNav]="true"
			[mobile]="true"
			(selected)="itemSelected($event)"
			(page_change)="handlePageChange($event, page)"
		>
		</paging-list-view>
	</div>
	<div *ngIf="!searchForForms" class="sixteen wide column">
		<h2>{{ t('quick_record') }}</h2>
		<p>{{ t('log_everything_and_anything_choose_a_template_below') }}</p>
	</div>
	<div
		*ngIf="!searchForForms && !loading && schema_page && schema_page.results"
		class="sixteen wide column"
	>
		<div *ngIf="schema_page.results.length <= 0">
			{{ t('looks_like_your_facility_has_no_fillable_forms') }}
		</div>
		<div class="ui fluid card" *ngFor="let schema of schema_page.results">
			<div class="content">
				<div class="header">{{ schema.name }}</div>
				<div *ngIf="!schema.description" class="description">
					<div
						style="
							background-image: url(&quot;/assets/paragraph.png&quot;);
							background-size: contain;
							background-repeat: no-repeat;
							height: 70px;
							width: 100px;
						"
					></div>
				</div>
				<div *ngIf="schema.description" class="description">
					{{ schema.description }}
				</div>
			</div>
			<div class="extra content">
				<button class="ui button" (click)="goToReport(schema)">
					{{ 'word_use' | transloco }}
				</button>
			</div>
		</div>
	</div>
	<es-loader *ngIf="!schema_page || loading"></es-loader>
	<es-message
		*ngIf="!loading && (loading_error$ | async)"
		[message]="loading_error$ | async"
	></es-message>
</div>
