import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, take, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { DevCycleService } from "app/devcycle/devcycle.service";
import { DevCycleKey } from "app/devcycle/devcycleKeys";
import { Component, ChangeDetectorRef, OnInit, OnDestroy } from "@angular/core";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import { InventoryProductDetailQuery } from "app/shared/eagers";
import { IInventoryProduct } from "@elevatedsignals/amygoodman";
import { TitleCasePipe } from "@angular/common";
import { handleObservableError } from "app/shared/utils";
import {
	Binding,
	FormProperty,
	PropertyGroup,
} from "@elevatedsignals/es-ngx-schema-form";
import { isNotNullOrUndefined } from "app/modules/dashboard/modules/rxjs-operators/isNotNullOrUndefined";

// @deprecated
@Component({
	selector: "inventory-product-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class InventoryProductEzGiUpdateComponent
	extends GenericUpdateComponent<IInventoryProduct>
	implements OnInit, OnDestroy
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			inventory_product_id: {
				type: "number",
				hidden: true,
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			archived: {
				type: "boolean",
				widget: "checkbox",
				title: "Archived",
				title_translation_key: marker("word_archived"),
			},
		},
		required: ["name"],
	};

	inventory_product$ = this._store.select(
		fromDashboard.getSelectedInventoryProduct,
	);

	inventory_product: IInventoryProduct;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
	) {
		super(_store, _cd);
		this.form_title = "Update Inventory Product";
		this.form_title_translation_key = marker(
			"form_title_update_inventory_product",
		);
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");
	}

	ngOnInit() {
		this.inventory_product$
			.pipe(takeUntil(this.destroyed$), isNotNullOrUndefined(), take(1))
			.subscribe((inventory_product) => {
				this.inventory_product = inventory_product;
				this.model.inventory_product_id = this.inventory_product.id;
				this.model.name = this.inventory_product.name;
				this.model.archived = this.inventory_product.archived;
				this._cd.detectChanges();
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(type: any) {
		this.loading$.next(true);
		this._itemService
			.update(
				`inventory_product`,
				type.inventory_product_id,
				{ ...this.inventory_product, ...type },
				InventoryProductDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "inventory_products",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
