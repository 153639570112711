import { combineReducers } from "@ngrx/store";

import * as applicableProducts from "./applicable-products";
import * as applicationMethods from "./application-methods";
import * as auditLogs from "./audit-logs";
import * as batchTypes from "./batch-types";
import * as batches from "./batches";
import * as boms from "./bill-of-materials";
import * as cultivars from "./cultivars";
import * as customFields from "./custom-fields";
import * as customScans from "./custom-scans";
import {
	batchDestructionEventsReducerBuilder,
	State as DEBatchState,
} from "./destruction-events-batch";
import { batchReducerBuilder, State as BatchState } from "./batches";
import * as destructionEvents from "./destruction-events";
import * as destructionLots from "./destruction-lots";
import * as destructionReasons from "./destruction-reasons";
import * as documentSets from "./document-sets";
import * as equipments from "./equipments";
import * as equivalentUnits from "./equivalent-units";
import * as facilities from "./facilities";
import * as growthStageTypes from "./growth-stage-types";
import * as growthStages from "./growth-stages";
import * as hubs from "./hubs";
import * as inventories from "./inventories";
import * as inventoryCategories from "./inventory-categories";
import * as inventoryProducts from "./inventory-products";
import * as inventroyUnits from "./inventory-units";
import * as jobs from "./jobs";
import * as locationTypes from "./location-types";
import * as locations from "./locations";
import * as lots from "./lots";
import * as notifications from "./notifications";
import * as pendingInventories from "./pending-inventories";
import * as plants from "./plants";
import * as printerJobTemplates from "./printer-job-templates";
import * as printerJobs from "./printer-jobs";
import * as printers from "./printers";
import * as productionStageTypes from "./production-stage-types";
import * as productionStages from "./production-stages";
import * as purchaseOrders from "./purchase-orders";
import * as quickRecords from "./quick-records";
import * as rateTypes from "./rate-types";
import * as records from "./records";
import * as relatedBatches from "./related-batches";
import * as reportTypes from "./report-types";
import * as reportingCategories from "./reporting-categories";
import * as reports from "./reports";
import * as roles from "./roles";
import * as schemas from "./schemas";
import * as sensors from "./sensors";
import * as skus from "./skus";
import * as sopCategories from "./sop-categories";
import * as sops from "./sops";
import * as statuses from "./statuses";
import * as substanceTypes from "./substance-types";
import * as tags from "./tags";
import * as users from "./users";
import * as vendorCategories from "./vendor-categories";
import * as vendors from "./vendors";
import * as workOrderOutputs from "./work-order-outputs";
import * as workOrderTypes from "./work-order-types";
import * as workOrders from "./work-orders";
import {
	BatchRelatedRecords,
	EquipmentRelatedRecords,
	LocationRelatedRecords,
	PlantRelatedRecords,
	VendorRelatedRecords,
} from "./related-records";
import * as reportingPeriods from "./reporting-periods";
import * as periods from "./periods";
import * as productionPlans from "./production-plans";

export interface PagingState {
	[x: string]: any;
	[applicableProducts.reducerKey]: applicableProducts.State;
	[applicationMethods.reducerKey]: applicationMethods.State;
	[auditLogs.reducerKey]: auditLogs.State;
	[batchTypes.reducerKey]: batchTypes.State;
	[batches.reducerKey]: BatchState;
	batch_gi: BatchState;
	batch_non_gi: BatchState;
	[boms.reducerKey]: boms.State;
	[cultivars.reducerKey]: cultivars.State;
	[customFields.reducerKey]: customFields.State;
	[customScans.reducerKey]: customScans.State;
	destruction_events_batch_gi: DEBatchState;
	destruction_events_batch_non_gi: DEBatchState;
	[destructionEvents.reducerKey]: destructionEvents.State;
	[destructionLots.reducerKey]: destructionLots.State;
	[destructionReasons.reducerKey]: destructionReasons.State;
	[documentSets.reducerKey]: documentSets.State;
	[equipments.reducerKey]: equipments.State;
	[equivalentUnits.reducerKey]: equivalentUnits.State;
	[facilities.reducerKey]: facilities.State;
	[growthStageTypes.reducerKey]: growthStageTypes.State;
	[growthStages.reducerKey]: growthStages.State;
	[hubs.reducerKey]: hubs.State;
	[inventories.reducerKey]: inventories.State;
	[inventoryCategories.reducerKey]: inventoryCategories.State;
	[inventoryProducts.reducerKey]: inventoryProducts.State;
	[inventroyUnits.reducerKey]: inventroyUnits.State;
	[jobs.reducerKey]: jobs.State;
	[locationTypes.reducerKey]: locationTypes.State;
	[locations.reducerKey]: locations.State;
	[lots.reducerKey]: lots.State;
	[notifications.reducerKey]: notifications.State;
	[pendingInventories.reducerKey]: pendingInventories.State;
	[plants.reducerKey]: plants.State;
	[printerJobTemplates.reducerKey]: printerJobTemplates.State;
	[printerJobs.reducerKey]: printerJobs.State;
	[printers.reducerKey]: printers.State;
	[productionStageTypes.reducerKey]: productionStageTypes.State;
	[productionStages.reducerKey]: productionStages.State;
	[purchaseOrders.reducerKey]: purchaseOrders.State;
	[quickRecords.reducerKey]: quickRecords.State;
	[rateTypes.reducerKey]: rateTypes.State;
	[records.reducerKey]: records.State;
	[relatedBatches.reducerKey]: relatedBatches.State;
	[PlantRelatedRecords.reducerKey]: PlantRelatedRecords.State;
	[EquipmentRelatedRecords.reducerKey]: EquipmentRelatedRecords.State;
	[LocationRelatedRecords.reducerKey]: LocationRelatedRecords.State;
	[BatchRelatedRecords.reducerKey]: BatchRelatedRecords.State;
	[VendorRelatedRecords.reducerKey]: VendorRelatedRecords.State;
	[reportTypes.reducerKey]: reportTypes.State;
	[reportingCategories.reducerKey]: reportingCategories.State;
	[reports.reducerKey]: reports.State;
	[roles.reducerKey]: roles.State;
	[schemas.reducerKey]: schemas.State;
	[sensors.reducerKey]: sensors.State;
	[skus.reducerKey]: skus.State;
	[sopCategories.reducerKey]: sopCategories.State;
	[sops.reducerKey]: sops.State;
	[statuses.reducerKey]: statuses.State;
	[substanceTypes.reducerKey]: substanceTypes.State;
	[tags.reducerKey]: tags.State;
	[users.reducerKey]: users.State;
	[vendorCategories.reducerKey]: vendorCategories.State;
	[vendors.reducerKey]: vendors.State;
	[workOrderOutputs.reducerKey]: workOrderOutputs.State;
	[workOrderTypes.reducerKey]: workOrderTypes.State;
	[workOrders.reducerKey]: workOrders.State;
	[reportingPeriods.reducerKey]: reportingPeriods.State;
	[periods.reducerKey]: periods.State;
	[productionPlans.reducerKey]: productionPlans.State;
}

export const pagingReducers = combineReducers({
	[applicableProducts.reducerKey]: applicableProducts.reducer,
	[applicationMethods.reducerKey]: applicationMethods.reducer,
	[auditLogs.reducerKey]: auditLogs.reducer,
	[batchTypes.reducerKey]: batchTypes.reducer,
	[batches.reducerKey]: batchReducerBuilder(true),
	batch_gi: batchReducerBuilder(true),
	batch_non_gi: batchReducerBuilder(false),
	[boms.reducerKey]: boms.reducer,
	[cultivars.reducerKey]: cultivars.reducer,
	[customFields.reducerKey]: customFields.reducer,
	[customScans.reducerKey]: customScans.reducer,
	destruction_events_batch_gi: batchDestructionEventsReducerBuilder(true),
	destruction_events_batch_non_gi: batchDestructionEventsReducerBuilder(false),
	[destructionEvents.reducerKey]: destructionEvents.reducer,
	[destructionLots.reducerKey]: destructionLots.reducer,
	[destructionReasons.reducerKey]: destructionReasons.reducer,
	[documentSets.reducerKey]: documentSets.reducer,
	[equipments.reducerKey]: equipments.reducer,
	[equivalentUnits.reducerKey]: equivalentUnits.reducer,
	[facilities.reducerKey]: facilities.reducer,
	[growthStageTypes.reducerKey]: growthStageTypes.reducer,
	[growthStages.reducerKey]: growthStages.reducer,
	[hubs.reducerKey]: hubs.reducer,
	[inventories.reducerKey]: inventories.reducer,
	[inventoryCategories.reducerKey]: inventoryCategories.reducer,
	[inventoryProducts.reducerKey]: inventoryProducts.reducer,
	[inventroyUnits.reducerKey]: inventroyUnits.reducer,
	[jobs.reducerKey]: jobs.reducer,
	[locationTypes.reducerKey]: locationTypes.reducer,
	[locations.reducerKey]: locations.reducer,
	[lots.reducerKey]: lots.reducer,
	[notifications.reducerKey]: notifications.reducer,
	[pendingInventories.reducerKey]: pendingInventories.reducer,
	[plants.reducerKey]: plants.reducer,
	[printerJobTemplates.reducerKey]: printerJobTemplates.reducer,
	[printerJobs.reducerKey]: printerJobs.reducer,
	[printers.reducerKey]: printers.reducer,
	[productionStageTypes.reducerKey]: productionStageTypes.reducer,
	[productionStages.reducerKey]: productionStages.reducer,
	[purchaseOrders.reducerKey]: purchaseOrders.reducer,
	[quickRecords.reducerKey]: quickRecords.reducer,
	[rateTypes.reducerKey]: rateTypes.reducer,
	[records.reducerKey]: records.reducer,
	[relatedBatches.reducerKey]: relatedBatches.reducer,
	[PlantRelatedRecords.reducerKey]: PlantRelatedRecords.reducer,
	[EquipmentRelatedRecords.reducerKey]: EquipmentRelatedRecords.reducer,
	[LocationRelatedRecords.reducerKey]: LocationRelatedRecords.reducer,
	[BatchRelatedRecords.reducerKey]: BatchRelatedRecords.reducer,
	[VendorRelatedRecords.reducerKey]: VendorRelatedRecords.reducer,
	[reportTypes.reducerKey]: reportTypes.reducer,
	[reportingCategories.reducerKey]: reportingCategories.reducer,
	[reports.reducerKey]: reports.reducer,
	[roles.reducerKey]: roles.reducer,
	[schemas.reducerKey]: schemas.reducer,
	[sensors.reducerKey]: sensors.reducer,
	[skus.reducerKey]: skus.reducer,
	[sopCategories.reducerKey]: sopCategories.reducer,
	[sops.reducerKey]: sops.reducer,
	[statuses.reducerKey]: statuses.reducer,
	[substanceTypes.reducerKey]: substanceTypes.reducer,
	[tags.reducerKey]: tags.reducer,
	[users.reducerKey]: users.reducer,
	[vendorCategories.reducerKey]: vendorCategories.reducer,
	[vendors.reducerKey]: vendors.reducer,
	[workOrderOutputs.reducerKey]: workOrderOutputs.reducer,
	[workOrderTypes.reducerKey]: workOrderTypes.reducer,
	[workOrders.reducerKey]: workOrders.reducer,
	[reportingPeriods.reducerKey]: reportingPeriods.reducer,
	[periods.reducerKey]: periods.reducer,
	[productionPlans.reducerKey]: productionPlans.reducer,
});
