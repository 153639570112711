import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY, ReplaySubject } from "rxjs";
import {
	Component,
	OnDestroy,
	Injector,
	OnInit,
	ChangeDetectorRef,
} from "@angular/core";
import { IInventoryProduct, MeasureUnit } from "@elevatedsignals/amygoodman";
import { TitleCasePipe } from "@angular/common";
import { handleObservableError } from "app/shared/utils";
import {
	Binding,
	FormProperty,
	PropertyGroup,
} from "@elevatedsignals/es-ngx-schema-form";
import {
	InventoryProductDetailQuery,
	InventoryProductPageQuery,
} from "app/shared/eagers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { TranslocoService } from "@jsverse/transloco";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import { Router } from "@angular/router";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { DevCycleService } from "app/devcycle/devcycle.service";
import { DevCycleKey } from "app/devcycle/devcycleKeys";
import { getCustomizeUnits, getRawUnits } from "app/shared/units";

import { InventoryProductCustomizeUnitsComponent } from "./customize-units.component";
import { InventoryProductManageUnitsComponent } from "./manage-units.component";
import { getProductCode } from "./utils";

@Component({
	selector: "inventory-product-create",
	templateUrl: "./create.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class InventoryProductCreateComponent implements OnDestroy, OnInit {
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			inventory_product_id: {
				type: "number",
				title: "Product to copy from",
				title_translation_key: marker("word_copy_product"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "inventory_products",
						queryString: {
							non_inventory_destruction: "true",
						},
					},
				],
			},
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
			product_code: {
				type: "string",
				title: "Product Code",
				title_translation_key: marker("word_product_code"),
			},
			vendor_ids: {
				type: "array",
				title: "Vendors",
				title_translation_key: marker("word_vendor"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "vendors",
						},
					],
				},
			},
			si_units: {
				type: "boolean",
				widget: "checkbox",
				title: "Use SI units?",
				title_translation_key: marker("form_field_label_use_si_units"),
			},
			inventory_unit_name: {
				type: "string",
				title: "Base Inventory Unit",
				title_translation_key: marker("form_field_label_base_inventory_unit"),
				visibleIf: {
					si_units: [false],
				},
			},
			measure_type: {
				type: "string",
				title: "Measure Type",
				title_translation_key: marker("form_field_label_measure_type"),
				widget: "select",
				oneOf: [
					{
						name: this._titleCasePipe.transform(MeasureUnit.Mass),
						name_translation_key: marker("word_mass"),
						value: MeasureUnit.Mass,
						enum: [MeasureUnit.Mass],
					},
					{
						name: this._titleCasePipe.transform(MeasureUnit.Volume),
						name_translation_key: marker("word_volume"),
						value: MeasureUnit.Volume,
						enum: [MeasureUnit.Volume],
					},
				],
				default: MeasureUnit.Mass,
				visibleIf: {
					si_units: [true],
				},
			},
			inventory_volume_units: {
				type: "array",
				title: "Select SI Units",
				title_translation_key: marker("form_field_label_select_si_units"),
				widget: "select",
				items: {
					type: "string",
					oneOf: ["l", "ml", "gallons"].map((unit) => {
						return {
							name: unit,
							value: unit,
							enum: [unit],
						};
					}),
				},
				visibleIf: {
					allOf: [
						{
							si_units: [true],
						},
						{
							measure_type: [MeasureUnit.Volume],
						},
					],
				},
			},
			inventory_volume_unit: {
				type: "string",
				title: "Display Unit",
				title_translation_key: marker("word_display_unit"),
				widget: "select",
				oneOf: ["l", "ml"].map((unit) => {
					// don't allow gallons not to divide by conversion constant
					return {
						name: unit,
						value: unit,
						enum: [unit],
					};
				}),
				visibleIf: {
					allOf: [
						{
							si_units: [true],
						},
						{
							measure_type: [MeasureUnit.Volume],
						},
					],
				},
			},
			inventory_weight_units: {
				type: "array",
				title: "Select SI Units",
				title_translation_key: marker("form_field_label_select_si_units"),
				widget: "select",
				items: {
					type: "string",
					oneOf: ["g", "kg", "mg", "lbs"].map((unit) => {
						return {
							name: unit,
							value: unit,
							enum: [unit],
						};
					}),
				},
				visibleIf: {
					allOf: [
						{
							si_units: [true],
						},
						{
							measure_type: [MeasureUnit.Mass],
						},
					],
				},
			},
			inventory_weight_unit: {
				type: "string",
				title: "Display Unit",
				title_translation_key: marker("word_display_unit"),
				widget: "select",
				oneOf: ["g", "kg", "mg"].map((unit) => {
					// don't allow lbs not to divide by conversion constant
					return {
						name: unit,
						value: unit,
						enum: [unit],
					};
				}),
				visibleIf: {
					allOf: [
						{
							si_units: [true],
						},
						{
							measure_type: [MeasureUnit.Mass],
						},
					],
				},
			},
			inventory_category_ids: {
				type: "array",
				title: "Inventory Categories",
				title_translation_key: marker("word_inventory_categories"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "inventory_categories",
							text_key: ["name"],
						},
					],
				},
			},
			sku_ids: {
				type: "array",
				title: "Associated Skus",
				title_translation_key: marker("form_field_label_associated_skus"),
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							result_type: "skus",
							text_key: ["sku"],
						},
					],
				},
			},
			require_sku: {
				type: "boolean",
				widget: "checkbox",
				title: "Require SKU",
				title_translation_key: marker("form_field_label_require_sku"),
				description:
					"Require a SKU when creating Inventory of a specific Inventory Product.",
				description_translation_key: marker(
					"form_field_description_require_a_sku_when_creating_inventory_of_a_specific_inventory_product",
				),
			},
			non_inventory_destruction: {
				title: "Non-Inventory Destruction",
				title_translation_key: marker("form_field_label_non_inventory_destruction"),
				description:
					"Enabling this option will only allow the destruction of this Product and will prevent any inventory of this Product from being created.",
				description_translation_key: marker(
					"form_field_description_enabling_this_option_will_only_allow_the_destruction_of_this_product",
				),
				type: "boolean",
				widget: "checkbox",
				default: false,
			},
			require_batch: {
				type: "boolean",
				widget: "checkbox",
				title: "Require Batch",
				default: true,
				title_translation_key: marker("form_field_label_require_batch"),
				description:
					"Require a Batch when creating Inventory of a specific Inventory Product.",
				description_translation_key: marker(
					"form_field_description_require_a_batch_when_creating_inventory_of_a_specific_inventory_product",
				),
			},
			// eslint-disable-next-line no-warning-comments
			// TODO Add Equivalent unit data-select
		},
		anyOf: [
			{
				required: ["name", "product_code", "inventory_unit_name"],
			},
			{
				required: ["name", "product_code", "measure_type", "inventory_weight_unit"],
			},
			{
				required: ["name", "product_code", "measure_type", "inventory_volume_unit"],
			},
			{
				// copy inventory_product
				required: ["name", "product_code", "inventory_product_id"],
			},
		],
	};

	fieldBindings: Record<string, Binding> = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": {
			input: (event, formProperty: FormProperty) => {
				const name = event.target.value;
				if (name && name !== this.previousName) {
					this.previousName = name;

					const productCode = getProductCode(name);

					const parent: PropertyGroup = formProperty.findRoot();
					const product_code: FormProperty = parent.getProperty("product_code");
					product_code.setValue(productCode, false);
				}
			},
		},
	};

	specific_field_id: string | null;
	copyProduct = false;
	form_title = "Create Inventory Product";
	form_title_translation_key = marker("form_title_create_inventory_product");
	submit_button = "Create";
	submit_button_translation_key: any = marker("word_create");
	unitsData: any;
	customize_units_button = "Customize Units";
	fromDetailPage = false;
	model: any = {};
	validators = {};

	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	error$: ReplaySubject<string> = new ReplaySubject();

	manageUnits = this._devCycleService.getVariableSignal(
		DevCycleKey.ManageUnits,
		false,
	);

	private previousName = "";
	private prevInventoryProductId: number | undefined;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
		private readonly _titleCasePipe: TitleCasePipe,
		private readonly _translocoService: TranslocoService,
		private readonly _router: Router,
		private readonly _devCycleService: DevCycleService,
	) {
		this.specific_field_id = this._injector.get("specific_field_id", null);

		this.customize_units_button = this._translocoService.translate(
			"form_button_save_customize_units",
		);
	}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		this.copyProduct = this._injector.get("copy", false);
		const product_id = this._injector.get("inventory_product_id", null);
		this.fromDetailPage = this._injector.get("detail_page", false);

		if (this.copyProduct) {
			if (product_id) {
				this.model.inventory_product_id = product_id;

				this._itemService
					.fetchItem(
						`inventory_product`,
						`${product_id}`,
						InventoryProductDetailQuery,
					)
					.pipe(
						takeUntil(this.destroyed$),
						timeout(50000),
						catchError((error) => {
							/* eslint no-console: off */
							console.error(error);
							return EMPTY;
						}),
					)
					.subscribe((inventory_product) => {
						let units;
						if (this.manageUnits()) {
							units = getRawUnits(inventory_product, this.copyProduct);
						} else {
							units = getCustomizeUnits(inventory_product);
						}
						this.unitsData = { units };
					});
			}

			delete this.schema.properties.si_units;
			delete this.schema.properties.inventory_unit_name;
		} else {
			delete this.schema.properties.inventory_product_id;
		}

		const vendor_ids = this._injector.get("vendor_ids", null);

		const sku_ids = this._injector.get("sku_ids", []);
		this.model = { ...this.model, sku_ids };

		if (vendor_ids) {
			this.model = {
				...this.model,
				vendor_ids,
			};
		}
	}

	onChanges(model) {
		if (!model.inventory_product_id && this.prevInventoryProductId) {
			this.prevInventoryProductId = undefined;
			model.inventory_unit_id = undefined;
		} else if (
			model.inventory_product_id &&
			this.prevInventoryProductId !== model.inventory_product_id
		) {
			this.prevInventoryProductId = model.inventory_product_id;
			model.inventory_unit_id = undefined;
			const firstProductSet = !this.model.inventory_product_id;
			this._itemService
				.fetchItem(`inventory_product`, `${model.inventory_product_id}`, {
					eager: "[inventory_categories, skus, vendors]",
				})
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((product) => {
					if (!firstProductSet || !this.model.inventory_unit_id) {
						this.model.name = `${product.name} copy`;
						this.model.product_code = getProductCode(this.model.name);

						this.model.vendor_ids = product.vendors.map((vendor) => vendor.id);

						if (product.inventory_categories) {
							this.model.inventory_category_ids = product.inventory_categories.map(
								(category) => category.id,
							);
						}

						if (product.skus) {
							this.model.sku_ids = product.skus.map((skus) => skus.id);
						}

						this.model.require_sku = product.require_sku;
						this.model.non_inventory_destruction = product.non_inventory_destruction;
						this.model.require_batch = product.require_batch;
					}
					this._cd.detectChanges();
				});
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		this.createItem(this.model);
	}

	openCustomizedUnits() {
		this._store.dispatch(
			SelectionActions.select({
				result_type: "inventory_products",
				id: this.model.inventory_product_id,
				query: InventoryProductDetailQuery,
			}),
		);

		this._store.dispatch(
			layoutActions.openSidenav({
				component: this.manageUnits()
					? InventoryProductManageUnitsComponent
					: InventoryProductCustomizeUnitsComponent,
				inputs: {
					copyProduct: this.copyProduct,
					onUpdated: (unitsData) => {
						this.unitsData = unitsData;
					},
				},
			}),
		);
	}

	createItem(inventory_product: Partial<IInventoryProduct>) {
		this.loading$.next(true);
		this._itemService
			.add(
				`inventory_products`,
				{
					...inventory_product,
					...(this.model.si_units && {
						inventory_unit_name:
							this.model.measure_type === MeasureUnit.Mass
								? this.model.inventory_weight_unit
								: this.model.inventory_volume_unit,
					}),
					...(this.model.si_units && {
						si_unit_names:
							this.model.measure_type === MeasureUnit.Mass
								? this.model.inventory_weight_units
								: this.model.inventory_volume_units,
					}),
					...(this.unitsData && {
						units: this.unitsData.units,
					}),
				},
				InventoryProductPageQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this.loading$.next(false);
					this.closeSidenav();

					if (this.fromDetailPage) {
						this._router.navigate([`/dashboard/inventory_products/${addedItem.id}`]);
					} else {
						this._store.dispatch(
							ItemActions.addSuccess({
								result_type: "inventory_products",
								addedItem,
								...(this.specific_field_id && {
									specific_field_id: this.specific_field_id,
								}),
							}),
						);
					}
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
