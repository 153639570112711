/* jscpd:ignore-start */
import {
	Component,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
	Injector,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ReplaySubject, EMPTY } from "rxjs";
import { tap, takeUntil, timeout, catchError, skipWhile } from "rxjs/operators";
import { Batch, UserProfile } from "@elevatedsignals/amygoodman";
import { DestructionLotDetailQuery } from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { PasswordInputComponent } from "app/modules/auth/components/password-input";
import { handleObservableError } from "app/shared/utils";
import { SelectionActions } from "app/modules/dashboard/actions/selection.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { ItemService } from "app/modules/dashboard/services/item.service";
/* jscpd:ignore-end */

@Component({
	selector: "destruction-event-move",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class DestructionEventMoveComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	batch$ = this._store.select(fromDashboard.getSelectedBatch);
	batch: Batch;
	destruction_event_ids: number[];
	destruction_lot_id: number = this._injector.get("destruction_lot_id", null);

	user$ = this._store.select(fromDashboard.getProfile);
	user: UserProfile;

	form_title = "Move Destruction Events";
	form_title_translation_key: string = marker(
		"form_title_move_destruction_event",
	);

	submit_button = "Move";
	submit_button_translation_key: string = marker("form_button_move");
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};
	onUpdated: any;
	errorMessage = "";

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			destruction_lot_id: {
				type: "number",
				hidden: true,
			},
			destruction_event_ids: {
				type: "array",
				title: "Selected Destruction Events",
				widget: "data-select",
				items: {
					type: "number",
					oneOf: [
						{
							text_key: ["id"],
							queryString: { destruction_lot_id: this.destruction_lot_id },
							result_type: "destruction_events",
						},
					],
				},
			},
			new_destruction_lot_id: {
				type: "number",
				title: "Destination Destruction Lot",
				title_translation_key: marker(
					"form_field_label_destination_destruction_lot",
				),
				widget: "data-select",
				oneOf: [{ result_type: "destruction_lots" }],
			},
		},
		required: ["destruction_event_ids", "new_destruction_lot_id"],
	};

	validators = {};

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
	) {}

	valid(validator) {
		this.valid$.next(validator);
	}

	ngOnInit() {
		this.destruction_event_ids = this._injector.get("ids", null);
		this.destruction_lot_id = this._injector.get("destruction_lot_id", null);
		this.onUpdated = this._injector.get("onUpdated", null);

		this.user$
			.pipe(
				takeUntil(this.destroyed$),
				skipWhile((user) => user === null),
			)
			.subscribe((user) => {
				this.user = user!;
			});
		this.model = {
			...this.model,
			destruction_event_ids: this.destruction_event_ids,
			destruction_lot_id: this.destruction_lot_id,
		};
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const movement: any = {
			...this.model,
		};
		if (this._globals.gmp_enabled) {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: PasswordInputComponent,
					inputs: {
						onSubmit: (password) => {
							this.moveRequest(movement, true, password);
						},
					},
				}),
			);
		} else {
			this.moveRequest(movement, true);
		}
	}

	onChanges(form_data): void {}

	moveRequest(move_model: any, signing = false, password?: string) {
		const authObject = password
			? { username: this.user.email, password }
			: undefined;
		this.loading$.next(true);

		this._itemService
			.update(
				"destruction_events",
				"move",
				move_model,
				signing
					? {
							...DestructionLotDetailQuery,
							signing: "true",
						}
					: DestructionLotDetailQuery,
				authObject,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap(() => {
					this._store.dispatch(
						SelectionActions.select({
							result_type: "destruction_lots",
							id: this.destruction_lot_id,
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
					this.onUpdated();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
