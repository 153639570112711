import { marker } from "@jsverse/transloco-keys-manager/marker";

export const GeneralInventorySourceDynamicSchemaWithLocation = {
	title: "",
	description: "",
	info: "",
	properties: {
		id: {
			type: "number",
			hidden: true,
		},
		location_id: {
			type: "number",
			title: "Location",
			title_translation_key: marker("word_location"),
			widget: "data-select",
			quick_create: false,
			oneOf: [
				{
					result_type: "locations",
				},
			],
		},
		/**
		 * Inventory Fields
		 */
		inventory_product_id: {
			title: "Product",
			title_translation_key: marker("word_product"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			selectFirstAndOnly: true,
			related_properties: ["location_id"],
			hidden: true,
			oneOf: [
				{
					result_type: "inventory_products",
					text_key: "name",
					value_key: "id",
					queryString: {
						non_zero_inventory: "true",
					},
				},
			],
		},
		vendor_id: {
			type: "number",
			title: "Vendor",
			title_translation_key: marker("word_vendor"),
			widget: "data-select",
			oneOf: [
				{
					result_type: "vendors",
				},
			],
			hidden: true,
			related_properties: ["inventory_product_id", "location_id"],
		},
		batch_id: {
			type: "number",
			title: "Source Batch",
			title_translation_key: marker("form_field_label_source_batch"),
			widget: "data-select",
			quick_create: false,
			related_properties: ["inventory_product_id", "vendor_id", "location_id"],
			selectFirstAndOnly: true,
			oneOf: [
				{
					result_type: "batches",
					queryString: {
						input: "true",
						dont_show_expired: "true",
						non_zero_inventory: "true",
					},
				},
			],
			visibleIf: {
				allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
			},
			hidden: true,
		},
		choose_source_lot: {
			title: "Choose Source Lot",
			title_translation_key: marker("form_field_label_choose_source_lot"),
			type: "boolean",
			widget: "checkbox",
			default: true,
			visibleIf: {
				allOf: [{ batch_id: ["$EXP$ target.value > 0"] }],
			},
			hidden: true,
		},
		lot_id: {
			type: "number",
			title: "Source Lot",
			title_translation_key: marker("form_field_label_source_lot"),
			widget: "data-select",
			quick_create: false,
			related_properties: [
				"inventory_product_id",
				"batch_id",
				"location_id",
				"vendor_id",
			],
			selectFirstAndOnly: true,
			oneOf: [
				{
					result_type: "lots",
					queryString: {
						input: "true",
						dont_show_expired: "false",
						non_zero_inventory: "true",
					},
				},
			],
			visibleIf: {
				allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
			},
		},
		choose_source_inventory: {
			title: "Choose Source Inventory",
			title_translation_key: marker("form_field_label_choose_source_inventory"),
			type: "boolean",
			widget: "checkbox",
			default: false,
		},
		inventory_ids: {
			type: "array",
			title: "Inventory ID",
			title_translation_key: marker("form_field_label_inventory_id"),
			widget: "data-select",
			quick_create: false,
			multi: true,
			related_properties: [
				"inventory_product_id",
				"batch_id",
				"lot_id",
				"location_id",
				"vendor_id",
			],
			selectFirstAndOnly: true,
			items: {
				type: "number",
				oneOf: [
					{
						result_type: "inventories",
						text_key: ["name", "id"],
						text_format: "?1 (#?2)",
						value_key: "id",
						queryString: {
							dont_show_expired: "false",
							non_zero_inventory: "true",
						},
					},
				],
			},
			visibleIf: {
				allOf: [
					{ choose_source_inventory: [true] },
					{ inventory_product_id: ["$EXP$ target.value > 0"] },
				],
			},
		},
		remaining_inventory: {
			title: "Use Remaining Inventory",
			title_translation_key: marker("form_field_label_use_remaining_inventory"),
			type: "boolean",
			widget: "checkbox",
			default: true,
		},
		quantity: {
			type: "number",
			title: `Quantity`,
			title_translation_key: marker("word_quantity"),
			width: "50%",
			visibleIf: {
				allOf: [
					{ inventory_product_id: ["$EXP$ target.value > 0"] },
					{ remaining_inventory: [false] },
				],
			},
		},
		inventory_unit_id: {
			title: "Inventory Unit",
			title_translation_key: marker("word_inventory_unit"),
			type: "number",
			widget: "data-select",
			quick_create: false,
			related_properties: ["inventory_product_id", "inventory_ids"],
			selectFirstAndOnly: true,
			width: "50%",
			shorter_placeholder: true,
			oneOf: [
				{
					result_type: "inventory_units",
				},
			],
			visibleIf: {
				allOf: [
					{ inventory_product_id: ["$EXP$ target.value > 0"] },
					{ remaining_inventory: [false] },
				],
			},
		},
		amount_available: {
			type: "string",
			widget: "string",
			title: "Amount Available",
			readOnly: true,
			visibleIf: {
				allOf: [{ inventory_product_id: ["$EXP$ target.value > 0"] }],
			},
		},
		timestamp: {
			type: "string",
			title: "Timestamp",
			title_translation_key: marker("word_timestamp"),
			widget: "date",
			hidden: false,
		},
	},
	anyOf: [
		/**
		 *  With Batch NOT required
		 */
		// With 'Choose Source Inventory' only
		{
			required: [
				"id",
				"inventory_product_id",
				"choose_source_inventory",
				"lot_id",
				"inventory_ids",
				"inventory_unit_id",
				"quantity",
			],
		},
		// Without 'Choose Source Inventory' or 'Use Remaining'
		{
			required: [
				"id",
				"inventory_product_id",
				"lot_id",
				"inventory_unit_id",
				"quantity",
			],
		},
		// With 'Use Remaining', but not 'Choose Source Inventory'
		{
			required: [
				"id",
				"inventory_product_id",
				"choose_source_inventory",
				"lot_id",
				"remaining_inventory",
			],
		},
		// With 'Choose Source Inventory' and 'Use Remaining'
		{
			required: [
				"id",
				"inventory_product_id",
				"choose_source_inventory",
				"lot_id",
				"inventory_ids",
				"remaining_inventory",
			],
		},
		/**
		 *  With Batch required
		 */
		// With 'Use Remaining' only
		{
			required: [
				"id",
				"inventory_product_id",
				"batch_id",
				"lot_id",
				"remaining_inventory",
			],
		},
		// With 'Choose Source Inventory' only
		{
			required: [
				"id",
				"inventory_product_id",
				"choose_source_inventory",
				"batch_id",
				"lot_id",
				"inventory_ids",
				"inventory_unit_id",
				"quantity",
			],
		},
		// Without 'Choose Source Inventory' or 'Use Remaining'
		{
			required: [
				"id",
				"inventory_product_id",
				"batch_id",
				"lot_id",
				"inventory_unit_id",
				"quantity",
			],
		},
		// With 'Choose Source Inventory' and 'Use Remaining'
		{
			required: [
				"id",
				"inventory_product_id",
				"choose_source_inventory",
				"batch_id",
				"lot_id",
				"inventory_ids",
				"remaining_inventory",
			],
		},
		{
			required: [
				"id",
				"inventory_product_id",
				"batch_id",
				"inventory_unit_id",
				"quantity",
			],
		},
		{
			required: [
				"id",
				"inventory_product_id",
				"choose_source_inventory",
				"batch_id",
				"remaining_inventory",
			],
		},
	],
};
