import { IVendorCategory } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.VendorsCategories;

export type State = SelectionState<IVendorCategory>;

export const reducer = createSelectionReducer<IVendorCategory>(reducerKey);
