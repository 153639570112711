import {
	HeaderQueryType,
	IReportingPeriod,
	Page,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "reporting_periods";

export type State = Page<IReportingPeriod>;

export const reducer = createPagingReducer<IReportingPeriod>(reducerKey, {
	result_type: "reporting_periods",
	title: "Reporting Period",
	title_translation_key: marker("page_title_reporting_periods"),
	title_plural_translation_key: marker("page_title_plural_reporting_periods"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Legacy",
			title_translation_key: marker("checkbox_legacy_reporting_periods"),
			key: "legacy",
			type: HeaderQueryType.checkbox,
			value: false,
		},
	],
});
