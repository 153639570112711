import { Component, OnInit, Injector } from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { handleObservableError } from "app/shared/utils";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { ReplaySubject, EMPTY } from "rxjs";
import { IGrowthStage } from "@elevatedsignals/amygoodman";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";

@Component({
	selector: "growth-stage-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class GrowthStageCreateComponent implements OnInit {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Create a Growth Stage";
	form_title_translation_key: string = marker(
		"form_title_create_a_growth_stage",
	);

	submit_button = "Create Growth Stage";
	submit_button_translation_key: string = marker(
		"form_button_create_growth_stage",
	);

	submit_icon = "plus";
	specific_field_id: string;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	model: any = {};

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
				warning: "Warning: Growth Stage name already in use.",
				warning_translation_key: marker(
					"form_field_warning_growth_stage_name_already_in_use",
				),
			},
			growth_stage_type_id: {
				type: "number",
				minItems: 1,
				title: "Growth Stage Type",
				title_translation_key: marker("word_growth_stage_type"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "growth_stage_types",
						show_all: true,
					},
				],
			},
		},
		required: ["name", "growth_stage_type_id"],
	};

	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private names;

	constructor(
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		this.specific_field_id = this._injector.get("specific_field_id", null);
	}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`growth_stages/fetch/names`, "", { col: "name" })
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const growth_stage: Partial<IGrowthStage> = {
			...this.model,
		};
		this.addGrowthStage(growth_stage);
	}

	onChanges(_error): void {
		// empty
	}

	addGrowthStage(add_growth_stage: Partial<IGrowthStage>) {
		this.loading$.next(true);
		this._itemService
			.add(`growth_stages`, add_growth_stage)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							result_type: "growth_stages",
							addedItem,
							specific_field_id: this.specific_field_id,
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
