import { Plant } from "@elevatedsignals/amygoodman";

import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.Plants;

export type State = SelectionState<Plant>;

export const reducer = createSelectionReducer<Plant>(reducerKey);
