import { HeaderQueryType, ISop, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "sops";

export type State = Page<ISop>;

export const reducer = createPagingReducer<ISop>(reducerKey, {
	result_type: "sops",
	title: "SOP",
	title_plural: "SOPs",
	title_translation_key: marker("page_title_sops"),
	title_plural_translation_key: marker("page_title_plural_sops"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Show Archived",
			key: "showArchived",
			title_translation_key: marker("checkbox_filter_show_archived"),
			type: HeaderQueryType.checkbox,
			value: false,
		},
		{
			title: "SOP category",
			title_translation_key: marker("page_title_sop_categories"),
			key: "sop_category_id",
			text_key: "name",
			value_key: "id",
			type: HeaderQueryType.dataSelect,
			result_type: "sop_categories",
			multiple: false,
			search: true,
			disabled: false,
			value: null,
			required_facility_settings: { use_sop_categories: true },
		},
	],
});
