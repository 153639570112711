import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	OnDestroy,
	ChangeDetectorRef,
	Injector,
	OnInit,
	SimpleChanges,
} from "@angular/core";
import { IInventory, IPurchaseOrder } from "@elevatedsignals/amygoodman";
import { PurchaseOrderDetailQuery } from "app/shared/eagers";
import { handleObservableError } from "app/shared/utils";
import { ESValidator } from "app/shared/es-validator";
import { dateIsBefore } from "app/shared/time-format";
import { Globals } from "app/shared/modules/globals/globals.service";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import {
	CostPerUnitInput,
	CurrencySelect,
	TotalTaxInput,
} from "app/modules/dashboard/modules/es-forms/schemas/fields/costing";
import { getSelectedFacilitySettings } from "app/modules/dashboard/selectors/facility-settings.selector";

import { GenericCreateComponent } from "../generic/generic-create.component";

import { PurchaseOrderReceiveInventoryComponent } from "./po-receive-inventory.component";

@Component({
	selector: "po-create-inventory-order",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class PurchaseOrderCreateInventoryOrderComponent
	extends GenericCreateComponent<IInventory>
	implements OnDestroy, OnInit
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			purchase_order_id: {
				type: "number",
				title: "Purchase Order",
				hidden: true,
			},
			vendor_id: {
				type: "number",
				title: "Vendor",
				title_translation_key: marker("word_vendor"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "vendors",
					},
				],
				quick_create: false,
				selectFirstAndOnly: true,
				hidden: true,
			},
			inventory_product_id: {
				title: "Product",
				title_translation_key: marker("word_product"),
				type: "number",
				widget: "data-select",
				quick_create: false,
				related_properties: ["vendor_id"],
				oneOf: [
					{
						result_type: "inventory_products",
						queryString: {
							non_zero_inventory: "false",
							showArchived: "false",
						},
					},
				],
			},
			value: {
				type: "number",
				title: `Quantity`,
				title_translation_key: marker("word_quantity"),
				visibleIf: {
					inventory_product_id: ["$EXP$ target.value > 0"],
				},
				width: "50%",
			},
			inventory_unit_id: {
				type: "number",
				title: "Inventory Unit",
				title_translation_key: marker("word_inventory_unit"),
				widget: "data-select",
				quick_create: false,
				shorter_placeholder: true,
				related_properties: ["inventory_product_id", "inventory_ids"],
				oneOf: [
					{
						result_type: "inventory_units",
					},
				],
				visibleIf: {
					inventory_product_id: ["$EXP$ target.value > 0"],
				},
				width: "50%",
			},
			cost_per_unit: { ...CostPerUnitInput },
			currency: { ...CurrencySelect, hidden: true, widget: undefined },
			tax: { ...TotalTaxInput },
			timestamp: {
				type: "string",
				title: "Date",
				widget: "date",
				title_translation_key: marker("word_date"),
				warning: "The date must be after Purchase Order Date.",
				warning_translation_key: marker(
					"form_field_warning_the_date_must_be_after_purchase_order_date",
				),
			},
			inventory_already_received: {
				type: "boolean",
				widget: "checkbox",
				default: false,
				title: "Inventory Already Received",
			},
		},
	};

	validators: Record<string, ESValidator> = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/timestamp": (value, property, form) => {
			this.dateValidatorFailed = false;
			const isValueBefore = dateIsBefore(new Date(value), new Date(this.minDate));
			if (isValueBefore) {
				this.dateValidatorFailed = true;

				const error = {
					code: "INVALID_DATE",
					path: `#${property.path}`,
					message: "The date must be in the past",
					params: ["timestamp"],
				};
				return [error];
			}

			return null;
		},
	};

	facilitySettings$ = this._store.select(getSelectedFacilitySettings);

	private purchaseOrder: IPurchaseOrder;
	private copiedInventory: IInventory | null;
	private showFinancialInfo: boolean;
	private minDate: Date;
	private prevInventoryProductId: number | undefined;
	private dateValidatorFailed: boolean;
	private readonly whatChanged: SimpleChanges = {};

	constructor(
		protected readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _injector: Injector,
		private readonly _globals: Globals,
	) {
		super(_store);
		this.form_title = "Create Inventory in a Purchase Order";
		this.form_title_translation_key = marker(
			"form_title_create_inventory_in_a_purchase_order",
		);
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
	}

	valid(valid) {
		this.valid$.next(valid);

		if (this.dateValidatorFailed) {
			this.valid$.next(false);
		}
	}

	ngOnInit() {
		this.showFinancialInfo = this._injector.get("show_financial_info", false);
		this.purchaseOrder = this._injector.get("purchase_order", null);
		this.copiedInventory = this._injector.get("copied_inventory", null);
		this.minDate = new Date(
			this.purchaseOrder.po_date
				? this.purchaseOrder.po_date
				: this.purchaseOrder.created_at,
		);

		this.model = {
			purchase_order_id: this.purchaseOrder.id,
			vendor_id: this.purchaseOrder.vendor_id,
			inventory_product_id: this.copiedInventory?.inventory_product_id,
			value: this.copiedInventory?.orderedAmount,
			inventory_unit_id: this.copiedInventory?.inventory_unit_id,
			...((this.copiedInventory?.cost_per_unit || this.copiedInventory?.tax) && {
				cost_per_unit: this.copiedInventory.cost_per_unit?.amount,
				currency:
					this.copiedInventory.cost_per_unit?.currency ??
					this.copiedInventory.tax?.currency,
				total_tax: this.copiedInventory.tax?.amount,
			}),
			// ...(this.copiedInventory?.created_at && {
			// 	timestamp: new Date(this.copiedInventory.created_at),
			// }),
		};

		this.minDate = new Date(
			this.purchaseOrder.po_date
				? this.purchaseOrder.po_date
				: this.purchaseOrder.created_at,
		);

		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.schema.properties.purchase_order_id = {
			...this.schema.properties.purchase_order_id,
			default: this.purchaseOrder.id,
			readOnly: true,
		};

		this.schema.anyOf = [
			{
				required: [
					"purchase_order_id",
					"inventory_product_id",
					"inventory_unit_id",
					"value",
				],
			},
		];

		if (this.showFinancialInfo) {
			this.facilitySettings$.subscribe((facilitySettings) => {
				this.model.currency =
					facilitySettings.data?.options?.facility_currency ?? "CAD";
			});
		} else {
			delete this.schema.properties.cost_per_unit;
			delete this.schema.properties.currency;
			delete this.schema.properties.tax;
		}

		// ESS-8465 If vendor is customer ignore vendor check for purpose of returns etc.
		const purchaseOrderVendorIsCustomer =
			this.purchaseOrder.vendor?.customer || false;
		if (purchaseOrderVendorIsCustomer) {
			const index = (
				this.schema.properties.inventory_product_id.related_properties ?? []
			).indexOf("vendor_id");
			if (index !== -1) {
				this.schema.properties.inventory_product_id.related_properties.splice(
					index,
					1,
				);
			}
		}

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		if (!model.inventory_product_id && this.prevInventoryProductId) {
			this.prevInventoryProductId = undefined;
			model.inventory_unit_id = undefined;
		} else if (
			model.inventory_product_id &&
			this.prevInventoryProductId !== model.inventory_product_id
		) {
			this.prevInventoryProductId = model.inventory_product_id;
			model.inventory_unit_id = undefined;
			const firstProductSet = !this.model.inventory_product_id;
			// Set unit to default unit of product
			this._itemService
				.fetchItem(`inventory_product`, `${model.inventory_product_id}`)
				.pipe(
					takeUntil(this.destroyed$),
					timeout(50000),
					catchError((error) => {
						/* eslint no-console: off */
						console.error(error);
						return EMPTY;
					}),
				)
				.subscribe((inventory_product) => {
					if (!firstProductSet || !this.model.inventory_unit_id) {
						this.model.inventory_unit_id = inventory_product.display_unit_id;
					}

					this._cd.detectChanges();
				});
		}
	}

	createItem(inventory) {
		this.loading$.next(true);
		this._itemService
			.add(
				`purchase_order/${this.purchaseOrder.id}/inventory`,
				inventory,
				PurchaseOrderDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((updatedItem: IPurchaseOrder) => {
				this._store.dispatch(
					ItemActions.updateSuccess({
						updatedItem,
						result_type: "purchase_orders",
					}),
				);

				this.loading$.next(false);
				this.closeSidenav();

				if (this.model.inventory_already_received) {
					if (updatedItem.inventories && updatedItem.inventories.length) {
						const pendingPoInventory = updatedItem.inventories.pop();
						this._store.dispatch(
							layoutActions.openSidenav({
								component: PurchaseOrderReceiveInventoryComponent,
								inputs: {
									purchase_order: this.purchaseOrder,
									pending_inventory: pendingPoInventory,
									copied_inventory: this.copiedInventory,
								},
							}),
						);
					}
				}
			});
	}
}
