import {
	ChangeDetectorRef,
	Component,
	Injector,
	OnDestroy,
	OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { findIndex } from "app/modules/dashboard/vendor/lodash";
import {
	IMeasureEvent,
	ISelectListItem,
	MeasureUnit,
} from "@elevatedsignals/amygoodman";
import { BatchDetailQuery, SeedLotDetailQuery } from "app/shared/eagers";
import { Globals } from "app/shared/modules/globals/globals.service";
import { handleObservableError } from "app/shared/utils";
import { EMPTY, ReplaySubject } from "rxjs";
import { catchError, timeout, takeUntil, tap } from "rxjs/operators";
import { UnitToBaseMeasureUnitPipe } from "app/modules/dashboard/modules/es-pipes/units/unit_to_base_measure_unit.pipe";
import { BaseMeasureUnitToUnitPipe } from "app/modules/dashboard/modules/es-pipes/units/base_measure_unit_to_unit.pipe";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";

import { UnitToGramsPipe } from "../../../modules/es-pipes/unit-to-grams.pipe";

import { AdjustmentType } from "./create.component";

@Component({
	selector: "measure-event-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
// @deprecated
export class UpdateMeasureEventComponent implements OnInit, OnDestroy {
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	form_title = "Update Adjustment";
	form_title_translation_key: string = marker("form_title_update_adjustment");
	submit_button = "Update Adjustment";
	submit_button_translation_key: string = marker(
		"form_button_update_adjustment",
	);

	submit_icon = "edit";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	measure_event: IMeasureEvent;
	measure_setting: string;
	related_item_id: number;
	substance_type_id: number;
	measure_unit: MeasureUnit;

	model: any = {};
	adjustment_reasons: { name: string; name_translation_key?: string }[] = [
		{ name: "Harvested", name_translation_key: marker("word_harvested") },
		{ name: "Dried", name_translation_key: marker("word_dried") },
		{
			name: "Processing Loss",
			name_translation_key: marker("word_processing_loss"),
		},
		{ name: "Packaged", name_translation_key: marker("word_packaged") },
		{ name: "Transferred", name_translation_key: marker("word_transferred") },
		{ name: "Drying Loss", name_translation_key: marker("word_drying_loss") },
		{ name: "Destroyed", name_translation_key: marker("word_destroyed") },
		{
			name: "Lost/Theft",
			name_translation_key: marker("form_field_value_lost_theft"),
		},
		{
			name: "Lab Test",
			name_translation_key: marker("form_field_value_lab_test"),
		},
		{ name: "Returns", name_translation_key: marker("word_returns") },
		{
			name: "Opening Inventory",
			name_translation_key: marker("word_opening_inventory"),
		},
		{ name: "Other", name_translation_key: marker("word_other") },
	];

	adjustment_types: ISelectListItem[] = [
		{
			name: "Addition",
			name_translation_key: marker("word_addition"),
			value: AdjustmentType.Addition,
			enum: [AdjustmentType.Addition],
		},
		{
			name: "Subtraction",
			name_translation_key: marker("word_subtraction"),
			value: AdjustmentType.Subtraction,
			enum: [AdjustmentType.Subtraction],
		},
		{
			name: "New Value",
			name_translation_key: marker("form_field_value_new_value"),
			value: AdjustmentType.New,
			enum: [AdjustmentType.New],
		},
	];

	weight_unit: string = this._globals.weight_unit;

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			substance_type_id: {
				type: "number",
				title: "Substance",
				title_translation_key: marker("word_substance"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "substance_types",
					},
				],
			},
			value: {
				widget: "number",
				type: "number",
				step: "0.00001",
				title: `Value (${this.weight_unit})`,
				title_translation_key: marker("form_field_label_value"),
				title_translation_params: { units: this.weight_unit },
			},
			reason: {
				type: "string",
				title: "Reason",
				title_translation_key: marker("word_reason"),
				widget: "select",
				oneOf: this.adjustment_reasons.map((item) => {
					return {
						name: item.name,
						name_translation_key: item.name_translation_key,
						value: item.name,
						enum: [item.name],
					};
				}),
			},
			reason_other: {
				type: "string",
				title: "Other Reason",
				title_translation_key: marker("form_field_label_other_reason"),
				visibleIf: {
					reason: ["Other"],
				},
				default: "",
			},
			description: {
				type: "string",
				title: "Description",
				title_translation_key: marker("word_description"),
				widget: "textarea",
			},
			timestamp: {
				type: "string",
				title: "Date",
				title_translation_key: marker("word_date"),
				widget: "date",
			},
		},
		required: ["id", "substance_type_id", "reason", "value"],
	};

	validators = {};

	constructor(
		private readonly _injector: Injector,
		private readonly _store: Store<fromDashboard.State>,
		private readonly _itemService: ItemService,
		private readonly _cd: ChangeDetectorRef,
		private readonly _unitToGrams: UnitToGramsPipe,
		private readonly _unitToBaseMeasureUnit: UnitToBaseMeasureUnitPipe,
		private readonly _baseMeasureUnitToUnit: BaseMeasureUnitToUnitPipe,
		private readonly _globals: Globals,
	) {}

	valid(val) {
		this.valid$.next(val);
	}

	ngOnInit() {
		this.measure_event = this._injector.get("measure_event", null);
		this.measure_setting = this._injector.get("measure_setting", undefined);
		this.measure_unit = this._injector.get(
			"measure_unit",
			this.measure_event.measure_unit,
		);
		this.related_item_id = this._injector.get(
			"related_item_id",
			this._injector.get("batch_id", null),
		);

		const units = this._globals.getFacilityUnit(
			this.measure_unit,
			this.measure_setting,
		);

		this.schema.properties.value.title = `Value (${units})`;
		this.schema.properties.value.title_translation_params = {
			units: units ?? "",
		};

		this.model = {
			id: this.measure_event.id,
			substance_type_id: this.measure_event.substance_type_id,
			value: Number(
				this._baseMeasureUnitToUnit.transform(
					this.measure_event.value,
					this.measure_event.measure_unit,
					this._globals.getFacilityUnit(
						this.measure_event.measure_unit,
						this.measure_setting,
					),
				),
			),
			timestamp: this.measure_event.timestamp,
		};

		if (this.measure_event.notes && this.measure_event.notes[0]) {
			const note = this.measure_event.notes[0];

			const foundAdjustmentReasonIndex = findIndex(
				this.adjustment_reasons,
				(item) => item.name === note.subject,
			);

			if (foundAdjustmentReasonIndex >= 0) {
				this.model = {
					...this.model,
					reason: note.subject,
					description: note.content,
				};
			} else {
				this.model = {
					...this.model,
					reason: "Other",
					reason_other: note.subject,
					description: note.content,
				};
			}
		}

		this._cd.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const adjustment = {
			id: this.model.id,
			substance_type_id: this.model.substance_type_id,
			measure_unit: this.measure_event.measure_unit,
			value: this._unitToBaseMeasureUnit.transform(
				this.model.value,
				this._globals.getFacilityUnit(
					this.measure_event.measure_unit,
					this.measure_setting,
				) ?? "",
				this.measure_event.measure_unit,
			),
			reason:
				this.model.reason === "Other"
					? this.model.reason_other || this.model.reason
					: this.model.reason,
			description: this.model.description ?? "",
			timestamp: this.model.timestamp,
		};
		this.updateEvent(adjustment);
	}

	onChanges(_form_data): void {}

	updateEvent(update_event: any) {
		this.loading$.next(true);
		let query = BatchDetailQuery;
		query = {
			...query,
			result_type: "batch",
			result_type_id: `${this.related_item_id}`,
		};
		this._itemService
			.update(`measurement`, this.measure_event.id, update_event, query)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "batches",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
