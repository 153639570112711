import {
	take,
	takeUntil,
	tap,
	timeout,
	catchError,
	filter,
} from "rxjs/operators";
import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	Injector,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { handleObservableError } from "app/shared/utils";
import { ReplaySubject, combineLatest, of, EMPTY } from "rxjs";
import { Batch } from "@elevatedsignals/amygoodman";
import { BatchDetailQuery } from "app/shared/eagers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import * as fromDashboard from "app/modules/dashboard/reducers";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";

@Component({
	selector: "batch-harvest",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class BatchHarvestComponent
	extends GenericUpdateComponent<Batch>
	implements OnInit, OnDestroy
{
	valid$: ReplaySubject<boolean> = new ReplaySubject(1);
	error$: ReplaySubject<string> = new ReplaySubject();

	batch_id: number;
	work_order_id: number;

	form_title = "Close a Harvest";
	form_title_translation_key: string = marker("form_title_close_a_harvest");
	submit_button = "Close Harvest";
	submit_button_translation_key: string = marker("form_button_close_harvest");
	submit_icon = "clipboard check";
	loading = false;

	loading$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
	destroyed$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

	batch$ = this._store.select(fromDashboard.getSelectedBatch);

	model: any = {};

	schema = {
		title: "",
		description: "",
		info: "",
		properties: {
			id: {
				type: "number",
				hidden: true,
			},
			location_id: {
				type: "number",
				title: "Destination Location",
				title_translation_key: marker("form_field_label_destination_location"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "locations",
					},
				],
			},
			production_stage_id: {
				type: "number",
				title: "Production Stage",
				title_translation_key: marker("word_production_stage"),
				widget: "data-select",
				oneOf: [
					{
						result_type: "production_stages",
					},
				],
			},
			timestamp: {
				type: "string",
				title: "Harvest Date",
				title_translation_key: marker("form_field_label_harvest_date"),
				widget: "date",
			},
		},
		required: ["location_id", "production_stage_id"],
	};

	validators = {};

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
	}

	ngOnInit() {
		this.batch_id = this._injector.get("batch_id", null);
		this.work_order_id = this._injector.get("work_order_id", null);
		combineLatest([of(this.batch_id), this.batch$])
			.pipe(
				takeUntil(this.destroyed$),
				filter(
					(values): values is [number, Batch] =>
						values[1] !== undefined && values[1].id === values[0],
				),
				take(1),
			)
			.subscribe(([_id, batch]) => {
				this.model = {
					...this.model,
					production_stage_id: batch.production_stage_id,
					location_id: batch.location_id,
				};
			});
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onSubmit() {
		const harvest: any = {
			...this.model,
			work_order_id: this.work_order_id,
			batch_id: this.batch_id,
		};
		this.updateItem(harvest);
	}

	updateItem(update_batch: any) {
		this.loading$.next(true);
		this._itemService
			.update(
				`batch`,
				`${this.batch_id}/harvest/close`,
				update_batch,
				BatchDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((e) => {
					this.error$.next(handleObservableError(e, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "batches",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}

	closeSidenav() {
		this._store.dispatch(layoutActions.closeSidenav());
	}
}
