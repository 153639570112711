import { Notification, Page } from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "notifications";

export type State = Page<Notification>;

export const reducer = createPagingReducer<Notification>(reducerKey, {
	result_type: "notifications",
	title: "Notification",
	title_translation_key: marker("page_title_notifications"),
	title_plural_translation_key: marker("page_title_plural_notifications"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
});
