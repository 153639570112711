/* eslint-disable @typescript-eslint/naming-convention */
import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { Notification, Page } from "@elevatedsignals/amygoodman";

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 *
 * The 'type' utility function coerces strings into string
 * literal types and runs a simple check to guarantee all
 * action types in the application are unique.
 */

export const notificationActions = createActionGroup({
	source: "Notification",
	events: {
		fetch: emptyProps(),
		"fetch success": props<{ result: any }>(),
		"fetch page success": props<{ page: Page<Notification> }>(),
		"fetch fail": props<{ error: string }>(),
		update: props<{ notification: Notification }>(),
		"update success": props<{ notification: Notification }>(),
		"update fail": props<{ error: string }>(),
		"new unread": props<{ notification: Notification }>(),
	},
});
