import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	OnDestroy,
	Injector,
	OnInit,
	ChangeDetectorRef,
} from "@angular/core";
import { IInventoryProduct } from "@elevatedsignals/amygoodman";
import { handleObservableError } from "app/shared/utils";
import { InventoryProductPageQuery } from "app/shared/eagers";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { getSelectedFacilitySettings } from "app/modules/dashboard/selectors/facility-settings.selector";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { GenericCreateComponent } from "../generic/generic-create.component";

@Component({
	selector: "inventory-product-ez-gi-create",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class InventoryProductEzGiCreateComponent
	extends GenericCreateComponent<IInventoryProduct>
	implements OnDestroy, OnInit
{
	schema: any = {
		title: "",
		description: "",
		info: "",
		properties: {
			name: {
				type: "string",
				title: "Name",
				title_translation_key: marker("word_name"),
			},
		},
		anyOf: [
			{
				required: ["name"],
			},
		],
	};

	facilitySettings$ = this._store.select(getSelectedFacilitySettings);
	facilityWeightUnit = "g";
	internalVendorId: number;
	cannabisCategoryId: number;

	specific_field_id: string | null;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store);

		this.form_title = "Create Inventory Product";
		this.form_title_translation_key = marker(
			"form_title_create_inventory_product",
		);
		this.submit_button = "Create";
		this.submit_button_translation_key = marker("word_create");
		this.specific_field_id = this._injector.get("specific_field_id", null);
		this.facilitySettings$
			.pipe(takeUntil(this.destroyed$))
			.subscribe((settings) => {
				this.facilityWeightUnit = settings.data?.options?.weight_unit ?? "g";
				this.internalVendorId = settings.data?.defaults?.vendor_id ?? NaN;
				this.cannabisCategoryId =
					settings.data?.defaults?.inventory_category_id ?? NaN;
			});
	}

	ngOnInit() {}
	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	createItem(inventory_product: Partial<IInventoryProduct>) {
		const create = {
			name: inventory_product.name,
			vendor_ids: [this.internalVendorId],
		};
		this.loading$.next(true);
		this._itemService
			.add(`inventory_products`, create, InventoryProductPageQuery)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((addedItem) => {
					this._store.dispatch(
						ItemActions.addSuccess({
							result_type: "inventory_products",
							addedItem,
							...(this.specific_field_id && {
								specific_field_id: this.specific_field_id,
							}),
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
