import {
	HeaderQueryType,
	IDestructionLot,
	Page,
} from "@elevatedsignals/amygoodman";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { DestructionLotPageQuery } from "app/shared/eagers";

import { createPagingReducer } from "./createPagingReducer";

export const reducerKey = "destruction_lots";

export type State = Page<IDestructionLot>;

export const reducer = createPagingReducer<IDestructionLot>(reducerKey, {
	result_type: "destruction_lots",
	title: "Destruction Lot",
	title_translation_key: marker("page_title_destruction_lots"),
	title_plural_translation_key: marker("page_title_plural_destruction_lots"),
	results: [],
	page: 1,
	page_size: 50,
	total: 0,
	header_queries: [
		{
			title: "Status Filter",
			key: "lot_status",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Open",
					translationKey: marker("word_open"),
					value: "OPEN",
				},
				{
					label: "Closed",
					translationKey: marker("word_closed"),
					value: "CLOSED",
				},
				{
					label: "Closed - Pending signatures",
					translationKey: marker("destruction_filter_closed_pending_signatures"),
					value: "CLOSED_UNSIGNED",
				},
				{
					label: "Archived",
					translationKey: marker("word_archived"),
					value: "ARCHIVED",
				},
				{
					label: "All",
					translationKey: marker("word_all"),
					value: "ALL",
				},
			],
			value: "OPEN",
		},
		{
			title: "Closed Date (start)",
			key: "start_date",
			title_translation_key: marker("destruction_start_date"),
			type: HeaderQueryType.date,
			value: "",
		},
		{
			title: "Closed Date (end) ",
			title_translation_key: marker("destruction_end_date"),
			key: "end_date",
			type: HeaderQueryType.date,
			value: "",
		},
		{
			title: "Verifications",
			title_translation_key: marker("page_title_plural_verifications"),
			key: "verification_status",
			type: HeaderQueryType.select,
			options: [
				{
					label: "Verified",
					translationKey: marker("word_verified"),
					value: "VERIFIED",
				},
				{
					label: "Unverified",
					translationKey: marker("word_unverified"),
					value: "UNVERIFIED",
				},
			],
			multiple: true,
			disabled: false,
			value: ["VERIFIED", "UNVERIFIED"],
		},
	],
	query: DestructionLotPageQuery,
});
