import { IWorkOrder, IWorkOrderType, Page } from "@elevatedsignals/amygoodman";
import { Store } from "@ngrx/store";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { ItemService } from "app/modules/dashboard/services/item.service";
import { timeout, catchError, takeUntil, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
	Injector,
} from "@angular/core";
import { WorkOrderDetailQuery } from "app/shared/eagers";
import { layoutActions } from "app/modules/dashboard/actions/layout.actions";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

import { UpdateReasonComponent } from "../generic/update-reason.component";

@Component({
	selector: "work-order-update",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderUpdateComponent
	extends GenericUpdateComponent<IWorkOrder>
	implements OnInit, OnDestroy
{
	validators = {
		// eslint-disable-next-line @typescript-eslint/naming-convention
		"/name": (value, property, form) => {
			if (value !== "") {
				if (this.names && this.names.includes(value.trim())) {
					return { name: {} };
				}
			}
			return null;
		},
	};

	private names;
	private readonly work_order: IWorkOrder;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _itemService: ItemService,
		private readonly _injector: Injector,
	) {
		super(_store, _cd);
		this.work_order = this._injector.get("work_order", null);
		this.form_title = `Update Work Order #${this.work_order.id}`;

		this.form_title_translation_key = marker("form_title_update_work_order");
		this.form_title_translation_params = {
			work_order_id: `#${this.work_order.id}`,
		};
		this.submit_button = "Update";
		this.submit_button_translation_key = marker("word_update");

		this.schema = {
			title: "",
			description: "",
			info: "",
			properties: {
				id: {
					type: "number",
					hidden: true,
				},
				work_order_type_id: {
					type: "number",
					title: "Work Order Type",
					title_translation_key: marker("word_work_order_type"),
					widget: "data-select",
					oneOf: [
						{
							result_type: "work_order_types",
							queryString: {
								not_special_filter: true,
							},
						},
					],
				},
				name: {
					type: "string",
					title: "Name",
					title_translation_key: marker("word_name"),
					warning: "Warning: Work Order name already in use.",
					warning_translation_key: marker(
						"form_field_warning_work_order_name_already_in_use",
					),
				},
				description: {
					type: "string",
					widget: "textarea",
					title: "Description",
					title_translation_key: marker("word_description"),
				},
				location_id: {
					type: "number",
					title: "Work Order Location",
					title_translation_key: marker("form_field_label_work_order_location"),
					widget: "data-select",
					oneOf: [
						{
							result_type: "locations",
						},
					],
				},
				manual_processing_loss: {
					title: "Manual Processing Loss",
					title_translation_key: marker("form_field_label_manual_processing_loss"),
					type: "boolean",
					widget: "checkbox",
					default: false,
				},
			},
			required: ["work_order_type_id"],
		};
	}

	ngOnInit() {
		this.model.id = this.work_order.id;
		this.model.name = this.work_order.name;
		this.model.description = this.work_order.description;
		this.model.work_order_type_id = this.work_order.work_order_type_id;
		this.model.location_id = this.work_order.location_id;
		this.model.manual_processing_loss = this.work_order.manual_processing_loss;

		if (
			!this.model.location_id &&
			this.work_order.work_order_type?.default_location_id
		) {
			this.model.location_id = this.work_order.work_order_type.default_location_id;
		}

		if (!this.work_order.work_order_type?.allow_location_change) {
			this.schema.properties.location_id.readOnly = true;
		}

		// Get a list of all names currently in use.
		this.loading$.next(true);
		this._itemService
			.fetchItem(`work_orders/fetch/names`, "", {
				col: "name",
				except: this.work_order.name,
			})
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.subscribe((value) => {
				// For duplicate name value validation
				this.names = value;
			});
		this.loading$.next(false);
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	updateItem(workOrder: Partial<IWorkOrder>) {
		// this.work_order.location_id can be null so make sure it's being cast to undefined
		const globalWorkOrder = this.work_order.location_id
			? this.work_order.location_id
			: undefined;

		if (workOrder.location_id === globalWorkOrder) {
			this.updateRecord(workOrder);
		} else {
			this._store.dispatch(
				layoutActions.openSidenav({
					component: UpdateReasonComponent,
					inputs: {
						onSubmit: (reason: any) => {
							const newWorkOrder = {
								...workOrder,
								reason,
							};
							this.updateRecord(newWorkOrder);
						},
						form_title: "Update Work Order Location",
						form_title_translation_key: marker(
							"form_title_update_work_order_location",
						),
						submit_button: "Update Location",
						submit_button_translation_key: marker("form_button_update_location"),
						update_reason_field_label_translation_key: marker(
							"form_field_label_update_location_reason",
						),
						gmpAskPassword: false,
					},
				}),
			);
		}
	}

	updateRecord(work_order: Partial<IWorkOrder>) {
		this.loading$.next(true);
		if (
			(this.work_order.harvest_batch || this.work_order.drying_batch) &&
			!work_order.location_id
		) {
			work_order.location_id = undefined;
		}
		if (!work_order.description) {
			work_order.description = "";
		}
		this._itemService
			.update(
				`work_order`,
				Number(work_order.id),
				work_order,
				WorkOrderDetailQuery,
			)
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((error) => {
					this.error$.next(handleObservableError(error, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
