import { IFacility, UserProfile } from "@elevatedsignals/amygoodman";
import { environment } from "environments/environment";
import { datadogRum } from "@datadog/browser-rum";

export class TrackingService {
	private static readonly _environmentSubDomain = environment.host.split(".")[0];

	static addFeatureFlagEvaluation(key: string, value: any) {
		datadogRum.addFeatureFlagEvaluation(key, value);
	}

	static identifyUser(userProfile: UserProfile) {
		datadogRum.setUser({
			id: userProfile.id.toString(),
			name: [userProfile.first_name, userProfile.last_name].join(" "),
			email: userProfile.email,
			facility: userProfile.facility.name,
			facilityId: userProfile.facility.id.toString(),
			permissions: userProfile.permissions,
		});
	}

	static getUserId(userProfile: UserProfile): string {
		const userEventId: string = [userProfile.id, this._environmentSubDomain].join(
			"-",
		);

		return userEventId;
	}

	static getGroupId(facility: IFacility): string {
		const groupEventId: string = [facility.id, this._environmentSubDomain].join(
			"-",
		);

		return groupEventId;
	}

	static getUserName(userProfile: UserProfile): string {
		return `${userProfile.first_name} ${userProfile.last_name}`;
	}
}
