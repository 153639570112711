import {
	createSelectionReducer,
	SelectionState,
} from "./createSelectionReducer";
import { ItemKey } from "./keys";

export const reducerKey = ItemKey.CustomScans;

export type State = SelectionState<any>;

export const reducer = createSelectionReducer<any>(reducerKey);
