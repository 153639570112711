import { Store } from "@ngrx/store";
import { timeout, catchError, tap, takeUntil } from "rxjs/operators";
import { EMPTY, Observable } from "rxjs";
import {
	Component,
	ChangeDetectorRef,
	Injector,
	OnInit,
	OnDestroy,
} from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { marker } from "@jsverse/transloco-keys-manager/marker";
import { WorkOrderService } from "app/modules/dashboard/services/work-order.service";
import { Globals } from "app/shared/modules/globals/globals.service";
import { SubstanceTypes, MeasureUnit } from "@elevatedsignals/amygoodman";
import { GramsToUnitPipe } from "app/modules/dashboard/modules/es-pipes/grams-to-unit.pipe";
import { UnitToBaseMeasureUnitPipe } from "app/modules/dashboard/modules/es-pipes/units/unit_to_base_measure_unit.pipe";
import { handleObservableError } from "app/shared/utils";
import { ItemActions } from "app/modules/dashboard/actions/item.actions";
import { GenericUpdateComponent } from "app/modules/dashboard/pages/sidenav/generic/generic-update.component";
import * as fromDashboard from "app/modules/dashboard/reducers";

@Component({
	selector: "work-order-add-weight",
	templateUrl: "../form-view.component.html",
	styleUrls: ["../sidenav.scss"],
})
export class WorkOrderAddWeightComponent
	extends GenericUpdateComponent<{
		id: number;
		batch_id: number;
		work_order_id: boolean;
		weight: number;
	}>
	implements OnInit, OnDestroy
{
	weight_unit = this._globals.weight_unit;

	inventory_types = [
		{
			name: "Weight",
			name_translation_key: marker("word_weight"),
			value: MeasureUnit.Mass,
			enum: [MeasureUnit.Mass],
		},
		{
			name: "Quantity",
			name_translation_key: marker("word_quantity"),
			value: MeasureUnit.Quantity,
			enum: [MeasureUnit.Quantity],
		},
	];

	private prevMeasureUnit: MeasureUnit;

	// Injected from other component
	private batch_id: number;
	private work_order_id: number;
	private substance_type: SubstanceTypes;
	private drying: boolean;
	private source: boolean;
	private total_fresh: number;
	private readonly duplicate_timestamp: boolean = false;

	constructor(
		protected _store: Store<fromDashboard.State>,
		protected _cd: ChangeDetectorRef,
		private readonly _workOrderService: WorkOrderService,
		private readonly _unitToBaseMeasureUnitPipe: UnitToBaseMeasureUnitPipe,
		private readonly _gramsToUnit: GramsToUnitPipe,
		private readonly _globals: Globals,
		private readonly _injector: Injector,
		private readonly _translocoService: TranslocoService,
	) {
		super(_store, _cd);
		this.form_title = "Add Weight";
		this.form_title_translation_key = marker("form_title_add_weight");
		this.submit_button = "Add";
		this.submit_button_translation_key = marker("word_add");
		this.submit_icon = "plus";

		this.duplicate_timestamp = this._injector.get("duplicate_timestamp", false);

		this.schema = {
			title: "",
			description: "",
			info: "",
			properties: {
				value: {
					type: "number",
					title: `Weight (${this._globals.getFacilityUnit(MeasureUnit.Mass)})`,
					title_translation_key: marker("form_field_label_value"),
					title_translation_params: {
						units: this._globals.getFacilityUnit(MeasureUnit.Mass),
					},
				},
				timestamp: {
					type: "string",
					title: "Date",
					title_translation_key: marker("word_date"),
					widget: "date",
				},
			},
			anyOf: [
				{
					required: ["use_remaining"],
				},
				{
					required: ["value"],
				},
			],
		};
	}

	ngOnInit() {
		if (this._globals.gmp_enabled) {
			delete this.schema.properties.timestamp;
		}

		this.batch_id = this._injector.get("batch_id", null);
		this.work_order_id = this._injector.get("work_order_id", null);

		this.substance_type = this._injector.get(
			"substance_type",
			SubstanceTypes.FreshCannabis,
		);
		this.drying = this._injector.get("drying", false);
		this.source = this._injector.get("source", false);

		this.total_fresh = this._injector.get("total_fresh", null);
		if (this.drying) {
			if (this.source) {
				this.form_title = "Add Fresh Cannabis";
				this.form_title_translation_key = marker("form_title_add_fresh_cannabis");
				this.substance_type = SubstanceTypes.FreshCannabis;
				if (this.total_fresh) {
					const total = this._gramsToUnit.transform(
						this.total_fresh,
						this.weight_unit,
					);
					this.schema.properties.value.title += ` | ${total} ${this.weight_unit} remaining`;
					this.schema.properties.value.title_translation_params = {
						weight_unit: this.weight_unit,
						amount_remaining_string: `| ${total} ${this.weight_unit} remaining`,
					};
				}
				this.schema.properties.use_remaining = {
					title: "Use Remaining Weight",
					title_translation_key: marker("form_field_label_use_remaining_weight"),
					type: "boolean",
					widget: "checkbox",
					default: false,
				};
				this.schema.properties.value.visibleIf = {
					use_remaining: [false],
				};
				if (this._globals.gmp_enabled) {
					this.schema.order = ["use_remaining", "value"];
				} else {
					this.schema.order = ["use_remaining", "value", "timestamp"];
				}
			} else {
				this.form_title = "Record Dried Output";
				this.form_title_translation_key = marker("form_title_record_dried_output");
				this.schema.properties.batch_id = {
					type: "number",
					title: "Destination Batch",
					title_translation_key: marker("form_field_label_destination_batch"),
					widget: "data-select",
					oneOf: [
						{
							result_type: "batches",
							queryString: {
								input: "true",
								dont_show_expired: "true",
							},
						},
					],
				};
				this.model.batch_id = this.batch_id;

				this.schema.properties.output_type = {
					title: "Output",
					title_translation_key: marker("word_output"),
					type: "string",
					widget: "select",
					oneOf: [
						{
							name: SubstanceTypes.DriedCannabis,
							name_translation_key: marker("word_dried_cannabis"),
							value: SubstanceTypes.DriedCannabis,
							enum: [SubstanceTypes.DriedCannabis],
						},
						{
							name: "Other",
							name_translation_key: marker("word_other"),
							value: "OTHER",
							enum: ["OTHER"],
						},
					],
				};
				this.schema.properties.inventory_type = {
					title: "Inventory Type",
					title_translation_key: marker("form_field_label_inventory_type"),
					type: "string",
					widget: "select",
					oneOf: this.inventory_types,
					visibleIf: { output_type: ["OTHER"] },
				};
				this.schema.properties.substance_type_id = {
					title: "Substance Type",
					title_translation_key: marker("word_substance_type"),
					type: "number",
					widget: "data-select",
					oneOf: [
						{
							result_type: "substance_types",
						},
					],
					visibleIf: {
						output_type: ["OTHER"],
					},
				};
				this.substance_type = SubstanceTypes.DriedCannabis;
				this.model.output_type = SubstanceTypes.DriedCannabis;
				if (this._globals.gmp_enabled) {
					this.schema.order = [
						"batch_id",
						"output_type",
						"substance_type_id",
						"inventory_type",
						"value",
					];
				} else {
					this.schema.order = [
						"batch_id",
						"output_type",
						"substance_type_id",
						"inventory_type",
						"value",
						"timestamp",
					];
				}

				const substance_type = this._injector.get("substance_type", null);
				const substance_type_id = this._injector.get("substance_type_id", null);
				if (substance_type === SubstanceTypes.DriedCannabis) {
					this.model = {
						...this.model,
						output_type: SubstanceTypes.DriedCannabis,
					};
				} else if (substance_type_id) {
					this.model = {
						...this.model,
						output_type: "OTHER",
						substance_type_id,
					};
				}
			}
		} else {
			// eslint-disable-next-line no-lonely-if
			if (!this.source) {
				this.form_title = "Record Fresh Cannabis";
				this.form_title_translation_key = marker(
					"form_title_record_fresh_cannabis",
				);

				this.schema.properties.batch_id = {
					type: "number",
					title: "Destination Batch",
					title_translation_key: marker("form_field_label_destination_batch"),
					widget: "data-select",
					oneOf: [
						{
							result_type: "batches",
							queryString: {
								input: "true",
								dont_show_expired: "true",
							},
						},
					],
				};
				this.model.batch_id = this.batch_id;

				this.substance_type = SubstanceTypes.FreshCannabis;

				if (this._globals.gmp_enabled) {
					this.schema.order = ["batch_id", "value"];
				} else {
					this.schema.order = ["batch_id", "value", "timestamp"];
				}
			}
		}

		const weight = this._injector.get("weight", null);
		const timestamp = this._injector.get("timestamp", null);
		if (weight) {
			this.model = {
				...this.model,
				value: Number(this._gramsToUnit.transform(weight)),
			};
		}
		if (timestamp) {
			this.model = {
				...this.model,
				timestamp,
			};
		}

		// clear timestamp for non GMP
		if (
			!this._globals.gmp_enabled &&
			this.schema.properties.timestamp &&
			!this.duplicate_timestamp
		) {
			delete this.model.timestamp;
		}
	}

	ngOnDestroy() {
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}

	onChanges(model) {
		if (
			model.inventory_type &&
			model.inventory_type !== this.prevMeasureUnit &&
			[MeasureUnit.Mass, MeasureUnit.Quantity].includes(this.model.inventory_type)
		) {
			const units = this._globals.getFacilityUnit(model.inventory_type);
			this.schema.properties.value.title = `Value (${units})`;
			this.schema.properties.value.title_translation_params = { units };
			this.prevMeasureUnit = model.inventory_type;
		}
	}

	updateItem(work_order_output: {
		batch_id: number;
		value: number;
		use_remaining?: boolean;
		substance_type_id?: number;
		inventory_type?: MeasureUnit;
		output_type?: string;
		timestamp: Date;
	}) {
		// eslint-disable-next-line no-param-reassign
		work_order_output = {
			...work_order_output,
			timestamp: work_order_output.timestamp,
		};

		// Verify form has remaining_weight or weight
		if (
			!work_order_output.use_remaining &&
			(!work_order_output.value || work_order_output.value <= 0)
		) {
			this.error$.next(
				this._translocoService.translate(
					"error_work_order_input_must_include_remaining_weight_or_positive_weight",
				),
			);
			return;
		}
		if (
			work_order_output.output_type === "OTHER" &&
			!work_order_output.substance_type_id
		) {
			this.error$.next(
				this._translocoService.translate(
					"error_work_order_output_must_include_a_substance_type_other_than_dried_cannabis",
				),
			);
			return;
		}
		this.loading$.next(true);
		const newWeight = this._unitToBaseMeasureUnitPipe.transform(
			work_order_output.value,
			this._globals.getFacilityUnit(MeasureUnit.Mass) ?? "",
			MeasureUnit.Mass,
		);
		const measure_unit = work_order_output.inventory_type ?? MeasureUnit.Mass;
		const input: any = {
			...work_order_output,
			value: work_order_output.value
				? this._unitToBaseMeasureUnitPipe.transform(
						work_order_output.value,
						this._globals.getFacilityUnit(measure_unit) ?? "",
						measure_unit,
					)
				: undefined,
			substance_type: this.substance_type,
			work_order_id: this.work_order_id,
			batch_id: work_order_output.batch_id
				? work_order_output.batch_id
				: this.batch_id,
			measure_unit,
		};

		if (
			work_order_output.output_type === "OTHER" &&
			work_order_output.substance_type_id
		) {
			delete input.substance_type;
			input.substance_type_id = work_order_output.substance_type_id;
		}
		let request: Observable<any>;

		if (this.source) {
			request = this._workOrderService.addSources(input);
		} else {
			request = this._workOrderService.addOutputs(input);
		}

		request
			.pipe(takeUntil(this.destroyed$))
			.pipe(
				timeout(10000),
				catchError((err) => {
					this.error$.next(handleObservableError(err, true));
					this.loading$.next(false);
					return EMPTY;
				}),
			)
			.pipe(
				tap((updatedItem) => {
					this._store.dispatch(
						ItemActions.updateSuccess({
							updatedItem,
							result_type: "work_orders",
						}),
					);
					this.loading$.next(false);
					this.closeSidenav();
				}),
			)
			.subscribe();
	}
}
